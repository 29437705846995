import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 컬렉션에 저장된 삐딱이 로그 목록 조회
 * @param {*} setBbiddakiApiLog client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
 */
export async function getBbiddakiApiLogList(setApiLog, setCount, setLoading) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/outer/bbiddak/getList`,
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then((response) =>  response.data);

    setApiLog(result.lists);
    setCount(result.count);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

export async function getApiLogSearchList(setApiLog, setCount, programType, successResult, start, end) {

  start = dayjs(start).startOf('D').format('YYYY-MM-DD HH:mm:ss');
  end = dayjs(end).endOf('D').format('YYYY-MM-DD HH:mm:ss');

  let startDate = dayjs(start).valueOf();
  let endDate = dayjs(end).valueOf();

  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/outer/bbiddak/getSearchList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        programType, successResult, startDate, endDate
      }),
    })
    .then((response) =>  response.data);

    setApiLog(result.lists);
    setCount(result.count);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 삐딱 api log 엑셀 다운로드
 */
export async function makeXlsx(excelReason) {
  try {
    // 사유 미작성 시 다운로드 못함
    if(!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName = 'AAIHC_API_LOG_' +  Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };
    return await axios.get(
      `${baseUrl}/outer/bbiddak/excel`).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import { category_ref, helpergroup_type_ref, product_id_ref } from 'lib/dataRef';
// import dayjs from 'dayjs';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';

// /**
//  * 컬렉션에 저장된 삐딱이 api_log 개수 조회 (실제로 사용하진 않는 펑션)
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  * @param {string} category2 중분류
//  */
// export async function getHbBbiddakiLogSize(app_code, category2) {
//   try {
//    let bbiddakiLogRef;
//    // 중분류 값이 들어오지 않을 경우
//    // 컬렉션에 저장된 모든 로그 정보를 가져옴
//    bbiddakiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//    .get();
//    let size = 0;
//    bbiddakiLogRef.docs.forEach((doc) => {
//     size++;
//    });
//    return size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 컬렉션에 저장된 삐딱이 로그 목록 조회
//  * @param {*} setBbiddakiApiLog client에서 전달하는 React.Dispatch
//  * @param {*} setCount client에서 전달하는 React.Dispatch
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getBbiddakiApiLogList(setApiLog, setCount, setLoading, app_code) {
//   try {
//     // 리로드 없이도 변동 사항을 반영하기 위해 onSnapshot 이용
//     // (onSnapshot에 대한 자세한 사항은 firebase documentation을 참고하길 바람)
//     const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .orderBy('reg_dt', 'desc')
//       .onSnapshot((snap) => {
//         const apiLogDocs = [];
//         const changes = snap.docChanges();

//         changes.forEach((change) => {
//           // document가 새로 추가된 경우
//           if (change.type === 'added') {
//             if (changes.length > 1) {
//               apiLogDocs.push({
//                 no: (apiLogDocs.length + 2) - 1,
//                 document_id: change.doc.data().document_id,
//                 program_type_cd: change.doc.data().program_type_cd,
//                 nm: change.doc.data().nm,
//                 birth_dt: change.doc.data().birth_dt,
//                 request_dt: change.doc.data().request_dt || '-',
//                 response_dt: change.doc.data().response_dt || '-',
//                 image_url_front: change.doc.data().image_url_front || '-',
//                 image_url_side: change.doc.data().image_url_side || '-',
//                 message: change.doc.data().message || '-',
//                 ok: change.doc.data().ok,
//                 ex001: change.doc.data().ex001.value || '-',
//                 ex002: change.doc.data().ex002.value || '-',
//                 ex003: change.doc.data().ex003.value || '-',
//                 ex004: change.doc.data().ex004.value || '-',
//                 ex005: change.doc.data().ex005.value || '-',
//                 ex006: change.doc.data().ex006.value || '-',
//                 ex007: change.doc.data().ex007.value || '-',
//                 total_score: change.doc.data().total_score || '-',
//                 head_score: change.doc.data().head_score || '-',
//                 upper_body_score: change.doc.data().upper_body_score || '-',
//                 lower_body_score: change.doc.data().lower_body_score || '-',
//                 reg_dt: change.doc.data().reg_dt,
//                 errors: change.doc.data().errors || '-',
//               });
//             } else {
//               snap.forEach((doc) => {
//                 apiLogDocs.push({
//                   no: (apiLogDocs.length + 2) - 1,
//                   document_id: doc.data().document_id,
//                   program_type_cd: doc.data().program_type_cd,
//                   nm: doc.data().nm,
//                   birth_dt: doc.data().birth_dt,
//                   request_dt: doc.data().request_dt || '-',
//                   response_dt: doc.data().response_dt || '-',
//                   image_url_front: doc.data().image_url_front || '-',
//                   image_url_side: doc.data().image_url_side || '-',
//                   message: doc.data().message || '-',
//                   ok: doc.data().ok,
//                   ex001: doc.data().ex001.value || '-',
//                   ex002: doc.data().ex002.value || '-',
//                   ex003: doc.data().ex003.value || '-',
//                   ex004: doc.data().ex004.value || '-',
//                   ex005: doc.data().ex005.value || '-',
//                   ex006: doc.data().ex006.value || '-',
//                   ex007: doc.data().ex007.value || '-',
//                   total_score: doc.data().total_score || '-',
//                   head_score: doc.data().head_score || '-',
//                   upper_body_score: doc.data().upper_body_score || '-',
//                   lower_body_score: doc.data().lower_body_score || '-',
//                   reg_dt: doc.data().reg_dt,
//                   errors: doc.data().errors || '-',
//                 });
//               });
//             }
//           }
//           // document 안의 값의 수정 혹은 필드 추가의 경우
//           if (change.type === 'modified') {
//             snap.forEach((doc) => {
//               apiLogDocs.push({
//                 no: (apiLogDocs.length + 2) - 1,
//                 document_id: doc.data().document_id,
//                 program_type_cd: doc.data().program_type_cd,
//                 nm: doc.data().nm,
//                 birth_dt: doc.data().birth_dt,
//                 request_dt: doc.data().request_dt || '-',
//                 response_dt: doc.data().response_dt || '-',
//                 image_url_front: doc.data().image_url_front || '-',
//                 image_url_side: doc.data().image_url_side || '-',
//                 message: doc.data().message || '-',
//                 ok: doc.data().ok,
//                 ex001: doc.data().ex001.value || '-',
//                 ex002: doc.data().ex002.value || '-',
//                 ex003: doc.data().ex003.value || '-',
//                 ex004: doc.data().ex004.value || '-',
//                 ex005: doc.data().ex005.value || '-',
//                 ex006: doc.data().ex006.value || '-',
//                 ex007: doc.data().ex007.value || '-',
//                 total_score: doc.data().total_score || '-',
//                 head_score: doc.data().head_score || '-',
//                 upper_body_score: doc.data().upper_body_score || '-',
//                 lower_body_score: doc.data().lower_body_score || '-',
//                 reg_dt: doc.data().reg_dt,
//                 errors: doc.data().errors || '-',
//               });
//             });
//           }
//           // document가 삭제된 경우
//           if (change.type === 'removed') {
//             snap.forEach((doc) => {
//               apiLogDocs.push({
//                 no: (apiLogDocs.length + 2) - 1,
//                 document_id: doc.data().document_id,
//                 program_type_cd: doc.data().program_type_cd,
//                 nm: doc.data().nm,
//                 birth_dt: doc.data().birth_dt,
//                 request_dt: doc.data().request_dt || '-',
//                 response_dt: doc.data().response_dt || '-',
//                 image_url_front: doc.data().image_url_front || '-',
//                 image_url_side: doc.data().image_url_side || '-',
//                 message: doc.data().message || '-',
//                 ok: doc.data().ok,
//                 ex001: doc.data().ex001.value || '-',
//                 ex002: doc.data().ex002.value || '-',
//                 ex003: doc.data().ex003.value || '-',
//                 ex004: doc.data().ex004.value || '-',
//                 ex005: doc.data().ex005.value || '-',
//                 ex006: doc.data().ex006.value || '-',
//                 ex007: doc.data().ex007.value || '-',
//                 total_score: doc.data().total_score || '-',
//                 head_score: doc.data().head_score || '-',
//                 upper_body_score: doc.data().upper_body_score || '-',
//                 lower_body_score: doc.data().lower_body_score || '-',
//                 reg_dt: doc.data().reg_dt,
//                 errors: doc.data().errors || '-',
//               });
//             });
//           }
//         });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//         setLoading(false);
//       });
//     return apiLogRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// export async function getApiLogSearchList(setApiLog, setCount, programType, successResult, start, end) {

//   start = dayjs(start).startOf('D').format('YYYY-MM-DD HH:mm:ss');
//   end = dayjs(end).endOf('D').format('YYYY-MM-DD HH:mm:ss');

//   let startDate = dayjs(start).valueOf();
//   let endDate = dayjs(end).valueOf();

//   try {
//     const apiLogDocs = [];
//     // 검색조건 초기화
//     if(programType === 'all' && successResult === 'all') {
//       console.log(' 검색조건 초기화 선택');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//         apiLogDocs.push({
//           no: (apiLogDocs.length + 2) - 1,
//           document_id: change.doc.data().document_id,
//           program_type_cd: change.doc.data().program_type_cd,
//           nm: change.doc.data().nm,
//           birth_dt: change.doc.data().birth_dt,
//           request_dt: change.doc.data().request_dt || '-',
//           response_dt: change.doc.data().response_dt || '-',
//           image_url_front: change.doc.data().image_url_front || '-',
//           image_url_side: change.doc.data().image_url_side || '-',
//           message: change.doc.data().message || '-',
//           ok: change.doc.data().ok,
//           ex001: change.doc.data().ex001.value || '-',
//           ex002: change.doc.data().ex002.value || '-',
//           ex003: change.doc.data().ex003.value || '-',
//           ex004: change.doc.data().ex004.value || '-',
//           ex005: change.doc.data().ex005.value || '-',
//           ex006: change.doc.data().ex006.value || '-',
//           ex007: change.doc.data().ex007.value || '-',
//           total_score: change.doc.data().total_score || '-',
//           head_score: change.doc.data().head_score || '-',
//           upper_body_score: change.doc.data().upper_body_score || '-',
//           lower_body_score: change.doc.data().lower_body_score || '-',
//           reg_dt: change.doc.data().reg_dt,
//           errors: change.doc.data().errors || '-',
//         });
//         });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 전체 + 성공여부 성공만 선택 시
//     if(programType === 'all' && successResult === 'true') {
//       console.log('프로그램명 전체 + 성공여부 성공 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('ok', '==', true)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//       const changes = snap.docChanges();
//       changes.forEach((change) => {
//         apiLogDocs.push({
//         no: (apiLogDocs.length + 2) - 1,
//         document_id: change.doc.data().document_id,
//         program_type_cd: change.doc.data().program_type_cd,
//         nm: change.doc.data().nm,
//         birth_dt: change.doc.data().birth_dt,
//         request_dt: change.doc.data().request_dt || '-',
//         response_dt: change.doc.data().response_dt || '-',
//         image_url_front: change.doc.data().image_url_front || '-',
//         image_url_side: change.doc.data().image_url_side || '-',
//         message: change.doc.data().message || '-',
//         ok: change.doc.data().ok,
//         ex001: change.doc.data().ex001.value || '-',
//         ex002: change.doc.data().ex002.value || '-',
//         ex003: change.doc.data().ex003.value || '-',
//         ex004: change.doc.data().ex004.value || '-',
//         ex005: change.doc.data().ex005.value || '-',
//         ex006: change.doc.data().ex006.value || '-',
//         ex007: change.doc.data().ex007.value || '-',
//         total_score: change.doc.data().total_score || '-',
//         head_score: change.doc.data().head_score || '-',
//         upper_body_score: change.doc.data().upper_body_score || '-',
//         lower_body_score: change.doc.data().lower_body_score || '-',
//         reg_dt: change.doc.data().reg_dt,
//         errors: change.doc.data().errors || '-',
//         });
//       });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 전체 + 성공여부 실패 선택 시
//     if(programType === 'all' && successResult === 'false') {
//       console.log('프로그램명 전체 + 성공여부 실패 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('ok', '==', false)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//       const changes = snap.docChanges();
//       changes.forEach((change) => {
//         apiLogDocs.push({
//         no: (apiLogDocs.length + 2) - 1,
//         document_id: change.doc.data().document_id,
//         program_type_cd: change.doc.data().program_type_cd,
//         nm: change.doc.data().nm,
//         birth_dt: change.doc.data().birth_dt,
//         request_dt: change.doc.data().request_dt || '-',
//         response_dt: change.doc.data().response_dt || '-',
//         image_url_front: change.doc.data().image_url_front || '-',
//         image_url_side: change.doc.data().image_url_side || '-',
//         message: change.doc.data().message || '-',
//         ok: change.doc.data().ok,
//         ex001: change.doc.data().ex001.value || '-',
//         ex002: change.doc.data().ex002.value || '-',
//         ex003: change.doc.data().ex003.value || '-',
//         ex004: change.doc.data().ex004.value || '-',
//         ex005: change.doc.data().ex005.value || '-',
//         ex006: change.doc.data().ex006.value || '-',
//         ex007: change.doc.data().ex007.value || '-',
//         total_score: change.doc.data().total_score || '-',
//         head_score: change.doc.data().head_score || '-',
//         upper_body_score: change.doc.data().upper_body_score || '-',
//         lower_body_score: change.doc.data().lower_body_score || '-',
//         reg_dt: change.doc.data().reg_dt,
//         errors: change.doc.data().errors || '-',
//         });
//       });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 재발방지 + 성공여부 전체 선택 시
//     if(programType === 'R' && successResult === 'all') {
//       console.log('프로그램명 재발방지 + 성공여부 전체 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('program_type_cd', '==', programType)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//       const changes = snap.docChanges();
//       changes.forEach((change) => {
//         apiLogDocs.push({
//         no: (apiLogDocs.length + 2) - 1,
//         document_id: change.doc.data().document_id,
//         program_type_cd: change.doc.data().program_type_cd,
//         nm: change.doc.data().nm,
//         birth_dt: change.doc.data().birth_dt,
//         request_dt: change.doc.data().request_dt || '-',
//         response_dt: change.doc.data().response_dt || '-',
//         image_url_front: change.doc.data().image_url_front || '-',
//         image_url_side: change.doc.data().image_url_side || '-',
//         message: change.doc.data().message || '-',
//         ok: change.doc.data().ok,
//         ex001: change.doc.data().ex001.value || '-',
//         ex002: change.doc.data().ex002.value || '-',
//         ex003: change.doc.data().ex003.value || '-',
//         ex004: change.doc.data().ex004.value || '-',
//         ex005: change.doc.data().ex005.value || '-',
//         ex006: change.doc.data().ex006.value || '-',
//         ex007: change.doc.data().ex007.value || '-',
//         total_score: change.doc.data().total_score || '-',
//         head_score: change.doc.data().head_score || '-',
//         upper_body_score: change.doc.data().upper_body_score || '-',
//         lower_body_score: change.doc.data().lower_body_score || '-',
//         reg_dt: change.doc.data().reg_dt,
//         errors: change.doc.data().errors || '-',
//         });
//       });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 재발방지 + 성공여부 성공 선택 시
//     if(programType === 'R' && successResult === 'true') {
//       console.log('프로그램명 재발방지 + 성공여부 성공 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('program_type_cd', '==', programType)
//       .where('ok', '==', true)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//           apiLogDocs.push({
//           no: (apiLogDocs.length + 2) - 1,
//           document_id: change.doc.data().document_id,
//           program_type_cd: change.doc.data().program_type_cd,
//           nm: change.doc.data().nm,
//           birth_dt: change.doc.data().birth_dt,
//           request_dt: change.doc.data().request_dt || '-',
//           response_dt: change.doc.data().response_dt || '-',
//           image_url_front: change.doc.data().image_url_front || '-',
//           image_url_side: change.doc.data().image_url_side || '-',
//           message: change.doc.data().message || '-',
//           ok: change.doc.data().ok,
//           ex001: change.doc.data().ex001.value || '-',
//           ex002: change.doc.data().ex002.value || '-',
//           ex003: change.doc.data().ex003.value || '-',
//           ex004: change.doc.data().ex004.value || '-',
//           ex005: change.doc.data().ex005.value || '-',
//           ex006: change.doc.data().ex006.value || '-',
//           ex007: change.doc.data().ex007.value || '-',
//           total_score: change.doc.data().total_score || '-',
//           head_score: change.doc.data().head_score || '-',
//           upper_body_score: change.doc.data().upper_body_score || '-',
//           lower_body_score: change.doc.data().lower_body_score || '-',
//           reg_dt: change.doc.data().reg_dt,
//           errors: change.doc.data().errors || '-',
//           });
//         });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 재발방지 + 성공여부 실패 선택 시
//     if(programType === 'R' && successResult === 'false') {
//       console.log('프로그램명 재발방지 + 성공여부 실패 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('program_type_cd', '==', programType)
//       .where('ok', '==', false)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//           apiLogDocs.push({
//           no: (apiLogDocs.length + 2) - 1,
//           document_id: change.doc.data().document_id,
//           program_type_cd: change.doc.data().program_type_cd,
//           nm: change.doc.data().nm,
//           birth_dt: change.doc.data().birth_dt,
//           request_dt: change.doc.data().request_dt || '-',
//           response_dt: change.doc.data().response_dt || '-',
//           image_url_front: change.doc.data().image_url_front || '-',
//           image_url_side: change.doc.data().image_url_side || '-',
//           message: change.doc.data().message || '-',
//           ok: change.doc.data().ok,
//           ex001: change.doc.data().ex001.value || '-',
//           ex002: change.doc.data().ex002.value || '-',
//           ex003: change.doc.data().ex003.value || '-',
//           ex004: change.doc.data().ex004.value || '-',
//           ex005: change.doc.data().ex005.value || '-',
//           ex006: change.doc.data().ex006.value || '-',
//           ex007: change.doc.data().ex007.value || '-',
//           total_score: change.doc.data().total_score || '-',
//           head_score: change.doc.data().head_score || '-',
//           upper_body_score: change.doc.data().upper_body_score || '-',
//           lower_body_score: change.doc.data().lower_body_score || '-',
//           reg_dt: change.doc.data().reg_dt,
//           errors: change.doc.data().errors || '-',
//           });
//         });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 질환예방 + 성공여부 전체 선택 시
//     if(programType === 'P' && successResult === 'all') {
//       console.log('프로그램명 질환예방 + 성공여부 전체 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('program_type_cd', '==', programType)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//           apiLogDocs.push({
//           no: (apiLogDocs.length + 2) - 1,
//           document_id: change.doc.data().document_id,
//           program_type_cd: change.doc.data().program_type_cd,
//           nm: change.doc.data().nm,
//           birth_dt: change.doc.data().birth_dt,
//           request_dt: change.doc.data().request_dt || '-',
//           response_dt: change.doc.data().response_dt || '-',
//           image_url_front: change.doc.data().image_url_front || '-',
//           image_url_side: change.doc.data().image_url_side || '-',
//           message: change.doc.data().message || '-',
//           ok: change.doc.data().ok,
//           ex001: change.doc.data().ex001.value || '-',
//           ex002: change.doc.data().ex002.value || '-',
//           ex003: change.doc.data().ex003.value || '-',
//           ex004: change.doc.data().ex004.value || '-',
//           ex005: change.doc.data().ex005.value || '-',
//           ex006: change.doc.data().ex006.value || '-',
//           ex007: change.doc.data().ex007.value || '-',
//           total_score: change.doc.data().total_score || '-',
//           head_score: change.doc.data().head_score || '-',
//           upper_body_score: change.doc.data().upper_body_score || '-',
//           lower_body_score: change.doc.data().lower_body_score || '-',
//           reg_dt: change.doc.data().reg_dt,
//           errors: change.doc.data().errors || '-',
//           });
//         });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 질환예방 + 성공여부 성공 선택 시
//     if(programType === 'P' && successResult === 'true') {
//       console.log('프로그램명 질환예방 + 성공여부 성공 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('program_type_cd', '==', programType)
//       .where('ok', '==', true)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//           apiLogDocs.push({
//           no: (apiLogDocs.length + 2) - 1,
//           document_id: change.doc.data().document_id,
//           program_type_cd: change.doc.data().program_type_cd,
//           nm: change.doc.data().nm,
//           birth_dt: change.doc.data().birth_dt,
//           request_dt: change.doc.data().request_dt || '-',
//           response_dt: change.doc.data().response_dt || '-',
//           image_url_front: change.doc.data().image_url_front || '-',
//           image_url_side: change.doc.data().image_url_side || '-',
//           message: change.doc.data().message || '-',
//           ok: change.doc.data().ok,
//           ex001: change.doc.data().ex001.value || '-',
//           ex002: change.doc.data().ex002.value || '-',
//           ex003: change.doc.data().ex003.value || '-',
//           ex004: change.doc.data().ex004.value || '-',
//           ex005: change.doc.data().ex005.value || '-',
//           ex006: change.doc.data().ex006.value || '-',
//           ex007: change.doc.data().ex007.value || '-',
//           total_score: change.doc.data().total_score || '-',
//           head_score: change.doc.data().head_score || '-',
//           upper_body_score: change.doc.data().upper_body_score || '-',
//           lower_body_score: change.doc.data().lower_body_score || '-',
//           reg_dt: change.doc.data().reg_dt,
//           errors: change.doc.data().errors || '-',
//           });
//         });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }

//    // 프로그램명 질환예방 + 성공여부 실패 선택 시
//     if(programType === 'P' && successResult === 'false') {
//       console.log('프로그램명 질환예방 + 성공여부 실패 선택 시');
//       const apiLogRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//       .where('program_type_cd', '==', programType)
//       .where('ok', '==', false)
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//           apiLogDocs.push({
//           no: (apiLogDocs.length + 2) - 1,
//           document_id: change.doc.data().document_id,
//           program_type_cd: change.doc.data().program_type_cd,
//           nm: change.doc.data().nm,
//           birth_dt: change.doc.data().birth_dt,
//           request_dt: change.doc.data().request_dt || '-',
//           response_dt: change.doc.data().response_dt || '-',
//           image_url_front: change.doc.data().image_url_front || '-',
//           image_url_side: change.doc.data().image_url_side || '-',
//           message: change.doc.data().message || '-',
//           ok: change.doc.data().ok,
//           ex001: change.doc.data().ex001.value || '-',
//           ex002: change.doc.data().ex002.value || '-',
//           ex003: change.doc.data().ex003.value || '-',
//           ex004: change.doc.data().ex004.value || '-',
//           ex005: change.doc.data().ex005.value || '-',
//           ex006: change.doc.data().ex006.value || '-',
//           ex007: change.doc.data().ex007.value || '-',
//           total_score: change.doc.data().total_score || '-',
//           head_score: change.doc.data().head_score || '-',
//           upper_body_score: change.doc.data().upper_body_score || '-',
//           lower_body_score: change.doc.data().lower_body_score || '-',
//           reg_dt: change.doc.data().reg_dt,
//           errors: change.doc.data().errors || '-',
//           });
//         });
//         setApiLog(apiLogDocs);
//         setCount(apiLogDocs.length);
//       });
//       return apiLogRef;
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 삐딱 api log 엑셀 다운로드
//  */
// export async function makeXlsx(programType, successResult, start, end) {

//   start = dayjs(start).startOf('D').format('YYYY-MM-DD HH:mm:ss');
//   end = dayjs(end).endOf('D').format('YYYY-MM-DD HH:mm:ss');
//   let startDate = dayjs(start).valueOf();
//   let endDate = dayjs(end).valueOf();

//   try {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const apiLogData = [];
//     if(programType === 'all' && successResult === 'all') {
//       console.log(' 검색조건 초기화 선택');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'all' && successResult === 'true') {
//       console.log('프로그램명 전체 + 성공여부 성공 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('ok', '==', true)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'all' && successResult === 'false') {
//       console.log('프로그램명 전체 + 성공여부 실패 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('ok', '==', false)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'P' && successResult === 'all') {
//       console.log('프로그램명 질환예방 + 성공여부 전체 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('program_type_cd', '==', programType)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'P' && successResult === 'true') {
//       console.log('프로그램명 질환예방 + 성공여부 성공 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('program_type_cd', '==', programType)
//         .where('ok', '==', true)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'P' && successResult === 'false') {
//       console.log('프로그램명 질환예방 + 성공여부 실패 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('program_type_cd', '==', programType)
//         .where('ok', '==', false)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'R' && successResult === 'all') {
//       console.log('프로그램명 재발방지 + 성공여부 전체 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('program_type_cd', '==', programType)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'R' && successResult === 'true') {
//       console.log('프로그램명 재발방지 + 성공여부 성공 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('program_type_cd', '==', programType)
//         .where('ok', '==', true)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }
//     if(programType === 'R' && successResult === 'false') {
//       console.log('프로그램명 재발방지 + 성공여부 실패 선택 시');
//       const apiLogsRef = await firestore.collection('cust_HB_BBIDDAKI_LOG')
//         .where('program_type_cd', '==', programType)
//         .where('ok', '==', false)
//         .where('reg_dt', '>', startDate)
//         .where('reg_dt', '<', endDate)
//         .orderBy('reg_dt', 'desc').get();
//         apiLogsRef.docs.forEach((doc) => {
//         apiLogData.push({
//           '프로그램명': doc.data().program_type_cd === 'R' ? '재발방지' : '질환예방',
//           '이름': doc.data().nm,
//           '전송시간': new Date(doc.data().request_dt).toLocaleString(),
//           '응답시간': new Date(doc.data().response_dt).toLocaleString(),
//           '정면사진': doc.data().image_url_front,
//           '측면사진': doc.data().image_url_side,
//           '메시지': doc.data().message,
//           '성공여부': String(doc.data().ok) === 'true' ? '성공' : '실패',
//           '머리-목1': doc.data().ex001.value || '-',
//           '머리-목2': doc.data().ex002.value || '-',
//           '머리-목3': doc.data().ex003.value || '-',
//           '상체1': doc.data().ex004.value || '-',
//           '상체2': doc.data().ex005.value || '-',
//           '하체1': doc.data().ex006.value || '-',
//           '하체2': doc.data().ex007.value || '-',
//           '전체점수(평균)': Number(doc.data().total_score).toFixed(2),
//           '머리-목': Number(doc.data().head_score).toFixed(2),
//           '상체': Number(doc.data().upper_body_score).toFixed(2),
//           '하체': Number(doc.data().lower_body_score).toFixed(2),
//         });
//       });
//     }

//     const exportXlsx = (data) => {
//       const fileName = 'AAIHC_API_LOG_' + (start + '-' + end);
//       const workSheet = XLSX.utils.json_to_sheet(data);
//       const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
//       const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
//       const blob = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(blob, fileName, fileExtension);
//     };
//     return exportXlsx(apiLogData);
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }