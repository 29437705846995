export const gender_ref = {
  M: '남성',
  F: '여성',
};

export const company_ref = {
  // L51: '라이나',
  N03: '롯데',
  N14: '하나',
  L03: '삼성',
  L34: '미래에셋',
  // X31: 'KT다온플랜',
  L77: '처브라이프',
  L81: '농협',
  X36: '농협투자증권',
  L71: 'DB생명',
  N13: 'DB손보',
  L04: '흥국생명',
  // L52: 'AIA생명',
};
export const storage_ref = {
  // L51: 'lina',
  N03: 'lotteDodamDodam',
  N14: 'hana',
  L03: 'samsung',
  L34: 'miraeasset',
  // X31: 'daon',
  L77: 'chubbLife',
  X36: 'nhInvestment',
  L71: 'dbLife',
  N13: 'promy',
  L04: 'hkLife',
  // L52: 'aiaHealth',
};
// 건강한몸 프로그램 구분참조 추가 (2021.10.01 YOO)
export const program_ref = {
  P: '질환예방',
  R: '재발방지',
};
export const body_type_ref = {
  P01: '목\n피로예방',
  P02: '어깨\n피로예방',
  P03: '허리\n피로예방',
  P04: '다리\n피로예방',
  P05: '손\n피로예방',
  P06: '발\n피로예방',
  R01: '목\n디스크',
  R02: '허리\n디스크',
  R03: '허리\n협착증',
  R04: '오십견',
  R05: '회전근개\n파열',
  R06: '반월상\n연골손상',
  R07: '십자인대\n파열',
  R08: '류마티스\n관절염',
  R09: '골다공증',
};
//시스템메뉴 강제업데이트 앱버전 관리 메뉴 os구분 추가 (20230105)
export const os_ref = {
  AOS: 'Android',
  IOS: 'IOS',
};

export const company_filter_ref = Object.entries(company_ref).map((row) => {
  const [value, text] = row;
  return {
    text,
    value,
  };
});
export const os_filter_ref = Object.entries(os_ref).map((row) => {
  const [value, text] = row;
  return {
    text,
    value,
  };
});
export const cp_id_mapper = {
  // L51: 'LinaCancer01_4180',
  N03: 'LotteDodam01_4186',
  N14: 'HanaCancer01_4231',
  L03: 'SamsungLife01_0000',
  L34: 'MiraeAssetAllCare01_4247',
  // X31: 'KTDaonPlanFamily01_4255',
  L77: 'ChubbLife01_0000',
  X36: 'NhInvestment01_4380',
  L71: 'DBLIFE01_0000',
  N13: 'DBIns01_0000',
  L04: 'HKLife01_0000',
  // L52: 'AIALife01_0000',
};
// export const product_id_ref = {
//   LinaCancer01_4180: '라이나 암케어 프로그램',
//   LotteDodam01_4186: '렛플레이 헬스케어',
//   HanaCancer01_4231: '하나손해보험 헬스케어',
//   HanaFire01_0000: '하나손해보험 헬스케어',
//   SamsungLife01_0000: '삼성생명 건강관리서비스',
//   MiraeAssetAllCare01_4247: '미래에셋 헬스케어',
//   KTDaonPlanFamily01_4255: 'KT다온플랜 가족사랑 헬스케어',
//   ChubbLife01_0000: '처브라이프 헬스케어',
// };
export const product_id_ref = {
  // LinaCancer01_4180: '라이나 암케어 프로그램',
  LotteDodam01_4186: '렛플레이 헬스케어',
  HanaFire01_0000: '하나손해보험 헬스케어',
  HanaCancer01_4231: '하나손해보험 헬스케어',
  HanaChild01_4397: '하나손해보험 어린이 헬스케어',
  SamsungLife01_0000: '삼성생명 건강관리서비스',
  MiraeAssetAllCare01_4247: '미래에셋 헬스케어',
  // KTDaonPlanFamily01_4255: 'KT다온플랜 가족사랑 헬스케어',
  ChubbLife01_0000: '처브라이프 헬스케어',
  NhInvestment01_4380: 'nh투자증권 퇴직연금 헬스케어',
  DBLIFE01_0000: '백년친구 헬스케어',
  DBins01_0000: '프로미 훼밀리케어',
  DBIns01_0000: '프로미 훼밀리케어',
  HKLife01_0000: '흥국생명 헬스케어',
  // AIALife01_0000: 'AIA생명 헬스케어',
};

export const app_code_ref = {
  // LinaCancer01: product_id_ref.LinaCancer01_4180,
  LotteDodam01: product_id_ref.LotteDodam01_4186,
  HanaCancer01: product_id_ref.HanaCancer01_4231,
  HanaFire01: product_id_ref.HanaFire01_0000,
  HanaChild01: product_id_ref.HanaChild01_4397,
  SamsungLife01: product_id_ref.SamsungLife01_0000,
  MiraeAssetAllCare01: product_id_ref.MiraeAssetAllCare01_4247,
  // KTDaonPlanFamily01: product_id_ref.KTDaonPlanFamily01_4255,
  ChubbLife01: product_id_ref.ChubbLife01_0000,
  NhInvestment01: product_id_ref.NhInvestment01_4380,
  DBINS01: product_id_ref.DBLIFE01_0000,
  DBLIKE01: product_id_ref.DBIns01_0000,
  HKLife01: product_id_ref.HKLife01_0000,
  // AIAHealth01: product_id_ref.AIALife01_0000,
};
export const status_ref = {
  0: '신청',
  1: '진행중',
  2: '완료',
  9: '취소',
  10: '[P]접수',
  11: '[P]서약서발송',
  12: '[P]서약서작성완료',
  13: '[P]완료',
  14: '[P]종결',
  19: '[P]취소',
};
export const body_age_status_ref = {
  0: '신청완료',
  3: '측정오류',
  4: '측정완료',
  5: 'PDF생성',
};

export const category_ref = (company_id) => {
  let category_obj1;
  let category_obj2;

  switch (company_id) {
    default:
    case 'N14': // 하나
      category_obj1 = {
        default: '일반상담',
        care: '예방 케어',
        professionalCare: '돌봄 케어',
        healthCare: '헬스 케어',
        basicCare: '기본 케어',
        learningPsychology: '학습 / 심리 상담',
        cancerCare: '암 케어',
      };
      category_obj2 = {
        default: '일반상담',
        booking: '진료/검진 예약',
        escort: '차량 에스코트',
        nurseAccompany: '간호사 진료 동행',
        patientDiet: '건강환자식',
        poorLearning: '학습진단평가',
        languageEvaluation: '언어평가 검사우대',
        caregiverHousekeeper: '간병/가사/산후/학습/등하교 도우미',
        immuneCellStorage: '면역세포 보관 서비스',
        psychologyCounsel: '심리상담',
        growthPlate: '성장판 검사비 지원',
        storageCleanup: '정리 / 수납',
        cancerVaccination: '암백신 접종 우대가',
        careStay: '케어스테이',
        silvia: '치매예방프로그램',
        discount: '간병인 지원',
        housekeeper: '가사 도우미 지원',
      };
      break;
    case 'L03': // 삼성
      category_obj1 = {
        default: '일반상담',
        basicCare: '베이직',
        prefTreatment: '우대',
        premiumCare: '프리미엄',
        growthCare: '성장케어',
        pregChildbirthCare: '임신출산케어',
        dentalCare: '치아케어',
        nursingCare: '시니어케어(요양형)',
        dementiaCare: '시니어케어(치매형)',
      };
      category_obj2 = {
        default: '일반상담',
        saemi: 'AI 챗봇 인지개선 프로그램',
        booking: '검진/진료 예약',
        storageCleanup: '정리수납∙공간 살균케어',
        escort: '입원시 차량에스코트',
        ambulance: '병원간 이송지원(앰뷸런스)',
        immuneCellStorage: '면역세포 보관 서비스',
        patientDiet: '치료식단 할인',
        discount: '간병케어 물품 구매 우대',
        careSupplies: '간병케어 물품 구매 우대',
        anticancerWig: '가발 우대',
        dietCancer: '치료식 배달(암)',
        SRTKTX: 'SRT/KTX',
        nurseAccompany: '상급병원 간호사 동행',
        carerSupport: '입원시 간병인 지원',
        housekeeper: '가사도우미 지원',
        psychologyCounsel: '대면 심리상담',
        sleepDisorders: '수면장애검사지원',
        growthPlate: '성장판 검사비 지원',
        obesityGene: '비만 유전자검사지원',
        childrenPsychologyCounsel: '[자녀]대면심리상담제공',
        parentsPsychologyCounsel: '[부모]유선/대면심리상담제공',
        refitCard: '[부모]리핏카드제공',
        underweightChildren: '저체중아 간호사 방문',
        languageEvaluation: '언어발달평가 우대',
        postpartumAssistant: '산후도우미 우선배정/우대',
        scailingSupport: '스케일링비 치료 지원',
        gumManageSupport: '잇몸관리비 치료 지원',
        hair: '모발 중금속 / 미네랄 검사',
        rehabilitationExercise: '재활 운동 지원',
        companion: '동행인 지원',
        wanderingDetector: '배회감지기 제공',
        familyProtection: '가족보호서비스(치매유전자)',
        careStay: '케어스테이',
        nursingFacilityCounsel: '요양시설 입소 상담',
        hospiceHospital: '호스피스 병원 안내',
        nursingCareIndemnity: '요양실손케어',
        dementiaPsychologyCounsel: '심리상담(보호자/환자)',
      };
      break;
    case 'L34': // 미래에셋
      category_obj1 = {
        default: '일반상담',
        care: '돌봄 케어',
        basicCare: '기본 케어',
        diagnosisCare: '진단 케어',
        professionalCare: '진단 케어',
      };
      category_obj2 = {
        default: '일반상담',
        booking: '진료/검진예약',
        psychologyCounsel: '심리상담',
        nurseAccompany: '간호사 진료 동행',
        escort: '차량 에스코트',
      };
      break;
    // case 'X31': // KT다온플랜
    //   category_obj1 = {
    //     dailyLifeCare: '일상케어',
    //     welfareCare: '복지케어',
    //     default: '일반상담',
    //   };
    //   category_obj2 = {
    //     booking: '진료/검진예약',
    //     psychologyCounsel: '심리상담우대',
    //     default: '일반상담',
    //   };
    //   break;
    case 'L77': // 처브라이프
      category_obj1 = {
        basicCare: '평상시 케어',
        seriousIllnessCare: '중대질환 케어',
        treatmentSupportCare: '치료지원 케어',
        nobleCare: '노블 케어',
        default: '일반상담',
        PETCare: '펫케어',
        stickCare: '밀착케어',
        newBasicCare: "[new] 평상시케어",
        newTreatmentSupportCare: "[new] 치료지원 케어",
        newSeriousIllnessCare: "[new] 중대질환케어",
      };
      category_obj2 = {
        booking: '진료예약 대행',
        escort: '입원 시 차량에스코트',
        ambulance: '병원간 이송지원(앰뷸런스)',
        patientDiet: '치료식단 할인 서비스',
        discount: '간병케어 물품 구매 우대',
        careSupplies: '간병케어 물품 구매 우대',
        nurseAccompany: '상급병원 간호사 동행',
        carerSupport: '입원시 간병인 지원',
        housekeeper: '가사도우미 지원',
        psychologyCounsel: '심리상담 (대면/유선)',
        saemi: 'AI 챗봇 인지개선 프로그램',
        hair: '모발 미네랄 검사',
        DTC: '유전자(DTC) 검사 우대',
        default: '일반상담',
        seniorDTC: '유전자/모발DTC검사',
        PETLossKit: 'PET심리상담 / 소변키트',
      };
      break;
    // case 'L51': // 라이나
    //   category_obj1 = {
    //     default: '일반상담',
    //     care: '돌봄 케어',
    //     professionalCare: '전문 케어',
    //     targetCare: '표적 케어',
    //     cancerCare: '암 케어',
    //   };
    //   category_obj2 = {
    //     default: '일반상담',
    //     ambulance: '병원간 앰뷸런스 이동',
    //     booking: '진료/검진 예약',
    //     mobileConsulting: '운동처방사 모바일상담',
    //     discount: '간병인/물품 구매 할인',
    //     escort: '차량 에스코트',
    //     hair: '모발 중금속/미네랄 검사',
    //     immune: '면역활성도 검사',
    //     microbe: '장내 미생물 검사',
    //     nurseAccompany: '간호사 병원 내원 동행',
    //     patientDiet: '암 환자식단 할인/제공',
    //   };
    case 'N03': // 롯데
      category_obj1 = {
        default: '일반상담',
        care: '돌봄 케어',
        professionalCare: '전문 케어',
        targetCare: '표적 케어',
        cancerCare: '암 케어',
        healthCare: '헬스케어 서비스',
        learningPsychology: '학습/심리 서비스',
      };
      category_obj2 = {
        default: '일반상담',
        ambulance: '병원간 앰뷸런스 이동',
        booking: '진료/검진 예약',
        mobileConsulting: '운동처방사 모바일상담',
        discount: '간병인/물품 구매 할인',
        escort: '차량 에스코트',
        hair: '모발 중금속/미네랄 검사',
        immune: '면역활성도 검사',
        microbe: '장내 미생물 검사',
        nurseAccompany: '간호사 병원 내원 동행',
        patientDiet: '암 환자식단 할인/제공',
        overseas: '해외 중입자 치료 중개',
        poorLearning: '학습부진 진단 평가',
        languageEvaluation: '언어평가 검사우대',
        dietProvided: '체중조절/치료식 제공',
        caregiverHousekeeper: '산후도우미/가정관리사 우대',
        immuneCellStorage: '면역세포 보관서비스 할인',
        psychologyCounsel: '심리상담(자녀/부모)',
        growthPlate: '성장판 검사비 지원',
      };
      break;
    case 'X36': // NH투자증권
      category_obj1 = {
        default: '일반상담',
        basicCare: '기본 케어',
      };
      category_obj2 = {
        booking: '진료/검진예약',
        callCounsling: '건강상담',
      };
      break;
    case 'N13': // 프로미
      category_obj1 = {
        default: '일반상담',
        basicCare: '기본 케어',
        professionalCare: '돌봄 케어',
        welfareCare: '복지 케어',
        prefTreatment: '우대',
        promotionCare: '프로모션',
      };
      category_obj2 = {
        booking: '진료/검진예약',
        nurseAccompany: '상급병원 간호사 동행',
        escort: '차량 에스코트',
        carerSupport: '입원시 간병인 지원',
        psychologyCounsel: '심리상담',
        discount: '간병케어 물품 구매 우대',
        silvia: '실비아',
        dnaKit: 'DNA키트',
      };
      break;
    // case 'L52': // aia
    //   category_obj1 = {
    //     default: '일반상담',
    //     basicCare: '기본 케어',
    //   };
    //   category_obj2 = {
    //     callCounsling: '건강상담',
    //     booking: '진료/검진예약',
    //     nurseAccompany: '상급병원 간호사 동행',
    //     escort: '차량 에스코트',
    //     supportAbroad: '해외의료기관 2차 소견 서비스',
    //     psychologyCounsel: '심리상담',
    //     multiCare: '셀뱅킹/중입자선/면역세포 암 치료 중개 서비스',
    //   };
    //   break;
    case 'L71': // 백년친구
    case 'L04': // 흥국
      category_obj1 = {
        basicCare: '기본 케어',
        mentalCare: '멘탈케어',
      };
      category_obj2 = {
        booking: '진료/검진예약',
        mentalCounsel: '심리상담',
      };
      break;
  }
  return {
    ref1: category_obj1,
    ref2: category_obj2,
  };
};

export const product_by_company_ref = (company_id) => {
  let products;
  switch (company_id) {
    case 'N14': // 하나
      products = {
        HanaCancer01_4231: '하나손보헬스케어',
        HanaFire01_0000: '하나손보헬스케어',
        HanaChild01_4397: '하나손해보험 어린이헬스케어',
      };
      break;
    case 'L03':
      products = {
        SamsungLife01_0000: '삼성생명 건강관리서비스',
      };
      break;
    case 'L34':
      products = {
        MiraeAssetAllCare01_4247: '미래에셋생명 헬스케어 서비스',
      };
      break;
    // case 'X31':
    //   products = {
    //     KTDaonPlanFamily01_4255: 'KT다온플랜 헬스케어',
    //   };
    //   break;
    case 'L77':
      products = {
        ChubbLife01_0000: '처브라이프 헬스케어',
      };
      break;
    // case 'L51':
    //   products = {
    //     LinaCancer01_4180: '라이나 암케어 프로그램',
    //   };
    //   break;
    case 'N03':
      products = {
        LotteDodam01_4186: '렛플레이 헬스케어',
      };
      break;
    case 'L81':
      products = {
        NHLIFE_0000: 'NH헬스케어',
      };
      break;
    case 'X36':
      products = {
        NhInvestment01_4380: 'NH투자증권 퇴직연금 헬스케어',
      };
      break;
    case 'L71':
      products = {
        DBLIFE01_0000: '백년친구 헬스케어',
      };
      break;
    case 'N13':
      products = {
        DBIns01_0000: '프로미 훼밀리케어',
      };
      break;
    case 'L04':
      products = {
        HKLife01_0000: '흥국생명 헬스케어',
      };
      break;
    // case 'L52':
    //   products = {
    //     AIA생명: 'AIA생명 헬스케어',
    //   };
    //   break;
  }
  return products;
};

export const applyType_ref = {
  C: '암 진단',
  T: '표적 치료',
  CT: '암 수술 후 치료식',
  WC: '출산 후 체중 조절식',
  A: '제휴 병원 이용',
  NA: '제휴 병원 외 타 병원 이용',
};
export const symptoms_list_ref = {
  cancer: {
    설사: undefined,
    식욕부진: undefined,
    구내염: undefined,
    피로: undefined,
    '오심/구토': undefined,
    가려움증: undefined,
    탈모: undefined,
    '손끝 저림': undefined,
  },
  brain: {
    시야이상: undefined,
    두통: undefined,
    어지러움: undefined,
    '균형감각 상실': undefined,
    '얼굴감각 이상': undefined,
    '사지 위약감': undefined,
    구음장애: undefined,
    보행장애: undefined,
  },
  heart: {
    흉통: undefined,
    호흡곤란: undefined,
    방사통: undefined,
    '구역/구토': undefined,
    '소화불량/속쓰림': undefined,
  },
};
export const symptoms_ref = {
  0: '전혀 없다',
  1: '약간 있다',
  2: '보통이다',
  3: '꽤 심하다',
  4: '아주 심하다',
  undefined: '입력없음',
};
export const login_yn_ref = {
  true: '성공',
  false: '실패',
};

export const mediCerts_ref = {
  0: '신청',
  1: '진행중',
  2: '완료',
  9: '취소',
};

export const img_url_map_ref = {
  freestyle: '자유 형식 그림',
  lately: '최근에 그린 그림',
  homeTreePerson: '집, 나무, 사람이 포함된 그림',
};

export const child_answer_ref = {
  who: '이 집에는 누가 살고 있나요?',
  houseAtmosphere: '이 집의 분위기는 어떻습니까?',
  wantToLive: '이 집에서 살고 싶나요?',
  treeAge: '이 나무는 몇 살 정도 되었습니까?',
  treeCondition: '이 나무의 건강상태는 어떤가요?',
  wish: '이 나무에게 소원이 있다면 무엇인가요?',
  personAge: '이 사람은 몇 살 정도 되었습니까?',
  reminiscent: '이 사람을 그릴 때 누구를 생각하며 그렸습니까?',
  action: '이 사람은 무엇을 하고있나요?',
  happy: '이 사람은 행복합니까? 불행합니까?',
  childAtmosphere: '그림을 그릴때 아이의 태도나 분위기는 어땠습니까?',
};

export const option_memo_ref = {
  friendship: '교우관계',
  subject: '교습중인 과목',
  history: '병력(과거/현재)',
  habitAction: '평소 습관 및 행동',
  specialty: '특기/취미',
  avoidFood: '피해야 할 음식',
  requestingReason: '상담 신청 이유',
  requestingWish: '상담시 바라는 점',
};

export const mediCerts_type_ref = {
  cancer: '암',
  brain: '뇌심',
  default: '일반',
};

export const helpergroup_type_ref = {
  carerSupport: '간병인',
  housekeeper: '가사도우미',
  rehabilitationExercise: '재활 운동 지원',
  companion: '동행인 지원',
};

export const helper_avg_type_ref = {
  applyReg: '신청일자',
  serviceStart: '서비스 희망일(시작일)',
};

export const p_apply_add_type_ref = {
  Atype: '입원 시 간병인 지원, 가사도우미 지원',
  Btype: '대면심리상담, 상급병원 간호사 동행',
  Ctype: '재활 운동 지원, 동행인 지원(시니어)',
};

export const p_apply_category2_array_ref = {
  housekeeper: '가사도우미 지원',
  carerSupport: '입원 시 간병인 지원',
  psychologyCounsel: '대면심리상담',
  nurseAccompany: '상급병원 간호사 동행',
  rehabilitationExercise: '재활 운동 지원',
  companion: '동행인 지원',
};
export const evaluation_ref = {
  carerSupport: [
    {
      label: '1.친절도',
      desc: '성심성의껏 간병하고 친절하였나요?',
    },
    {
      label: '2.청결도',
      desc: '정해진 복장 착용과 위생상태가 청결했나요?',
    },
    {
      label: '3.전문성',
      desc: '욕창이나 낙상이 없이 환자 및 간호사의 지시를 성실히 잘 이행하였나요?',
    },
    {
      label: '4.시간 준수',
      desc: '간병기간 중 환자곁을 이탈하지 않고 간병시간을 잘 준수하였나요?',
    },
    {
      label: '5.대화 장애',
      desc: '간병 동안 간병인과 대화 시 불편함을 느끼지 못하고 치료에 도움이 되는 의사소통을 하셨나요?',
    },
  ],
  carerSupport_new: [
    {
      label: '1. 서비스질',
      desc: '간병인의 서비스에 얼마나 만족 하셨습니까?',
    },
    {
      label: '2. 태도',
      desc: '간병인의 태도에 얼마나 만족 하셨습니까?',
    },
    {
      label: '3. 친절도',
      desc: '간병인의 친절도에 얼마나 만족 하셨습니까?',
    },
    {
      label: '4. 용모',
      desc: '간병인의 복장과 위생상태가 청결 했나요?',
    },
    {
      label: '5. 의사 소통',
      desc: '간병인과 대화 시 원활한 의사소통을 하셨나요?',
    },
  ],
  housekeeper: [
    {
      label: '1.친절도',
      desc: '성심성의껏 고객님께 친절하였나요?',
    },
    {
      label: '2.청결도',
      desc: '정해진 복장 착용과 위생상태가 청결했나요?',
    },
    {
      label: '3.전문성',
      desc: '동선에 대한 협의와 함께 고객의 지시를 성실히 잘 이행하였나요?',
    },
    {
      label: '4.시간준수',
      desc: '작업 중 장소의 이탈없이 가사 청소의 시간을 잘 준수하였나요?',
    },
  ],
  housekeeper_new: [
    {
      label: '1. 서비스질',
      desc: '가사도우미의 서비스에 얼마나 만족 하셨습니까?',
    },
    {
      label: '2. 태도',
      desc: '가사도우미의 태도에 얼마나 만족 하셨습니까?',
    },
    {
      label: '3. 친절도',
      desc: '가사도우미의 친절도에 얼마나 만족 하셨습니까?',
    },
    {
      label: '4. 용모',
      desc: '가사도우미의 복장과 위생상태가 청결 했나요?',
    },
    {
      label: '5. 의사 소통',
      desc: '가사도우미와 대화 시 원활한 의사소통을 하셨나요?',
    },
  ],
  nurseAccompany: [
    {
      label: '1.친절도',
      desc: '성심성의껏 고객님께 친절하였나요?',
    },
    {
      label: '2.청결도',
      desc: '정해진 복장 착용과 위생상태가 청결했나요?',
    },
    {
      label: '3.전문성',
      desc: '동선에 대한 협의와 함께 고객의 지시를 성실히 잘 이행하였나요?',
    },
    {
      label: '4.시간준수',
      desc: '작업 중 장소의 이탈없이 가사 청소의 시간을 잘 준수하였나요?',
    },
  ],
  rehabilitationExercise: [
    {
      label: '1.서비스질',
      desc: '재활운동 서비스에 얼마나 만족하셨습니까?',
    },
    {
      label: '2.태도',
      desc: '재활운동 전문가의 태도에 얼마나 만족하셨습니까?',
    },
    {
      label: '3.친절도',
      desc: '재활운동 전문가의 친절도에 얼마나 만족하셨습니까?',
    },
    {
      label: '4.용모',
      desc: '재활운동 전문가의 복장과 위생상태가 청결했나요?',
    },
    {
      label: '5.의사소통',
      desc: '재활운동 전문가와 대화시 원활한 의사소통을 하셨나요?',
    },
  ],
  companion: [
    {
      label: '1.서비스질',
      desc: '동행인 지원 서비스에 얼마나 만족하셨습니까?',
    },
    {
      label: '2.태도',
      desc: '동행인의 태도에 얼마나 만족하셨습니까?',
    },
    {
      label: '3.친절도',
      desc: '동행인의 친절도에 얼마나 만족하셨습니까?',
    },
    {
      label: '4.용모',
      desc: '동행인의 복장과 위생상태가 청결했나요?',
    },
    {
      label: '5.의사소통',
      desc: '동행인과 대화시 원활한 의사소통을 하셨나요?',
    },
  ],
};

export const crm_return_type_ref = {
  NT: '임시회원 (서비스 가능)',
  T: '임시회원 (서비스 가능)',
  C: '기회원 (서비스 가능)',
  NA: '서비스 불가',
};

export const cancer_category_ref = {
  cancer: '암',
  brain: '뇌',
  heart: '심',
};

export const month_ref = {
  1: '1월',
  2: '2월',
  3: '3월',
  4: '4월',
  5: '5월',
  6: '6월',
  7: '7월',
  8: '8월',
  9: '9월',
  10: '10월',
  11: '11월',
  12: '12월',
};

export const report_type_ref = {
  NOW: '현재평가 보고서',
  MIDDLE: '중간평가 보고서',
  LAST: '최종평가 보고서',
  COUNSELING: '상담 확인',
};

export const report_state_ref = {
  all: '전체',
  done: '평가 완료',
  notDone: '평가 미완료',
};

export const report_date_type_ref = {
  all: '전체',
  regDt: '보고서 문진일',
  endDt: '보고서 작성일',
};

export const program_type_cd_ref = {
  all: '전체',
  R: '재발방지',
  P: '질환예방',
};

export const success_result_ref = {
  all: '전체',
  true: '성공',
  false: '실패',
};

export const subscribe_goods_ref = {
  // all: '전체',
  A: '이스트코퍼레이션',
  B: '키튼플래닛',
};

export const subscribe_cancel_ref = {
  all: '전체',
  false: '신청',
  true: '취소',
};

export const sort_ref = {
  asc: '오름차순',
  desc: '내림차순',
};

// 관리자변경로그 타입구분
export const mod_log_type_ref = {
  1: '신규 계정 생성',
  2: '정보 변경',
  3: '권한 변경',
  4: '계정 잠김',
  5: '계정 잠김 해제',
};

// 프리미엄 서비스 helper 타입구분
export const premium_helper_type_ref = {
  carerSupport: '간병인',
  housekeeper: '가사도우미',
  rehabilitationExercise: '재활운동 전문가',
  companion: '동행인',
};
