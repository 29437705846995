import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 항암일지 전체 정보 조회
 * @param {*} setCancerLogs client에서 전달하는 React.Dispatch
 * @param {*} cancerLogs client에서 전달하는 React.Dispatch
 */
export async function getCancerLsList(
  setCancerLogs,
  cancerLogs,
  goPage,
  setCurrentPage,
  searchState,
  setLoading
) {
  try {
    const result = await axios
      .post(`${baseUrl}/health/cancerLogs/getList`, {
        goPage: goPage,
        pagingState: cancerLogs.pagingState,
        searchState: searchState,
      })
      .then((res) => {
        setCancerLogs(res.data);
        setCurrentPage(1);
        setLoading(false);
      });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 항암일지의 상세 정보 조회
 * @param {string} member_id 보고자 하는 항암일지를 작성한 회원의 id
 * @param {string} category 보고자 하는 항암일지의 분류
 */
export async function getCancerLDetail(member_id, category) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/health/cancerLogs/getDetail/${member_id}/${category}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 항암일지의 정보 수정
 * @param {object} cancerLogList 수정하고자 하는 항암일지의 정보 및 수정할 값
 */
export async function editCancerLDetail(cancerLogList) {
  try {
    const cancerList = cancerLogList.lists;
    cancerList.forEach((v, i) => {
      Object.keys(cancerList[i]).forEach((key) => {
        // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
        if (
          cancerList[i][key] === undefined ||
          cancerList[i][key].length === 0
        ) {
          // 필수 파람(인자)가 누락된 경우, 에러 발생
          if (
            key === 'document_id' ||
            key === 'symptoms' ||
            (cancerList[i][key].length !== 0 && key === 'symptoms_etc')
          ) {
            throw new Error("The param must have a '" + key + "'.");
          }
          delete cancerList[i][key];
        }
      });
    });

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/health/cancerLogs/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        cancerList,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 신규 항암일지 작성
 * @param {object} cancerL 새로 작성할 항암일지의 정보
 */
export async function insertCancerL(cancerL) {
  try {
    Object.keys(cancerL).forEach((v) => {
      // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
      if (cancerL[v] === undefined || cancerL[v].length === 0) {
        delete cancerL[v];
        // 필수 파람(인자)가 없는 경우, 에러 발생
      } else if (
        (v === 'company_id' ||
          v === 'category' ||
          v === 'crm_nm' ||
          v === 'crm_phone' ||
          v === 'product_id' ||
          v === 'local_dt' ||
          v === 'member_id' ||
          v === 'member_doc_id' ||
          v === 'symptoms' ||
          v === 'symptoms_etc') &&
        cancerL[v] === undefined
      ) {
        throw new Error('Param is invalid.');
      }
    });

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/health/cancerLogs/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        cancerL,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 회원의 보험 상품 관련 정보 조회
 * @param {string} company_id 보험사 코드
 * @param {string} product_id 앱 코드 및 보험상품 코드
 * @param {string} nm 이름
 * @param {string} phone 전화번호
 */
export async function checkMemberServiceDetail(
  company_id,
  product_id,
  nm,
  phone
) {
  try {
    // 필수 파람(인자)가 없다면, 에러 발생
    if (
      company_id === undefined ||
      company_id.length === 0 ||
      product_id === undefined ||
      product_id.length === 0 ||
      nm === undefined ||
      nm.length === 0 ||
      phone === undefined ||
      phone.length === 0
    ) {
      throw new Error('Please check params.');
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/health/cancerLogs/checkMemberService`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        company_id,
        product_id,
        nm,
        phone,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 항암일지 삭제
 * @param {string} document_id 삭제하고자 하는 항암일지의 document id
 */
export async function deleteCancerL(document_id) {
  try {
    // 인자가 선언되지 않았거나 길이가 0인 경우, 에러 발생
    if (document_id === undefined || document_id.length === 0) {
      throw new Error('Document ID is invalid.');
    }
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/health/cancerLogs/delete/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import { cancer_category_ref } from 'lib/dataRef';
// import firestore from 'store/firestore';

// let size;
// /**
//  * 항암일지 총 건수 조회
//  */
// export function getCancerLsSize() {
//   return size;
// }

// /**
//  * 항암일지 전체 정보 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getCancerLsList(app_code) {
//   try {
//     const clsRef = await firestore.collection('cust_CANCERS')
//       .orderBy('reg_dt', 'desc').get();
//     let product_id = String(clsRef.docs[0].data().product_id).split('_');
//     const lists = [{
//       company_id: clsRef.docs[0].data().company_id,
//       app_code: product_id[0],
//       goods_code: product_id[1],
//       product_id: clsRef.docs[0].data().product_id,
//       category: clsRef.docs[0].data().category ? clsRef.docs[0].data().category : 'cancer',
//       crm_nm: clsRef.docs[0].data().crm_nm,
//       crm_phone: clsRef.docs[0].data().crm_phone,
//       member_id: clsRef.docs[0].data().member_id,
//       count: 0,
//       local_dt: clsRef.docs[0].data().local_dt,
//     }];
//     clsRef.forEach((doc) => {
//       let check = false;
//       for (let i = 0; i < lists.length; i++) {
//         if (lists[i].member_id === doc.data().member_id
//           && ((doc.data().category === undefined || lists[i].category === undefined)
//           || (doc.data().category !== undefined && lists[i].category !== undefined
//           && doc.data().category === lists[i].category))) {
//           lists[i].count++;
//           lists[i].local_dt = lists[i].local_dt > doc.data().local_dt ? lists[i].local_dt : doc.data().local_dt;
//           check = true;
//           break;
//         }else if(lists[i].member_id === doc.data().member_id
//         && ((doc.data().category === null || lists[i].category === null)
//         || (doc.data().category !== null && lists[i].category !== null
//         && doc.data().category === lists[i].category))){
//           // 하나손보만 category = null
//           lists[i].count++;
//           lists[i].local_dt = lists[i].local_dt > doc.data().local_dt ? lists[i].local_dt : doc.data().local_dt;
//           check = true;
//           break;
//         }
//       }
//       if (!check) {
//         product_id = String(doc.data().product_id).split('_');
//         lists.push({
//           company_id: doc.data().company_id,
//           app_code: product_id[0],
//           goods_code: product_id[1],
//           product_id: doc.data().product_id,
//           category: doc.data().category ? doc.data().category : 'cancer',
//           crm_nm: doc.data().crm_nm,
//           crm_phone: doc.data().crm_phone,
//           member_id: doc.data().member_id,
//           count: 1,
//           local_dt: doc.data().local_dt,
//         });
//       }
//     });
//     size = lists.length;
//     return lists;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 보험사의 전체 회원 정보 조회
//  * @param {object} company_id 보험사 코드
//  */
// export async function getMembersList(company_id) {
//   try {
//     let memsRef;
//     const lists = [];
//     // 보험사 코드가 입력된 경우, 조건문에 활용하여 회원 정보 검색
//     if (company_id === undefined) {
//       memsRef = await firestore.collection('cust_MEMBERS')
//         .where('company_id', '==', company_id)
//         .where('del_yn', '==', false)
//         .orderBy('nm', 'desc')
//         .get();
//     // 보험사 코드가 입력되지 않은 경우, 보험사 코드 관련 조건문은 쓰지 않고 회원 정보 검색
//     } else {
//       memsRef = await firestore.collection('cust_MEMBERS')
//         .where('del_yn', '==', false)
//         .orderBy('nm', 'desc')
//         .get();
//     }
//     memsRef.docs.forEach((doc) => {
//       lists.push({
//         document_id: doc.data().document_id,
//         company_id: doc.data().company_id,
//         product_id: doc.data().product_id,
//         id: doc.data().id,
//         nm: doc.data().nm,
//         phone: doc.data().phone,
//       });
//     });

//     return lists;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 항암일지의 상세 정보 조회
//  * @param {string} member_id 보고자 하는 항암일지를 작성한 회원의 id
//  * @param {string} category 보고자 하는 항암일지의 분류
//  */
// export async function getCancerLDetail(member_id, category) {
//   try {
//     let clsRef;
//     // 분류값이 전달되지 않은 경우, 분류에 대한 조건문은 쓰지 않고 조회
//     if (category === undefined) {
//       clsRef = await firestore.collection('cust_CANCERS')
//         .where('member_id', '==', member_id)
//         .where('del_yn', '==', false)
//         .orderBy('local_dt', 'desc')
//         .get();
//     // 분류값이 전달된 경우, 조건문에 이를 활용하여 조회
//     } else {
//       clsRef = await firestore.collection('cust_CANCERS')
//         .where('member_id', '==', member_id)
//         .where('category', '==', category)
//         .where('del_yn', '==', false)
//         .orderBy('local_dt', 'desc')
//         .get();
//     }
//     const lists = [];
//     clsRef.docs.forEach((doc) => {
//       lists.push({
//         document_id: doc.data().document_id,
//         symptoms: doc.data().symptoms,
//         symptoms_etc: doc.data().symptoms_etc,
//         reg_dt: doc.data().reg_dt,
//         mod_dt: doc.data().mod_dt,
//         del_yn: doc.data().del_yn,
//         del_dt: doc.data().del_dt,
//         local_dt: doc.data().local_dt,
//       });
//     });
//     return {
//       company_id: clsRef.docs[0].data().company_id,
//       category: clsRef.docs[0].data().category ? clsRef.docs[0].data().category : 'cancer',
//       member_id: clsRef.docs[0].data().member_id,
//       crm_phone: clsRef.docs[0].data().crm_phone,
//       crm_nm: clsRef.docs[0].data().crm_nm,
//       lists,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 항암일지의 정보 수정
//  * @param {object} cancerList 수정하고자 하는 항암일지의 정보 및 수정할 값
//  */
// export async function editCancerLDetail(cancerList) {
//   try {
//     cancerList.forEach((v, i) => {
//       Object.keys(cancerList[i]).forEach((key) => {
//         // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//         if (cancerList[i][key] === undefined || cancerList[i][key].length === 0) {
//           // 필수 파람(인자)가 누락된 경우, 에러 발생
//           if (key === 'document_id' || key === 'symptoms'
//             || (cancerList[i][key].length !== 0 && key === 'symptoms_etc')) {
//             throw new Error('The param must have a \'' + key + '\'.');
//           }
//           delete cancerList[i][key];
//         }
//       });
//     });

//     const now = Math.floor(new Date().getTime());
//     cancerList.forEach(async (v, i) => {
//       cancerList[i].mod_dt = now;
//       await firestore.collection('cust_CANCERS').doc(cancerList[i].document_id)
//         .set(cancerList[i], { merge: true });
//     });

//     return {
//       result: 'success',
//       message: 'The charts(cancer lists) are updated.',
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 신규 항암일지 작성
//  * @param {object} cancerL 새로 작성할 항암일지의 정보
//  */
// export async function insertCancerL(cancerL) {
//   try {
//     Object.keys(cancerL).forEach((v) => {
//       // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//       if (cancerL[v] === undefined || cancerL[v].length === 0) {
//         delete cancerL[v];
//       // 필수 파람(인자)가 없는 경우, 에러 발생
//       } else if ((v === 'company_id' || v === 'category' || v === 'crm_nm' || v === 'crm_phone'
//         || v === 'product_id' || v === 'local_dt' || v === 'member_id' || v === 'member_doc_id'
//         || v === 'symptoms' || v === 'symptoms_etc')
//         && cancerL[v] === undefined) {
//         throw new Error('Param is invalid.');
//       }
//     });

//     const now = Math.floor(new Date().getTime());
//     cancerL.reg_dt = now;
//     cancerL.mod_dt = now;
//     cancerL.del_dt = 0;
//     cancerL.del_yn = false;

//     await firestore.collection('cust_CANCERS').add(cancerL)
//       .then(async (doc) => {
//         await firestore.collection('cust_CANCERS').doc(doc.id)
//           .set({ document_id: doc.id }, { merge: true });
//       }).catch((err) => {
//         console.error(err);
//       });

//     return { result: 'success', message: 'Cancer log is inserted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 회원의 보험 상품 관련 정보 조회
//  * @param {string} company_id 보험사 코드
//  * @param {string} product_id 앱 코드 및 보험상품 코드
//  * @param {string} nm 이름
//  * @param {string} phone 전화번호
//  */
// export async function checkMemberServiceDetail(company_id, product_id, nm, phone) {
//   try {
//     // 필수 파람(인자)가 없다면, 에러 발생
//     if (company_id === undefined || company_id.length === 0 || product_id === undefined
//       || product_id.length === 0 || nm === undefined
//       || nm.length === 0 || phone === undefined || phone.length === 0) {
//       throw new Error('Please check params.');
//     }
//     // 회원 정보 조회
//     const memsRef = await firestore.collection('cust_MEMBERS')
//       .where('company_id', '==', company_id)
//       .where('product_id', '==', product_id)
//       .where('nm', '==', nm)
//       .where('phone', '==', phone)
//       .where('del_yn', '==', false)
//       .get();
//     const returnValue = { category: {}, member: {} };
//     // 조회 결과가 0건인 경우, 에러 발생
//     if (memsRef.size === 0) {
//       throw new Error('There is no member.');
//     // 조회 결과가 1건인 경우
//     } else if (memsRef.size === 1) {
//       // 회원 정보에서 보험 상품 정보가 있는 경우
//       if (memsRef.docs[0].data().service_detail !== undefined) {
//         // 리턴될 변수에 보험 상품 정보 저장
//         Object.keys(memsRef.docs[0].data().service_detail).forEach((v) => {
//           returnValue.category[v] = cancer_category_ref[v];
//           if (v === 'brain') {
//             returnValue.category.heart = '심';
//           }
//         });
//       } else {
//         returnValue.category.cancer = '암';
//       }
//       returnValue.member.member_id = memsRef.docs[0].data().id;
//       returnValue.member.member_doc_id = memsRef.docs[0].data().document_id;
//     // 조회 결과가 2건 이상인 경우, 에러 발생
//     } else {
//       throw new Error('There are too many members.');
//     }
//     return returnValue;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 회원의 항암일지 조회 결과 유무
//  * @param {object} member 특정 회원의 정보
//  * @param {string} category 항암일지의 분류
//  */
// export async function checkMemberCancerL(member, category) {
//   try {
//     // 필수 파람(인자)가 없는 경우, 리턴
//     if (member.company_id === undefined || member.product_id === undefined
//       || member.crm_nm === undefined || member.crm_phone === undefined || member.member_id === undefined) {
//       return { result: 'fail', message: 'Member param is wrong.' };
//     }
//     const clsRef = await firestore.collection('cust_CANCERS')
//       .where('company_id', '==', member.company_id)
//       .where('product_id', '==', member.product_id)
//       .where('crm_nm', '==', member.crm_nm)
//       .where('crm_phone', '==', member.crm_phone)
//       .where('member_id', '==', member.member_id)
//       .get();

//     let findCl = false;
//     clsRef.docs.forEach((doc) => {
//       // 인자로 넘어온 분류와 항암일지 컬렉션을 조회하여 나온 분류가 일치하는 경우, 조회 결과가 있음을 표시
//       if (category !== undefined && doc.data().category !== undefined && doc.data().category === category) {
//         findCl = true;
//       }
//     });
//     if (findCl) {
//       return { result: 'fail', message: 'Cancer log is already defined.' };
//     } else {
//       return { result: 'success', message: 'There is no Cancer log.' };
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 항암일지 삭제
//  * @param {string} document_id 삭제하고자 하는 항암일지의 document id
//  */
// export async function deleteCancerL(document_id) {
//   try {
//     // 인자가 선언되지 않았거나 길이가 0인 경우, 에러 발생
//     if (document_id === undefined || document_id.length === 0) {
//       throw new Error('Document ID is invalid.');
//     }
//     await firestore.collection('cust_CANCERS').doc(document_id)
//       .delete();

//     return { result: 'success', message: 'The chart(cancer list) is deleted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }
