import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * hash 전체 정보 조회
 */
export async function getHashList(setHash, hash,  goPage, setCurrentPage, searchState, setLoading) {
  try {
    const result = await axios.post(
      `${baseUrl}/hash/getList`,{
        goPage: goPage,
        pagingState: hash.pagingState,
        searchState: searchState
      }).then((res) => {
        setHash(res.data);
        setCurrentPage(1);  
        setLoading(false);
      });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 hash의 정보 조회
 * @param {string} document_id 조회하고자 하는 행사정보의 document id
 */
export async function getHashDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/hash/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);
    
    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 hash의 정보 수정
 * @param {object} hash 수정하고자 하는 행사정보의 정보 및 수정 내용
 */
export async function editHashDetail(hash) {
  try {
    // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(hash).forEach((v) => {
      if (hash[v] === undefined || hash[v].length === 0) {
        delete hash[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/hash/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        hash
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 새로운 hash 등록
 * @param {object} hash 등록하고자 하는 행사정보의 정보
 */
export async function postHash(hash) {
  try {
    // 키가 contents 혹은 title이 아니면서, 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(hash).forEach((v) => {
      if ((v !== 'company_id' && v !== 'os' && v !== 'product_id' && v !== 'hash')
        || (hash[v] === undefined || hash[v].length === 0)) {
        delete hash[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/hash/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        hash
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}