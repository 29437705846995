import React, { useState, useEffect } from 'react';
import { getTreatmentDetail } from 'controllers/Contents/treatments';
import { TreatmentsDetailTable } from 'components/Common';
import { Spin } from 'antd';

const PostTreatmentsDetailContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [treatmentDetail, setTreatmentDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setTreatmentDetail({});
      setLoading(true);
      const data = getTreatmentDetail(document_id);
      data.then((d) => {
        setTreatmentDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <TreatmentsDetailTable treatmentDetail={treatmentDetail} setTreatmentDetail={setTreatmentDetail}/>
      </Spin>
    :
      <TreatmentsDetailTable treatmentDetail={treatmentDetail} setTreatmentDetail={setTreatmentDetail} />
  );
};

export default PostTreatmentsDetailContainer;
