import { getCookie } from 'lib/cookie';
import axios from 'axios';
import { baseUrl } from 'lib/url';

export async function getHealthyBodySearchList(setHealthyBody, setCount, setLoading) {
  try {
    const res = await axios.get(
      `${baseUrl}/healthbody/getList`).then((res) => {
        setHealthyBody(res.data.healthyBody);
        setCount(res.data.count);
        setLoading(false);
      }).catch((err) => {
        console.error(err)
      });

    return res;
  } catch (e) {
    console.log(e);
  }
}

export async function getHealthyBodyDetail(document_id, type, program_cd, props) {
  try {
    return await axios.post(
      `${baseUrl}/healthbody/getDetail`, {
        document_id: document_id,
        type: type,
        program_cd: program_cd,
        props: props
      }).then((res) => {
        return res.data;
      }).catch((err) =>
        console.error(err)
      );

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

export async function addTrainerOpinion(param) {
  try{
    param.trainer_id = getCookie('session');
    return await axios.post(
      `${baseUrl}/healthbody/addOpinion`, {
        param
      }).then((res) => {
        alert('등록되었습니다.');
      }).catch((err) =>
        console.error(err)
      );
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }

}

export async function insertCounselingAnswer(counseling) {
  try {
    return await axios.post(
      `${baseUrl}/healthbody/addAnswer`, {
        counseling
      }).then((res) => {
        alert('등록되었습니다.');
      }).catch((err) =>
        console.error(err)
      );
  } catch(err) {
    console.log(err);
    return { result: 'error: \n', message: err };
  }
}

// import dayjs from 'dayjs';
// import { getCookie } from 'lib/cookie';
// import { getFilteredWeeklyExerciseDay_ref } from 'lib/filteredRef';
// import firestore from 'store/firestore';
// // import getDocs from "firebase/compat/firestore";
// import { ConsoleSqlOutlined } from '@ant-design/icons';

// export async function getHealthyBodySize(app_code) {
//   try {
//     const acsRef = await firestore.collection('cust_HB_MSTR')
//       .orderBy('reg_dt', 'desc').get();
//     return acsRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// export async function getHealthyBodyList(setHealthyBody, setCount, setCounselingArr) {
//   try {
//     const completionCounselingArr = [];
//     // 상담 완료 건수 조회 하기 위해 선언
//     // 각 row의 document_id 가지고 cust_HB_COUNSELING 의 program_seq와 같은 것들 중 confirm_yn 이 Y 인 것들 조회해서 그 length 가 상담 완료 갯수
//     const completionCounseling = await firestore.collection('cust_HB_COUNSELING').where('confirm_yn', '==', 'Y').get();
//     completionCounseling.forEach((row) => {
//       completionCounselingArr.push({
//         id: row.data().program_seq,
//       });
//     });
//     const acsRef = await firestore.collection('cust_HB_MSTR')
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const acDocs = [];
//         const changes = snap.docChanges();

//         changes.forEach((change) => {
//           if (change.type === 'added') {
//             if (changes.length > 1) {
//               // 맨 처음 리스트가 불려졌을 때
//               if (change.doc.data().status !== 8) {
//                 const product_id = String(change.doc.data().product_id).split('_');
//                 acDocs.push({
//                   no: (acDocs.length + 2) - 1,
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: change.doc.data().product_id,
//                   document_id: change.doc.data().document_id,
//                   company_id: change.doc.data().company_id,
//                   program_cd: change.doc.data().program_cd,
//                   program_nm: change.doc.data().program_nm,
//                   nm: change.doc.data().nm,
//                   gender: change.doc.data().gender,
//                   birth_dt: change.doc.data().birth_dt || '-',
//                   start_dt: change.doc.data().start_dt,
//                   end_dt: change.doc.data().end_dt,
//                   reg_dt: change.doc.data().reg_dt,
//                   middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                   middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                   last_report_q_dt: change.doc.data().last_report_q_dt,
//                   last_report_a_dt: change.doc.data().last_report_a_dt,
//                   total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                   total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                   body_type_cd: change.doc.data().body_type_cd,
//                   body_type_nm: change.doc.data().body_type_nm,
//                 });
//               }
//             } else {
//               snap.forEach((doc) => {
//                 // 뭔가 추가가 되었을 때
//                 if (doc.data().status !== 8) {
//                   const product_id = String(change.doc.data().product_id).split('_');
//                   acDocs.push({
//                     no: (acDocs.length + 2) - 1,
//                     app_code: product_id[0],
//                     goods_code: product_id[1],
//                     product_id: doc.data().product_id,
//                     document_id: doc.data().document_id,
//                     company_id: doc.data().company_id,
//                     program_cd: doc.data().program_cd,
//                     program_nm: doc.data().program_nm,
//                     nm: doc.data().nm,
//                     gender: doc.data().gender,
//                     birth_dt: doc.data().birth_dt || '-',
//                     start_dt: doc.data().start_dt,
//                     end_dt: doc.data().end_dt,
//                     reg_dt: doc.data().reg_dt,
//                     middle_report_q_dt: doc.data().middle_report_q_dt,
//                     middle_report_a_dt: doc.data().middle_report_a_dt,
//                     last_report_q_dt: doc.data().last_report_q_dt,
//                     last_report_a_dt: doc.data().last_report_a_dt,
//                     total_exercise_cnt: doc.data().total_exercise_cnt !== 0 ? doc.data().total_exercise_cnt : '-',
//                     total_counseling_cnt: doc.data().total_counseling_cnt,
//                     body_type_cd: doc.data().body_type_cd,
//                     body_type_nm: doc.data().body_type_nm,
//                   });
//                 }
//               });
//             }
//           }

//           if (change.type === 'modified') {
//             snap.forEach((doc) => {
//               if (doc.data().status !== 8) {
//                 const product_id = String(change.doc.data().product_id).split('_');
//                 acDocs.push({
//                   no: (acDocs.length + 2) - 1,
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: doc.data().product_id,
//                   document_id: doc.data().document_id,
//                   company_id: doc.data().company_id,
//                   program_cd: doc.data().program_cd,
//                   program_nm: doc.data().program_nm,
//                   nm: doc.data().nm,
//                   gender: doc.data().gender,
//                   birth_dt: doc.data().birth_dt || '-',
//                   start_dt: doc.data().start_dt,
//                   end_dt: doc.data().end_dt,
//                   reg_dt: doc.data().reg_dt,
//                   middle_report_q_dt: doc.data().middle_report_q_dt,
//                   middle_report_a_dt: doc.data().middle_report_a_dt,
//                   last_report_q_dt: doc.data().last_report_q_dt,
//                   last_report_a_dt: doc.data().last_report_a_dt,
//                   total_exercise_cnt: doc.data().total_exercise_cnt !== 0 ? doc.data().total_exercise_cnt : '-',
//                   total_counseling_cnt: doc.data().total_counseling_cnt,
//                   body_type_cd: doc.data().body_type_cd,
//                   body_type_nm: doc.data().body_type_nm,
//                 });
//               }
//             });
//           }

//           if (change.type === 'removed') {
//             snap.forEach((doc) => {
//               if (doc.data().status !== 8) {
//                 const product_id = String(change.doc.data().product_id).split('_');
//                 acDocs.push({
//                   no: (acDocs.length + 2) - 1,
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: doc.data().product_id,
//                   document_id: doc.data().document_id,
//                   company_id: doc.data().company_id,
//                   program_cd: doc.data().program_cd,
//                   program_nm: doc.data().program_nm,
//                   nm: doc.data().nm,
//                   gender: doc.data().gender,
//                   birth_dt: doc.data().birth_dt || '-',
//                   start_dt: doc.data().start_dt,
//                   end_dt: doc.data().end_dt,
//                   reg_dt: doc.data().reg_dt,
//                   middle_report_q_dt: doc.data().middle_report_q_dt,
//                   middle_report_a_dt: doc.data().middle_report_a_dt,
//                   last_report_q_dt: doc.data().last_report_q_dt,
//                   last_report_a_dt: doc.data().last_report_a_dt,
//                   total_exercise_cnt: doc.data().total_exercise_cnt !== 0 ? doc.data().total_exercise_cnt : '-',
//                   total_counseling_cnt: doc.data().total_counseling_cnt,
//                   body_type_cd: doc.data().body_type_cd,
//                   body_type_nm: doc.data().body_type_nm,
//                 });
//               }
//             });
//           }
//         });
//         setHealthyBody(acDocs);
//         setCount(acDocs.length);
//         setCounselingArr(completionCounselingArr);
//       });

//     return acsRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// export async function getHealthyBodySearchList(setHealthyBody, setCount, type, isState, dateType, start, end) {

//   start = dayjs(start).startOf('D').format('YYYY-MM-DD HH:mm:ss');
//   end = dayjs(end).endOf('D').format('YYYY-MM-DD HH:mm:ss');

//   let startDate = dayjs(start).valueOf();
//   let endDate = dayjs(end).valueOf();

//   try {
//     const acDocs = [];
//     // 검색조건 초기화
//     if(type === '') {
//      console.log(' 검색조건 초기화 선택');
//      const acsRef = await firestore.collection('cust_HB_MSTR')
//      .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//        const changes = snap.docChanges();
//        changes.forEach((change) => {

//          const product_id = String(change.doc.data().product_id).split('_');
//          acDocs.push({
//            no: (acDocs.length + 2) - 1,
//            app_code: product_id[0],
//            goods_code: product_id[1],
//            product_id: change.doc.data().product_id,
//            document_id: change.doc.data().document_id,
//            company_id: change.doc.data().company_id,
//            program_cd: change.doc.data().program_cd,
//            program_nm: change.doc.data().program_nm,
//            nm: change.doc.data().nm,
//            gender: change.doc.data().gender,
//            birth_dt: change.doc.data().birth_dt || '-',
//            start_dt: change.doc.data().start_dt,
//            end_dt: change.doc.data().end_dt,
//            reg_dt: change.doc.data().reg_dt,
//            middle_report_q_dt: change.doc.data().middle_report_q_dt,
//            middle_report_a_dt: change.doc.data().middle_report_a_dt,
//            last_report_q_dt: change.doc.data().last_report_q_dt,
//            last_report_a_dt: change.doc.data().last_report_a_dt,
//            total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//            total_counseling_cnt: change.doc.data().total_counseling_cnt,
//            body_type_cd: change.doc.data().body_type_cd,
//            body_type_nm: change.doc.data().body_type_nm,
//          });
//        });
//        setHealthyBody(acDocs);
//        setCount(acDocs.length);
//      });
//      return acsRef;
//     }

//     // 현재 보고서 선택 검색
//     if(type === 'NOW') {
//       console.log(' 현재 보고서 선택 ');
//       const acsRef = await firestore.collection('cust_HB_MSTR')
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//           const product_id = String(change.doc.data().product_id).split('_');
//           acDocs.push({
//             no: (acDocs.length + 2) - 1,
//             app_code: product_id[0],
//             goods_code: product_id[1],
//             product_id: change.doc.data().product_id,
//             document_id: change.doc.data().document_id,
//             company_id: change.doc.data().company_id,
//             program_cd: change.doc.data().program_cd,
//             program_nm: change.doc.data().program_nm,
//             nm: change.doc.data().nm,
//             gender: change.doc.data().gender,
//             birth_dt: change.doc.data().birth_dt || '-',
//             start_dt: change.doc.data().start_dt,
//             end_dt: change.doc.data().end_dt,
//             reg_dt: change.doc.data().reg_dt,
//             middle_report_q_dt: change.doc.data().middle_report_q_dt,
//             middle_report_a_dt: change.doc.data().middle_report_a_dt,
//             last_report_q_dt: change.doc.data().last_report_q_dt,
//             last_report_a_dt: change.doc.data().last_report_a_dt,
//             total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//             total_counseling_cnt: change.doc.data().total_counseling_cnt,
//             body_type_cd: change.doc.data().body_type_cd,
//             body_type_nm: change.doc.data().body_type_nm,
//           });
//         });
//         setHealthyBody(acDocs);
//         setCount(acDocs.length);
//       });
//       return acsRef;
//     // 중간 보고서 선택
//     } else if(type === 'MIDDLE') {
//       console.log(' 중간 보고서 선택 ');
//       // 평가 완료
//       if(isState === 'done') {
//         console.log(' 중간 보고서 선택 > 평가완료 ');
//         // 보고서 등록일 선택
//         if(dateType === 'regDt') {
//           console.log(' 중간 보고서 선택 > 평가완료 > 보고서 등록일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_q_dt', '>', startDate)
//           .where('middle_report_q_dt', '<', endDate)
//           .orderBy('middle_report_q_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         // 보고서 평가일 선택
//         } else if(dateType === 'endDt') {
//           console.log(' 중간 보고서 선택 > 평가완료 > 보고서 평가일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_a_dt', '>', startDate)
//           .where('middle_report_a_dt', '<', endDate)
//           .orderBy('middle_report_a_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         // 전체 선택
//         } else {
//           console.log(' 중간 보고서 선택 > 평가완료 > 전체 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_a_dt', '>', startDate)
//           .where('middle_report_a_dt', '<', endDate)
//           .orderBy('middle_report_a_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         }
//       // 평가 미완료
//       } else if(type === 'notDone') {
//         console.log(' 중간 보고서 선택 > 평가 미완료');
//         // 보고서 등록일 선택
//         if(dateType === 'regDt') {
//           console.log(' 중간 보고서 선택 > 평가 미완료 선택 > 보고서 등록일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_q_dt', '>', startDate)
//           .where('middle_report_q_dt', '<', endDate)
//           .orderBy('middle_report_q_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         // 보고서 평가일 선택
//         } else {
//           console.log(' 중간 보고서 선택 > 평가 미완료 선택 > 보고서 평가일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_a_dt', '>', startDate)
//           .where('middle_report_a_dt', '<', endDate)
//           .orderBy('middle_report_a_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         }
//       // 중간 보고서 선택 > 전체 선택
//       } else {
//         console.log(' 중간 보고서 선택 > 전체 선택');
//         if(dateType === 'regDt') {
//           console.log(' 중간 보고서 선택 > 전체 선택 > 보고서 등록일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_q_dt', '>', startDate)
//           .where('middle_report_q_dt', '<', endDate)
//           .orderBy('middle_report_q_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         } else if(dateType === 'endDt') {
//           console.log(' 중간 보고서 선택 > 전체 선택 > 보고서 평가일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_a_dt', '>', startDate)
//           .where('middle_report_a_dt', '<', endDate)
//           .orderBy('middle_report_a_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         // 전체 선택
//         } else {
//           console.log(' 중간 보고서 선택 > 전체 선택 > 전체 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('middle_report_q_dt', '>', startDate)
//           .where('middle_report_q_dt', '<', endDate)
//           .orderBy('middle_report_q_dt', 'desc').onSnapshot((snap) => {

//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         }
//       }
//     // 최종 보고서 선택
//     } else if(type === 'LAST'){
//       console.log(' 최종 보고서 선택');
//       // 평가 완료
//       if(isState === 'done') {
//         console.log(' 최종보고서 선택 > 평가 완료 선택');
//         // 보고서 등록일 선택
//         if(dateType === 'regDt') {
//           console.log(' 최종보고서 선택 > 평가 완료 선택 > 보고서 등록일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('last_report_q_dt', '>', startDate)
//           .where('last_report_q_dt', '<', endDate)
//             .orderBy('last_report_q_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         // 보고서 평가일 선택
//         } else if(dateType === 'endDt') {
//           console.log(' 최종보고서 선택 > 평가 완료 선택 > 보고서 평가일 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('last_report_a_dt', '>', startDate)
//           .where('last_report_a_dt', '<', endDate)
//           .orderBy('last_report_a_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         // 기준일 전체 선택 시 평가일 기준으로 검색 함
//         } else {
//           console.log(' 최종보고서 선택 > 평가 완료 선택 > 전체 선택');
//           const acsRef = await firestore.collection('cust_HB_MSTR')
//           .where('last_report_a_dt', '>', startDate)
//           .where('last_report_a_dt', '<', endDate)
//           .orderBy('last_report_a_dt', 'desc').onSnapshot((snap) => {
//             const changes = snap.docChanges();
//             changes.forEach((change) => {
//               const product_id = String(change.doc.data().product_id).split('_');
//               acDocs.push({
//                 no: (acDocs.length + 2) - 1,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 program_cd: change.doc.data().program_cd,
//                 program_nm: change.doc.data().program_nm,
//                 nm: change.doc.data().nm,
//                 gender: change.doc.data().gender,
//                 birth_dt: change.doc.data().birth_dt || '-',
//                 start_dt: change.doc.data().start_dt,
//                 end_dt: change.doc.data().end_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                 middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                 last_report_q_dt: change.doc.data().last_report_q_dt,
//                 last_report_a_dt: change.doc.data().last_report_a_dt,
//                 total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                 total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                 body_type_cd: change.doc.data().body_type_cd,
//                 body_type_nm: change.doc.data().body_type_nm,
//               });
//             });
//             setHealthyBody(acDocs);
//             setCount(acDocs.length);
//           });
//           return acsRef;
//         }
//       // 평가 미완료 선택 시
//       } else {
//         console.log(' 최종보고서 선택 > 평가 미완료 선택');
//         const acsRef = await firestore.collection('cust_HB_MSTR')
//         .where('last_report_q_dt', '>', startDate)
//         .where('last_report_q_dt', '<', endDate)
//         .orderBy('last_report_q_dt', 'desc').onSnapshot((snap) => {
//           const changes = snap.docChanges();
//           changes.forEach((change) => {
//             const product_id = String(change.doc.data().product_id).split('_');
//             acDocs.push({
//               no: (acDocs.length + 2) - 1,
//               app_code: product_id[0],
//               goods_code: product_id[1],
//               product_id: change.doc.data().product_id,
//               document_id: change.doc.data().document_id,
//               company_id: change.doc.data().company_id,
//               program_cd: change.doc.data().program_cd,
//               program_nm: change.doc.data().program_nm,
//               nm: change.doc.data().nm,
//               gender: change.doc.data().gender,
//               birth_dt: change.doc.data().birth_dt || '-',
//               start_dt: change.doc.data().start_dt,
//               end_dt: change.doc.data().end_dt,
//               reg_dt: change.doc.data().reg_dt,
//               middle_report_q_dt: change.doc.data().middle_report_q_dt,
//               middle_report_a_dt: change.doc.data().middle_report_a_dt,
//               last_report_q_dt: change.doc.data().last_report_q_dt,
//               last_report_a_dt: change.doc.data().last_report_a_dt,
//               total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//               total_counseling_cnt: change.doc.data().total_counseling_cnt,
//               body_type_cd: change.doc.data().body_type_cd,
//               body_type_nm: change.doc.data().body_type_nm,
//             });
//           });
//           setHealthyBody(acDocs);
//           setCount(acDocs.length);
//         });
//         return acsRef;
//       }
//     } else if(type === 'COUNSELING') {
//       // 검색조건 상담확인 일 경우
//       console.log(' 상담 확인 선택');

//       let hbMstrArr = [];
//       let completionIdArr = [];
//       let data = [];

//       const hbMstr = await firestore.collection('cust_HB_MSTR')
//       .where('reg_dt', '>', startDate)
//       .where('reg_dt', '<', endDate)
//       .orderBy('reg_dt','desc')
//       .onSnapshot( async (snap) => {
//         const changes = snap.docChanges();
//         changes.forEach((row) => {
//           if(row.doc.data().total_counseling_cnt > 0) {
//             hbMstrArr.push({
//               id: row.doc.data().document_id,
//               totalCnt: row.doc.data().total_counseling_cnt,
//               currCnt: 0,
//             })
//           }
//         });

//         if(isState === 'done' || isState === 'notDone') {
//           // 완료 / 미완료
//           const hbCounseling = await firestore.collection('cust_HB_COUNSELING')
//           .onSnapshot( async (snap) => {
//             const counselingData = snap.docChanges();
//             counselingData.forEach((row) => {
//               hbMstrArr.forEach((row2, index) => {
//                 completionIdArr.push({
//                   id: row.doc.data().program_seq,
//                   done: 'N'
//                 });

//                 if(row.doc.data().program_seq === row2.id && row.doc.data().confirm_yn === 'Y') {
//                   row2['currCnt']++;

//                   if(row2.totalCnt === row2.currCnt) {
//                     completionIdArr.push({
//                       id: row.doc.data().program_seq,
//                       done: 'Y'
//                     });
//                   }
//                 }
//               });
//             });

//             changes.forEach((change) => {
//               let ctu = false;
//               for(let i=0; i<completionIdArr.length; i++) {
//                 let comId = completionIdArr[i].id;
//                 let comDone = completionIdArr[i].done;
//                 if(isState === 'done') {
//                   if(comId === change.doc.data().document_id && comDone === 'Y') {
//                     ctu = true;
//                     break;
//                   }
//                 } else if(isState === 'notDone') {
//                   if(comId === change.doc.data().document_id && comDone === 'N') {
//                     ctu = true;
//                     for(let j=0; j<completionIdArr.length; j++) {
//                       let comJId = completionIdArr[j].id;
//                       let comJDone = completionIdArr[j].done;
//                       if(comJId === change.doc.data().document_id && comJDone === 'Y') {
//                         ctu = false;
//                         break;
//                       }
//                     }
//                     break;
//                   }
//                 }
//               }

//               if(ctu) {
//                 data.push({
//                   no: (data.length + 2) - 1,
//                   document_id: change.doc.data().document_id,
//                   company_id: change.doc.data().company_id,
//                   program_cd: change.doc.data().program_cd,
//                   program_nm: change.doc.data().program_nm,
//                   nm: change.doc.data().nm,
//                   gender: change.doc.data().gender,
//                   birth_dt: change.doc.data().birth_dt || '-',
//                   start_dt: change.doc.data().start_dt,
//                   end_dt: change.doc.data().end_dt,
//                   reg_dt: change.doc.data().reg_dt,
//                   middle_report_q_dt: change.doc.data().middle_report_q_dt,
//                   middle_report_a_dt: change.doc.data().middle_report_a_dt,
//                   last_report_q_dt: change.doc.data().last_report_q_dt,
//                   last_report_a_dt: change.doc.data().last_report_a_dt,
//                   total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//                   total_counseling_cnt: change.doc.data().total_counseling_cnt,
//                   body_type_cd: change.doc.data().body_type_cd,
//                   body_type_nm: change.doc.data().body_type_nm,
//                 });
//               }
//             });
//             setHealthyBody(data);
//             setCount(data.length);
//           });
//         } else {
//           // 전체
//           changes.forEach((change) => {
//             data.push({
//               no: (data.length + 2) - 1,
//               document_id: change.doc.data().document_id,
//               company_id: change.doc.data().company_id,
//               program_cd: change.doc.data().program_cd,
//               program_nm: change.doc.data().program_nm,
//               nm: change.doc.data().nm,
//               gender: change.doc.data().gender,
//               birth_dt: change.doc.data().birth_dt || '-',
//               start_dt: change.doc.data().start_dt,
//               end_dt: change.doc.data().end_dt,
//               reg_dt: change.doc.data().reg_dt,
//               middle_report_q_dt: change.doc.data().middle_report_q_dt,
//               middle_report_a_dt: change.doc.data().middle_report_a_dt,
//               last_report_q_dt: change.doc.data().last_report_q_dt,
//               last_report_a_dt: change.doc.data().last_report_a_dt,
//               total_exercise_cnt: change.doc.data().total_exercise_cnt !== 0 ? change.doc.data().total_exercise_cnt : '-',
//               total_counseling_cnt: change.doc.data().total_counseling_cnt,
//               body_type_cd: change.doc.data().body_type_cd,
//               body_type_nm: change.doc.data().body_type_nm,
//             });
//           });
//           setHealthyBody(data);
//           setCount(data.length);
//         }
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// export async function getHealthyBodyDetail(document_id, type, program_cd, props) {
//   try {
//     if(type === 'COUNSELING') {
//       const data = {
//         userInfo: {
//           nm: '',
//           birth_dt: '',
//           gender: '',
//           program_nm: '',
//           reg_dt: 0,
//           start_dt: 0,
//         },
//         counselingInfo: [],
//       }

//       const getHbMstr = await firestore.collection('cust_HB_MSTR')
//       .doc(document_id).get()

//       data.userInfo.nm = getHbMstr.data().nm
//       data.userInfo.birth_dt = getHbMstr.data().birth_dt || '-'
//       data.userInfo.gender = getHbMstr.data().gender
//       data.userInfo.program_nm = getHbMstr.data().program_nm
//       data.userInfo.reg_dt = getHbMstr.data().reg_dt
//       data.userInfo.start_dt = getHbMstr.data().start_dt

//       const acRef = await firestore.collection('cust_HB_COUNSELING')
//       .where('program_seq', '==', document_id).orderBy('reg_dt', 'desc').get();
//       // .orderBy('reg_dt', 'desc')

//       acRef.docs.forEach((doc) => {
//         data.counselingInfo.push({
//           document_id: doc.data().document_id,
//           program_seq: doc.data().program_seq,
//           member_id: doc.data().member_id,
//           question: doc.data().question,
//           answer: doc.data().answer,
//           confirm_yn: doc.data().confirm_yn,
//           reg_dt: doc.data().reg_dt,
//           mod_dt: doc.data().mod_dt,
//           del_yn: doc.data().del_yn,
//           del_dt: doc.data().del_dt,
//         });
//       });

//       return data;

//     } else {
//       const acRef = await firestore.collection('cust_HB_REPORT')
//       .where('program_seq', '==', document_id)
//       .where('report_type_cd', '==', type)
//       .get();
//       const acRefData = acRef.docs[0].data();
//       const dataRow = props.filter((row) => row.document_id === document_id);


//       if(type === 'NOW') {
//         return {
//           document_id: acRefData.document_id,
//           program_seq: acRefData.program_seq,
//           member_id: acRefData.member_id,
//           nm: dataRow[0].nm,
//           birth_dt: dataRow[0].birth_dt,
//           gender: dataRow[0].gender,
//           // app_code: product_id[0],
//           // goods_code: product_id[1],
//           report_type_cd: acRefData.report_type_cd,
//           report_type_nm: acRefData.report_type_nm,
//           program_type_cd: acRefData.program_type_cd,
//           program_type_nm: acRefData.program_type_nm,
//           body_type_cd: acRefData.body_type_cd,
//           body_type_nm: acRefData.body_type_nm,
//           pain: acRefData.pain,
//           range: acRefData.range,
//           uncomfortable: acRefData.uncomfortable,
//           medical_result: acRefData.medical_result || [],
//           image_url_front: acRefData.image_url_front,
//           image_url_side: acRefData.image_url_side,
//           bbiddaki: acRefData.bbiddaki || [],
//           trainer_id: acRefData.trainer_id,
//           trainer_nm: acRefData.trainer_nm,
//           trainer_opinion: acRefData.trainer_opinion,
//           opinion_dt: acRefData.opinion_dt,
//           start_dt: acRefData.start_dt,
//           end_dt: acRefData.end_dt,
//           reg_dt: acRefData.reg_dt,
//         };
//       } else if(type === 'MIDDLE') {

//         const selectFirstWeekData = await firestore.collection('cust_HB_REPORT')
//         .where('program_seq', '==', document_id)
//         .where('report_type_cd', '==', 'NOW')
//         .get();
//         const firstWeekData = selectFirstWeekData.docs[0].data();

//         const selectFourWeekData = await firestore.collection('cust_HB_REPORT')
//         .where('program_seq', '==', document_id)
//         .where('report_type_cd', '==', 'MIDDLE')
//         .get();
//         const fourWeekData = selectFourWeekData.docs[0].data();

//         const weekRef = await firestore.collection('cust_HB_WEEK')
//         .where('program_seq', '==', document_id).get();
//         const weekRefData = weekRef.docs[0].data();
//         const week_period = weekRefData.week_period;

//         const data = [];
//         for (let i = 0; i < week_period.length; i++) {
//           data.push({
//             key: i,
//             week: week_period[i].week + '주차',
//             program: week_period[i].weekly_program,
//             exercise_day: getFilteredWeeklyExerciseDay_ref(Number(week_period[i].week)),
//             exercise_cnt: week_period[i].exercise_cnt,
//             counseling_cnt: week_period[i].counseling_cnt,
//           });
//         }

//         const chapter5Data = [];
//         let position_align = '';
//         let first_week = '';
//         let four_week = '';
//         for(let i=0; i < 3; i++) {
//           if(i === 0) {
//             position_align = '머리 위치';
//             first_week = firstWeekData.bbiddaki.scores[1].score;
//             four_week = fourWeekData.bbiddaki.scores[1].score;
//           } else if (i === 1) {
//             position_align = '상체 정렬';
//             first_week = firstWeekData.bbiddaki.scores[2].score;
//             four_week = fourWeekData.bbiddaki.scores[2].score;
//           } else {
//             position_align = '하체 정렬';
//             first_week = firstWeekData.bbiddaki.scores[3].score;
//             four_week = fourWeekData.bbiddaki.scores[3].score;
//           }
//           chapter5Data.push({
//             key: i,
//             position_align: position_align,
//             first_week: first_week,
//             four_week: four_week,
//           });
//         }
//         return {
//           document_id: fourWeekData.document_id,
//           program_seq: fourWeekData.program_seq,
//           member_id: fourWeekData.member_id,
//           nm: dataRow[0].nm,
//           birth_dt: dataRow[0].birth_dt,
//           gender: dataRow[0].gender,
//           // app_code: product_id[0],
//           // goods_code: product_id[1],
//           report_type_cd: fourWeekData.report_type_cd,
//           report_type_nm: fourWeekData.report_type_nm,
//           program_type_cd: fourWeekData.program_type_cd,
//           program_type_nm: fourWeekData.program_type_nm,
//           body_type_cd: fourWeekData.body_type_cd,
//           body_type_nm: fourWeekData.body_type_nm,
//           before_pain: firstWeekData.pain,
//           pain: fourWeekData.pain,
//           before_range: firstWeekData.range,
//           range: fourWeekData.range,
//           before_uncomfortable: firstWeekData.uncomfortable,
//           uncomfortable: fourWeekData.uncomfortable,
//           before_medical_result: firstWeekData.medical_result,
//           medical_result: fourWeekData.medical_result,
//           before_image_url_front: firstWeekData.image_url_front,
//           image_url_front: fourWeekData.image_url_front,
//           before_image_url_side: firstWeekData.image_url_side,
//           image_url_side: fourWeekData.image_url_side,
//           before_bbiddaki: firstWeekData.bbiddaki,
//           bbiddaki: fourWeekData.bbiddaki,
//           trainer_id: fourWeekData.trainer_id,
//           trainer_nm: fourWeekData.trainer_nm,
//           trainer_opinion: fourWeekData.trainer_opinion,
//           opinion_dt: fourWeekData.opinion_dt,
//           start_dt: fourWeekData.start_dt,
//           end_dt: fourWeekData.end_dt,
//           reg_dt: fourWeekData.reg_dt,
//           week_period: data,
//           chapter5Data: chapter5Data,
//         };

//       } else if (type === 'LAST' && program_cd === 'P') {
//         const beforeAcRef = await firestore.collection('cust_HB_REPORT')
//         .where('program_seq', '==', document_id)
//         .where('report_type_cd', '==', 'NOW')
//         .get();
//         const beforeAcRefData = beforeAcRef.docs[0].data();

//         const weekRef = await firestore.collection('cust_HB_WEEK')
//         .where('program_seq', '==', document_id).get();
//         const weekRefData = weekRef.docs[0].data();
//         const week_period = weekRefData.week_period;

//         const data = [];
//         for (let i = 0; i < week_period.length; i++) {
//           data.push({
//             key: i,
//             week: week_period[i].week + '주차',
//             program: week_period[i].weekly_program,
//             exercise_day: getFilteredWeeklyExerciseDay_ref(Number(week_period[i].week)),
//             exercise_cnt: week_period[i].exercise_cnt,
//             counseling_cnt: week_period[i].counseling_cnt,
//           });
//         }

//         const chapter5Data = [];
//         let position_align = '';
//         let first_week = '';
//         let last_week = '';
//         for(let i=0; i < 3; i++) {
//           if(i === 0) {
//             position_align = '머리 위치';
//             first_week = beforeAcRefData.bbiddaki.scores[1].score;
//             last_week = acRefData.bbiddaki.scores[1].score;
//           } else if (i === 1) {
//             position_align = '상체 정렬';
//             first_week = beforeAcRefData.bbiddaki.scores[2].score;
//             last_week = acRefData.bbiddaki.scores[2].score;
//           } else {
//             position_align = '하체 정렬';
//             first_week = beforeAcRefData.bbiddaki.scores[3].score;
//             last_week = acRefData.bbiddaki.scores[3].score;
//           }
//           chapter5Data.push({
//             key: i,
//             position_align: position_align,
//             first_week: first_week,
//             last_week: last_week,
//           });
//         }
//         return {
//           document_id: acRefData.document_id,
//           program_seq: acRefData.program_seq,
//           member_id: acRefData.member_id,
//           nm: dataRow[0].nm,
//           birth_dt: dataRow[0].birth_dt,
//           gender: dataRow[0].gender,
//           // app_code: product_id[0],
//           // goods_code: product_id[1],
//           report_type_cd: acRefData.report_type_cd,
//           report_type_nm: acRefData.report_type_nm,
//           program_type_cd: acRefData.program_type_cd,
//           program_type_nm: acRefData.program_type_nm,
//           body_type_cd: acRefData.body_type_cd,
//           body_type_nm: acRefData.body_type_nm,
//           before_pain: beforeAcRefData.pain,
//           pain: acRefData.pain,
//           before_range: beforeAcRefData.range,
//           range: acRefData.range,
//           before_uncomfortable: beforeAcRefData.uncomfortable,
//           uncomfortable: acRefData.uncomfortable,
//           before_medical_result: beforeAcRefData.medical_result,
//           medical_result: acRefData.medical_result,
//           before_image_url_front: beforeAcRefData.image_url_front,
//           image_url_front: acRefData.image_url_front,
//           before_image_url_side: beforeAcRefData.image_url_side,
//           image_url_side: acRefData.image_url_side,
//           before_bbiddaki: beforeAcRefData.bbiddaki,
//           bbiddaki: acRefData.bbiddaki,
//           trainer_id: acRefData.trainer_id,
//           trainer_nm: acRefData.trainer_nm,
//           trainer_opinion: acRefData.trainer_opinion,
//           opinion_dt: acRefData.opinion_dt,
//           start_dt: acRefData.start_dt,
//           end_dt: acRefData.end_dt,
//           reg_dt: acRefData.reg_dt,
//           week_period: data,
//           chapter5Data: chapter5Data,
//         };
//       } else if(type === 'LAST' && program_cd === 'R') {

//         let firstWeekData;
//         let fourWeekData;
//         const chapter5Data = [];

//         const selectFirstWeekData = await firestore.collection('cust_HB_REPORT')
//         .where('program_seq', '==', document_id)
//         .where('report_type_cd', '==', 'NOW')
//         .get().then( async (first) => {
//           firstWeekData = first.docs[0].data();

//           const selectFourWeekData = await firestore.collection('cust_HB_REPORT')
//           .where('program_seq', '==', document_id)
//           .where('report_type_cd', '==', 'MIDDLE')
//           .get().then((four) => {
//             fourWeekData = four.docs[0].data();

//             let position_align = '';
//             let first_week = '';
//             let four_week = '';
//             let last_week = '';
//             for(let i=0; i < 3; i++) {
//               if(i === 0) {
//                 position_align = '머리 위치';
//                 first_week = firstWeekData.bbiddaki.scores[1].score;
//                 four_week = fourWeekData.bbiddaki.scores[1].score;
//                 last_week = acRefData.bbiddaki.scores[1].score;
//               } else if (i === 1) {
//                 position_align = '상체 정렬';
//                 first_week = firstWeekData.bbiddaki.scores[2].score;
//                 four_week = fourWeekData.bbiddaki.scores[2].score;
//                 last_week = acRefData.bbiddaki.scores[2].score;
//               } else {
//                 position_align = '하체 정렬';
//                 first_week = firstWeekData.bbiddaki.scores[3].score;
//                 four_week = fourWeekData.bbiddaki.scores[3].score;
//                 last_week = acRefData.bbiddaki.scores[3].score;
//               }
//               chapter5Data.push({
//                 key: i,
//                 position_align: position_align,
//                 first_week: first_week,
//                 four_week: first_week,
//                 last_week: last_week,
//               });
//             }
//           });
//         });

//         const weekRef = await firestore.collection('cust_HB_WEEK')
//         .where('program_seq', '==', document_id).get();
//         const weekRefData = weekRef.docs[0].data();
//         const week_period = weekRefData.week_period;

//         const data = [];
//         for (let i = 0; i < week_period.length; i++) {
//           data.push({
//             key: i,
//             week: week_period[i].week + '주차',
//             program: week_period[i].weekly_program,
//             exercise_day: getFilteredWeeklyExerciseDay_ref(Number(week_period[i].week)),
//             exercise_cnt: week_period[i].exercise_cnt,
//             counseling_cnt: week_period[i].counseling_cnt,
//           });
//         }

//         return {
//           document_id: acRefData.document_id,
//           program_seq: acRefData.program_seq,
//           member_id: acRefData.member_id,
//           nm: dataRow[0].nm,
//           birth_dt: dataRow[0].birth_dt,
//           gender: dataRow[0].gender,
//           report_type_cd: acRefData.report_type_cd,
//           report_type_nm: acRefData.report_type_nm,
//           program_type_cd: acRefData.program_type_cd,
//           program_type_nm: acRefData.program_type_nm,
//           body_type_cd: acRefData.body_type_cd,
//           body_type_nm: acRefData.body_type_nm,
//           first_week_pain: firstWeekData.pain,
//           four_week_pain: fourWeekData.pain,
//           pain: acRefData.pain,
//           first_week_range: firstWeekData.range,
//           four_week_range: fourWeekData.range,
//           range: acRefData.range,
//           first_week_uncomfortable: firstWeekData.uncomfortable,
//           four_week_uncomfortable: fourWeekData.uncomfortable,
//           uncomfortable: acRefData.uncomfortable,
//           first_week_medical_result: firstWeekData.medical_result,
//           four_week_medical_result: fourWeekData.medical_result,
//           medical_result: acRefData.medical_result,
//           first_week_image_url_front: firstWeekData.image_url_front,
//           four_week_image_url_front: fourWeekData.image_url_front,
//           image_url_front: acRefData.image_url_front,
//           first_week_image_url_side: firstWeekData.image_url_side,
//           four_week_image_url_side: fourWeekData.image_url_side,
//           image_url_side: acRefData.image_url_side,
//           first_week_bbiddaki: firstWeekData.bbiddaki,
//           four_week_bbiddaki: fourWeekData.bbiddaki,
//           bbiddaki: acRefData.bbiddaki,
//           trainer_id: acRefData.trainer_id,
//           trainer_nm: acRefData.trainer_nm,
//           trainer_opinion: acRefData.trainer_opinion,
//           opinion_dt: acRefData.opinion_dt,
//           start_dt: acRefData.start_dt,
//           four_week_start_dt: fourWeekData.start_dt,
//           end_dt: acRefData.end_dt,
//           reg_dt: acRefData.reg_dt,
//           week_period: data,
//           chapter5Data: chapter5Data,
//         };
//       }
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// export async function addTrainerOpinion(param) {
//   try{
//     let memberId = param.member_id;
//     let type = param.type;
//     let updateData = {};
//     let fcm_id = '';
//     let fcm_body = "";
//     const PUSH_API_AUTH = 'Key B7B4CBA68FDBB45F70F0D4CAEDF50E26F44E2291CA3845B69DC75F25FACF1573';

//     const postData = {
//       trainer_id: getCookie('session'),
//       trainer_nm: param.trainer_nm,
//       trainer_opinion: param.trainer_opinion,
//       opinion_dt: dayjs().valueOf(),
//     }

//     await firestore.collection('cust_HB_REPORT').doc(param.document_id).update(postData)
//       .then(async (doc) => {
//         if(type === 'MIDDLE') {
//           updateData.middle_report_a_dt = postData.opinion_dt;
//         } else {
//           updateData.last_report_a_dt = postData.opinion_dt;
//         }
//         await firestore.collection('cust_HB_MSTR').doc(param.program_seq).update(updateData).then(async (doc) => {
//           const membersData = await firestore.collection('cust_MEMBERS').where('id', '==', memberId).get().then((members) =>{

//             fcm_id = members.docs[0].data().reg_id;

//             const myHeaders = new Headers();
//             myHeaders.append('Content-Type', 'application/json; charset=utf-8');
//             myHeaders.append('Authorization', PUSH_API_AUTH);
//             if(type === "MIDDLE") {
//               fcm_body = '운동 처방사의 중간평가가 완료되었습니다. 중간 평가 보고서를 확인해 주세요.';
//             } else {
//               fcm_body = '운동 처방사의 최종평가가 완료되었습니다. 최종 평가 보고서를 확인해 주세요.';
//             }

//             const raw = JSON.stringify({
//               to: fcm_id,
//               title: '삼성생명 건강관리서비스 건강한몸 알림',
//               body: fcm_body,
//             });

//             const requestOptions = {
//               method: 'POST',
//               headers: myHeaders,
//               body: raw,
//               redirect: 'follow',
//             };

//             fetch('https://asia-northeast1-aaihealth-b209b.cloudfunctions.net/push', requestOptions)
//             .then((response) => response.text())
//             .then((result) => console.log(result))
//             .catch((error) => console.log('error:', error));
//           });
//         });
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//     return { result: 'success', message: 'The post is inserted.' };

//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }

// }

// export async function insertCounselingAnswer(counseling) {
//   try {
//     if(counseling) {
//       let document_id = counseling.document_id;
//       let program_seq = counseling.program_seq;
//       const postData = {
//         answer: counseling.answer,
//         confirm_yn: 'Y',
//         mod_dt: dayjs().valueOf(),
//       };
//       let member_id = '';
//       let fcm_id = '';
//       let key = 'Key B7B4CBA68FDBB45F70F0D4CAEDF50E26F44E2291CA3845B69DC75F25FACF1573';

//       await firestore.collection('cust_HB_COUNSELING').doc(document_id).update(postData)
//       .then(async (doc) => {

//         const hbMstrData = await firestore.collection('cust_HB_MSTR').where('document_id', '==', program_seq)
//         .get()
//         .then(async (mstr) => {
//           member_id = mstr.docs[0].data().member_id;
//         });
//         const membersData = await firestore.collection('cust_MEMBERS').where('id', '==', member_id)
//         .get()
//         .then( async (members) => {
//           fcm_id = members.docs[0].data().reg_id;
//           const myHeaders = new Headers();
//           myHeaders.append('Content-Type', 'application/json; charset=utf-8');
//           myHeaders.append('Authorization', key);

//           const raw = JSON.stringify({
//             to: fcm_id,
//             title: '삼성생명 건강관리서비스 건강한몸 알림',
//             body: '상담 답변이 등록되었습니다. 확인해주세요',
//           });

//           const requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow',
//           };

//           fetch('https://asia-northeast1-aaihealth-b209b.cloudfunctions.net/push', requestOptions)
//             .then((response) => response.text())
//             .then((result) => console.log(result))
//             .catch((error) => console.log('error:', error));
//         });
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//       return { result: 'success', message: 'The post is inserted.' };
//     }
//   } catch(err) {
//     console.log(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// export async function editArtCounselDetail(artCounsel) {
//   try {
//     if (artCounsel.del_dt > 0) {
//       console.log('fail: The art counsel was deleted.');
//       return { result: 'fail', message: 'The art counsel was deleted.' };
//     }
//     const artCounselB = await firestore.collection('cust_ARTCOUNSEL')
//       .doc(artCounsel.document_id).get();
//     const now = Math.floor(new Date().getTime());
//     delete artCounsel.app_code;
//     delete artCounsel.goods_code;

//     // checking about status
//     if (!artCounsel.admin_check_yn
//       && (artCounsel.status === 1 || artCounsel.status === 2)) {
//       artCounsel.admin_check_yn = true;
//       artCounsel.admin_check_dt = now;
//       artCounsel.mod_dt = now;
//     } else if (artCounsel.admin_check_yn && artCounsel.status === 2
//       && artCounselB.data().status !== artCounsel.status) {
//       artCounsel.mod_dt = now;
//     } else if (!artCounsel.del_yn && artCounsel.status === 9) {
//       artCounsel.admin_check_yn = true;
//       artCounsel.admin_check_dt = now;
//       artCounsel.del_dt = now;
//       artCounsel.del_yn = true;
//       artCounsel.mod_dt = now;
//     }

//     // checking about text
//     if ((!artCounselB.data().admin_memo && !!artCounsel.admin_memo)
//       || artCounselB.data().admin_memo !== artCounsel.admin_memo) {
//       artCounsel.mod_dt = now;
//     }
//     if ((!artCounselB.data().admin_answer && !!artCounsel.admin_answer)
//       || artCounselB.data().admin_answer !== artCounsel.admin_answer) {
//       artCounsel.mod_dt = now;
//     }

//     if (artCounsel.mod_dt !== now) {
//       console.log('fail: There are no modifications.');
//       return { result: 'fail', message: 'There are no modifications.' };
//     }

//     if (artCounsel.mod_dt === now && artCounsel.status === 2
//       && artCounselB.data().status !== artCounsel.status) {
//       const myHeaders = new Headers();
//       myHeaders.append('Content-Type', 'application/json; charset=utf-8');
//       myHeaders.append('Authorization', process.env.PUSH_API_AUTH);

//       const raw = JSON.stringify({
//         to: artCounsel.fcm_id,
//         title: '[롯데손보 도담도담] 서비스 제공 완료',
//         body: '신청하신 미술 심리 검사 서비스 제공이 완료되었습니다. 롯데손보 도담도담을 이용해주셔서 감사드립니다.',
//       });

//       const requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow',
//       };

//       fetch('https://asia-northeast1-aaihealth-b209b.cloudfunctions.net/push', requestOptions)
//         .then((response) => response.text())
//         .then((result) => console.log(result))
//         .catch((error) => console.log('error:', error));
//     }

//     // checking undefined value and so on...
//     Object.keys(artCounsel).forEach((v) => {
//       if (artCounsel[v] === undefined || artCounsel[v].length === 0) {
//         delete artCounsel[v];
//       }
//     });
//     if (artCounselB.data().admin_memo === artCounsel.admin_memo) {
//       delete artCounsel.admin_memo;
//     }
//     if (artCounselB.data().admin_answer === artCounsel.admin_answer) {
//       delete artCounsel.admin_answer;
//     }
//     await firestore.collection('cust_ARTCOUNSEL').doc(artCounsel.document_id)
//       .set(artCounsel, { merge: true });
//     return { result: 'success', message: 'The art counsel is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }