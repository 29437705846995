import React, { useRef, useState } from 'react';
import {
  CancelButton,
  SaveButton,
  DelButton,
  LinkButton,
} from 'components/Common';
import { Descriptions, Divider, Input, Space, Row, ConfigProvider } from 'antd';
import Item from 'antd/es/list/Item';
import {
  editChatDoctorDetail,
  deleteChatDoctor,
} from 'controllers/chatbot/chatbotDoctor';
import { Link } from 'react-router-dom';
import { routes_ref } from 'lib/routeRef';
import './ChatDoctorDetail.css';

const { doctor_add } = routes_ref.base.chatbot;
const ChatDoctorDetailTable = (props) => {
  const { chatDoctorDetail, setChatDoctorDetail } = props;
  return (
    <>
      <Descriptions
        title={`의료진 정보(${chatDoctorDetail.m_doc})`}
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="분류">
          {chatDoctorDetail.m_main_cate} - {chatDoctorDetail.m_sub_cate}
        </Item>
        <Item label="병원명">{chatDoctorDetail.h_name}</Item>
        <Item label="진료과">{chatDoctorDetail.m_dep || '-'}</Item>
        <Item label="진료분야">{chatDoctorDetail.m_name || '-'}</Item>
        <Item label="관리자 메모">
          <Input.TextArea
            value={chatDoctorDetail.m_memo}
            rows={5}
            allowClear
            onChange={(e) =>
              setChatDoctorDetail({
                ...chatDoctorDetail,
                m_memo: e.currentTarget.value,
              })
            }
          />
        </Item>
        <Item label="숨김 상태">
          {chatDoctorDetail.hide_yn ? '설정' : '미설정'}
        </Item>
      </Descriptions>
      <Divider />
      <ConfigProvider
        theme={{
          components: {
            Descriptions: {
              extraColor: 'blue',
            },
          },
        }}
      >
      <Descriptions
        title="병원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
        extra={'*건강보험심사평가원 제공 정보 기준'}
      >
        <Item label="병원 종류">{chatDoctorDetail.h_class || '-'}</Item>
        <Item label="병원 주소">{chatDoctorDetail.h_addr || '-'}</Item>
        <Item label="병원 전화번호">{chatDoctorDetail.h_tel || '-'}</Item>
      </Descriptions>
      </ConfigProvider>
      <Divider />

      <Row>
        <Space>
          <DelButton
            func={deleteChatDoctor}
            data={chatDoctorDetail.m_no}
            location="/doctor"
          />
          <Link className='link-styles'
            to={doctor_add.path}
            state={{
              m_no: chatDoctorDetail.m_no
            }}
          >
            수정하기
          </Link>
          <SaveButton
            func={editChatDoctorDetail}
            data={chatDoctorDetail}
            location="/doctor"
          />
        </Space>
      </Row>
    </>
  );
};

export default ChatDoctorDetailTable;
