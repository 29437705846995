import React, { useEffect, useState } from 'react';
import {
  CounterLabel,
  LinkButton,
  ListTableWithOutPaging,
  Pagination,
  ExcelModal,
  DelListButton,
} from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { getAppliesList, makeXlsx } from 'controllers/Services/applies';
import { deleteBundle } from 'controllers/common';
import { Button, Drawer, Select, Badge } from 'antd';
import { company_ref, status_ref, p_apply_add_type_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { routes_ref } from 'lib/routeRef';
import { Link } from 'react-router-dom';
import ApplyDetailTableContainer from './ApplyDetailTableContainer';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { maskName, maskPhone } from 'lib/maskPersonInfo';

const { Option } = Select;

const ApplyListTableContainer = () => {
  const appliesInitState = {
    document_id: '',
    company_id: '',
    app_code: '',
    goods_code: '',
    product_id: '',
    category1: '',
    category2: '',
    category1_nm: '',
    category2_nm: '',
    service_detail: '',
    crm_child_nm: '',
    crm_nm: '',
    crm_phone: '',
    IBcall_yn: '',
    service_period: '',
    status: '',
    admin_check_yn: '',
    admin_check_dt: '',
    reg_dt: '',
    del_yn: '',
    del_dt: '',
  };

  const { p_apply } = routes_ref.base.services;
  const [applies, setApplies] = useState({
    data: [appliesInitState],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first',
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });
  const [loading, setLoading] = useState(true);
  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const [searchState, setSearchState] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유

  useEffect(() => {
    const res = getAppliesList(
      setApplies,
      applies,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
    return () => res;
  }, []);

  function goSearch() {
    getAppliesList(
      setApplies,
      applies,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
  }
  const excelDownload = () => {
    insertInfoAcessLog(location.pathname, 'makeXlsx', '다운로드', excelReason);
    makeXlsx(excelReason, searchState);
  };
  const columns = [
    {
      title: () => {
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.length == list.length}
              onChange={(e) => onCheckedAll(e)}
            />
            전체
          </>
        );
      },
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render(document_id, record) {
        const prem_category2_type = [
          'nurseAccompany',
          'carerSupport',
          'housekeeper',
          'rehabilitationExercise',
          'companion',
        ];
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.includes(document_id)}
              onChange={(e) => onChangeChecked(e, document_id)}
            />
            <Button
              key={document_id}
              type="primary"
              size="small"
              onClick={() => {
                if (record.category1 !== 'premiumCare') {
                  showDrawer(document_id);
                  insertInfoAcessLog(
                    location.pathname,
                    'serviceDetail',
                    '조회',
                    undefined,
                    document_id
                  );
                }
              }}
            >
              {record.company_id === 'L03' &&
              (record.category1 === 'premiumCare' ||
                prem_category2_type.includes(record.category2)) ? (
                <Link
                  to={p_apply.path}
                  state={{
                    p_apply_category2_array: [record.category2],
                    document_id: record.document_id,
                    category1: record.category1,
                  }}
                >
                  프리미엄
                </Link>
              ) : (
                '관리'
              )}
            </Button>
          </>
        );
      },
    },
    {
      title: '신청\n분류',
      dataIndex: 'IBcall_yn',
      align: 'center',
      render: (isIBcall) => (isIBcall ? 'I/B' : 'App'),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '대분류',
      dataIndex: 'category1_nm',
      key: 'category1_nm',
      align: 'center',
    },
    {
      title: '중분류',
      dataIndex: 'category2_nm',
      key: 'category2_nm',
      align: 'center',
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data) => {
        let badge;
        switch (data) {
          case 0:
          case 10:
            badge = <Badge status="error" text={status_ref[data]} />;
            break;
          case 1:
          case 11:
          case 12:
            badge = <Badge status="processing" text={status_ref[data]} />;
            break;
          case 2:
          case 13:
          case 14:
            badge = <Badge status="success" text={status_ref[data]} />;
            break;
          case 9:
          case 19:
            badge = <Badge status="default" text={status_ref[data]} />;
            break;
          case 99:
            badge = <Badge status="default" text={status_ref[9]} />;
            break;
          default:
            break;
        }
        return badge;
      },
    },
    {
      title: '만족도조사\n완료 유무',
      dataIndex: 'evaluationYn',
      key: 'evaluationYn',
      align: 'center',
      render: (data) => {
        let state;
        switch (data) {
          case undefined:
            state = '해당\n없음';
            break;
          case true:
            state = '완료';
            break;
          case false:
            state = '미완료';
            break;
          default:
            state = '해당\n없음';
            break;
        }
        return state;
      },
    },
    {
      title: '관리자\n확인 시각',
      dataIndex: 'admin_check_dt',
      key: 'admin_check_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '신청일자\n(희망일자)',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date, apply) => {
        const { service_period } = apply;
        return (
          <>
            <div>{getFormattedDate(date, 'opt2')}</div>
            {service_period ? <div>{service_period}</div> : null}
          </>
        );
      },
    },
  ];

  let cp = currentPage;
  if (cp > 10) {
    // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp % 10;
    cp = n == 0 ? 10 : n;
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  };
  let list = currentData(applies.data);

  const setPage = (page) => {
    setCurrentPage(page);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const renderMoveData = (move) => {
    getAppliesList(
      setApplies,
      applies,
      move,
      setCurrentPage,
      searchState,
      setLoading
    );
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const onChangeChecked = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      checkedList.splice(checkedList.indexOf(id), 1);
      setCheckedList([...checkedList]);
    }
  };
  const onCheckedAll = (e) => {
    const checkList = list.map((item) => item.document_id);
    if (e.target.checked) {
      setCheckedList(checkList);
    } else {
      setCheckedList([]);
    }
  };

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton link={routes_ref.base.services.apply.path} />
        }
        countLabel={<CounterLabel count={applies.count} />}
        addButton={
          <Select
            style={{ width: '250px', float: 'right' }}
            placeholder="신규 등록"
          >
            <Option value="Atype">
              <Link
                to={routes_ref.base.services.p_apply.path}
                state={{
                  p_apply_category2_array: [
                    'carerSupport',
                    'housekeeper',
                  ],
                }}
              >
                {p_apply_add_type_ref.Atype}
              </Link>
            </Option>
            <Option value="Btype">
              <Link
                to={p_apply.path}
                state={{
                  p_apply_category2_array: [
                    'psychologyCounsel',
                    'nurseAccompany',
                  ],
                }}
              >
                {p_apply_add_type_ref.Btype}
              </Link>
            </Option>
            <Option value="Ctype">
              <Link
                to={routes_ref.base.services.p_apply.path}
                state={{
                  p_apply_category2_array: [
                    'rehabilitationExercise',
                    'companion',
                  ],
                }}
              >
                {p_apply_add_type_ref.Ctype}
              </Link>
            </Option>
          </Select>
        }
        delButton={
          <DelListButton
            func={deleteBundle}
            data={checkedList}
            location="/apply"
          />
        }
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'apply',
          field: ['보험사', '이름', '연락처', '상태', '대분류', '중분류'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(applies.data)}
        columns={columns}
        loading={loading}
        pagination={
          <Pagination
            totalData={applies.data}
            pagingState={applies.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        }
        DetailTableContainer={
          <Drawer width={750} closable onClose={onClose} open={status.visible}>
            <ApplyDetailTableContainer document_id={status.document_id} />
          </Drawer>
        }
      />
      <div>
        {modalIsOpen && (
          <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          ></ExcelModal>
        )}
      </div>
    </>
  );
};

export default ApplyListTableContainer;
