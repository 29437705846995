import React, { useEffect, useState } from 'react';
import { getBodyAgesList, makeXlsx } from 'controllers/HealthLogs/bodyAges';
import { InfoPane } from 'components/InfoPane';
import {
  CounterLabel,
  LinkButton,
  ListTableWithOutPaging,
  Pagination,
  ExcelModal,
  PdfModal,
  DelListButton,
} from 'components/Common';
import { Button, Drawer } from 'antd';
import { deleteBundle } from 'controllers/common';
import { getFormattedDate } from 'lib/getFormattedDate';
import BodyAgeDetailTableContainer from './BodyAgeDetailTableContainer';
import { routes_ref } from 'lib/routeRef';
import { company_ref, body_age_status_ref } from 'lib/dataRef';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const BodyAgeListTableContainer = () => {
  const [bodyAges, setBodyAges] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first',
    },
  });
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });

  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [excelModalIsOpen, setExcelModalIsOpen] = useState(false);
  const [pdfReason, setPdfReason] = useState(''); // pdf다운로드 사유
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
  const [record, setRecord] = useState({});

  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  const excelDownload = () => {
    insertInfoAcessLog(location.pathname, 'makeXlsx', '다운로드', excelReason);
    makeXlsx(excelReason, searchState);
  };
  const pdfDownload = (record) => {
    insertInfoAcessLog(
      location.pathname,
      'body-ages-pdf',
      '다운로드',
      pdfReason,
      record.document_id
    );
    window.open(record.pdf_file);
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getBodyAgesList(setBodyAges, bodyAges, 'first',
    setCurrentPage,
    searchState,
    setLoading);
  }, []);

  function goSearch() {
    getBodyAgesList(setBodyAges, bodyAges, 'first',
    setCurrentPage,
    searchState,
    setLoading);
  }
  let cp = currentPage;
  if (cp > 10) {
    // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp % 10;
    cp = n == 0 ? 10 : n;
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  };
  let list = currentData(bodyAges.data);

  const setPage = (page) => {
    setCurrentPage(page);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const renderMoveData = (move) => {
    getBodyAgesList(setBodyAges, bodyAges, 'first',
    setCurrentPage,
    searchState,
    setLoading);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const onChangeChecked = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      checkedList.splice(checkedList.indexOf(id), 1);
      setCheckedList([...checkedList]);
    }
  };
  const onCheckedAll = (e) => {
    const checkList = list.map((item) => item.document_id);
    if (e.target.checked) {
      setCheckedList(checkList);
    } else {
      setCheckedList([]);
    }
  };
  const columns = [
    {
      title: () => {
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.length == list.length}
              onChange={(e) => onCheckedAll(e)}
            />
            전체
          </>
        );
      },
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
         <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={checkedList.includes(document_id)}
            onChange={(e) => onChangeChecked(e, document_id)}
          />
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => {
              insertInfoAcessLog(
                location.pathname,
                'body-agesDetail',
                '조회',
                undefined,
                document_id
              ),
                showDrawer(document_id);
            }}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data, record) => {
        if (data >= 5) {
          return (
            <Button
              onClick={() => {
                setPdfModalIsOpen(true), setRecord(record);
              }}
            >
              {body_age_status_ref[data]}
            </Button>
          );
        }
        return body_age_status_ref[data];
      },
    },
    {
      title: '등록일자',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '취소여부',
      dataIndex: 'del_yn',
      key: 'del_yn',
      align: 'center',
      render: (data) => (data ? 'Y' : ''),
    },
    {
      title: '취소날짜',
      dataIndex: 'del_dt',
      key: 'del_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton link={routes_ref.base.healthLogs.body_age.path} />
        }
        countLabel={<CounterLabel count={bodyAges.count} />}
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setExcelModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
        }
        delButton={
          <DelListButton
            func={deleteBundle}
            data={checkedList}
            location="/body-ages"
          />
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'body-ages',
          field: ['보험사', '이름', '연락처', '상태'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(bodyAges.data)}
        columns={columns}
        loading={loading}
        pagination={
          <Pagination
            totalData={bodyAges.data}
            pagingState={bodyAges.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        }
        DetailTableContainer={
          <Drawer width={750} closable onClose={onClose} open={status.visible}>
            <BodyAgeDetailTableContainer document_id={status.document_id} />
          </Drawer>
        }
      />
      <div>
        {excelModalIsOpen && (
          <ExcelModal
            open={excelModalIsOpen}
            modalIsOpen={setExcelModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          ></ExcelModal>
        )}
      </div>
      <div>
        {pdfModalIsOpen && (
          <PdfModal
            open={pdfModalIsOpen}
            modalIsOpen={setPdfModalIsOpen}
            pdfDownload={pdfDownload}
            pdfReason={setPdfReason}
            record={record}
          ></PdfModal>
        )}
      </div>
    </>
  );
};

export default BodyAgeListTableContainer;
