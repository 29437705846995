import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import AdminListTableContainer from 'containers/Services/Admins/AdminListTableContainer';
import { useCheckAuth } from 'lib/hooks';

const AdminListPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['systems']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<AdminListTableContainer />}
    />
  );
};

export default AdminListPage;
