import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 치료 정보의 정보 조회
 */
export async function getTreatmentsList(setTreatments, setCount, setLoading) {
  try {
    return await axios.get(
      `${baseUrl}/treatments/getList`).then((res) => {
        setTreatments(res.data.data);
        setCount(res.data.count);
        setLoading(false);
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 치료 정보의 세부 정보 조회
 * @param {string} document_id 조회하고자 하는 특정 치료 정보의 document id
 */
export async function getTreatmentDetail(document_id) {
  try {
    return await axios.get(
      `${baseUrl}/treatments/getDetail/${document_id}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err);
      });
      
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 치료 정보의 정보 수정
 * @param {object} treatment 수정하고자 하는 특정 치료 정보의 정보 및 수정 내용
 */
export async function editTreatmentDetail(treatment) {
  try {
    return await axios.post(
      `${baseUrl}/treatments/update`, {
        treatment
      }).then((res) => {
        return { result: 'success', message: 'The post is inserted.' };
      }).catch((err) => {
        return { result: 'fail' };
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 새로운 치료 정보 document 추가
 * @param {object} treatment 추가할 치료 정보의 정보
 */
export async function postTreatment(treatment) {
  try {
    return await axios.post(
      `${baseUrl}/treatments/add`, {
        treatment
      }).then((res) => {
        return { result: 'success', message: 'The post is inserted.' };
      }).catch((err) => {
        return { result: 'fail' };
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 치료 정보의 갯수 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getTreatmentsSize(app_code) {
//   try {
//     const trmsRef = await firestore.collection('main_POSTS')
//       .where('category', '==', 'treatment').orderBy('reg_dt', 'desc')
//       .get();
//     if (app_code === undefined) {
//       return trmsRef.size;
//     } else {
//       let size = 0;
//       trmsRef.docs.forEach((doc) => {
//         if (doc.data().product_id.includes(app_code)) {
//           size++;
//         }
//       });
//       return size;
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 치료 정보의 정보 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getTreatmentsList(app_code) {
//   try {
//     const trmsRef = await firestore.collection('main_POSTS')
//       .where('category', '==', 'treatment').orderBy('reg_dt', 'desc')
//       .get();
//     const trmDocs = [];

//     trmsRef.docs.forEach((doc) => {
//       const product_id = String(doc.data().product_id).split('_');
//       trmDocs.push({
//         document_id: doc.data().document_id,
//         company_id: doc.data().company_id,
//         newspaper_nm: doc.data().newspaper_nm,
//         newspaper_dt: doc.data().newspaper_dt,
//         title: doc.data().title,
//         url: doc.data().url,
//         app_code: product_id[0],
//         goods_code: product_id[0],
//         reg_dt: doc.data().reg_dt,
//         mod_dt: doc.data().mod_dt,
//         del_yn: doc.data().del_yn,
//         del_dt: doc.data().del_dt,
//       });
//     });

//     return trmDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 치료 정보의 세부 정보 조회
//  * @param {string} document_id 조회하고자 하는 특정 치료 정보의 document id
//  */
// export async function getTreatmentDetail(document_id) {
//   try {
//     const trmRef = await firestore.collection('main_POSTS')
//       .doc(document_id).get();
//     const product_id = String(trmRef.data().product_id).split('_');
//     return {
//       document_id: trmRef.data().document_id,
//       company_id: trmRef.data().company_id,
//       newspaper_nm: trmRef.data().newspaper_nm,
//       newspaper_dt: trmRef.data().newspaper_dt,
//       title: trmRef.data().title,
//       url: trmRef.data().url,
//       app_code: product_id[0],
//       goods_code: product_id[0],
//       reg_dt: trmRef.data().reg_dt,
//       mod_dt: trmRef.data().mod_dt,
//       del_yn: trmRef.data().del_yn,
//       del_dt: trmRef.data().del_dt,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 치료 정보의 정보 수정
//  * @param {object} treatment 수정하고자 하는 특정 치료 정보의 정보 및 수정 내용
//  */
// export async function editTreatmentDetail(treatment) {
//   try {
//     treatment.mod_dt = Math.floor(new Date().getTime());
//     // 값이 undefined 이거나, 길이가 0인 경우, 키 삭제
//     Object.keys(treatment).forEach((v) => {
//       if (treatment[v] === undefined || treatment[v].length === 0) {
//         delete treatment[v];
//       }
//     });
//     // 삭제 여부가 true인 경우, 삭제 시각을 현재로 수정
//     if (treatment.del_yn === true) {
//       treatment.del_dt = treatment.mod_dt;
//     }
//     await firestore.collection('main_POSTS')
//       .doc(treatment.document_id).set(treatment, { merge: true });
//     return { result: 'success', message: 'The post is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 새로운 치료 정보 document 추가
//  * @param {object} treatment 추가할 치료 정보의 정보
//  */
// export async function postTreatment(treatment) {
//   try {
//     // 키가 url, title, newspaper_dt, newspaper_nm이 아니거나, 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//     Object.keys(treatment).forEach((v) => {
//       if ((v !== 'url' && v !== 'title' && v !== 'newspaper_dt' && v !== 'newspaper_nm')
//         || (treatment[v] === undefined || treatment[v].length === 0)) {
//         delete treatment[v];
//       }
//     });
//     treatment.newspaper_dt = (treatment.newspaper_dt).valueOf(); // convert moment to epoch ms
//     treatment.product_id = 'LinaCancer01_4180';
//     treatment.company_id = 'L51';
//     treatment.category = 'treatment';
//     treatment.reg_dt = Math.floor(new Date().getTime());
//     treatment.del_yn = false;

//     await firestore.collection('main_POSTS').add(treatment)
//       .then(async (doc) => {
//         await firestore.collection('main_POSTS').doc(doc.id)
//           .set({ document_id: doc.id }, { merge: true });
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//     return { result: 'success', message: 'The post is inserted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }