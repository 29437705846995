import React, { useEffect, useState } from 'react';
import {
  CounterLabel,
  LinkButton,
  ListTableTemplate,
  ExcelModal,
} from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Button, Spin } from 'antd';
import { routes_ref } from 'lib/routeRef';
import { getChatCenterList, makeXlsx } from 'controllers/chatbot/chatbotCenter';
import { Link } from 'react-router-dom';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
const { center_add } = routes_ref.base.chatbot;

const ChatCenterTableContainer = () => {
  const [chatCenter, setChatCenter] = useState([{}]);
  const [searchState, setSearchState] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };

  const excelDownload = () => {
    insertInfoAcessLog(location.pathname, 'makeXlsx', '다운로드', excelReason);
    makeXlsx(excelReason, searchState);
  };
  useEffect(() => {
    getChatCenterList(setChatCenter, searchState, setLoading);
  }, []);
  function goSearch() {
    getChatCenterList(setChatCenter, searchState, setLoading);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'i_no',
      key: 'i_no',
      align: 'center',
      render: (i_no, record) => (
        <>
        <Link className='link-styles'
            to={center_add.path}
            state={{
              i_no: i_no,
              i_name: record.i_name
            }}
          >
            수정
          </Link>
        </>
      ),
    },
    {
      title: '기관명',
      dataIndex: 'i_name',
      key: 'i_name',
      align: 'center',
    },
    {
      title: '주소',
      dataIndex: 'i_addr',
      key: 'i_addr',
      align: 'center',
    },
    {
      title: '우대율(%)',
      dataIndex: 'i_rate',
      key: 'i_rate',
      align: 'center',
    },
    {
      title: '협약 기간',
      dataIndex: 'i_date',
      key: 'i_date',
      align: 'center',
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton
            ctx="전체목록"
            link={routes_ref.base.chatbot.center.path}
            originColor="#565e8c"
            hoveredColor="#00854a"
          />
        }
        countLabel={<CounterLabel count={chatCenter.length} />}
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
        }
        addButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              window.location.href = routes_ref.base.chatbot.center_add.path;
            }}
          >
            등록
          </Button>
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'chatCenter',
          field: ['협약 상태', '정렬', '지역', '검색'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      {loading ? (
        <Spin tip="Loading" size="large">
          <ListTableTemplate columns={columns} />
        </Spin>
      ) : (
        <ListTableTemplate dataSource={chatCenter} columns={columns} />
      )}
      <div>
        {modalIsOpen && (
          <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          ></ExcelModal>
        )}
      </div>
    </>
  );
};

export default ChatCenterTableContainer;
