import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Descriptions, Divider, Input, Select, Table, Row, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { SaveButton } from 'components/Common';
import { company_ref, symptoms_list_ref, symptoms_ref } from 'lib/dataRef';
import Modal from 'antd/es/modal/Modal';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import { deleteCancerL, editCancerLDetail } from 'controllers/HealthLogs/cancerLogs';
import dayjs from 'dayjs';

const { Item } = Descriptions;
const { Option } = Select;
const { ColumnGroup, Column } = Table;
const CancerLogDetailTable = (props) => {
  const initCancerLogDetail = {
    category: '',
    company_id: '',
    crm_nm: '',
    crm_phone: '',
    lists: [],
    member_id: '',
  };
  const [cancerLogDetail, setCancerLogDetail] = useState(initCancerLogDetail);
  const initEtcModal = {
    visible: false,
    etc: '',
    record: {},
  };
  const [etcModal, setEtcModal] = useState(initEtcModal);

  useEffect(() => {
    setCancerLogDetail(props.cancerLogDetail);
  }, [props.cancerLogDetail]);

  const buttonStyle = {
    margin: '0 7px',
    minWidth: '100px',
  };

  const handleChangeRecord = (record, onChangeFunction) => {
    const changedRecord = record;
    const { lists } = cancerLogDetail;
    const idx = cancerLogDetail.lists.findIndex((list) => list.local_dt === record.local_dt);
    onChangeFunction(changedRecord);

    setCancerLogDetail({
      ...cancerLogDetail,
      lists: [
        ...lists.slice(0, idx),
        changedRecord,
        ...lists.slice(idx + 1, lists.length),
      ],
    });
  };
  const options = () => (getOptionsByRefId(symptoms_ref));
  const symtomColumns = () => (cancerLogDetail.category ? Object.keys(symptoms_list_ref[cancerLogDetail.category]).map((key) => (
    <Column
      title={key}
      dataIndex="symptoms"
      key={key}
      render={(symptoms, record) => (
        <Select
          size="small"
          style={{ width: '100%' }}
          value={String(symptoms[key])}
          onChange={(changed) => {
            handleChangeRecord(record, (changedRecord) => {
              changedRecord.symptoms[key] = Number(changed);
            });
          }}
        >
          {options()}
        </Select>
      )}
    />
  )) : null);

  const symtomEtcColumn = () => (
    <Column
      title="기타증상"
      dataIndex="symptoms_etc"
      key="symptoms_etc"
      render={(etc, record) => (
        <Input
          value={etc}
          onFocus={() => {
            setEtcModal({
              visible: true,
              etc,
              record,
            });
          }}
        />
      )}
    />
  );

  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="이름">
          {props.cancerLogDetail.crm_nm || '-'}
        </Item>
        <Item label="연락처">
          {props.cancerLogDetail.crm_phone || '-'}
        </Item>
        <Item label="보험사명">
          {company_ref[props.cancerLogDetail.company_id] || '-'}
        </Item>
      </Descriptions>
      <Divider />

      <Table
        dataSource={cancerLogDetail.lists}
        rowKey={(record) => JSON.stringify(record) + Math.random()}
        pagination={{
          position: ['none', 'bottomCenter'],
        }}
      >
        <ColumnGroup>
          <Column
            title="기록일자"
            dataIndex="local_dt"
            key="local_dt"
            width="16%"
            render={(local_dt, record) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="small"
                  danger
                  onClick={async () => {
                    if (confirm('정말로 삭제하시겠습니까?')) {
                      try {
                        const res = await deleteCancerL(record.document_id);
                        if (res.result === 'error') { throw new Error(res.message); }
                        alert('삭제되었습니다');
                        props.onClose();
                      } catch (e) {
                        console.log(e);
                        alert('삭제가 비정상 종료되었습니다');
                      }
                    }
                  }}
                >
                </Button>
                <DatePicker
                  style={{
                    width: '125px',
                  }}
                  value={dayjs(local_dt)}
                />
              </div>
            )}
          />
        </ColumnGroup>
        <ColumnGroup
          title="증상"
        >
          {symtomColumns()}
          {symtomEtcColumn()}
        </ColumnGroup>
      </Table>
      <Row>
        <Space>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: '20px 0',
        }}
      >
        <div> */}
          <Button
            style={buttonStyle}
            onClick={props.onClose}
          >
            취소
          </Button>
          <SaveButton
            func={editCancerLDetail}
            data={cancerLogDetail}
            location="/cancer-logs"
          />
        {/* </div>
      </div> */}
      </Space>
      </Row>
      <Modal
        title="기타증상 입력"
        open={etcModal.visible}
        okText="저장"
        onOk={() => {
          handleChangeRecord(etcModal.record, (changedRecord) => {
            changedRecord.symptoms_etc = etcModal.etc;
          });
          setEtcModal(initEtcModal);
        }}
        cancelText="취소"
        onCancel={() => {
          setEtcModal(initEtcModal);
        }}
        keyboard
      >
        <Input
          value={etcModal.etc}
          onChange={(e) => {
            setEtcModal({
              ...etcModal,
              etc: e.target.value,
            });
          }}
        />
      </Modal>
    </>
  );
};

export default CancerLogDetailTable;
