import axios from 'axios';
import { baseUrl } from 'lib/url';
import { getCookie } from "lib/cookie";

/**
 * push의 전체 정보 조회
 */
export async function getPushList(setPush, push, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const result = await axios.post(
       `${baseUrl}/push/getList`,{
        goPage: goPage,
        pagingState: push.pagingState,
        searchState: searchState
    }).then((res) =>  {
      setPush(res.data);
      setCurrentPage(1);  
      setLoading(false);
    });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 push의 정보 조회
 * @param {string} document_id 조회하고자 하는 행사정보의 document id
 */
export async function getPushDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/push/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);
    
    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 push의 정보 수정
 * @param {object} push 수정하고자 하는 행사정보의 정보 및 수정 내용
 */
export async function editPushDetail(push) {
  try {
    // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(push).forEach((v) => {
      if (push[v] === undefined || push[v].length === 0) {
        delete push[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/push/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        push
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 새로운 push 등록
 * @param {object} push 등록하고자 하는 행사정보의 정보
 */
export async function postPush(push) {
  try {
    // 필수 파람(인자)가 없는 경우, 실패함을 리턴
    if ( !push.product_id || !push.nm || !push.phone ) {
      return { result: 'fail', message: '필수 입력사항을' };
    } else {
      const document_id = getCookie('session');

      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/push/add`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          document_id,
          push
        }),
      }).then((res) => {
        return { result: 'success' };
      }).catch((err) => {
        const res = err.response.data;
        if(res.status == 404) {
          return { result: 'fail', message: '고객정보를 다시' };
        } else {
          return { result: 'fail', message: '관리자에게' };
        }
      });

      return result;
    }
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * push 발송하기
 * @param {list} pushList 발송하고자 하는 대상 리스트
 */
export async function sendPush(pushList) {
  try {
    // 필수 파람(인자)가 없는 경우, 실패함을 리턴
    if ( pushList.length == 0 ) {
      return { result: 'fail', message: '발송 대상이 없습니다.' };
    } else {
      const result = await axios({
        method: 'POST',
        url: `${baseUrl}/push/send`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          pushList
        }),
      }).then((res) => {
        return { result: 'success' };
      }).catch((err) => {
        if(err.response.status == 400) {
          return { result: 'fail', message: '발송 대상이 없습니다.' };
        } else {
          return { result: 'fail', message: '관리자에게 문의해주세요.' };
        }
      });

      return result;
    }
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}