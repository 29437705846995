/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Descriptions, Divider, Input, InputNumber, Radio, Row, Select, Space, Switch } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import { applyType_ref, gender_ref, product_id_ref } from 'lib/dataRef';
import { editMemberDetail, makeNewPwd } from 'controllers/Services/members';
import { SaveButton, CancelButton } from 'components/Common';

const { Item } = Descriptions;
const MemberDetailTable = (props) => {
  const { memberDetail, setMemberDetail } = props;
  const [isLocked, setIsLocked] = useState(true);

  useEffect(() => {
    setIsLocked(true);
  }, [props.memberDetail]);

  const {
    type, nm, phone, gender, reg_dt, del_yn, del_dt, last_logged_dt, admin_msort, admin_memo, children,
    height, weight, diseases = [], id,
    weight_loss_yn, smoking_yn, drinking_yn, meals_per_day, daily_walking_mins, product_id
  } = memberDetail;

  const handleSelectChange = (value) => {
    setMemberDetail({ ...memberDetail, admin_msort: value });
  };
  const handleMemberDelChange = (value) => {
    setMemberDetail({ ...memberDetail, del_yn: value });
  };
  const childrenList = () => {
    if (children) {
      return children.map((child, idx) => (
        <>
          <Divider />
          <Row>
            <Descriptions
              title={(idx + 1) + ' 번 피보험자 ' + (nm === child.nm ? '(본인)' : '')}
              bordered
              column={1}
              style={{ width: '100%' }}
            >
              {
                child.child_yn
                  ? (
                    <>
                      <Item label="이름">
                        {child.nm}
                      </Item>
                      <Item label="성별">
                        {gender_ref[child.gender]}
                      </Item>
                      <Item label="생년월일">
                        {child.birth_dt}
                      </Item>
                      <Item label="프로필 이미지">
                        {child.profile_url}
                      </Item>
                    </>
                  )
                  : (
                    <>
                      <Item label="예정일">
                        {child.est_due_dt}
                      </Item>
                      <Item label="태명">
                        {child.bump_nm}
                      </Item>
                    </>
                  )
              }
              <Item label="등록일">
                {getFormattedDate(child.reg_dt, 'opt2')}
              </Item>
              <Item label="수정일">
                {getFormattedDate(child.mod_dt, 'opt2')}
              </Item>
              <Item label="삭제여부">
                {child.del_yn}
              </Item>
              <Item label="삭제일">
                {getFormattedDate(child.del_dt, 'opt2')}
              </Item>
            </Descriptions>
          </Row>
        </>
      ));
    }
    return false;
  };
  const walkingTimes = () => {
    const MINUTE_60 = 60;
    const current = daily_walking_mins;
    let h = parseInt(current / MINUTE_60, 0);
    const m = parseInt(current % MINUTE_60, 0);
    let changedH;
    let changedM;
    let changed_daily_walking_mins;
    const handleTimeChange = (changed, timeType) => {
      switch (timeType) {
      case 'h':
        changedH = changed;
        changed_daily_walking_mins = (changedH * MINUTE_60) + m;
        setMemberDetail({
          ...memberDetail,
          daily_walking_mins: changed_daily_walking_mins,
        });
        break;
      case 'm':
        changedM = changed;
        if (changedM === 60) {
          h += 1;
          changedM = 0;
        }
        if (changedM === -1) {
          h += -1;
          changedM = 59;
        }
        changed_daily_walking_mins = (h * MINUTE_60) + changedM;
        setMemberDetail({
          ...memberDetail,
          daily_walking_mins: changed_daily_walking_mins,
        });
        break;
      default:
        break;
      }
    };
    return (
      <Item label="하루 걷는 시간">
        <InputNumber
          min={0}
          max={24}
          value={parseInt(daily_walking_mins / MINUTE_60, 0)}
          onChange={(e) => handleTimeChange(e, 'h')}
        />{' 시'}
        <InputNumber
          min={-1}
          max={60}
          value={daily_walking_mins === -1 ? 0 : parseInt(daily_walking_mins % MINUTE_60, 0)}
          onChange={(e) => handleTimeChange(e, 'm')}
        />{' 분'}
      </Item>
    );
  };

  return (
    <>
      <Row>
        <Descriptions
          title="회원 정보"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="일반회원 여부">
            {!type || type === 'C' ? '일반회원' : '임시회원'}
          </Item>
          <Item label="상품코드">
            {product_id_ref[product_id]}
          </Item>
          <Item label="이름">
            {nm}
          </Item>
          <Item label="비밀번호">
            <Space>
              버튼 활성화
              <Switch checked={!isLocked} onChange={() => setIsLocked(!isLocked)} />
              <Button
                danger
                disabled={isLocked}
                onClick={() => makeNewPwd(memberDetail)}
              >
                비밀번호 초기화
              </Button>
            </Space>
          </Item>
          <Item label="연락처">
            <Space.Compact
              style={{
                width: '100%',
              }}
            >
              <Input
                value={phone}
                onChange={(e) => setMemberDetail({ ...memberDetail, phone: e.target.value })}
              />
            </Space.Compact>
          </Item>
          <Item label="성별">
            {gender_ref[gender]}
          </Item>
          <Item label="가입일">
            {getFormattedDate(reg_dt)}
          </Item>
          <Item label="회원탈퇴처리">
            <Select
              value={del_yn}
              style={{ width: '120px' }}
              onChange={handleMemberDelChange}
            >
              <Select.Option value={false}>정상</Select.Option>
              <Select.Option value>탈퇴</Select.Option>
            </Select>
          </Item>
          <Item label="탈퇴여부">
            {del_yn ? '탈퇴한 회원' : '-'}
          </Item>
          <Item label="탈퇴일자">
            {getFormattedDate(del_dt)}
          </Item>
          <Item label="최종접속일">
            {getFormattedDate(last_logged_dt)}
          </Item>
          <Item label="회원구분">
            <Select
              value={admin_msort || ''}
              style={{ width: '120px' }}
              onChange={handleSelectChange}
            >
              <Select.Option value="C">{applyType_ref.C}</Select.Option>
              <Select.Option value="T">{applyType_ref.T}</Select.Option>
            </Select>
          </Item>
          <Item label="관리자 메모">
            <Input.TextArea
              value={admin_memo}
              rows={10}
              allowClear
              onChange={(e) => setMemberDetail({ ...memberDetail, admin_memo: e.currentTarget.value })}
            />
          </Item>
        </Descriptions>
      </Row>
      {
        memberDetail.company_id && memberDetail.company_id === 'L03'
          ? (
            <Row>
              <Descriptions
                bordered
                column={1}
                style={{ width: '100%' }}
              >
                <Item label="키">
                  <Input
                    value={height || ''}
                    style={{ width: '30%' }}
                    onChange={(e) => setMemberDetail({ ...memberDetail, height: e.currentTarget.value })}
                  />{' cm'}
                </Item>
                <Item label="체중">
                  <Input
                    value={weight || ''}
                    style={{ width: '30%' }}
                    onChange={(e) => setMemberDetail({ ...memberDetail, weight: e.currentTarget.value })}
                  />{' kg'}
                </Item>
                <Item label="최근 6개월 간 5kg 이상 체중 감소">
                  <Radio.Group
                    value={weight_loss_yn}
                    onChange={(select) => {
                      setMemberDetail({
                        ...memberDetail,
                        weight_loss_yn: select.target.value,
                      });
                    }}
                  >
                    <Radio value={false}>아니오</Radio>
                    <Radio value>예</Radio>
                  </Radio.Group>
                </Item>
                <Item label="질환력">
                  <Checkbox.Group
                    value={diseases}
                    onChange={(checked) => setMemberDetail({ ...memberDetail, diseases: checked })}
                  >
                    <Row>
                      <Checkbox value="암">암</Checkbox>
                      <Checkbox value="뇌졸증">뇌졸증</Checkbox>
                      <Checkbox value="심근경색/협심증">심근경색/협심증</Checkbox>
                      <Checkbox value="고혈압">고혈압</Checkbox>
                    </Row>
                    <Row>
                      <Checkbox value="당뇨병">당뇨병</Checkbox>
                      <Checkbox value="이상지질혈증">이상지질혈증</Checkbox>
                      <Checkbox value="기타">기타</Checkbox>
                    </Row>
                  </Checkbox.Group>
                </Item>
                <Item label="흡연 여부">
                  <Radio.Group
                    value={smoking_yn || undefined}
                    onChange={(select) => setMemberDetail({ ...memberDetail, smoking_yn: select.target.value })}
                  >
                    <Radio value={0}>예</Radio>
                    <Radio value={1}>아니오</Radio>
                    <Radio value={2}>끊었다(한 달 이상)</Radio>
                  </Radio.Group>
                </Item>
                <Item label="음주 여부">
                  <Radio.Group
                    value={drinking_yn || undefined}
                    onChange={(select) => setMemberDetail({ ...memberDetail, drinking_yn: select.target.value })}
                  >
                    <Radio value={0}>예</Radio>
                    <Radio value={1}>아니오</Radio>
                    <Radio value={2}>끊었다(한 달 이상)</Radio>
                  </Radio.Group>
                </Item>
                <Item label="하루 식사 횟수">
                  <Radio.Group
                    value={meals_per_day}
                    onChange={(select) => setMemberDetail({ ...memberDetail, meals_per_day: select.target.value })}
                  >
                    <Radio value={0}>불규칙</Radio>
                    <Radio value={1}>1회</Radio>
                    <Radio value={2}>2회</Radio>
                    <Radio value={3}>3회</Radio>
                  </Radio.Group>
                </Item>
                {walkingTimes()}
              </Descriptions>
            </Row>
          )
          : null
      }
      {
        children
          ? childrenList()
          : null
      }
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/member"
          />
          <SaveButton
            func={editMemberDetail}
            data={memberDetail}
            location='/member'
          />
        </Space>
      </Row>
    </>
  );
};

export default MemberDetailTable;

