import axios from 'axios';
import sha256 from 'crypto-js/sha256';
import { getCookie } from 'lib/cookie';
import { baseUrl } from 'lib/url';

/**
 * 컬렉션에 저장된 모든 관리자 계정을 조회(생성시각 기준 내림차순)
 */
export async function getAdminsList(setAdmins, setCount, setLoading, searchState) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/admin/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        searchState,
      }),
    })
    .then((response) =>  response.data);

    setAdmins(result.lists);
    setCount(result.count);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 관리자 계정의 정보 조회
 * @param {string} document_id 해당 계정의 정보가 저장된 document의 id
 */
export async function getAdminDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/service/admin/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 관리자 계정 수정
 * @param {object} admin 해당 계정에 새롭게 입력될 값
 */
export async function editAdminDetail(admin) {
  // admin === getAdminDetail's return value
  try {
    const document_id = getCookie('session');

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/admin/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        admin,
        document_id
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 관리자 계정 신규 입력
 * @param {object} admin 신규로 입력될 계정의 정보(id, nm, pwd, ...)
 */
export async function insertAdmin(admin) {
  // admin keys : id, nm, pwd, department, permissions, ....(optional)
  try {
    // document에 들어가야 할 필수 값 체크
    if (!admin.nm && !admin.pwd && !admin.id && !admin.department && !admin.permissions) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const document_id = getCookie('session');

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/admin/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        admin,
        document_id
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 관리자 계정의 비밀번호 일치여부 조회
 * @param {string} document_id 해당 계정의 정보가 저장된 document의 id
 * @param {string} pwd 입력된 해당 계정의 비밀번호
 */
export async function getAdminPwdYn(document_id, pwd) {
  try {
    const pwdd = sha256(pwd).toString();  // 비밀번호 암호화
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/apply/pwnYn/${document_id}/${pwdd}`,
      headers: {
        'Content-Type': 'application/json',
      },
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 관리자 계정의 정보 조회
 * @param {string} document_id 해당 계정의 정보가 저장된 document의 id
 */
export async function deleteAdmin(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/service/admin/delete/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        docId: document_id,
      }),
    }).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import sha256 from 'crypto-js/sha256';
// import AES from 'crypto-js/aes';

// /**
//  * 컬렉션에 저장된 관리자 계정의 개수 조회
//  */
// export async function getAdminsSize() {
//   try {
//     const admsRef = await firestore.collection('adm_MEMBERS')
//       .get();
//     // '- 1' means that admin id doesn't included admin list.
//     return admsRef.size - 1;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 컬렉션에 저장된 모든 관리자 계정을 조회(생성시각 기준 내림차순)
//  */
// export async function getAdminsList() {
//   try {
//     const admsRef = await firestore.collection('adm_MEMBERS')
//       .orderBy('reg_dt', 'desc').get();
//     const admDocs = [];
//     admsRef.docs.forEach((doc) => {
//       if (doc.data().id !== 'admin') {
//         admDocs.push({
//           document_id: doc.data().document_id,
//           id: doc.data().id,
//           phone: doc.data().phone,
//           nm: doc.data().nm,
//           reg_dt: doc.data().reg_dt,
//           mod_dt: doc.data().mod_dt,
//           del_yn: doc.data().del_yn,
//           del_dt: doc.data().del_dt,
//           last_logged_dt: doc.data().last_logged_dt,
//           department: doc.data().department,
//           block_dt: doc.data().block_dt,
//           block_yn: doc.data().block_yn,
//         });
//       }
//     });
//     return admDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 관리자 계정의 정보 조회
//  * @param {string} document_id 해당 계정의 정보가 저장된 document의 id
//  */
// export async function getAdminDetail(document_id) {
//   try {
//     const admRef = await firestore.collection('adm_MEMBERS')
//       .doc(document_id).get();
//     return {
//       document_id: admRef.data().document_id,
//       id: admRef.data().id,
//       phone: admRef.data().phone,
//       nm: admRef.data().nm,
//       reg_dt: admRef.data().reg_dt,
//       mod_dt: admRef.data().mod_dt,
//       del_yn: admRef.data().del_yn,
//       del_dt: admRef.data().del_dt,
//       last_logged_dt: admRef.data().last_logged_dt,
//       pwd_mod_dt: admRef.data().pwd_mod_dt,
//       permissions: admRef.data().permissions,
//       department: admRef.data().department,
//       other_admin_sites: {
//         db: {
//           id: admRef.data().other_admin_sites.db.id,
//         },
//         ss: {
//           id: admRef.data().other_admin_sites.ss.id,
//         },
//       },
//       block_dt: admRef.data().block_dt,
//       block_yn: admRef.data().block_yn,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 관리자 계정 수정
//  * @param {object} admin 해당 계정에 새롭게 입력될 값
//  */
// export async function editAdminDetail(admin) {
//   // admin === getAdminDetail's return value
//   try {
//     // 기존에 DB에 저장되어있던 값 읽어오기
//     const original = await firestore.collection('adm_MEMBERS')
//       .doc(admin.document_id).get();

//     // 잘못 덮어씌워지면 안 되는 값 제거
//     delete admin.mod_dt;
//     if (admin.pwd !== undefined && admin.pwd.length > 0) {
//       admin.pwd = sha256(admin.pwd).toString();
//     } else {
//       delete admin.pwd;
//     }

//     const { db: { id: dbId, pwd: dbPwd }, ss: { id: ssId, pwd: ssPwd } } = admin.other_admin_sites;

//     if (!dbId) {
//       delete admin.other_admin_sites.db.id;
//       delete admin.other_admin_sites.db.pwd;
//     }

//     if (!ssId) {
//       delete admin.other_admin_sites.ss.id;
//       delete admin.other_admin_sites.ss.pwd;
//     }

//     if (dbPwd) {
//       admin.other_admin_sites.db.pwd = AES.encrypt(dbPwd, 'FINElab2020').toString();
//     }

//     if (ssPwd) {
//       admin.other_admin_sites.ss.pwd = AES.encrypt(ssPwd, 'FINElab2020').toString();
//     }

//     // 현재 들어온 값과 비교하기 위한 DB에 인서트된 값을 담은 object
//     const checkChg = {
//       pwd: original.pwd,
//       nm: original.nm,
//       phone: original.phone,
//       department: original.department,
//       permissions: original.permissions,
//       other_admin_sites: original.other_admin_sites,
//     };

//     // 변경된 사항이 있다면 mod_dt(수정시각)을 업데이트
//     Object.keys(checkChg).forEach((v) => {
//       if (checkChg[v] !== admin[v]) {
//         admin.mod_dt = Math.floor(new Date().getTime());
//       }
//     });

//     // 아예 키가 선언도 되지 않았거나 빈 값이 들어있는 경우, 삭제 처리
//     // (값이 undefined인 상태로 document를 업데이트 하려 하면 에러 발생)
//     Object.keys(admin).forEach((v) => {
//       if (admin[v] === undefined || admin[v].length === 0) {
//         delete admin[v];
//       }
//     });
//     if (admin.mod_dt !== undefined && admin.mod_dt > 0) {
//       // del_yn(삭제여부)가 true 처리 되었다면, del_dt(삭제시각)도 업데이트
//       if (admin.del_yn && (admin.del_dt === undefined || admin.del_dt === 0)) {
//         admin.del_dt = admin.mod_dt;
//       }
//       // block_yn(블락여부)가 true 처리 되었다면, block_dt(블락시각)도 업데이트
//       if (admin.block_yn && (admin.block_dt === undefined || admin.block_dt === 0)) {
//         admin.block_dt = admin.mod_dt;
//       }
//       // 비밀번호가 변경되었다면, pwd_mod_dt(비밀번호 변경시각)도 업데이트
//       if (admin.pwd !== checkChg.pwd) {
//         admin.pwd_mod_dt = admin.mod_dt;
//       }

//       // document 값 업데이트
//       await firestore.collection('adm_MEMBERS').doc(admin.document_id).set(admin, { merge: true });
//       return { result: 'success', message: 'The admin member information is updated.' };
//     } else {
//       return { result: 'fail', message: 'The member information is the same as before.' };
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 관리자 계정 신규 입력
//  * @param {object} admin 신규로 입력될 계정의 정보(id, nm, pwd, ...)
//  */
// export async function insertAdmin(admin) {
//   // admin keys : id, nm, pwd, department, permissions, ....(optional)
//   try {
//     // document에 들어가야 할 필수 값 체크
//     if (!admin.nm && !admin.pwd && !admin.id && !admin.department && !admin.permissions) {
//       return { result: 'fail', message: 'Please check the values.' };
//     }

//     // 블락되지 않은 동일한 아이디가 존재하는지 확인
//     const checkId = await firestore.collection('adm_MEMBERS')
//       .where('id', '==', admin.id).where('block_yn', '==', false).get();
//     if (checkId.size > 0) {
//       return { result: 'fail', message: 'Please check the id.' };
//     }

//     // 기본적으로 들어가야 할 값 가공 및 처리
//     admin.reg_dt = Math.floor(new Date().getTime());
//     admin.pwd = sha256(admin.pwd).toString();
//     admin.del_yn = false;
//     admin.block_yn = false;

//     // DB와 삼성 어드민 계정이 존재하는 경우, 비밀번호 암호화 처리
//     const { db: { pwd: dbPwd }, ss: { pwd: ssPwd } } = admin.other_admin_sites;
//     if (dbPwd) {
//       admin.other_admin_sites.db.pwd = AES.encrypt(dbPwd, 'FINElab2020').toString();
//     }
//     if (ssPwd) {
//       admin.other_admin_sites.ss.pwd = AES.encrypt(ssPwd, 'FINElab2020').toString();
//     }

//     // document 생성 및 값 입력
//     await firestore.collection('adm_MEMBERS').add(admin)
//       .then((doc) => {
//         firestore.collection('adm_MEMBERS').doc(doc.id)
//           .set({ document_id: doc.id }, { merge: true });
//       }).catch((err) => {
//         console.error(err);
//       });
//     return { result: 'success', message: 'The admin member is inserted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }