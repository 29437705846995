import React, { useState } from 'react';
import { Descriptions, Divider, Input, Radio, Select, Space, Drawer } from 'antd';
import { company_ref, product_id_ref, product_by_company_ref } from 'lib/dataRef';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { postHash } from 'controllers/Systems/appHash';
import getOptionsByRefId from 'lib/getOptionsByRefId';

const { Item } = Descriptions;
const AppHashAddTable = () => {
    const [appHash, setAppHash] = useState({});
    const [drawerStatus, setDrawerStatus] = useState(false);
    const handleDrawerShow = () => {
        setDrawerStatus({
        visible: true,
        });
    };
    const handleDrawerClose = () => {
        setDrawerStatus({
        visible: false,
        });
        setAppHash(appHash);
    };
    const options = () => (getOptionsByRefId(company_ref));

    const productIdOptions = () => {
        if(appHash.company_id == undefined) {
            return getOptionsByRefId(product_id_ref);
        }else {
            return getOptionsByRefId(product_by_company_ref(appHash.company_id));
        }
    }
    return (
        <>
       <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
            <Descriptions
            title="해시 추가"
            bordered
            column={1}
            style={{ width: '100%' }}
            >
            <Item label="보험사">
                <Select
                style={{ width: '200px' }}
                onChange={(e) => setAppHash({ ...appHash, company_id: e })}
                >
                    {options()}
                </Select>
            </Item>
            <Item label="상품코드">
                <Select
                style={{ width: '200px' }}
                onChange={(e) => setAppHash({ ...appHash, product_id: e })}
                >
                    {productIdOptions()}
                </Select>
            </Item>
            <Item label="os 구분">
                <Select
                style={{ width: '200px' }}
                onChange={(e) => setAppHash({ ...appHash, os: e })}
                options={[
                    {
                        value: 'AOS',
                        label: 'Android'
                    },
                    {
                        value: 'IOS',
                        label: 'IOS'
                    },
                ]}
                />
            </Item>
            <Item label="해시 키">
                <Input
                value={appHash.hash}
                onChange={(e) => setAppHash({ ...appHash, hash: e.target.value })}
                />
            </Item>
            </Descriptions>
        <Divider />
            <Space>
            <CancelButton
                location="/app-hash"
            />
            <SaveButton
                func={postHash}
                data={appHash}
                location="/app-hash"
            />
            </Space>
            </Drawer>
        </>
    );
};

export default AppHashAddTable;
