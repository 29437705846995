import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate } from 'components/Common';
import { Button, Drawer, Popover } from 'antd';
import { getHealthyBodySearchList } from 'controllers/Outers/healthyBody';
import { getFormattedDate } from 'lib/getFormattedDate';
import HealthyBodyDetailTableContainer from './HealthyBodyDetailTableContainer';
import { routes_ref } from 'lib/routeRef';
import { program_type_cd_ref, body_type_ref, gender_ref } from 'lib/dataRef';
import { maskName } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const HealthyBodyListTableContainer = () => {
  const [healthyBody, setHealthyBody] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
    type: '',
    program_cd: '',
  });

  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
      type: '',
    });
  };

  const showDrawer = (type, obj) => {
    if(type == 'MIDDLE' && !obj.middle_report_q_dt) {
        return alert('중간평가가 완료되지 않았습니다.');
    } else if (type == 'LAST' && !obj.last_report_q_dt) {
      return alert('최종평가가 완료되지 않았습니다.');
    } else {
      setStatus({
        visible: true,
        document_id: obj.document_id,
        type,
        program_cd: obj.program_cd,
      });
    }
  };

  useEffect(() => {
    getHealthyBodySearchList(setHealthyBody, setCount, setLoading);
  }, []);

  const columns = [
    {
      title: '번호',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      render: (no) => `${no || 0}`,
    },
    {
      title: '프로그램\n구분',
      dataIndex: 'program_cd',
      key: 'program_cd',
      align: 'center',
      render: (data) => program_type_cd_ref[data]
    },
    {
      title: '프로그램\n명',
      dataIndex: 'body_type_cd',
      key: 'body_type_cd',
      align: 'center',
      render: (data) => body_type_ref[data]
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      render: (data) => gender_ref[data]
    },
    {
      title: '생년월일',
      dataIndex: 'birth_dt',
      key: 'birth_dt',
      align: 'center',
      render: (birth_dt) => (
        <div style={{ textAlign: 'center' }}>
          {birth_dt}
        </div>
      ),
    },
    {
      title: '운동\n시작일',
      dataIndex: 'start_dt',
      key: 'start_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt1'),
    },
    {
      title: '운동\n종료일',
      dataIndex: 'end_dt',
      key: 'end_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt1'),
    },
    {
      title: '운동참여\n횟수',
      dataIndex: 'total_exercise_cnt',
      key: 'total_exercise_cnt',
      align: 'center',
      render: (total_exercise_cnt) => (
        <div style={{ textAlign: 'center' }}>
          {total_exercise_cnt}
        </div>
      ),
    },
    {
      title: '상담참여\n횟수',
      dataIndex: 'total_counseling_cnt',
      key: 'total_counseling_cnt',
      align: 'center',
      render: (total_counseling_cnt, obj) => {
        return (
          obj.total_counseling_cnt > 0 ?
          <>
            <div
              style={{ backgroundColor: '#52c41a', textAlign: 'center', verticalAlign: 'middle', width: '100%', borderRadius: '10px', color: 'white', cursor: 'pointer', fontWeight: 'bold' }}
              onClick={() => showDrawer('COUNSELING', obj)}
            >
              {obj.completion_cnt+' / '+total_counseling_cnt}
            </div>
          </>
          :
          <div
            style={{ backgroundColor: '#DEDEDE', textAlign: 'center', verticalAlign: 'middle', width: '100%', borderRadius: '10px', color: 'black' }}
          >
            {'-'}
          </div>
        )
      },
    },
    {
      title: '보고서',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id, obj) => {
       return  <>
        <Popover
          content={
            <div>
              <Button onClick={() => {insertInfoAcessLog(location.pathname, 'bodyReport-NOW', "조회", undefined, document_id), showDrawer('NOW', obj)}}>현재</Button>
              <Button onClick={() => {insertInfoAcessLog(location.pathname, 'bodyReport-MIDDLE', "조회", undefined, document_id), showDrawer('MIDDLE', obj)}}>중간</Button>
              <Button onClick={() => {insertInfoAcessLog(location.pathname, 'bodyReport-LAST', "조회", undefined, document_id), showDrawer('LAST', obj)}}>최종</Button>
            </div>
          }
          title='보고서를 선택하세요.'
          trigger='click'
          >
            <Button
              type="primary"
              size="small"
            >
              관리
            </Button>
          </Popover>
        </>
      }
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.outers.healthy_body.path}
          />
        )}
        countLabel={(<CounterLabel count={count} />)}
      />
      <ListTableTemplate
        dataSource={healthyBody}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
          <Drawer
            width={1000}
            closable
            onClose={onClose}
            open={status.visible}
          >
            <HealthyBodyDetailTableContainer
              document_id={status.document_id}
              type={status.type}
              program_cd={status.program_cd}
              props={healthyBody}
            />
          </Drawer>
        )}
      />
    </>
  );
};

export default HealthyBodyListTableContainer;
