import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 질병 예측의 전체 정보 조회
 * @param {*} setDzPreds client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 */
export async function getDzPredsList(
  setDzPreds,
  dzPreds,
  goPage,
  setCurrentPage,
  searchState,
  setLoading
) {
  try {
    const result = await axios
      .post(`${baseUrl}/health/dzPreds/getList`, {
        goPage: goPage,
        pagingState: dzPreds.pagingState,
        searchState: searchState,
      })
      .then((res) => {
        setDzPreds(res.data);
        setCurrentPage(1);
        setLoading(false);
      });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 질병 예측의 정보 조회
 * @param {string} document_id 조회하고자 하는 질병 예측의 document id
 */
export async function getDzPredDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/health/dzPreds/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 질병 예측의 정보 수정
 * @param {object} dzPred 수정하고자 하는 질병 예측의 정보 및 수정 내용
 */
export async function editDzPredDetail(dzPred) {
  // dzPred === getDzPredDetail function's return value
  try {
    // 삭제 시각이 0 초과인 경우, 이미 삭제됨을 리턴
    if (dzPred.del_dt > 0) {
      return { result: 'fail', message: 'The diseasePrediction was deleted.' };
    }
    // 삭제 여부가 false인 경우, 삭제만 가능함을 리턴
    if (!dzPred.del_yn) {
      return {
        result: 'fail',
        message: 'The diseasePrediction can only be deleted.',
      };
    }
    // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(dzPred).forEach((v) => {
      if (dzPred[v] === undefined || dzPred[v].length === 0) {
        delete dzPred[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/health/dzPreds/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        dzPred,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 질병 예측의 건수 조회
//  */
// export async function getDzPredsSize() {
//   try {
//     const dzPredsRef = await firestore.collection('cust_DZPRED')
//       .get();
//     return dzPredsRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 질병 예측의 전체 정보 조회
//  * @param {*} setDzPreds client에서 전달하는 React.Dispatch
//  * @param {*} setCount client에서 전달하는 React.Dispatch
//  */
// export async function getDzPredsList(setDzPreds, setCount, setLoading) {
//   try {
//     // 컬렉션 내에서 변동이 있을 경우(새로운 질병 예측 추가, 기존 질병 예측 변동 등),
//     // 리로드 없이도 변동 사항을 반영하기 위해 onSnapshot 이용
//     // (onSnapshot에 대한 자세한 사항은 firebase documentation을 참고하길 바람)
//     const aplsRef = await firestore.collection('cust_DZPRED')
//       .orderBy('reg_dt', 'desc')
//       .onSnapshot((snap) => {
//         const aplDocs = [];
//         const changes = snap.docChanges();

//         changes.forEach((change) => {
//           if (change.type === 'added') {
//             if (changes.length > 1) {
//               aplDocs.push({
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 product_id: change.doc.data().product_id,
//                 crm_nm: change.doc.data().crm_nm,
//                 crm_phone: change.doc.data().crm_phone,
//                 status: change.doc.data().status,
//                 reg_dt: change.doc.data().reg_dt,
//                 mod_dt: change.doc.data().mod_dt,
//                 del_yn: change.doc.data().del_yn,
//                 del_dt: change.doc.data().del_dt,
//                 pdf_file: change.doc.data().pdf_file,
//               });
//             } else {
//               snap.forEach((doc) => {
//                 aplDocs.push({
//                   document_id: doc.data().document_id,
//                   company_id: doc.data().company_id,
//                   product_id: doc.data().product_id,
//                   crm_nm: doc.data().crm_nm,
//                   crm_phone: doc.data().crm_phone,
//                   status: doc.data().status,
//                   reg_dt: doc.data().reg_dt,
//                   mod_dt: doc.data().mod_dt,
//                   del_yn: doc.data().del_yn,
//                   del_dt: doc.data().del_dt,
//                   pdf_file: doc.data().pdf_file,
//                 });
//               });
//             }
//           }
//           if (change.type === 'modified') {
//             snap.forEach((doc) => {
//               aplDocs.push({
//                 document_id: doc.data().document_id,
//                 company_id: doc.data().company_id,
//                 product_id: doc.data().product_id,
//                 crm_nm: doc.data().crm_nm,
//                 crm_phone: doc.data().crm_phone,
//                 status: doc.data().status,
//                 reg_dt: doc.data().reg_dt,
//                 mod_dt: doc.data().mod_dt,
//                 del_yn: doc.data().del_yn,
//                 del_dt: doc.data().del_dt,
//                 pdf_file: doc.data().pdf_file,
//               });
//             });
//           }
//           if (change.type === 'removed') {
//             snap.forEach((doc) => {
//               aplDocs.push({
//                 document_id: doc.data().document_id,
//                 company_id: doc.data().company_id,
//                 product_id: doc.data().product_id,
//                 crm_nm: doc.data().crm_nm,
//                 crm_phone: doc.data().crm_phone,
//                 status: doc.data().status,
//                 reg_dt: doc.data().reg_dt,
//                 mod_dt: doc.data().mod_dt,
//                 del_yn: doc.data().del_yn,
//                 del_dt: doc.data().del_dt,
//                 pdf_file: doc.data().pdf_file,
//               });
//             });
//           }
//         });
//         setDzPreds(aplDocs);
//         setCount(aplDocs.length);
//         setLoading(false);
//       });
//     return aplsRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 질병 예측의 정보 조회
//  * @param {string} document_id 조회하고자 하는 질병 예측의 document id
//  */
// export async function getDzPredDetail(document_id) {
//   try {
//     const dzPredRef = await firestore.collection('cust_DZPRED')
//       .doc(document_id).get();
//     const product_id = String(dzPredRef.data().product_id).split('_');
//     return {
//       document_id: dzPredRef.data().document_id,
//       company_id: dzPredRef.data().company_id,
//       app_code: product_id[0],
//       goods_code: product_id[1],
//       product_id: dzPredRef.data().product_id,
//       crm_nm: dzPredRef.data().crm_nm,
//       crm_phone: dzPredRef.data().crm_phone,
//       checkup_dt: dzPredRef.data().checkup_dt,
//       reg_number: dzPredRef.data().reg_number,
//       gender: dzPredRef.data().gender,
//       birth_dt: dzPredRef.data().birth_dt,
//       age: dzPredRef.data().age,
//       metabolic_age: dzPredRef.data().metabolic_age,
//       status: dzPredRef.data().status,
//       reg_dt: dzPredRef.data().reg_dt,
//       mod_dt: dzPredRef.data().mod_dt,
//       del_yn: dzPredRef.data().del_yn,
//       del_dt: dzPredRef.data().del_dt,
//       pdf_file: dzPredRef.data().pdf_file,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 질병 예측의 정보 수정
//  * @param {object} dzPred 수정하고자 하는 질병 예측의 정보 및 수정 내용
//  */
// export async function editDzPredDetail(dzPred) { // dzPred === getDzPredDetail function's return value
//   try {
//     // 삭제 시각이 0 초과인 경우, 이미 삭제됨을 리턴
//     if (dzPred.del_dt > 0) {
//       return { result: 'fail', message: 'The diseasePrediction was deleted.' };
//     }
//     // 삭제 여부가 false인 경우, 삭제만 가능함을 리턴
//     if (!dzPred.del_yn) {
//       return { result: 'fail', message: 'The diseasePrediction can only be deleted.' };
//     }
//     // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//     Object.keys(dzPred).forEach((v) => {
//       if (dzPred[v] === undefined || dzPred[v].length === 0) {
//         delete dzPred[v];
//       }
//     });
//     dzPred.mod_dt = Math.floor(new Date().getTime());
//     dzPred.del_dt = dzPred.mod_dt;

//     await firestore.collection('cust_DZPRED').doc(dzPred.document_id).set(dzPred, { merge: true });

//     return { result: 'success', message: 'The diseasePrediction is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }
