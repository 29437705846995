import React from 'react';
import { editHelperGroupDetail } from 'controllers/Outers/helperGroups';
import { Descriptions, Divider, Input, Row, Select, Space } from 'antd';
import { CancelButton, SaveButton } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import { helpergroup_type_ref } from 'lib/dataRef';

const { Item } = Descriptions;
const HelperGroupsDetailTable = (props) => {
    const { helperGroup, setHelperGroup } = props;

  function handleChangeHelperGroupsType(selected) {
    setHelperGroup({
      ...helperGroup,
      type: selected,
    });
  }

  return (
    <>
      <Descriptions
        title="간병/가사도우미 업체 수정"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="구분">
          <Select
            disabled
            style={{ width: '150px' }}
            // onChange={handleChangeHelperGroupsType}
            value={helperGroup.type}
          >
            {getOptionsByRefId(helpergroup_type_ref)}
          </Select>
        </Item>

        <Item label="업체명">
          <Input
            value={helperGroup.nm}
            onChange={(e) => {
                setHelperGroup({
                ...helperGroup,
                nm: e.target.value,
              });
            }}
          />
        </Item>
        <Item
          label="담당자 연락처"
        >
          <Input
            value={helperGroup.phone}
            onChange={(e) => {
                setHelperGroup({
                ...helperGroup,
                phone: e.target.value,
              });
            }}
          />
        </Item>
        <Item
          label="이메일"
        >
          <Input
            value={helperGroup.email}
            onChange={(e) => {
                setHelperGroup({
                ...helperGroup,
                email: e.target.value,
              });
            }}
          />
        </Item>
      </Descriptions>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location={routes_ref.base.outers.helper_group.path}
          />
          {/* TODO: 저장버튼 기능구현 완성하고 기능 체크하기 */}
          <SaveButton
            func={editHelperGroupDetail}
            data={helperGroup}
            location={routes_ref.base.outers.helper_group.path}
          />
        </Space>
      </Row>
    </>
  );
};

export default HelperGroupsDetailTable;
