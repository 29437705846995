import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 복약관리/알람의 정보 조회
 */
export async function getMedicinesList(setMedicens, setCount, setLoading, searchState) {
  try {
    const res = await axios.post(
      `${baseUrl}/medicines/getList`,{
        searchState,
      }).then((res) => {
        setMedicens(res.data.data);
        setCount(res.data.count);
        setLoading(false);
      }).catch((err) => {
        console.error(err);
      });

    return res;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 고객의 복약관리/알람 상세 정보 조회
 * @param {string} member_id 조회하고자 하는 고객의 id
 */
export async function getMedicineDetail(member_id) {
  try {
    return await axios.get(
      `${baseUrl}/medicines/getDetail/${member_id}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err);
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// let size;
// /**
//  * 복약관리/알람의 총 갯수 조회
//  */
// export function getMedicinesSize() {
//   return size;
// }

// /**
//  * 복약관리/알람의 정보 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getMedicinesList(app_code) {
//   try {
//     const now = Math.floor(new Date().getTime());
//     const medsRef = await firestore.collection('cust_MEDICINES')
//       .orderBy('med_end_dt', 'desc').get();
//     const medDocs = [{
//       company_id: medsRef.docs[0].data().company_id,
//       member_id: medsRef.docs[0].data().member_id,
//       crm_nm: medsRef.docs[0].data().crm_nm,
//       crm_phone: medsRef.docs[0].data().crm_phone,
//       pre_name: medsRef.docs[0].data().pre_name,
//       pre_hospital: medsRef.docs[0].data().pre_hospital,
//       pre_dt: medsRef.docs[0].data().pre_dt,
//       med_end_dt: medsRef.docs[0].data().med_end_dt,
//       med_end_yn: medsRef.docs[0].data().med_end_dt < now,
//       count: 0,
//     }];

//     medsRef.docs.forEach((doc) => {
//       let check = false;
//       for (let i = 0; i < medDocs.length; i++) {
//         if (doc.data().member_id === medDocs[i].member_id) {
//           check = true;
//           medDocs[i].count++;
//         }
//       }
//       if (!check) {
//         medDocs.push({
//           company_id: doc.data().company_id,
//           member_id: doc.data().member_id,
//           crm_nm: doc.data().crm_nm,
//           crm_phone: doc.data().crm_phone,
//           pre_name: doc.data().pre_name,
//           pre_hospital: doc.data().pre_hospital,
//           pre_dt: doc.data().pre_dt,
//           med_end_dt: doc.data().med_end_dt,
//           med_end_yn: doc.data().med_end_dt < now,
//           count: 1,
//         });
//       }
//     });

//     size = medDocs.length;
//     return medDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 고객의 복약관리/알람 상세 정보 조회
//  * @param {string} member_id 조회하고자 하는 고객의 id
//  */
// export async function getMedicineDetail(member_id) {
//   try {
//     const now = Math.floor(new Date().getTime());
//     const medsRef = await firestore.collection('cust_MEDICINES')
//       .where('member_id', '==', member_id).orderBy('med_end_dt', 'desc')
//       .get();
//     const medDocs = [];
//     medsRef.docs.forEach((doc) => {
//       const alertList = [];
//       for (let i = 0; i < 6; i++) {
//         if (doc.data().alerts[i].visible) {
//           alertList.push({ time: doc.data().alerts[i].time });
//         } else {
//           break;
//         }
//       }
//       medDocs.push({
//         company_id: doc.data().company_id,
//         document_id: doc.data().document_id,
//         member_id: doc.data().member_id,
//         alerts: alertList,
//         crm_nm: doc.data().crm_nm,
//         crm_phone: doc.data().crm_phone,
//         del_dt: doc.data().del_dt,
//         del_yn: doc.data().del_yn,
//         mod_dt: doc.data().mod_dt,
//         reg_dt: doc.data().reg_dt,
//         img_url: doc.data().img_url,
//         med_start_dt: doc.data().med_start_dt,
//         med_end_dt: doc.data().med_end_dt,
//         med_end_yn: doc.data().med_end_dt < now,
//         pre_hospital: doc.data().pre_hospital,
//         pre_name: doc.data().pre_name,
//         pre_dt: doc.data().pre_dt,
//         alarm_yn: doc.data().alarm_yn,
//       });
//     });
//     return medDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }