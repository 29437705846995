import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import { useCheckAuth } from 'lib/hooks';
import ChatCenterAddContainer from 'containers/chatbot/ChatCenter/ChatCenterAddContainer';
import { useLocation } from 'react-router';

const ChatCenterAddPage = ({ pid }) => {
  useCheckAuth(pid);
  const location = useLocation();
  console.log('기관관리 등록/수정');
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['chatbot', 'chatbot-contents']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<ChatCenterAddContainer props={location} />}
    />
  );
};

export default ChatCenterAddPage;
