import React, { useState } from 'react';
import { AddButton, CancelButton } from 'components/Common';
import { Button, Descriptions, Divider, Drawer, Input, Row, Select, Space } from 'antd';
import { company_ref, gender_ref } from 'lib/dataRef';
import { routes_ref } from 'lib/routeRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import { insertMember } from 'controllers/Services/members';
const { Item } = Descriptions;

const MembersAddTable = () => {
  const [member, setMember] = useState({
    company_id: 'L03',
    product_id: 'SamsungLife01_0000',
  });
  const [drawerStatus, setDrawerStatus] = useState({
    visible: false,
  });
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setMember({
    });
  };
  const handleChangeCompanyId = (company_id) => {
    setMember({
      ...member,
      company_id,
    });
  };
  const handleChangeGender = (gender) => {
    setMember({
      ...member,
      gender,
    });
  };

  return (
    <>
      <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={480}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
        <Descriptions
          title="회원 정보 추가"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="보험사">
            <Select
              style={{ width: '150px' }}
              onChange={handleChangeCompanyId}
              value={member.company_id}
              disabled
            >
              {getOptionsByRefId(company_ref)}
            </Select>
          </Item>
          <Item label="이름">
            <Input
              value={member.nm}
              onChange={(e) => {
                setMember({
                  ...member,
                  nm: e.target.value,
                });
              }}
            />
          </Item>
          <Item label="연락처">
            <Input
              value={member.phone}
              onChange={(e) => {
                setMember({
                  ...member,
                  phone: e.target.value,
                });
              }}
            />
          </Item>
          <Item label="성별">
            <Select
              style={{ width: '150px' }}
              onChange={handleChangeGender}
              value={member.gender}
            >
              {getOptionsByRefId(gender_ref)}
            </Select>
          </Item>
        </Descriptions>
        <Divider />
        <Row>
          <Space>
            <CancelButton
              location={routes_ref.base.members.member.path}
            />
            <Button
              type="primary"
              onClick={() => insertMember(member)}
            >
              저장하기
            </Button>
          </Space>
        </Row>
      </Drawer>
    </>
  );
};

export default MembersAddTable;
