import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { baseUrl } from 'lib/url';
import axios from 'axios';

/**
 * 컬렉션에 저장된 서비스 신청의 정보 조회
 * @param {*} setApplies client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
 */
export async function getAppliesList(setApplies, applies, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage,
        pagingState: applies.pagingState,
        searchState: searchState
      })
    })
    .then((response) =>  response);
    setApplies(result.data);
    setCurrentPage(1);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 컬렉션에 저장된 모든 도우미 회사에 대한 정보 조회
 * @param {string} category2 중분류
 */
export async function getHelperGroupsList(category2) { // category2 === cust_APPLIES.category2
  try {
    // 중분류가 들어오지 않는 경우는 조회할 수 없음
    if (category2 === undefined) {
      return { result: 'fail', message: 'There is no category2.' };
    }

    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/service/applies/getHGList/${category2}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 컬렉션에 저장된 모든 도우미에 대한 정보 조회
 * @param {string} helpergroup_type 도우미 회사의 분류
 * @param {string} helpergroup_nm 도우미 회사의 이름
 * @param {string} company_id 보험사 아이디
 */
export async function getHelpersList(helpergroup_type, helpergroup_nm, company_id) {
  try {
    // 인자가 하나라도 누락이 되어있다면 진행되지 않음
    if (helpergroup_type === undefined || helpergroup_nm === undefined
      || company_id === undefined) {
      return { result: 'fail', message: 'Param is a must.' };
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/getHelpersList`,
      data: JSON.stringify({
        helpergroup_type, helpergroup_nm, company_id
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 컬렉션에서 특정 document에 담긴 서비스 신청의 정보를 조회
 * @param {string} document_id 조회하고자 하는 document의 id
 * @param {string} category1 대분류
 * @param {string} category2 중분류
 */
export async function getApplyDetail(document_id, category1, category2) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/getDetail`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        document_id, category1, category2
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/* export async function getApplyCountDetail(member_doc_id) {
  try {
    const acsRef = await firestore.collection('cust_APPLIESCOUNT')
      .where('member_doc_id', '==', member_doc_id)
      .where('del_yn', '==', false)
      .get();

    if (acsRef.size < 0) {
      return { result: 'fail', message: 'There is no applies count.' };
    }
    return {
      free: acsRef.docs[0].data().free,
      discount: acsRef.docs[0].data().discount,
    };
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
} */

/**
 * 특정 도우미의 정보 조회
 * @param {string} helper_doc_id 해당 도우미의 정보가 담긴 document의 id
 */
export async function getHelperDetail(helper_doc_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/service/applies/getHelperDetail/${helper_doc_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 컬렉션에서 특정 도우미 회사에 대한 정보 조회
 * @param {string} helpergroup_doc_id 특정 도우미 회사의 정보가 담긴 document의 id
 */
export async function getHelperGroupDetail(helpergroup_doc_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/service/applies/getHGDetail/${helpergroup_doc_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 도우미와 관련하여 문자메세지(SMS)를 발송
 * @param {string} category2 중분류
 * @param {string} option 문자메세지의 용도(목적)
 * @param {string} apl_doc_id 특정 서비스 신청의 document id
 * @param {string} helper_doc_id 도우미의 document id
 */
export async function sendMessage(category2, option, apl_doc_id, helper_doc_id) {
  try {
    if ((category2 === undefined || category2.length === 0)
      || (option === undefined || option.length === 0)
      || (apl_doc_id === undefined || apl_doc_id.length === 0)
      || (helper_doc_id === undefined || helper_doc_id.length === 0)) {
      throw new Error('Please check param.');
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/sendMessage`,
      data: JSON.stringify({
        category2, option, apl_doc_id, helper_doc_id
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/* -- INFO ABOUT PARAM WHEN PAPPLY --
apply = {
  document_id,
  company_id,
  product_id,
  category1,
  category2,
  member_id,
  member_doc_id,
  crm_phone,
  crm_nm,
  status,
  member_nm: optional,
  member_ph: optional,
  member_addr: optional,
  service_nm: optional,
  service_dt: optional,
  service_hours: optional,
  service_days: optional,
  helper_list: optional [{
    helpergroup_doc_id,
    helpergroup_nm,
    helpergroup_type,
    helper_doc_id,
    helper_nm,
    helper_phone,
    helper_birth_dt,
    helper_local_yn,
  }, {...}],
  evaluation: optional (Array<number>),
  claim_yn:
  claim_detail_memo: optional,
}
 */
/**
 * 특정 서비스 신청의 정보를 수정
 * @param {object} apply 서비스 신청에 업데이트될 정보
 */
export async function editApplyDetail(apply) { // apply === getApplyDetail function's return value
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        apply
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/* -- INFO ABOUT PARAM --
datas = {
  memberType: return value of API(HY),
  newMemberDocObj: return value of API(HY),
  apply: {
    company_id,
    product_id,
    category1,
    category2,
    member_id,
    member_doc_id,
    crm_phone,
    crm_nm,
    member_nm: optional,
    member_ph: optional,
    member_addr: optional,
    service_dt: optional,
    service_hours: optional,
    service_days: optional,
    service_nm: optional,
    helper_list: optional [{
      helpergroup_doc_id,
      helpergroup_nm,
      helpergroup_type,
      helper_doc_id,
      helper_nm,
      helper_phone,
      helper_birth_dt,
      helper_local_yn,
    }, {...}],
  }
}
 */
/**
 * 신규 서비스 신청을 인서트
 * @param {object} datas 신규 서비스 신청의 정보
 */
export async function insertApply(datas) {
  try {
    // 필수 파람(인자) 체크
    if (datas.memberType === undefined || datas.newMemberDocObj === undefined
      || datas.apply === undefined) {
      throw new Error('Please check params.');
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        datas
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 도우미 신규 추가
 * @param {object} helper 추가될 도우미에 대한 정보
 */
export async function insertHelper(helper) {
  // helper = {nm, phone, birth_dt, local_yn, helpergroup_doc_id,
  //   helpergroup_nm, helpergroup_type, company_id, product_id}
  try {
    // 필수 파람(인자) 확인
    if (helper.nm === undefined || helper.phone === undefined
      || helper.birth_dt === undefined || helper.local_yn === undefined
      || helper.helpergroup_doc_id === undefined || helper.helpergroup_nm === undefined
      || helper.helpergroup_type === undefined || helper.company_id === undefined
      || helper.product_id === undefined) {
      return { result: 'fail', message: 'Param is lack.' };
    }
    // 값이 undefined 이거나 길이가 0인 키가 있는 경우, 삭제
    Object.keys(helper).forEach((v) => {
      if (helper[v] === undefined || helper[v].length === 0) {
        delete helper[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/addHelper`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        helper
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// when the admin click 'cancel' button.
/**
 * 등록된 서비스 신청을 취소함(취소 버튼을 누름)
 * @param {string} member_doc_id 해당 서비스 신청을 올린 회원의 document id
 * @param {string} memberType 해당 서비스 신청을 올린 회원의 회원타입
 */
export async function cancelApply(member_doc_id, memberType) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/service/applies/cancelApply/${member_doc_id}/${memberType}`,
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 등록된 서비스 신청을 삭제함
 * @param {object} apply 등록된 서비스 신청의 정보
 */
export async function deleteApply(apply) { // apply === getApplyDetail function's return value
  try {
    // 필수 파람(인자) 확인
    if (apply === undefined
      || (apply.document_id === undefined || apply.document_id.length === 0)) {
      return { result: 'fail', message: 'Param is lack.' };
    }

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/service/applies/deleteApply`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        apply
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 고객들의 모든 만족도조사 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if(!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName = 'Healthcare_Admin_evaluation_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios.post(
      `${baseUrl}/service/applies/excel`,{
        searchState: searchState
      }).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }

}