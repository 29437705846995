import React from 'react';
import { useHelperHandler } from 'lib/hooks';
import { HelpersDetailTable } from 'components/Common';

const HelpersDetailTableContainer = ({ document_id }) => {
  const hlpd = useHelperHandler(document_id, 'd');
  const hlpa = useHelperHandler(document_id, 'a');
  return (
    <>
    <HelpersDetailTable hlpd={hlpd} hlpa={hlpa} />
    </>
  );
};

export default HelpersDetailTableContainer;
