import { message } from 'antd';
import axios from 'axios';
import { getCookie } from 'lib/cookie';
import { routes_ref } from 'lib/routeRef';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { mod_log_type_ref } from 'lib/dataRef';

/**
 * 관리자 변경 내역 로그 신규 입력
 * @param {object} adminLog 신규로 입력될 로그(id, nm, ...)
 */
export async function insertAdminModLog(location, cont, type, excelReason) {
  // admin keys : id, nm, pwd, department, permissions, ....(optional)
  try {
    // document에 들어가야 할 필수 값 체크
    if (!location && !cont) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    // 다운로드 시 사유 필수 값 체크
    if (cont == 'makeXlsx' && !excelReason) {
      await message.error(`저장에 실패했습니다. 사유를 확인해주세요`);
      return { result: 'fail', message: 'Please check the values.' };
    }
    const document_id = getCookie('session');

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/system/adminActLog/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        location,
        cont,
        document_id,
        excelReason,
        type,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 관리자 변경 내역 조회
 */
export async function getAdminModLogList(
  setAdminLogs,
  adminLogs,
  goPage,
  setCurrentPage,
  setLoading
) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/system/adminModLog/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage,
        pagingState: adminLogs.pagingState,
      }),
    }).then((res) => {
      setAdminLogs(res.data);
      setCurrentPage(1);
      setLoading(false);
    });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 모든 관리자 변경 로그 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_ModLog_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };
    return await axios
      .post(`${baseUrl}/system/adminModLog/excel`, {
        typeRef: mod_log_type_ref,
      })
      .then((res) => {
        return exportXlsx(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
