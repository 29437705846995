/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  AdminListPage,
  ApplyListPage,
  ArtCounselsPage,
  BodyAgePage,
  CancerLogPage,
  DzPredsPage,
  GrowthRecsPage,
  HelperGroupPage,
  HelperPage,
  LoginLogsPage,
  LoginPage,
  MainPage,
  MedicertsListPage,
  MedicinesPage,
  MemberListPage,
  PApplyAddPage,
  MentalPage,
  NoticePage,
  FaqPage,
  PushPage,
  PostEventInfosPage,
  PostTreatmentsPage,
  StepsPage,
  DentalsPage,
  HealthyBodyPage,
  BbiddakiApiLogPage,
  BenefitsPage,
  HanaStepPage,
  AppVersionPage,
  AppHashPage,
  AdminInfoAcessLogPage,
  AdminLoginLogPage,
  AdminModLogPage,
  ChattingPage,
  ChatApplyPage,
  ChatHealthTypePage,
  ChatCenterPage,
  ChatCenterAddPage,
  ChatDoctorPage,
  ChatDoctorAddPage,
  ChatQnAPage,
  ChatTermPage
} from 'pages';
import { routes_ref } from 'lib/routeRef';

function App() {
  const {
    admins: { admin },
    members: { member, login_log, hana_step },
    services: { apply, p_apply, mediCert, mental },
    contents: { notice, faq, treatment, event_info, push },
    outers: {
      art_consulting,
      helper_group,
      helper,
      healthy_body,
      bbiddaki_api_log,
      benefits,
    },
    healthLogs: {
      cancer_log,
      medicine,
      growth_rec,
      body_age,
      dzPreds,
      steps,
      dentals,
    },
    systems: {
      app_version,
      app_hash,
      admin_access_log,
      admin_login_log,
      admin_mod_log,
    },
    chatbot: {
      chatting,
      chat_apply,
      health_type,
      center,
      center_add,
      doctor,
      doctor_add,
      chat_term,
      chat_qna,
    },
  } = routes_ref.base;
  return (
    <Routes>
      {/* 메인 */}
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/main" element={<MainPage />} />
      {/* 헬스케어서비스 */}
      <Route path={admin.path} element={<AdminListPage pid={admin.pid} />} />
      <Route path={member.path} element={<MemberListPage pid={member.pid} />} />
      <Route
        path={login_log.path}
        element={<LoginLogsPage pid={login_log.pid} />}
      />
      <Route path={apply.path} element={<ApplyListPage pid={apply.pid} />} />
      <Route
        path={p_apply.path}
        element={<PApplyAddPage pid={p_apply.pid} />}
      />
      <Route
        path={mediCert.path}
        element={<MedicertsListPage pid={mediCert.pid} />}
      />
      {/* 하나손해보험 걷기 현황 페이지 추가 */}
      <Route
        path={hana_step.path}
        element={<HanaStepPage pid={hana_step.pid} />}
      />
      <Route path={mental.path} element={<MentalPage pid={mental.pid} />} />

      {/* 컨텐츠관리 */}
      <Route path={notice.path} element={<NoticePage pid={notice.pid} />} />
      <Route path={faq.path} element={<FaqPage pid={faq.pid} />} />
      <Route
        path={treatment.path}
        element={<PostTreatmentsPage pid={treatment.pid} />}
      />
      <Route
        path={event_info.path}
        element={<PostEventInfosPage pid={event_info.pid} />}
      />
      <Route path={push.path} element={<PushPage pid={push.pid} />} />

      {/* 외부 관리자 */}
      <Route
        path={art_consulting.path}
        element={<ArtCounselsPage pid={art_consulting.pid} />}
      />
      <Route
        path={helper_group.path}
        element={<HelperGroupPage pid={helper_group.pid} />}
      />
      <Route path={helper.path} element={<HelperPage pid={helper.pid} />} />
      <Route
        path={healthy_body.path}
        element={<HealthyBodyPage pid={healthy_body.pid} />}
      />
      <Route
        path={bbiddaki_api_log.path}
        element={<BbiddakiApiLogPage pid={bbiddaki_api_log.pid} />}
      />
      <Route
        path={benefits.path}
        element={<BenefitsPage pid={benefits.pid} />}
      />

      {/* 건강관리 */}
      <Route
        path={cancer_log.path}
        element={<CancerLogPage pid={cancer_log.pid} />}
      />
      <Route
        path={medicine.path}
        element={<MedicinesPage pid={medicine.pid} />}
      />
      <Route
        path={growth_rec.path}
        element={<GrowthRecsPage pid={growth_rec.pid} />}
      />
      <Route
        path={body_age.path}
        element={<BodyAgePage pid={body_age.pid} />}
      />
      <Route path={dzPreds.path} element={<DzPredsPage pid={dzPreds.pid} />} />
      <Route path={steps.path} element={<StepsPage pid={steps.pid} />} />
      <Route path={dentals.path} element={<DentalsPage pid={dentals.pid} />} />

      {/* 시스템관리 */}
      {/* 시스템 관리 메뉴 추가 */}
      <Route
        path={app_version.path}
        element={<AppVersionPage pid={app_version.pid} />}
      />
      <Route
        path={app_hash.path}
        element={<AppHashPage pid={app_hash.pid} />}
      />
      <Route
        path={admin_access_log.path}
        element={<AdminInfoAcessLogPage pid={admin_access_log.pid} />}
      />
      <Route
        path={admin_login_log.path}
        element={<AdminLoginLogPage pid={admin_login_log.pid} />}
      />
      <Route
        path={admin_mod_log.path}
        element={<AdminModLogPage pid={admin_mod_log.pid} />}
      />

      {/* 챗봇 관리 메뉴 추가 */}
      <Route
        path={chatting.path}
        element={<ChattingPage pid={chatting.pid} />}
      />
      <Route
        path={chat_apply.path}
        element={<ChatApplyPage pid={chat_apply.pid} />}
      />
      <Route
        path={health_type.path}
        element={<ChatHealthTypePage pid={health_type.pid} />}
      />
      <Route path={center.path} element={<ChatCenterPage pid={center.pid} />} />
      <Route
        path={center_add.path}
        element={<ChatCenterAddPage pid={center_add.pid} />}
      />
      <Route path={doctor.path} element={<ChatDoctorPage pid={doctor.pid} />} />
      <Route
        path={doctor_add.path}
        element={<ChatDoctorAddPage pid={doctor_add.pid} />}
      />

      <Route
        path={chat_qna.path}
        element={<ChatQnAPage pid={chat_qna.pid} />}
      />
      <Route
        path={chat_term.path}
        element={<ChatTermPage pid={chat_term.pid} />}
      />
    </Routes>
  );
}

export default App;
