import React from 'react';
import { Layout } from 'antd';

const { Header, Content } = Layout;
const PageTemplate = ({ header, menu, body }) => (
  <Layout>
    <Header className="header">{header}</Header>
    <Layout>
      {menu}
      <Layout>
        <Content
          className="site-layout-background"
          style={{
            padding: 30,
            margin: 0,
            minHeight: 280,
          }}
        >
          {body}
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default PageTemplate;
