import React, { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { routes_ref } from 'lib/routeRef';

const SaveButton = ({ func, data, location, isDebug = false }) => {
  const [popState, setPopState] = useState({
    visible: false,
  });
  const confirm = async () => {
    setPopState({ visible: false });
    await func(data).then(async (d) => {
      if (d.result === 'success') {
        await message.success('저장되었습니다').then((d) => {
          // 개인정보접근 로그 저장
          const infoText = data.nm || data.crm_nm || data.member_nm || null;
          if (infoText) {
            insertInfoAcessLog(
              location,
              func.name,
              '저장',
              undefined,
              infoText
            );
          }
          if (location && !isDebug) {
            document.location.href = location;
          }
        });
      }else if(d.result === 'fail'){
        await message.error(`저장에 실패했습니다. ${d.message} 확인해주세요`)
      }else if(d.result === 'fail_with_message'){
        await message.error(`저장에 실패했습니다. ${d.message}`)
      }
    });
  };

  const cancel = () => {
    setPopState({ visible: false });
    message.error('취소했습니다');
  };

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setPopState({ visible });
      return;
    }
    setPopState({ visible }); // show the popconfirm
  };

  const log = () => {
    console.log(data);
  };
  return (
    <>
      <Popconfirm
        title="저장하시겠습니까?"
        okText="저장"
        cancelText="취소"
        open={popState.visible}
        onOpenChange={handleVisibleChange}
        onClick={log}
        onConfirm={confirm}
        onCancel={cancel}
      >
        <Button block type="primary">
          저장하기
        </Button>
      </Popconfirm>
    </>
  );
};

export default SaveButton;
