import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import MentalListTableContainer from 'containers/Services/Mental/MentalListTableContainer';
import { useCheckAuth } from 'lib/hooks';

const MentalListPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['aaihc']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<MentalListTableContainer />}
    />
  );
};

export default MentalListPage;
