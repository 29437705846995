import React from 'react';
import { editHelperDetail } from 'controllers/Outers/helpers';
import { DatePicker, Descriptions, Divider, Input, Radio, Row, Select, Space } from 'antd';
import Text from 'antd/es/typography/Text';
import { company_ref, helpergroup_type_ref } from 'lib/dataRef';
import { CancelButton, SaveButton } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import dayjs from 'dayjs';
import moment from 'moment';
import { ListTableTemplate } from 'components/Common';
const { Item } = Descriptions;
const { Option } = Select;
const HelpersDetailTable = (props) => {
const hlpd = props.hlpd;
const hlpa = props.hlpa;
  const helpergroupListOptions = hlpd.helpergroup_list.map((group, idx) => (
    <Option
      key={group.helpergroup_doc_id}
    >
      {group.helpergroup_nm}
    </Option>
  ));
  const columns = [
    {
      title: '고객명',
      dataIndex: 'member_nm',
      key: 'member_nm',
      align: 'center',
    },
    {
      title: '신청일',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (epoch) => epoch !== undefined ? (moment(epoch).format('YYYY-MM-DD')) : '',
    },
    {
      title: '배정일(서약서 문자 전송일)',
      dataIndex: 'first_letter_send_dt',
      key: 'first_letter_send_dt',
      align: 'center',
      render: (epoch) => epoch !== undefined ? (moment(epoch).format('YYYY-MM-DD')) : '',
    },
    {
      title: '작업 시간',
      dataIndex: 'service_hours',
      key: 'service_hours',
      align: 'center',
    },
    {
      title: '만족도조사 결과',
      dataIndex: 'evaluation',
      key: 'evaluation',
      align: 'center',
    },
    {
      title: '불만 접수 상태',
      dataIndex: 'claim_yn',
      key: 'claim_yn',
      align: 'center',
      render: (bool) => (bool ? '접수됨' : ''),
    },
  ];

  return (
    <>
      <Descriptions
        title="간병인/가사도우미 관리"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="구분">
          <Select
            style={{ width: '150px' }}
            onChange={hlpd.handleChangeHelperGroupType}
            value={hlpd.helper.helpergroup_type}
          >
            <Option value="carerSupport">{helpergroup_type_ref.carerSupport}</Option>
            <Option value="housekeeper">{helpergroup_type_ref.housekeeper}</Option>
            <Option value="rehabilitationExercise">{helpergroup_type_ref.rehabilitationExercise}</Option>
            <Option value="companion">{helpergroup_type_ref.companion}</Option>
          </Select>
        </Item>
        <Item label="보험사">
          <Select
            style={{ width: '150px' }}
            onChange={hlpd.handleChangeCompanyId}
            value={hlpd.helper.company_id}
          >
            {getOptionsByRefId(company_ref)}
          </Select>
        </Item>
        <Item
          label="업체명"
        >
          <Select
            style={{ width: '150px' }}
            onChange={hlpd.handleChangeHelperGroupName}
            value={hlpd.helper.helpergroup_nm}
          >
            {helpergroupListOptions}
          </Select>
        </Item>
        <Item
          label="이름"
        >
          {hlpd.helper.nm}
        </Item>
        <Item
          label="핸드폰번호"
        >
          <Space.Compact
              style={{
                width: '100%',
              }}
            >
           <Input
              value={hlpd.helper.phone}
              onChange={hlpd.handleChangePhone}
              />
           </Space.Compact>
        </Item>
        <Item
          label="생년월일"
        >
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            {
              hlpd.helper.birth_dt ? <></>
                : <Text disabled>* 연도를 클릭하시면 더 넓은 범위가 나타납니다</Text>
            }
            <DatePicker
              format="YYYYMMDD"
              value={
                hlpd.helper.birth_dt !== ''
                  ? dayjs(hlpd.helper.birth_dt, 'YYYYMMDD')
                  : null
              }
              onChange={hlpd.handleChangeBirth}
            />
          </div>
        </Item>
        <Item
          label="내외국인"
        >
          <Radio.Group
            onChange={hlpd.handleChangeLocal}
            value={hlpd.helper.local_yn}
          >
            <Radio value>내국인</Radio>
            <Radio value={false}>외국인</Radio>
          </Radio.Group>
        </Item>
        <Divider />
        <Item
          label="만족도 조사 횟수"
        >
          {hlpd.helper.evaluation_num_count}
        </Item>
        <Item
          label="만족도 조사 점수"
        >
          {
            hlpd.helper.evaluation_num
              ? hlpd.helper.evaluation_num
              : '-'
          }
        </Item>
      </Descriptions>
      <ListTableTemplate
        dataSource={hlpa.helper.applies}
        columns={columns}
      />
      <Row>
        <Space>
          <CancelButton
            location={routes_ref.base.outers.helper.path}
          />
          <SaveButton
            func={editHelperDetail}
            data={hlpd.helper}
            location={routes_ref.base.outers.helper.path}
          />
        </Space>
      </Row>
    </>
  );
};

export default HelpersDetailTable;
