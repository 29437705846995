import React, { useEffect, useState } from 'react';
import { getChatDoctorDetail } from 'controllers/chatbot/chatbotDoctor';
import { ChatDoctorDetailTable } from 'components/Common';
import { Spin } from 'antd';

const ChatDoctorDetailTableContainer = ({ m_no }) => {
  const [chatDoctorDetail, setChatDoctorDetail] = useState({});
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (m_no) {
      setChatDoctorDetail({});
      setLoading(true);
      const data = getChatDoctorDetail(m_no);
      data.then((d) => {
        setChatDoctorDetail(d);
        setLoading(false);
      });

    }
  }, [m_no]);

  return (
    loading ?
    <Spin
      tip="Loading"
      size="large"
    >
      <ChatDoctorDetailTable chatDoctorDetail={chatDoctorDetail} setChatDoctorDetail={setChatDoctorDetail} />
    </Spin>
  :
    <ChatDoctorDetailTable chatDoctorDetail={chatDoctorDetail} setChatDoctorDetail={setChatDoctorDetail} />
  );
};

export default ChatDoctorDetailTableContainer;
