import React from 'react';
import { Row, Descriptions, Input, Divider, Space, Switch } from 'antd';
import { CancelButton, SaveButton } from 'components/Common';
import { getFormattedDate } from 'lib/getFormattedDate';
import { editTreatmentDetail } from 'controllers/Contents/treatments';

const { Item } = Descriptions;
const TreatmentsDetailTable = (props) => {
  const { treatmentDetail, setTreatmentDetail } = props;
  const handleSwitchChange = () => {
    setTreatmentDetail({ ...treatmentDetail, del_yn: !treatmentDetail.del_yn });
  };
  return (
    <>
      <Row>
        <Descriptions
          title="세부 사항"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="타이틀">
            <Input
              value={treatmentDetail.title}
              onChange={(e) => setTreatmentDetail({ ...treatmentDetail, title: e.target.value })}
            />
          </Item>
          <Item label="url">
            <Input
              value={treatmentDetail.url}
              onChange={(e) => setTreatmentDetail({ ...treatmentDetail, url: e.target.value })}
            />
          </Item>
          <Item label="신문사명">
            <Input
              value={treatmentDetail.newspaper_nm}
              onChange={(e) => setTreatmentDetail({ ...treatmentDetail, newspaper_nm: e.target.value })}
            />
          </Item>
          <Item label="기사등록일자">{getFormattedDate(treatmentDetail.newspaper_dt)}</Item>
          <Item label="등록일">{getFormattedDate(treatmentDetail.reg_dt)}</Item>
          <Item label="수정시각">{getFormattedDate(treatmentDetail.mod_dt, 'opt2')}</Item>
          <Item label="공개여부">
            <Switch checked={!treatmentDetail.del_yn} onChange={handleSwitchChange} />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/post-treatments"
          />
          <SaveButton
            func={editTreatmentDetail}
            data={treatmentDetail}
            location="/post-treatments"
          />
        </Space>
      </Row>
    </>
  );
};

export default TreatmentsDetailTable;
