import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { DzPredsDetailTable } from 'components/Common';
import { getDzPredDetail } from 'controllers/HealthLogs/dzPreds';

const DzPredsDetailContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [dzPredsDetail, setDzPredsDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setLoading(true);
      setDzPredsDetail({});
      const res = getDzPredDetail(document_id);
      res.then((d)=> {
        setDzPredsDetail(d);
        setLoading(false);
      })
    }
  }, [document_id]);
  
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <DzPredsDetailTable dzPredsDetail={dzPredsDetail} setDzPredsDetail={setDzPredsDetail} />
      </Spin>
    :
      <DzPredsDetailTable dzPredsDetail={dzPredsDetail} setDzPredsDetail={setDzPredsDetail} />
  );
};

export default DzPredsDetailContainer;
