import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 도우미 회사의 정보 조회
 */
export async function getHelperGroupsList(setHelperGroups, setLoading) {
  try {
    return await axios.get(
      `${baseUrl}/helperGroups/getList`).then((res) => {
        setHelperGroups(res.data);
        setLoading(false);
      }).catch((err) => {
        console.error(err)
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 도우미 회사의 정보 조회
 * @param {string} document_id 조회하려는 회사의 document id
 */
export async function getHelperGroupDetail(document_id) {
  try {
    return await axios.get(
      `${baseUrl}/helperGroups/getDetail/${document_id}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err)
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 새로운 회사 정보 추가
 * @param {object} helperGroup 신규 회사에 대한 정보
 */
export async function insertHelperGroup(helperGroup) {
  try {
        // 필수 파람(인자)가 없는 경우, 실패함을 리턴
    if (!helperGroup.type || !helperGroup.nm || !helperGroup.phone || !helperGroup.email) {
      return { result: 'fail', message: '필수 입력사항을' };
    }

    return await axios.post(
      `${baseUrl}/helperGroups/add`, {
        helperGroup
    }).then((res) => {
      return { result: 'success', message: 'The helperGroup is inserted.' };
    }).catch((err) => {
      console.error(err)
      return { result: 'fail'}
    });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 회사 정보 수정
 * @param {object} helperGroup 특정 회사에 대한 정보와 수정 사항
 */
export async function editHelperGroupDetail(helperGroup) { 
  try {
    return await axios.post(
      `${baseUrl}/helperGroups/update`, {
        helperGroup
      }).then((res) => {
        return { result: 'success', message: 'The helperGroup is updated.' };
      }).catch((err) => {
        console.error(err)
        return { result: 'fail'}
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 도우미 회사의 갯수 리턴
//  */
// export async function getHelperGroupsSize() {
//   try {
//     const hgRef = await firestore.collection('adm_HELPERGROUPS')
//       .get();
//     return hgRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 도우미 회사의 정보 조회
//  */
// export async function getHelperGroupsList() {
//   try {
//     const lists = [];
//     const hgsRef = await firestore.collection('adm_HELPERGROUPS')
//       .orderBy('reg_dt', 'desc').get();
//     hgsRef.docs.forEach((doc) => {
//       // let eval_length = 0;
//       let eval_new_length = 5;

//       // 회사의 타입이 housekeeper 인 경우, 설문지의 문항수가 4개
//       // if (doc.data().type === 'housekeeper') {
//       //   eval_length = 4;
//       // // 회사의 타입이 housekeeper 가 아닌 경우(carerSupport 인 경우), 설문지의 문항수가 5개
//       // } else {
//       //   eval_length = 5;
//       // }
//       lists.push({
//         document_id: doc.data().document_id,
//         type: doc.data().type,
//         nm: doc.data().nm,
//         reg_dt: doc.data().reg_dt,
//         del_yn: doc.data().del_yn,
//         helper_count: 0,
//         evaluation_num_count: doc.data().evaluation_num_count,
//         evaluation_num: doc.data().evaluation_num_count > 0
//             ? doc.data().evaluation_num_sum / (doc.data().evaluation_num_count * eval_new_length )
//             : 0,
//           // doc.data().evaluation_count > 0
//           // ? doc.data().evaluation_new_sum > 0
//           // ? doc.data().evaluation_sum > 0
//           // ? (doc.data().evaluation_sum / eval_length + doc.data().evaluation_new_sum / eval_new_length) / (doc.data().evaluation_count)
//           // : (doc.data().evaluation_new_sum / eval_new_length) / (doc.data().evaluation_count)
//           // : doc.data().evaluation_sum / (doc.data().evaluation_count * eval_length)
//           // : 0,
//       });
//     });
//     const hsRef = await firestore.collection('adm_HELPERS')
//       .where('del_yn', '==', false)
//       .get();
//     hsRef.docs.forEach((doc) => {
//       for (let i = 0; i < lists.length; i++) {
//         if (lists[i].document_id === doc.data().helpergroup_doc_id) {
//           lists[i].helper_count++;
//           break;
//         }
//       }
//     });
//     return lists;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 도우미 회사의 정보 조회
//  * @param {string} document_id 조회하려는 회사의 document id
//  */
// export async function getHelperGroupDetail(document_id) {
//   try {
//     // let eval_length = 0;
//     let eval_new_length = 5;

//     // 특정 회사와 특정 회사에 재직중인 도우미의 정보 조회
//     const hsRef = await firestore.collection('adm_HELPERS')
//       .where('helpergroup_doc_id', '==', document_id)
//       .where('del_yn', '==', false)
//       .get();
//     const hgRef = await firestore.collection('adm_HELPERGROUPS')
//       .doc(document_id).get();

//     // 회사의 타입이 housekeeper 인 경우, 설문지의 문항수가 4개
//     // if (hgRef.data().type === 'housekeeper') {
//     //   eval_length = 4;
//     // // 회사의 타입이 housekeeper 가 아닌 경우(carerSupport 인 경우), 설문지의 문항수가 5개
//     // } else {
//     //   eval_length = 5;
//     // }

//     const aplsRef = await firestore.collection('cust_APPLIES')
//       .where('del_yn', '==', false)
//       .orderBy('helper_list', 'asc')
//       .get();
//     let dispatch_count = 0;
//     let evaluation_num_count = 0;
//     let evaluation_num_sum = 0;
//     // let evaluation_count = 0;
//     // let evaluation_sum = 0;
//     // let evaluation_new_sum = 0;
//     // 특정 회사의 도우미가 배정됐던 것에 대한 정보 조회
//     aplsRef.docs.forEach((doc) => {
//       doc.data().helper_list.forEach(async (v, i) => {
//         if (v.helpergroup_doc_id === document_id) {
//           if (!v.reassign_yn) {
//             dispatch_count++;
//           }
//           if (i === doc.data().helper_list.length - 1 && doc.data().status === 13
//             && doc.data().evaluation_num !== undefined && doc.data().evaluation_num.length > 0) {
//             evaluation_num_count++;
//             doc.data().evaluation_num.forEach((evaluation_num) => {
//               evaluation_num_sum += evaluation_num;
//             });
//           }
//           // // 리뉴얼된 만족도조사
//           // if (i === doc.data().helper_list.length - 1 && doc.data().status === 13
//           //   && doc.data().evaluation_new !== undefined && doc.data().evaluation_new.length > 0) {
//           //   evaluation_count++;
//           //   doc.data().evaluation_new.forEach((evaluation) => {
//           //     evaluation_new_sum += evaluation;
//           //   });
//           // }
//         }
//       });
//     });

//     // 위에서 다른 컬렉션을 조회해 얻은 데이터와 현재 회사 정보가 다르다면, 업데이트
//     if (hgRef.data().dispatch_num_count !== dispatch_count
//       || hgRef.data().evaluation_num_count !== evaluation_num_count || hgRef.data().evaluation_num_sum !== evaluation_num_sum) {
//       await firestore.collection('adm_HELPERGROUPS').doc(document_id).set({
//         dispatch_count,
//         evaluation_num_count,
//         evaluation_num_sum,
//         mod_dt: Math.floor(new Date().getTime()),
//       }, { merge: true });
//     }

//     return {
//       document_id: hgRef.data().document_id,
//       type: hgRef.data().type,
//       nm: hgRef.data().nm,
//       phone: hgRef.data().phone,
//       email: hgRef.data().email === undefined ? '' : hgRef.data().email,
//       reg_dt: hgRef.data().reg_dt,
//       del_yn: hgRef.data().del_yn,
//       helper_count: hsRef.size,
//       evaluation_num_count,
//       evaluation: evaluation_num_count > 0
//         ? evaluation_num_sum / (evaluation_num_count * eval_new_length)
//         : 0,
//         // evaluation_count > 0
//         // // ? evaluation_sum / (evaluation_count * eval_length)
//         // // : 0,
//         // ? evaluation_new_sum > 0
//         // ? evaluation_sum > 0
//         // ? (evaluation_sum / eval_length + evaluation_new_sum / eval_new_length) / (evaluation_count)
//         // : (evaluation_new_sum / eval_new_length) / (evaluation_count)
//         // : evaluation_sum / (evaluation_count * eval_length)
//         // : 0,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 새로운 회사 정보 추가
//  * @param {object} helperGroup 신규 회사에 대한 정보
//  */
// export async function insertHelperGroup(helperGroup) {
// // helperGroup === { type, nm, phone, email }
//   try {
//     // 필수 파람(인자)가 없는 경우, 실패함을 리턴
//     if (helperGroup.type === undefined || helperGroup.nm === undefined
//       || helperGroup.phone === undefined || helperGroup.email === undefined) {
//       return { result: 'fail', message: 'Param is lack.' };
//     }
//     // 분류, 사명, 연락처, 이메일이 아니면서, 값이 undefined 이거나 길이가 0인 경우, 키 제거
//     Object.keys(helperGroup).forEach((v) => {
//       if ((v !== 'type' && v !== 'nm' && v !== 'phone' && v !== 'email')
//         || (helperGroup[v] === undefined || helperGroup[v].length === 0)) {
//         delete helperGroup[v];
//       }
//     });
//     const now = Math.floor(new Date().getTime());
//     helperGroup.reg_dt = now;
//     helperGroup.mod_dt = now;
//     helperGroup.del_dt = 0;
//     helperGroup.del_yn = false;
//     helperGroup.evaluation_num_count = 0;
//     await firestore.collection('adm_HELPERGROUPS').add(helperGroup)
//       .then(async (doc) => {
//         await firestore.collection('adm_HELPERGROUPS').doc(doc.id)
//           .set({ document_id: doc.id }, { merge: true });
//       }).catch((err) => {
//         console.error(err);
//       });

//     return { result: 'success', message: 'The helperGroup is inserted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 회사 정보 수정
//  * @param {object} helperGroup 특정 회사에 대한 정보와 수정 사항
//  */
// export async function editHelperGroupDetail(helperGroup) { // helper === getHelperDetail function's return value
//   try {
//     const helperGroupB = await firestore.collection('adm_HELPERGROUPS')
//       .doc(helperGroup.document_id).get();
//     // 삭제 여부가 true인 경우, 실패함을 리턴
//     if (helperGroupB.del_yn) {
//       return { result: 'fail', message: 'The helperGroup was deleted.' };
//     }
//     const now = Math.floor(new Date().getTime());
//     // 값이 undefined 이거나 길이가 0이면서, 소속된 도우미 수, 설문횟수, 평가가 아닌 경우, 키 삭제
//     Object.keys(helperGroup).forEach((v) => {
//       if ((helperGroup[v] === undefined || helperGroup[v].length === 0)
//         || v === 'helper_count' || v === 'evaluation_count' || v === 'evaluation') {
//         delete helperGroup[v];
//       }
//     });
//     // 사명, 연락처, 이메일 중 한가지 이상이 이전 값과 다른 경우
//     if (helperGroupB.data().nm !== helperGroup.nm
//       || helperGroupB.data().phone !== helperGroup.phone
//       || ((helperGroupB.data().email !== undefined && helperGroupB.data().email !== helperGroup.email)
//       || (helperGroupB.data().email === undefined && helperGroup.email !== undefined))) {
//       helperGroup.mod_dt = now;

//       // 회사에 대한 변경된 사항들을 서비스 신청 정보에 업데이트
//       const aplsRef = await firestore.collection('cust_APPLIES')
//         .where('del_yn', '==', false)
//         .orderBy('helper_list', 'asc')
//         .get();
//       aplsRef.docs.forEach(async (doc) => {
//         const helperList = doc.data().helper_list;
//         for (let i = 0; i < helperList.length; i++) {
//           if (helperList[i].helpergroup_doc_id === helperGroup.document_id) {
//             helperList[i].helpergroup_nm = helperGroup.nm;
//             helperList[i].helpergroup_phone = helperGroup.phone;
//             break;
//           }
//         }
//         await firestore.collection('cust_APPLIES').doc(doc.id)
//           .set({
//             helper_list: helperList,
//           }, { merge: true });
//       });

//       // 회사에 대해 변경된 사항들을 도우미 정보에 업데이트
//       const helpersRef = await firestore.collection('adm_HELPERS')
//         .where('helpergroup_doc_id', '==', helperGroup.document_id)
//         .where('del_yn', '==', false)
//         .get();
//       helpersRef.docs.forEach(async (doc) => {
//         await firestore.collection('adm_HELPERS').doc(doc.id).set({
//           helpergroup_nm: helperGroup.nm,
//           helpergroup_phone: helperGroup.phone,
//           mod_dt: now,
//         }, {
//           merge: true,
//         });
//         await firestore.collection('adm_HELPERSAVG').doc(doc.id).set({
//           helpergroup_nm: helperGroup.nm,
//           helpergroup_phone: helperGroup.phone,
//           mod_dt: now,
//         }, {
//           merge: true,
//         });
//       });
//     } else if (helperGroup.del_yn) {
//       helperGroup.mod_dt = now;
//       helperGroup.del_dt = now;
//     } else {
//       return { result: 'fail', message: 'There is no modification.' };
//     }

//     await firestore.collection('adm_HELPERGROUPS')
//       .doc(helperGroup.document_id).set(helperGroup, { merge: true });

//     return { result: 'success', message: 'The helperGroup is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }