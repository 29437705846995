import React, { useEffect, useState } from 'react';
import { getNoticeList } from 'controllers/Contents/notice';
import { Button, Drawer } from 'antd';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate, NoticeAddTable } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { product_id_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import NoticeDetailContainer from 'containers/Contents/Notice/NoticeDetailContainer';

const NoticeListTableContainer = () => {
  const [notice, setNotice] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [status, setStatus] = useState({
    visible: false,
    document_id: ''
  });
  const onClose = () => {
    setStatus({
      visible: false,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id
    });
  };
  useEffect(() => {
    getNoticeList(setNotice, setCount, searchState, setLoading);
  }, []);

  function goSearch() {
    getNoticeList(setNotice, setCount, searchState, setLoading);
  }
  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      width: '5%',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => showDrawer(document_id)}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '앱 명칭',
      dataIndex: 'product_id',
      key: 'product_id',
      width: '20%',
      align: 'center',
      render: (data) => product_id_ref[data],
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: '등록시각',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      width: '15%',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '수정시각',
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      width: '15%',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    }
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.contents.notice.path}
          />
        )}
        countLabel={(<CounterLabel count={count} />)}
        addButton={(<NoticeAddTable />)}
        resetButton={(
            <Button
              type="primary"
              style={{float: 'right', marginLeft:'8px'}}
              onClick={() => {
                setSearchState({});
              }}
            >
              초기화
            </Button>
          )}
          searchButton={(
            <Button
              type="primary"
              style={{float: 'right', marginLeft: '8px'}}
              onClick={() => {
                setLoading(true);
                goSearch()
              }}
            >
              검색
            </Button>
          )}
          searchFieldList={{
            field: ['앱 명칭'],
            searchState: searchState,
            setSearchState: setSearchState,
            onKeyPress: onKeyPress
          }}
      />
      <ListTableTemplate
        dataSource={notice}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
        <Drawer
          width={750}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <NoticeDetailContainer
            document_id={status.document_id} />
        </Drawer>
      )}
      />
    </>
  );
};

export default NoticeListTableContainer;
