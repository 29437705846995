import React from 'react';
import classNames from 'classnames/bind';
import LoginContainer from 'containers/LoginContainer';
import styles from './LoginPage.scss';

const cx = classNames.bind(styles);

const LoginPage = () => (
  <div className={cx('login-page')}>
    <div className={cx('login-page-title')}>
      <h1>LOGIN 페이지</h1>
    </div>
    <div className={cx('login-page-body')}>
      <LoginContainer />
    </div>
  </div>
);

export default LoginPage;
