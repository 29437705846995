import React from 'react';
import { MenuPane } from 'components/MenuPane';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import MainContainer from 'containers/MainContainer';

const MainPage = () => (
  <PageTemplate
    header={<HeaderContainer />}
    menu={<MenuPane />}
    body={<MainContainer />}
  />
);

export default MainPage;
