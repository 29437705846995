import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * mental의 전체 정보 조회
 */
export async function getMentalList(setMental, setCount, setLoading) {
  try {
    const result = await axios.get(
      `${baseUrl}/mental/getList`).then((res) => {
      setMental(res.data.data);
      setCount(res.data.count);
      setLoading(false);
    });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 mental의 정보 조회
 * @param {string} document_id 조회하고자 하는 행사정보의 document id
 */
export async function getMentalDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/mental/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);
    
    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 mental의 정보 수정
 * @param {object} mental 수정하고자 하는 행사정보의 정보 및 수정 내용
 */
export async function editMentalDetail(mental) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/mental/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        mental
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}