import React, { useState } from 'react';
import { Descriptions, Divider, Drawer, Input, Row, Select, Space } from 'antd';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { insertHelperGroup } from 'controllers/Outers/helperGroups';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import { helpergroup_type_ref } from 'lib/dataRef'; 

const { Item } = Descriptions;
const HelperGroupsAddTable = () => {
  const [helperGroups, setHelperGroups] = useState({
    type: '',
    nm: '',
    phone: '',
    email: '',
  });
  const [drawerStatus, setDrawerStatus] = useState({
    visible: false,
  });
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setHelperGroups({});
  };

  function handleChangeHelperGroupsType(selected) {
    setHelperGroups({
      ...helperGroups,
      type: selected,
    });
  }

  return (
    <>
      <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={480}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
        <Descriptions
          title="간병인/가사도우미 업체 추가"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="구분">
            <Select
              style={{ width: '150px' }}
              onChange={handleChangeHelperGroupsType}
              value={helperGroups.type}
            >
              {getOptionsByRefId(helpergroup_type_ref)}
            </Select>
          </Item>
          <Item label="업체">
            <Input
              value={helperGroups.nm}
              onChange={(e) => {
                setHelperGroups({
                  ...helperGroups,
                  nm: e.target.value,
                });
              }}
            />
          </Item>
          <Item
            label="담당자 연락처"
          >
            <Input
              value={helperGroups.phone}
              onChange={(e) => {
                setHelperGroups({
                  ...helperGroups,
                  phone: e.target.value,
                });
              }}
            />
          </Item>
          <Item
            label="이메일"
          >
            <Input
              value={helperGroups.email}
              onChange={(e) => {
                setHelperGroups({
                  ...helperGroups,
                  email: e.target.value,
                });
              }}
            />
          </Item>
        </Descriptions>
        <Divider />
        <Row>
          <Space>
            <CancelButton
              location={routes_ref.base.outers.helper_group.path}
            />
            <SaveButton
              func={insertHelperGroup}
              data={helperGroups}
              location={routes_ref.base.outers.helper_group.path}
            />
          </Space>
        </Row>
      </Drawer>
    </>
  );
};

export default HelperGroupsAddTable;
