/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Descriptions, Divider, Input, Row, Space, Checkbox } from 'antd';
import { editMentalDetail } from 'controllers/Services/mental';
import { SaveButton, CancelButton } from 'components/Common';
import { company_ref } from 'lib/dataRef';

const { Item } = Descriptions;
const MentalDetailTable = (props) => {
  const { mentalDetail, setMentalDetail } = props;
  const handleTypeChange = (e) => {
    setMentalDetail({
      ...mentalDetail,
      status: e.target.value,
    });
  };
  const selectedList = () => {
    if(mentalDetail.selected) {
      return mentalDetail.selected.map((item) => (
        <Item>{item}<br /></Item>
      ));
    }
  }
  return (
    <>
      <Row>
        <Descriptions
          title="상담 신청 정보"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="보험사">
            {company_ref[mentalDetail.company_id]}
          </Item>
          <Item label="이름">
            {mentalDetail.nm}
          </Item>
          <Item label="연락처">
            {mentalDetail.phone}
          </Item>
          <Item label="신청 상담">
            {selectedList()}
          </Item>
          <Item label={"상태"}
        >
        <Checkbox value={0} onChange={handleTypeChange} checked={mentalDetail.status == 0 ? true : false}>
          상담신청
        </Checkbox>
        <Checkbox value={2} onChange={handleTypeChange} checked={mentalDetail.status == 2 ? true : false}>
          상담완료
        </Checkbox>
        </Item>
          <Item label="관리자 메모">
            <Input.TextArea
              value={mentalDetail.admin_memo}
              rows={5}
              allowClear
              onChange={(e) => setMentalDetail({ ...mentalDetail, admin_memo: e.currentTarget.value })}
            />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/mental"
          />
          <SaveButton
            func={editMentalDetail}
            data={mentalDetail}
            location='/mental'
          />
        </Space>
      </Row>
    </>
  );
};

export default MentalDetailTable;

