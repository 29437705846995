import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { getStepsList, makeXlsx, make300StepsXlsx } from 'controllers/HealthLogs/steps';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging,
  Pagination, ExcelModal, DelListButton } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { company_ref } from 'lib/dataRef';
import { deleteBundle } from 'controllers/common';
import StepsDetailContainer from './StepsDetailContainer';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const StepsListContainer = () => {
  const initStatus = {
    visible: false,
    document_id: '',
  };
  const initSteps = [];
  const [steps, setSteps] = useState({
    data: initSteps,
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [status, setStatus] = useState(initStatus);
  const [searchState, setSearchState] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
  useEffect(() => {
    const fetchData = async () => {
        await getStepsList(setSteps, steps, 'first', setCurrentPage, searchState, setLoading);
    };
    fetchData();
  }, []);

  function goSearch() {
    getStepsList(setSteps, steps, 'first', setCurrentPage, searchState, setLoading);
  }

  const onClose = () => {
    setStatus(initStatus);
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const excelDownload = () =>{
    insertInfoAcessLog(location.pathname, 'makeXlsx', "다운로드", excelReason);
    makeXlsx(excelReason, searchState);
  }
  const columns = [
    {
      title: () => {
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.length == list.length}
              onChange={(e) => onCheckedAll(e)}
            />
            전체
          </>
        );
      },
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render(document_id) {
        return (
          <>
            <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={checkedList.includes(document_id)}
            onChange={(e) => onChangeChecked(e, document_id)}
          />
            <Button
              key={document_id}
              type="primary"
              size="small"
              onClick={() => {insertInfoAcessLog(location.pathname, 'stepsDetail', "조회", undefined, document_id), showDrawer(document_id)}}
            >
              관리
            </Button>
          </>
        );
      },
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data]
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '총 누적 걸음 수',
      dataIndex: 'steps_total',
      key: 'steps_total',
      align: 'center',
      render(steps_total) {
        return `${steps_total} 보`;
      },
    },
    {
      title: '총 활동 기간',
      dataIndex: 'activity_dt',
      key: 'activity_dt',
      align: 'center',
    },
  ];


  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  const setPage = (page) => {
    setCurrentPage(page);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  }

  const renderMoveData = (move) => {
    getStepsList(setSteps, steps, move, setCurrentPage, searchState, setLoading);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  }
  let list = currentData(steps.data);
  const onChangeChecked = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      checkedList.splice(checkedList.indexOf(id), 1);
      setCheckedList([...checkedList]);
    }
  };
  const onCheckedAll = (e) => {
    const checkList = list.map((item) => item.document_id);
    if (e.target.checked) {
      setCheckedList(checkList);
    } else {
      setCheckedList([]);
    }
  };
  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.healthLogs.steps.path}
          />
        )}
        countLabel={(<CounterLabel count={steps.count} />)}
        hanaStepButton={(
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
            //  setTimeout(alert("테스트~~"), 1000);
             make300StepsXlsx()
            }}
          >
            300만보 달성자
          </Button>
        )}
        // xlsButton={(
        //   <Button
        //     type="primary"
        //     style={{ float: 'right', marginLeft: '8px' }}
        //     onClick={() => {
        //       setModalIsOpen(true);
        //     }}
        //   >
        //     엑셀 다운로드
        //   </Button>
        // )}
        delButton={
          <DelListButton
            func={deleteBundle}
            data={checkedList}
            location="/steps"
          />
        }
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['보험사', '이름', '연락처'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(steps.data)}
        columns={columns}
        loading={loading}
        pagination={(
          <Pagination
            totalData={steps.data}
            pagingState={steps.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        )}
        DetailTableContainer={(
          <Drawer
            width={1200}
            closable
            onClose={onClose}
            open={status.visible}
          >
            <StepsDetailContainer
              document_id={status.document_id}
            />
          </Drawer>
        )}
      />
      <div>
        {
          modalIsOpen && (
            <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
          </ExcelModal>
        )}
      </div>
    </>
  );
};

export default StepsListContainer;
