import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 주어진 조건에 걸맞는 도우미 정보 조회
 * @param {string} type 날짜의 기준
 * @param {number} year 기준 년도
 * @param {number} month 기준 월
 */
export async function getHelpersList(setHelpers, setCount, setLoading, searchState) {
  try {
    const res = await axios.post(
      `${baseUrl}/helper/getList`, {
        searchState,
      }).then((res) => {
        setHelpers(res.data.data);
        setCount(res.data.count);
        setLoading(false);
      }).catch((err) => {
        console.error(err);
      });

    return res;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * (특정 분류를 갖는) 회사의 정보 조회
 * @param {string} helpergroup_type 회사의 분류
 */
export async function getHelperGroupsList(helpergroup_type) {
  try {
    return await axios.get(
      `${baseUrl}/helper/getHgList/${helpergroup_type}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err)
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 도우미의 정보 조회
 * @param {string} document_id 특정 도우미의 document id
 */
export async function getHelperDetail(document_id) {
  try {
    return await axios.get(
      `${baseUrl}/helper/getDetail/${document_id}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err)
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 도우미의 활동 내역 조회
 * @param {string} document_id 특정 도우미의 document id
 */
export async function getHelperActivities(document_id) {
  try {
    return await axios.get(
      `${baseUrl}/helper/getActivities/${document_id}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err)
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 신규 도우미 추가
 * @param {object} helper 추가될 도우미에 대한 정보
 */
export async function insertHelper(helper) {
  try {
    // 필수 파람(인자)가 없는 경우, 실패함을 리턴
    if (
      !helper.company_id ||
      !helper.product_id ||
      !helper.helpergroup_doc_id ||
      !helper.helpergroup_nm ||
      !helper.helpergroup_type ||
      !helper.nm ||
      !helper.phone ||
      !helper.birth_dt ||
      !helper.local_yn
    ) {
      return { result: 'fail', message: '필수 입력사항을' };
    } else {
      return await axios.post(
        `${baseUrl}/helper/add`, {
          helper
      }).then((res) => {
        return { result: 'success', message: 'The helper is inserted.' };
      }).catch((err) => {
        console.error(err)
      });
    }
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 도우미의 정보를 수정
 * @param {object} helper 수정하려는 도우미의 정보 및 수정할 데이터
 */
export async function editHelperDetail(helper) {
  try {
    return await axios.post(
      `${baseUrl}/helper/update`, {
        helper
      }).then((res) => {
        return { result: 'success', message: 'The helper is updated.' };
      }).catch((err) => {
        console.error(err)
        return { result: 'fail'}
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 도우미 대한 정보를 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if(!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_helpers_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios.post(
      `${baseUrl}/helper/excel`,{
        searchState: searchState
      }).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    alert(err);
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';
// import dayjs from 'dayjs';
// import { company_ref, helpergroup_type_ref } from '../lib/dataRef';
// import getFormattedDate from 'lib/getFormattedDate';

// /**
//  * 주어진 조건에 걸맞는 도우미 정보 조회
//  * @param {string} type 날짜의 기준
//  * @param {number} year 기준 년도
//  * @param {number} month 기준 월
//  */
// export async function getHelpersList(setHelpers, setCount, type, year, month) {
//   try {
//     const lists = [];
//     const helpersRef = await firestore
//       .collection('adm_HELPERS')
//       .orderBy('reg_dt', 'desc')
//       .get();

//     // 필터링에 따라서 보이는 간병인/가사도우미가 달라지기는 하지만,
//     // 디테일한 값(만족도조사 등)까지 해당 필터링에 맞춰서 값이 변경되지는 않는다.
//     // (리스트에 보여지는 사람들만 필터링이 된다.)
//     // 날짜의 기준이 신청일자
//     if (type === 'applyReg') {
//       let from = dayjs(year + '-' + month + '-01').valueOf();
//       let to = dayjs(year + '-' + (month + 1) + '-01').valueOf();

//       // 날짜의 기준이 서비스 희망일
//       const firstRef = await firestore
//         .collection('cust_APPLIES')
//         .where('category2', 'in', ['carerSupport', 'housekeeper'])
//         .where('status', '>=', 13)
//         .where('status', '<=', 14)
//         .orderBy('status', 'asc')
//         .orderBy('reg_dt', 'desc')
//         .get();
//       // 신청일자 === 서비스 신청일자 로 고려하고 작성했음
//       let aplsRef = [];
//       if (firstRef.size > 0) {
//         firstRef.docs.forEach((firstDoc) => {
//           let regDt = Number(firstDoc.data().reg_dt);
//           if (regDt >= Number(from) && regDt < Number(to)) {
//             aplsRef.push(firstDoc.data());
//           }
//         });
//         // 데이터 입력
//         aplsRef.forEach((aplDoc) => {
//           if (helpersRef.docs.length < 0) {
//             return;
//           }
//           helpersRef.docs.forEach((helpers) => {
//             const lastHelper = aplDoc.helper_list.length - 1;
//             if (
//               helpers.data().document_id ===
//               aplDoc.helper_list[lastHelper].helper_doc_id
//               /* && !!aplDoc.data().letter_url */
//             ) {
//               // let eval_length = 0;
//               let eval_new_length = 5;
//               // 사용X, 만족도조사 없는경우 에러 발생하여 주석처리
//               // let evaluation = 0;
//               // aplDoc.evaluation.forEach((v) => {
//               //   evaluation += v;
//               // });
//               // if (aplDoc.category2 === 'housekeeper') {
//               //   eval_length = 4;
//               // } else {
//               //   eval_length = 5;
//               // }
//               lists.push({
//                 document_id: helpers.data().document_id,
//                 company_id: helpers.data().company_id,
//                 product_id: helpers.data().product_id,
//                 helpergroup_type: helpers.data().helpergroup_type,
//                 helpergroup_nm: helpers.data().helpergroup_nm,
//                 nm: helpers.data().nm,
//                 phone: helpers.data().phone,
//                 evaluation_num_count: helpers.data().evaluation_num_count || 0,
//                 evaluation_num:
//                   helpers.data().evaluation_num_count > 0
//                     ? helpers.data().evaluation_num_sum / (helpers.data().evaluation_num_count * eval_new_length)
//                     : 0,
//                 reg_dt: helpers.data().reg_dt,
//                 del_yn: helpers.data().del_yn,
//                 del_dt: helpers.data().del_dt,
//               });
//             }
//           });
//         });
//         setHelpers(lists);
//         setCount(lists.length);
//       } else {
//         setHelpers([]);
//         setCount(0);
//       }
//       // 날짜의 기준이 서비스 희망일
//     } else if (type === 'serviceStart') {
//       let from = dayjs(year + '-' + month + '-01').valueOf();
//       let to = dayjs(year + '-' + (month + 1) + '-01').valueOf();

//       // 날짜의 기준이 서비스 희망일
//       const firstRef = await firestore
//         .collection('cust_APPLIES')
//         .where('category2', 'in', ['carerSupport', 'housekeeper'])
//         .where('status', '>=', 13)
//         .where('status', '<=', 14)
//         .orderBy('status', 'asc')
//         .orderBy('service_dt', 'desc')
//         .get();
//       // 신청일자 === 서비스 신청일자 로 고려하고 작성했음
//       let aplsRef = [];
//       if (firstRef.size > 0) {
//         firstRef.docs.forEach((firstDoc) => {
//           let serviceDt =
//             firstDoc.data().service_dt !== undefined
//               ? dayjs(firstDoc.data().service_dt.substring(0, 10)).valueOf()
//               : today;
//           if (serviceDt >= Number(from) && serviceDt < Number(to)) {
//             aplsRef.push(firstDoc.data());
//           }
//         });
//         // 데이터 입력
//         aplsRef.forEach((aplDoc) => {
//           if (helpersRef.docs.length < 0) {
//             return;
//           }
//           helpersRef.docs.forEach((helpers) => {
//             const lastHelper = aplDoc.helper_list.length - 1;
//             if (
//               helpers.data().document_id ===
//               aplDoc.helper_list[lastHelper].helper_doc_id
//               /* && !!aplDoc.data().letter_url */
//             ) {
//               // let eval_length = 0;
//               let eval_new_length = 5;
//               // 사용X, 만족도조사 없는경우 에러 발생하여 주석처리
//               // let evaluation = 0;
//               // aplDoc.evaluation.forEach((v) => {
//               //   evaluation += v;
//               // });
//               // if (aplDoc.category2 === 'housekeeper') {
//               //   eval_length = 4;
//               // } else {
//               //   eval_length = 5;
//               // }

//               lists.push({
//                 document_id: helpers.data().document_id,
//                 company_id: helpers.data().company_id,
//                 product_id: helpers.data().product_id,
//                 helpergroup_type: helpers.data().helpergroup_type,
//                 helpergroup_nm: helpers.data().helpergroup_nm,
//                 nm: helpers.data().nm,
//                 phone: helpers.data().phone,
//                 evaluation_num_count: helpers.data().evaluation_num_count || 0,
//                 evaluation_num:
//                   // helpers.data().evaluation_count > 0
//                   //   // ? helpers.data().evaluation_sum /
//                   //   //   (helpers.data().evaluation_count * eval_length)
//                   //   // : 0,
//                   //   ? helpers.data().evaluation_new_sum > 0
//                   //   ? helpers.data().evaluation_sum > 0
//                   //   ? (helpers.data().evaluation_sum / eval_length + helpers.data().evaluation_new_sum / eval_new_length) / (helpers.data().evaluation_count)
//                   //   : (helpers.data().evaluation_new_sum / eval_new_length) / (helpers.data().evaluation_count)
//                   //   : helpers.data().evaluation_sum / (helpers.data().evaluation_count * eval_length)
//                   //   : 0,
//                   helpers.data().evaluation_num_count > 0
//                     ? helpers.data().evaluation_num_sum / (helpers.data().evaluation_num_count * eval_new_length)
//                     : 0,
//                 reg_dt: helpers.data().reg_dt,
//                 del_yn: helpers.data().del_yn,
//                 del_dt: helpers.data().del_dt,
//               });
//             }
//           });
//         });
//         setHelpers(lists);
//         setCount(lists.length);
//       } else {
//         setHelpers([]);
//         setCount(0);
//       }
//       // 날짜의 기준이 등록일 혹은 서비스 제공일이 아닌 경우 (undefined 인 경우)
//     } else {
//       helpersRef.docs.forEach((doc) => {
//         // let eval_length = 0;
//         let eval_new_length = 5;

//         // 회사의 타입이 housekeeper 인 경우, 설문지의 문항수가 4개
//         // if (doc.data().helpergroup_type === 'housekeeper') {
//         //   eval_length = 4;
//         //   // 회사의 타입이 housekeeper 가 아닌 경우(carerSupport 인 경우), 설문지의 문항수가 5개
//         // } else {
//         //   eval_length = 5;
//         // }
//         lists.push({
//           document_id: doc.data().document_id,
//           company_id: doc.data().company_id,
//           product_id: doc.data().product_id,
//           helpergroup_type: doc.data().helpergroup_type,
//           helpergroup_nm: doc.data().helpergroup_nm,
//           nm: doc.data().nm,
//           phone: doc.data().phone,
//           evaluation_num_count: doc.data().evaluation_num_count || 0,
//           evaluation_num:
//             // doc.data().evaluation_count > 0
//             //   // ? doc.data().evaluation_sum /
//             //   //   (doc.data().evaluation_count * eval_length)
//             //   // : 0,
//             //   ? doc.data().evaluation_new_sum > 0
//             //   ? doc.data().evaluation_sum > 0
//             //   ? (doc.data().evaluation_sum / eval_length + doc.data().evaluation_new_sum / eval_new_length) / (doc.data().evaluation_count)
//             //   : (doc.data().evaluation_new_sum / eval_new_length) / (doc.data().evaluation_count)
//             //   : doc.data().evaluation_sum / (doc.data().evaluation_count * eval_length)
//             //   : 0,
//             doc.data().evaluation_num_count > 0
//               ? doc.data().evaluation_num_sum / (doc.data().evaluation_num_count * eval_new_length)
//               : 0,
//           reg_dt: doc.data().reg_dt,
//           del_yn: doc.data().del_yn,
//           del_dt: doc.data().del_dt,
//         });
//       });
//       setHelpers(lists);
//       setCount(lists.length);
//     }

//     return helpersRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * (특정 분류를 갖는) 회사의 정보 조회
//  * @param {string} helpergroup_type 회사의 분류
//  */
// export async function getHelperGroupsList(helpergroup_type) {
//   try {
//     const lists = [];
//     let hgsRef;
//     // 인자가 들어오지 않은 경우, 조건문 없이 회사 정보 조회
//     if (helpergroup_type === undefined) {
//       hgsRef = await firestore.collection('adm_HELPERGROUPS').get();
//     } else {
//       hgsRef = await firestore
//         .collection('adm_HELPERGROUPS')
//         .where('type', '==', helpergroup_type)
//         .get();
//     }
//     hgsRef.docs.forEach((doc) => {
//       lists.push({
//         helpergroup_doc_id: doc.data().document_id,
//         helpergroup_type: doc.data().type,
//         helpergroup_nm: doc.data().nm,
//       });
//     });

//     return lists;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 도우미의 정보 조회
//  * @param {string} document_id 특정 도우미의 document id
//  */
// export async function getHelperDetail(document_id) {
//   try {
//     const helperRef = await firestore
//       .collection('adm_HELPERS')
//       .doc(document_id)
//       .get();
//     // let eval_length = 0;
//     let eval_new_length = 5;

//     // 회사의 타입이 housekeeper 인 경우, 설문지의 문항수가 4개
//     // if (helperRef.data().helpergroup_type === 'housekeeper') {
//     //   eval_length = 4;
//     //   // 회사의 타입이 housekeeper 가 아닌 경우(carerSupport 인 경우), 설문지의 문항수가 5개
//     // } else {
//     //   eval_length = 5;
//     // }
//     return {
//       document_id: helperRef.data().document_id,
//       company_id: helperRef.data().company_id,
//       product_id: helperRef.data().product_id,
//       helpergroup_nm: helperRef.data().helpergroup_nm,
//       helpergroup_type: helperRef.data().helpergroup_type,
//       helpergroup_doc_id: helperRef.data().helpergroup_doc_id,
//       nm: helperRef.data().nm,
//       phone: helperRef.data().phone,
//       birth_dt: helperRef.data().birth_dt,
//       local_yn: helperRef.data().local_yn,
//       evaluation_num_count: helperRef.data().evaluation_num_count || 0,
//       evaluation_num:
//         // helperRef.data().evaluation_count > 0
//         //   // ? helperRef.data().evaluation_sum /
//         //   //   (helperRef.data().evaluation_count * eval_length)
//         //   // : 0,
//         //   ? helperRef.data().evaluation_new_sum > 0
//         //   ? helperRef.data().evaluation_sum > 0
//         //   ? (helperRef.data().evaluation_sum / eval_length + helperRef.data().evaluation_new_sum / eval_new_length) / (helperRef.data().evaluation_count)
//         //   : (helperRef.data().evaluation_new_sum / eval_new_length) / (helperRef.data().evaluation_count)
//         //   : helperRef.data().evaluation_sum / (helperRef.data().evaluation_count * eval_length)
//         //   : 0,
//             helperRef.data().evaluation_num_count > 0
//               ? helperRef.data().evaluation_num_sum / (helperRef.data().evaluation_num_count * eval_new_length)
//               : 0,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 도우미의 활동 내역 조회
//  * @param {string} document_id 특정 도우미의 document id
//  */
// export async function getHelperActivities(document_id) {
//   try {
//     const helperRef = await firestore
//       .collection('adm_HELPERS')
//       .doc(document_id)
//       .get();
//     const aplsRef = await firestore
//       .collection('cust_APPLIES')
//       .where('del_yn', '==', false)
//       .orderBy('reg_dt', 'desc')
//       .orderBy('helper_list', 'asc')
//       .get();
//     const applies = [];
//     let reject_count = 0;
//     let assign_count = 0;
//     let evaluation_num_count = 0;
//     let evaluation_num_sum = 0;
//     // let evaluation_count = 0;
//     // let evaluation_sum = 0;
//     // let evaluation_new_sum = 0;

//     aplsRef.docs.forEach((doc) => {
//       const lastHelper = doc.data().helper_list.length - 1;
//       // 서비스 신청에 배정된 도우미가 있으면서, 가장 마지막에 배정된 도우미가 현재 선택된 도우미이면서, 상태가 완료 혹은 종결인 경우
//       if (
//         lastHelper !== undefined &&
//         lastHelper >= 0 &&
//         doc.data().helper_list[lastHelper].helper_doc_id === document_id &&
//         (doc.data().status === 13 || doc.data().status === 14)
//       ) {
//         // let apl_evaluation_sum = 0;
//         // let apl_evaluation_new_sum = 0;
//         // let eval_length = 0;
//         let apl_evaluation_num_sum = 0;
//         let eval_new_length = 5;

//         // 회사의 타입이 housekeeper 인 경우, 설문지의 문항수가 4개
//         // if (doc.data().category2 === 'housekeeper') {
//         //   eval_length = 4;
//         //   // 회사의 타입이 housekeeper 가 아닌 경우(carerSupport 인 경우), 설문지의 문항수가 5개
//         // } else {
//         //   eval_length = 5;
//         // }
//         assign_count++;
//         // if (
//         //   doc.data().evaluation !== undefined &&
//         //   doc.data().evaluation.length > 0
//         // ) {
//         //   evaluation_count++;
//         //   doc.data().evaluation.forEach((v) => {
//         //     apl_evaluation_sum += v;
//         //     evaluation_sum += v;
//         //   });
//         // }
//         //리뉴얼 만족도조사 버전
//         // if (
//         //   doc.data().evaluation_new !== undefined &&
//         //   doc.data().evaluation_new.length > 0
//         // ) {
//         //   evaluation_count++;
//         //   doc.data().evaluation_new.forEach((v) => {
//         //     apl_evaluation_new_sum += v;
//         //     evaluation_new_sum += v;
//         //   });
//         // }
//         if (
//           doc.data().evaluation_num !== undefined &&
//           doc.data().evaluation_num.length > 0
//         ) {
//           evaluation_num_count++;
//           doc.data().evaluation_num.forEach((v) => {
//             apl_evaluation_num_sum += v;
//             evaluation_num_sum += v;
//           });
//         }
//         applies.push({
//           member_nm: doc.data().member_nm,
//           reg_dt: doc.data().reg_dt,
//           first_letter_send_dt:
//             doc.data().helper_list[lastHelper].first_letter_send_dt,
//           service_hours: doc.data().service_hours * doc.data().service_days,
//           evaluation: apl_evaluation_num_sum / eval_new_length,
//           claim_yn: doc.data().claim_yn,
//         });
//       } else {
//         if (
//           doc.data().helper_list !== undefined &&
//           doc.data().helper_list.length > 0
//         ) {
//           doc.data().helper_list.forEach((v) => {
//             if (v.helper_doc_id === document_id) {
//               assign_count++;
//               if (v.reassign_yn) {
//                 reject_count++;
//               }
//             }
//           });
//         }
//       }
//     });

//     // 서비스 신청에서 수집한 거절 횟수, 배정 횟수, 설문 횟수, 설문결과 합 중 한 가지 이상이 저장된 도우미 정보와 다른 경우, 업데이트
//     if (
//       helperRef.data().reject_count !== reject_count ||
//       helperRef.data().assign_count !== assign_count ||
//       helperRef.data().evaluation_num_count !== evaluation_num_count ||
//       helperRef.data().evaluation_num_sum !== evaluation_num_sum
//     ) {
//       await firestore
//         .collection('adm_HELPERS')
//         .doc(document_id)
//         .set(
//           {
//             reject_count,
//             assign_count,
//             evaluation_num_count,
//             evaluation_num_sum,
//             mod_dt: Math.floor(new Date().getTime()),
//           },
//           { merge: true }
//         );
//     }

//     return {
//       document_id: helperRef.data().document_id,
//       company_id: helperRef.data().company_id,
//       product_id: helperRef.data().product_id,
//       helpergroup_doc_id: helperRef.data().helpergroup_doc_id,
//       helpergroup_nm: helperRef.data().helpergroup_nm,
//       helpergroup_type: helperRef.data().helpergroup_type,
//       nm: helperRef.data().nm,
//       phone: helperRef.data().phone,
//       birth_dt: helperRef.data().birth_dt,
//       local_yn: helperRef.data().local_yn,
//       applies,
//       reg_dt: helperRef.data().reg_dt,
//       mod_dt: helperRef.data().mod_dt,
//       del_dt: helperRef.data().del_dt,
//       del_yn: helperRef.data().del_yn,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 신규 도우미 추가
//  * @param {object} helper 추가될 도우미에 대한 정보
//  */
// export async function insertHelper(helper) {
//   // helper === {company_id, product_id, helpergroup_doc_id,
//   //             helpergroup_nm, helpergroup_type, nm, phone, birth_dt, local_yn}
//   try {
//     // 필수 파람(인자)가 없는 경우, 실패함을 리턴
//     if (
//       helper.company_id === undefined ||
//       helper.product_id === undefined ||
//       helper.helpergroup_doc_id === undefined ||
//       helper.helpergroup_nm === undefined ||
//       helper.helpergroup_type === undefined ||
//       helper.nm === undefined ||
//       helper.phone === undefined ||
//       helper.birth_dt === undefined ||
//       helper.local_yn === undefined
//     ) {
//       return { result: 'fail', message: 'Param is lack.' };
//     }

//     // 필수 파람(인자)가 아니거나 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//     Object.keys(helper).forEach((v) => {
//       if (
//         (v !== 'company_id' &&
//           v !== 'product_id' &&
//           v !== 'helpergroup_doc_id' &&
//           v !== 'helpergroup_nm' &&
//           v !== 'helpergroup_type' &&
//           v !== 'nm' &&
//           v !== 'phone' &&
//           v !== 'birth_dt' &&
//           v !== 'local_yn') ||
//         helper[v] === undefined ||
//         helper[v].length === 0
//       ) {
//         delete helper[v];
//       }
//     });
//     const now = Math.floor(new Date().getTime());

//     helper.reg_dt = now;
//     helper.mod_dt = now;
//     helper.del_dt = 0;
//     helper.del_yn = false;
//     helper.evaluation_num_count = 0;
//     // helper.evaluation_sum = 0;
//     helper.evaluation_num_sum = 0;
//     helper.reject_count = 0;
//     helper.assign_count = 0;
//     helper.dispatch_count = 0;
//     helper.first_eval_dt = 0;
//     helper.last_eval_dt = 0;
//     await firestore
//       .collection('adm_HELPERS')
//       .add(helper)
//       .then(async (doc) => {
//         helper.document_id = doc.id;
//         await firestore
//           .collection('adm_HELPERS')
//           .doc(doc.id)
//           .set({ document_id: doc.id }, { merge: true });
//       })
//       .catch((err) => {
//         console.error(err);
//       });

//     // insert adm_HELPERSAVG
//     const helpersavg = {
//       document_id: helper.document_id,
//       company_id: helper.company_id,
//       product_id: helper.product_id,
//       helpergroup_doc_id: helper.helpergroup_doc_id,
//       helpergroup_nm: helper.helpergroup_nm,
//       helpergroup_type: helper.helpergroup_type,
//       nm: helper.nm,
//       phone: helper.phone,
//       birth_dt: helper.birth_dt,
//       local_yn: helper.local_yn,
//       reg_dt: now,
//       mod_dt: now,
//       del_dt: 0,
//       del_yn: false,
//     };
//     await firestore
//       .collection('adm_HELPERSAVG')
//       .doc(helpersavg.document_id)
//       .set(helpersavg);

//     return { result: 'success', message: 'The helper is inserted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 도우미의 정보를 수정
//  * @param {object} helper 수정하려는 도우미의 정보 및 수정할 데이터
//  */
// export async function editHelperDetail(helper) {
//   // helper === getHelperDetail function's return value
//   try {
//     delete helper.applies;
//     const helperB = await firestore
//       .collection('adm_HELPERS')
//       .doc(helper.document_id)
//       .get();

//     // 현재 도우미가 삭제처리된 상태면, 실패함을 리턴
//     if (helperB.del_yn) {
//       return { result: 'fail', message: 'The helper was deleted.' };
//     }
//     const now = Math.floor(new Date().getTime());

//     // 현재 도우미의 정보와 비교했을 때 이름, 연락처, 생년월일, 내국인여부, 회사명, 회사타입 중 한 가지 이상이 변경된 경우
//     if (
//       !helper.del_yn &&
//       (helperB.nm !== helper.nm ||
//         helperB.phone !== helper.phone ||
//         helperB.birth_dt !== helper.birth_dt ||
//         helperB.local_yn !== helper.local_yn ||
//         helperB.helpergroup_nm !== helper.helpergroup_nm ||
//         helperB.helpergroup_type !== helper.helpergroup_type)
//     ) {
//       helper.mod_dt = now;

//       // 저장된 서비스 신청 정보를 불러와, 도우미 정보를 업데이트하고 저장
//       const aplsRef = await firestore
//         .collection('cust_APPLIES')
//         .where('del_yn', '==', false)
//         .orderBy('helper_list', 'asc')
//         .get();
//       aplsRef.docs.forEach(async (doc) => {
//         const helperList = doc.data().helper_list;
//         for (let i = 0; i < helperList.length; i++) {
//           if (helperList[i].helper_doc_id === helper.document_id) {
//             helperList[i].helper_nm = helper.nm;
//             helperList[i].helper_phone = helper.phone;
//             helperList[i].helper_birth_dt = helper.birth_dt;
//             helperList[i].helper_local_yn = helper.local_yn;
//             helperList[i].helpergroup_doc_id = helper.helpergroup_doc_id;
//             helperList[i].helpergroup_nm = helper.helpergroup_nm;
//             helperList[i].helpergroup_type = helper.helpergroup_type;
//             break;
//           }
//         }
//         await firestore.collection('cust_APPLIES').doc(doc.id).set(
//           {
//             helper_list: helperList,
//           },
//           { merge: true }
//         );
//       });

//       // 평균값 산정용 컬렉션에도 수정된 도우미 정보를 업데이트
//       const helperAvgsRef = await firestore
//         .collection('adm_HELPERSAVG')
//         .doc(helper.document_id)
//         .get();
//       const helpersAvg = helperAvgsRef.data();
//       helpersAvg.nm = helper.nm;
//       helpersAvg.phone = helper.phone;
//       helpersAvg.birth_dt = helper.birth_dt;
//       helpersAvg.local_yn = helper.local_yn;
//       helpersAvg.helpergroup_doc_id = helper.helpergroup_doc_id;
//       helpersAvg.helpergroup_nm = helper.helpergroup_nm;
//       helpersAvg.helpergroup_type = helper.helpergroup_type;
//       helpersAvg.mod_dt = now;
//       await firestore
//         .collection('adm_HELPERSAVG')
//         .doc(helper.document_id)
//         .set(helpersAvg, { merge: true });
//     } else if (helper.del_yn) {
//       helper.mod_dt = now;
//       helper.del_dt = now;

//       await firestore.collection('adm_HELPERSAVG').doc(helper.document_id).set(
//         {
//           mod_dt: now,
//           del_dt: now,
//           del_yn: true,
//         },
//         { merge: true }
//       );
//     } else {
//       return { result: 'fail', message: 'There is no modification.' };
//     }

//     Object.keys(helper).forEach((v) => {
//       if (helper[v] === undefined || helper[v].length === 0) {
//         delete helper[v];
//       }
//     });

//     await firestore
//       .collection('adm_HELPERS')
//       .doc(helper.document_id)
//       .set(helper, { merge: true });

//     return { result: 'success', message: 'The helper is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 도우미 대한 정보를 엑셀로 추출
//  * @param {string} avgType 추출 기준 (날짜 관련)
//  * @param {number} year 추출 기준 연도
//  * @param {number} month 추출 기준 월
//  */
// export async function makeXlsx(avgType, year, month) {
//   try {
//     const fileType =
//       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';
//     const lists = [];
//     // 추출 기준이 존재하지 않는 경우
//     if (avgType === undefined) {
//       const helpersRef = await firestore
//         .collection('adm_HELPERS')
//         .orderBy('reg_dt', 'desc')
//         .get();
//       const aplsRef = await firestore
//         .collection('cust_APPLIES')
//         .where('category2', 'in', ['carerSupport', 'housekeeper'])
//         .where('status', '>=', 13)
//         .where('status', '<=', 14)
//         .orderBy('status', 'asc')
//         .orderBy('reg_dt', 'desc')
//         .get();
//       // 신청일자 === 서비스 신청일자 로 고려하고 작성했음
//       if (aplsRef.size > 0) {
//         aplsRef.docs.forEach((aplDoc) => {
//           if (helpersRef.docs.length < 0) {
//             return;
//           }

//           helpersRef.docs.forEach((helpers) => {

//             console.log('json : '+JSON.stringify(aplDoc.data()))
//             const lastHelper = aplDoc.data().helper_list.length - 1;

//             if (
//               helpers.data().document_id ===
//               aplDoc.data().helper_list[lastHelper].helper_doc_id
//               /* && !!aplDoc.data().letter_url */
//             ) {
//               let evaluation = 0;
//               // let eval_length = 0;
//               let eval_new_length = 5;

//               if(aplDoc.data().evaluation_num || aplDoc.data().evaluation_num != undefined){
//                 aplDoc.data().evaluation_num.forEach((v) => {
//                   evaluation += v;
//                 });
//               }
//               // 리뉴얼 만족도조사가 있을 경우
//               // if(aplDoc.data().evaluation_new || aplDoc.data().evaluation_new != undefined){
//               //   aplDoc.data().evaluation_new.forEach((v) => {
//               //     evaluation += v;
//               //   });
//               // }else if(aplDoc.data().evaluation === undefined){
//               //   console.log(JSON.stringify(aplDoc.data()))
//               // }else{
//               //   // 구버전 만족도조사가 있을 경우
//               //   aplDoc.data().evaluation.forEach((v) => {
//               //     evaluation += v;
//               //   });
//               // }
//               // if (aplDoc.data().category2 === 'housekeeper') {
//               //   eval_length = 4;
//               // } else {
//               //   eval_length = 5;
//               // }

//               lists.push({
//                 고객사: company_ref[helpers.data().company_id],
//                 업무구분: helpergroup_type_ref[helpers.data().helpergroup_type],
//                 '간병/가사도우미명': helpers.data().nm,
//                 핸드폰번호: helpers.data().phone,
//                 생년월일: helpers.data().birth_dt,
//                 내외국인: helpers.data().local_yn ? '내국인' : '외국인',
//                 고객명: aplDoc.data().member_nm,
//                 '고객 신청일':
//                   getFormattedDate(aplDoc.data().reg_dt, 'opt6') || '',
//                 '배정일(서약서 문자 전송일)':
//                   getFormattedDate(
//                     aplDoc.data().helper_list[lastHelper].first_letter_send_dt,
//                     'opt6'
//                   ) || '',
//                 작업시간:
//                   aplDoc.data().service_days * aplDoc.data().service_hours,
//                 // '만족도 조사 결과': aplDoc.data().evaluation
//                 //   ? evaluation / eval_length
//                 //   : '미작성',
//                 '만족도 조사 결과':
//                 // aplDoc.data().evaluation
//                 //   ? evaluation / eval_length
//                 //   : aplDoc.data().evaluation_new
//                 //   ? evaluation / eval_new_length
//                   aplDoc.data().evaluation_num
//                   ? evaluation / eval_new_length
//                     : '미작성',
//                 '불만 접수 상태': aplDoc.data().claim_yn ? 'Y' : 'N',
//               });
//             }
//             // helpersRef.docs.splice(i, 1);
//             // break;
//           });
//         });
//       } else {
//         throw new Error('다운로드 할 데이터가 없습니다.');
//       }
//       // 추출 기준이 서비스 신청 작성 시각인 경우
//     } else if (avgType === 'applyReg') {
//       const from = dayjs(year + '-' + month + '-01').valueOf();
//       const to = dayjs(year + '-' + (month + 1) + '-01').valueOf();
//       const helpersRef = await firestore
//         .collection('adm_HELPERS')
//         .orderBy('reg_dt', 'desc')
//         .get();
//       const firstRef = await firestore
//         .collection('cust_APPLIES')
//         .where('category2', 'in', ['carerSupport', 'housekeeper'])
//         .where('status', '>=', 13)
//         .where('status', '<=', 14)
//         .orderBy('status', 'asc')
//         .orderBy('reg_dt', 'desc')
//         .get();
//       // 신청일자 === 서비스 신청일자 로 고려하고 작성했음
//       if (helpersRef.size > 0) {
//         let aplsRef = [];
//         if (firstRef.size > 0) {
//           firstRef.docs.forEach((firstDoc) => {
//             let regDt = Number(firstDoc.data().reg_dt);
//             if (regDt >= Number(from) && regDt < Number(to)) {
//               aplsRef.push(firstDoc.data());
//             }
//           });

//           // 데이터 입력
//           aplsRef.forEach((aplDoc) => {
//             if (helpersRef.docs.length < 0) {
//               return;
//             }
//             helpersRef.docs.forEach((helpers) => {
//               const lastHelper = aplDoc.helper_list.length - 1;
//               if (
//                 helpers.data().document_id ===
//                 aplDoc.helper_list[lastHelper].helper_doc_id
//                 /* && !!aplDoc.data().letter_url */
//               ) {
//                 let evaluation = 0;
//                 // let eval_length = 0;
//                 let eval_new_length = 5;
//                 // aplDoc.evaluation.forEach((v) => {
//                 //   evaluation += v;
//                 // }); -> 만족도조사가 없는 경우 에러 발생함

//                 // if(aplDoc.evaluation_new !== undefined || aplDoc.evaluation_new){
//                 //   // 리뉴얼 만족도조사가 있을 경우
//                 //   aplDoc.evaluation_new.forEach((v) => {
//                 //     evaluation += v;
//                 //   });
//                 // }else if(aplDoc.evaluation !== undefined || aplDoc.evaluation) {
//                 //   // 구버전 만족도조사가 있을 경우
//                 //   aplDoc.evaluation.forEach((v) => {
//                 //     evaluation += v;
//                 //   });
//                 // }
//                 // if (aplDoc.category2 === 'housekeeper') {
//                 //   eval_length = 4;
//                 // } else {
//                 //   eval_length = 5;
//                 // }
//                 if(aplDoc.evaluation_num !== undefined || aplDoc.evaluation_num){
//                   // 리뉴얼 만족도조사가 있을 경우(230321)
//                   aplDoc.evaluation_num.forEach((v) => {
//                     evaluation += v;
//                   });
//                 }

//                 lists.push({
//                   고객사: company_ref[helpers.data().company_id],
//                   업무구분:
//                     helpergroup_type_ref[helpers.data().helpergroup_type],
//                   '간병/가사도우미명': helpers.data().nm,
//                   핸드폰번호: helpers.data().phone,
//                   생년월일: helpers.data().birth_dt,
//                   내외국인: helpers.data().local_yn ? '내국인' : '외국인',
//                   고객명: aplDoc.member_nm,
//                   '고객 신청일': getFormattedDate(aplDoc.reg_dt, 'opt6') || '',
//                   '배정일(서약서 문자 전송일)':
//                     getFormattedDate(
//                       aplDoc.helper_list[lastHelper].first_letter_send_dt,
//                       'opt6'
//                     ) || '',
//                   작업시간: aplDoc.service_days * aplDoc.service_hours,
//                   // '만족도 조사 결과': aplDoc.evaluation
//                   //   ? evaluation / eval_length
//                   //   : '미작성',
//                   '만족도 조사 결과':
//                   // aplDoc.evaluation
//                   //   ? evaluation / eval_length
//                   //   : aplDoc.evaluation_new
//                   //   ? evaluation / eval_new_length
//                     aplDoc.evaluation_num
//                     ? evaluation / eval_new_length
//                       : '미작성',
//                   '불만 접수 상태': aplDoc.claim_yn ? 'Y' : 'N',
//                 });
//               }
//               // helpersRef.docs.splice(i, 1);
//               // break;
//             });
//           });
//         } else {
//           throw new Error('다운로드 할 데이터가 없습니다.');
//         }
//       } else {
//         throw new Error('다운로드 할 데이터가 없습니다.');
//       }
//       // 추출 기준이 서비스 제공일인 경우
//     } else {
//       const today = dayjs().valueOf();
//       const from = dayjs(year + '-' + month + '-01').valueOf();
//       const to = dayjs(year + '-' + (month + 1) + '-01').valueOf();
//       const helpersRef = await firestore
//         .collection('adm_HELPERS')
//         .orderBy('reg_dt', 'desc')
//         .get();
//       const firstRef = await firestore
//         .collection('cust_APPLIES')
//         .where('category2', 'in', ['carerSupport', 'housekeeper'])
//         .where('status', '>=', 13)
//         .where('status', '<=', 14)
//         .orderBy('status', 'asc')
//         .orderBy('service_dt', 'desc')
//         .get();
//       // 신청일자 === 서비스 신청일자 로 고려하고 작성했음
//       if (helpersRef.size > 0) {
//         let aplsRef = [];
//         if (firstRef.size > 0) {
//           firstRef.docs.forEach((firstDoc) => {
//             let serviceDt =
//               firstDoc.data().service_dt !== undefined
//                 ? dayjs(firstDoc.data().service_dt.substring(0, 10)).valueOf()
//                 : today;
//             if (serviceDt >= Number(from) && serviceDt < Number(to)) {
//               aplsRef.push(firstDoc.data());
//             }
//           });
//           // 데이터 입력
//           aplsRef.forEach((aplDoc) => {
//             if (helpersRef.docs.length < 0) {
//               return;
//             }
//             helpersRef.docs.forEach((helpers) => {
//               const lastHelper = aplDoc.helper_list.length - 1;
//               if (
//                 helpers.data().document_id ===
//                 aplDoc.helper_list[lastHelper].helper_doc_id
//                 /* && !!aplDoc.data().letter_url */
//               ) {
//                 let evaluation = 0;
//                 // let eval_length = 0;
//                 let eval_new_length = 5;
//                 // aplDoc.evaluation.forEach((v) => {
//                 //   evaluation += v;
//                 // }); -> 만족도조사가 없는 경우 에러 발생함

//                 // if(aplDoc.evaluation_new !== undefined || aplDoc.evaluation_new){
//                 //   // 리뉴얼 만족도조사가 있을 경우
//                 //   aplDoc.evaluation_new.forEach((v) => {
//                 //     evaluation += v;
//                 //   });
//                 // }else if(aplDoc.evaluation !== undefined || aplDoc.evaluation) {
//                 //   // 구버전 만족도조사가 있을 경우
//                 //   aplDoc.evaluation.forEach((v) => {
//                 //     evaluation += v;
//                 //   });
//                 // }
//                 // if (aplDoc.category2 === 'housekeeper') {
//                 //   eval_length = 4;
//                 // } else {
//                 //   eval_length = 5;
//                 // }
//                 if(aplDoc.evaluation_num !== undefined || aplDoc.evaluation_num){
//                   // 리뉴얼 만족도조사가 있을 경우(230321)
//                   aplDoc.evaluation_num.forEach((v) => {
//                     evaluation += v;
//                   });
//                 }

//                 lists.push({
//                   고객사: company_ref[helpers.data().company_id],
//                   업무구분:
//                     helpergroup_type_ref[helpers.data().helpergroup_type],
//                   '간병/가사도우미명': helpers.data().nm,
//                   핸드폰번호: helpers.data().phone,
//                   생년월일: helpers.data().birth_dt,
//                   내외국인: helpers.data().local_yn ? '내국인' : '외국인',
//                   고객명: aplDoc.member_nm,
//                   '고객 신청일': getFormattedDate(aplDoc.reg_dt, 'opt6') || '',
//                   '배정일(서약서 문자 전송일)':
//                     getFormattedDate(
//                       aplDoc.helper_list[lastHelper].first_letter_send_dt,
//                       'opt6'
//                     ) || '',
//                   작업시간: aplDoc.service_days * aplDoc.service_hours,
//                   // '만족도 조사 결과': aplDoc.evaluation
//                   //   ? evaluation / eval_length
//                   //   : '미작성',
//                   '만족도 조사 결과':
//                   // aplDoc.evaluation
//                   //   ? evaluation / eval_length
//                   //   : aplDoc.evaluation_new
//                   //   ? evaluation / eval_new_length
//                   //   : '미작성',
//                     aplDoc.evaluation_num
//                       ? evaluation / eval_new_length
//                         : '미작성',
//                   '불만 접수 상태': aplDoc.claim_yn ? 'Y' : 'N',
//                 });
//               }
//             });
//           });
//         } else {
//           throw new Error('다운로드 할 데이터가 없습니다.');
//         }
//       } else {
//         throw new Error('다운로드 할 데이터가 없습니다.');
//       }
//     }

//     const exportXlsx = (data) => {
//       const fileName =
//         'Healthcare_Admin_helpers_' + Math.floor(new Date().getTime() / 1000);
//       const workSheet = XLSX.utils.json_to_sheet(data);
//       const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
//       const excelBuffer = XLSX.write(workBook, {
//         bookType: 'xlsx',
//         type: 'array',
//       });
//       const blob = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(blob, fileName, fileExtension);
//     };
//     return exportXlsx(lists);
//   } catch (err) {
//     alert(err);
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }