import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 챗봇 전문의료진 리스트 조회
 */
export async function getChatDoctorList(
  setChatDoctor,
  searchState,
  setLoading
) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        searchState,
      }),
    }).then((response) => response.data.lists);

    setChatDoctor(result);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 전문의료진 등록
 * @param {object} chatDoctor 해당 전문의료진 등록
 */
export async function addChatDoctor(chatDoctor) {
  try {
    if (
      !chatDoctor.m_main_cate ||
      !chatDoctor.m_sub_cate ||
      !chatDoctor.m_doc ||
      !chatDoctor.m_dep ||
      !chatDoctor.m_name ||
      !chatDoctor.h_ykiho
    ) {
      return { result: 'fail', message: '미입력사항을 ' };
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        chatDoctor,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 전문의료진 의 상세 정보 조회
 * @param {string} no 해당 전문의료진 정보가 저장된 no
 */
export async function getChatDoctorDetail(no) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/doctor/getDetail/${no}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 전문의료진 의 수정
 * @param {object} chatDoctor 해당 전문의료진 정보
 */
export async function editChatDoctorDetail(chatDoctor) {
  try {
    if (
      !chatDoctor.m_main_cate ||
      !chatDoctor.m_sub_cate ||
      !chatDoctor.m_doc ||
      !chatDoctor.m_dep ||
      !chatDoctor.m_name ||
      !chatDoctor.m_ykiho
    ) {
      return { result: 'fail', message: '미입력사항을 ' };
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        chatDoctor,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 전문의료진 의 삭제
 * @param {object} m_no 해당 전문의료진 삭제
 */
export async function deleteChatDoctor(m_no) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/doctor/delete/${m_no}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 지역 조회
 */
export async function getRegionList(region, setSigungu) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/region/getList/${region}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data.lists);

    setSigungu(result);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 병원정보 조회 - db
 */
export async function searchHospital(searchState, setLoading) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/getHsptList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ searchState }),
    }).then((response) => response.data.lists);

    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 병원정보 조회 - 심평원
 */
export async function searchHospitalBySimpyungwon(searchState, setLoading) {
  try {
    if (searchState.type == 'h_fullname' && searchState.value == undefined) {
      //병원명 검색시 검색어 필수 입력
      return alert('병원명을 입력하세요');
    }

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/getSimpyungwon`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ searchState }),
    }).then((response) => response.data);

    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 병원정보 등록 - 심평원
 * @param {object} Hospital 해당 병원정보 등록
 */
export async function addHospital(Hospital) {
  try {
    if (
      !Hospital.h_ykiho ||
      !Hospital.h_fullname ||
      !Hospital.h_class ||
      !Hospital.h_addr ||
      !Hospital.h_sigungu ||
      !Hospital.h_sido
    ) {
      return { result: 'fail', message: '미입력사항을 ' };
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/addHospital`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        Hospital,
      }),
    }).then((response) => response.data);

    return alert('등록되었습니다.');
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 고객들의 모든 챗봇 건강유형 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_chatDoctor_' +
        Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };
    return await axios
      .post(`${baseUrl}/chatbot/doctor/excel`, {
        searchState: searchState,
      })
      .then((res) => {
        return exportXlsx(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 전문의료진 진료과(용어)
 */
export async function getDeptList(setDeptList, setDeptMap) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/doctor/getDeptList`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setDeptList(response.data.list);
      setDeptMap(response.data.map);
    });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
/**
 * 챗봇 전문의료진 질병(용어)
 */
export async function getDeseaseList(searchState, setLoading) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/getDeseaseList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ searchState }),
    }).then((response) => response.data);

    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
