import React, { useEffect, useRef, useState } from 'react';
import { getTreatmentsList } from 'controllers/Contents/treatments';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, TreatmentsAddTable,ListTableTemplate } from 'components/Common';
import { Button, Drawer, Spin } from 'antd';
import { routes_ref } from 'lib/routeRef';
import { company_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import PostTreatmentsDetailContainer from 'containers/Contents/PostTreatments/PostTreatmentsDetailContainer';

const PostTreatmentsListTableContainer = () => {
  const [treatments, setTreatments] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });

  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  useEffect(() => {
    getTreatmentsList(setTreatments, setCount, setLoading);
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => showDrawer(document_id)}
          >
            관리
          </Button>
        </>
      ),
    },
    // {
    //   title: '보험사',
    //   dataIndex: 'company_id',
    //   key: 'company_id',
    //   align: 'center',
    //   render: (data) => company_ref[data],
    // },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: '기사등록시각',
      dataIndex: 'newspaper_dt',
      key: 'newspaper_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '등록시각',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '수정시각',
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '공개여부',
      dataIndex: 'del_yn',
      key: 'del_yn',
      align: 'center',
      render: (date) => (date ? '비공개' : '공개'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.contents.treatment.path}
          />
        )}
        countLabel={( <CounterLabel count={count} />)}
        addButton={(<TreatmentsAddTable />)}
      />
          <ListTableTemplate
            dataSource={treatments}
            columns={columns}
            loading={loading}
            DetailTableContainer={(
            <Drawer
              width={750}
              closable
              onClose={onClose}
              open={status.visible}
            >
              <PostTreatmentsDetailContainer
                document_id={status.document_id} />
            </Drawer>
            )}
          />
      {/* <TreatmentsListTable treatments={treatments} loading={loading} /> */}
    </>
  );
};

export default PostTreatmentsListTableContainer;
