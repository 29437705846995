import React, { useEffect, useState } from 'react';
import { SaveButton } from 'components/Common';
import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  Input,
  Select,
  Space,
  Row,
  Checkbox,
  Badge,
  ConfigProvider,
  Spin,
} from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { default as TermSelect } from 'react-select';
import Text from 'antd/es/typography/Text';
import {
  addChatDoctor,
  getChatDoctorDetail,
  editChatDoctorDetail,
  searchHospitalBySimpyungwon,
  getDeptList,
  getDeseaseList,
} from 'controllers/chatbot/chatbotDoctor';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import { getFormattedDate } from 'lib/getFormattedDate';
import {
  chat_category1,
  chat_category2_by_doctor,
  region_by_simpyungwon,
} from 'lib/chatbotDataRef';

const { Item } = Descriptions;
const ChatDoctorAddContainer = ({ props }) => {
  const { state } = props;
  const [add, setAdd] = useState(true);
  const [hospital, setHospital] = useState(null);
  const [statusH, setStatusH] = useState(false); // 병원 status
  const [desease, setDesease] = useState([]);
  const [statusD, setStatusD] = useState(false); // 병명 status
  const [searchState, setSearchState] = useState({});
  const [loading, setLoading] = useState(false);
  const [chatDoctor, setDoctor] = useState({});
  const [selectedDesease, setSelectedDesease] = useState('');
  const [deptList, setDeptList] = useState([]);
  const [deptMap, setDeptMap] = useState({});
  useEffect(() => {
    if (state) {
      const data = getChatDoctorDetail(state.m_no);
      data.then((d) => {
        setDoctor(d);
        if (d.m_name != '' && d.m_name != undefined) {
          setSelectedDesease(d.m_name);
        }
        setAdd(false);
      });
    }
  }, [state]);

  useEffect(() => {
    setLoading(true);
    getDeptList(setDeptList, setDeptMap).then(() => {
      setLoading(false);
    });
  }, []);

  const onClose = () => {
    setHospital(null);
    setSearchState({ type: 'all' });
    setStatusH(false);
    setStatusD(false);
  };

  const goSearch = (type) => {
    setLoading(true);
    let result;
    if (type == 'hospital') {
      result = searchHospitalBySimpyungwon(searchState, setLoading);
      result.then((d) => {
        setHospital(d);
        setLoading(false);
      });
    } else if (type == 'desease') {
      result = getDeseaseList(searchState, setLoading);
      result.then((d) => {
        setDesease(d);
        setLoading(false);
      });
    }
  };

  const setHospitalData = (hspt) => {
    const bool = confirm(`${hspt.h_fullname}을 등록하시겠어요?`);

    if (bool) {
      // 등록ok, 심평원에서 콜해서 신규등록일때
      try {
        setDoctor({
          ...chatDoctor,
          ...hspt,
        });
        setStatusH(false);
        setHospital(null);
        setSearchState({ type: 'all' });
      } catch (e) {
        alert('등록에 실패했습니다.\n 관리자에게 문의 바랍니다');
      }
    }
  };
  const onKeyPress = (e, type) => {
    if (e.key === 'Enter') {
      goSearch(type);
    }
  };
  const hospitalResult = () => {
    if (hospital.length > 0) {
      return hospital.map((hspt) => {
        return (
          <Row
            key={hspt.h_no}
            onClick={() => {
              setHospitalData(hspt);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ fontWeight: 'bolder', fontSize: '15px' }}>
                {hspt.h_fullname}
              </Text>

              <Text>{hspt.h_addr}</Text>
            </div>
            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
          </Row>
        );
      });
    }
  };
  const onChecked = (e, d_name) => {
    if (e.target.checked) {
      if (replaceComma(selectedDesease).split(',').length >= 10) {
        alert('최대 10개까지 추가할 수 있습니다.');
      } else {
        setSelectedDesease(selectedDesease + ',' + d_name);
      }
    } else {
      setSelectedDesease(replaceComma(selectedDesease.replace(d_name, '')));
    }
  };
  const deseaseResult = () => {
    if (desease.length > 0) {
      return desease.map((d) => {
        return (
          <Row key={d.d_no}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '5px 15px',
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: '5px' }}
                checked={selectedDesease.includes(d.representative)}
                onChange={(e) => onChecked(e, d.representative)}
              />
              <Text style={{ fontWeight: 'bolder', fontSize: '15px' }}>
                {d.representative} &nbsp;
              </Text>
              <Text>({d.synonym})</Text>
            </div>
            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
          </Row>
        );
      });
    }
  };
  const addDesease = () => {
    if (replaceComma(selectedDesease).split(',').length > 10) {
      alert('최대 10개까지 추가할 수 있습니다.');
    } else {
      setDoctor({
        ...chatDoctor,
        m_name: replaceComma(selectedDesease),
      });
      setStatusD(false);
      setSearchState({ type: 'all' });
    }
  };
  const replaceComma = (data) => {
    return data.replace(/,\s*$/, '').replace(/^,\s*/, '').replace(/,,/g, ',');
  };
  const setMNameList = () => {
    if (chatDoctor.m_name) {
      const entriesArr = chatDoctor.m_name.split(',');
      return entriesArr.map((array) => (
        <Button
          style={{ margin: '5px' }}
          icon={<CloseOutlined />}
          onClick={() => {
            setDoctor({
              ...chatDoctor,
              m_name: replaceComma(chatDoctor.m_name.replace(array, '')),
            });
            setSelectedDesease(
              replaceComma(selectedDesease.replace(array, ''))
            );
          }}
        >
          {array}
        </Button>
      ));
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Descriptions: {
              extraColor: 'red',
            },
          },
        }}
      >
        {loading && deptList.length == 0 ? (
          <Spin tip="Loading" size="large">
            <Descriptions
              title={add ? '전문의료진 등록' : '전문의료진 수정'}
              column={1}
              bordered
              style={{ width: '100%' }}
              extra="AI건강상담 챗봇에 반영되는 정보입니다."
            ></Descriptions>
          </Spin>
        ) : (
          <Descriptions
            title={add ? '전문의료진 등록' : '전문의료진 수정'}
            column={1}
            bordered
            style={{ width: '100%' }}
            extra="AI건강상담 챗봇에 반영되는 정보입니다."
          >
            <Item
              label={
                chatDoctor.m_main_cate && chatDoctor.m_sub_cate ? (
                  <Badge status="success" text="분류" />
                ) : (
                  <Badge status="error" text="분류" />
                )
              }
            >
              <Select
                style={{ width: '150px' }}
                value={
                  chatDoctor.m_main_cate ? chatDoctor.m_main_cate : '대분류'
                }
                onChange={(e) => {
                  setDoctor({
                    ...chatDoctor,
                    m_main_cate: e,
                  });
                }}
              >
                {getOptionsByRefId(chat_category1)}
              </Select>
              <Select
                style={{ width: '150px', marginLeft: '10px' }}
                value={chatDoctor.m_sub_cate ? chatDoctor.m_sub_cate : '소분류'}
                onChange={(e) => {
                  if (chatDoctor.m_main_cate == undefined) {
                    return alert('대분류를 먼저 선택해주세요.');
                  } else {
                    setDoctor({
                      ...chatDoctor,
                      m_sub_cate: e,
                    });
                  }
                }}
              >
                {getOptionsByRefId(
                  chat_category2_by_doctor(chatDoctor.m_main_cate)
                )}
              </Select>
            </Item>

            <Item
              label={
                chatDoctor.m_doc ? (
                  <Badge status="success" text="이름" />
                ) : (
                  <Badge status="error" text="이름" />
                )
              }
            >
              <Input
                style={{ width: '200px' }}
                value={chatDoctor.m_doc}
                onChange={(e) => {
                  setDoctor({
                    ...chatDoctor,
                    m_doc: e.target.value,
                  });
                }}
              />
              {add ? (
                ''
              ) : (
                <>
                  <Checkbox
                    style={{ marginLeft: '5px' }}
                    checked={chatDoctor.hide_yn}
                    onChange={() => {
                      setDoctor({
                        ...chatDoctor,
                        hide_yn: !chatDoctor.hide_yn,
                      });
                    }}
                  >
                    목록에서 숨김
                  </Checkbox>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text style={{ color: 'grey' }}>
                      * 숨김 체크 시 챗봇에서 검색되지 않음
                    </Text>
                  </div>
                </>
              )}
            </Item>

            <Item
              label={
                chatDoctor.m_dep ? (
                  <Badge status="success" text="진료과" />
                ) : (
                  <Badge status="error" text="진료과" />
                )
              }
            >
              <TermSelect
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '400px',
                    marginBottom: '5px',
                  }),
                }}
                isMulti={false}
                placeholder={'선택'}
                value={{ label: chatDoctor.m_dep }}
                options={deptList}
                components={{ IndicatorSeparator: () => null }}
                onChange={(e) => {
                  setDoctor({
                    ...chatDoctor,
                    m_dep: e.value,
                  });
                }}
              ></TermSelect>
            </Item>
            <Item
              label={
                chatDoctor.m_name ? (
                  <Badge status="success" text="진료분야" />
                ) : (
                  <Badge status="error" text="진료분야" />
                )
              }
            >
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  type="primary"
                  style={{
                    marginLeft: '5px',
                    marginRight: '5px',
                    width: '100px',
                  }}
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setStatusD(true);
                  }}
                >
                  병명 추가
                </Button>
                <Text style={{ color: 'grey', marginTop: '5px' }}>
                  * 최대 10개까지 추가할 수 있습니다.
                </Text>
              </div>
              <div>{chatDoctor.m_name != null ? setMNameList() : null}</div>
            </Item>
            <Item
              label={
                chatDoctor.h_fullname ? (
                  <Badge status="success" text="병원명" />
                ) : (
                  <Badge status="error" text="병원명" />
                )
              }
            >
              {add ? (
                <Input.Search
                  placeholder="병원 찾기"
                  style={{ width: '200px' }}
                  value={chatDoctor.h_fullname ? chatDoctor.h_fullname : ''}
                  onSearch={(v) => {
                    setStatusH(true);
                  }}
                  onClick={(v) => {
                    setStatusH(true);
                  }}
                />
              ) : (
                <div>
                  <Input
                    disabled
                    style={{ width: '200px' }}
                    value={chatDoctor.h_fullname}
                  />
                  <Button
                    type="primary"
                    style={{ marginLeft: '5px' }}
                    onClick={() => {
                      setStatusH(true);
                    }}
                  >
                    병원 변경
                  </Button>
                </div>
              )}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ color: 'grey' }}>
                  * 의료진이 근무하는 병원이 두 곳 이상인 경우 병원별로 각각
                  등록해 주세요.
                </Text>
              </div>
            </Item>
            {chatDoctor.h_class != undefined ? (
              <>
                <Item label="병원 종류">
                  <Text>{chatDoctor.h_class}</Text>
                </Item>
                <Item label="병원 주소">
                  <Text>{chatDoctor.h_addr}</Text>
                </Item>
                <Item label="병원 전화번호">
                  <Text>{chatDoctor.h_tel}</Text>
                </Item>
              </>
            ) : (
              ''
            )}
            {!add ? (
              <>
                <Item label="등록일">
                  <Text>{getFormattedDate(chatDoctor.reg_dt, 'opt5')}</Text>
                </Item>
                <Item label="최종 수정일">
                  <Text>{getFormattedDate(chatDoctor.mod_dt, 'opt5')}</Text>
                </Item>
              </>
            ) : (
              ''
            )}
          </Descriptions>
        )}
      </ConfigProvider>
      <Divider />
      <Space>
        <Button
          type="primary"
          style={{ width: '70px' }}
          onClick={async () => {
            window.location.href = '/doctor';
          }}
        >
          목록
        </Button>
        {add ? (
          <SaveButton
            func={addChatDoctor}
            data={chatDoctor}
            location="/doctor"
          />
        ) : (
          <Button
            type="primary"
            style={{ marginLeft: '6px', minWidth: '90px' }}
            onClick={async () => {
              const bool = confirm(
                '페이지 내 모든 수정 사항을 저장하시겠습니까?'
              );
              if (bool) {
                try {
                  const res = await editChatDoctorDetail(chatDoctor);
                  if (res.result == 'success') {
                    setSelectedDesease('');
                    insertInfoAcessLog(
                      location.pathname,
                      'chatDoctorDetail',
                      '수정',
                      undefined,
                      chatDoctor.m_no + '_' + chatDoctor.h_no
                    ),
                      alert('저장되었습니다');

                    window.location.href = '/doctor';
                  }
                } catch (error) {
                  alert('저장에 실패하였습니다\n 관리자에게 문의 바랍니다');
                }
              }
            }}
          >
            수정하기
          </Button>
        )}
      </Space>
      {/* 병원찾기 Drawer */}
      <Drawer width={600} closable onClose={onClose} open={statusH}>
        <Descriptions
          title="병원 찾기"
          column={1}
          style={{ width: '100%' }}
        ></Descriptions>
        <Select
          style={{ width: '150px' }}
          value={searchState.sido ? searchState.sido : '전체'}
          onChange={(e) => {
            setSearchState({
              ...searchState,
              sido: e,
            });
          }}
        >
          {getOptionsByRefId(region_by_simpyungwon)}
        </Select>
        <Input
          placeholder="병원명을 입력해 주세요."
          style={{ width: '300px', margin: '0px 5px' }}
          value={searchState.h_class}
          onChange={(e) => {
            setSearchState({
              ...searchState,
              h_class: e.target.value,
            });
          }}
          onKeyPress={(e) => onKeyPress(e, 'hospital')}
        ></Input>
        <Button
          onKeyPress={(e) => onKeyPress(e, 'hospital')}
          onClick={() => {
            goSearch('hospital');
          }}
        >
          검색
        </Button>

        <div style={{ height: '80%', paddingTop: hospital ? '0%' : '40%' }}>
          {loading ? (
            <Text disabled style={{ paddingLeft: '30%', fontSize: '30px' }}>
              병원을 검색중입니다.
            </Text>
          ) : !hospital ? (
            <Text disabled style={{ paddingLeft: '40%' }}>
              병원을 검색해주세요.
            </Text>
          ) : hospital.length > 0 ? (
            hospitalResult()
          ) : (
            <Text disabled style={{ paddingLeft: '40%' }}>
              검색 결과가 없습니다.
            </Text>
          )}
        </div>
      </Drawer>
      {/* 병명찾기 Drawer */}
      <Drawer width={600} closable onClose={onClose} open={statusD}>
        <Descriptions
          title="병명 검색"
          column={1}
          style={{ width: '100%' }}
        ></Descriptions>
        <Select
          style={{ width: '140px' }}
          value={searchState.department ? searchState.department : '전체'}
          onChange={(e) => {
            setSearchState({
              ...searchState,
              department: e,
            });
          }}
        >
          {getOptionsByRefId(deptMap)}
        </Select>
        <Input
          style={{ width: '60%', margin: '0px 5px' }}
          value={searchState.synonym}
          onChange={(e) => {
            setSearchState({
              ...searchState,
              synonym: e.target.value,
            });
          }}
          onKeyPress={(e) => onKeyPress(e, 'desease')}
        ></Input>
        <Button
          onKeyPress={(e) => onKeyPress(e, 'desease')}
          onClick={() => {
            goSearch('desease');
          }}
        >
          검색
        </Button>

        <div style={{ height: '85%', marginTop: '10px' }}>
          <div
            style={{
              backgroundColor: '#FFFBE9',
              // height: '40px',
              padding: '10px',
              textAlign: 'center',
              // lineHeight: '40px',
            }}
          >
            <Text
              disabled
              style={{ color: selectedDesease != '' ? 'black' : 'grey' }}
            >
              {selectedDesease != ''
                ? replaceComma(selectedDesease)
                : '진료분야(병명)를 선택해 주세요.'}
            </Text>
          </div>
          <div
            style={{
              height: '60%',
              marginTop: '10px',
              paddingTop: desease ? '0%' : '40%',
            }}
          >
            {loading ? (
              <Text disabled style={{ paddingLeft: '30%', fontSize: '30px' }}>
                병명을 검색중입니다.
              </Text>
            ) : !desease ? (
              <Text disabled style={{ paddingLeft: '40%' }}>
                병명을 검색해주세요.
              </Text>
            ) : desease.length > 0 ? (
              deseaseResult()
            ) : (
              <Text disabled style={{ paddingLeft: '40%' }}>
                검색 결과가 없습니다.
              </Text>
            )}

            {desease.length > 0 ? (
              <Button
                type="primary"
                style={{ width: '100%', marginBottom: '10px' }}
                onClick={() => addDesease()}
              >
                추가
              </Button>
            ) : null}
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ChatDoctorAddContainer;
