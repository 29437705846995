import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { baseUrl } from 'lib/url';
import axios from 'axios';

// /**
//  * 암호화된 비밀번호를 복호화 처리
//  * 외부 관리자
//  * @param {string} encryptPwd 암호화된 비밀번호
//  */
// export function decryptPwd(encryptPwd) {
//   return AES.decrypt(encryptPwd, 'FINElab2020').toString(enc);
// }

/**
 * 암호화
 * @param {string} data 암호화할 데이터
 */
export function encrypt(data) {
  const crtyptoKey = process.env.CRYPTO_SERVER_KEY;
  return AES.encrypt(data, crtyptoKey).toString();
}

/**
 * 복호화
 * @param {string} data 복호화할 데이터
 */
export function decrypt(data) {
  const crtyptoKey = process.env.CRYPTO_SERVER_KEY;
  return AES.decrypt(data, crtyptoKey).toString(enc);
}

/**
 * 등록된 서비스 신청을 삭제함
 * @param {object} data 신청대상 리스트
 */
export async function deleteBundle(data, type) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/deleteBundle`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        data,
        type,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
