import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const AddButton = (props) => (
  <Button
    type={props.type}
    onClick={props.handleDrawerShow}
    style={{float: 'right', marginLeft:'8px'}}
  >
    추가
  </Button>
);

AddButton.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  handleDrawerShow: PropTypes.func,
};

AddButton.defaultProps = {
  type: 'primary',
  text: '추가 버튼',
  handleDrawerShow: () => {
    console.log('drawer open');
  },
};
export default AddButton;
