import React, { useState } from 'react';
import { Badge, Row, Descriptions, Divider, Input, Space, Drawer, Select } from 'antd';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { postPush } from 'controllers/Contents/push';
import { product_id_ref } from 'lib/dataRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';

const { Item } = Descriptions;
const PushAddTable = () => {
  const [pushDetail, setPushDetail] = useState({});
  const [drawerStatus, setDrawerStatus] = useState(false);
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setPushDetail(pushDetail);
  };
  const handleChangeProductId = (product_id) => {
    setPushDetail({
      ...pushDetail,
      product_id,
    });
  };
  return (
    <>
    <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
      <Row>
        <Descriptions title="알림푸시 추가" bordered column={1} style={{ width: '100%' }}>
          <Item label={pushDetail.product_id ? <Badge status="success" text="보험사" /> : <Badge status="error" text="보험사" />}>
            <Select
              style={{ width: '100%' }}
              onChange={handleChangeProductId}
              value={pushDetail.product_id}
            >
              {getOptionsByRefId(product_id_ref)}
            </Select>
          </Item>
          <Item label={pushDetail.nm ? <Badge status="success" text="이름" /> : <Badge status="error" text="이름" />}>
            <Input
              value={pushDetail.nm}
              onChange={(e) => {
                setPushDetail({
                  ...pushDetail,
                  nm: e.target.value,
                });
              }}
            />
          </Item>
          <Item label={pushDetail.phone ? <Badge status="success" text="연락처" /> : <Badge status="error" text="연락처" />}>
            <Input
              value={pushDetail.phone}
              onChange={(e) => {
                setPushDetail({
                  ...pushDetail,
                  phone: e.target.value,
                });
              }}
            />
          </Item>
          <Item label="제목">
            <Input.TextArea
              value={pushDetail.subject}
              rows={8}
              allowClear
              onChange={(e) => setPushDetail({ ...pushDetail, subject: e.target.value })}
            />
          </Item>
          <Item label="내용">
            <Input.TextArea
              value={pushDetail.content}
              rows={8}
              allowClear
              onChange={(e) => setPushDetail({ ...pushDetail, content: e.target.value })}
            />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/push"
          />
          <SaveButton
            func={postPush}
            data={pushDetail}
            location="/push"
          />
        </Space>
      </Row>
      </Drawer>
    </>
  );
};

export default PushAddTable;
