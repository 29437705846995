import { message } from 'antd';
import axios from 'axios';
import { getCookie } from 'lib/cookie';
import { routes_ref } from 'lib/routeRef';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 관리자 개인정보접근 로그 신규 입력
 * @param {object} adminLog 신규로 입력될 로그(id, nm, ...)
 */
export async function insertInfoAcessLog(
  location,
  cont,
  type,
  excelReason,
  infoText
) {
  // admin keys : id, nm, pwd, department, permissions, ....(optional)
  try {
    // document에 들어가야 할 필수 값 체크
    if (!location && !cont) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const document_id = getCookie('session');
    let result;
    // 다운로드 시 사유 필수 값 체크
    if (cont == 'makeXlsx' && !excelReason) {
      result = await axios({
        method: 'POST',
        url: `${baseUrl}/system/infoAcessLog/add`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          location,
          cont,
          document_id,
          excelReason: '사유 미입력',
          type: '다운로드 실패',
          infoText,
        }),
      }).then((response) => response.data);
    } else {
      result = await axios({
        method: 'POST',
        url: `${baseUrl}/system/infoAcessLog/add`,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          location,
          cont,
          document_id,
          excelReason,
          type,
          infoText,
        }),
      }).then((response) => response.data);
    }

    if (cont == 'makeXlsx' && !excelReason) {
      await message.error(`다운로드에 실패했습니다. 사유를 확인해주세요`);
    } else if ((cont == 'makeXlsx' || cont == 'downloadImg') && excelReason) {
      await message.success(
        `다운로드에 성공했습니다. 파일이 생성될때까지 다소 시간이 걸릴 수 있습니다.`
      );
    }

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 관리자 개인정보접근 내역 조회
 */
export async function getInfoAcessLogList(
  setAdminLogs,
  adminLogs,
  goPage,
  setCurrentPage,
  searchState,
  setLoading
) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/system/infoAcessLog/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage,
        pagingState: adminLogs.pagingState,
        searchState: searchState,
      }),
    })
      .then((res) => {
        setAdminLogs(res.data);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 모든 관리자 개인정보접근 로그 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    // 존재하는 메뉴값들 보내야함 (백단에서 별도관리안하기위해)
    let menuList = [];
    for (const key in routes_ref.base) {
      let pathList = routes_ref.base[key];

      for (const path in pathList) {
        if (path !== 'default_label') {
          let menu = {};
          menu.label = pathList[path].label.label;
          menu.path = pathList[path].path;
          menuList.push(menu);
        }
      }
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_InfoAcessLog_' +
        Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };
    return await axios
      .post(`${baseUrl}/system/infoAcessLog/excel`, {
        searchState: searchState,
        menuList: menuList,
      })
      .then((res) => {
        return exportXlsx(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
