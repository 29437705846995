import React, { useState } from 'react';
import { Descriptions, Divider, Input, Space, Drawer } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { insertAdmin } from 'controllers/Services/admins';
import { CancelButton, SaveButton, AddButton } from 'components/Common';
import AdminPermissionCheckTable from './AdminPermissionCheckTable';
// import AdminExternalAccountTable from './AdminExternalAccountTable';

const { Item } = Descriptions;
const AdminAddTable = () => {
  const [admin, setAdmin] = useState({
    id: '',
    pwd: '',
    nm: '',
    phone: '',
    reg_dt: 0,
    mod_dt: 0,
    block_yn: false,
    block_dt: 0,
    last_logged_dt: 0,
    pwd_mod_dt: 0,
    permissions: {
      common: [],
      admins: [],
      members: [],
      services: [],
      contents: [],
      outers: [],
      healthLogs: [],
    },
    department: '',
    other_admin_sites: {
      db: {
        id: '',
        pwd: '',
      },
      ss: {
        id: '',
        pwd: '',
      },
    },
  });
 const [drawerStatus, setDrawerStatus] = useState(false);
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setAdmin(admin);
  };

  return (
    <>
     <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={750}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
        <Descriptions
          title="관리자 추가"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
            <Item label="* ID">
              <Input
                value={admin.id}
                onChange={(e) => setAdmin({ ...admin, id: e.target.value })}
              />
            </Item>
          <Item label="* 비밀번호">
            <Input.Password
              value={admin.pwd}
              onChange={(e) => setAdmin({ ...admin, pwd: e.target.value })}
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Item>
          <Item label="* 이름">
            <Input
              value={admin.nm}
              onChange={(e) => setAdmin({ ...admin, nm: e.target.value })}
            />
          </Item>
          <Item label="연락처">
            <Input
              value={admin.phone}
              onChange={(e) => setAdmin({ ...admin, phone: e.target.value })}
            />
          </Item>
          <Item label="* 소속">
            <Input
              value={admin.department}
              onChange={(e) => setAdmin({ ...admin, department: e.target.value })}
            />
          </Item>
          <Item label="* 권한">
            <AdminPermissionCheckTable
              adminDetail={admin}
              setAdminDetail={setAdmin}
            />
          </Item>
          {/* <Item label="외부 관리자 계정" style={{ width: '105px' }}>
            <AdminExternalAccountTable
              adminDetail={admin}
              setAdminDetail={setAdmin}
            />
          </Item> */}
        </Descriptions>
      <Divider />
        <Space>
          <CancelButton
            location="/admin"
          />
          <SaveButton
            func={insertAdmin}
            data={admin}
            location="/admin"
          />
        </Space>
         </Drawer>
    </>
  );
};

export default AdminAddTable;
