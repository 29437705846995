/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { MenuPane } from 'components/MenuPane';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { useCheckAuth } from 'lib/hooks';
import PApplyAddContainer from '../../containers/Services/Applies/PApplyAddContainer';
import { useLocation } from 'react-router';

const PApplyAddPage = ({ pid }) => {
  const location = useLocation();
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['aaihc']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<PApplyAddContainer props={location} />}
    />
  );
};

export default PApplyAddPage;
