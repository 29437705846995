import { useState } from 'react';
import { getApiLogSearchList, makeXlsx } from '../../controllers/Outers/bbiddakiApiLog';
import dayjs from 'dayjs';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

export default function useApiLogSearch(setApiLog, setCount) {
  const ApiLog = {};
  const [apiLogSearch, setApiLogSearch] = useState({
    programType: 'all',
    successResult: 'all',
    startDate: new Date(),
    endDate: new Date(),
    isOnSearching: false,
  });

  ApiLog.apiLogSearch = apiLogSearch;

  ApiLog.handleApiLogProgramTypeChange = (programType) => {
   setApiLogSearch({
      ...apiLogSearch,
      programType,
      successResult: 'all',
      isOnSearching: true,
    });
  };

  ApiLog.handleApiLogSuccessResultChange = (successResult) => {
   setApiLogSearch({
      ...apiLogSearch,
      successResult,
      isOnSearching: true,
    });
  };

  ApiLog.handleApiLogPeriodChange = (type, date) => {
    if(type === 'start') {
     setApiLogSearch({
        ...apiLogSearch,
        startDate: date,
      });
    } else {
     setApiLogSearch({
        ...apiLogSearch,
        endDate: date,
      });
    }

  };

  ApiLog.handleSearch = () => {
    // TODO: 해당 월 조회 기능 구현하기
    const searchedList = [];
    getApiLogSearchList(setApiLog, setCount, apiLogSearch.programType, apiLogSearch.successResult,
     apiLogSearch.startDate, apiLogSearch.endDate).then((d) => {
      searchedList.push(d);
    });
  };

  ApiLog.handleApiLogReset = () => {
    console.log('search reset!');
    setApiLogSearch({
     programType: 'all',
        successResult: 'all',
        startDate: new Date(),
        endDate: new Date(),
        isOnSearching: false,
    });
  };

  ApiLog.handleExcelDownload = (excelReason, searchState) => {
   makeXlsx(apiLogSearch.programType, apiLogSearch.successResult, apiLogSearch.startDate, apiLogSearch.endDate, excelReason, searchState);
   insertInfoAcessLog(location.pathname, 'makeXlsx', "다운로드", excelReason);
  }
  return ApiLog;
}
