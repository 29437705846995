import React, { useEffect, useState } from 'react';
import { getPushList, sendPush } from 'controllers/Contents/push';
import { Button, Drawer } from 'antd';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination, PushAddTable, SendButton } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { product_id_ref } from 'lib/dataRef';
import PushDetailContainer from 'containers/Contents/Push/PushDetailContainer';
import { maskName } from 'lib/maskPersonInfo';

const PushListTableContainer = () => {
  const [push, setPush] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }});
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});   // 검색어
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [status, setStatus] = useState({
    visible: false,
    document_id: ''
  });
  const onClose = () => {
    setStatus({
      visible: false,
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id
    });
  };
  function goSearch() {
    getPushList(setPush, push, 'first', setCurrentPage, searchState, setLoading);
  }
  useEffect(() => {
    getPushList(setPush, push, 'first', setCurrentPage, searchState, setLoading);
  }, []);
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
 
  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    const currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  let list = currentData(push.data);
  
  const setPage = (page) => {
    setCurrentPage(page)
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  }

  const renderMoveData = (move) => {
    getPushList(setPush, push, move, setCurrentPage, searchState, setLoading);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  }
  const onChangeChecked = (e, id) => {
    if(e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      checkedList.splice(checkedList.indexOf(id), 1);
      setCheckedList([...checkedList]);
    }
  };
  const onCheckedAll = (e) => {
    const checkList = list.map((item) => item.document_id);
    if(e.target.checked) {
      setCheckedList(checkList);
    } else {
      setCheckedList([]);
    }
  };
  const columns = [
    {
      title: () => {
        return (
          <>
          <input
            type="checkbox"
            style={{"marginRight": "5px"}}
            checked={checkedList.length == list.length}
            onChange={(e) => onCheckedAll(e)}
          />
          전체
          </>
        )
      },
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <input
            type="checkbox"
            style={{"marginRight": "5px"}}
            checked={checkedList.includes(document_id)}
            onChange={(e) => onChangeChecked(e, document_id)}
          />
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => showDrawer(document_id)}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '앱 명칭',
      dataIndex: 'product_id',
      key: 'product_id',
      width: '18%',
      align: 'center',
      render: (data) => product_id_ref[data] 
    },
    {
      title: '제목',
      dataIndex: 'subject',
      key: 'subject',
      width: '18%',
      align: 'center',
    },
    {
      title: '발송자',
      dataIndex: 'sender',
      key: 'sender',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '수신자',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '총 발송\n횟수',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
    },  
    {
      title: '성공\n건수',
      dataIndex: 'success_count',
      key: 'success_count',
      align: 'center',
    },  
    {
      title: '실패\n건수',
      dataIndex: 'fail_count',
      key: 'fail_count',
      align: 'center',
    },  
    {
      title: '등록일',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt5'),
    },
    {
      title: '최근\n발송일',
      dataIndex: 'send_dt',
      key: 'send_dt',
      align: 'center',
    }
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.contents.push.path}
          />
        )}
        countLabel={(<CounterLabel count={push.count} />)}
        addButton={(<PushAddTable />)}
        sendButton={( 
          <SendButton
            func={sendPush}
            data={checkedList}
            location="/push"
          />
        )}
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['앱 명칭', '이름'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(push.data)}
        columns={columns}
        loading={loading}
        pagination={(
          <Pagination
            totalData={push.data}
            pagingState={push.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        )}
        DetailTableContainer={(
        <Drawer
          width={750}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <PushDetailContainer
            document_id={status.document_id} />
        </Drawer>
      )}
      />
    </>
  );
};

export default PushListTableContainer;
