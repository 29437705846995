import React, { useEffect, useState } from 'react';
import {
  CounterLabel,
  LinkButton,
  ListTableTemplate,
  ExcelModal,
} from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Button, Drawer, Spin } from 'antd';
import { routes_ref } from 'lib/routeRef';
import { maskName } from 'lib/maskPersonInfo';
import { getChatDoctorList, makeXlsx } from 'controllers/chatbot/chatbotDoctor';
import ChatDoctorDetailTableContainer from './ChatDoctorDetailTableContainer';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import hideIcon from 'assets/icons/hide.png';

const ChatDoctorTableContainer = () => {
  const [chatDoctor, setDoctor] = useState([{}]);
  const [status, setStatus] = useState({
    visible: false,
    m_no: '',
  });
  const [searchState, setSearchState] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유

  const onClose = () => {
    setStatus({
      visible: false,
      m_no: '',
    });
  };

  const showDrawer = (m_no) => {
    setStatus({
      visible: true,
      m_no,
    });
  };
  const excelDownload = () => {
    insertInfoAcessLog(location.pathname, 'makeXlsx', '다운로드', excelReason);
    makeXlsx(excelReason, searchState);
  };
  useEffect(() => {
    getChatDoctorList(setDoctor, searchState, setLoading);
  }, []);
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  function goSearch() {
    getChatDoctorList(setDoctor, searchState, setLoading);
  }
  const columns = [
    {
      title: '',
      dataIndex: 'm_no',
      key: 'm_no',
      width: '5%',
      align: 'center',
      render: (m_no, record) => (
        <>
          <Button
            key={m_no}
            type="primary"
            size="small"
            onClick={() => {
              insertInfoAcessLog(
                location.pathname,
                'chatDoctorDetail',
                '조회',
                undefined,
                record.m_no + '_' + record.h_no
              ),
                showDrawer(m_no);
            }}
          >
            상세
          </Button>
        </>
      ),
    },
    {
      title: '분류',
      dataIndex: 'm_sub_cate',
      key: 'm_sub_cate',
      width: '8%',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'm_doc',
      key: 'm_doc',
      width: '8%',
      align: 'center',
      render: (data, record) => {
        return (
          <>
            {record.hide_yn ? (
              <>
                <img
                  src={hideIcon}
                  style={{
                    height: '20px',
                    width: 'auto',
                  }}
                />{' '}
                {maskName(data)}
              </>
            ) : (
              maskName(data)
            )}
          </>
        );
      },
    },
    {
      title: '병원명',
      dataIndex: 'col_h_name',
      key: 'col_h_name',
      width: '15%',
      align: 'center',
    },
    {
      title: '진료과',
      dataIndex: 'm_dep',
      key: 'm_dep',
      width: '15%',
      align: 'center',
    },
    {
      title: '진료분야',
      dataIndex: 'm_name',
      key: 'm_name',
      width: '40%',
      align: 'center',
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton
            ctx="전체목록"
            link={routes_ref.base.chatbot.doctor.path}
            originColor="#565e8c"
            hoveredColor="#00854a"
          />
        }
        countLabel={<CounterLabel count={chatDoctor.length} />}
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
        }
        addButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              window.location.href = routes_ref.base.chatbot.doctor_add.path;
            }}
          >
            등록
          </Button>
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'chatDoctor',
          field: ['분류', '병원종류', '지역', '숨김', '검색', '정렬'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      {loading ? (
        <Spin tip="Loading" size="large">
          <ListTableTemplate columns={columns} />
        </Spin>
      ) : (
        <ListTableTemplate
          dataSource={chatDoctor}
          columns={columns}
          DetailTableContainer={
            <Drawer
              width={750}
              closable
              onClose={onClose}
              open={status.visible}
            >
              <ChatDoctorDetailTableContainer m_no={status.m_no} />
            </Drawer>
          }
        />
      )}
      <div>
        {modalIsOpen && (
          <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          ></ExcelModal>
        )}
      </div>
    </>
  );
};

export default ChatDoctorTableContainer;
