import React from 'react';
import { Descriptions, Divider, Radio, message, Switch, Space } from 'antd';
import { company_ref, body_age_status_ref, gender_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { CancelButton, SaveButton } from 'components/Common';
import { editDzPredDetail } from 'controllers/HealthLogs/dzPreds';

const { Item } = Descriptions;
const DzPredsDetailTable = (props) => {
  const { dzPredsDetail, setDzPredsDetail } = props;
  // const [loading, setLoading] = useState(true);
  const handleSwitchChange = () => {
    if (dzPredsDetail.del_yn === false) {
      message.warning('저장하실 경우,\n고객님의 해당 검사가 취소처리 됩니다');
    }
    setDzPredsDetail({ ...dzPredsDetail, del_yn: !dzPredsDetail.del_yn });
  };
  return (
    // 컴포넌트(화면)이 많아서 불투명한 화면으로 처리
    //   <div
    //     style={{
    //       display: 'flex',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //       height: '100vh', // 화면의 높이에 맞춰줍니다
    //     }}
    //   >
    //     <Spin />
    //   </div>
    // :
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="연락처">
          {dzPredsDetail.crm_phone || '-'}
        </Item>
        <Item label="이름">
          {dzPredsDetail.crm_nm || '-'}
        </Item>
        <Item label="보험사명">
          {company_ref[dzPredsDetail.company_id] || '-'}
        </Item>
        <Item label="신청인 이름">
          {dzPredsDetail.member_nm || '-'}
        </Item>
        <Item label="신청인 생년월일">{dzPredsDetail.birth_dt || '-'}</Item>
        <Item label="성별">{gender_ref[dzPredsDetail.gender] || '-'}</Item>
        <Item label="나이">
          <strong>{dzPredsDetail.age || '-'}</strong>
          {' 세'}
        </Item>
        <Item label="신청인 건강검진 날짜">{dzPredsDetail.checkup_dt}</Item>
        <Item label="상태">
          <Radio.Group value={dzPredsDetail.status}>
            <Radio value={0}>{body_age_status_ref[0]}</Radio>
            <Radio value={3}>{body_age_status_ref[3]}</Radio>
            <Radio value={4}>{body_age_status_ref[4]}</Radio>
            <Radio value={5}>{body_age_status_ref[5]}</Radio>
          </Radio.Group>
        </Item>
        <Item label="해당 검사 취소처리">
          <Switch
            checked={dzPredsDetail.del_yn}
            onChange={handleSwitchChange}
          />
        </Item>

        <Item label="등록일">
          {getFormattedDate(dzPredsDetail.reg_dt, 'opt2')}
        </Item>
        <Item label="검진에 사용되는 등록넘버(코드)">
          {dzPredsDetail.reg_number || '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions title="결과" bordered column={1} style={{ width: '100%' }}>
        <Item label="대사나이">
          <strong>{dzPredsDetail.metabolic_age || '-'}</strong>
          {' 세'}
        </Item>
        <Item label="등록일">
          {getFormattedDate(dzPredsDetail.reg_dt, 'opt2')}
        </Item>
        <Item label="PDF다운일시">
          {dzPredsDetail.pdf_down_yn
            ? getFormattedDate(dzPredsDetail.pdf_down_dt, 'opt2')
            : '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Space>
        <CancelButton location="/dzPreds" />
        <SaveButton
          func={editDzPredDetail}
          data={dzPredsDetail}
          location="/dzPreds"
        />
      </Space>
    </>
  );
};

export default DzPredsDetailTable;
