import React, { useState, useEffect } from 'react';
import { Descriptions, Divider } from 'antd';
import { ListTableTemplate } from 'components/Common';

const { Item } = Descriptions;
const GrowthRecsDetailTable = ({ growthRecDetail }) => {
  const [growList, setGrowList] = useState([]);
  const columns = [
    {
      title: '측정일',
      dataIndex: 'checkup_dt_str',
      key: 'checkup_dt_str',
      align: 'center',
      render: (data) => data.slice(2),
    },
    {
      title: '신장',
      dataIndex: 'height',
      key: 'height',
      align: 'center',
      render: (data) => (
        <>
          <strong>
            {data}
          </strong>
          {' cm'}
        </>
      ),
    },
    {
      title: '신장 백분율',
      dataIndex: 'height_percentile',
      key: 'height_percentile',
      align: 'center',
      render: (data) => {
        if (!!data === false) {
          data = '-';
        }
        return (
          <>
            <strong>
              {data}
            </strong>
            {' %'}
          </>
        );
      },
    },
    {
      title: '체중',
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      render: (data) => (
        <>
          <strong>
            {data}
          </strong>
          {' kg'}
        </>
      ),
    },
    {
      title: '체중 백분율',
      dataIndex: 'weight_percentile',
      key: 'weight_percentile',
      align: 'center',
      render: (data) => {
        if (!!data === false) {
          data = '-';
        }
        return (
          <>
            <strong>
              {data}
            </strong>
            {' %'}
          </>
        );
      },
    },
    {
      title: '체질량지수',
      dataIndex: 'body_mass',
      key: 'body_mass',
      align: 'center',
      render: (data) => (
        <>
          <strong>
            {data}
          </strong>
          {' kg/m2'}
        </>
      ),
    },
    {
      title: '체질량지수 백분율',
      dataIndex: 'body_mass_percentile',
      key: 'body_mass_percentile',
      align: 'center',
      render: (data) => {
        if (!!data === false) {
          data = '-';
        }
        return (
          <>
            <strong>
              {data}
            </strong>
            {' %'}
          </>
        );
      },
    },
    {
      title: '머리둘레',
      dataIndex: 'head_circum',
      key: 'head_circum',
      align: 'center',
      render: (data) => {
        if (data === -1) {
          data = '-';
        }
        return (
          <>
            <strong>
              {data}
            </strong>
            {' cm'}
          </>
        );
      },
    },
    {
      title: '머리둘레 백분율',
      dataIndex: 'head_circum_percentile',
      key: 'head_circum_percentile',
      align: 'center',
      render: (data) => {
        if (!!data === false) {
          data = '-';
        }
        return (
          <>
            <strong>
              {data}
            </strong>
            {' %'}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    const growArr = [];
    if (growthRecDetail.length) {
      growthRecDetail.map((row) => {
        growArr.push(row);
      });
    }
    setGrowList(growArr);
  }, [growthRecDetail]);
  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="계약자 이름">
          {growthRecDetail[0] ? growthRecDetail[0].crm_nm : '-'}
        </Item>
        <Item label="자녀 이름">
          {growthRecDetail[0] ? growthRecDetail[0].crm_child_nm : '-'}
        </Item>
        <Item label="날짜(개월)">
          {(growthRecDetail[0] ? growthRecDetail[0].crm_child_birth_dt + ' ( ' + growthRecDetail[0].months + ' 개월 )' : '-')}
        </Item>
      </Descriptions>
      <Divider />
      <ListTableTemplate
        dataSource={growList}
        columns={columns}
      />
    </>
  );
};

export default GrowthRecsDetailTable;
