import React from 'react';
import { Row, Input, Descriptions, Divider, Space, Radio, Select } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import { CancelButton, SaveButton, DelButton } from 'components/Common';
import { company_ref, product_id_ref } from 'lib/dataRef';
import { deleteAppVer, editAppVerDetail } from 'controllers/Systems/appVersion';
import getOptionsByRefId from 'lib/getOptionsByRefId';

const { Item } = Descriptions;
const AppVersionDetailTable = (props) => {
  const { appVerDetail, setAppVerDetail } = props;
  const prooductIdOptions = () => (getOptionsByRefId(product_id_ref));
  
  return (
    <>
      <Row>
        <Descriptions
          title="버전 관리 정보"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="보험사">{company_ref[appVerDetail.company_id]}</Item>
          <Item label="상품코드">
            <Select
              style={{ width: '200px' }}
              value={product_id_ref[appVerDetail.product_id]}
              onChange={(e) => setAppVerDetail({ ...appVerDetail, product_id: e })}
              >
                  {prooductIdOptions()}
            </Select>
          </Item>
          <Item label="os 구분">
                <Select
                value={appVerDetail.av_tp}
                style={{ width: '150px' }}
                onChange={(e) => setAppVerDetail({ ...appVerDetail, av_tp: e })}
                options={[
                    {
                        value: 'AOS',
                        label: 'Android'
                    },
                    {
                        value: 'IOS',
                        label: 'IOS'
                    },
                ]}
                />
            </Item>
          <Item label="최신버전">
            <Input
              value={appVerDetail.av_fnl}
              onChange={(e) => setAppVerDetail({ ...appVerDetail, av_fnl: e.target.value })}
            />
          </Item>
          <Item label="업데이트 기준버전">
            <Input
              value={appVerDetail.av_enfr_updt}
              onChange={(e) => setAppVerDetail({ ...appVerDetail, av_enfr_updt: e.target.value })}
            />
          </Item>
          <Item label="점검여부">
            <Radio.Group
                onChange={(e) => setAppVerDetail({ ...appVerDetail, av_dstrb_yn: e.target.value, })}
                value={appVerDetail.av_dstrb_yn}
                >
                <Radio value>Y</Radio>
                <Radio value={false}>N</Radio>
            </Radio.Group>
          </Item>
          <Item label="사유">
            <Input.TextArea
              value={appVerDetail.text}
              rows={8}
              allowClear
              onChange={(e) => setAppVerDetail({ ...appVerDetail, text: e.target.value })}
            />
          </Item>
          <Item label="등록일">{getFormattedDate(appVerDetail.reg_dt, 'opt2')}</Item>
          <Item label="수정시각">{getFormattedDate(appVerDetail.mod_dt, 'opt2')}</Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/app-version"
          />
          <SaveButton
            func={editAppVerDetail}
            data={appVerDetail}
            location="/app-version"
          />
          <DelButton
            func={deleteAppVer}
            data={appVerDetail}
            location="/app-version"
          />
        </Space>
      </Row>
    </>
  );
};

export default AppVersionDetailTable;
