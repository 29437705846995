import React, { useEffect, useState } from 'react';
import { Badge, Popover, Button, Drawer } from 'antd';
import { CounterLabel, LinkButton, ListTableTemplate, ExcelModal } from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { getBbiddakiApiLogList } from 'controllers/Outers/bbiddakiApiLog';
import { getFormattedDate } from 'lib/getFormattedDate';
import { useApiLogSearch } from 'lib/hooks';
import { routes_ref } from 'lib/routeRef';
import { program_type_cd_ref } from 'lib/dataRef';
import BbiddakiApiLogDetailTableContainer from './BbiddakiApiLogDetailTableContainer';
import { maskName } from 'lib/maskPersonInfo';
import noImage from '../../assets/none_image.png';

const BbiddakiApiLogListTableContainer = () => {
  const [apiLog, setApiLog] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
    obj: {}
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유

  const apiLogSearch = useApiLogSearch(setApiLog, setCount);

  const showDrawer = (obj) => {
    setStatus({
      visible: true,
      obj
    });
  };

  const onClose = () => {
    setStatus({
      visible: false,
    document_id: '',
    obj: {}
    });
  };
  useEffect(() => {
    getBbiddakiApiLogList(setApiLog, setCount, setLoading);
  }, []);
  const excelDownload = () =>{
    apiLogSearch.handleExcelDownload(excelReason, searchState)
  }
  const handleErrorImage = (e) => {
    e.target.src = noImage;
    e.target.style = 'width: 50px;'
  };
  const columns = [
    {
      title: '번호',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      render: (no) => `${no || 0}`,
    },
    {
      title: '프로그램명',
      dataIndex: 'program_type_cd',
      key: 'program_type_cd',
      align: 'center',
      render: (data) => program_type_cd_ref[data]
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '전송시간',
      dataIndex: 'request_dt',
      key: 'request_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt5'),
    },
    {
      title: '응답시간',
      dataIndex: 'response_dt',
      key: 'response_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt5'),
    },
    {
      title: '정면사진',
      dataIndex: 'image_url_front',
      key: 'image_url_front',
      align: 'center',
      render: (img, obj) => (
      <Popover content={
        <div>
        <img src={obj.image_url_front} alt={obj.image_url_front} style={{ width: '500px', height: '500px'}} 
        onError={handleErrorImage}
        onContextMenu={(e) => e.preventDefault()} />
        </div>
      }>
        <img src={img} alt={img} style={{ width: '50px', height: '50px'}} 
        onError={handleErrorImage}
        onContextMenu={(e) => e.preventDefault()} />
      </Popover>
      ),
    },
    {
      title: '측면사진',
      dataIndex: 'image_url_side',
      key: 'image_url_side',
      align: 'center',
      render: (img, obj) => (
      <Popover content={
        <div>
        <img src={obj.image_url_side} alt={obj.image_url_side} style={{ width: '500px', height: '500px'}} 
        onError={handleErrorImage}
        onContextMenu={(e) => e.preventDefault()} />
        </div>
      }>
        <img src={img} alt={img} style={{ width: '50px', height: '50px'}} 
        onError={handleErrorImage}
        onContextMenu={(e) => e.preventDefault()} />
      </Popover>
      ),
    },
    {
      title: '메시지',
      dataIndex: 'message',
      key: 'message',
      align: 'center',
      render: (message, obj) => (
      <div style={{ textAlign: 'center' }}>
        {obj.ok === false ? '-' : message}
      </div>
      ),
    },
    {
      title: '성공여부',
      dataIndex: 'ok',
      key: 'ok',
      align: 'center',
      render: (ok) => (
      <div>
        {
        ok
        ?
        <Badge status="success" text="성공"/>
        :
        <Badge status="error" text="실패"/>
        }
      </div>
      ),
    },
    {
      title: '상세',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
      render: (no, obj) => (
          <>
          <Button
            key={no}
            type="primary"
            size="small"
            onClick={() => showDrawer(obj)}
            >
            관리
          </Button>
        </>
      )
    },
  ];

  return (
    <>
    <InfoPane
      boardListButton={(
        <LinkButton
        link={routes_ref.base.outers.bbiddaki_api_log.path}
        />
      )}
      countLabel={(<CounterLabel count={count} />)}
      xlsButton={(
        <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={()=> {
              setModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
      )}
    />
    <ListTableTemplate
      dataSource={apiLog}
      columns={columns}
      loading={loading}
      DetailTableContainer={(
        <Drawer
          width={500}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <BbiddakiApiLogDetailTableContainer
            props={status.obj}
          />
        </Drawer>
      )}
    />
    <div>
        {
          modalIsOpen && (
            <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
          </ExcelModal>
        )}
      </div>
  </>
  );
};

export default BbiddakiApiLogListTableContainer;
