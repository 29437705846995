import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import classNames from 'classnames/bind';
import { deleteCookie, setCookie, getCookie } from 'lib/cookie';
import { Link } from 'react-router-dom';
import styles from './HeaderLogout.scss';
import { logout, updateLoginInfo } from 'controllers/login';

const cx = classNames.bind(styles);

const HeaderLogout = (props) => {
  const LOGOUT = 'LOGOUT';
  const [count, setCount] = useState(60 * 1000 * 60); // 60 * 1000 = 1 minute
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const step = 1000;
  const MINUTE = 60;
  const countTransToTime = (count, step) => {
    const minutes = Math.floor((count / step / 60) % 60);
    const seconds = Math.floor((count / step) % 60);
    if (count > 0 && getCookie('session')) {
      setCookie('session', getCookie('session'),
        { 'max-age': 60 * MINUTE });
      return minutes + '분' + seconds + '초';
    } else if (count < 0) {
      logout();
      alert('장시간 활동이 없어 \n로그인이 해제되었습니다 \n 다시 로그인 바랍니다');
    } else if (!getCookie('session')) {
      deleteCookie('session');
      document.location.href = '/login';
    }
  };
  useEffect(() => {
    if (getCookie('session')) {
      setCookie('session', getCookie('session'),
        { 'max-age': 60 * MINUTE });
        // 세션쿠키 시간 저장
        updateLoginInfo();
    }
    const id = setInterval(() => {
      setCount((c) => c - step);
    }, 1000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    // 로그아웃 1분전 알림 설정
    if (count === 60000 && getCookie('session')) {
      setModalIsOpen(true);
    }
  }, [count]);

  const handleExtendSession = () => {
    if (getCookie('session')) {
      // 세션 연장
      setCookie('session', getCookie('session'), { 'max-age': 60 * MINUTE });
      setCount(60 * 1000 * 60);
    }
    setModalIsOpen(false);
  };

  return (
      <>
        <div className={cx('headerLogout')}>
        <Link
          to="/main"
          style={{
            color: 'white',
            fontSize: '24px',
            fontWeight: '300',
            letterSpacing: '-1.6px',
            marginLeft: '-32px',
          }}
        >
          ADMINISTRATION
        </Link>
        <div>
          {countTransToTime(count, step)}
          <label>
            <Button type="dashed" onClick={() => props.onLogout()}>{LOGOUT}</Button>
          </label>
        </div>
      </div>
      <div>
      {
        modalIsOpen && (
        <Modal
          open={modalIsOpen}
          okText={"연장하기"}
          onOk={handleExtendSession}
          cancelText={"닫기"}
          onCancel={()=> setModalIsOpen(false)}
          title={"현재 세션은 자동 로그아웃 예정입니다."}
        >
          <p>세션 유지 시간이 만료되기까지 남은 시간: {countTransToTime(count, step)}</p>
        </Modal>
      )}
      </div>
    </>
  );
};

export default HeaderLogout;
