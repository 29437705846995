import React, { useEffect, useState } from 'react';
import { MedicinesDetailTable } from 'components/Common';
import { getMedicineDetail } from 'controllers/HealthLogs/medicines';
import { Spin } from 'antd';

const MedicinesDetailTableContainer = ({ member_id }) => {
  const [loading, setLoading] = useState(true);
  const [medicineDetail, setMedicineDetail] = useState({});
  useEffect(() => {
    if (member_id) {
      setMedicineDetail({});
      setLoading(true);
      const data = getMedicineDetail(member_id);
      data.then((d) => {
        setMedicineDetail(d);
        setLoading(false);
      });
    }
  }, [member_id]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <MedicinesDetailTable medicineDetail={medicineDetail} />
      </Spin>
    :
      <MedicinesDetailTable medicineDetail={medicineDetail} />
  );
};

export default MedicinesDetailTableContainer;
