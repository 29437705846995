import React, { useState } from 'react';
import { Row, Descriptions, Divider, Input, Space, Drawer, Select } from 'antd';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { postFaq } from 'controllers/Contents/faq';
import { product_id_ref } from 'lib/dataRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';

const { Item } = Descriptions;
const FaqAddTable = () => {
  const [faqDetail, setFaqDetail] = useState({
    subject: '',
    content: '',
    product_id: '',
  });
  const [drawerStatus, setDrawerStatus] = useState(false);
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setFaqDetail(faqDetail);
  };
  const handleChangeProductId = (product_id) => {
    setFaqDetail({
      ...faqDetail,
      product_id,
    });
  };
  return (
    <>
    <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
      <Row>
        <Descriptions title="faq 추가" bordered column={1} style={{ width: '100%' }}>
          <Item label="보험사">
            <Select
              style={{ width: '100%' }}
              onChange={handleChangeProductId}
              value={faqDetail.product_id}
            >
              {getOptionsByRefId(product_id_ref)}
            </Select>
          </Item>
          <Item label="제목">
            <Input.TextArea
              value={faqDetail.subject}
              rows={8}
              allowClear
              onChange={(e) => setFaqDetail({ ...faqDetail, subject: e.target.value })}
            />
          </Item>
          <Item label="내용">
            <Input.TextArea
              value={faqDetail.content}
              rows={8}
              allowClear
              onChange={(e) => setFaqDetail({ ...faqDetail, content: e.target.value })}
            />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/faq"
          />
          <SaveButton
            func={postFaq}
            data={faqDetail}
            location="/faq"
          />
        </Space>
      </Row>
      </Drawer>
    </>
  );
};

export default FaqAddTable;
