import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 서류 제출 정보 조회
 * @param {*} setMediCerts client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 */
export async function getMediCertsList(
  setMediCerts,
  mediCerts,
  goPage,
  setCurrentPage,
  searchState,
  setLoading
) {
  try {
    const res = await axios
      .post(`${baseUrl}/medicerts/getList`, {
        goPage: goPage,
        pagingState: mediCerts.pagingState,
        searchState: searchState,
      })
      .then((res) => {
        setMediCerts(res.data);
        setCurrentPage(1);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });

    return res;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 서류 제출의 세부 정보 조회
 * @param {string} document_id 특정 서류 제출의 document id
 */
export async function getMediCertDetail(document_id) {
  try {
    return await axios
      .get(`${baseUrl}/medicerts/getMediDetail/${document_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 서류 제출 신규 추가
 * @param {object} mediCert 새로 추가될 서류 제출의 세부 정보
 */
export async function insertMediCert(mediCert) {
  try {
    // 필수 파람(인자)가 누락된 경우, 실패를 리턴
    if (
      !mediCert.crm_nm ||
      !mediCert.crm_phone ||
      !mediCert.product_id ||
      !mediCert.company_id ||
      !mediCert.img_url ||
      !mediCert.type
    ) {
      return { result: 'fail', message: '필수 입력사항을 ' };
    }

    return await axios
      .post(`${baseUrl}/medicerts/add`, {
        mediCert,
      })
      .then((res) => {
        return { result: 'success', message: 'The mediCert is inserted.' };
      })
      .catch((err) => {
        alert('계약자 정보를 확인해주세요.');
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 서류 제출의 정보 수정
 * @param {object} mediCert 특정 서류 제출의 정보 및 수정할 사항
 */
export async function editMediCertDetail(mediCert) {
  try {
    if (mediCert.del_dt > 0) {
      console.log('fail: The mediCert was deleted.');
      return { result: 'fail', message: 'The mediCert was deleted.' };
    }

    return await axios
      .post(`${baseUrl}/medicerts/update`, {
        mediCert,
      })
      .then((res) => {
        return { result: 'success', message: 'The mediCert is updated.' };
      })
      .catch((err) => {
        let msg;
        switch (err.response.status) {
          case 401:
            if (err.response.data.errorCode == 'TYPE_ERROR') {
              msg = '고객의 서비스 타입을 확인해주세요';
            } else {
              msg = '이미 진행중인 서류 입니다.';
            }
            break;
          case 404:
            msg =
              'CRM 에서 조회되지 않는 회원입니다\n기입 정보를 다시 확인해주세요';
            break;
          case 500:
          default:
            msg = '알 수 없는 CRM 에러입니다\n잠시 후에 다시 시도바랍니다';
            break;
        }
        alert(msg);
        return { result: 'fail', message: '' };
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 서류 제출 삭제
 * @param {object} mediCert 삭제하고자 하는 서류 제출의 정보
 */
export async function deleteMediCert(mediCert) {
  try {
    return await axios
      .get(`${baseUrl}/medicerts/delete/${mediCert.document_id}`)
      .then((res) => {
        return { result: 'success', message: 'The mediCert is deleted.' };
      })
      .catch((err) => {
        return { result: 'fail' };
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import dayjs from 'dayjs';

// /**
//  * 서류 제출 갯수 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getMediCertsSize(app_code) {
//   try {
//     const aplsRef = await firestore.collection('cust_MEDICERTS')
//       .get();
//     // 특정 앱코드가 파람(인자)에 주어지지 않은 경우
//     if (app_code === undefined) { // nothing
//       return aplsRef.size;
//     // 특정 앱코드가 파람(인자)에 주어진 경우
//     } else {
//       let size = 0;
//       aplsRef.docs.forEach((doc) => {
//         if (doc.data().product_id.includes(app_code)) {
//           size++;
//         }
//       });
//       return size;
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 서류 제출 정보 조회
//  * @param {*} setMediCerts client에서 전달하는 React.Dispatch
//  * @param {*} setCount client에서 전달하는 React.Dispatch
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getMediCertsList(setMediCerts, setCount, setLoading, app_code) {
//   try {
//     // app_code and category2 are undefined.
//     // 컬렉션 내에서 변동이 있을 경우(새로운 서류 제출 추가, 기존 서류 제출 변동 등),
//     // 리로드 없이도 변동 사항을 반영하기 위해 onSnapshot 이용
//     // (onSnapshot에 대한 자세한 사항은 firebase documentation을 참고하길 바람)
//     const mcsRef = await firestore.collection('cust_MEDICERTS')
//       .orderBy('reg_dt', 'desc')
//       .onSnapshot((snap) => {
//         const mcDocs = [];
//         const changes = snap.docChanges();

//         changes.forEach((change) => {
//           if (change.type === 'added') {
//             if (changes.length > 1) {
//               const product_id = String(change.doc.data().product_id).split('_');
//               mcDocs.push({
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 type: change.doc.data().type,
//                 crm_nm: change.doc.data().crm_nm,
//                 crm_phone: change.doc.data().crm_phone,
//                 approved_yn: change.doc.data().approved_yn,
//                 status: change.doc.data().status,
//                 admin_check_yn: change.doc.data().admin_check_yn,
//                 admin_check_dt: change.doc.data().admin_check_dt,
//                 reg_dt: change.doc.data().reg_dt,
//                 del_yn: change.doc.data().del_yn,
//                 del_dt: change.doc.data().del_dt,
//               });
//             } else {
//               snap.forEach((doc) => {
//                 const product_id = String(doc.data().product_id).split('_');
//                 mcDocs.push({
//                   document_id: doc.data().document_id,
//                   company_id: doc.data().company_id,
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: doc.data().product_id,
//                   type: doc.data().type,
//                   crm_nm: doc.data().crm_nm,
//                   crm_phone: doc.data().crm_phone,
//                   approved_yn: doc.data().approved_yn,
//                   status: doc.data().status,
//                   admin_check_yn: doc.data().admin_check_yn,
//                   admin_check_dt: doc.data().admin_check_dt,
//                   reg_dt: doc.data().reg_dt,
//                   del_yn: doc.data().del_yn,
//                   del_dt: doc.data().del_dt,
//                 });
//               });
//             }
//           }
//           if (change.type === 'modified') {
//             snap.forEach((doc) => {
//               const product_id = String(doc.data().product_id).split('_');
//               mcDocs.push({
//                 document_id: doc.data().document_id,
//                 company_id: doc.data().company_id,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: doc.data().product_id,
//                 type: doc.data().type,
//                 crm_nm: doc.data().crm_nm,
//                 crm_phone: doc.data().crm_phone,
//                 approved_yn: doc.data().approved_yn,
//                 status: doc.data().status,
//                 admin_check_yn: doc.data().admin_check_yn,
//                 admin_check_dt: doc.data().admin_check_dt,
//                 reg_dt: doc.data().reg_dt,
//                 del_yn: doc.data().del_yn,
//                 del_dt: doc.data().del_dt,
//               });
//             });
//           }
//           if (change.type === 'removed') {
//             snap.forEach((doc) => {
//               const product_id = String(doc.data().product_id).split('_');
//               mcDocs.push({
//                 document_id: doc.data().document_id,
//                 company_id: doc.data().company_id,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: doc.data().product_id,
//                 type: doc.data().type,
//                 crm_nm: doc.data().crm_nm,
//                 crm_phone: doc.data().crm_phone,
//                 approved_yn: doc.data().approved_yn,
//                 status: doc.data().status,
//                 admin_check_yn: doc.data().admin_check_yn,
//                 admin_check_dt: doc.data().admin_check_dt,
//                 reg_dt: doc.data().reg_dt,
//                 del_yn: doc.data().del_yn,
//                 del_dt: doc.data().del_dt,
//               });
//             });
//           }
//         });
//         setMediCerts(mcDocs);
//         setCount(mcDocs.length);
//         setLoading(false);
//       });
//     return mcsRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 서류 제출의 세부 정보 조회
//  * @param {string} document_id 특정 서류 제출의 document id
//  */
// export async function getMediCertDetail(document_id) {
//   try {
//     const mcRef = await firestore.collection('cust_MEDICERTS')
//       .doc(document_id).get();
//     const product_id = String(mcRef.data().product_id).split('_');
//     return {
//       document_id: mcRef.data().document_id,
//       company_id: mcRef.data().company_id,
//       app_code: product_id[0],
//       goods_code: product_id[1],
//       product_id: mcRef.data().product_id,
//       member_doc_id: mcRef.data().member_doc_id,
//       type: mcRef.data().type,
//       crm_nm: mcRef.data().crm_nm,
//       crm_phone: mcRef.data().crm_phone,
//       img_url: mcRef.data().img_url,
//       approved_yn: mcRef.data().approved_yn,
//       premium_start_str_dt: mcRef.data().premium_start_str_dt,
//       premium_end_str_dt: mcRef.data().premium_end_str_dt,
//       status: mcRef.data().status,
//       admin_memo: mcRef.data().admin_memo,
//       admin_check_yn: mcRef.data().admin_check_yn,
//       admin_check_dt: mcRef.data().admin_check_dt,
//       mod_dt: mcRef.data().mod_dt,
//       reg_dt: mcRef.data().reg_dt,
//       del_yn: mcRef.data().del_yn,
//       del_dt: mcRef.data().del_dt,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 고객의 document id 조회
//  * @param {string} crm_nm 특정 고객의 이름
//  * @param {string} crm_phone 특정 고객의 연락처
//  * @param {string} company_id 특정 고객의 보험사 코드
//  * @returns
//  */
// export async function getMemberDetail(crm_nm, crm_phone, company_id) {
//   try {
//     const memRef = await firestore.collection('cust_MEMBERS')
//       .where('nm', '==', crm_nm)
//       .where('phone', '==', crm_phone)
//       .where('company_id', '==', company_id)
//       .where('del_yn', '==', false)
//       .get();

//     if (memRef.size > 0) {
//       return {
//         member_doc_id: memRef.docs[0].id,
//       };
//     } else {
//       return {
//         result: 'error: \n',
//         message: 'There is no member document.',
//       };
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 서류 제출 신규 추가
//  * @param {object} mediCert 새로 추가될 서류 제출의 세부 정보
//  */
// export async function insertMediCert(mediCert) {
// // mediCert = { crm_nm, crm_phone, img_url }
//   try {
//     // 필수 파람(인자)가 누락된 경우, 실패를 리턴
//     if (mediCert.crm_nm === undefined || mediCert.crm_phone === undefined || mediCert.img_url === undefined) {
//       return { result: 'fail', message: 'Param is lack.' };
//     }
//     // 값이 undefined 이거나, 길이가 0인 경우, 키 삭제
//     Object.keys(mediCert).forEach((v) => {
//       if (mediCert[v] === undefined || mediCert[v].length === 0) {
//         delete mediCert[v];
//       }
//     });
//     const now = Math.floor(new Date().getTime());

//     // set default value
//     mediCert.approved_yn = false;
//     mediCert.admin_check_yn = true;
//     mediCert.admin_check_dt = now;
//     mediCert.status = 1;
//     mediCert.reg_dt = now;
//     mediCert.mod_dt = now;
//     mediCert.del_yn = false;
//     mediCert.del_dt = 0;

//     // insert cust_MEDICERTS
//     await firestore.collection('cust_MEDICERTS').add(mediCert)
//       .then(async (doc) => {
//         await firestore.collection('cust_MEDICERTS').doc(doc.id)
//           .set({ document_id: doc.id }, { merge: true });
//       }).catch((err) => {
//         console.error(err);
//       });

//     return { result: 'success', message: 'The mediCert is inserted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 서류 제출의 정보 수정
//  * @param {object} mediCert 특정 서류 제출의 정보 및 수정할 사항
//  */
// export async function editMediCertDetail(mediCert) { // mediCert === getMediCertDetail function's return value
//   try {
//     if (mediCert.del_dt > 0) {
//       console.log('fail: The mediCert was deleted.');
//       return { result: 'fail', message: 'The mediCert was deleted.' };
//     }
//     const mediCertB = await firestore.collection('cust_MEDICERTS')
//       .doc(mediCert.document_id).get();
//     const now = Math.floor(new Date().getTime());

//     // 상태 혹은 타입이 이전과 달라진 경우
//     if (mediCertB.data().status !== mediCert.status || mediCertB.data().type !== mediCert.type) {
//       mediCert.mod_dt = now;
//       // 관리자 확인 여부가 false인 경우
//       if (!mediCertB.data().admin_check_yn) {
//         mediCert.admin_check_dt = now;
//         mediCert.admin_check_yn = true;
//       }
//     }

//     // 변경되어야 할 상태가 '진행중'인 경우
//     if (mediCert.status === 2) {
//       // 타입 정보가 기입되지 않은 경우, 실패를 리턴
//       if (!mediCert.type) {
//         // type must be defined before updating status to 2.
//         console.log('fail: Type does not defined.');

//         return { result: 'fail', message: 'Type does not defined' };
//       // 현재 상태도 '진행중'이면서, 타입이 달라진 경우, 실패를 리턴
//       } else if (mediCertB.data().status === 2 && mediCertB.data().type !== mediCert.type) {
//         // type can not be edited after updating status to 2.
//         console.log('fail: The mediCert was completed.');

//         return { result: 'fail', message: 'The mediCert was completed' };
//       // 현재 상태도 '진행중'이면서, 변경될 상태가 '취소' 혹은 '진행중'이 아닌 경우, 실패를 리턴
//       } else if (mediCertB.data().status === 2 && (mediCert.status !== 9 || mediCert.status !== 2)) {
//         // status can not be edited after updating status to 2.
//         console.log('fail: The mediCert was completed.');

//         return { result: 'fail', message: 'The mediCert was completed' };
//       // 타입 정보가 있지만 빈 값인 경우, 실패를 리턴
//       } else if (mediCert.type.length === 0) {
//         // type must be filled before updating status to 2.
//         console.log('fail: Type is empty.');

//         return { result: 'fail', message: 'Type is empty' };
//       // 상태가 이전과 달라진 경우
//       } else if (mediCertB.data().status !== mediCert.status) {
//         // if status === 2, update cust_MEDICERTS
//         const start_dt = dayjs().format('YYYY-MM-DD');
//         const end_dt = dayjs().add(5, 'year').subtract(1, 'day').format('YYYY-MM-DD');

//         mediCert.approved_yn = true;
//         mediCert.premium_start_str_dt = start_dt;
//         mediCert.premium_end_str_dt = end_dt;
//         mediCert.mod_dt = now;

//         const mediCertTypes = mediCert.type;
//         mediCert.type = mediCert.type[0].toString();
//         if (mediCertTypes.length > 1) {
//           const lists = [];

//           // duplicate document
//           // 타입의 형식이 string이어야만 하므로, document를 타입의 갯수만큼 복제
//           // (타입이 A, B 두 가지라면 그 외의 모든 내용은 같으면서 타입만 다른 두 개의 document가 생성됨)
//           mediCertTypes.forEach(async (v, i) => {
//             if (i > 0) {
//               lists.push({
//                 company_id: mediCert.company_id,
//                 product_id: mediCert.product_id,
//                 member_doc_id: mediCert.member_doc_id,
//                 member_id: mediCert.member_id,
//                 crm_nm: mediCert.crm_nm,
//                 crm_phone: mediCert.crm_phone,
//                 type: v,
//                 img_url: mediCert.img_url,
//                 approved_yn: mediCert.approved_yn,
//                 premium_end_str_dt: mediCert.premium_end_str_dt,
//                 premium_start_str_dt: mediCert.premium_start_str_dt,
//                 status: mediCert.status,
//                 admin_memo: mediCert.admin_memo,
//                 admin_check_yn: mediCert.admin_check_yn,
//                 admin_check_dt: mediCert.admin_check_dt,
//                 reg_dt: mediCert.reg_dt,
//                 mod_dt: mediCert.mod_dt,
//                 del_yn: mediCert.del_yn,
//                 del_dt: mediCert.del_dt,
//               });
//               Object.keys(lists[lists.length - 1]).forEach((key) => {
//                 if (lists[lists.length - 1][key] === undefined) {
//                   delete lists[lists.length - 1][key];
//                 }
//               });
//               await firestore.collection('cust_MEDICERTS').add(lists[lists.length - 1])
//                 .then(async (doc) => {
//                   await firestore.collection('cust_MEDICERTS').doc(doc.id)
//                     .set({ document_id: doc.id }, { merge: true });
//                 }).catch((err) => {
//                   console.error(err);
//                 });
//             }
//           });
//         }
//       }
//     }
//     // 상태가 달라졌으면서, 변경될 상태가 '취소'인 경우
//     if (mediCert.status !== mediCertB.data().status && mediCert.status === 9) {
//       mediCert.del_yn = true;
//       mediCert.del_dt = now;
//       mediCert.mod_dt = now;
//       mediCert.approved_yn = false;
//       mediCert.premium_start_str_dt = '';
//       mediCert.premium_end_str_dt = '';
//       if (!mediCertB.data().admin_check_yn) {
//         mediCert.admin_check_dt = now;
//         mediCert.admin_check_yn = true;
//       }
//     }
//     // checking about admin memo
//     // 관리자 메모 내용이 변경된 경우
//     if (mediCertB.data().admin_memo !== mediCert.admin_memo) {
//       mediCert.mod_dt = now;
//     }

//     // 변경 시각이 현재가 아닌 경우, 실패를 리턴
//     if (mediCert.mod_dt !== now) {
//       console.log('fail: There are no modifications.');
//       return { result: 'fail', message: 'There are no modifications.' };
//     }

//     // checking undefined value and so on...
//     // 키가 app_code 혹은 goods_code 이거나, 값이 undefined 혹은 값의 길이가 0인 경우, 키 삭제
//     Object.keys(mediCert).forEach((v) => {
//       if ((v === 'app_code' || v === 'goods_code')
//         || (mediCert[v] === undefined || mediCert[v].length === 0)) {
//         delete mediCert[v];
//       }
//     });
//     // 관리자 메모에 변화가 없는 경우, 해당 키 삭제
//     if (mediCertB.data().admin_memo === mediCert.admin_memo) {
//       delete mediCert.admin_memo;
//     }
//     await firestore.collection('cust_MEDICERTS').doc(mediCert.document_id)
//       .set(mediCert, { merge: true });

//     return { result: 'success', message: 'The mediCert is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 서류 제출 삭제
//  * @param {object} mediCert 삭제하고자 하는 서류 제출의 정보
//  */
// export async function deleteMediCert(mediCert) { // mediCert === getMediCertDetail function's return value
//   try {
//     await firestore.collection('cust_MEDICERTS').doc(mediCert.document_id)
//       .delete();

//     return { result: 'success', message: 'The mediCert is deleted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }
