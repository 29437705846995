import React, { useState } from 'react';
import { Button, Popconfirm } from 'antd';

const AlertButton = ({ func, data, location }) => {
  const [popState, setPopState] = useState({
    visible: false,
  });
  const handleVisibleChange = (visible) => {
    if (!visible) {
      setPopState({ visible });
      return;
    }
    setPopState({ visible }); // show the popconfirm
  };

  return (
    <>
      <Popconfirm
        title="필수항목을 체크 바랍니다"
        oepn={popState.visible}
        onOpenChange={handleVisibleChange}
      >
        <Button
          block
          disabled
        >
          저장하기
        </Button>
      </Popconfirm>
    </>
  );
};

export default AlertButton;
