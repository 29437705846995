import React from 'react';
import { Row, Descriptions, Input, Divider, Space } from 'antd';
import { CancelButton, SaveButton, DelButton } from 'components/Common';
import { editNoticeDetail, deleteNotice } from 'controllers/Contents/notice';
import { getFormattedDate } from 'lib/getFormattedDate';
import { company_ref, product_id_ref } from 'lib/dataRef';

const { Item } = Descriptions;
const NoticeDetailTable = (props) => {
  const { noticeDetail, setNoticeDetail } = props;
  return (
    <>
      <Row>
        <Descriptions
          title="세부 사항"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
            <Item label="보험사">
            {company_ref[noticeDetail.company_id] || '-'}
          </Item>
            <Item label="앱 명칭">
             {product_id_ref[noticeDetail.product_id] || '-'}
            </Item>
          <Item label="제목">
            <Input
              value={noticeDetail.title}
              onChange={(e) => setNoticeDetail({ ...noticeDetail, title: e.target.value })}
            />
          </Item>
          <Item label="내용">
            <Input.TextArea
              rows={10}
              allowClear
              value={noticeDetail.contents}
              onChange={(e) => {
                setNoticeDetail({ ...noticeDetail, contents: e.target.value });
              }}
            />
          </Item>
          <Item label="등록일">{getFormattedDate(noticeDetail.reg_dt)}</Item>
          <Item label="수정시각">{getFormattedDate(noticeDetail.mod_dt, 'opt2')}</Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/notice"
          />
          <SaveButton
            func={editNoticeDetail}
            data={noticeDetail}
            location="/notice"
          />
          <DelButton
            func={deleteNotice}
            data={noticeDetail}
            location="/notice"
          />
        </Space>
      </Row>
    </>
  );
};

export default NoticeDetailTable;
