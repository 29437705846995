import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LinkButton = ({ ctx, link, originColor, hoveredColor }) => {
  const [backgroundColor, setBackgroundColor] = useState('#565e8c');
  const handleMouseOver = () => {
    setBackgroundColor('#00854a');
  };
  const handleMouseLeave = () => {
    setBackgroundColor('#565e8c');
  };
  const style = {
    display: 'inline-block',
    marginRight: '10px',
    padding: '0 5px',
    borderRadius: '5px',
    background: backgroundColor,
    height: '30px',
    width: 'auto',
  };
  const textStyle = {
    verticalAlign: 'center',
    color: '#ffffff',
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: '11.5px',
    margin: '0 4px',
  };
  return (
    <button
      style={style}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <a href={link} style={textStyle}>
        전체목록
      </a>
    </button>
  );
};

LinkButton.propTypes = {
  /** 버튼에 쓰여질 **Text** */
  ctx: PropTypes.string,
  /** 버튼이 눌렸을 때, **이동할 주소** */
  link: PropTypes.string,
  /** 기본 색상 */
  originColor: PropTypes.string,
  /** Hover 이벤트 시 바뀔 색상 ex)`#FF6738` */
  hoveredColor: PropTypes.string,
};

export default LinkButton;
