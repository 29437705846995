import React, { useEffect, useState } from 'react';
import { getMembersList, makeXlsx } from 'controllers/Services/members';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination, ExcelModal } from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Button, Drawer } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import MemberDetailTableContainer from './MemberDetailTableContainer';
import { routes_ref } from 'lib/routeRef';
import { company_ref, applyType_ref } from 'lib/dataRef';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { maskName, maskPhone } from 'lib/maskPersonInfo';

const MemberListTableContainer = () => {
  const [members, setMembers] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }
  });
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});   // 검색어
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const excelDownload = () =>{
    insertInfoAcessLog(location.pathname, 'makeXlsx', "다운로드", excelReason);
    makeXlsx(excelReason, searchState);
  }
  useEffect(() => {
    getMembersList(setMembers, members, 'first', setCurrentPage, searchState, setLoading);
  }, []);
  // 검색어 변화할때마다 호출

  function goSearch() {
    getMembersList(setMembers, members, 'first', setCurrentPage, searchState, setLoading);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => {insertInfoAcessLog(location.pathname, 'memberDetail', "조회", undefined, document_id), showDrawer(document_id)}}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data]
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '생년월일',
      dataIndex: 'birth_dt',
      key: 'birth_dt',
      align: 'center',
      render: (data) => data,
    },
    {
      title: '회원구분',
      dataIndex: 'admin_msort',
      key: 'admin_msort',
      align: 'center',
      render: (data) => applyType_ref[data]
    },
    {
      title: '가입일',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '최종접속일',
      dataIndex: 'last_logged_dt',
      key: 'last_logged_dt',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  const setPage = (page) => {
    setCurrentPage(page)
  }

  const renderMoveData = (move) => {
    getMembersList(setMembers, members, move, setCurrentPage, searchState, setLoading);
  }

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.members.member.path}
          />
        )}
        countLabel={(<CounterLabel count={members.count} />)}
        xlsButton={(
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
        )}
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['보험사', '이름', '연락처'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(members.data)}
        columns={columns}
        loading={loading}
        pagination={(
          <Pagination
            totalData={members.data}
            pagingState={members.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        )}
        DetailTableContainer={(
          <Drawer
            width={750}
            closable
            onClose={onClose}
            open={status.visible}
          >
            <MemberDetailTableContainer
              document_id={status.document_id} />
          </Drawer>
        )}
      />
      <div>
        {
          modalIsOpen && (
            <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
          </ExcelModal>
        )}
      </div>
    </>
  );
};

export default MemberListTableContainer;
