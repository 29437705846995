import React from 'react';
import { getFormattedDate } from 'lib/getFormattedDate';
import {
  app_code_ref,
  applyType_ref,
  category_ref,
  company_ref,
  status_ref,
} from 'lib/dataRef';
import { deleteApply, editApplyDetail } from 'controllers/Services/applies';
import { Descriptions, Divider, Input, Radio, Row, Space, Steps } from 'antd';
import { CancelButton, SaveButton, DelButton } from 'components/Common';
import noImage from '../../../../../assets/none_image.png';

const { Item } = Descriptions;
const ApplyDetailTable = (props) => {
  const { applyDetail, setApplyDetail } = props;

  const handleStatusChange = (current) => {
    setApplyDetail({ ...applyDetail, status: current });
  };
  const handleErrorImage = (e) => {
    e.target.src = noImage;
    e.target.style = 'width: 50px;';
  };
  const handleStatusCancelChange = (current) => {
    let statusNum;
    // eslint-disable-next-line default-case
    switch (current) {
      case 9:
        statusNum = 9;
        break;
      case 10:
        statusNum = 99;
        break;
    }
    setApplyDetail({ ...applyDetail, status: statusNum });
  };
console.log(applyDetail)
  const mappedImgUrl = applyDetail.img_url
    ? applyDetail.img_url.map((url) => (
        <Row key={url}>
          <img
            style={{ height: '250px', width: 'auto' }}
            key={url}
            src={url}
            onError={handleErrorImage}
            onClick={() => window.open(url)}
            alt="Img Url"
          />
          <Divider />
        </Row>
      ))
    : '-';

  const imgUrlMap = applyDetail.img_url_map
    ? Object.keys(applyDetail.img_url_map).map((v) => (
        <Item lable={v}>
          <b>{v}</b>
          {applyDetail.img_url_map[v].map((url) => (
            <Row key={url}>
              <img
                style={{ height: '250px', width: 'auto' }}
                key={url}
                src={url}
                onError={handleErrorImage}
                onClick={() => window.open(url)}
                alt="Img Url"
              />
              <Divider />
            </Row>
          ))}
        </Item>
      ))
    : '-';
    const selectedList = () => {
      if(applyDetail.selected) {
        return applyDetail.selected.map((item) => (
          <Item>{item}<br /></Item>
        ));
      }
    }
  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="계약자 이름">{applyDetail.crm_nm || '-'}</Item>
        <Item label="계약자 연락처">{applyDetail.crm_phone || '-'}</Item>
        <Item label="보험사명">
          {company_ref[applyDetail.company_id] || '-'}
        </Item>
        {applyDetail.crm_child_nm ? (
          <Item label="피보험자 이름">{applyDetail.crm_child_nm || '-'}</Item>
        ) : null}
        <Item label="신청 대상자">{applyDetail.member_nm || '-'}</Item>
        <Item label="신청 대상자 연락처">{applyDetail.member_ph || '-'}</Item>
        <Item label="신청 대상자 주소">{applyDetail.member_addr || '-'}</Item>
        {applyDetail.category2 === 'hair' || applyDetail.category2 === 'DTC'? (
          <Item label="신청 대상자 이메일">
            {applyDetail.member_email || '-'}
          </Item>
        ) : null}
      </Descriptions>
      <Divider />

      <Descriptions
        title="서비스 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="앱 명칭">{app_code_ref[applyDetail.app_code] || '-'}</Item>
        <Item label="대분류">
          {category_ref(applyDetail.company_id).ref1[applyDetail.category1] ||
            '-'}
        </Item>
        <Item label="중분류">
          {category_ref(applyDetail.company_id).ref2[applyDetail.category2] ||
            '-'}
        </Item>
        {applyDetail.apply_type ? (
          <Item label="신청 정보">
            {applyType_ref[applyDetail.apply_type] || '-'}
          </Item>
        ) : null}
        {applyDetail.apply_type === 'NA' ||
        (applyDetail.bank_member_nm &&
          applyDetail.bank_nm &&
          applyDetail.bank_account) ? (
          <>
            <Item label="예금주 명">{applyDetail.bank_member_nm}</Item>
            <Item label="은행명">{applyDetail.bank_nm}</Item>
            <Item label="계좌번호">{applyDetail.bank_account}</Item>
          </>
        ) : null}
        {
          // 진료/검진 예약 분류를 위해 추가 (230705)
          applyDetail.service_type ? (
            <Item label="소분류">{applyDetail.service_type || '-'}</Item>
          ) : null
        }
        {applyDetail.company_id == 'L71' || applyDetail.company_id == 'L04' ? (
          <Item label="신청상담">{selectedList()}</Item>
        ) : (
          <Item label="기관명">{applyDetail.service_nm || '-'}</Item>
        )}
        {applyDetail.category2 === 'saemi' ? (
          <Item label="AI 챗봇 인지개선 프로그램(새미) 코드">
            <Input
              value={applyDetail.saemi_code}
              onChange={(e) =>
                setApplyDetail({
                  ...applyDetail,
                  saemi_code: e.target.value,
                })
              }
            />
          </Item>
        ) : null}
        {applyDetail.category2 === "nursingCareIndemnity" ? (
          // 삼성생명 시니어케어(요양) 요양실손케어 상품만 존재
          <Item label="서비스 구분">
            <Radio.Group
              value={applyDetail.service_id || undefined}
              onChange={(select) => setApplyDetail({ ...applyDetail, service_id: select.target.value })}
            >
              <Radio value={"dayNightCare"}>주야간 보호</Radio>
              <Radio value={"homeNursing"}>방문요양</Radio>
              <Radio value={"homeBathing"}>방문목욕</Radio>
            </Radio.Group>
          </Item>
        ) : null}
        <Item label="관리자 메모">
          <Input.TextArea
            value={applyDetail.admin_memo}
            rows={10}
            allowClear
            onChange={(e) =>
              setApplyDetail({
                ...applyDetail,
                admin_memo: e.currentTarget.value,
              })
            }
          />
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions
        title="신청 정보"
        bordered
        column={2}
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Item label="서비스 진행 상태" span={2}>
          <Steps
            type="navigation"
            size="default"
            current={applyDetail.status}
            labelPlacement="vertical"
            onChange={(current) => handleStatusChange(current)}
            className="site-navigation-steps"
          >
            <Steps
              title={status_ref[0]}
              description={getFormattedDate(applyDetail.reg_dt, 'opt1')}
            />
            <Steps
              title={status_ref[1]}
              description={getFormattedDate(applyDetail.admin_check_dt, 'opt1')}
            />
            <Steps title={status_ref[2]} subTitle="" description="" />
          </Steps>
          <Steps
            type="navigation"
            size="small"
            current={
              applyDetail.status >= 9
                ? applyDetail.status === 99
                  ? 10
                  : 9
                : undefined
            }
            labelPlacement="vertical"
            initial={9}
            onChange={(current) => handleStatusCancelChange(current)}
            className="site-navigation-steps"
          >
            <Steps
              title={status_ref[9]}
              status="error"
              subTitle=""
              description="사용자의 신청을 취소합니다"
            />
            <Steps
              title={status_ref[99]}
              status="finish"
              subTitle=""
              description="취소 후 일반상담으로 전환합니다"
            />
          </Steps>
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions bordered column={1} style={{ width: '100%' }}>
        <Item label="서비스 지역">{applyDetail.service_area || '-'}</Item>
        {category_ref(applyDetail.company_id).ref2[applyDetail.category2] ==
        '심리상담우대' ? (
          <Item label="심리상담 희망일">
            {applyDetail.service_dt ? applyDetail.service_dt.slice(0, 10) : '-'}
          </Item>
        ) : (
          <Item label="상담 희망일">
            {applyDetail.service_dt ? applyDetail.service_dt.slice(0, 10) : '-'}
          </Item>
        )}
        <Item label="상담희망시간">{applyDetail.service_time || '-'}</Item>
        <Item label="서비스 선택">{applyDetail.service_type || '-'}</Item>
        <Item label="사용자 전달사항">{applyDetail.service_memo || '-'}</Item>
      </Descriptions>
      <Divider />
      <Descriptions bordered layout="vertical">
        <Item label="첨부 이미지들">
          {applyDetail.status == 2 || applyDetail.status == 9
            ? '취소/완료된 건으로 삭제된 이미지 입니다.'
            : applyDetail.apply_type === 'A' || applyDetail.apply_type === 'NA'
            ? imgUrlMap
            : mappedImgUrl}
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions
        title="관리자 관련"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="관리자 확인 여부">
          {applyDetail.admin_check_yn
            ? applyDetail.status === 1
              ? '관리자 확인중'
              : '확인완료'
            : '-'}
        </Item>
        <Item label="관리자 확인 시각">
          {getFormattedDate(applyDetail.admin_check_dt, 'opt2') || '-'}
        </Item>
        <Item label="등록시각">
          {getFormattedDate(applyDetail.reg_dt, 'opt2') || '-'}
        </Item>
        <Item label="삭제여부">{applyDetail.del_yn || '-'}</Item>
      </Descriptions>
      <Divider />

      <Row>
        <Space>
          <CancelButton location="/apply" />
          <SaveButton
            func={editApplyDetail}
            data={applyDetail}
            location="/apply"
          />
          <DelButton func={deleteApply} data={applyDetail} location="/apply" />
        </Space>
      </Row>
    </>
  );
};

export default ApplyDetailTable;
