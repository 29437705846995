import React from 'react';
import { Modal, Row } from 'antd';

const ImgModal = (props) => {
  // const artCounsel = props.artCounsel;
  return (
    <Modal
      open={props.open}
      footer={null}
      onCancel={() => props.modalIsOpen(false)}
      closable={true}
      width={800}
      // width={artCounsel ? 700 : 1500}
    >
      <Row key={props.imgUrl}>
        <img
          style={{
            width: '700px',
            // width: artCounsel ? '600px' : '1400px',
            margin: 'auto',
            display: 'block',
          }}
          key={props.imgUrl}
          src={props.imgUrl}
          onContextMenu={(e) => e.preventDefault()}
          alt="Img Url"
        />
      </Row>
    </Modal>
  );
};

export default ImgModal;
