import React from 'react';
import { Descriptions, Divider, Radio, message, Switch, Space } from 'antd';
import { company_ref, body_age_status_ref, gender_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { CancelButton, SaveButton } from 'components/Common';
import { editBodyAgeDetail } from 'controllers/HealthLogs/bodyAges';

const { Item } = Descriptions;
const BodyAgeDetailTable = (props) => {
  const { bodyAgeDetail, setBodyAgeDetail } = props;
  const handleSwitchChange = () => {
    if (bodyAgeDetail.del_yn === false) {
      message.warning('저장하실 경우,\n고객님의 해당 검사가 취소처리 됩니다');
    }
    setBodyAgeDetail({ ...bodyAgeDetail, del_yn: !bodyAgeDetail.del_yn });
  };

  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="연락처">
          {bodyAgeDetail.crm_phone || '-'}
        </Item>
        <Item label="이름">
          {bodyAgeDetail.crm_nm || '-'}
        </Item>
        <Item label="보험사명">
          {company_ref[bodyAgeDetail.company_id] || '-'}
        </Item>
        <Item label="신청인 이름">
          {bodyAgeDetail.member_nm || '-'}
        </Item>
        <Item label="신청인 생년월일">
          {bodyAgeDetail.birth_dt || '-'}
        </Item>
        <Item label="성별">
          {gender_ref[bodyAgeDetail.gender] || '-'}
        </Item>
        <Item label="나이">
          <strong>
            {(bodyAgeDetail.age || '-') }
          </strong>
          {' 세'}
        </Item>
        <Item label="신청인 건강검진 날짜">
          {bodyAgeDetail.checkup_dt}
        </Item>
        <Item label="상태">
          <Radio.Group
            value={bodyAgeDetail.status}
          >
            <Radio value={0}>{body_age_status_ref[0]}</Radio>
            <Radio value={3}>{body_age_status_ref[3]}</Radio>
            <Radio value={4}>{body_age_status_ref[4]}</Radio>
            <Radio value={5}>{body_age_status_ref[5]}</Radio>
          </Radio.Group>
        </Item>
        <Item label="해당 검사 취소처리">
          <Switch checked={bodyAgeDetail.del_yn} onChange={handleSwitchChange} />
        </Item>

        <Item label="등록일">
          {getFormattedDate(bodyAgeDetail.reg_dt, 'opt2')}
        </Item>
        <Item label="검진에 사용되는 등록넘버(코드)">
          {bodyAgeDetail.reg_number || '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="결과"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="검진으로 계산된 신체 나이">
          <strong>
            {(bodyAgeDetail.bio_age || '-') }
          </strong>
          {' 세'}
        </Item>
        <Item label="등록일">
          {getFormattedDate(bodyAgeDetail.reg_dt, 'opt2')}
        </Item>
        <Item label="PDF다운일시">
          {bodyAgeDetail.pdf_down_yn ? getFormattedDate(bodyAgeDetail.pdf_down_dt, 'opt2') : '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="필수 입력 검진항목"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="신장 HT">
          <strong>
            {(bodyAgeDetail.ht || '-') }
          </strong>
          {' cm'}
        </Item>
        <Item label="체중 BW">
          <strong>
            {(bodyAgeDetail.bw || '-') }
          </strong>
          {' kg'}
        </Item>
        <Item label="허리둘레 WC">
          <strong>
            {(bodyAgeDetail.wc || '-') }
          </strong>
          {' cm'}
        </Item>
        <Item label="수축기혈압 SBP">
          <strong>
            {(bodyAgeDetail.sbp || '-') }
          </strong>
          {' mmHg'}
        </Item>
        <Item label="이완기혈압 DBP">
          <strong>
            {(bodyAgeDetail.dbp || '-') }
          </strong>
          {' mmHg'}
        </Item>
        <Item label="공복혈당 FBS">
          <strong>
            {(bodyAgeDetail.fbs || '-') }
          </strong>
          {' mg/dL'}
        </Item>
        <Item label="크레아티닌 cr">
          <strong>
            {(bodyAgeDetail.cr || '-') }
          </strong>
          {' mg/dL'}
        </Item>
        <Item label="지오티 ast">
          <strong>
            {(bodyAgeDetail.ast || '-') }
          </strong>
          {' IU/L'}
        </Item>
        <Item label="지피티 alt">
          <strong>
            {(bodyAgeDetail.alt || '-') }
          </strong>
          {' IU/L'}
        </Item>
        <Item label="중성지방 tg">
          <strong>
            {(bodyAgeDetail.tg || '-') }
          </strong>
          {' mg'}
        </Item>
        <Item label="혈중요소질소 bun">
          <strong>
            {(bodyAgeDetail.bun || '-') }
          </strong>
          {' mg'}
        </Item>
        <Item label="체지방량 BF">
          <strong>
            {(bodyAgeDetail.bf || '-') }
          </strong>
          {' kg'}
        </Item>
        <Item label="강제폐활량 FVC">
          <strong>
            {(bodyAgeDetail.fvc || '-') }
          </strong>
          {' ml'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="선택 입력 검진항목"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="강제일초량 FEV1">
          <strong>
            {(bodyAgeDetail.fev1 || '-') }
          </strong>
          {' ml'}
        </Item>
        <Item label="근육량 lbm">
          <strong>
            {(bodyAgeDetail.lbm || '-') }
          </strong>
          {' kg'}
        </Item>
        <Item label="젖산탈수소효소 ldh">
          <strong>
            {(bodyAgeDetail.ldh || '-') }
          </strong>
          {' U/L'}
        </Item>
        <Item label="씨피케이 cpk">
          <strong>
            {(bodyAgeDetail.cpk || '-') }
          </strong>
          {' U/L'}
        </Item>
        <Item label="총콜레스테롤 tc">
          <strong>
            {(bodyAgeDetail.tc || '-') }
          </strong>
          {' mg/dL'}
        </Item>
        <Item label="고밀도콜레스테롤 hdl">
          <strong>
            {(bodyAgeDetail.tc || '-') }
          </strong>
          {' mg/dL'}
        </Item>
        <Item label="저밀도콜레스테롤 ldl">
          <strong>
            {(bodyAgeDetail.tc || '-') }
          </strong>
          {' mg/dL'}
        </Item>
        <Item label="총단백질 tp">
          <strong>
            {(bodyAgeDetail.tp || '-') }
          </strong>
          {' g/dL'}
        </Item>
        <Item label="알부민 alb">
          <strong>
            {(bodyAgeDetail.alb || '-') }
          </strong>
          {' g/dL'}
        </Item>
        <Item label="글로불린 glo">
          <strong>
            {(bodyAgeDetail.glo || '-') }
          </strong>
          {' g/dL'}
        </Item>
        <Item label="에이엘피 alp">
          <strong>
            {(bodyAgeDetail.alp || '-') }
          </strong>
          {' U/L'}
        </Item>
        <Item label="감마지티피 ggtp">
          <strong>
            {(bodyAgeDetail.ggtp || '-') }
          </strong>
          {' U/L'}
        </Item>
        <Item label="총빌리루빈 tb">
          <strong>
            {(bodyAgeDetail.tb || '-') }
          </strong>
          {' mg'}
        </Item>
        <Item label="직접빌리루빈 db">
          <strong>
            {(bodyAgeDetail.db || '-') }
          </strong>
          {' mg'}
        </Item>
        <Item label="당화혈색소 hba1c">
          <strong>
            {(bodyAgeDetail.hba1c || '-') }
          </strong>
          {' %'}
        </Item>
        <Item label="아밀라제 amylase">
          <strong>
            {(bodyAgeDetail.amylase || '-') }
          </strong>
          {' U/L'}
        </Item>
        <Item label="크레아티닌제거율 ccr">
          <strong>
            {(bodyAgeDetail.ccr || '-') }
          </strong>
          {' ml/min'}
        </Item>
        <Item label="요질량 usg">
          <strong>
            {(bodyAgeDetail.usg || '-') }
          </strong>
          {' '}
        </Item>
        <Item label="요산도 uph">
          <strong>
            {(bodyAgeDetail.uph || '-') }
          </strong>
          {' pH'}
        </Item>
      </Descriptions>
      <Divider />
      <Space>
        <CancelButton
          location="/body-ages"
        />
        <SaveButton
          func={editBodyAgeDetail}
          data={bodyAgeDetail}
          location="/body-ages"
        />
      </Space>
    </>
  );
};

export default BodyAgeDetailTable;
