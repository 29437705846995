import React from 'react';
import { Button } from 'antd';

const CancelButton = ({ location }) => (
  <Button
    block
    onClick={() => {
      if (confirm('저장하지 않은 수정사항은 유실됩니다')) {
        document.location.href = location;
      }
    }}
  >
    취소
  </Button>
);

export default CancelButton;
