import React from 'react';
import { Row, Descriptions, Input, Divider, Space, Switch, DatePicker } from 'antd';
import { CancelButton, SaveButton } from 'components/Common';
import { editEventInfoDetail } from 'controllers/Contents/eventInfos';
import { getFormattedDate } from 'lib/getFormattedDate';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';

const { Item } = Descriptions;
const EventInfosDetailTable = (props) => {
  const { eventInfoDetail, setEventInfoDetail } = props;
  
  const handleSwitchChange = () => {
    setEventInfoDetail({ ...eventInfoDetail, del_yn: !eventInfoDetail.del_yn });
  };
  return (
    <>
      <Row>
        <Descriptions
          title="세부 사항"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="제목">
            <Input
              value={eventInfoDetail.title}
              onChange={(e) => setEventInfoDetail({ ...eventInfoDetail, title: e.target.value })}
            />
          </Item>
          <Item label="컨텐츠">
            <TextArea
              rows={10}
              allowClear
              value={eventInfoDetail.contents && true ? eventInfoDetail.contents.replace(/\\n/gi, '\r\n') : '-'}
              onChange={(e) => {
                let { value } = e.target;
                if (value === '\r\n') {
                  value = '\n';
                }
                setEventInfoDetail({ ...eventInfoDetail, contents: value });
              }}
            />
          </Item>
          <Item label="장소">
            <Input
              value={eventInfoDetail.place}
              onChange={(e) => setEventInfoDetail({ ...eventInfoDetail, place: e.target.value })}
            />
          </Item>
          <Item label="URL">
            <Input
              value={eventInfoDetail.url}
              onChange={(e) => setEventInfoDetail({ ...eventInfoDetail, url: e.target.value })}
            />
          </Item>
          <Item label="행사기간">
            <DatePicker
              onChange={(date) => setEventInfoDetail({ ...eventInfoDetail, event_start_dt: date.format('YYYY.MM.DD') })}
              value={eventInfoDetail.event_start_dt !== '' && eventInfoDetail.event_start_dt !== undefined
                      ? dayjs(eventInfoDetail.event_start_dt)
                      : null} />
            {' ~ '}
            <DatePicker
              onChange={(date) => setEventInfoDetail({ ...eventInfoDetail, event_end_dt: date.format('YYYY.MM.DD') })}
              value={eventInfoDetail.event_end_dt !== '' && eventInfoDetail.event_end_dt !== undefined
                      ? dayjs(eventInfoDetail.event_end_dt)
                      : null} />
          </Item>
          <Item label="등록일">{getFormattedDate(eventInfoDetail.reg_dt)}</Item>
          <Item label="수정시각">{getFormattedDate(eventInfoDetail.mod_dt, 'opt2')}</Item>
          <Item label="공개여부">
            <Switch checked={!eventInfoDetail.del_yn} onChange={handleSwitchChange} />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/post-eventInfos"
          />
          <SaveButton
            func={editEventInfoDetail}
            data={eventInfoDetail}
            location="/post-eventInfos"
          />
        </Space>
      </Row>
    </>
  );
};

export default EventInfosDetailTable;
