import { useEffect } from 'react';
import { checkAuth } from '../../controllers/login';

export default function useCheckAuth(permission) {
  const HOME = '/main';
  useEffect(() => {
    checkAuth(permission).then((hasAuth) => {
      if (!hasAuth.auth) {
        alert('권한이 없는 페이지입니다');
        document.location.href = HOME;
      }
    });
  }, []);
}
