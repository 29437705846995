import React, { useEffect, useState } from 'react';
import {
  Button,
  Descriptions,
  Divider,
  DatePicker,
  Input,
  Space,
  Checkbox,
  message,
  Badge,
  ConfigProvider,
} from 'antd';
import {
  getChatCenterDetail,
  editChatCenterDetail,
  addChatCenter,
  deleteChatCenter,
} from 'controllers/chatbot/chatbotCenter';
import { SaveButton, DelButton } from 'components/Common';
import Text from 'antd/es/typography/Text';
import dayjs from 'dayjs';
import { daumPostcode } from '../../../Map/GeoCode';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const { Item } = Descriptions;
const ChatCenterAddContainer = ({ props }) => {
  const { state } = props;
  const [chatCenter, setCenter] = useState({});
  const [add, setAdd] = useState(true);
  useEffect(() => {
    if (state) {
      const data = getChatCenterDetail(state.i_no);
      data.then((d) => {
        insertInfoAcessLog(
          location.pathname,
          'chatCenterDetail',
          '조회',
          undefined,
          state.i_no + "_" + state.i_name
        );
        setCenter(d);
        setAdd(false);
      });
    }
  }, [state]);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Descriptions: {
              extraColor: 'red',
            },
          },
        }}
      >
        <Descriptions
          className={'Descriptions'}
          title={add ? '건강검진기관 등록' : '건강검진기관 수정'}
          column={1}
          bordered
          style={{ width: '100%' }}
          extra="AI건강상담 챗봇에 반영되는 정보입니다."
        >
          <Item
            label={
              chatCenter.i_name ? (
                <Badge status="success" text="기관명" />
              ) : (
                <Badge status="error" text="기관명" />
              )
            }
          >
            <Input
              placeholder="(예시) 하나로의료재단 강남센터"
              style={{ width: '400px' }}
              value={chatCenter.i_name}
              onChange={(e) => {
                setCenter({
                  ...chatCenter,
                  i_name: e.target.value,
                });
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ color: 'grey' }}>
                * 여러 지점이 있는 경우 기관명 뒤에 지점명을 입력
              </Text>
            </div>
          </Item>

          <Item
            label={
              chatCenter.i_addr ? (
                <Badge status="success" text="주소" />
              ) : (
                <Badge status="error" text="주소" />
              )
            }
          >
            <Input.Search
              placeholder="주소 찾기"
              disabled={chatCenter.addrYn}
              style={{ width: '400px' }}
              value={chatCenter.i_addr}
              onChange={(e) => {
                daumPostcode(chatCenter, setCenter);
              }}
              onSearch={(e) => {
                daumPostcode(chatCenter, setCenter);
              }}
            />
          </Item>

          <Item
            label={
              chatCenter.i_rate1 && chatCenter.i_rate2 ? (
                <Badge status="success" text="우대율" />
              ) : (
                <Badge status="error" text="우대율" />
              )
            }
          >
            <Input
              type="number"
              style={{ width: '80px' }}
              min={0}
              max={100}
              value={chatCenter.i_rate1}
              onChange={(e) => {
                const min = e.target.value;
                if (min < 0 || min > 100) {
                  alert('우대율은 0~100사이만 입력 가능합니다.');
                  return;
                } else {
                  setCenter({
                    ...chatCenter,
                    i_rate1: min,
                  });
                }
              }}
            />
            {' ~ '}
            <Input
              type="number"
              style={{ width: '80px' }}
              min={0}
              max={100}
              value={chatCenter.i_rate2}
              onChange={(e) => {
                const max = e.target.value;
                if (max < 0 || max > 100) {
                  alert('우대율은 0~100사이만 입력 가능합니다.');
                  return;
                } else {
                  setCenter({
                    ...chatCenter,
                    i_rate2: max,
                  });
                }
              }}
            />
            {' %'}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ color: 'grey' }}>
                * 숫자만 입력 / 우대율이 구간이 아니라 정해져 있는 경우 동일한
                숫자 입력 (예시) 20% 우대 시 '20 ~ 20' 입력
              </Text>
            </div>
          </Item>
          <Item
            label={
              chatCenter.i_start ? (
                <Badge status="success" text="협약시작일" />
              ) : (
                <Badge status="error" text="협약시작일" />
              )
            }
          >
            <DatePicker
              value={chatCenter.i_start ? dayjs(chatCenter.i_start) : null}
              onChange={(date) => {
                const formatDate =
                  date == undefined ? null : date.format('YYYY/MM/DD');
                if (chatCenter.i_end < formatDate) {
                  alert('협약시작일은 협약종료일보다 과거여야 합니다.');
                  return;
                } else {
                  setCenter({
                    ...chatCenter,
                    i_start: formatDate,
                  });
                }
              }}
            />
          </Item>
          <Item
            label={
              chatCenter.i_end || chatCenter.i_end == '' ? (
                <Badge status="success" text="협약 종료일" />
              ) : (
                <Badge status="error" text="협약 종료일" />
              )
            }
          >
            <DatePicker
              disabled={
                chatCenter.i_end == null || chatCenter.i_end != ''
                  ? false
                  : true
              }
              value={chatCenter.i_end ? dayjs(chatCenter.i_end) : null}
              onChange={(date) => {
                const formatDate =
                  date == undefined ? null : date.format('YYYY/MM/DD');
                if (chatCenter.i_start > formatDate) {
                  alert('협약종료일은 협약시작일보다 미래여야 합니다.');
                  return;
                } else {
                  setCenter({
                    ...chatCenter,
                    i_end: formatDate,
                  });
                }
              }}
            />
            <Checkbox
              style={{ marginLeft: '5px' }}
              checked={
                chatCenter.i_end == null || chatCenter.i_end != ''
                  ? false
                  : true
              }
              onChange={(e) => {
                const checked = e.target.checked;
                setCenter({
                  ...chatCenter,
                  i_end: checked ? '' : null,
                });
              }}
            >
              해지 시까지
            </Checkbox>
          </Item>
        </Descriptions>
      </ConfigProvider>
      <Divider />
      <Space>
        <Button
          type="primary"
          style={{ width: '70px' }}
          onClick={async () => {
            window.location.href = '/center';
          }}
        >
          목록
        </Button>
        {add ? (
          <SaveButton
            func={addChatCenter}
            data={chatCenter}
            location="/center"
          />
        ) : (
          <Button
            type="primary"
            style={{ marginLeft: '6px', minWidth: '90px' }}
            onClick={async () => {
              const bool = confirm(
                '페이지 내 모든 수정 사항을 저장하시겠습니까?'
              );
              if (bool) {
                try {
                  const res = await editChatCenterDetail(chatCenter);
                  if (res.result == 'success') {
                    insertInfoAcessLog(
                      location.pathname,
                      'chatCenterDetail',
                      '수정',
                      undefined,
                      state.i_no
                    ),
                      await message.success('저장되었습니다');
                    window.location.href = '/center';
                  } else {
                    await message.error(
                      `저장에 실패했습니다. ${res.message} 확인해주세요`
                    );
                  }
                } catch (error) {
                  alert('저장에 실패하였습니다\n 관리자에게 문의 바랍니다');
                }
              }
            }}
          >
            수정하기
          </Button>
        )}
        <DelButton
          func={deleteChatCenter}
          data={chatCenter.i_no}
          location="/center"
        />
      </Space>
    </>
  );
};

export default ChatCenterAddContainer;
