export const chat_company = {
  // 보험사 오름차순 정렬되있으므로 순서 건들지말것
  A10: 'A+라이프',
  A01: 'A+에셋',
  X01: 'AAI헬스케어',
  L52: 'AIA생명',
  L71: 'DB생명',
  N13: 'DB손해보험',
  L31: 'DGB생명',
  L61: 'KB라이프생명',
  X31: 'KT다온플랜',
  L42: 'NH농협생명',
  X36: 'NH투자증권',
  L81: 'NH헬스케어',
  X23: '기업의별',
  X08: '네이버',
  L51: '라이나생명',
  N03: '롯데손해보험',
  N01: '메리츠화재',
  L34: '미래에셋생명',
  L03: '삼성생명',
  X34: '세라젬',
  X14: '옥시레킷벤키저',
  X28: '위플',
  X32: '이제너두',
  L77: '처브라이프 ',
  X33: '태양라이프',
  A20: '파인랩',
  X24: '포스코중우회',
  N14: '하나손해보험',
  X19: '한국교직원공제회',
  X30: '현대차증권',
  L04: '흥국생명',
  N05: '흥국화재',
};

export const chat_category1 = {
  뇌: '뇌',
  심장: '심장',
  '폐/식도': '폐/식도',
  소화기: '소화기',
  구강: '구강',
  내과: '내과',
  '척추/관절/통증재활': '척추/관절/통증재활',
  비뇨생식기: '비뇨생식기',
  여성: '여성',
  성기능: '성기능',
  암치료: '암치료',
  '성형/피부': '성형/피부',
  알레르기: '알레르기',
  안질환: '안질환',
  이비인후: '이비인후',
  '소아/청소년': '소아/청소년',
  '정신/심리': '정신/심리',
  혈관: '혈관',
  화상: '화상',
  기타: '기타',
  수면: '수면',
};

export const chat_category2_by_doctor = (category1) => {
  let result;
  switch (category1) {
    default:
    case '뇌':
      result = {
        뇌혈관: '뇌혈관',
        뇌비혈관: '뇌비혈관',
      };
      break;
    case '심장':
      result = {
        심혈관질환: '심혈관질환',
      };
      break;
    case '폐/식도':
      result = {
        '호흡기계/식도': '호흡기계/식도',
      };
      break;
    case '소화기':
      result = {
        간: '간',
        간담도췌장: '간담도췌장',
        '위장/식도': '위장/식도',
        '대장/항문': '대장/항문',
      };
      break;
    case '구강':
      result = {
        치과: '치과',
      };
      break;
    case '내과':
      result = {
        감염내과: '감염내과',
        내분비질환: '내분비질환',
        감상선: '감상선',
      };
      break;
    case '척추/관절/통증재활':
      result = {
        재활의학: '재활의학',
        통증치료: '통증치료',
        척추: '척추',
        '관절/수지접합': '관절/수지접합',
        류머티즘: '류머티즘',
      };
      break;
    case '비뇨생식기':
      result = {
        신장: '신장',
        비뇨기과: '비뇨기과',
      };
      break;
    case '여성':
      result = {
        유방: '유방',
        '부인과(여성생식기)': '부인과(여성생식기)',
        '고위험임신/출산': '고위험임신/출산',
        불임: '불임',
      };
      break;
    case '성기능':
      result = {
        성기능장애: '성기능장애',
      };
      break;
    case '암치료':
      result = {
        '종양내과/혈액내과': '종양내과/혈액내과',
        방사선치료: '방사선치료',
      };
      break;
    case '성형/피부':
      result = {
        성형외과: '성형외과',
        피부과: '피부과',
      };
      break;
    case '알레르기':
      result = {
        알레르기내과: '알레르기내과',
      };
      break;
    case '안질환':
      result = {
        안과: '안과',
      };
      break;
    case '이비인후':
      result = {
        귀: '귀',
        코: '코',
        '인후/두경부': '인후/두경부',
      };
      break;
    case '소아/청소년':
      result = {
        소아청소년: '소아청소년',
      };
      break;
    case '정신/심리':
      result = {
        정신건강의학과: '정신건강의학과',
      };
      break;
    case '혈관':
      result = {
        혈관외과: '혈관외과',
      };
      break;
    case '화상':
      result = {
        화상외과: '화상외과',
      };
      break;
    case '기타':
      result = {
        '가정의학/응급의학 등': '가정의학/응급의학 등',
      };
      break;
    case '수면':
      result = {
        수면장애: '수면장애',
      };
      break;
  }
  return result;
};

export const chat_category2 = {
  건강검진: '건강검진',
  진료예약: '진료예약',
};

export const chat_service_type = (type) => {
  let result;
  switch (type) {
    default:
    case '건강검진':
      result = {
        검진설계상담: '검진설계상담',
      };
      break;
    case '진료예약':
      result = {
        진료예약상담: '진료예약상담',
        진료예약: '진료예약',
      };
      break;
  }
  return result;
};

export const hospital = {
  all: '전체',
  상급종합: '상급종합병원',
  종합병원: '종합병원',
  병원: '병원',
  의원: '의원',
  요양병원: '요양병원',
  정신병원: '정신병원',
  치과: '치과',
  한방: '한방',
};

export const hspt_sort = {
  reg_DESC: '최근 등록일순',
  reg_ASC: '오래된 등록일순',
  hspt_ASC: '병원명 오름차순',
  hspt_DESC: '병원명 내림차순',
};

export const inst_sort = {
  reg_DESC: '최근 등록일순',
  reg_ASC: '오래된 등록일순',
  inst_ASC: '기관명 오름차순',
  inst_DESC: '기관명 내림차순',
};

export const constract = {
  all: '전체',
  before: '협약 전',
  ing: '협약 중',
  after: '협약 종료',
};

export const hide_doctor = {
  all: '전체',
  true: '설정',
  false: '미설정',
};
export const search = {
  m_doc: '이름',
  h_fullname: '병원명',
  m_dep: '진료과',
  m_name: '진료분야',
};

export const searchHspt = {
  all: '전체',
  h_addr: '시/도',
  h_fullname: '병원명',
};

export const region = {
  강원: '강원',
  경기: '경기',
  경남: '경남',
  경북: '경북',
  광주: '광주',
  대구: '대구',
  대전: '대전',
  부산: '부산',
  서울: '서울',
  울산: '울산',
  인천: '인천',
  전남: '전남',
  전북: '전북',
  제주: '제주',
  충남: '충남',
  충북: '충북',
  세종시: '세종시',
};

export const region_by_simpyungwon = {
  100000: '전체',
  320000: '강원',
  310000: '경기',
  380000: '경남',
  370000: '경북',
  240000: '광주',
  230000: '대구',
  250000: '대전',
  210000: '부산',
  110000: '서울',
  260000: '울산',
  220000: '인천',
  360000: '전남',
  350000: '전북',
  390000: '제주',
  340000: '충남',
  330000: '충북',
  410000: '세종시',
};

export const ambti = {
  HLFE: '금상첨화',
  HLVE: '동에번쩍',
  HLFP: '명불허전',
  HLVP: '애매모호',
  HIFE: '살아있네',
  HIVE: '강약약강',
  HIFP: '사면초가',
  HIVP: '무한도전',
  BLFE: '눈에띄네',
  BLVE: '애써외면',
  BLFP: '라떼탈출',
  BLVP: '동공지진',
  BIVE: '고마해라',
  BIFE: '올게왔다',
  BIFP: '진퇴양난',
  BIVP: '개과천선',
};
