import React, { useState } from 'react';
import {
  Descriptions,
  Divider,
  Input,
  Row,
  Space,
  Radio,
  Button,
  Modal,
} from 'antd';
import {
  company_ref,
  img_url_map_ref,
  child_answer_ref,
  option_memo_ref,
  gender_ref,
} from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { CancelButton, SaveButton, ImgModal } from 'components/Common';
import { editArtCounselDetail } from 'controllers/Outers/artCounsels';
import TextArea from 'antd/lib/input/TextArea';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import axios from 'axios';
import noImage from '../../../../../assets/none_image.png';

const { Item } = Descriptions;
const ArtCounselsDetailTable = (props) => {
  const { artCounsel, setArtCounsel } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const [imgModalIsOpen, setImgModalIsOpen] = useState(false);
  const [imgReason, setImgReason] = useState(''); // 이미지다운로드 사유
  const imgObjToArr = () => {
    let entriesArr;
    const handleErrorImage = (e) => {
      e.target.src = noImage;
      e.target.style = 'width: 50px;';
    };
    if (artCounsel.img_url_map) {
      entriesArr = Object.entries(artCounsel.img_url_map);
      return entriesArr.map((array) => (
        <Item label={img_url_map_ref[array[0]]}>
          {/* <Button
            type="primary"
            style={{ float: 'right', marginRight: '550px' }}
            onClick={() => {
              setImgModalIsOpen(true), 
              setImgUrl(array[1][0])
            }}
          >
            다운로드
          </Button> */}
          <img
            style={{ height: '250px', width: 'auto' }}
            key={array[1]}
            src={array[1]}
            onError={handleErrorImage}
            onClick={() => {
              setModalIsOpen(true), setImgUrl(array[1]);
            }}
            onContextMenu={(e) => e.preventDefault()}
            alt="Img Url"
          />
          {array[1].length > 0 ? (
            <Button
              type="primary"
              style={{ float: 'right' }}
              onClick={() => {
                setImgModalIsOpen(true), setImgUrl(array[1][0]);
              }}
            >
              다운로드
            </Button>
          ) : (
            ''
          )}
        </Item>
      ));
    }
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const imgDownload = () => {
    insertInfoAcessLog(
      location.pathname,
      'downloadImg',
      '다운로드',
      imgReason,
      artCounsel.document_id
    );
    downloadImage();
  };
  const downloadImage = async () => {
     const response = await axios({
      method: 'GET',
      url: imgUrl,
      responseType: 'blob',
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.error(err);
      });
    const a = document.createElement('a');
    a.href = URL.createObjectURL(response);
    a.download =
      '계약자_' + artCounsel.crm_nm + '_자녀_' + artCounsel.crm_child_nm; // 다운로드할 파일의 이름
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const relationshipsArr = () => {
    if (artCounsel.relationships) {
      return artCounsel.relationships.map((rel) => (
        <Item label={rel.type + ' (생년월일: ' + rel.birth_dt + ')'}>
          {rel.intimacy}
        </Item>
      ));
    }
  };
  return (
    <>
      <Descriptions
        title={`계약자 정보 (상담신청: ${artCounsel.counsel_count || 0}회)`}
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="계약자 이름">{artCounsel.crm_nm || '-'}</Item>
        <Item label="자녀 이름">{artCounsel.crm_child_nm || '-'}</Item>
        <Item label="자녀 생년월일">
          {artCounsel.crm_child_birth_dt || '-'}
        </Item>
        <Item label="자녀 성별">
          {gender_ref[artCounsel.crm_child_gender] || '-'}
        </Item>
        <Item label="연락처">{artCounsel.crm_phone || '-'}</Item>
        <Item label="최종수정일">
          {getFormattedDate(artCounsel.mod_dt, 'opt2') || '-'}
        </Item>
        <Item label="보험사명">
          {company_ref[artCounsel.company_id] || '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="1. 그림 이미지들"
        bordered
        column={1}
        style={{ width: '100%', paddingTop: '10px' }}
      >
        {imgObjToArr()}
      </Descriptions>
      {artCounsel.img_url_map && artCounsel.img_url_map.homeTreePerson ? (
        <Descriptions
          title="  집, 나무, 사람 그림 자녀 답변"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label={child_answer_ref.who || '-'}>
            {artCounsel.child_answer ? artCounsel.child_answer.who : '-'}
          </Item>
          <Item label={child_answer_ref.houseAtmosphere || '-'}>
            {artCounsel.child_answer
              ? artCounsel.child_answer.houseAtmosphere
              : '-'}
          </Item>
          <Item label={child_answer_ref.wantToLive || '-'}>
            {artCounsel.child_answer ? artCounsel.child_answer.wantToLive : '-'}
          </Item>
          <Item label={child_answer_ref.treeAge || '-'}>
            {artCounsel.child_answer ? artCounsel.child_answer.treeAge : '-'}
          </Item>
          <Item label={child_answer_ref.treeCondition || '-'}>
            {artCounsel.child_answer
              ? artCounsel.child_answer.treeCondition
              : '-'}
          </Item>
          <Item label={child_answer_ref.wish || '-'}>
            {artCounsel.child_answer ? artCounsel.child_answer.wish : '-'}
          </Item>
          <Item label={child_answer_ref.personAge || '-'}>
            {artCounsel.child_answer ? artCounsel.child_answer.personAge : '-'}
          </Item>
          <Item label={child_answer_ref.reminiscent || '-'}>
            {artCounsel.child_answer
              ? artCounsel.child_answer.reminiscent
              : '-'}
          </Item>
          <Item label={child_answer_ref.action || '-'}>
            {artCounsel.child_answer ? artCounsel.child_answer.action : '-'}
          </Item>
          <Item label={child_answer_ref.happy || '-'}>
            {artCounsel.child_answer ? artCounsel.child_answer.happy : '-'}
          </Item>
          <Item label={child_answer_ref.childAtmosphere || '-'}>
            {artCounsel.child_answer
              ? artCounsel.child_answer.childAtmosphere
              : '-'}
          </Item>
        </Descriptions>
      ) : null}

      <Divider />
      <Descriptions
        title="2. 가족관계"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        {relationshipsArr()}
      </Descriptions>
      <Divider />
      <Descriptions
        title="3. 선택 질문"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label={option_memo_ref.friendship}>
          {artCounsel.option_memo ? artCounsel.option_memo.friendship : '-'}
        </Item>
        <Item label={option_memo_ref.subject}>
          {artCounsel.option_memo ? artCounsel.option_memo.subject : '-'}
        </Item>
        <Item label={option_memo_ref.history}>
          {artCounsel.option_memo ? artCounsel.option_memo.history : '-'}
        </Item>
        <Item label={option_memo_ref.habitAction}>
          {artCounsel.option_memo ? artCounsel.option_memo.habitAction : '-'}
        </Item>
        <Item label={option_memo_ref.specialty}>
          {artCounsel.option_memo ? artCounsel.option_memo.specialty : '-'}
        </Item>
        <Item label={option_memo_ref.avoidFood}>
          {artCounsel.option_memo ? artCounsel.option_memo.avoidFood : '-'}
        </Item>
        <Item label={option_memo_ref.requestingReason}>
          {artCounsel.option_memo
            ? artCounsel.option_memo.requestingReason
            : '-'}
        </Item>
        <Item label={option_memo_ref.requestingWish}>
          {artCounsel.option_memo ? artCounsel.option_memo.requestingWish : '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="4. 검사결과"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="상태">
          <Radio.Group
            value={artCounsel.status}
            onChange={(e) =>
              setArtCounsel({ ...artCounsel, status: e.target.value })
            }
          >
            <Radio value={0}>신청</Radio>
            <Radio value={1}>진행중</Radio>
            <Radio value={2}>완료</Radio>
            <Radio value={9}>취소</Radio>
          </Radio.Group>
        </Item>
        <Item label="완료일">
          <Input
            value={getFormattedDate(artCounsel.admin_check_dt, 'opt2')}
            readOnly
          />
        </Item>
        <Item label="검사결과상세">
          <TextArea
            rows={10}
            allowClear
            value={
              artCounsel.admin_answer && true
                ? artCounsel.admin_answer.replace(/\\n/gi, '\r\n')
                : ''
            }
            placeholder="분석 내용을 적어주세요"
            onChange={(e) => {
              let { value } = e.target;
              if (value === '\r\n') {
                value = '\n';
              }
              setArtCounsel({ ...artCounsel, admin_answer: value });
            }}
          />
        </Item>
      </Descriptions>
      <Divider />
      <Row>
        <Space>
          <CancelButton location="/art-counsels" />
          <SaveButton
            func={editArtCounselDetail}
            data={artCounsel}
            location="/art-counsels"
          />
        </Space>
      </Row>
      <div>
        {modalIsOpen && (
          <ImgModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            imgUrl={imgUrl}
            // artCounsel={true}
          ></ImgModal>
        )}
      </div>
      <div>
        {imgModalIsOpen && (
          <Modal
            open={imgModalIsOpen}
            cancelText={'닫기'}
            okText={'확인'}
            onCancel={() => setImgModalIsOpen(false)}
            onOk={() => {
              imgDownload(), setImgModalIsOpen(false);
            }}
            title={'다운로드 사유를 입력하세요.'}
            closable={false}
          >
            <p>다운로드 시 시간이 걸릴 수 있습니다.</p>
            <Input
              onChange={(e) => setImgReason(e.target.value)}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default ArtCounselsDetailTable;
