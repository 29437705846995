import React, { useState } from 'react';
import { getFormattedDate } from 'lib/getFormattedDate';
import { company_ref, product_id_ref, status_ref } from 'lib/dataRef';
import { deleteMediCert, editMediCertDetail } from 'controllers/Services/mediCerts';
import { Badge, Checkbox, Descriptions, Divider, Input, Row, Space, Steps } from 'antd';
import { AlertButton, CancelButton, DelButton, SaveButton, ImgModal } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import noImage from '../../../../../assets/none_image.png'

const { Item } = Descriptions;
const MediCertsDetailTable = (props) => {
  const LOCATION = routes_ref.base.services.mediCert.path;
  const {
    mediCertsDetail,
    setMedicertsDetail,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState('');
  const handleTypeChange = (checked) => {
    setMedicertsDetail({
      ...mediCertsDetail,
      type: checked,
    });
  };
  const handleStatusChange = (current) => {
    setMedicertsDetail({
      ...mediCertsDetail,
      status: current,
    });
  };
  const handleErrorImage = (e) => {
    e.target.src = noImage;
    e.target.style = 'width: 50px;'
  };

  const mappedImgUrl = mediCertsDetail.img_url
    ? mediCertsDetail.img_url.map((url) => (
      <Row key={url}>
        <img
          style={{
            height: '250px',
            width: 'auto',
          }}
          key={url}
          src={url}
          onError={handleErrorImage}
          onClick={() => {setModalIsOpen(true), setImgUrl(url)}}
          onContextMenu={(e) => e.preventDefault()}
          alt="Img Url"
        />
        <Divider />
      </Row>
    ))
    : '-';
  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="계약자 이름">
          {mediCertsDetail.crm_nm || '-'}
        </Item>
        <Item label="계약자 연락처">
          {mediCertsDetail.crm_phone || '-'}
        </Item>
        <Item label="보험사명">
          {company_ref[mediCertsDetail.company_id] || '-'}
        </Item>
        {
          mediCertsDetail.crm_child_nm
            ? (
              <Item label="피보험자 이름">
                {mediCertsDetail.crm_child_nm || '-'}
              </Item>
            )
            : null
        }
      </Descriptions>
      <Divider />

      <Descriptions
        title="서비스 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="앱 명칭">
          {product_id_ref[mediCertsDetail.product_id] || '-'}
        </Item>
        <Item label={
          mediCertsDetail.type ? <Badge status="success" text="타입지정" /> : <Badge status="error" text="타입지정" />
        }
        >
          <Checkbox.Group
            onChange={handleTypeChange}
            value={mediCertsDetail && mediCertsDetail.type}
          >
            {
              mediCertsDetail.product_id === 'ChubbLife01_0000' || mediCertsDetail.product_id === 'NhInvestment01_4380'
                ? (
                  <Checkbox value="default">
                    일반
                  </Checkbox>
                )
                : (
                  <>
                    <Checkbox value="cancer">
                      암
                    </Checkbox>
                    <Checkbox value="brain">
                      뇌심
                    </Checkbox>
                    <Checkbox value="gi">
                      GI-LTC
                    </Checkbox>
                    <Checkbox value="default">
                      일반
                    </Checkbox>
                    <Checkbox value="seniorCare">
                      시니어케어(요양형) 경증
                    </Checkbox>
                    <Checkbox value="seniorDementia">
                      시니어케어(치매형) 경증
                    </Checkbox>
                  </>
                )
            }
          </Checkbox.Group>
        </Item>
        <Item label="관리자 메모">
          <Input.TextArea
            value={mediCertsDetail.admin_memo}
            rows={10}
            allowClear
            onChange={(e) => setMedicertsDetail({
              ...mediCertsDetail,
              admin_memo: e.currentTarget.value,
            })}
          />
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions
        bordered
        layout="vertical"
      >
        <Item label="첨부 이미지들">
          {mediCertsDetail.status == 2 || mediCertsDetail.status == 9 ? "취소/완료된 건으로 삭제된 이미지 입니다." : mappedImgUrl}
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions
        title="신청 정보"
        bordered
        column={2}
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Item label="서비스 진행 상태" span={2}>
          <Steps
            type="navigation"
            size="default"
            current={mediCertsDetail.status}
            labelPlacement="vertical"
            onChange={(current) => handleStatusChange(current)}
            className="site-navigation-steps"
          >
            <Steps
              title={status_ref[0]}
              description={getFormattedDate(mediCertsDetail.reg_dt, 'opt1')}
            />
            <Steps
              title={status_ref[1]}
              description={getFormattedDate(mediCertsDetail.admin_check_dt, 'opt1')}
            />
            <Steps
              title={status_ref[2]}
              subTitle=""
              description=""
            />
          </Steps>
          <Steps
            type="navigation"
            size="small"
            current={mediCertsDetail.status}
            labelPlacement="vertical"
            initial={9}
            onChange={() => handleStatusChange(9)}
            className="site-navigation-steps"
          >
            <Steps
              title={status_ref[9]}
              status="error"
              subTitle=""
              description="사용자의 신청을 취소합니다"
            />
          </Steps>
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions
        title="관리자 관련"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="관리자 확인 여부">
          {mediCertsDetail.admin_check_yn
            ? mediCertsDetail.status === 1
              ? '관리자 확인중' : '확인완료'
            : '-'}
        </Item>
        <Item label="관리자 확인 시각">
          {getFormattedDate(mediCertsDetail.admin_check_dt, 'opt2') || '-'}
        </Item>
        <Item label="등록시각">
          {getFormattedDate(mediCertsDetail.reg_dt, 'opt2') || '-'}
        </Item>
      </Descriptions>
      <Divider />

      <Row>
        <Space>
          <CancelButton
            location={LOCATION}
          />
          {
            mediCertsDetail.type
              ? (
                <SaveButton
                  func={editMediCertDetail}
                  data={mediCertsDetail}
                  location={LOCATION}
                />
              )
              : <AlertButton />
          }
          <DelButton
            func={deleteMediCert}
            data={mediCertsDetail}
            location={LOCATION}
          />
        </Space>
      </Row>
      <div>
      {
          modalIsOpen && (
            <ImgModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            imgUrl={imgUrl}
          >
             </ImgModal>
        )}
      </div>
    </>
  );
};

export default MediCertsDetailTable;
