import React, { useState } from 'react';
import Item from 'antd/es/list/Item';
import { Descriptions, Divider, Button } from 'antd';
import { makeForCustomerXlsx } from 'controllers/HealthLogs/steps';
import { company_ref } from 'lib/dataRef';
import { ListTableTemplate, ExcelModal } from 'components/Common';

const StepsDetailTable = (props) => {
    const { stepsDetail, monthly } = props;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
    const [excelData, setExcelData] = useState({id: "", year: {}}); // 엑셀다운로드 키값
    const fetchData = (id, year) => {
      excelData.id = id;
      excelData.year = year;
      setExcelData(excelData);
    };
    const excelDownload = () =>{
      makeForCustomerXlsx(excelData.id, excelData.year.year, excelData.year.month, excelReason)
    }
  const columns = [
    {
      title: '연도',
      dataIndex: 'year',
      key: 'year',
      align: 'center',
      sorter: (a, b) => a.year - b.year,
    },
    {
      title: '월',
      dataIndex: 'month',
      key: 'month',
      align: 'center',
      sorter: (a, b) => a.month - b.month,
      render: (month) => `${month} 월`,
    },
    {
      title: '일일 평균 걸음',
      dataIndex: 'steps_avg',
      key: 'steps_avg',
      align: 'center',
      sorter: (a, b) => a.steps_avg - b.steps_avg,
      render: (steps_avg) => `${steps_avg} 보`,
    },
    {
      title: '일일 최고 성과 걸음',
      dataIndex: 'steps_max',
      key: 'steps_max',
      align: 'center',
      sorter: (a, b) => a.steps_max - b.steps_max,
      render: (steps_max) => `${steps_max} 보`,
    },
    {
      title: '월 누적 걸음',
      dataIndex: 'steps_total',
      key: 'steps_total',
      align: 'center',
      sorter: (a, b) => a.steps_total - b.steps_total,
      render: (steps_total) => `${steps_total} 보`,
    },
    {
      title: '일일 평균 열량',
      dataIndex: 'calories_avg',
      key: 'calories_avg',
      align: 'center',
      sorter: (a, b) => a.calories_avg - b.calories_avg,
      render: (calories_avg) => `${calories_avg} kcal`,
    },
    {
      title: '일일 최고 성과 열량',
      dataIndex: 'calories_max',
      key: 'calories_max',
      align: 'center',
      sorter: (a, b) => a.calories_max - b.calories_max,
      render: (calories_max) => `${calories_max} kcal`,
    },
    {
      title: '누적 열량',
      dataIndex: 'calories_total',
      key: 'calories_total',
      align: 'center',
      sorter: (a, b) => a.calories_total - b.calories_total,
      render: (calories_total) => `${calories_total} kcal`,
    },
    {
      title: '지급 포인트',
      dataIndex: 'points_total',
      key: 'points_total',
      align: 'center',
      sorter: (a, b) => a.points_total - b.points_total,
      render: (points_total) => `${points_total || '-'}`,
    },
    {
      title: '일별 포인트',
      dataIndex: 'total_document_id',
      key: 'total_document_id',
      align: 'center',
      render(total_document_id, year) {
        return (
          <>
            <Button
              key={total_document_id}
              type="primary"
              size="small"
              onClick={() => {setModalIsOpen(true), fetchData(total_document_id, year)}}
            >
              상세보기
            </Button>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="이름">
          {stepsDetail.crm_nm || '-'}
        </Item>
        <Item label="연락처">
          {stepsDetail.crm_phone || '-'}
        </Item>
        <Item label="보험사명">
          {company_ref[stepsDetail.company_id] || '-'}
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions
        title="기간 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="총 활동 기간">
          {stepsDetail.activity_dt || '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="걷기 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="총 누적 걸음 수">
          {stepsDetail.steps_total || '-'}
        </Item>
        <Item label="월 평균 걸음 수">
          {stepsDetail.monthly_steps_avg || '-'}
        </Item>
        <Item label="일일 최고 걸음 수">
          {stepsDetail.daily_steps_max || '-'}
        </Item>
        <Item label="총 누적 소비 열량">
          {`${stepsDetail.calories_total} kcal` || '-'}
        </Item>
        <Item label="일 평균 소비 열량">
          {`${stepsDetail.daily_calories_avg} kcal` || '-'}
        </Item>
        <Item label="일일 최고 소비 열량">
          {`${stepsDetail.daily_calories_max} kcal` || '-'}
        </Item>
        <Item label="지급 포인트">
        {stepsDetail.points_total || '-'}
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="기간별 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      />
      <ListTableTemplate
        dataSource={monthly}
        columns={columns}
      />
      <div>
        {
          modalIsOpen && (
            <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
             </ExcelModal>
        )}
      </div>
    </>
  );
};

export default StepsDetailTable;
