import React from 'react';
import { Select } from 'antd';

const getOptionsByRefId = (ref) => Object.entries(ref).map((row) => {
  const [key, value] = row;
  return (
    <Select.Option
      key={key}
      value={key}
    >
      {value}
    </Select.Option>
  );
});
export default getOptionsByRefId;
