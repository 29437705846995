import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 등록된 현물급부의 전체 정보
 * @param {*} setBenefits client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
 */
export async function getBenefitsList(setBenefits, benefits, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/outer/benefits/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage,
        pagingState: benefits.pagingState,
        searchState: searchState
      })
    })
    .then((response) =>  response);

    setBenefits(result.data);
    setCurrentPage(1);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 현물급부의 세부 정보 조회
 * @param {string} document_id 해당 미술심리상담의 document id
 */
export async function getBenefitDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/outer/benefits/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 현물급부의 정보를 수정
 * @param {object} artCounsel 해당 미술심리상담의 정보 및 수정하고자 하는 내용
 */
export async function editBenefitDetail(benefit) {
  try {
    // 이미 삭제된 건인 경우, 리턴
    if (benefit.del_dt > 0) {
      console.log('fail: The benefit was deleted.');
      return { result: 'fail', message: 'The benefit was deleted.' };
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/outer/benefits/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        benefit
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 등록된 현물급부 신청을 삭제함
 * @param {object} benefit 등록된 현물급부 신청의 정보
 */
export async function deleteBenefit(benefit) { // benefit === getBenefitDetail function's return value
  try {
    // 필수 파람(인자) 확인
    if (benefit === undefined
      || (benefit.document_id === undefined || benefit.document_id.length === 0)) {
      return { result: 'fail', message: 'Param is lack.' };
    }

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/outer/benefits/delete`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        benefit
      }),
    },).then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}