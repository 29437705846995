import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import { useCheckAuth } from 'lib/hooks';
import AppHashTableContainer from 'containers/Systems/AppHash/AppHashListTableContainer';

const AppHashPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['systems']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<AppHashTableContainer />}
    />
  );
};

export default AppHashPage;
