import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from '@redux-devtools/extension';
import createRootReducer from './modules';

export const history = createBrowserHistory();

const env = process.env.NODE_ENV;
const middlewares = [routerMiddleware(history)];

export default function configureStore(preloadedState) {
  let store;
  if (env === 'development') {
    store = createStore(
      createRootReducer(history),
      preloadedState,
      composeWithDevTools(applyMiddleware(...middlewares)),
    );
  } else if (env === 'production') {
    store = createStore(
      createRootReducer(history),
      preloadedState,
      compose(applyMiddleware(...middlewares)),
    );
  }
  return store;
}
