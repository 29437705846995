import React, { useState, useEffect } from 'react';
import { getNoticeDetail } from 'controllers/Contents/notice';
import { NoticeDetailTable } from 'components/Common';
import { Spin } from 'antd';

const NoticeDetailContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [noticeDetail, setNoticeDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setNoticeDetail({});
      setLoading(true);
      const data = getNoticeDetail(document_id);
      data.then((d) => {
        setNoticeDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <NoticeDetailTable noticeDetail={noticeDetail} setNoticeDetail={setNoticeDetail} />
      </Spin>
    :
      <NoticeDetailTable noticeDetail={noticeDetail} setNoticeDetail={setNoticeDetail} />
  );
};

export default NoticeDetailContainer;
