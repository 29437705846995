import React from 'react';
import { Table } from 'antd';

const ListTableTemplate = ({ dataSource, columns, DetailTableContainer, loading }) => (
  <>
    <Table
      rowKey={(record) => JSON.stringify(record) + Math.random()}
      dataSource={dataSource}
      columns={columns}
      style={{ whiteSpace: 'pre-line' }}
      pagination={{
        position: ['none', 'bottomCenter'],
      }}
      loading={loading}
    />
    {DetailTableContainer || null}
  </>
);

export default ListTableTemplate;
