import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 등록된 미술심리상담의 전체 정보
 * @param {*} setArtCounsels client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
 */
export async function getArtCounselsList(setArtCounsels, artCounsels, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/outer/artCounsels/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage,
        pagingState: artCounsels.pagingState,
        searchState: searchState
      })
    })
    .then((response) =>  response);

    setArtCounsels(result.data);
    setCurrentPage(1);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 미술심리상담의 세부 정보 조회
 * @param {string} document_id 해당 미술심리상담의 document id
 */
export async function getArtCounselDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/outer/artCounsels/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 미술심리상담의 정보를 수정
 * @param {object} artCounsel 해당 미술심리상담의 정보 및 수정하고자 하는 내용
 */
export async function editArtCounselDetail(artCounsel) {
  try {
    // 이미 삭제된 건인 경우, 리턴
    if (artCounsel.del_dt > 0) {
      console.log('fail: The art counsel was deleted.');
      return { result: 'fail', message: 'The art counsel was deleted.' };
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/outer/artCounsels/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        artCounsel
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 등록된 미술심리상담의 건수
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getArtCounselsSize(app_code) {
//   try {
//     const acsRef = await firestore.collection('cust_ARTCOUNSEL')
//       .orderBy('reg_dt', 'desc').get();
//     return acsRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 등록된 미술심리상담의 전체 정보
//  * @param {*} setArtCounsels client에서 전달하는 React.Dispatch
//  * @param {*} setCount client에서 전달하는 React.Dispatch
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getArtCounselsList(setArtCounsels, setCount, setLoading, app_code) {
//   try {
//     // 컬렉션 내에서 변동이 있을 경우(새로운 미술심리상담 추가, 기존 미술심리상담 변동 등),
//     // 리로드 없이도 변동 사항을 반영하기 위해 onSnapshot 이용
//     // (onSnapshot에 대한 자세한 사항은 firebase documentation을 참고하길 바람)
//     const acsRef = await firestore.collection('cust_ARTCOUNSEL')
//       .orderBy('reg_dt', 'desc').onSnapshot((snap) => {
//         const acDocs = [];
//         const changes = snap.docChanges();
//         changes.forEach((change) => {
//           if (change.type === 'added') {
//             if (changes.length > 1) {
//               // 맨 처음 리스트가 불려졌을 때
//               if (change.doc.data().status !== 8) {
//                 const product_id = String(change.doc.data().product_id).split('_');
//                 acDocs.push({
//                   no: (acDocs.length + 2) - 1,
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: change.doc.data().product_id,
//                   document_id: change.doc.data().document_id,
//                   company_id: change.doc.data().company_id,
//                   crm_child_nm: change.doc.data().crm_child_nm,
//                   crm_child_birth_dt: change.doc.data().crm_child_birth_dt,
//                   crm_nm: change.doc.data().crm_nm,
//                   crm_phone: change.doc.data().crm_phone,
//                   status: change.doc.data().status,
//                   reg_dt: change.doc.data().reg_dt,
//                   mod_dt: change.doc.data().mod_dt,
//                   del_yn: change.doc.data().del_yn,
//                   del_dt: change.doc.data().del_dt,
//                   admin_check_yn: change.doc.data().admin_check_yn,
//                   admin_check_dt: change.doc.data().admin_check_dt,
//                 });
//               }
//             } else {
//               snap.forEach((doc) => {
//                 // 뭔가 추가가 되었을 때
//                 if (doc.data().status !== 8) {
//                   const product_id = String(change.doc.data().product_id).split('_');
//                   acDocs.push({
//                     app_code: product_id[0],
//                     goods_code: product_id[1],
//                     product_id: doc.data().product_id,
//                     document_id: doc.data().document_id,
//                     company_id: doc.data().company_id,
//                     crm_child_nm: doc.data().crm_child_nm,
//                     crm_child_birth_dt: doc.data().crm_child_birth_dt,
//                     crm_nm: doc.data().crm_nm,
//                     crm_phone: doc.data().crm_phone,
//                     status: doc.data().status,
//                     reg_dt: doc.data().reg_dt,
//                     mod_dt: doc.data().mod_dt,
//                     del_yn: doc.data().del_yn,
//                     del_dt: doc.data().del_dt,
//                     admin_check_yn: doc.data().admin_check_yn,
//                     admin_check_dt: doc.data().admin_check_dt,
//                   });
//                 }
//               });
//             }
//           }

//           if (change.type === 'modified') {
//             snap.forEach((doc) => {
//               if (doc.data().status !== 8) {
//                 const product_id = String(change.doc.data().product_id).split('_');
//                 acDocs.push({
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: doc.data().product_id,
//                   document_id: doc.data().document_id,
//                   company_id: doc.data().company_id,
//                   crm_child_nm: doc.data().crm_child_nm,
//                   crm_child_birth_dt: doc.data().crm_child_birth_dt,
//                   crm_nm: doc.data().crm_nm,
//                   crm_phone: doc.data().crm_phone,
//                   status: doc.data().status,
//                   reg_dt: doc.data().reg_dt,
//                   mod_dt: doc.data().mod_dt,
//                   del_yn: doc.data().del_yn,
//                   del_dt: doc.data().del_dt,
//                   admin_check_yn: doc.data().admin_check_yn,
//                   admin_check_dt: doc.data().admin_check_dt,
//                 });
//               }
//             });
//           }

//           if (change.type === 'removed') {
//             snap.forEach((doc) => {
//               if (doc.data().status !== 8) {
//                 const product_id = String(change.doc.data().product_id).split('_');
//                 acDocs.push({
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: doc.data().product_id,
//                   document_id: doc.data().document_id,
//                   company_id: doc.data().company_id,
//                   crm_child_nm: doc.data().crm_child_nm,
//                   crm_child_birth_dt: doc.data().crm_child_birth_dt,
//                   crm_nm: doc.data().crm_nm,
//                   crm_phone: doc.data().crm_phone,
//                   status: doc.data().status,
//                   reg_dt: doc.data().reg_dt,
//                   mod_dt: doc.data().mod_dt,
//                   del_yn: doc.data().del_yn,
//                   del_dt: doc.data().del_dt,
//                   admin_check_yn: doc.data().admin_check_yn,
//                   admin_check_dt: doc.data().admin_check_dt,
//                 });
//               }
//             });
//           }
//         });

//         setArtCounsels(acDocs);
//         setCount(acDocs.length);
//         setLoading(false);
//       });

//     return acsRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 미술심리상담의 세부 정보 조회
//  * @param {string} document_id 해당 미술심리상담의 document id
//  */
// export async function getArtCounselDetail(document_id) {
//   try {
//     const acRef = await firestore.collection('cust_ARTCOUNSEL')
//       .doc(document_id).get();
//     // 해당 미술심리상담을 신청한 사용자가 이전에 몇 번이나 신청했었는지 확인하기 위해 조회
//     const countRef = await firestore.collection('cust_ARTCOUNSEL')
//       .where('crm_child_id', '==', acRef.data().crm_child_id)
//       .where('reg_dt', '<=', acRef.data().reg_dt)
//       .get();
//     let count = 0;
//     countRef.docs.forEach((doc) => {
//       if (doc.data().status <= 2) {
//         count++;
//       }
//     });
//     const product_id = String(acRef.data().product_id).split('_');
//     return {
//       document_id: acRef.data().document_id,
//       company_id: acRef.data().company_id,
//       app_code: product_id[0],
//       goods_code: product_id[1],
//       product_id: acRef.data().product_id,
//       crm_child_nm: acRef.data().crm_child_nm,
//       crm_child_birth_dt: acRef.data().crm_child_birth_dt,
//       crm_child_gender: acRef.data().crm_child_gender,
//       crm_nm: acRef.data().crm_nm,
//       crm_phone: acRef.data().crm_phone,
//       img_url_map: acRef.data().img_url_map,
//       relationships: acRef.data().relationships,
//       child_answer: acRef.data().child_answer,
//       option_memo: acRef.data().option_memo,
//       status: acRef.data().status,
//       admin_answer: acRef.data().admin_answer,
//       admin_check_yn: acRef.data().admin_check_yn,
//       admin_check_dt: acRef.data().admin_check_dt,
//       admin_memo: acRef.data().admin_memo,
//       fcm_id: acRef.data().fcm_id,
//       reg_dt: acRef.data().reg_dt,
//       mod_dt: acRef.data().mod_dt,
//       del_yn: acRef.data().del_yn,
//       del_dt: acRef.data().del_dt,
//       counsel_count: count,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 미술심리상담의 정보를 수정
//  * @param {object} artCounsel 해당 미술심리상담의 정보 및 수정하고자 하는 내용
//  */
// export async function editArtCounselDetail(artCounsel) {
//   try {
//     // 이미 삭제된 건인 경우, 리턴
//     if (artCounsel.del_dt > 0) {
//       console.log('fail: The art counsel was deleted.');
//       return { result: 'fail', message: 'The art counsel was deleted.' };
//     }
//     // 현재 해당 미술심리상담의 정보 조회
//     const artCounselB = await firestore.collection('cust_ARTCOUNSEL')
//       .doc(artCounsel.document_id).get();
//     const now = Math.floor(new Date().getTime());
//     delete artCounsel.app_code;
//     delete artCounsel.goods_code;

//     // checking about status
//     // 관리자가 아직 확인하지 않은 건이며, 변경하고자 하는 상태가 진행중 혹은 완료인 경우
//     if (!artCounsel.admin_check_yn
//       && (artCounsel.status === 1 || artCounsel.status === 2)) {
//       artCounsel.admin_check_yn = true;
//       artCounsel.admin_check_dt = now;
//       artCounsel.mod_dt = now;
//     // 관리자가 이미 확인한 건이며, 변경하고자 하는 상태가 완료이면서, 현재 완료 상태가 아닌 경우
//     } else if (artCounsel.admin_check_yn && artCounsel.status === 2
//       && artCounselB.data().status !== artCounsel.status) {
//       artCounsel.mod_dt = now;
//     // 아직 삭제되지 않은 건이며, 변경하고자 하는 상태가 취소인 경우
//     } else if (!artCounsel.del_yn && artCounsel.status === 9) {
//       artCounsel.admin_check_yn = true;
//       artCounsel.admin_check_dt = now;
//       artCounsel.del_dt = now;
//       artCounsel.del_yn = true;
//       artCounsel.mod_dt = now;
//     }

//     // checking about text
//     // 관리자 메모가 있었다가 없어진 경우
//     if ((!artCounselB.data().admin_memo && !!artCounsel.admin_memo)
//       || artCounselB.data().admin_memo !== artCounsel.admin_memo) {
//       artCounsel.mod_dt = now;
//     }
//     // 관리자 답변이 있었다가 없어진 경우
//     if ((!artCounselB.data().admin_answer && !!artCounsel.admin_answer)
//       || artCounselB.data().admin_answer !== artCounsel.admin_answer) {
//       artCounsel.mod_dt = now;
//     }

//     // 수정 시각이 현재가 아닌 경우, 수정된 것이 없음을 리턴
//     if (artCounsel.mod_dt !== now) {
//       console.log('fail: There are no modifications.');
//       return { result: 'fail', message: 'There are no modifications.' };
//     }

//     // 수정 시각이 현재이며, 변경될 상태가 완료이고, 이전의 상태가 완료가 아닌 경우
//     if (artCounsel.mod_dt === now && artCounsel.status === 2
//       && artCounselB.data().status !== artCounsel.status) {
//       const myHeaders = new Headers();
//       myHeaders.append('Content-Type', 'application/json; charset=utf-8');
//       myHeaders.append('Authorization', process.env.PUSH_API_AUTH);

//       const raw = JSON.stringify({
//         to: artCounsel.fcm_id,
//         title: '[렛플레이 헬스케어] 서비스 제공 완료',
//         body: '신청하신 미술 심리 검사 서비스 제공이 완료되었습니다. 렛플레이 헬스케어를 이용해주셔서 감사드립니다.',
//       });

//       const requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow',
//       };

//       // 푸쉬 API 이용
//       fetch('https://asia-northeast1-aaihealth-b209b.cloudfunctions.net/push', requestOptions)
//         .then((response) => response.text())
//         .then((result) => console.log(result))
//         .catch((error) => console.log('error:', error));
//     }

//     // checking undefined value and so on...
//     // 값이 undefined이거나 길이가 0인 키값이 있다면, 삭제
//     Object.keys(artCounsel).forEach((v) => {
//       if (artCounsel[v] === undefined || artCounsel[v].length === 0) {
//         delete artCounsel[v];
//       }
//     });
//     // 관리자 메모, 관리자 답변이 이전과 같다면, 삭제
//     if (artCounselB.data().admin_memo === artCounsel.admin_memo) {
//       delete artCounsel.admin_memo;
//     }
//     if (artCounselB.data().admin_answer === artCounsel.admin_answer) {
//       delete artCounsel.admin_answer;
//     }
//     await firestore.collection('cust_ARTCOUNSEL').doc(artCounsel.document_id)
//       .set(artCounsel, { merge: true });
//     return { result: 'success', message: 'The art counsel is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }