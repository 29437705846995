import React, { useState } from 'react';
import { Row, Descriptions, Input, Divider, Space, Drawer, Select } from 'antd';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { postNotice } from 'controllers/Contents/notice';
import { product_id_ref } from 'lib/dataRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';

const { Item } = Descriptions;
const NoticeAddTable = () => {
  const [noticeDetail, setNoticeDetail] = useState({});
  const [drawerStatus, setDrawerStatus] = useState(false);
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setNoticeDetail(noticeDetail);
  };
  const handleChangeProductId = (product_id) => {
    setNoticeDetail({
      ...noticeDetail,
      product_id,
    });
  };
  return (
    <>
    <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
      <Row>
        <Descriptions title="공지사항 추가" bordered column={1} style={{ width: '100%' }}>
        <Item label="보험사">
            <Select
              style={{ width: '100%' }}
              onChange={handleChangeProductId}
              value={noticeDetail.product_id}
            >
              {getOptionsByRefId(product_id_ref)}
            </Select>
          </Item>
          <Item label="제목">
            <Input
              value={noticeDetail.title}
              onChange={(e) => setNoticeDetail({ ...noticeDetail, title: e.target.value })}
            />
          </Item>
          <Item label="공지사항 내용">
            <Input.TextArea
              value={noticeDetail.contents}
              rows={8}
              allowClear
              onChange={(e) => setNoticeDetail({ ...noticeDetail, contents: e.target.value })}
            />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/notice"
          />
          <SaveButton
            func={postNotice}
            data={noticeDetail}
            location="/notice"
          />
        </Space>
      </Row>
      </Drawer>
    </>
  );
};

export default NoticeAddTable;
