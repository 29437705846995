import { Select, Descriptions, Button } from 'antd';
import classNames from 'classnames/bind';
import React from 'react';
import styles from './InfoPane.scss';
import getChatbotFieldList from 'lib/getChatbotFieldList';
import getFieldList from 'lib/getFieldList';

const cx = classNames.bind(styles);

const InfoPane = ({
  boardListButton,
  countLabel,
  addButton,
  hanaStepButton,
  xlsButton,
  searchButton,
  sendButton,
  resetButton,
  delButton,
  searchFieldList: s,
}) => {
  let data;
  if (s !== undefined) {
    if (s.page != undefined && s.page.includes('chat')) {
      data = getChatbotFieldList(
        s.field,
        s.setSearchState,
        s.searchState,
        s.page,
        s.onKeyPress
      );
    } else {
      data = getFieldList(
        s.field,
        s.setSearchState,
        s.searchState,
        s.page,
        s.onKeyPress
      );
    }
  }
  return (
    <div className={cx('infoPane')}>
      <div className={cx('labels')}>
        {boardListButton}
        {countLabel}
      </div>
      <div className={cx('button-group')}>
        {searchButton}
        {resetButton}
        {xlsButton}
        {delButton}
        {hanaStepButton}
        {addButton}
        {sendButton}
      </div>
      <div className={cx('field')}>
        {s !== undefined ? (
          <Descriptions bordered column={2} size={'small'}>
            {data}
          </Descriptions>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default InfoPane;
