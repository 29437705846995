import React from 'react';
import { HeaderPane } from 'components/HeaderPane';
import { useDispatch } from 'react-redux';
import { handleLogout } from 'store/modules/login_reducer';

const HeaderContainer = () => {
  const dispatch = useDispatch();
  const onLogout = () => dispatch(handleLogout());
  return (
    <>
      <HeaderPane onLogout={onLogout} />
    </>
  );
};
export default HeaderContainer;
