/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { getCancerLsList } from 'controllers/HealthLogs/cancerLogs';
import { InfoPane } from 'components/InfoPane';
import {
  CounterLabel,
  LinkButton,
  ListTableWithOutPaging,
  Pagination,
  CancerLogAddTable,
  DelListButton,
} from 'components/Common';
import { Button, Drawer } from 'antd';
import CancerLogDetailTableContainer from './CancerLogDetailTableContainer';
import { routes_ref } from 'lib/routeRef';
import { deleteBundle } from 'controllers/common';
import { company_ref, cancer_category_ref } from 'lib/dataRef';
import { slice } from 'lodash';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const CancerLogListTableContainer = () => {
  const initStatusState = {
    visible: false,
    member_id: '',
    category: '',
  };
  const [cancerLogs, setCancerLogs] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first',
    },
  });
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(initStatusState);
  const [searchState, setSearchState] = useState({});
  const [checkedList, setCheckedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const onClose = () => {
    setStatus({
      visible: false,
      member_id: '',
    });
  };
  const showDrawer = (member_id, category) => {
    setStatus({
      visible: true,
      member_id,
      category,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getCancerLsList(
      setCancerLogs,
      cancerLogs,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
  }, []);

  function goSearch() {
    getCancerLsList(
      setCancerLogs,
      cancerLogs,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
  }

  let cp = currentPage;
  if (cp > 10) {
    // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp % 10;
    cp = n == 0 ? 10 : n;
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  };
  let list = currentData(cancerLogs.data);

  const setPage = (page) => {
    setCurrentPage(page);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const renderMoveData = (move) => {
    getMediCertsList(
      setCancerLogs,
      cancerLogs,
      move,
      setCurrentPage,
      searchState,
      setLoading
    );
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };
  const onChangeChecked = (e, record) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, record]);
    } else {
      const idx = checkedList.findIndex(function (item) {
        return item.member_id === record.member_id;
      });
      checkedList.splice(checkedList.indexOf(idx), 1);
      setCheckedList([...checkedList]);
    }
  };
  console.log(checkedList)
  const onCheckedAll = (e) => {
    if (e.target.checked) {
      setCheckedList(list);
    } else {
      setCheckedList([]);
    }
  };
  const columns = [
    {
      title: () => {
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.length == list.length}
              onChange={(e) => onCheckedAll(e)}
            />
            전체
          </>
        );
      },
      dataIndex: 'member_id',
      key: 'member_id',
      width: '5%',
      render(member_id, record) {
        const category =
          record.member_id === member_id ? record.category : null;
        const NO_CATEGORY_COMPANY = ['L51', 'N14', 'L34'];
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.some(
                (data) =>
                  data.member_id === member_id &&
                  data.category === record.category
              )}
              onChange={(e) => onChangeChecked(e, record)}
            />
            <Button
              key={member_id}
              type="primary"
              size="small"
              onClick={() => {
                insertInfoAcessLog(
                  location.pathname,
                  'cancerLogDetail',
                  '조회',
                  undefined,
                  member_id
                ),
                  showDrawer(
                    member_id,
                    NO_CATEGORY_COMPANY.includes(record.company_id)
                      ? undefined
                      : category
                  );
              }}
            >
              관리
            </Button>
          </>
        );
      },
    },
    {
      title: '구분',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
      render: (data) => cancer_category_ref[data],
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '신청일(기록일자)',
      dataIndex: 'local_dt',
      key: 'local_dt',
      align: 'center',
      render: (date) => slice(date, 0, 10),
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '등록 건',
      dataIndex: 'count',
      key: 'count',
      align: 'center',
      render: (value) => `${value} 회`,
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton link={routes_ref.base.healthLogs.cancer_log.path} />
        }
        countLabel={<CounterLabel count={cancerLogs.count} />}
        addButton={<CancerLogAddTable />}
        delButton={
          <DelListButton
            func={deleteBundle}
            data={checkedList}
            location="/cancer-logs"
          />
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          field: ['보험사', '이름', '연락처'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(cancerLogs.data)}
        columns={columns}
        loading={loading}
        pagination={
          <Pagination
            totalData={cancerLogs.data}
            pagingState={cancerLogs.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        }
        DetailTableContainer={
          <Drawer width={1400} closable onClose={onClose} open={status.visible}>
            <CancerLogDetailTableContainer
              member_id={status.member_id}
              category={status.category}
              visible={status.visible}
              onClose={onClose}
            />
          </Drawer>
        }
      />
    </>
  );
};

export default CancerLogListTableContainer;
