import { Card, Descriptions, Radio, Space } from 'antd';
import questionIcon from 'assets/icons/question_icon.png';
import { gender_ref } from 'lib/dataRef';
import { getFilteredPain_ref, getProgramRecommend_ref, getSplitBbiddakiResult_ref, getFilteredSelfTest_ref } from 'lib/filteredRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import React from 'react';
import noImage from '../../../../../assets/none_image.png'

const { Item } = Descriptions;
const reportTitleStyle = {
  textAlign: 'center',
  width: '100%',
};

const titleStyle = {
  textAlign: 'center',
  width: '100%',
};
const handleErrorImage = (e) => {
  e.target.src = noImage;
  e.target.style = 'width: 50px;'
};
const HealthyBodyNowReportTable = (props) => {
  const { dataState, setDataState } = props;

  const drawChater2 = () => {
    if (dataState.medical_result) {
      if(dataState.medical_result.medical_report) {
        return dataState.medical_result.medical_report.map((row, index) => (
          <React.Fragment key={index}>
            <Item label={row.nm || '-'} style={{ width: '100px', textAlign: 'center', verticalAlign: 'middle' }}>
              <img src={getFilteredPain_ref(row)} />
            </Item>
            <br />
            <Item style={{ textAlign: 'center', verticalAlign: 'middle' }}> 
              {row.description || '-'}
            </Item>
          </React.Fragment>
        ));
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  };

  const drawSelfCheckResult = () => {
    let value = true;
    if(dataState.medical_result) {
      if(dataState.medical_result.medical_interview) {
        let data = dataState.medical_result.medical_interview;
        let beforeSymptom;
        let flag = true;
        return data.map((row, index) => {
          if(!beforeSymptom || beforeSymptom !== row.symptom) {
            flag = true;
            beforeSymptom = row.symptom;
          } else {
           flag = false;
          }
          return <React.Fragment key={index}>
            {flag
              ?
              <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                {row.symptom}
              </div>
              :
              null
            }
            <span style={{ width: '10px', height: '10px' }} >
              <img src={questionIcon} />
            </span>
            <span style={{ marginLeft: '5px' }}>
              {row.question}
            </span>
            <br/>
            <div style={{ marginLeft: '35px' }}>
              {row.value
                ?
                <Radio.Group value={value}>
                  <Space direction="horizontal">
                    <Radio value={true}>예</Radio>
                    <Radio value={false}>아니오</Radio>
                  </Space>
                </Radio.Group>
                :
                <Radio.Group value={value}>
                  <Space direction="horizontal">
                    <Radio value={false}>예</Radio>
                    <Radio value={true}>아니오</Radio>
                  </Space>
                </Radio.Group>
              }
            </div>
            <br/>
            <br/>
          </React.Fragment>
        });
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  const drawSelfCheckResult2 = () => {
    if(dataState.medical_result.self_test) {
      return dataState.medical_result.self_test.map((row, index) => {
        return <React.Fragment key={index}>
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {row.question}
          </div>
          <div style={{ marginLeft: '15px', marginTop: '2px' }}>
            {getFilteredSelfTest_ref(row.value, index)}
          </div>
          <br />
        </React.Fragment>
      });
    } else {
      return '-';
    }
  };

  return (
    <>
      <Descriptions
        title="현재 상태 보고서"
        bordered
        style={reportTitleStyle}
      >
        <Item label="이름">
          {dataState.nm || '-'}
        </Item>
        <Item label="생년월일">
          {dataState.birth_dt || '-'}
        </Item>
        <Item label="성별">
          {gender_ref[dataState.gender] || '-'}
        </Item>
        <Item label="프로그램명" >
          {dataState.program_type_nm || '-'}
        </Item>
        <Item label="고객작성일">
          {getFormattedDate(dataState.reg_dt, 'opt1') || '-'}
        </Item>
        <Item label="운동시작일">
          {getFormattedDate(dataState.start_dt, 'opt1') || '-'}
        </Item>
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="1. 불편감 정도"
        bordered
        layout="vertical"
        style={titleStyle}
      >
        <Item label="통증">
          {dataState.pain || '-'}
        </Item>
        <Item label="동작범위">
          {dataState.range || '-'}
        </Item>
        <Item label="불편감">
          {dataState.uncomfortable || '-'}
        </Item>
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="2. 증상에 따른 가능 질환 위험성"
        bordered
        style={titleStyle}
      >
        {drawChater2()}
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="3. 생활 문진 및 자가체크 결과"
        style={titleStyle}
      >
      </Descriptions>
      <Card>
        {drawSelfCheckResult()}
        {drawSelfCheckResult2()}
      </Card>
      <br />
      <br />
      <Descriptions
        title="4. 인공지능 자세진단 평가"
        bordered
        layout="vertical"
        style={titleStyle}
      >
        {dataState.image_url_front && (
            <Item label="정면사진">
              <img src={dataState.image_url_front} alt={dataState.image_url_front} style={{ width: '100%', height: '335px'}} 
              onError={handleErrorImage}
              onContextMenu={(e) => e.preventDefault()} />
            </Item>
        )}
        {dataState.image_url_side && (
          <Item label="측면사진">
            <img src={dataState.image_url_side} alt={dataState.image_url_side} style={{ width: '100%', height: '335px'}} />
          </Item>
        )}
      </Descriptions>
      <br />
      <br />
      <Card style={{ backgroundColor: '#EBF5FF' }}>
        <div>
          <Descriptions layout="horizontal">
            <Item style={{ width: '275px', height: '55px', paddingLeft: 15, marginBottom: 15, textAlign: 'left', verticalAlign: 'middle', display: 'inline-block' }}>
              <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                머리위치
                <br/>
                {dataState.bbiddaki.scores[1].score}점
              </div>
            </Item>
            <Item style={{ verticalAlign: 'middle', display: 'inline-block' }}>
              <progress value={dataState.bbiddaki.scores[1].score} max={100} style={{ width: '350px', height: '30px' }}></progress>
            </Item>
          </Descriptions>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[0].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[0].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[0].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[1].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[1].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[1].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[2].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[2].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[2].value)}&#176;{')'}
            </div>
          </Card>
        </div>
        <br/>
        <div>
          <Descriptions layout="horizontal">
            <Item style={{ width: '275px', height: '55px', paddingLeft: 15, marginBottom: 15, textAlign: 'left', verticalAlign: 'middle', display: 'inline-block' }}>
              <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
                상체정렬
                <br/>
                {dataState.bbiddaki.scores[2].score}점
              </div>
            </Item>
            <Item style={{ verticalAlign: 'middle', display: 'inline-block' }}>
              <progress value={dataState.bbiddaki.scores[2].score} max={100} style={{ width: '350px', height: '30px' }}></progress>
            </Item>
          </Descriptions>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[3].title}{' ]'} 
              </span>
              &nbsp;{getSplitBbiddakiResult_ref(dataState.bbiddaki.reports[3].result)}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[3].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[5].title}{' ]'} 
              </span>
              &nbsp;{getSplitBbiddakiResult_ref(dataState.bbiddaki.reports[5].result)}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[5].value)}&#176;{')'}
            </div>
          </Card>
        </div>
        <br/>
        <div>
          <Descriptions layout="horizontal">
            <Item style={{ width: '275px', height: '55px', paddingLeft: 15, marginBottom: 15, textAlign: 'left', verticalAlign: 'middle', display: 'inline-block' }}>
            <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
              하체정렬
              <br/>
              {dataState.bbiddaki.scores[3].score}점
            </div>
            </Item>
            <Item style={{ verticalAlign: 'middle', display: 'inline-block' }}>
              <progress value={dataState.bbiddaki.scores[3].score} max={100} style={{ width: '350px', height: '30px' }}></progress>
            </Item>
          </Descriptions>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[4].title}{' ]'} 
              </span>
              &nbsp;{getSplitBbiddakiResult_ref(dataState.bbiddaki.reports[4].result)}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[4].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[6].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[6].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[6].value)}&#176;{')'}
            </div>
          </Card>
          <br/>
          <Card style={{ borderRadius: '5px', color: '#FFFFFF', backgroundColor: '#007AFF', verticalAlign: 'middle' }}>
            <div style={{ textAlign: 'center', float: 'left', marginLeft: '150px', marginTop: '5px', verticalAlign: 'middle', fontSize: 16 }}>
              인공지능 자세진단
              <br/>
              평가 종합점수
            </div>
            <div style={{ textAlign: 'center', float: 'center', marginTop: '5px', fontWeight: 'bold', fontSize: '25px' }}>
              {dataState.bbiddaki.scores[0].score.toFixed(2) || '-'}점
            </div>
          </Card>
          <br/>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'left', display: 'inline-block' }}>
              {dataState.medical_result.medical_report && dataState.medical_result.medical_report.length > 0 && dataState.medical_result.medical_report.map((row, i) => {
                return <React.Fragment key={i}>
                  {'[ ' + row.nm + ' ]'}
                  <br />
                  {row.comment.split('.').map((line, index) => {
                    let val = row.comment;
                    let valArr = val.split('.');
                    let valLen = valArr.length;
                    if(valLen === index+1) {
                      if(valArr[index].trim() !== '') {
                        return <React.Fragment key={index}>{line}.</React.Fragment>
                      }
                    } else {
                      if(valLen === index+2) {
                        if(valArr[index+1].trim() === '') {
                          return <React.Fragment key={index}>{line}.</React.Fragment>
                        } else {
                          return <React.Fragment key={index}>{line}.<br /></React.Fragment>
                        }
                      } else {
                        return <React.Fragment key={index}>{line}.<br /></React.Fragment>
                      }
                    }
                  })}
                  <br />
                  <br />
                </React.Fragment>
              })}
            </div>
          </Card>
        </div>
      </Card>
      <br />
      <br />
      <Descriptions
        bordered
        style={titleStyle}
      >
        <Item label={'프로그램 추천'}  style={{ width: '275px', height: '55x', borderRadius: '5px' }}>
          {getProgramRecommend_ref(dataState.body_type_cd) || '-'}
        </Item>
      </Descriptions>
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default HealthyBodyNowReportTable;
