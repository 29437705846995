import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import HanaStepListTableContainer from 'containers/Services/HanaStep/HanaStepListTableContainer';
import { useCheckAuth } from 'lib/hooks';

const HanaStepPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['aaihc']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<HanaStepListTableContainer />}
    />
  );
};

export default HanaStepPage;
