 import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate, AppVersionAddTable } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { company_ref, product_id_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { getAppVersList } from 'controllers/Systems/appVersion';
import { Button, Drawer, Spin } from 'antd';
import AppVerDetailTableContainer from './AppVerDetailTableContainer';

const AppVersionTableContainer = () => {
  const [count, setCount] = useState(0);
  const [appVer, setAppVer] = useState([{}]);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });
  const [loading, setLoading] = useState(true);

  const onClose = () => {
  setStatus({
    visible: false,
    document_id: '',
  });
  };
  const showDrawer = (document_id) => {
  setStatus({
    visible: true,
    document_id,
  });
  };
  useEffect(() => {
    getAppVersList(setAppVer, setCount, setLoading);
  }, [])

  const columns = [
        {
          title: '',
          dataIndex: 'document_id',
          key: 'document_id',
          align: 'center',
          render: (document_id) => (
            <>
            <Button
              key={document_id}
              type="primary"
              size="small"
              onClick={() => showDrawer(document_id)}
            >
              관리
            </Button>
            </>
          ),
        },
        {
          title: '보험사',
          dataIndex: 'company_id',
          key: 'company_id',
          align: 'center',
          render: (data) => company_ref[data],
        },
        {
          title: '상품코드',
          dataIndex: 'product_id',
          key: 'product_id',
          align: 'center',
          render: (data) => product_id_ref[data],
        },
        {
          title: 'OS 구분',
          dataIndex: 'av_tp',
          key: 'av_tp',
          align: 'center',
        },
        {
          title: '최신버전',
          dataIndex: 'av_fnl',
          key: 'av_fnl',
          align: 'center',
        },
        {
          title: '업데이트기준 버전',
          dataIndex: 'av_enfr_updt',
          key: 'av_enfr_updt',
          align: 'center',
        },
        {
          title: '점검여부',
          dataIndex: 'av_dstrb_yn',
          key: 'av_dstrb_yn',
          align: 'center',
          render: (data) => (data ? 'Y' : 'N'),
        },
        {
          title: '등록일시',
          dataIndex: 'reg_dt',
          key: 'reg_dt',
          align: 'center',
          render: (date) => getFormattedDate(date, 'opt2'),
        },
        {
          title: '수정일시',
          dataIndex: 'mod_dt',
          key: 'mod_dt',
          align: 'center',
          render: (date) => getFormattedDate(date, 'opt2'),
        },
      ];

  return (
    <>
    <InfoPane
    boardListButton={(
      <LinkButton
        link={routes_ref.base.systems.app_version.path}
      />
    )}
    countLabel={(<CounterLabel count={count} />)}
    addButton={(<AppVersionAddTable />)}
    />
    <ListTableTemplate
        dataSource={appVer}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
        <Drawer
          width={750}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <AppVerDetailTableContainer
            document_id={status.document_id} />
        </Drawer>
      )}
      />
    </>
  );
};

export default AppVersionTableContainer;
