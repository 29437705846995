import React, { useEffect, useState } from 'react';
import { getHelperGroupDetail } from 'controllers/Outers/helperGroups';
import { HelperGroupsDetailTable } from 'components/Common';
import { Spin } from 'antd';

const HelperGroupsDetailTableContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [helperGroup, setHelperGroup] = useState({});
  useEffect(() => {
    if (document_id) {
      setHelperGroup({});
      setLoading(true);
      const data = getHelperGroupDetail(document_id);
      data.then((d) => {
        setHelperGroup(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <HelperGroupsDetailTable helperGroup={helperGroup} setHelperGroup={setHelperGroup} />
      </Spin>
    :
      <HelperGroupsDetailTable helperGroup={helperGroup} setHelperGroup={setHelperGroup} />
  );
};

export default HelperGroupsDetailTableContainer;
