import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import axios from 'axios';
import { baseUrl } from 'lib/url';
import getFormattedPersonalInfo from 'lib/getFormattedPersonalInfo';

/**
 * 디지털구강관리 조회
 */
export async function getDentalsList(
  setDentals,
  dentals,
  goPage,
  setCurrentPage,
  searchState,
  setLoading
) {
  try {
    const result = await axios
      .post(`${baseUrl}/health/dentals/getList`, {
        goPage: goPage,
        pagingState: dentals.pagingState,
        searchState: searchState,
      })
      .then((res) => {
        setDentals(res.data);
        setCurrentPage(1);
        setLoading(false);
      });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 디지털구강관리의 정보 조회
 * @param {string} document_id 특정 디지털구강관리의 document id
 */
export async function getDentalDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/health/dentals/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 디지털구강관리를 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios
      .post(`${baseUrl}/health/dentals/excel`, {
        searchState: searchState,
      })
      .then((res) => {
        return exportXlsx(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import dayjs from 'dayjs';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';
// import { subscribe_cancel_ref } from 'lib/dataRef';
// import getFormattedDate from 'lib/getFormattedDate';

// /**
//  * 디지털구강관리 조회
//  */
// export async function getDentalsList(
//   setDentals,
//   setCount,
//   subscribeGoods,
//   subscribeCancel,
//   startDate,
//   endDate,
//   setLoading
// ) {
//   if (subscribeGoods === undefined) subscribeGoods = 'all';
//   if (subscribeCancel === undefined) subscribeCancel = 'all';
//   // 구독서비스 시작이 20220607이라서 6월1일로 지정해둠
//   let start =
//     startDate !== undefined
//       ? dayjs(startDate).format('YYYYMMDDHHmmss')
//       : dayjs(
//           new Date('2022', '06'-1, '1')
//         ).format('YYYYMMDDHHmmss');
//   let end =
//     endDate !== undefined
//       ? dayjs(endDate).format('YYYYMMDDHHmmss')
//       : dayjs(new Date()).format('YYYYMMDDHHmmss');
//   try {
//     setLoading(true); // 검색시 로딩화면 보여주기 위해
//     if (subscribeGoods === 'all' && subscribeCancel === 'all') {
//       console.log('제휴사 전체, 처리상태 전체');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'desc')
//         .onSnapshot((snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'all' && subscribeCancel === 'false') {
//       console.log('제휴사 전체, 처리상태 신청');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_cancel_yn', '==', false)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'all' && subscribeCancel === 'true') {
//       console.log('제휴사 전체, 처리상태 취소');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_cancel_yn', '==', true)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'A' && subscribeCancel === 'all') {
//       console.log('제휴사 벤처스랩, 처리상태 전체');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '1')
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'A' && subscribeCancel === 'false') {
//       console.log('제휴사 벤처스랩, 처리상태 신청');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '1')
//         .where('subscribe_cancel_yn', '==', false)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'A' && subscribeCancel === 'true') {
//       console.log('제휴사 벤처스랩, 처리상태 취소');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '1')
//         .where('subscribe_cancel_yn', '==', true)
//         .where('subscribe_cancel_dt', '>', start)
//         .where('subscribe_cancel_dt', '<', end)
//         .orderBy('subscribe_cancel_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'B' && subscribeCancel === 'all') {
//       console.log('제휴사 키튼플래닛, 처리상태 전체');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '2')
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'B' && subscribeCancel === 'false') {
//       console.log('제휴사 키튼플래닛, 처리상태 신청');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '2')
//         .where('subscribe_cancel_yn', '==', false)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     } else if (subscribeGoods === 'B' && subscribeCancel === 'true') {
//       console.log('제휴사 키튼플래닛, 처리상태 취소');
//       const dentalsRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '2')
//         .where('subscribe_cancel_yn', '==', true)
//         .where('subscribe_cancel_dt', '>', start)
//         .where('subscribe_cancel_dt', '<', end)
//         .orderBy('subscribe_cancel_dt', 'asc')
//         .onSnapshot(async (snap) => {
//           const dentalDocs = [];
//           let memberBirthDt = '';
//           const changes = snap.docChanges();
//           let listCnt = changes.length;

//           if (listCnt > 0) {
//             changes.forEach((change) => {
//               let startDate;
//               if (change.doc.data().subscribe_start_dt)
//                 startDate = getFormattedDate(
//                   to_date(change.doc.data().subscribe_start_dt),
//                   'opt5'
//                 );
//               let memberId = change.doc.data().member_id;
//               firestore
//                 .collection('cust_MEMBERS')
//                 .where('id', '==', memberId)
//                 .get()
//                 .then((members) => {
//                   memberBirthDt = members.docs[0].data().birth_dt;
//                   dentalDocs.push({
//                     no: dentalDocs.length + 2 - 1,
//                     category1: change.doc.data().category1,
//                     category2: change.doc.data().category2,
//                     crm_nm: change.doc.data().crm_nm,
//                     crm_phone: change.doc.data().crm_phone,
//                     member_addr: change.doc.data().member_addr,
//                     member_addr_detail: change.doc.data().member_addr_detail,
//                     member_doc_id: change.doc.data().member_doc_id,
//                     member_id: change.doc.data().member_id,
//                     member_nm: change.doc.data().member_nm,
//                     member_ph: change.doc.data().member_ph,
//                     member_post_num: change.doc.data().member_post_num,
//                     member_birth_dt: memberBirthDt,
//                     subscribe_brush_color:
//                       change.doc.data().subscribe_brush_color,
//                     subscribe_brush_type:
//                       change.doc.data().subscribe_brush_type,
//                     subscribe_cancel_dt: change.doc.data().subscribe_cancel_dt,
//                     subscribe_cancel_yn: change.doc.data().subscribe_cancel_yn,
//                     subscribe_day_cancel_yn:
//                       change.doc.data().subscribe_day_cancel_yn,
//                     subscribe_doc_id: change.doc.data().subscribe_doc_id,
//                     subscribe_goods_cd: change.doc.data().subscribe_goods_cd,
//                     subscribe_goods_nm: change.doc.data().subscribe_goods_nm,
//                     subscribe_service_cnt:
//                       change.doc.data().subscribe_service_cnt,
//                     subscribe_start_dt: startDate,
//                     subscribe_state: change.doc.data().subscribe_state,
//                   });
//                   if (listCnt === dentalDocs.length) {
//                     setDentals(dentalDocs);
//                     setCount(dentalDocs.length);
//                     setLoading(false);
//                   }
//                 });
//             });
//           } else {
//             setDentals([]);
//             setCount(0);
//             setLoading(false);
//           }
//         });
//       return dentalsRef;
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 디지털구강관리를 엑셀로 추출
//  */
// export async function makeXlsx(
//   subscribeGoods,
//   subscribeCancel,
//   startDate,
//   endDate
// ) {
//   try {
//     const fileType =
//       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const dentalList = [];
//     const dentalDocs = [];
//     if (subscribeGoods === undefined) subscribeGoods = 'all';
//     if (subscribeCancel === undefined) subscribeCancel = 'all';
//     let start =
//       startDate !== undefined
//         ? dayjs(startDate).format('YYYYMMDDHHmmss')
//         : dayjs(
//             new Date(new Date().getFullYear(), new Date().getMonth(), '1')
//           ).format('YYYYMMDDHHmmss');
//     let end =
//       endDate !== undefined
//         ? dayjs(endDate).format('YYYYMMDDHHmmss')
//         : dayjs(new Date()).format('YYYYMMDDHHmmss');

//     if (subscribeGoods === 'all' && subscribeCancel === 'all') {
//       console.log('제휴사 전체, 처리상태 전체');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'all' && subscribeCancel === 'false') {
//       console.log('제휴사 전체, 처리상태 신청');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_cancel_yn', '==', false)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'all' && subscribeCancel === 'true') {
//       console.log('제휴사 전체, 처리상태 취소');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_cancel_yn', '==', true)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'A' && subscribeCancel === 'all') {
//       console.log('제휴사 벤처스랩, 처리상태 전체');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '1')
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'A' && subscribeCancel === 'false') {
//       console.log('제휴사 벤처스랩, 처리상태 신청');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '1')
//         .where('subscribe_cancel_yn', '==', false)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'A' && subscribeCancel === 'true') {
//       console.log('제휴사 벤처스랩, 처리상태 취소');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '1')
//         .where('subscribe_cancel_yn', '==', true)
//         .where('subscribe_cancel_dt', '>', start)
//         .where('subscribe_cancel_dt', '<', end)
//         .orderBy('subscribe_cancel_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'B' && subscribeCancel === 'all') {
//       console.log('제휴사 키튼플래닛, 처리상태 전체');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '2')
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'B' && subscribeCancel === 'false') {
//       console.log('제휴사 키튼플래닛, 처리상태 신청');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '2')
//         .where('subscribe_cancel_yn', '==', false)
//         .where('subscribe_start_dt', '>', start)
//         .where('subscribe_start_dt', '<', end)
//         .orderBy('subscribe_start_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     } else if (subscribeGoods === 'B' && subscribeCancel === 'true') {
//       console.log('제휴사 키튼플래닛, 처리상태 취소');
//       const dentalsExcelRef = await firestore
//         .collection('cust_SUBSCRIBE')
//         .where('subscribe_goods_cd', '==', '2')
//         .where('subscribe_cancel_yn', '==', true)
//         .where('subscribe_cancel_dt', '>', start)
//         .where('subscribe_cancel_dt', '<', end)
//         .orderBy('subscribe_cancel_dt', 'asc')
//         .get();

//       const dentalCnt = dentalsExcelRef.docs.length;
//       let memberBirthDt = '';
//       dentalsExcelRef.docs.forEach((doc) => {
//         let startDate;
//         if (doc.data().subscribe_start_dt)
//           startDate = getFormattedDate(
//             to_date(doc.data().subscribe_start_dt),
//             'opt5'
//           );
//         const subscribe = subscribe_cancel_ref[doc.data().subscribe_cancel_yn];
//         let memberId = doc.data().member_id;
//         firestore
//           .collection('cust_MEMBERS')
//           .where('id', '==', memberId)
//           .get()
//           .then(async (members) => {
//             memberBirthDt = members.docs[0].data().birth_dt;
//             dentalDocs.push({
//               제휴사: doc.data().subscribe_goods_nm,
//               이름: doc.data().member_nm,
//               생년월일: memberBirthDt,
//               연락처: doc.data().member_ph,
//               처리상태: subscribe,
//               일시: startDate,
//             });
//             if (dentalCnt === dentalDocs.length) {
//               const exportXlsx = (values) => {
//                 const fileName =
//                   'AAIHC_dentals_' + Math.floor(new Date().getTime() / 1000);
//                 const workSheet = XLSX.utils.json_to_sheet(values);
//                 const workBook = {
//                   Sheets: { data: workSheet },
//                   SheetNames: ['data'],
//                 };
//                 const excelBuffer = XLSX.write(workBook, {
//                   bookType: 'xlsx',
//                   type: 'array',
//                 });
//                 const blob = new Blob([excelBuffer], { type: fileType });
//                 FileSaver.saveAs(blob, fileName, fileExtension);
//               };
//               return exportXlsx(dentalDocs);
//             }
//           });
//       });
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 날짜 String to Date
//  * @param {*} date_str
//  * @returns
//  */
// function to_date(date_str) {
//   var yyyyMMdd = String(date_str);
//   var sYear = yyyyMMdd.substring(0, 4);
//   var sMonth = yyyyMMdd.substring(4, 6);
//   var sDate = yyyyMMdd.substring(6, 8);
//   var sHour = yyyyMMdd.substring(8, 10);
//   var sMinute = yyyyMMdd.substring(10, 12);
//   var sSecond = yyyyMMdd.substring(12, 14);

//   return new Date(
//     Number(sYear),
//     Number(sMonth) - 1,
//     Number(sDate),
//     Number(sHour),
//     Number(sMinute),
//     Number(sSecond)
//   );
// }
