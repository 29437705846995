import React, { useState, useEffect } from 'react';
import { getAdminDetail } from 'controllers/Services/admins';
import { AdminDetailTable } from 'components/Common';
import { Spin } from 'antd';

const AdminDetailTableContainer = ({ document_id }) => {
  const initialState = {
    id: '',
    pwd: '',
    nm: '',
    birth_dt: '',
    phone: '',
    reg_dt: 0,
    mod_dt: 0,
    block_yn: false,
    block_dt: 0,
    last_logged_dt: 0,
    pwd_mod_dt: 0,
    permissions: {
      common: [],
      admins: [],
      members: [],
      services: [],
      contents: [],
      outers: [],
      healthLogs: [],
    },
    department: '',
    other_admin_sites: {
      db: {
        id: '',
        pwd: '',
      },
      ss: {
        id: '',
        pwd: '',
      },
    },
  }
  const [adminDetail, setAdminDetail] = useState(initialState);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (document_id) {
      setAdminDetail(initialState);
      setLoading(true);
      const data = getAdminDetail(document_id);
      data.then((d) => {
        setAdminDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <AdminDetailTable adminDetail={adminDetail} setAdminDetail={setAdminDetail} />
      </Spin>
    :
      <AdminDetailTable
        adminDetail={adminDetail}
        setAdminDetail={setAdminDetail} />
  );
};

export default AdminDetailTableContainer;
