import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 공지사항의 전체 정보 조회
 */
export async function getNoticeList(setNotice, setCount, searchState, setLoading) {
  try {
    const result = await axios.post(
     `${baseUrl}/notice/getList`,{
     searchState: searchState
    }).then((res) => {
      setNotice(res.data.lists);
      setCount(res.data.count);
      setLoading(false);
    });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 공지사항의 정보 조회
 * @param {string} document_id 조회하고자 하는 행사정보의 document id
 */
export async function getNoticeDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/notice/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);
    
    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 공지사항의 정보 수정
 * @param {object} notice 수정하고자 하는 행사정보의 정보 및 수정 내용
 */
export async function editNoticeDetail(notice) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/notice/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        notice
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 새로운 공지사항 등록
 * @param {object} notice 등록하고자 하는 공지사항의 정보
 */
export async function postNotice(notice) {
  try {
    // 키가 contents 혹은 title이 아니면서, 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    // Object.keys(notice).forEach((v) => {
    //   if ((v !== 'contents' && v !== 'title' && v !== 'place' && v !== 'url' && v !== 'event_start_dt' && v !== 'event_end_dt')
    //     || (notice[v] === undefined || notice[v].length === 0)) {
    //     delete notice[v];
    //   }
    // });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/notice/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        notice
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 공지사항 삭제
 * @param {object} notice 삭제하고자 하는 공지사항의 정보
 */
export async function deleteNotice(notice) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/notice/delete`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        notice
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}