import React, { useEffect, useState } from 'react';
import { DatePicker, Descriptions, Divider, Drawer, Input, Radio, Row, Select, Space } from 'antd';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import Text from 'antd/es/typography/Text';
import { company_ref, helpergroup_type_ref } from 'lib/dataRef';
import { getHelperGroupsList, insertHelper } from 'controllers/Outers/helpers';
import { routes_ref } from 'lib/routeRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import dayjs from 'dayjs';

const { Item } = Descriptions;
const { Option } = Select;
const HelperAddTable = () => {
  const initialState = {
    company_id: '',
    product_id: 'SamsungLife01_0000',
    helpergroup_doc_id: '',
    helpergroup_nm: '',
    helpergroup_type: '',
    nm: '',
    phone: '',
    birth_dt: '',
    local_yn: undefined,
  };
  const [helpers, setHelpers] = useState(initialState);
  const [helpergroup_list, setHelpergroup_list] = useState([]);
  const [drawerStatus, setDrawerStatus] = useState({
    visible: false,
  });
  useEffect(() => {
    if (helpers.helpergroup_type) {
      getHelperGroupsList(helpers.helpergroup_type).then((d) => {
        setHelpergroup_list(d);
      });
    }
  }, [helpers.helpergroup_type]);

  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setHelpers(initialState);
  };

  const handleChangeHelperGroupType = (changedType) => {
    setHelpers({
      ...helpers,
      helpergroup_doc_id: '',
      helpergroup_nm: '',
      helpergroup_type: changedType,
    });
  };
  const handleChangeCompanyId = (company_id) => {
    setHelpers({
      ...helpers,
      company_id,
    });
  };

  const handleChangeLocal = (e) => {
    setHelpers({
      ...helpers,
      local_yn: e.target.value,
    });
  };

  const handleChangeBirth = (date, dateStr) => {
    setHelpers({
      ...helpers,
      birth_dt: dateStr,
    });
  };

  const handleChangeHelperGroupName = (doc_id) => {
    const selected = helpergroup_list.filter((helperGroup) => helperGroup.helpergroup_doc_id === doc_id);
    setHelpers({
      ...helpers,
      helpergroup_doc_id: selected[0].helpergroup_doc_id,
      helpergroup_nm: selected[0].helpergroup_nm,
      helpergroup_type: selected[0].helpergroup_type,
    });
  };
  const helpergroupListOptions = helpergroup_list.map((group) => (
    <Option
      key={group.helpergroup_doc_id}
    >
      {group.helpergroup_nm}
    </Option>
  ));

  return (
    <>
      <AddButton  handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
        <Descriptions
          title="간병인/가사도우미 추가"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="구분">
            <Select
              style={{ width: '150px' }}
              onChange={handleChangeHelperGroupType}
              value={helpers.helpergroup_type}
            >
              <Option value="carerSupport">{helpergroup_type_ref.carerSupport}</Option>
              <Option value="housekeeper">{helpergroup_type_ref.housekeeper}</Option>
              <Option value="rehabilitationExercise">{helpergroup_type_ref.rehabilitationExercise}</Option>
              <Option value="companion">{helpergroup_type_ref.companion}</Option>
            </Select>
          </Item>
          <Item label="보험사">
            <Select
              style={{ width: '150px' }}
              onChange={handleChangeCompanyId}
              value={helpers.company_id}
            >
              {getOptionsByRefId(company_ref)}
            </Select>
          </Item>
          <Item
            label="업체명"
          >
            <Select
              style={{ width: '150px' }}
              onChange={handleChangeHelperGroupName}
              value={helpers.helpergroup_nm}
            >
              {helpergroupListOptions}
            </Select>
          </Item>
          <Item
            label="이름"
          >
            <Input
              value={helpers.nm}
              onChange={(e) => {
                setHelpers({
                  ...helpers,
                  nm: e.target.value,
                });
              }}
            />
          </Item>
          <Item
            label="핸드폰번호"
          >
            <Input
              value={helpers.phone}
              onChange={(e) => {
                setHelpers({
                  ...helpers,
                  phone: e.target.value,
                });
              }}
            />
          </Item>
          <Item
            label="생년월일"
          >
            <div
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {
                helpers.birth_dt ? <></>
                  : <Text disabled>* 연도를 클릭하시면 더 넓은 범위가 나타납니다</Text>
              }
              <DatePicker
                format="YYYYMMDD"
                onChange={handleChangeBirth}
              />
            </div>
          </Item>
          <Item
            label="내외국인"
          >
            <Radio.Group
              onChange={handleChangeLocal}
              value={helpers.local_yn}
            >
              <Radio value>내국인</Radio>
              <Radio value={false}>외국인</Radio>
            </Radio.Group>
          </Item>
        </Descriptions>
        <Divider />
        <Row>
          <Space>
            <CancelButton
              location={routes_ref.base.outers.helper.path}
            />
            <SaveButton
              func={insertHelper}
              data={helpers}
              location={routes_ref.base.outers.helper.path}
            />
          </Space>
        </Row>
      </Drawer>
    </>
  );
};

export default HelperAddTable;
