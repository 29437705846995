import React, { useState } from 'react';
import { Button, DatePicker, Descriptions, Drawer, Input, Select, Space } from 'antd';
import Item from 'antd/es/list/Item';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import { company_ref, product_id_ref, symptoms_list_ref, symptoms_ref } from 'lib/dataRef';
import { checkMemberServiceDetail, insertCancerL } from 'controllers/HealthLogs/cancerLogs';

const CancerLogAddTable = () => {
  const [cancerLog, setCancerLog] = useState({
    company_id: '',
    product_id: '',
    crm_nm: '',
    crm_phone: '',
    category: '',
    member_id: '',
    member_doc_id: '',
    local_dt: '',
    symptoms: {},
    symptoms_etc: '',
  });
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setProcessStatus(initProcessStatus);
    setCancerCategoryRef(initCancerCateogryRef);
  };
  const initCancerCateogryRef = {};
  const [cancerCategoryRef, setCancerCategoryRef] = useState(initCancerCateogryRef);
  const [drawerStatus, setDrawerStatus] = useState({
    visible: false,
  });
  const initProcessStatus = {
    hasMemberChecked: false,
  };
  const [processStatus, setProcessStatus] = useState(initProcessStatus);

  const handleChangeCompanyId = (company_id) => {
    setCancerLog({
      ...cancerLog,
      company_id,
    });
    setCancerCategoryRef(initCancerCateogryRef);
    setProcessStatus(initProcessStatus);
  };
  function handleChangeProductId(product_id) {
    setCancerLog({
      ...cancerLog,
      product_id,
    });
  }
  function handleChangeCrmNm(e) {
    setCancerLog({
      ...cancerLog,
      crm_nm: e.target.value,
    });
    setProcessStatus(initProcessStatus);
    setCancerCategoryRef(initCancerCateogryRef);
  }

  function handleChangeCrmPhone(e) {
    setCancerLog({
      ...cancerLog,
      crm_phone: e.target.value,
    });
    setProcessStatus(initProcessStatus);
    setCancerCategoryRef(initCancerCateogryRef);
  }

  const symptoms_items = () => (Object.entries(cancerLog.symptoms).map((symptom) => {
    const [key, value] = symptom;
    const symptom_option = getOptionsByRefId(symptoms_ref);
    return (
      <Item
        key={key}
        label={key}
      >
        <Select
          style={{ width: '150px' }}
          onChange={(changedValue) => {
            const changedObj = {};
            changedObj[key] = Number(changedValue);
            setCancerLog({
              ...cancerLog,
              symptoms: { ...cancerLog.symptoms, ...changedObj },
            });
          }}
        >
          {symptom_option}
        </Select>
      </Item>
    );
  }));

  return (
    <>
      <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
        <Descriptions
          title="회원 정보 추가"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="보험사">
            <Select
              style={{ width: '100%' }}
              onChange={handleChangeCompanyId}
              value={cancerLog.company_id}
            >
              {getOptionsByRefId(company_ref)}
            </Select>
          </Item>
          <Item label="이용 서비스">
            <Select
              style={{ width: '100%' }}
              onChange={handleChangeProductId}
              value={cancerLog.product_id}
            >
              {getOptionsByRefId(product_id_ref)}
            </Select>
          </Item>
          <Item label="이름">
            <Input
              style={{ width: '150px' }}
              value={cancerLog.crm_nm}
              onChange={handleChangeCrmNm}
            />
          </Item>
          <Item label="연락처">
            <Input
              style={{ width: '150px' }}
              value={cancerLog.crm_phone}
              onChange={handleChangeCrmPhone}
            />
          </Item>
          <Item label="">
            <Button
              onClick={async () => {
                const { company_id, product_id, crm_nm, crm_phone } = cancerLog;
                try {
                  const res = await checkMemberServiceDetail(company_id, product_id, crm_nm, crm_phone);
                  setCancerCategoryRef(res.category);
                  setCancerLog({
                    ...cancerLog,
                    ...res.member,
                  });
                  setProcessStatus({ ...processStatus, hasMemberChecked: true });
                } catch (e) {
                  alert('처리가 비정상 종료되었습니다\n잠시후에 다시 시도 바랍니다');
                  setProcessStatus({ ...processStatus, hasMemberChecked: false });
                }
              }}
            >
              조회
            </Button>
          </Item>
          <Item label="구분">
            <Select
              style={{ width: '150px' }}
              value={cancerLog.category}
              disabled={!processStatus.hasMemberChecked}
              onChange={(category) => setCancerLog({
                ...cancerLog,
                category,
                symptoms: symptoms_list_ref[category],
              })}
            >
              {getOptionsByRefId(cancerCategoryRef)}
            </Select>
          </Item>
          {cancerLog.category ? (
            <>
              <Item label="날짜">
                <DatePicker
                  onChange={(moment) => {
                    setCancerLog({
                      ...cancerLog,
                      local_dt: moment.format('YYYY-MM-DDT00:00:00.000'),
                    });
                  }}
                />
              </Item>
              {symptoms_items()}
              <Item label="기타 증상">
                <Input
                  value={cancerLog.symptoms_etc}
                  onChange={(e) => { setCancerLog({ ...cancerLog, symptoms_etc: e.target.value }); }}
                />
              </Item>
            </>
          ) : null}
        </Descriptions>
        <Space>
          <CancelButton
            location="/cancer-logs"
          />
          <SaveButton
            func={insertCancerL}
            data={cancerLog}
            location="/cancer-logs"
          />
        </Space>
      </Drawer>
    </>
  );
};

export default CancerLogAddTable;
