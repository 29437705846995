import React, { useEffect, useState } from 'react';
import { Checkbox, Divider, Row } from 'antd';

import { routes_ref } from 'lib/routeRef';

const AdminPermissionCheckTable = ({ adminDetail, setAdminDetail }) => {
  const { admins, members, services, contents, outers, healthLogs, systems, chatbot } = routes_ref.base;
  const [check_array, setCheck_array] = useState({
    common: [],
    admins: [],
    members: [],
    services: [],
    contents: [],
    outers: [],
    healthLogs: [],
    systems: [],
    chatbot: []
  });
  useEffect(() => {
    const fetchData = async () => {
      const tmpObj = {
        common: [
          admins.default_label,
          members.default_label,
          services.default_label,
          contents.default_label,
          outers.default_label,
          healthLogs.default_label,
          systems.default_label,
          chatbot.default_label,
        ],
        admins: [],
        members: [],
        services: [],
        contents: [],
        outers: [],
        healthLogs: [],
        systems: [],
        chatbot: []
      };
      for await (const [key, value] of Object.entries(routes_ref.base)) {
        for await (const v of Object.values(value)) {
          if (v.hasOwnProperty('label') && v.label.label !== undefined) {
            tmpObj[key].push(v.label);
          }
        }
      }
      setCheck_array(tmpObj);
    };
    fetchData();
  }, []);

  const onChange = (checkedValue, optName) => {
    switch (optName) {
    case 'common_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          common: checkedValue,
        },
      });
      break;
    case 'admins_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          admins: checkedValue,
        },
      });
      break;
    case 'members_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          members: checkedValue,
        },
      });
      break;
    case 'services_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          services: checkedValue,
        },
      });
      break;
    case 'contents_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          contents: checkedValue,
        },
      });
      break;
    case 'outers_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          outers: checkedValue,
        },
      });
      break;
    case 'healthLogs_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          healthLogs: checkedValue,
        },
      });
      break;
    case 'systems_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          systems: checkedValue,
        },
      });
      break;
    case 'chatbot_opt':
      setAdminDetail({
        ...adminDetail,
        permissions: {
          ...adminDetail.permissions,
          chatbot: checkedValue,
        },
      });
    break;
    default: break;
    }
  };
  return (
    <>
      <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
        권한 목록
      </p>
      <Checkbox.Group
        value={adminDetail.permissions.common}
        options={check_array.common}
        onChange={(checkedValue) => onChange(checkedValue, 'common_opt')}
      />
      <Divider />
      {
        adminDetail.permissions.common.includes(100)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                관리자 관리 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.admins}
                  options={check_array.admins}
                  onChange={(checkedValue) => onChange(checkedValue, 'admins_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
      {
        adminDetail.permissions.common.includes(200)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                회원 관리 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.members}
                  options={check_array.members}
                  onChange={(checkedValue) => onChange(checkedValue, 'members_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
      {
        adminDetail.permissions.common.includes(300)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                서비스 관리 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.services}
                  options={check_array.services}
                  onChange={(checkedValue) => onChange(checkedValue, 'services_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
      {
        adminDetail.permissions.common.includes(400)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                컨텐츠 관리 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.contents}
                  options={check_array.contents}
                  onChange={(checkedValue) => onChange(checkedValue, 'contents_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
      {
        adminDetail.permissions.common.includes(500)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                외부관리자 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.outers}
                  options={check_array.outers}
                  onChange={(checkedValue) => onChange(checkedValue, 'outers_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
      {
        adminDetail.permissions.common.includes(600)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                건강관리 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.healthLogs}
                  options={check_array.healthLogs}
                  onChange={(checkedValue) => onChange(checkedValue, 'healthLogs_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
      {
        adminDetail.permissions.common.includes(800)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                시스템관리 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.systems}
                  options={check_array.systems}
                  onChange={(checkedValue) => onChange(checkedValue, 'systems_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
      {
        adminDetail.permissions.common.includes(900)
          ? (
            <>
              <p className="site-description-item-profile-p" style={{ marginBottom: 24 }}>
                챗봇 세부 목록
              </p>
              <Row>
                <Checkbox.Group
                  value={adminDetail.permissions.chatbot}
                  options={check_array.chatbot}
                  onChange={(checkedValue) => onChange(checkedValue, 'chatbot_opt')}
                />
              </Row>
              <Divider />
            </>
          )
          : <></>
      }
    </>
  );
};

export default AdminPermissionCheckTable;
