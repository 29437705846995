import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { idChange, pwChange, handleSubmit } from 'store/modules/login_reducer';
import LoginFormWithLabel from 'components/Login/LoginFormWithLabel';
import { getCookie } from 'lib/cookie';

const LoginContainer = () => {
  const { id, pw } = useSelector((state) => ({
    id: state.login.id,
    pw: state.login.pw,
  }));
  const dispatch = useDispatch();

  const onIdChange = (id) => dispatch(idChange(id));
  const onPwChange = (pw) => dispatch(pwChange(pw));
  const onSubmit = (id, pw) => dispatch(handleSubmit(id, pw));
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit(id, pw);
    }
  };

  useEffect(() => {
    if (getCookie('session')) {
      document.location.href = '/main';
    }
  }, []);

  return (
    <>
      <LoginFormWithLabel
        id={id}
        pw={pw}
        onIdChange={onIdChange}
        onPwChange={onPwChange}
        onSubmit={onSubmit}
        onKeyPress={onKeyPress}
      />
    </>
  );
};

export default LoginContainer;
