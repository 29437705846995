import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 행사 정보의 전체 정보 조회
 * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
 */
export async function getEventInfoList(setEventInfos, setCount, setLoading) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/content/eventInfos/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    setEventInfos(result.lists);
    setCount(result.count);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 행사정보의 정보 조회
 * @param {string} document_id 조회하고자 하는 행사정보의 document id
 */
export async function getEventInfoDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/content/eventInfos/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);
    
    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 행사정보의 정보 수정
 * @param {object} eventInfo 수정하고자 하는 행사정보의 정보 및 수정 내용
 */
export async function editEventInfoDetail(eventInfo) {
  try {
    eventInfo.mod_dt = Math.floor(new Date().getTime());
    if (eventInfo.del_yn === true) {
      eventInfo.del_dt = eventInfo.mod_dt;
    }
    // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(eventInfo).forEach((v) => {
      if (eventInfo[v] === undefined || eventInfo[v].length === 0) {
        delete eventInfo[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/content/eventInfos/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        eventInfo
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 새로운 행사정보 등록
 * @param {object} eventInfo 등록하고자 하는 행사정보의 정보
 */
export async function postEventInfo(eventInfo) {
  try {
    // 키가 contents 혹은 title이 아니면서, 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(eventInfo).forEach((v) => {
      if ((v !== 'contents' && v !== 'title' && v !== 'place' && v !== 'url' && v !== 'event_start_dt' && v !== 'event_end_dt')
        || (eventInfo[v] === undefined || eventInfo[v].length === 0)) {
        delete eventInfo[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/content/eventInfos/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        eventInfo
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 행사 정보의 건수 조회
//  */
// export async function getEventInfosSize() {
//   try {
//     const eisRef = await firestore.collection('main_POSTS')
//       .where('category', '==', 'eventInfo').get();
//     return eisRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 행사 정보의 전체 정보 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getEventInfoList(app_code) {
//   try {
//     const eisRef = await firestore.collection('main_POSTS')
//       .where('category', '==', 'eventInfo').orderBy('reg_dt', 'desc')
//       .get();
//     const eiDocs = [];
//     eisRef.docs.forEach((doc) => {
//       eiDocs.push({
//         category: doc.data().category,
//         company_id: doc.data().company_id,
//         product_id: doc.data().product_id,
//         title: doc.data().title,
//         reg_dt: doc.data().reg_dt,
//         document_id: doc.data().document_id,
//         mod_dt: doc.data().mod_dt,
//         del_yn: doc.data().del_yn,
//         del_dt: doc.data().del_dt,
//       });
//     });
//     return eiDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 행사정보의 정보 조회
//  * @param {string} document_id 조회하고자 하는 행사정보의 document id
//  */
// export async function getEventInfoDetail(document_id) {
//   try {
//     const eiRef = await firestore.collection('main_POSTS')
//       .doc(document_id).get();
//     return eiRef.data();
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 행사정보의 정보 수정
//  * @param {object} eventInfo 수정하고자 하는 행사정보의 정보 및 수정 내용
//  */
// export async function editEventInfoDetail(eventInfo) {
//   try {
//     eventInfo.mod_dt = Math.floor(new Date().getTime());
//     if (eventInfo.del_yn === true) {
//       eventInfo.del_dt = eventInfo.mod_dt;
//     }
//     // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//     Object.keys(eventInfo).forEach((v) => {
//       if (eventInfo[v] === undefined || eventInfo[v].length === 0) {
//         delete eventInfo[v];
//       }
//     });
//     await firestore.collection('main_POSTS').doc(eventInfo.document_id)
//       .set(eventInfo, { merge: true });
//     return { result: 'success', message: 'The post is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 새로운 행사정보 등록
//  * @param {object} eventInfo 등록하고자 하는 행사정보의 정보
//  */
// export async function postEventInfo(eventInfo) {
//   try {
//     // 키가 contents 혹은 title이 아니면서, 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//     Object.keys(eventInfo).forEach((v) => {
//       if ((v !== 'contents' && v !== 'title' && v !== 'place' && v !== 'url' && v !== 'event_start_dt' && v !== 'event_end_dt')
//         || (eventInfo[v] === undefined || eventInfo[v].length === 0)) {
//         delete eventInfo[v];
//       }
//     });
//     eventInfo.category = 'eventInfo';
//     eventInfo.company_id = 'N03';
//     eventInfo.product_id = 'LotteDodam01_4186';
//     eventInfo.del_yn = false;
//     eventInfo.reg_dt = Math.floor(new Date().getTime());
//     await firestore.collection('main_POSTS').add(eventInfo)
//       .then(async (doc) => {
//         await firestore.collection('main_POSTS').doc(doc.id)
//           .set({ document_id: doc.id }, { merge: true });
//       })
//       .catch((err) => {
//         console.error(err);
//       });
//     return { result: 'success', message: 'The post is inserted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }