import React, { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const DelButton = ({ func, data, location }) => {
  const [popState, setPopState] = useState({
    visible: false,
  });
  const confirm = async () => {
    setPopState({ visible: false });
    await func(data);
    await message.success('삭제되었습니다');
    // 개인정보접근 로그 저장
    const infoText = data.nm || data.crm_nm || data.member_nm || null;
    if(infoText) {
      insertInfoAcessLog(location, func.name, "삭제", undefined, infoText);
    }
    if (location) {
      document.location.href = location;
    }
  };

  const cancel = () => {
    setPopState({ visible: false });
    message.error('취소했습니다');
  };

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setPopState({ visible });
      return;
    }
    setPopState({ visible }); // show the popconfirm
  };

  return (
    <>
      <Popconfirm
        title="삭제하시겠습니까?"
        okText="삭제"
        cancelText="취소"
        open={popState.visible}
        onOpenChange={handleVisibleChange}
        onConfirm={confirm}
        onCancel={cancel}
      >
        <Button
          block
          danger
        >
          삭제하기
        </Button>
      </Popconfirm>
    </>
  );
};

export default DelButton;
