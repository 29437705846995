import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// 운영 DB (콘솔창 입력 : firebase use default)
const firebaseConfig = {
  apiKey: 'AIzaSyDjuMz-OslFbD_V7NBYrJyhiqLkP_2b3Og',
  authDomain: 'aaihealth-b209b.firebaseapp.com',
  databaseURL: 'https://aaihealth-b209b.firebaseio.com',
  projectId: 'aaihealth-b209b',
  storageBucket: 'aaihealth-b209b.appspot.com',
  messagingSenderId: '957190549809',
  appId: '1:957190549809:web:da4e1450ed798054e90010',
  measurementId: 'G-1D8MRS1JM9',
};

// 개발 DB (콘솔창 입력 : firebase use dev)
// const firebaseConfig = {
//   apiKey: "AIzaSyC8ts2ZR7FhC91c5zp5KipC8bbi3d6l3II",
//   authDomain: "aaihealthcare-backup.firebaseapp.com",
//   projectId: "aaihealthcare-backup",
//   storageBucket: "aaihealthcare-backup.appspot.com",
//   messagingSenderId: "273518895317",
//   appId: "1:273518895317:web:ed72e4175ec8289a02c25c",
//   measurementId: "G-FD0T7RWKJ8"
// };

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

export default firestore;
export const storage = firebase.storage();
