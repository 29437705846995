import React, { useEffect, useState } from 'react';
import { getChatHealthTypeDetail } from 'controllers/chatbot/chatbotHealthType';
import { ChatHealthTypeDetailTable } from 'components/Common';
import { Spin } from 'antd';

const ChatHealthTypeDetailTableContainer = ({ s_id }) => {
  const [chatHlTypeDetail, setChatHlTypeDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (s_id) {
      setChatHlTypeDetail({});
      setLoading(true);
      const data = getChatHealthTypeDetail(s_id);
      data.then((d) => {
        setChatHlTypeDetail(d);
        setLoading(false);
      });
    }
  }, [s_id]);

  return loading ? (
    <Spin tip="Loading" size="large">
      <ChatHealthTypeDetailTable
        chatHlTypeDetail={chatHlTypeDetail}
        setChatHlTypeDetail={setChatHlTypeDetail}
      />
    </Spin>
  ) : (
    <ChatHealthTypeDetailTable
      chatHlTypeDetail={chatHlTypeDetail}
      setChatHlTypeDetail={setChatHlTypeDetail}
    />
  );
};

export default ChatHealthTypeDetailTableContainer;
