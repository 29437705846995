import React, { useState, useEffect } from 'react';
import { getBodyAgeDetail } from 'controllers/HealthLogs/bodyAges';
import { BodyAgeDetailTable } from 'components/Common';
import { Spin } from 'antd';

const BodyAgeDetailTableContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [bodyAgeDetail, setBodyAgeDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setLoading(true);
      setBodyAgeDetail({});
      const data = getBodyAgeDetail(document_id);
      data.then((d) => {
        setBodyAgeDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <BodyAgeDetailTable bodyAgeDetail={bodyAgeDetail} setBodyAgeDetail={setBodyAgeDetail} />
      </Spin>
    :
      <BodyAgeDetailTable bodyAgeDetail={bodyAgeDetail} setBodyAgeDetail={setBodyAgeDetail} />
  );
};

export default BodyAgeDetailTableContainer;
