import React from 'react';
import { Row, Descriptions, Input, Divider, Space } from 'antd';
import { CancelButton, SaveButton, DelButton } from 'components/Common';
import { editFaqDetail, deleteFaq } from 'controllers/Contents/faq';
import { company_ref, product_id_ref } from 'lib/dataRef';

const { Item } = Descriptions;
const FaqDetailTable = (props) => {
  const { faqDetail, setFaqDetail } = props;
  
  return (
    <>
      <Row>
        <Descriptions
          title="세부 사항"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="보험사">
          {company_ref[faqDetail.company_id] || '-'}
          </Item>
          <Item label="앱 명칭">
             {product_id_ref[faqDetail.product_id] || '-'}
            </Item>
          <Item label="제목">
            <Input.TextArea
              value={faqDetail.subject}
              rows={8}
              allowClear
              onChange={(e) => setFaqDetail({ ...faqDetail, subject: e.target.value })}
            />
          </Item>
          <Item label="내용">
          <Input.TextArea
              value={faqDetail.content}
              rows={8}
              allowClear
              onChange={(e) => setFaqDetail({ ...faqDetail, content: e.target.value })}
            />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/faq"
          />
          <SaveButton
            func={editFaqDetail}
            data={faqDetail}
            location="/faq"
          />
          <DelButton
            func={deleteFaq}
            data={faqDetail}
            location="/faq"
          />
        </Space>
      </Row>
    </>
  );
};

export default FaqDetailTable;
