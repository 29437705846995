import React, { useState, useEffect } from 'react';
import { getEventInfoDetail } from 'controllers/Contents/eventInfos';
import { EventInfosDetailTable } from 'components/Common';
import { Spin } from 'antd';

const PostEventInfosDetailContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [eventInfoDetail, setEventInfoDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setEventInfoDetail({});
      setLoading(true);
      const data = getEventInfoDetail(document_id);
      data.then((d) => {
        setEventInfoDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <EventInfosDetailTable eventInfoDetail={eventInfoDetail} setEventInfoDetail={setEventInfoDetail} />
      </Spin>
    :
      <EventInfosDetailTable eventInfoDetail={eventInfoDetail} setEventInfoDetail={setEventInfoDetail} />
  );
};

export default PostEventInfosDetailContainer;
