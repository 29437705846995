import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 고객 계정 정보 조회
 * @param {*} setMembers client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 */
export async function getMembersList(setMembers, members, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const res = await axios.post(
      `${baseUrl}/members/getList`, {
        goPage: goPage,
        pagingState: members.pagingState,
        searchState: searchState
      }).then((res) => {
        setMembers(res.data);
        setCurrentPage(1);  // 콜할때 100개씩만 가져오고 1페이지 리턴(1, 11, 21페이지)
        setLoading(false);
      }).catch((err) =>
        console.error(err)
      );

      return res;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 고객 추가(삼성)
 * @param {*} member
 */
export async function insertMember(member) {
  try {
    const res = await axios.post(
      `${baseUrl}/member/new`, {
        companyCode: member.company_id,
        memberName: member.nm,
        memberMobile: member.phone,
        devMode: process.env.NODE_ENV === 'development',
      }).then(async (res) => {
        if (res.ok) {
          const json = await res.json();
          alert('성공적으로 저장되었습니다');
        } else {
          // 여기 손봐야함
          let msg;
          switch (res.status) {
          case 400: msg = '이미 가입되어있는 회원입니다'; break;
          case 404: msg = 'CRM 에서 조회되지 않는 회원입니다\n기입 정보를 다시 확인해주세요'; break;
          case 500:
          default: msg = '알 수 없는 CRM 에러입니다\n잠시 후에 다시 시도바랍니다'; break;
          }
          alert(msg);
        }
      }).catch((err) =>
        console.error(err)
      );

      return res;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 고객 계정 정보 조회
 * @param {string} document_id 특정 계정의 document id
 */
export async function getMemberDetail(document_id) {
  try {
    return await axios.get(
      `${baseUrl}/members/getDetail/${document_id}`).then((res) => {
        return res.data;
      }).catch((err) =>
        console.error(err)
      );

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 고객 계정의 정보 수정
 * @param {object} member 수정하고자 하는 계정의 정보 및 수정 내용
 */
export async function editMemberDetail(member) {
  try {
    return await axios.post(
      `${baseUrl}/members/update`, {
         document_id: member.document_id,
         company_id: member.company_id,
         product_id: member.product_id,
         type: member.type,
         id: member.id,
         nm: member.nm,
         birth_dt: member.birth_dt,
         gender: member.gender,
         phone: member.phone,
         height: member.height,
         weight: member.weight,
         diseases: member.diseases,
         weight_loss_yn: member.weight_loss_yn,
         smoking_yn: member.smoking_yn,
         drinking_yn: member.drinking_yn,
         meals_per_day: member.meals_per_day,
         daily_walking_mins: member.daily_walking_mins,
         del_yn: member.del_yn,
         children: member.children,
         admin_memo: member.admin_memo
        }).then((res) => {
          return { result: 'success', message: 'The member information is updated.' };
      }).catch((err) => {
        return { result: 'fail' };
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 계정에 새로운 임시 비밀번호 발급
 * @param {object} member 특정 계정의 정보
 */
export async function makeNewPwd(member) {
  try {
    return await axios.post(
      `${baseUrl}/members/changePwd`, {
        document_id: member.document_id,
        nm: member.nm,
        phone: member.phone,
        product_id: member.product_id
      }).then((res) => {
        alert("비밀번호가 초기화되었습니다.");
      }).catch((err) =>
        console.error(err)
      );
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 전체 고객 계정 정보를 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if(!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName = 'AAIHC_members_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };
    console.log('Writing')

    return await axios.post(
      `${baseUrl}/members/excel`,{
        searchState: searchState
      }).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import sha256 from 'crypto-js/sha256';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';
// import { applyType_ref, company_ref, product_id_ref } from 'lib/dataRef';

// /**
//  * 고객 계정 총 갯수 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getMembersSize(app_code) {
//   try {
//     const memsRef = await firestore.collection('cust_MEMBERS')
//       .orderBy('reg_dt', 'desc').get();
//     if (app_code !== undefined) {
//       let size = 0;
//       memsRef.docs.forEach((doc) => {
//         if (doc.data().product_id.includes(app_code)) {
//           size++;
//         }
//       });
//       return size;
//     } else {
//       return memsRef.size;
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 고객 계정 정보 조회
//  * @param {*} setMembers client에서 전달하는 React.Dispatch
//  * @param {*} setCount client에서 전달하는 React.Dispatch
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getMembersList(setMembers, direction, pagingState, setPagingState, app_code, searchState, setTotal) {
//   try {
//     // 컬렉션 내에서 변동이 있을 경우(새로운 서비스 신청 추가, 기존 서비스 신청 변동 등),
//     // 리로드 없이도 변동 사항을 반영하기 위해 onSnapshot 이용
//     // (onSnapshot에 대한 자세한 사항은 firebase documentation을 참고하길 바람) -> 230630 pagination때문에 get()으로 변경
//     const memberDB = firestore.collection('cust_MEMBERS');
//     let q = memberDB.orderBy('reg_dt', 'desc');

//     // 보험사 검색
//     if(searchState.searchCompany !== '' && searchState.searchCompany.length > 0){
//       q = q.where('company_id', '==', searchState.searchCompany)
//     }
//     // 핸드폰번호 검색
//     if(searchState.searchPhone !== '' && searchState.searchPhone.length > 0){
//       q = q.where('phone', '==', searchState.searchPhone)
//     }
//     // 이름 검색
//     if(searchState.searchName !== '' && searchState.searchName.length > 0){
//       q = q.where('nm', '==', searchState.searchName)
//     }
//     // 회원구분 검색
//     if(searchState.searchMsort !== '' && searchState.searchMsort.length > 0){
//       q = q.where('admin_msort', '==', searchState.searchMsort)
//     }

//     if(direction === 1){  // 다음버튼 클릭
//       q = q.startAfter(pagingState.next).limit(10)

//     }else if(direction === -1){ // 이전버튼 클릭
//       q = q.endBefore(pagingState.start).limitToLast(10)

//     }else if(direction > 1){  // 젤다음버튼 클릭
//       q = q.limitToLast(10)

//     }else{  // 젤이전버튼 클릭
//       // setTotal(Math.ceil((await q.get()).size / 10));  // 230630 기준 총회원수 22863 -> 속도 느려서 임의숫자 넣음
//       q = q.limit(10)

//     }

//     const memsRef = await q
//       .get().then((snap) => {
//         const memDocs = [];
//         const changes = snap.docChanges();

//         changes.forEach((change) => {
//           if (change.type === 'added') {
//             if (changes.length > 1) {

//               const product_id = String(change.doc.data().product_id).split('_');
//               memDocs.push({
//                 document_id: change.doc.data().document_id,
//                 company_id: company_ref[change.doc.data().company_id],
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 id: change.doc.data().id,
//                 nm: change.doc.data().nm,
//                 birth_dt: change.doc.data().birth_dt,
//                 phone: change.doc.data().phone,
//                 gender: change.doc.data().gender,
//                 type: change.doc.data().type,
//                 admin_msort: change.doc.data().admin_msort,
//                 reg_dt: change.doc.data().reg_dt,
//                 mod_dt: change.doc.data().mod_dt,
//                 del_yn: change.doc.data().del_yn,
//                 del_dt: change.doc.data().del_dt,
//                 last_logged_dt: change.doc.data().last_logged_dt,
//               });
//             } else {
//               snap.forEach((doc) => {
//                 const product_id = String(doc.data().product_id).split('_');
//                 memDocs.push({
//                   document_id: doc.data().document_id,
//                   company_id: company_ref[doc.data().company_id],
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: doc.data().product_id,
//                   id: doc.data().id,
//                   nm: doc.data().nm,
//                   birth_dt: doc.data().birth_dt,
//                   phone: doc.data().phone,
//                   gender: doc.data().gender,
//                   type: doc.data().type,
//                   admin_msort: doc.data().admin_msort,
//                   reg_dt: doc.data().reg_dt,
//                   mod_dt: doc.data().mod_dt,
//                   del_yn: doc.data().del_yn,
//                   del_dt: doc.data().del_dt,
//                   last_logged_dt: doc.data().last_logged_dt,
//                 });
//               });
//             }
//           }
//           if (change.type === 'modified') {
//             const product_id = String(change.doc.data().product_id).split('_');
//             memDocs.push({
//               document_id: change.doc.data().document_id,
//               company_id: company_ref[change.doc.data().company_id],
//               app_code: product_id[0],
//               goods_code: product_id[1],
//               product_id: change.doc.data().product_id,
//               id: change.doc.data().id,
//               nm: change.doc.data().nm,
//               birth_dt: change.doc.data().birth_dt,
//               phone: change.doc.data().phone,
//               gender: change.doc.data().gender,
//               type: change.doc.data().type,
//               admin_msort: change.doc.data().admin_msort,
//               reg_dt: change.doc.data().reg_dt,
//               mod_dt: change.doc.data().mod_dt,
//               del_yn: change.doc.data().del_yn,
//               del_dt: change.doc.data().del_dt,
//               last_logged_dt: change.doc.data().last_logged_dt,
//             });

//           }
//           if (change.type === 'removed') {
//             const product_id = String(change.doc.data().product_id).split('_');
//             memDocs.push({
//               document_id: change.doc.data().document_id,
//               company_id: company_ref[change.doc.data().company_id],
//               app_code: product_id[0],
//               goods_code: product_id[1],
//               product_id: change.doc.data().product_id,
//               id: change.doc.data().id,
//               nm: change.doc.data().nm,
//               birth_dt: change.doc.data().birth_dt,
//               phone: change.doc.data().phone,
//               gender: change.doc.data().gender,
//               type: change.doc.data().type,
//               admin_msort: change.doc.data().admin_msort,
//               reg_dt: change.doc.data().reg_dt,
//               mod_dt: change.doc.data().mod_dt,
//               del_yn: change.doc.data().del_yn,
//               del_dt: change.doc.data().del_dt,
//               last_logged_dt: change.doc.data().last_logged_dt,
//             });
//           }
//         });
//         setMembers(memDocs);
//         setPagingState({
//           start: memDocs[0].reg_dt,
//           next: memDocs[memDocs.length - 1].reg_dt,
//           page: direction === 0 ? 1 : pagingState.page + direction
//         });

//       });
//     return memsRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 고객 계정 정보 조회
//  * @param {string} document_id 특정 계정의 document id
//  */
// export async function getMemberDetail(document_id) {
//   try {
//     const memRef = await firestore.collection('cust_MEMBERS')
//       .doc(document_id).get();
//     const children = [];
//     // 조회한 계정의 보험사 코드가 롯데손보이거나 상품코드가 하나어린이보험일 경우, 자녀 collection 조회
//     if (memRef.data().company_id === 'N03' || memRef.data().product_id === 'HanaChild01_4397') {
//       const cdrsRef = await firestore.collection('cust_CHILDREN')
//         .where('parent_id', '==', document_id).get();
//       cdrsRef.docs.forEach((doc) => {
//         children.push({
//           document_id: doc.data().document_id,
//           birth_dt: doc.data().birth_dt,
//           est_due_dt: doc.data().est_due_dt,
//           bump_nm: doc.data().bump_nm,
//           child_yn: doc.data().child_yn || doc.data().is_child,
//           gender: doc.data().gender,
//           nm: doc.data().nm,
//           profile_url: doc.data().profile_url,
//           reg_dt: doc.data().reg_dt,
//           mod_dt: doc.data().mod_dt,
//           del_yn: doc.data().del_yn,
//           del_dt: doc.data().del_dt,
//         });
//       });
//     }

//     const product_id = String(memRef.data().product_id).split('_');
//     return {
//       document_id: memRef.data().document_id,
//       company_id: memRef.data().company_id,
//       app_code: product_id[0],
//       goods_code: product_id[1],
//       product_id: memRef.data().product_id,
//       type: memRef.data().type,
//       id: memRef.data().id,
//       nm: memRef.data().nm,
//       birth_dt: memRef.data().birth_dt,
//       gender: memRef.data().gender,
//       phone: memRef.data().phone,
//       cocoa_id: memRef.data().cocoa_id,
//       children,
//       last_steps: memRef.data().last_steps,
//       height: memRef.data().height,
//       weight: memRef.data().weight,
//       last_steps_str_dt: memRef.data().last_steps_str_dt,
//       steps_updated_at: memRef.data().steps_updated_at,
//       diseases: memRef.data().diseases,
//       weight_loss_yn: memRef.data().weight_loss_yn,
//       smoking_yn: memRef.data().smoking_yn,
//       drinking_yn: memRef.data().drinking_yn,
//       meals_per_day: memRef.data().meals_per_day,
//       daily_walking_mins: memRef.data().daily_walking_mins,
//       admin_memo: memRef.data().admin_memo,
//       admin_msort: memRef.data().admin_msort,
//       reg_dt: memRef.data().reg_dt,
//       mod_dt: memRef.data().mod_dt,
//       last_logged_dt: memRef.data().last_logged_dt,
//       pwd_mod_dt: memRef.data().pwd_mod_dt,
//       del_dt: memRef.data().del_dt,
//       del_yn: memRef.data().del_yn,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 고객 계정의 정보 수정
//  * @param {object} member 수정하고자 하는 계정의 정보 및 수정 내용
//  */
// export async function editMemberDetail(member) { // member === getMemberDetail function's return value
//   try {
//     member.mod_dt = Math.floor(new Date().getTime());
//     // 삭제 시각이 존재하는 경우, 실패를 리턴
//     if (member.del_dt > 0) {
//       if(member.admin_memo == undefined) {
//         member.admin_memo = '';
//       }
//       await firestore.collection('cust_MEMBERS').doc(member.document_id)
//         .set({ mod_dt: member.mod_dt, admin_memo: member.admin_memo }, { merge: true });
//       return { result: 'success', message: 'The member\'s admin_memo is updated.' };
//     }
//     delete member.pwd;
//     delete member.app_code;
//     delete member.goods_code;
//     member.mod_dt = Math.floor(new Date().getTime());
//     // 삭제 여부가 true 이면서 삭제 시각이 선언되지 않은 경우
//     if (member.del_yn && (member.del_dt === undefined || member.del_dt === 0)) {
//       member.del_dt = member.mod_dt;
//       // 자녀 정보가 존재하는 경우, 자녀 document에도 삭제 처리 진행
//       if (member.children !== undefined) {
//         member.children.forEach(async (child) => {
//           child.del_yn = true;
//           child.del_dt = member.del_dt;
//           child.mod_dt = member.del_dt;
//           await firestore.collection('cust_CHILDREN').doc(child.document_id)
//             .set(child, { merge: true });
//         });
//       }
//     }
//     delete member.children;
//     // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
//     Object.keys(member).forEach((v) => {
//       if (member[v] === undefined || member[v].length === 0) {
//         delete member[v];
//       }
//     });
//     await firestore.collection('cust_MEMBERS').doc(member.document_id)
//       .set(member, { merge: true });

//     return { result: 'success', message: 'The member information is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 계정에 새로운 임시 비밀번호 발급
//  * @param {object} member 특정 계정의 정보
//  */
// export async function makeNewPwd(member) { // member === getMemberDetail function's return value
//   try {
//     /**
//      * 비밀번호에 들어갈 수 있는 것들
//      */
//     const list = '!@23456789abcdefghijkmnpqrstuvwxyz';
//     /**
//      * 생성된 임시 비밀번호 (암호화 전)
//      */
//     let newPWD = '';
//     /**
//      * 생성된 임시 비밀번호에 들어간 숫자의 갯수
//      */
//     let checkN = 0;
//     /**
//      * 생성된 임시 비밀번호에 들어간 특수문자의 갯수
//      */
//     let checkC = 0;

//     // 숫자, 특수문자가 1번 이상씩 들어가도록 임시 비밀번호 설정
//     for (let i = 0; i < 8; i++) {
//       let num = Math.floor(Math.random() * list.length);
//       if (num === 0 || num === 1) {
//         checkC++;
//       } else if (num >= 2 && num <= 9) {
//         checkN++;
//       }
//       if (i === 0 && num <= 9) {
//         num += 15;
//       } else if (i === 6 && checkN === 0 && (num <= 1 || num >= 10)) {
//         num = (num % 8) + 2;
//       } else if (i === 7 && checkC === 0 && num >= 2) {
//         num %= 2;
//       }
//       newPWD += list.substr(num, 1);
//     }
//     const fields = {
//       pwd: sha256(newPWD).toString(),
//       pwd_mod_dt: Math.floor(new Date().getTime()),
//       mod_dt: Math.floor(new Date().getTime()),
//     };
//     await firestore.collection('cust_MEMBERS').doc(member.document_id).set(fields, { merge: true });

//     const myHeaders = new Headers();
//     myHeaders.append('Content-Type', 'application/json');

//     const raw = JSON.stringify({
//       'to': member.phone,
//       'content': member.nm + ' 고객님의 임시 비밀번호는 ' + newPWD
//         + ' 입니다.',
//       'productId': member.product_id,
//     });

//     const requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow',
//     };

//     // SMS 전송 API 이용
//     fetch('https://asia-northeast1-aaihealth-b209b.cloudfunctions.net/v2/dashboard/sms', requestOptions)
//       .then((response) => response.text())
//       .then((result) => console.log(result))
//       .catch((error) => console.log('error', error));

//     return { result: 'success', message: 'The member pwd is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 전체 고객 계정 정보를 엑셀로 추출
//  */
// export async function makeXlsx() {
//   try {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const mbData = [];
//     const memsRef = await firestore.collection('cust_MEMBERS')
//       .orderBy('reg_dt', 'desc').get();
//     memsRef.docs.forEach((doc) => {
//       const app_name = product_id_ref[doc.data().product_id];
//       const company_name = company_ref[doc.data().company_id];
//       mbData.push({
//         '보험사 코드': doc.data().company_id,
//         '보험사명': company_name,
//         '앱명': app_name,
//         '이름': doc.data().nm,
//         '생년월일': doc.data().birth_dt,
//         '연락처': doc.data().phone,
//         '성별': doc.data().gender,
//         '앱 가입 시각': new Date(doc.data().reg_dt).toLocaleString(),
//         '정보 수정 시각': new Date(doc.data().mod_dt).toLocaleString(),
//         '탈퇴 여부': doc.data().del_yn ? 'Y' : 'N',
//         '탈퇴 시각': doc.data().del_dt !== 0 ? new Date(doc.data().del_dt).toLocaleString() : '',
//         '마지막 로그인 시각': new Date(doc.data().last_logged_dt).toLocaleString(),
//       });
//     });

//     const exportXlsx = (data) => {
//       const fileName = 'AAIHC_members_' + Math.floor(new Date().getTime() / 1000);
//       const workSheet = XLSX.utils.json_to_sheet(data);
//       const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
//       const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
//       const blob = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(blob, fileName, fileExtension);
//     };
//     return exportXlsx(mbData);
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }