import React, { useEffect, useState } from 'react';
import { getAdminsList } from 'controllers/Services/admins';
import { InfoPane } from 'components/InfoPane';
import { AdminAddTable, CounterLabel, LinkButton, ListTableTemplate } from 'components/Common';
import { Button, Drawer } from 'antd';
import { routes_ref } from 'lib/routeRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import AdminDetailTableContainer from 'containers/Services/Admins/AdminDetailTableContainer';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const AdminListTableContainer = () => {
  const [admins, setAdmins] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [status, setStatus] = useState({
    visible: false,
  });
  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getAdminsList(setAdmins, setCount, setLoading, searchState);
  }, []);

  function goSearch() {
    getAdminsList(setAdmins, setCount, setLoading, searchState);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => {insertInfoAcessLog(location.pathname, 'adminDetail', "조회", undefined, document_id), showDrawer(document_id)}}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '가입일',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '수정시각',
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '최종접속일',
      dataIndex: 'last_logged_dt',
      key: 'last_logged_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '차단여부',
      dataIndex: 'block_yn',
      key: 'block_yn',
      align: 'center',
      render: (bool) => (bool ? 'Y' : ''),
    },
    {
      title: '차단일자',
      dataIndex: 'block_dt',
      key: 'block_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.admins.admin.path}
          />
        )}
        countLabel={(<CounterLabel count={count} />)}
        addButton={(<AdminAddTable />)}
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['이름'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableTemplate
        dataSource={admins}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
        <Drawer
          width={750}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <AdminDetailTableContainer
            document_id={status.document_id} />
        </Drawer>
      )}
      />
    </>
  );
};

export default AdminListTableContainer;
