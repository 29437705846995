 import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination, AppHashAddTable } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { company_ref, product_id_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { getHashList } from 'controllers/Systems/appHash';
import { Button, Drawer } from 'antd';
import AppHashDetailTableContainer from './AppHashDetailTableContainer';

const AppHashTableContainer = () => {
  const [count, setCount] = useState(0);
  const [appHash, setAppHash] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }
  });
  const [searchState, setSearchState] = useState({});   // 검색어
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });
  const [loading, setLoading] = useState(true);

  const onClose = () => {
  setStatus({
    visible: false,
    document_id: '',
  });
  };
  const showDrawer = (document_id) => {
  setStatus({
    visible: true,
    document_id,
  });
  };
  function goSearch() {
    getHashList(setAppHash, appHash, 'first', setCurrentPage, searchState, setLoading);
  }
  useEffect(() => {
    getHashList(setAppHash, appHash, 'first', setCurrentPage, searchState, setLoading);
  }, [])
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const columns = [
        {
          title: '',
          dataIndex: 'document_id',
          key: 'document_id',
          width: '5%',
          align: 'center',
          render: (document_id) => (
            <>
            <Button
              key={document_id}
              type="primary"
              size="small"
              onClick={() => showDrawer(document_id)}
            >
              관리
            </Button>
            </>
          ),
        },
        {
          title: '보험사',
          dataIndex: 'company_id',
          key: 'company_id',
          width: '10%',
          align: 'center',
          render: (data) => company_ref[data],
        },
        {
          title: '앱 명칭',
          dataIndex: 'product_id',
          key: 'product_id',
          width: '20%',
          align: 'center',
          render: (data) => product_id_ref[data],
        },
        {
          title: 'OS 구분',
          dataIndex: 'os',
          key: 'os',
          width: '7%',
          align: 'center',
        },
        {
          title: 'hash 키',
          dataIndex: 'hash',
          key: 'hash',
          align: 'center',
        },
        {
          title: '등록일시',
          dataIndex: 'reg_dt',
          key: 'reg_dt',
          width: '10%',
          align: 'center',
          render: (date) => getFormattedDate(date, 'opt2'),
        },
      ];

      let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  const setPage = (page) => {
    setCurrentPage(page)
  }

  const renderMoveData = (move) => {
    getHashList(setAppHash, appHash, move, setCurrentPage, searchState, setLoading);
  }

  return (
    <>
    <InfoPane
    boardListButton={(
      <LinkButton
        link={routes_ref.base.systems.app_hash.path}
      />
    )}
    countLabel={(<CounterLabel count={count} />)}
    addButton={(<AppHashAddTable />)}
    resetButton={(
      <Button
        type="primary"
        style={{float: 'right', marginLeft:'8px'}}
        onClick={() => {
          setSearchState({});
        }}
      >
        초기화
      </Button>
    )}
    searchButton={(
      <Button
        type="primary"
        style={{float: 'right', marginLeft: '8px'}}
        onClick={() => {
          setLoading(true);
          goSearch()
        }}
      >
        검색
      </Button>
    )}
    searchFieldList={{
      field: ['앱 명칭'],
      searchState: searchState,
      setSearchState: setSearchState,
      onKeyPress: onKeyPress
    }}
    />
    <ListTableWithOutPaging
        dataSource={currentData(appHash.data)}
        columns={columns}
        loading={loading}
        pagination={(
          <Pagination
            totalData={appHash.data}
            pagingState={appHash.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        )}
        DetailTableContainer={(
        <Drawer
          width={750}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <AppHashDetailTableContainer
            document_id={status.document_id} />
        </Drawer>
      )}
      />
    </>
  );
};

export default AppHashTableContainer;
