import React from 'react';
import { Table } from 'antd';

const ListTableWithOutPaging = ({ dataSource, columns, pagination, DetailTableContainer, loading }) => (
  <>
    <Table
      rowKey={(record) => JSON.stringify(record) + Math.random()}
      dataSource={dataSource}
      columns={columns}
      loading={loading}
      style={{ whiteSpace: 'pre-line' }}
      pagination={false}
    />
    {DetailTableContainer || null}
    {pagination || null}
  </>
);

export default ListTableWithOutPaging;
