import { useState } from 'react';
import { getDentalsList, makeXlsx } from '../../controllers/HealthLogs/dentals';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

export default function useDentalSearch(setDentals, setLoading) {
  const Dentals = {};
  var date = new Date();

  // 구독서비스 시작이 20220607이라서 6월1일로 지정해둠
  const [dentalSearch, setDentalSearch] = useState({
    subscribeGoods: 'all',
    subscribeCancel: 'all',
    startDate: new Date('2022', '06'-1, 1),
    endDate: date,
    isOnSearching: false,
  });

  Dentals.dentalSearch = dentalSearch;

  Dentals.handleSubscribeGoodsChange = (subscribeGoods) => {
    setDentalSearch({
        ...dentalSearch,
        subscribeGoods,
        isOnSearching: true,
    });
  };

  Dentals.handleSubscribeCancelChange = (subscribeCancel) => {
    setDentalSearch({
      ...dentalSearch,
      subscribeCancel,
      isOnSearching: true,
    });
  };

  Dentals.handleDentalPeriodChange = (type, date) => {
    if (type === 'start') {
      setDentalSearch({
        ...dentalSearch,
        startDate: date,
      });
    } else {
      setDentalSearch({
        ...dentalSearch,
        endDate: date,
      });
    }
  };

  // Dentals.handleSearch = () => {
  //   // TODO: 해당 월 조회 기능 구현하기
  //   const searchedList = [];
  //   getDentalsList(
  //     setDentals,
  //     setCount,
  //     dentalSearch.subscribeGoods,
  //     dentalSearch.subscribeCancel,
  //     dentalSearch.startDate,
  //     dentalSearch.endDate,
  //     setLoading
  //   ).then((d) => {
  //     searchedList.push(d);
  //   });
  // };

  Dentals.handleExcelDownload = (excelReason, searchState) => {
    makeXlsx( excelReason, searchState );
    insertInfoAcessLog(location.pathname, 'makeXlsx', "다운로드", excelReason);
  };
  return Dentals;
}
