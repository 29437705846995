import React, { useState } from 'react';
import { Badge, Card, Collapse, Descriptions, Input, Row, Space } from 'antd';
import answerIcon from 'assets/icons/answer_icon.png';
import questionIcon from 'assets/icons/question_icon.png';
import { CancelButton, SaveButton } from 'components/Common';
import { insertCounselingAnswer } from 'controllers/Outers/healthyBody';
import { gender_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { routes_ref } from 'lib/routeRef';

const { Item } = Descriptions;
const { Panel } = Collapse;
const reportTitleStyle = {
  textAlign: 'center',
  width: '100%',
};

const HealthyBodyCounselingTable = (props) => {

  const { dataState, setDataState } = props;
  const [temp, setTemp] = useState('0');
  const [counseling, setCounseling] = useState({
    document_id: '',
    program_seq: '',
    answer: '',
  });

  function callback(index) {
    let i = String(index);
    if(temp === i ){
      setTemp('');
    } else {
      setCounseling({});
      setTemp(i);
    }
  }

  return (
    <>
      <Descriptions
      title="상담 이력"
      bordered
      style={reportTitleStyle}
      >
        <Item label="이름">
          {dataState.userInfo.nm || '-'}
        </Item>
        <Item label="생년월일">
          {dataState.userInfo.birth_dt || '-'}
        </Item>
        <Item label="성별">
          {gender_ref[dataState.userInfo.gender] || '-'}
        </Item>
        <Item label="프로그램명" >
          {dataState.userInfo.program_nm || '-'}
        </Item>
        <Item label="고객작성일">
          {getFormattedDate(dataState.userInfo.reg_dt, 'opt1') || '-'}
        </Item>
        <Item label="운동시작일">
          {getFormattedDate(dataState.userInfo.start_dt, 'opt1') || '-'}
        </Item>
      </Descriptions>
      <br />
      <br />
      {dataState && dataState.counselingInfo.length > 0 && dataState.counselingInfo.map((row, index) => (
        <Collapse accordion key={index} activeKey={temp} onChange={() => callback(index)}>
          <React.Fragment key={index}>
            {row.confirm_yn === 'N'
              ?
                <Panel
                  key={index}
                  header={(
                    <>
                      <span
                        style={{textAlign: 'center', alignItems: 'center', maxWidth: '700px', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {row.question}
                      </span>
                      <span style={{ float: 'right', verticalAlign: 'middle', minWidth: '174px' }}>
                        {getFormattedDate(row.reg_dt, 'opt4')}
                        <br />
                        <Badge status="error" text="답변 대기중"/>
                      </span>
                    </>
                  )}
                >
                  <div key={index}>
                    <div style={{ textAlign: 'left', fontWeight: 'bold', width: '300px', marginLeft: '5px', marginBottom: '5px' }}>
                      {'상담 내용 작성'}
                    </div>
                    <Card style={{ borderRadius: '5px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span>
                          <img src={questionIcon} />
                          <span style={{ marginLeft: '5px', fontSize: 14, wordBreak: 'break-all' }}>
                            {row.question}
                          </span>
                        </span>
                        <span style={{ minWidth: '174px', minHeight: '37px' }}>
                          {getFormattedDate(row.reg_dt, 'opt4')}
                        </span>
                      </div>
                    </Card>
                    <br />
                    <Card style={{ borderRadius: '5px' }}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                        <span>
                          <img src={answerIcon} style={{ width: '32px', height: '37px' }}/>
                        </span>
                      </div>
                      <div>
                        <Input.TextArea
                          key={index}
                          value={counseling.answer}
                          rows={10}
                          name={index}
                          allowClear
                          onChange={((e) => setCounseling({ ...counseling, document_id: row.document_id, program_seq: row.program_seq, answer: e.currentTarget.value }))}
                        />
                        <Row style={{ marginTop: '10px'}}>
                          <Space>
                            <CancelButton
                              location={routes_ref.base.outers.healthy_body.path}
                            />
                            <SaveButton
                              func={insertCounselingAnswer}
                              data={counseling}
                              location={routes_ref.base.outers.healthy_body.path}
                            />
                          </Space>
                        </Row>
                      </div>
                    </Card>
                  </div>
                </Panel>
              :
                <Panel
                  key={index}
                  header={(
                    <>
                      <span
                        style={{ textAlign: 'center', alignItems: 'center', maxWidth: '700px', display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {row.question}
                      </span>
                      <span style={{ float: 'right', verticalAlign: 'middle', minWidth: '174px' }}>
                        {getFormattedDate(row.mod_dt, 'opt4')}
                        <br />
                        <Badge status="success" text="답변 완료"/>
                      </span>
                    </>
                  )}
                >
                  <div style={{ textAlign: 'left', fontWeight: 'bold', width: '300px', marginLeft: '5px', marginBottom: '5px' }}>
                    {'상담 내용 보기'}
                  </div>
                  <Card style={{ borderRadius: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        <img src={questionIcon} />
                        <span style={{ marginLeft: '5px', fontSize: 14, fontWeight: 'bold' }}>
                         {row.question}
                        </span>
                      </span>
                      <span style={{ minWidth: '174px', minHeight: '37px' }}>
                        {getFormattedDate(row.reg_dt, 'opt4')}
                      </span>
                    </div>
                  </Card>
                  <br />
                  <Card style={{ borderRadius: '5px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>
                        <img src={answerIcon} style={{ width: '32px', height: '37px' }}/>
                      </span>
                      <span style={{ minWidth: '174px', minHeight: '37px' }}>
                        {getFormattedDate(row.mod_dt, 'opt4')}
                      </span>
                    </div>
                    <br />
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {row.answer}
                    </div>
                  </Card>
              </Panel>
            }
          </React.Fragment>
        </Collapse>
      ))}
    </>
  );
};

export default HealthyBodyCounselingTable;
