import React from 'react';
import classNames from 'classnames/bind';
import { Input } from 'antd';
import styles from './LoginFormWithLabel.scss';

const cx = classNames.bind(styles);

const LoginFormWithLabel = ({ id, pw, onIdChange, onPwChange, onSubmit, onKeyPress }) => (
  <div className={cx('form')}>
    {/* <form> */}
    <label>
      아이디
      <Input
        type="text"
        value={id}
        onChange={(e) => onIdChange(e.target.value)}
        onKeyPress={(e) => onKeyPress(e)}
      />
    </label>
    <label>
      비밀번호
      <Input
        type="password"
        value={pw}
        onChange={(e) => onPwChange(e.target.value)}
        onKeyPress={(e) => onKeyPress(e)}
      />
    </label>
    <button onClick={() => onSubmit(id, pw)}>로그인</button>
    {/* </form> */}
  </div>
);

export default LoginFormWithLabel;
