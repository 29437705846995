import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 하나 손보 걷기 기록 조회
 */
export async function getHanaStepsList(setHanaSteps, hanaSteps, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/health/hanaSteps/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage, 
        pagingState: hanaSteps.pagingState, 
        searchState: searchState
      })
    })
    .then((response) =>  response);
    
    setHanaSteps(result.data);
    setCurrentPage(1); 
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 하나 손보 걷기 기록의 갯수 조회
//  */
// export async function getHanaStepsSize() {
//     try {
//         const stepsRef = await firestore.collection('cust_HANAPOINTS')
//         .where('product_id', '==', 'HanaFire01_0000')
//         .where('is_valid', '==', true)
//         .get();
//         return stepsRef.size;
//     } catch (err) {
//         console.error(err);
//         return { result: 'error: \n', message: err };
//     }
// }

// /**
//  * 하나 손보 걷기 기록 조회
//  */
// export async function getHanaStepsList() {
//     try {
//         const stepsRef = await firestore.collection('cust_HANAPOINTS')
//         .where('product_id', '==', 'HanaFire01_0000')
//         .where('is_valid', '==', true)
//         .orderBy('reg_dt', 'desc')
//         .get();

//         const lists = [];

//         stepsRef.docs.forEach(async (doc) => {
//             lists.push({
//                 document_id: doc.data().document_id,
//                 product_id: doc.data().product_id,
//                 member_id: doc.data().member_id,
//                 crm_nm: doc.data().crm_nm,
//                 crm_phone: doc.data().crm_phone,
//                 accumulated_steps: doc.data().accumulated_steps.toLocaleString(),
//                 accumulated_points: doc.data().accumulated_points.toLocaleString(),
//                 signup_str_dt: doc.data().signup_str_dt,
//                 activity_dt: doc.data().start_str_dt !== undefined && doc.data().start_str_dt.length > 0
//                 && doc.data().end_str_dt !== undefined && doc.data().end_str_dt.length > 0
//                 ? doc.data().start_str_dt + ' ~ ' + doc.data().end_str_dt
//                 : '',
//             });
//         });
//         return lists;
//     } catch (err) {
//         console.error(err);
//         return { result: 'error: \n', message: err };
//     }
// }