import React, { useEffect, useState } from 'react';
import { CounterLabel, LinkButton,  ListTableWithOutPaging,
  Pagination, ExcelModal,DelListButton } from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { getDentalsList } from 'controllers/HealthLogs/dentals';
import { subscribe_cancel_ref } from 'lib/dataRef';
import { routes_ref } from 'lib/routeRef';
import { useDentalSearch } from 'lib/hooks';
import { deleteBundle } from 'controllers/common';
import { Button, Drawer } from 'antd';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { getFormattedDate, to_date } from 'lib/getFormattedDate';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import DentalDetailContainer from 'containers/HealthLogs/Dentals/DentalDetailContainer';

const DentalsListContainer = () => {
  const [dentals, setDentals] = useState({data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first',
    },});
  const [searchState, setSearchState] = useState({});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
  const dentalSearch = useDentalSearch(setDentals, setLoading);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });
  useEffect(() => {
    getDentalsList(setDentals, dentals,  'first',
    setCurrentPage,
    searchState,
    setLoading);
  }, []);

  function goSearch() {
    getDentalsList(setDentals, dentals,  'first',
    setCurrentPage,
    searchState,
    setLoading);
  }
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const excelDownload = () =>{
    dentalSearch.handleExcelDownload(excelReason, searchState)
  }
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  let cp = currentPage;
  if (cp > 10) {
    // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp % 10;
    cp = n == 0 ? 10 : n;
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  };
  let list = currentData(dentals.data);

  const setPage = (page) => {
    setCurrentPage(page);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const renderMoveData = (move) => {
    getDentalsList(setDentals, dentals,  'first',
    setCurrentPage,
    searchState,
    setLoading);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const onChangeChecked = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      checkedList.splice(checkedList.indexOf(id), 1);
      setCheckedList([...checkedList]);
    }
  };
  const onCheckedAll = (e) => {
    const checkList = list.map((item) => item.document_id);
    if (e.target.checked) {
      setCheckedList(checkList);
    } else {
      setCheckedList([]);
    }
  };
  const columns = [
    {
      title: () => {
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.length == list.length}
              onChange={(e) => onCheckedAll(e)}
            />
            전체
          </>
        );
      },
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render(document_id) {
        return (
          <>
           <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={checkedList.includes(document_id)}
            onChange={(e) => onChangeChecked(e, document_id)}
          />
            <Button
              key={document_id}
              type="primary"
              size="small"
              onClick={() => {insertInfoAcessLog(location.pathname, 'dentalDetail', "조회", undefined, document_id), showDrawer(document_id)}}
            >
              관리
            </Button>
          </>
        );
      },
    },
    {
      title: '제휴사',
      dataIndex: 'subscribe_goods_nm',
      key: 'subscribe_goods_nm',
      align: 'center',
      render: (data) => `${data}`,
    },
    {
      title: '이름',
      dataIndex: 'member_nm',
      key: 'member_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '아이디',
      dataIndex: 'member_id',
      key: 'member_id',
      render: (data) => `${data}`,
    },
    {
      title: '생년월일',
      dataIndex: 'member_birth_dt',
      key: 'member_birth_dt',
      align: 'center',
      render: (data) => `${data}`,
    },
    {
      title: '연락처',
      dataIndex: 'member_ph',
      key: 'member_ph',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '처리상태',
      dataIndex: 'subscribe_cancel_yn',
      key: 'subscribe_cancel_yn',
      align: 'center',
      render: (data) => subscribe_cancel_ref[data],
    },
    {
      title: '일시',
      dataIndex: 'subscribe_dt',
      key: 'subscribe_dt',
      render: (data) => getFormattedDate(to_date(data), 'opt5'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton
            link={routes_ref.base.healthLogs.dentals.path}
          />
        }
        countLabel={(<CounterLabel count={dentals.count} />)}
        xlsButton={(
          <Button
              type="primary"
              style={{ float: 'right', marginLeft: '8px' }}
              onClick={() => {
                setModalIsOpen(true);
              }}
            >
              엑셀 다운로드
            </Button>
        )}
        delButton={
          <DelListButton
            func={deleteBundle}
            data={checkedList}
            location="/dentals"
          />
        }
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
            >
            검색
          </Button>
        )}
        searchFieldList={{
          page: 'dentals',
          field: ['제휴사', '이름', '연락처', '상태', '등록일'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableWithOutPaging 
      dataSource={currentData(dentals.data)} 
      columns={columns} 
      loading={loading}
      pagination={
        <Pagination
          totalData={dentals.data}
          pagingState={dentals.pagingState}
          setPage={setPage}
          renderMoveData={renderMoveData}
        ></Pagination>
      }
      DetailTableContainer={(
        <Drawer
          width={700}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <DentalDetailContainer
            document_id={status.document_id}
          />
        </Drawer>
      )}/>
      <div>
        {
          modalIsOpen && (
          <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
          </ExcelModal>
        )}
      </div>
    </>
  );
};

export default DentalsListContainer;
