import React, { useEffect, useState } from 'react';
import { MemberDetailTable } from 'components/Common';
import { getMemberDetail } from 'controllers/Services/members';
import { Spin } from 'antd';

const MemberDetailTableContainer = ({ document_id }) => {
  const [memberDetail, setMemberDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (document_id) {
      setMemberDetail({});
      setLoading(true);
      const data = getMemberDetail(document_id);
      data.then((d) => {
        setMemberDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <MemberDetailTable memberDetail={memberDetail} setMemberDetail={setMemberDetail} />
      </Spin>
    :
      <MemberDetailTable
        memberDetail={memberDetail}
        setMemberDetail={setMemberDetail} />
  );
};

export default MemberDetailTableContainer;
