import React, { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const DelButton = ({ func, data, location }) => {
  const [popState, setPopState] = useState({
    visible: false,
  });
  const confirm = async () => {
    setPopState({ visible: false });
    await func(data, location).then(async (d) => {
      if (d.result === 'success') {
        // 개인정보접근 로그 저장
        for (let i = 0; i < data.length; i++) {
          let inforText = data[i]['crm_nm'] || data[i];
          console.log(inforText)
          insertInfoAcessLog(location, func.name, '삭제', undefined, inforText);
        }
        await message
          .success(
            `일괄삭제에 성공하였습니다. 삭제요청: ${d.total}건, 삭제성공: ${d.cnt}건`
          )
          .then((d) => {
            if (location) {
              document.location.href = location;
            }
          });
      } else if (d.result === 'fail') {
        await message.error(`일괄삭제에 실패했습니다. ${d.message}`);
      }
    });
  };

  const cancel = () => {
    setPopState({ visible: false });
    message.error('취소했습니다');
  };

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setPopState({ visible });
      return;
    }
    setPopState({ visible }); // show the popconfirm
  };

  const description =
    '일괄삭제 대상은 총 ' +
    data.length +
    '개 서비스 입니다. \n 삭제하시겠습니까?';
  return (
    <>
      <Popconfirm
        title="삭제데이터를 확인해주세요"
        description={description}
        okText="삭제"
        cancelText="취소"
        open={popState.visible}
        onOpenChange={handleVisibleChange}
        onConfirm={confirm}
        onCancel={cancel}
      >
        <Button
          block
          type="primary"
          style={{ float: 'right', marginLeft: '8px', width: '80px' }}
        >
          일괄삭제
        </Button>
      </Popconfirm>
    </>
  );
};

export default DelButton;
