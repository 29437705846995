import React, { useState, useEffect } from 'react';
import { getAppVerDetail } from 'controllers/Systems/appVersion';
import { AppVersionDetailTable } from 'components/Common';
import { Spin } from 'antd';

const AppVerDetailTableContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [appVerDetail, setAppVerDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setAppVerDetail({});
      setLoading(true);
      const data = getAppVerDetail(document_id);
      data.then((d) => {
        setAppVerDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <AppVersionDetailTable appVerDetail={appVerDetail} setAppVerDetail={setAppVerDetail} />
      </Spin>
    :
      <AppVersionDetailTable appVerDetail={appVerDetail} setAppVerDetail={setAppVerDetail} />
  );
};

export default AppVerDetailTableContainer;