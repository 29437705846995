import React, { useState } from 'react';
import { Descriptions, Input, Divider, Space, DatePicker, Drawer } from 'antd';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { postTreatment } from 'controllers/Contents/treatments';

const { Item } = Descriptions;
const TreatmentsAddTable = () => {
  const [treatmentsDetail, setTreatmentsDetail] = useState({
    title: '',
    newspaper_nm: '',
    newspaper_dt: {},
    url: '',
  });
  const [drawerStatus, setDrawerStatus] = useState(false);
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setTreatmentsDetail(treatmentsDetail);
  };
  const dateOnChange = (date, dateString) => {
    setTreatmentsDetail({ ...treatmentsDetail, newspaper_dt: date });
  };
  return (
    <>
    <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
        <Descriptions title="컨텐츠 추가" bordered column={1}>
          <Item label="제목">
            <Input
              value={treatmentsDetail.title}
              onChange={(e) => setTreatmentsDetail({ ...treatmentsDetail, title: e.target.value })}
            />
          </Item>
          <Item label="신문사">
            <Input
              value={treatmentsDetail.newspaper_nm}
              onChange={(e) => setTreatmentsDetail({ ...treatmentsDetail, newspaper_nm: e.target.value })}
            />
          </Item>
          <Item label="신문날짜">
            <DatePicker onChange={dateOnChange} />
          </Item>
          <Item label="url">
            <Input
              value={treatmentsDetail.url}
              onChange={(e) => setTreatmentsDetail({ ...treatmentsDetail, url: e.target.value })}
            />
          </Item>
        </Descriptions>
      <Divider />
        <Space>
          <CancelButton
            location="/post-treatments"
          />
          <SaveButton
            func={postTreatment}
            data={treatmentsDetail}
            location="/post-treatments"
          />
        </Space>
      </Drawer>
    </>
  );
};

export default TreatmentsAddTable;
