import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 고객들의 로그인 이력 내역 조회
 * @param {*} setLoginlogs client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 */
export async function getLoginLogsList(setLoginlogs, loginlogs, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const res = await axios.post(
      `${baseUrl}/loginLogs/getList`, {
        goPage: goPage,
        pagingState: loginlogs.pagingState,
        searchState: searchState
      }).then((res) => {
        setLoginlogs(res.data);
        setCurrentPage(1);  // 콜할때 100개씩만 가져오고 1페이지 리턴(1, 11, 21페이지)
        setLoading(false);
      }).catch((err) => {
        console.error(err)
      });

    return res;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 고객들의 모든 로그인 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if(!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName = 'AAIHC_loginLogs_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios.post(
      `${baseUrl}/loginLogs/excel`,{
        searchState: searchState
      }).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';
// import { company_ref, product_id_ref, login_yn_ref } from 'lib/dataRef';

// /**
//  * 저장된 고객들의 로그인 이력의 총 갯수 조회
//  */
// // export async function getLoginLogsSize() {
// //   try {
// //     const lgsRef = await firestore.collection('cust_LOGINLOGS')
// //       .orderBy('reg_dt', 'desc').get();
// //     return lgsRef.size;
// //   } catch (err) {
// //     console.error(err);
// //     return { result: 'error: \n', message: err };
// //   }
// // }
// export async function getLoginLogsSize(app_code) {
//   try {
//     const lgsRef = await firestore.collection('cust_LOGINLOGS')
//       .orderBy('reg_dt', 'desc').get();
//       if (app_code !== undefined) {
//         let size = 0;
//         lgsRef.docs.forEach((doc) => {
//           if (doc.data().product_id.includes(app_code)) {
//             size++;
//           }
//         });
//         return size;
//       } else {
//         return lgsRef.size;
//       }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }


// /**
//  * 고객들의 로그인 이력 내역 조회
//  * @param {*} setLoginlogs client에서 전달하는 React.Dispatch
//  * @param {*} setCount client에서 전달하는 React.Dispatch
//  */
// export async function getLoginLogsList(setLoginlogs, direction, pagingState, setPagingState, app_code, searchState, setSearchState) {
//   try {
//     // 컬렉션 내에서 변동이 있을 경우(새로운 서비스 신청 추가, 기존 서비스 신청 변동 등),
//     // 리로드 없이도 변동 사항을 반영하기 위해 onSnapshot 이용
//     // (onSnapshot에 대한 자세한 사항은 firebase documentation을 참고하길 바람) -> 230630 pagination때문에 get()으로 변경
//     const loginDB = firestore.collection('cust_LOGINLOGS');
//     let q = loginDB.orderBy('reg_dt', 'desc');

//     // 보험사 검색
//     if(searchState.searchCompany !== '' && searchState.searchCompany.length > 0){
//       q = q.where('company_id', '==', searchState.searchCompany)
//     }
//     // 핸드폰번호 검색
//     if(searchState.searchPhone !== '' && searchState.searchPhone.length > 0){
//       q = q.where('crm_phone', '==', searchState.searchPhone)
//     }
//     // 이름 검색
//     if(searchState.searchName !== '' && searchState.searchName.length > 0){
//       q = q.where('crm_nm', '==', searchState.searchName)
//     }
//     // 회원구분 검색
//     if(searchState.searchLoginYn !== '' && searchState.searchLoginYn != null){
//       q = q.where('login_yn', '==', searchState.searchLoginYn)
//     }

//     if(direction === 1){  // 다음버튼 클릭
//       q = q.startAfter(pagingState.next).limit(10)

//     }else if(direction === -1){ // 이전버튼 클릭
//       q = q.endBefore(pagingState.start).limitToLast(10)

//     }else if(direction > 1){  // 젤다음버튼 클릭
//       q = q.limitToLast(10)

//     }else{  // 젤이전버튼 클릭
//       q = q.limit(10)
//     }

//     const lgsRef = await q
//       .get().then((snap) => {
//         const lgDocs = [];
//         const changes = snap.docChanges();

//         changes.forEach((change) => {
//           if (change.type === 'added') {
//             if (changes.length > 1) {
//               lgDocs.push({
//                 company_id: company_ref[change.doc.data().company_id],
//                 // product_id: change.doc.data().product_id,
//                 product_id: product_id_ref[change.doc.data().product_id],
//                 // crm_nm field는 없다가 생긴 것이므로, 값이 존재하지 않는다면 아주 오래된 버전의 앱을 사용중인 것
//                 crm_nm: change.doc.data().crm_nm
//                   ? change.doc.data().crm_nm : '구버전 사용자',
//                 crm_phone: change.doc.data().crm_phone,
//                 // member_id: change.doc.data().member_id,
//                 // login_yn field는 원래부터 존재하는 것이었는데, 특정 앱에서 snake case가 아닌 camel case로 작성된 경우가 있음
//                 // login_yn: change.doc.data().login_yn !== undefined
//                 //   ? change.doc.data().login_yn : change.doc.data().loginYn,
//                 login_yn: login_yn_ref[change.doc.data().login_yn],
//                 reg_dt: change.doc.data().reg_dt,
//               });
//             } else {
//               snap.forEach((doc) => {
//                 lgDocs.push({
//                   company_id: company_ref[change.doc.data().company_id],
//                   // product_id: doc.data().product_id,
//                   product_id: product_id_ref[change.doc.data().product_id],
//                   // crm_nm field는 없다가 생긴 것이므로, 값이 존재하지 않는다면 아주 오래된 버전의 앱을 사용중인 것
//                   crm_nm: doc.data().crm_nm
//                     ? doc.data().crm_nm : '구버전 사용자',
//                   crm_phone: doc.data().crm_phone,
//                   // member_id: doc.data().member_id,
//                   // login_yn field는 원래부터 존재하는 것이었는데, 특정 앱에서 snake case가 아닌 camel case로 작성된 경우가 있음
//                   // login_yn: doc.data().login_yn !== undefined
//                   //   ? doc.data().login_yn : doc.data().loginYn,
//                   login_yn: login_yn_ref[change.doc.data().login_yn],
//                   reg_dt: doc.data().reg_dt,
//                 });
//               });
//             }
//           }
//           if (change.type === 'modified') {
//             snap.forEach((doc) => {
//               lgDocs.push({
//                 company_id: company_ref[change.doc.data().company_id],
//                 // product_id: doc.data().product_id,
//                 product_id: product_id_ref[change.doc.data().product_id],
//                 // crm_nm field는 없다가 생긴 것이므로, 값이 존재하지 않는다면 아주 오래된 버전의 앱을 사용중인 것
//                 crm_nm: doc.data().crm_nm
//                   ? doc.data().crm_nm : '구버전 사용자',
//                 crm_phone: doc.data().crm_phone,
//                 // member_id: doc.data().member_id,
//                 // login_yn field는 원래부터 존재하는 것이었는데, 특정 앱에서 snake case가 아닌 camel case로 작성된 경우가 있음
//                 // login_yn: doc.data().login_yn !== undefined
//                 //   ? doc.data().login_yn : doc.data().loginYn,
//                 login_yn: login_yn_ref[change.doc.data().login_yn],
//                 reg_dt: doc.data().reg_dt,
//               });
//             });
//           }
//           if (change.type === 'removed') {
//             snap.forEach((doc) => {
//               lgDocs.push({
//                 company_id: company_ref[change.doc.data().company_id],
//                 // product_id: doc.data().product_id,
//                 product_id: product_id_ref[change.doc.data().product_id],
//                 crm_nm: doc.data().crm_nm
//                 // crm_nm field는 없다가 생긴 것이므로, 값이 존재하지 않는다면 아주 오래된 버전의 앱을 사용중인 것
//                   ? doc.data().crm_nm : '구버전 사용자',
//                 crm_phone: doc.data().crm_phone,
//                 // member_id: doc.data().member_id,
//                 // login_yn field는 원래부터 존재하는 것이었는데, 특정 앱에서 snake case가 아닌 camel case로 작성된 경우가 있음
//                 // login_yn: doc.data().login_yn !== undefined
//                 //   ? doc.data().login_yn : doc.data().loginYn,
//                 login_yn: login_yn_ref[change.doc.data().login_yn],
//                 reg_dt: doc.data().reg_dt,
//               });
//             });
//           }
//         });
//         setLoginlogs(lgDocs);
//         setPagingState({
//           start: lgDocs[0].reg_dt,
//           next: lgDocs[lgDocs.length - 1].reg_dt,
//           page: direction === 0 ? 1 : pagingState.page + direction
//         });
//       });
//     return lgsRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 고객들의 모든 로그인 이력을 엑셀로 추출
//  */
// export async function makeXlsx() {
//   try {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const logData = [];
//     const llsRef = await firestore.collection('cust_LOGINLOGS')
//       .orderBy('reg_dt', 'desc').get();
//     llsRef.docs.forEach((doc) => {
//       const company_name = company_ref[doc.data().company_id];
//       // (login_yn field는 원래부터 존재하는 것이었는데, 특정 앱에서 snake case가 아닌 camel case로 작성된 경우가 있음)
//       if (doc.data().loginYn !== undefined) {
//         logData.push({
//           '보험사 코드': doc.data().company_id,
//           '보험사명': company_name,
//           '상품코드': product_id_ref[doc.data().product_id],
//           '이름': '구버전 사용자',
//           '연락처': '',
//           '로그인 시도 시각': new Date(doc.data().reg_dt).toLocaleString(),
//           '로그인 성공 여부': doc.data().loginYn ? 'Y' : 'N',
//         });
//       } else {
//         logData.push({
//           '보험사 코드': doc.data().company_id,
//           '보험사명': company_name,
//           '상품코드': product_id_ref[doc.data().product_id],
//           '이름': doc.data().crm_nm ? doc.data().crm_nm : '구버전 사용자',
//           '연락처': doc.data().crm_phone,
//           '로그인 시도 시각': new Date(doc.data().reg_dt).toLocaleString(),
//           '로그인 성공 여부': doc.data().login_yn ? 'Y' : 'N',
//         });
//       }
//     });

//     const exportXlsx = (data) => {
//       const fileName = 'AAIHC_loginLogs_' + Math.floor(new Date().getTime() / 1000);
//       const workSheet = XLSX.utils.json_to_sheet(data);
//       const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
//       const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
//       const blob = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(blob, fileName, fileExtension);
//     };
//     return exportXlsx(logData);
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }