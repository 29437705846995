import React, { useState } from 'react';
import { Badge, Checkbox, Descriptions, Divider, Drawer, Input, Modal, Row, Select, Space, Upload } from 'antd';
import { company_ref, product_id_ref, storage_ref } from 'lib/dataRef';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import useUpload from 'lib/hooks/useUpload';
import { PlusOutlined } from '@ant-design/icons';
import uploadToStorage from 'lib/uploadToStorage';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import { insertMediCert } from 'controllers/Services/mediCerts';

const { Item } = Descriptions;

const MediCertsAddTable = () => {
  const LOCATION = routes_ref.base.services.mediCert.path;
  const [mediCertsDetail, setMedicertsDetail] = useState({});
  const [drawerStatus, setDrawerStatus] = useState(false);
  const UPLOAD = useUpload();
  const { previewVisible, previewImage, fileList, previewTitle } = UPLOAD.upload;
  const { handlePreview, handleChange, handleCancel } = UPLOAD;

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setMedicertsDetail(mediCertsDetail);
  };

  const handleChangeName = (e) => setMedicertsDetail({
    ...mediCertsDetail,
    crm_nm: e.target.value,
  });
  const handleChangePhone = (e) => setMedicertsDetail({
    ...mediCertsDetail,
    crm_phone: e.target.value,
  });

  const handleChangeCompanyId = (company_id) => {
    setMedicertsDetail({
      ...mediCertsDetail,
      company_id,
    });
  };
  const handleTypeChange = (checked) => {
    setMedicertsDetail({
      ...mediCertsDetail,
      type: checked,
    });
  };

  const handleChangeProductId = (product_id) => {
    setMedicertsDetail({
      ...mediCertsDetail,
      product_id,
    });
  };
  return (
    <>
      <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
        <Descriptions
          title="회원 정보"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label={
            mediCertsDetail.crm_nm ?  <Badge status="success" text="계약자 이름" /> : <Badge status="error" text="계약자 이름" />
            }>
            <Input
              value={mediCertsDetail.crm_nm}
              onChange={handleChangeName}
            />
          </Item>
          <Item label= {
            mediCertsDetail.crm_phone ?  <Badge status="success" text="계약자 연락처" /> : <Badge status="error" text="계약자 연락처" />
            }>
            <Input
              value={mediCertsDetail.crm_phone}
              onChange={handleChangePhone}
            />
          </Item>
          <Item label={
            mediCertsDetail.company_id ? <Badge status="success" text="보험사" /> : <Badge status="error" text="보험사" />
          }>
            <Select
              style={{ width: '100%' }}
              onChange={handleChangeCompanyId}
              value={mediCertsDetail.company_id}
            >
              {getOptionsByRefId(company_ref)}
            </Select>
          </Item>
        </Descriptions>
        <Divider />

        <Descriptions
          title="서비스 정보"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label={
            mediCertsDetail.product_id ? <Badge status="success" text="앱 명칭" /> : <Badge status="error" text="앱 명칭" />
          }>
            <Select
              style={{ width: '100%' }}
              onChange={handleChangeProductId}
              value={mediCertsDetail.product_id}
            >
              {getOptionsByRefId(product_id_ref)}
            </Select>
          </Item>
          <Item label={
            mediCertsDetail.type ? <Badge status="success" text="타입지정" /> : <Badge status="error" text="타입지정" />
          }
          >
            <Checkbox.Group
              onChange={handleTypeChange}
              value={mediCertsDetail && mediCertsDetail.type}
            >
              {
                mediCertsDetail.product_id === 'ChubbLife01_0000'
                  ? (
                    <Checkbox value="default">
                      일반
                    </Checkbox>
                  )
                  : (
                    <>
                      <Checkbox value="cancer">
                        암
                      </Checkbox>
                      <Checkbox value="brain">
                        뇌심
                      </Checkbox>
                      <Checkbox value="gi">
                        GI-LTC
                      </Checkbox>
                      <Checkbox value="default">
                        일반
                      </Checkbox>
                      <Checkbox value="seniorCare">
                        시니어케어(요양형) 경증
                      </Checkbox>
                      <Checkbox value="seniorDementia">
                        시니어케어(치매형) 경증
                      </Checkbox>
                    </>
                  )
              }
            </Checkbox.Group>
          </Item>
          <Item label="관리자 메모">
            <Input.TextArea
              value={mediCertsDetail.admin_memo}
              rows={10}
              allowClear
              onChange={(e) => setMedicertsDetail({
                ...mediCertsDetail,
                admin_memo: e.currentTarget.value,
              })}
            />
          </Item>
        </Descriptions>
        <Divider />

        <Descriptions
          bordered
          layout="vertical"
        >
          <Item label={
            mediCertsDetail.img_url ?  <Badge status="success" text="첨부 이미지들" /> : <Badge status="error" text="첨부 이미지들" />
            }>
            <Upload
              disabled={mediCertsDetail.company_id === undefined}
              customRequest={(customOption) => uploadToStorage(storage_ref[mediCertsDetail.company_id], customOption)}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={(info) => {
                handleChange(info);
                setMedicertsDetail({
                  ...mediCertsDetail,
                  img_url: Array.isArray(info.fileList) ? info.fileList.map((fileInfo) => fileInfo.response) : [],
                });
              }}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewVisible}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: '100%' }} src={previewImage} />
            </Modal>
          </Item>
        </Descriptions>
        <Divider />

        <Descriptions
          title="신청 정보"
          bordered
          column={2}
          layout="vertical"
          style={{ width: '100%' }}
        >
        </Descriptions>
        <Divider />
        <Row>
          <Space>
            <CancelButton
              location={LOCATION}
            />
            <SaveButton
              func={insertMediCert}
              data={mediCertsDetail}
              location={LOCATION}
            />
          </Space>
        </Row>
      </Drawer>
    </>

  );
};

export default MediCertsAddTable;
