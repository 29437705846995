import React, { useState } from 'react';
import { Select, Descriptions, Input, DatePicker, Button } from 'antd';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import {
  chat_category1,
  chat_category2_by_doctor,
  chat_category2,
  chat_service_type,
  chat_company,
  hospital,
  hspt_sort,
  inst_sort,
  hide_doctor,
  search,
  region,
  constract,
  ambti,
} from 'lib/chatbotDataRef';
import { getRegionList } from 'controllers/chatbot/chatbotDoctor';
import { getInstRegionList } from 'controllers/chatbot/chatbotCenter';
import dayjs from 'dayjs';

const { Item } = Descriptions;
const getChatbotFieldList = (
  ref,
  setSearchState,
  searchState,
  page,
  onKeyPress
) =>
  ref.map((row) => {
    const [sigungu, setSigungu] = useState({
      속초시: '속초시',
      강릉시: '강릉시',
      태백시: '태백시',
      동해시: '동해시',
      삼척시: '삼척시',
      춘천시: '춘천시',
      홍천군: '홍천군',
      영월군: '영월군',
      원주시: '원주시',
    });
    if (row !== '') {
      // 복잡해서 챗봇 필터섹션만 따로 분류함
      if (row === '보험사') {
        return (
          <Item label={row} contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.company_id}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  company_id: e,
                });
              }}
            >
              {getOptionsByRefId(chat_company)}
            </Select>
          </Item>
        );
      } else if (row === '이름') {
        return (
          <Item label="이름" contentStyle={{ width: '40%' }}>
            <Input
              style={{ width: '150px' }}
              value={searchState.nm}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  nm: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      } else if (row === '연락처') {
        return (
          <Item label="연락처" contentStyle={{ width: '40%' }}>
            <Input
              style={{ width: '150px' }}
              value={searchState.phone}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  phone: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      } else if (row === '분류') {
        const field = Object.keys(searchState);
        return page == 'chatDoctor' ? (
          <Item label="분류" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={
                searchState.m_main_cate ? searchState.m_main_cate : '대분류'
              }
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  m_main_cate: e,
                });
              }}
            >
              {getOptionsByRefId(chat_category1)}
            </Select>
            <Select
              style={{ width: '150px', marginLeft: '10px' }}
              value={searchState.m_sub_cate ? searchState.m_sub_cate : '소분류'}
              onChange={(e) => {
                if (!field.includes('m_main_cate')) {
                  return alert('대분류를 먼저 선택해주세요.');
                } else {
                  setSearchState({
                    ...searchState,
                    m_sub_cate: e,
                  });
                }
              }}
            >
              {getOptionsByRefId(
                chat_category2_by_doctor(searchState.m_main_cate)
              )}
            </Select>
          </Item>
        ) : (
          <Item label="분류" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.category2 ? searchState.category2 : '중분류'}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  category2: e,
                });
              }}
            >
              {getOptionsByRefId(chat_category2)}
            </Select>
            <Select
              style={{ width: '150px', marginLeft: '10px' }}
              value={
                searchState.service_type ? searchState.service_type : '소분류'
              }
              onChange={(e) => {
                if (!field.includes('category2')) {
                  return alert('중분류를 먼저 선택해주세요.');
                } else {
                  setSearchState({
                    ...searchState,
                    service_type: e,
                  });
                }
              }}
            >
              {getOptionsByRefId(chat_service_type(searchState.category2))}
            </Select>
          </Item>
        );
      } else if (row === '상태') {
        return (
          <Item label="상태" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.status}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  status: e,
                });
              }}
            >
              {getOptionsByRefId({
                0: '신청',
                1: '진행중',
                2: '완료',
                9: '취소',
              })}
            </Select>
          </Item>
        );
      } else if (row === '병원종류') {
        return (
          <Item label="병원 종류" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.h_class}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  h_class: e,
                });
              }}
            >
              {getOptionsByRefId(hospital)}
            </Select>
          </Item>
        );
      } else if (row === '정렬') {
        return (
          <Item label="정렬" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.sort}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  sort: e,
                });
              }}
            >
              {page == 'chatDoctor'
                ? getOptionsByRefId(hspt_sort)
                : getOptionsByRefId(inst_sort)}
            </Select>
          </Item>
        );
      } else if (row === '숨김') {
        return (
          <Item label="숨김 상태" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.hide_yn}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  hide_yn: e,
                });
              }}
            >
              {getOptionsByRefId(hide_doctor)}
            </Select>
          </Item>
        );
      } else if (row === '검색') {
        return (
          <Item label="검색" contentStyle={{ width: '40%' }}>
            {page != 'chatCenter' ? (
              <Select
                style={{ width: '150px' }}
                value={searchState.search}
                onChange={(e) => {
                  setSearchState({
                    ...searchState,
                    search: e,
                  });
                }}
              >
                {getOptionsByRefId(search)}
              </Select>
            ) : (
              ''
            )}
            <Input
              style={{ width: '200px', marginLeft: '10px' }}
              value={searchState.search_detail}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  search_detail: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      } else if (row === '지역') {
        const field = Object.keys(searchState);
        return (
          <Item label="지역" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.h_sido}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  h_sido: e,
                });
                page == 'chatDoctor'
                  ? getRegionList(e, setSigungu)
                  : getInstRegionList(e, setSigungu);
              }}
            >
              {getOptionsByRefId(region)}
            </Select>
            <Select
              style={{ width: '150px', marginLeft: '10px' }}
              value={searchState.h_sigungu}
              onChange={(e) => {
                if (!field.includes('h_sido')) {
                  setSearchState({
                    ...searchState,
                    h_sigungu: '',
                  });
                  return alert('시/도를 먼저 선택해주세요.');
                } else {
                  setSearchState({
                    ...searchState,
                    h_sigungu: e,
                  });
                }
              }}
            >
              {getOptionsByRefId(sigungu)}
            </Select>
          </Item>
        );
      } else if (row === '협약 상태') {
        return (
          <Item label="협약 상태" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.contract}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  contract: e,
                });
              }}
            >
              {getOptionsByRefId(constract)}
            </Select>
          </Item>
        );
      } else if (row === '유형 이름') {
        return (
          <Item label="유형 이름" contentStyle={{ width: '40%' }}>
            <Select
              style={{ width: '150px' }}
              value={searchState.ambti}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  ambti: e,
                });
              }}
            >
              {getOptionsByRefId(ambti)}
            </Select>
          </Item>
        );
      } else if (row === '희망일') {
        return (
          <Item label="상담희망일">
            <DatePicker
              style={{ width: '150px' }}
              value={searchState.service_dt}
              onChange={(dates, dateStrings, info) => {
                console.log(typeof dates);
                console.log(dates);
                if (dates == null) {
                  setSearchState({
                    ...searchState,
                    service_dt: '',
                  });
                } else {
                  setSearchState({
                    ...searchState,
                    service_dt: dates,
                  });
                }
              }}
            />
            <Button
              style={{ margin: '5px' }}
              onClick={() => {
                const today = dayjs(new Date());
                setSearchState({
                  ...searchState,
                  service_dt: today,
                });
              }}
            >
              오늘
            </Button>
            <Button
              style={{ margin: '5px' }}
              onClick={() => {
                const tomorrow = dayjs(new Date()).add(1,'day');
                setSearchState({
                  ...searchState,
                  service_dt: tomorrow,
                });
              }}
            >
              내일
            </Button>
          </Item>
        );
      }
    }
  });

export default getChatbotFieldList;
