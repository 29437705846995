import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate, HelperGroupsAddTable } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { helpergroup_type_ref } from 'lib/dataRef';
import { getHelperGroupsList } from 'controllers/Outers/helperGroups';
import HelperGroupsDetailTableContainer from './HelperGroupsDetailTableContainer';

const HelperGroupsListTableContainer = () => {
  const [helperGroups, setHelperGroups] = useState({
    data: [],
    count: 0,
  });
  const [loading, setLoading] = useState(true);
  const [drawerStatus, setDrawerStatus] = useState({
    detail: {
      visible: false,
    },
    document_id: '',
  });
  const showDetailDrawer = (document_id) => {
    setDrawerStatus({
      ...drawerStatus,
      detail: {
        visible: true,
      },
      document_id,
    });
  };
  const onCloseDetailDrawer = () => {
    setDrawerStatus({
      ...drawerStatus,
      detail: {
        visible: false,
      },
      document_id: '',
    });
  };

  useEffect(() => {
    const getDataList = {};
    const fetchDataList = async () => {
      await getHelperGroupsList(setHelperGroups, setLoading);
    };
    fetchDataList();
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => showDetailDrawer(document_id)}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '구분',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (data) => helpergroup_type_ref[data]
    },
    {
      title: '업체명',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
    },
    {
      title: '소속 인원 수',
      dataIndex: 'helper_count',
      key: 'helper_count',
      align: 'center',
    },
    {
      title: '만족도 조사 횟수',
      dataIndex: 'evaluation_num_count',
      key: 'evaluation_num_count',
      align: 'center',
      render: (value) => `${value || 0} 회`,
    },
    {
      title: '만족도 조사 점수',
      dataIndex: 'evaluation_num',
      key: 'evaluation_num',
      align: 'center',
      render: (value, record) => (record.evaluation_num ? `${record.evaluation_num.toFixed(2)} 점` : ''),
    },
    {
      title: '업체 등록일',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.outers.helper.path}
          />
        )}
        countLabel={(<CounterLabel count={helperGroups.count} />)}
        addButton={(<HelperGroupsAddTable />)}
      />
      <ListTableTemplate
        dataSource={helperGroups.data}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
          <>
            <Drawer
              width={400}
              closable
              onClose={onCloseDetailDrawer}
              open={drawerStatus.detail.visible}
            >
              <HelperGroupsDetailTableContainer
                document_id={drawerStatus.document_id}
              />
            </Drawer>
          </>
        )}
      />
    </>
  );
};

export default HelperGroupsListTableContainer;
