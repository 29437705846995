import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination, DelListButton } from 'components/Common';
import { Button, Drawer, Badge } from 'antd';
import { getArtCounselsList } from 'controllers/Outers/artCounsels';
import { getFormattedDate } from 'lib/getFormattedDate';
import ArtCounselsDetailTableConatiner from './ArtCounselsDetailTableConatiner';
import { deleteBundle } from 'controllers/common';
import { routes_ref } from 'lib/routeRef';
import { company_ref, status_ref } from 'lib/dataRef';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const ArtCounselsListTableContainer = () => {
  const [artCounsels, setArtCounsels] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }
  });
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [checkedList, setCheckedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });
  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getArtCounselsList(setArtCounsels, artCounsels, 'first', setCurrentPage, searchState, setLoading);
  }, []);

  function goSearch() {
    getArtCounselsList(setArtCounsels, artCounsels, 'first', setCurrentPage, searchState, setLoading);
  }

  const columns = [
    {
      title: () => {
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.length == list.length}
              onChange={(e) => onCheckedAll(e)}
            />
            전체
          </>
        );
      },
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
        <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={checkedList.includes(document_id)}
            onChange={(e) => onChangeChecked(e, document_id)}
          />
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => {insertInfoAcessLog(location.pathname, 'artCouncelDetail', "조회", undefined, document_id), showDrawer(document_id)}}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data]
    },
    {
      title: '계약자 이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '자녀 이름',
      dataIndex: 'crm_child_nm',
      key: 'crm_child_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data) => {
        let badge;
        switch (data) {
        case 0:
          badge = <Badge status="error" text={status_ref[data]} />;
          break;
        case 1:
          badge = <Badge status="processing" text={status_ref[data]} />;
          break;
        case 2:
          badge = <Badge status="success" text={status_ref[data]} />;
          break;
        case 9:
          badge = <Badge status="default" text={status_ref[data]} />;
          break;
        default:
          break;
        }
        return badge;
      },
    },
    {
      title: '관리자 확인 시각',
      dataIndex: 'admin_check_dt',
      key: 'admin_check_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '완료일자',
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      align: 'center',
      render: (date, record) => {
        let completeDate;
        if (date && (record.status === 2)) {
          completeDate = record.mod_dt;
          return getFormattedDate(completeDate, 'opt2');
        }
        return '-';
      },
    },
    {
      title: '신청일자',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  let list = currentData(artCounsels.data);
  const setPage = (page) => {
    setCurrentPage(page);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  }

  const renderMoveData = (move) => {
    getArtCounselsList(setArtCounsels, artCounsels, move, setCurrentPage, searchState, setLoading);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  }
  const onChangeChecked = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      checkedList.splice(checkedList.indexOf(id), 1);
      setCheckedList([...checkedList]);
    }
  };
  const onCheckedAll = (e) => {
    const checkList = list.map((item) => item.document_id);
    if (e.target.checked) {
      setCheckedList(checkList);
    } else {
      setCheckedList([]);
    }
  };
  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.outers.art_consulting.path}
          />
        )}
        countLabel={(<CounterLabel count={artCounsels.count} />)}
        delButton={
          <DelListButton
            func={deleteBundle}
            data={checkedList}
            location="/art-counsels"
          />
        }
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          page: 'art-counsels',
          field: ['보험사', '이름', '자녀', '연락처', '상태'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(artCounsels.data)}
        columns={columns}
        loading={loading}
        pagination={(
          <Pagination
            totalData={artCounsels.data}
            pagingState={artCounsels.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        )}
        DetailTableContainer={(
          <Drawer
            width={800}
            closable
            onClose={onClose}
            open={status.visible}
          >
            <ArtCounselsDetailTableConatiner
              document_id={status.document_id}
            />
          </Drawer>
        )}
      />
    </>
  );
};

export default ArtCounselsListTableContainer;
