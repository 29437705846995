import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 모든 생체나이 리포트 조회
 * @param {*} setBodyAges client에서 전달하는 React.Dispatch
 * @param {*} setCount client에서 전달하는 React.Dispatch
 * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
 */
export async function getBodyAgesList(
  setBodyAges,
  bodyAges,
  goPage,
  setCurrentPage,
  searchState,
  setLoading
) {
  try {
    const result = await axios
      .post(`${baseUrl}/health/bodyAges/getList`, {
        goPage: goPage,
        pagingState: bodyAges.pagingState,
        searchState: searchState,
      })
      .then((res) => {
        setBodyAges(res.data);
        setCurrentPage(1);
        setLoading(false);
      });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 생체나이 리포트의 정보 조회
 * @param {string} document_id 특정 생체나이 리포트의 document id
 */
export async function getBodyAgeDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/health/bodyAges/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 생체나이 리포트의 수정
 * @param {object} bodyAge 수정하고자 하는 생체나이 리포트의 정보 및 수정될 값
 */
export async function editBodyAgeDetail(bodyAge) {
  // bodyAge === getBodyAgeDetail function's return value
  try {
    if (bodyAge.del_dt > 0) {
      return { result: 'fail', message: 'The bodyAge was deleted.' };
    }
    if (!bodyAge.del_yn) {
      return { result: 'fail', message: 'The bodyAge can only be deleted.' };
    }
    // 값이 undefined 이거나 길이가 0인 경우, 해당 키 삭제
    Object.keys(bodyAge).forEach((v) => {
      if (bodyAge[v] === undefined || bodyAge[v].length === 0) {
        delete bodyAge[v];
      }
    });

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/health/bodyAges/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        bodyAge,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 생체나이 리포트 정보를 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_bodyage_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };
    return await axios
      .post(`${baseUrl}/health/bodyAges/excel`, {
        searchState: searchState,
      })
      .then((res) => {
        return exportXlsx(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';
// import { bodyAge_ref } from 'lib/dataRef';

// /**
//  * 생체나이 리포트 건수 조회
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getBodyAgesSize(app_code) {
//   try {
//     const aplsRef = await firestore.collection('cust_BODYAGES')
//       .get();
//     if (app_code === undefined) { // nothing
//       return aplsRef.size;
//     // 앱코드 값이 있다면, 조회한 값에서 해당 앱코드 값이 적힌 건의 갯수 체크
//     } else {
//       let size = 0;
//       aplsRef.docs.forEach((doc) => {
//         if (doc.data().product_id.includes(app_code)) {
//           size++;
//         }
//       });
//       return size;
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 모든 생체나이 리포트 조회
//  * @param {*} setBodyAges client에서 전달하는 React.Dispatch
//  * @param {*} setCount client에서 전달하는 React.Dispatch
//  * @param {string} app_code 앱마다 할당된 코드(product_id 필드의 값 일부)
//  */
// export async function getBodyAgesList(setBodyAges, setCount, setLoading, app_code) {
//   try {
//     // 컬렉션 내에서 변동이 있을 경우(새로운 생체나이 리포트 추가, 기존 생체나이 리포트 변동 등),
//     // 리로드 없이도 변동 사항을 반영하기 위해 onSnapshot 이용
//     // (onSnapshot에 대한 자세한 사항은 firebase documentation을 참고하길 바람)
//     const aplsRef = await firestore.collection('cust_BODYAGES')
//       .orderBy('reg_dt', 'desc')
//       .onSnapshot((snap) => {
//         const aplDocs = [];
//         const changes = snap.docChanges();

//         changes.forEach((change) => {
//           if (change.type === 'added') {
//             if (changes.length > 1) {
//               const product_id = String(change.doc.data().product_id).split('_');
//               aplDocs.push({
//                 document_id: change.doc.data().document_id,
//                 company_id: change.doc.data().company_id,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: change.doc.data().product_id,
//                 crm_nm: change.doc.data().crm_nm,
//                 crm_phone: change.doc.data().crm_phone,
//                 status: change.doc.data().status,
//                 admin_check_yn: null,
//                 admin_check_dt: null,
//                 reg_dt: change.doc.data().reg_dt,
//                 mod_dt: change.doc.data().mod_dt,
//                 del_yn: change.doc.data().del_yn,
//                 del_dt: change.doc.data().del_dt,
//                 pdf_file: change.doc.data().pdf_file,
//               });
//             } else {
//               snap.forEach((doc) => {
//                 const product_id = String(doc.data().product_id).split('_');
//                 aplDocs.push({
//                   document_id: doc.data().document_id,
//                   company_id: doc.data().company_id,
//                   app_code: product_id[0],
//                   goods_code: product_id[1],
//                   product_id: doc.data().product_id,
//                   crm_nm: doc.data().crm_nm,
//                   crm_phone: doc.data().crm_phone,
//                   status: doc.data().status,
//                   admin_check_yn: null,
//                   admin_check_dt: null,
//                   reg_dt: doc.data().reg_dt,
//                   mod_dt: doc.data().mod_dt,
//                   del_yn: doc.data().del_yn,
//                   del_dt: doc.data().del_dt,
//                   pdf_file: doc.data().pdf_file,
//                 });
//               });
//             }
//           }
//           if (change.type === 'modified') {
//             snap.forEach((doc) => {
//               const product_id = String(doc.data().product_id).split('_');
//               aplDocs.push({
//                 document_id: doc.data().document_id,
//                 company_id: doc.data().company_id,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: doc.data().product_id,
//                 crm_nm: doc.data().crm_nm,
//                 crm_phone: doc.data().crm_phone,
//                 status: doc.data().status,
//                 admin_check_yn: null,
//                 admin_check_dt: null,
//                 reg_dt: doc.data().reg_dt,
//                 mod_dt: doc.data().mod_dt,
//                 del_yn: doc.data().del_yn,
//                 del_dt: doc.data().del_dt,
//                 pdf_file: doc.data().pdf_file,
//               });
//             });
//           }
//           if (change.type === 'removed') {
//             snap.forEach((doc) => {
//               const product_id = String(doc.data().product_id).split('_');
//               aplDocs.push({
//                 document_id: doc.data().document_id,
//                 company_id: doc.data().company_id,
//                 app_code: product_id[0],
//                 goods_code: product_id[1],
//                 product_id: doc.data().product_id,
//                 crm_nm: doc.data().crm_nm,
//                 crm_phone: doc.data().crm_phone,
//                 status: doc.data().status,
//                 admin_check_yn: null,
//                 admin_check_dt: null,
//                 reg_dt: doc.data().reg_dt,
//                 mod_dt: doc.data().mod_dt,
//                 del_yn: doc.data().del_yn,
//                 del_dt: doc.data().del_dt,
//                 pdf_file: doc.data().pdf_file,
//               });
//             });
//           }
//         });
//         setBodyAges(aplDocs);
//         setCount(aplDocs.length);
//         setLoading(false);
//       });
//     return aplsRef;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 생체나이 리포트의 정보 조회
//  * @param {string} document_id 특정 생체나이 리포트의 document id
//  */
// export async function getBodyAgeDetail(document_id) {
//   try {
//     const aplRef = await firestore.collection('cust_BODYAGES')
//       .doc(document_id).get();
//     const product_id = String(aplRef.data().product_id).split('_');
//     return {
//       document_id: aplRef.data().document_id,
//       company_id: aplRef.data().company_id,
//       app_code: product_id[0],
//       goods_code: product_id[1],
//       product_id: aplRef.data().product_id,
//       crm_nm: aplRef.data().crm_nm,
//       crm_phone: aplRef.data().crm_phone,
//       member_nm: aplRef.data().member_nm,
//       birth_dt: aplRef.data().birth_dt,
//       checkup_dt: aplRef.data().checkup_dt,
//       gender: aplRef.data().gender,
//       age: aplRef.data().age,
//       bio_age: aplRef.data().bio_age,
//       ht: aplRef.data().ht,
//       bw: aplRef.data().bw,
//       wc: aplRef.data().wc,
//       sbp: aplRef.data().sbp,
//       dbp: aplRef.data().dbp,
//       tg: aplRef.data().tg,
//       ast: aplRef.data().ast,
//       alt: aplRef.data().alt,
//       fbs: aplRef.data().fbs,
//       cr: aplRef.data().cr,
//       bun: aplRef.data().bun,
//       fvc: aplRef.data().fvc,
//       bf: aplRef.data().bf,
//       status: aplRef.data().status,
//       reg_dt: aplRef.data().reg_dt,
//       mod_dt: aplRef.data().mod_dt,
//       del_yn: aplRef.data().del_yn,
//       del_dt: aplRef.data().del_dt,
//       message: aplRef.data().message,
//       pdf_file: aplRef.data().pdf_file,
//       pdf_down_yn: aplRef.data().pdf_down_yn,
//       pdf_down_dt: aplRef.data().pdf_down_dt,
//       reg_number: aplRef.data().reg_number,
//       ldh: aplRef.data().ldh,
//       cpk: aplRef.data().cpk,
//       tc: aplRef.data().tc,
//       hdl: aplRef.data().hdl,
//       ldl: aplRef.data().ldl,
//       lbm: aplRef.data().lbm,
//       fev1: aplRef.data().fev1,
//       tp: aplRef.data().tp,
//       alb: aplRef.data().alb,
//       glo: aplRef.data().glo,
//       alp: aplRef.data().alp,
//       ggtp: aplRef.data().ggtp,
//       tb: aplRef.data().tb,
//       db: aplRef.data().db,
//       hba1c: aplRef.data().hba1c,
//       amylase: aplRef.data().amylase,
//       ccr: aplRef.data().ccr,
//       usg: aplRef.data().usg,
//       uph: aplRef.data().uph,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 생체나이 리포트의 수정
//  * @param {object} bodyAge 수정하고자 하는 생체나이 리포트의 정보 및 수정될 값
//  */
// export async function editBodyAgeDetail(bodyAge) { // bodyAge === getBodyAgeDetail function's return value
//   try {
//     if (bodyAge.del_dt > 0) {
//       return { result: 'fail', message: 'The bodyAge was deleted.' };
//     }
//     if (!bodyAge.del_yn) {
//       return { result: 'fail', message: 'The bodyAge can only be deleted.' };
//     }
//     // 값이 undefined 이거나 길이가 0인 경우, 해당 키 삭제
//     Object.keys(bodyAge).forEach((v) => {
//       if (bodyAge[v] === undefined || bodyAge[v].length === 0) {
//         delete bodyAge[v];
//       }
//     });
//     const now = Math.floor(new Date().getTime());
//     bodyAge.mod_dt = now;
//     bodyAge.del_dt = now;

//     await firestore.collection('cust_BODYAGES').doc(bodyAge.document_id).set(bodyAge, { merge: true });

//     return { result: 'success', message: 'The bodyAge is updated.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 생체나이 리포트 정보를 엑셀로 추출
//  */
// export async function makeXlsx() {
//   try {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const bdData = [];
//     const aplRef = await firestore.collection('cust_BODYAGES')
//       .orderBy('reg_dt', 'desc').get();
//     aplRef.docs.forEach((doc) => {
//       let status = '';
//       Object.keys(bodyAge_ref).forEach((v) => {
//         if (String(doc.data().status) === v) {
//           status = bodyAge_ref[v];
//         }
//       });
//       bdData.push({
//         '회원 이름': doc.data().crm_nm,
//         '회원 연락처': doc.data().crm_phone,
//         '신청인 이름': doc.data().member_nm,
//         '신청인 생년월일': doc.data().birth_dt,
//         '검사일': doc.data().checkup_dt,
//         '성별': doc.data().gender,
//         '나이': doc.data().age,
//         '생체나이': doc.data().bio_age,
//         '신청 상태': status,
//         '오류 사유': doc.data().message,
//         'ht(키)': doc.data().ht,
//         'bw(몸무게)': doc.data().bw,
//         'wc(허리둘레)': doc.data().wc,
//         'sbp(수축기혈압)': doc.data().sbp,
//         'dbp(이완기혈압)': doc.data().dbp,
//         'tg(중성지방)': doc.data().tg,
//         'ast(지오티)': doc.data().ast,
//         'alt(지피티)': doc.data().alt,
//         'fbs(공복혈당)': doc.data().fbs,
//         'cr(크레아티닌)': doc.data().cr,
//         'bun(혈중요소질소)': doc.data().bun,
//         'fvc(강제폐활량)': doc.data().fvc,
//         'bf(체지방량)': doc.data().bf,
//         'ldh(젖산탈수소효소)': doc.data().ldh,
//         'cpk(씨피케이)': doc.data().cpk,
//         'tc(총콜레스테롤)': doc.data().tc,
//         'hdl(고밀도콜레스테롤)': doc.data().hdl,
//         'ldl(저밀도콜레스테롤)': doc.data().ldl,
//         'lbm(근육량)': doc.data().lbm,
//         'fev1(강제일초량)': doc.data().fev1,
//         'tp(총단백질)': doc.data().tp,
//         'alb(알부민)': doc.data().alb,
//         'glo(글로불린)': doc.data().glo,
//         'alp(에이엘피)': doc.data().alp,
//         'ggtp(감마지티피)': doc.data().ggtp,
//         'tb(총빌리루빈)': doc.data().tb,
//         'db(직접빌리루빈)': doc.data().db,
//         'hba1c(당화혈색소)': doc.data().hba1c,
//         'amylase(아밀라제)': doc.data().amylase,
//         'ccr(크레아티닌제거율)': doc.data().ccr,
//         'usg(요질량)': doc.data().usg,
//         'uph(요산도)': doc.data().uph,
//       });
//     });

//     const exportXlsx = (data) => {
//       const fileName = 'Healthcare_Admin_bodyage_' + Math.floor(new Date().getTime() / 1000);
//       const workSheet = XLSX.utils.json_to_sheet(data);
//       const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
//       const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
//       const blob = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(blob, fileName, fileExtension);
//     };
//     return exportXlsx(bdData);
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }
