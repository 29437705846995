import { useState } from 'react';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const useUpload = () => {
  const initialState = {
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    fileList: [],
  };
  const [upload, setUpload] = useState(initialState);
  const UPLOAD = {};
  UPLOAD.upload = upload;
  UPLOAD.setUpload = setUpload;
  UPLOAD.handleCancel = () => {
    setUpload({
      ...upload,
      previewVisible: false,
    });
  };
  UPLOAD.handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setUpload({
      ...upload,
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };
  UPLOAD.handleChange = ({ fileList }) => {
    setUpload({
      ...upload,
      fileList,
    });
  };
  return UPLOAD;
};

export default useUpload;
