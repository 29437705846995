import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 챗봇 건강검진기관 리스트 조회
 */
export async function getChatCenterList(
  setChatCenter,
  searchState,
  setLoading
) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/center/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        searchState,
      }),
    }).then((response) => response.data.lists);

    setChatCenter(result);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 건강검진기관 의 상세 정보 조회
 * @param {string} i_no 해당 건강검진기관 정보가 저장된 document의 id
 */
export async function getChatCenterDetail(i_no) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/center/getDetail/${i_no}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 건강검진기관 등록
 * @param {object} chatCenter 해당 건강검진기관 등록
 */
export async function addChatCenter(chatCenter) {
  try {
    // 필수입력사항 미입력시 리턴
    if (
      !chatCenter.i_name ||
      !chatCenter.i_rate1 ||
      !chatCenter.i_rate2 ||
      !chatCenter.i_start
    ) {
      return { result: 'fail', message: '미입력사항을' };
    } else if (
      !chatCenter.i_addr ||
      !chatCenter.i_shortaddr ||
      !chatCenter.i_lon ||
      !chatCenter.i_lat
    ) {
      // 주소는 api를 사용해야만 입력 가능(정확한 주소와 좌표값을 위해서)
      return {
        result: 'fail_with_message',
        message: '주소는 검색을 이용해서 입력해주세요',
      };
    } else if (chatCenter.i_end == undefined || chatCenter.i_end == null) {
      // undefined = 아무설정 안함, null = 설정햇다가 삭제(날짜선택이든 체크박스든 선택을 해야함 - 체크박스 선택시 공백)
      return { result: 'fail', message: '협약종료일을 ' };
    }

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/center/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        chatCenter,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 건강검진기관 의 수정
 * @param {object} chatCenter 해당 건강검진기관 정보
 */
export async function editChatCenterDetail(chatCenter) {
  try {
    // 필수입력사항 미입력시 리턴
    if (
      !chatCenter.i_name ||
      !chatCenter.i_rate1 ||
      !chatCenter.i_rate2 ||
      !chatCenter.i_start
    ) {
      return { result: 'fail', message: '미입력사항을' };
    } else if (
      !chatCenter.i_addr ||
      !chatCenter.i_shortaddr ||
      !chatCenter.i_lon ||
      !chatCenter.i_lat
    ) {
      // 주소는 api를 사용해야만 입력 가능(정확한 주소와 좌표값을 위해서)
      return {
        result: 'fail_with_message',
        message: '주소는 검색을 이용해서 입력해주세요',
      };
    } else if (chatCenter.i_end == null) {
      return { result: 'fail', message: '협약종료일을 ' };
    }
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/center/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        chatCenter,
      }),
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 건강검진기관 의 삭제
 * @param {object} i_no 해당 건강검진기관 삭제
 */
export async function deleteChatCenter(i_no) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/center/delete/${i_no}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 지역 조회
 */
export async function getInstRegionList(region, setSigungu) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/instRegion/getList/${region}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data.lists);

    setSigungu(result);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 검진기관 주소찾기
 */
export async function searchAddr() {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/doctor/getSimpyungwon`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ searchState }),
    }).then((response) => response.data);

    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 고객들의 모든 챗봇 건강유형 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_chatCenter_' +
        Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios
      .post(`${baseUrl}/chatbot/center/excel`, {
        searchState: searchState,
      })
      .then((res) => {
        return exportXlsx(res.data);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
