import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import { useCheckAuth } from 'lib/hooks';
import ChattingTableContainer from 'containers/chatbot/Chatting/ChattingTableContainer';

const ChattingPage = ({ pid }) => {
  useCheckAuth(pid);
  console.log('채팅상담');
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['chatbot', 'chatbot-service']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<ChattingTableContainer />}
    />
    // <PageTemplate body={<ChattingTableContainer />} />;
  );
};

export default ChattingPage;
