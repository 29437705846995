import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';
import styles from './HeaderTitle.scss';

const cx = classNames.bind(styles);

const HeaderTitle = () => {
  const TITLE = 'ADMINISTRATOR';
  return (
    <div className={cx('headerTitle')}>
      <Link to="/main">
        {TITLE}
      </Link>
    </div>
  );
};

export default HeaderTitle;
