import React, { useRef, useEffect, useState } from 'react';
import '../../chatbot/Chat.css';
import { getCookie } from 'lib/cookie';
import { encrypt } from 'controllers/common';

const ChattingTableContainer = () => {
  const [iframe, setIframe] = useState(null);
  const [viewMode, setViewMode] = useState('initView');
  const id = encrypt(getCookie('id'));
  const nm = encrypt(getCookie('nm'));
  return (
    <>
      <Newframe viewMode={viewMode} setIframe={setIframe} />
    </>
  );
};

const Newframe = (props) => {
  const iframe = useRef();
  const id = encrypt(getCookie('id'));
  const nm = encrypt(getCookie('nm'));
  useEffect(() => {
    props.setIframe(iframe.current);
  }, []);

  function load() {
    setTimeout(
      () =>
        document.getElementById('chat').contentWindow.window.postMessage(
          { id: id, name: nm },
          // 'https://aichat-admin-nextjs-develop.vercel.app/' // 개발
          'https://aichat-admin-nextjs.vercel.app/' // 운영
        ),
      3000
    );
  }

  return (
    <div id="area" className="content">
      <iframe
        ref={iframe}
        id="chat"
        // src="https://aichat-admin-nextjs-develop.vercel.app/" // 개발
        src="https://aichat-admin-nextjs.vercel.app/" // 운영
        allow="clipboard-read; clipboard-write"
        onLoad={() => {
          load();
        }}
        // scrolling="no"
      />
    </div>
  );
};
export default ChattingTableContainer;
