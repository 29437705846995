import * as api from 'controllers/Services/members';

const GET_MEMBERS_LIST = 'member/GET_MEMBERS_LIST';
const GET_MEMBERS_SIZE = 'member/GET_MEMBERS_SIZE';

export const getList = () => ({
  type: GET_MEMBERS_LIST,
  list: api.getMembersList(),
});
export const getCount = () => ({
  type: GET_MEMBERS_SIZE,
  size: api.getMembersList(),
});

const initialState = {
  count: 0,
  key: '',
};

export default function member(state = initialState, action) {
  switch (action.type) {
  case GET_MEMBERS_LIST:
    return state;
  case GET_MEMBERS_SIZE:
    return {
      ...state,
      count: action.count,
    };
  default:
    return state;
  }
}
