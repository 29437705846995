import React from 'react';
import { Descriptions, Divider } from 'antd';
import Item from 'antd/es/list/Item';
import Paragraph from 'antd/es/typography/Paragraph';
import Tag from 'antd/es/tag';
import { getFormattedDate } from 'lib/getFormattedDate';

const ChatHealthTypeDetailTable = (props) => {
  const { chatHlTypeDetail, setChatHlTypeDetail } = props;

  return chatHlTypeDetail.u_id ? (
    <>
      <Descriptions
        title={chatHlTypeDetail.u_name}
        column={1}
        style={{ width: '100%' }}
        extra={
          '문진일: ' + getFormattedDate(chatHlTypeDetail.created_at, 'opt5')
        }
      />
      <Paragraph>
        <ul>
          <li>미해당 항목은 ‘-‘로 표시됩니다.</li>
          <li>
            고객에게는 암 점수가 30점 이상이면 위험도가 '높음'으로 결과가
            안내됩니다.
          </li>
        </ul>
      </Paragraph>
      <Tag
        color="geekblue"
        style={{
          width: '150px',
          marginBottom: '5px',
          padding: '5px',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '15px',
        }}
      >
        {'건강유형 : ' + chatHlTypeDetail.ambti_ko}
      </Tag>
      <Descriptions bordered column={1} style={{ width: '100%' }}>
        <Item label="성별">{chatHlTypeDetail.no_1.answer_ko[0] || '-'}</Item>
        <Item label="나이">{chatHlTypeDetail.no_2.answer_ko[0] || '-'}</Item>
        <Item label="키(cm)/몸무게(kg)">
          {chatHlTypeDetail.no_3.answer_ko[0] +
            '/' +
            chatHlTypeDetail.no_3.answer_ko[1]}
        </Item>
        <Item label="허리둘레(cm/in)">
          {chatHlTypeDetail.no_4.answer[0] +
            '/' +
            chatHlTypeDetail.no_4.answer[1]}
        </Item>
        <Item label="암 진단 여부">
          {chatHlTypeDetail.no_5.answer_ko[0] || '-'}
        </Item>
        <Item label="질병 유무">
          {chatHlTypeDetail.no_6.answer_ko[0] || '-'}
        </Item>
        <Item label="헬리코박터 파일로리균 유무">
          {chatHlTypeDetail.no_7.answer_ko[0] || '-'}
        </Item>
        <Item label="B형, C형 간염 바이러스 보유 유무">
          {chatHlTypeDetail.no_8.answer_ko[0] || '-'}
        </Item>
        <Item label="흡연 유무">
          {chatHlTypeDetail.no_9.answer_ko[0] || '-'}
        </Item>
        <Item label="흡연 시작">
          {chatHlTypeDetail.no_9_1.answer_ko[0] || '-'}
        </Item>
        <Item label="흡연 기간">
          {chatHlTypeDetail.no_9_2.answer_ko[0] || '-'}
        </Item>
        <Item label="흡연량">
          {chatHlTypeDetail.no_9_3.answer_ko[0] || '-'}
        </Item>
        <Item label="담배 연기 노출 여부">
          {chatHlTypeDetail.no_10.answer_ko[0] || '-'}
        </Item>
        <Item label="음주 여부">
          {chatHlTypeDetail.no_11.answer_ko[0] || '-'}
        </Item>
        <Item label="음주 횟수">
          {chatHlTypeDetail.no_11_1.answer_ko[0] || '-'}
        </Item>
        <Item label="음주량">
          {chatHlTypeDetail.no_11_2.answer_ko[0] || '-'}
        </Item>
        <Item label="운동 유무">
          {chatHlTypeDetail.no_12.answer_ko[0] || '-'}
        </Item>
        <Item label="국물 먹는양">
          {chatHlTypeDetail.no_13.answer_ko[0] || '-'}
        </Item>
        <Item label="짭짤한 반찬 얼마나 자주">
          {chatHlTypeDetail.no_14.answer_ko[0] || '-'}
        </Item>
        <Item label="하루 채소 섭취량">
          {chatHlTypeDetail.no_15.answer_ko[0] || '-'}
        </Item>
        <Item label="하루 과일 섭취량">
          {chatHlTypeDetail.no_16.answer_ko[0] || '-'}
        </Item>
        <Item label="하루 잡곡류 섭취량">
          {chatHlTypeDetail.no_17.answer_ko[0] || '-'}
        </Item>
        <Item label="하루 콩, 두부 섭취량">
          {chatHlTypeDetail.no_18.answer_ko[0] || '-'}
        </Item>
        <Item label="육가공품 얼마나 자주">
          {chatHlTypeDetail.no_19.answer_ko[0] || '-'}
        </Item>
        <Item label="붉은색 고기 얼마나 자주">
          {chatHlTypeDetail.no_20.answer_ko[0] || '-'}
        </Item>
        <Item label="육류 구워 먹는 편">
          {chatHlTypeDetail.no_21.answer_ko[0] || '-'}
        </Item>
        <Item label="육류 탄 부분을 제거 유무">
          {chatHlTypeDetail.no_22.answer_ko[0] || '-'}
        </Item>
        <Item label="우유 및 유제품 매일 섭취 유무">
          {chatHlTypeDetail.no_23.answer_ko[0] || '-'}
        </Item>
        <Item label="평소 식성">
          {chatHlTypeDetail.no_24.answer_ko[0] || '-'}
        </Item>
        <Item label="가족 암 진단 유무">
          {chatHlTypeDetail.no_25.answer_ko[0] || '-'}
        </Item>
        <Item label="가족 질병 유무">
          {chatHlTypeDetail.no_26.answer_ko[0] || '-'}
        </Item>
        <Item label="직업/직종 선택(과거, 현재)">
          {chatHlTypeDetail.no_27.answer_ko[0] || '-'}
        </Item>
        <Item label="환경 노출 경험(과거, 현재)">
          {chatHlTypeDetail.no_28.answer_ko[0] || '-'}
        </Item>
        <Item label="야간 근무 여부">
          {chatHlTypeDetail.no_29.answer_ko[0] || '-'}
        </Item>
        <Item label="도시/시설 근처에 거주 유무(과거, 현재)">
          {chatHlTypeDetail.no_30.answer_ko[0] || '-'}
        </Item>
        <Item label="월경 시작일">
          {chatHlTypeDetail.no_31.answer_ko[0] || '-'}
        </Item>
        <Item label="현재 월경 상태">
          {chatHlTypeDetail.no_32.answer_ko[0] || '-'}
        </Item>
        <Item label="폐경 시기">
          {chatHlTypeDetail.no_32_1.answer_ko[0] || '-'}
        </Item>
        <Item label="자녀 출산수">
          {chatHlTypeDetail.no_33.answer_ko[0] || '-'}
        </Item>
        <Item label="첫 출산 시기">
          {chatHlTypeDetail.no_33_1.answer_ko[0] || '-'}
        </Item>
        <Item label="모유 수유 여부 및 기간">
          {chatHlTypeDetail.no_33_2.answer_ko[0] || '-'}
        </Item>
        <Item label="알파벳 유형">{chatHlTypeDetail.ambti || '-'}</Item>
        <Item label="알파벳 이름">{chatHlTypeDetail.ambti_ko || '-'}</Item>
        <Item label="갑상선암 점수">{chatHlTypeDetail.thyroid_pt || '-'}</Item>
        <Item label="폐암 점수">{chatHlTypeDetail.lung_pt || '-'}</Item>
        <Item label="대장암 점수">{chatHlTypeDetail.colorectal_pt || '-'}</Item>
        <Item label="위암 점수">{chatHlTypeDetail.gastric_pt || '-'}</Item>
        <Item label="유방암 점수">{chatHlTypeDetail.ovarian_pt || '-'}</Item>
        <Item label="전립선암 점수">{chatHlTypeDetail.breast_pt || '-'}</Item>
        <Item label="간암 점수">{chatHlTypeDetail.liver_pt || '-'}</Item>
        <Item label="췌장암 점수">{chatHlTypeDetail.pancreatic_pt || '-'}</Item>
        <Item label="난소암 점수">{chatHlTypeDetail.prostate_pt || '-'}</Item>
      </Descriptions>
      <Divider />
    </>
  ) : (
    ''
  );
};

export default ChatHealthTypeDetailTable;
