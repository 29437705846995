import React from 'react';
import { Row, Input, Descriptions, Divider, Space, Radio, Select } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import { CancelButton, SaveButton } from 'components/Common';
import { company_ref, product_id_ref } from 'lib/dataRef';
import { editHashDetail } from 'controllers/Systems/appHash';
import getOptionsByRefId from 'lib/getOptionsByRefId';

const { Item } = Descriptions;
const AppHashDetailTable = (props) => {
  const { hashDetail, setHashDetail } = props;
  const prooductIdOptions = () => (getOptionsByRefId(product_id_ref));
  return (
    <>
      <Row>
        <Descriptions
          title="버전 관리 정보"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="보험사">{company_ref[hashDetail.company_id]}</Item>
          <Item label="상품코드">
            <Select
              style={{ width: '200px' }}
              value={product_id_ref[hashDetail.product_id]}
              onChange={(e) => setHashDetail({ ...hashDetail, product_id: e })}
              >
                  {prooductIdOptions()}
            </Select>
          </Item>
          <Item label="os 구분">
                <Select
                value={hashDetail.os}
                style={{ width: '150px' }}
                onChange={(e) => setHashDetail({ ...hashDetail, os: e })}
                options={[
                    {
                        value: 'AOS',
                        label: 'Android'
                    },
                    {
                        value: 'IOS',
                        label: 'IOS'
                    },
                ]}
                />
            </Item>
          <Item label="해시 키">
            <Input
              value={hashDetail.hash}
              onChange={(e) => setHashDetail({ ...hashDetail, hash: e.target.value })}
            />
          </Item>
          <Item label="등록일">{getFormattedDate(hashDetail.reg_dt, 'opt2')}</Item>
          <Item label="수정시각">{getFormattedDate(hashDetail.mod_dt, 'opt2')}</Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/app-hash"
          />
          <SaveButton
            func={editHashDetail}
            data={hashDetail}
            location="/app-hash"
          />
        </Space>
      </Row>
    </>
  );
};

export default AppHashDetailTable;
