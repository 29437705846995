
import dot_green from 'assets/icons/dot_green.png';
import dot_red from 'assets/icons/dot_red.png';
import dot_yellow from 'assets/icons/dot_yellow.png';

export const getFilteredPain_ref = (row) => {
  const type = row.cd;
  const value = Number(row.value);
  let imgUrl;
  if(row){
    switch(type) {
      case 'P01_01':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P01_02':
        if(value <= 2) {
          imgUrl = dot_green;
        } else if(value === 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P02_01':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P02_02':
        if(value === 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P03_01':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value > 1 && value <= 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P03_02':
        if(value <= 2) {
          imgUrl = dot_green;
        } else if(value === 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P03_03':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P04_01':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P04_02':
        if(value <= 2) {
          imgUrl = dot_green;
        } else if(value === 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P05_01':
        if(value <= 2) {
          imgUrl = dot_green;
        } else if(value === 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'P06_01':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value > 1 && value <= 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R01':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value > 1 && value <= 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R02':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R03':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value > 1 && value <= 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R04':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R05':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R06_01':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R07':
        if(value < 1) {
          imgUrl = dot_green;
        } else if(value === 1) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R08':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value === 2) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      case 'R09':
        if(value <= 1) {
          imgUrl = dot_green;
        } else if(value > 1 && value <= 3) {
          imgUrl = dot_yellow;
        } else {
          imgUrl = dot_red;
        }
      break;
      default:
        imgUrl = '-';
      break;
    }
    return imgUrl;
  }
  return '-';
  
};

export const getProgramRecommend_ref = (cd) => {
    let value;
    if(cd){
        switch(cd) {
        case 'P01':
            value = '목 피로예방 (VDT증후군, 거북목)'
        break;
        case 'P02':
            value = '어깨 피로예방 (오십견, 어깨충돌증후군)'
        break;
        case 'P03':
            value = '허리 피로예방 (요추염좌, 천장관절증후군)'
        break;
        case 'P04':
            value = '다리 피로예방 (퇴행성무릎, 고관절염)'
        break;
        case 'P05':
            value = '손 피로예방 (손목터널 증후군)'
        break;
        case 'P06':
            value = '발 피로예방 (족저근막염)'
        break;
        case 'R01':
            value = '목 디스크 재발방지'
        break;
        case 'R02':
            value = '허리 디스크 재발방지'
        break;
        case 'R03':
            value = '허리 협착증 재발방지'
        break;
        case 'R04':
            value = '오십견 재발방지'
        break;
        case 'R05':
            value = '회전근개파열 재발방지'
        break;
        case 'R06':
            value = '반월상연골손상 재발방지'
        break;
        case 'R07':
            value = '십자인대파열 재발방지'
        break;
        case 'R08':
            value = '류마티스관절염재발방지 재발방지'
        break;
        case 'R09':
            value = '골다공증 재발방지'
        break;
        default:
            value = '-';
        break;
        }
        return value;
    }
    return '-';
  
  };

export const setTotalEvalue_ref = (data, setTotalEvalue) => {
  const keys = Object.keys(data);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i] // 각각의 키
    const value = data[key] // 각각의 키에 해당하는 각각의 값
    if( key === 'bbiddaki') {
      setTotalEvalue({ ...value, score: value["scores"][0].score, desc: value["scores"][0].title});
    }
  }
};

export const getFilteredWeeklyExerciseDay_ref = (week) => {
  let exercise_day;
  if(week) {
    if(week === 1 || week === 3 || week === 5 || week === 7) {
      exercise_day = '화,목';
    } else {
      exercise_day = '월,수,금';
    }
    return exercise_day;
  }
  return '-';
}

export const getSplitBbiddakiResult_ref = (text) => {
  let splitArr = [];
  if(text) {
    splitArr = text.split('(');
    return splitArr[0];
  }
  return '-';
};

export const getFilteredSelfCheckPain_ref = (pain) => {
  let painValue;
  if(pain) {
    if(pain === 0 || 1) {
      return painValue = '아프지 않다.';
    } else if(pain === 2 || 3) {
      return painValue = '조금 아프다.';
    } else if(pain === 4 || 5) {
      return painValue = '조금 더 아프다.';
    } else if(pain === 6 || 7) {
      return painValue = '통증이 보통 이상이다.';
    } else if(pain === 8 || 9) {
      return painValue = '꽤 많이 아프다.';
    } else {
      return painValue = '굉장히 아프고 참기 힘들다.';
    }
  }
  return '-';
}

export const getFilteredSelfTest_ref = (value, index) => {

  let returnValue = '';
  if(index === 4) {
    if(value) {
      returnValue = '예';
    } else {
      returnValue = '아니오';
    }
  } else if(index === 5) {
    if(value) {
      returnValue = '예';
    } else {
      returnValue = '아니오';
    }
  } else {
    returnValue = value;
  }
  return returnValue;
  
}

