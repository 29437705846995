import React, { useEffect, useState } from 'react';
import {
  CounterLabel,
  LinkButton,
  ListTableTemplate,
  ExcelModal,
} from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Button, Drawer, Spin } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import { routes_ref } from 'lib/routeRef';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import {
  getChatHealthTypeList,
  makeXlsx,
} from 'controllers/chatbot/chatbotHealthType';
import ChatHealthTypeDetailTableContainer from './ChatHealthTypeDetailTableContainer';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const ChatHealthTypeTableContainer = () => {
  const [chatHlTypes, setChatHlTypes] = useState([{}]);
  const [status, setStatus] = useState({
    visible: false,
    s_id: '',
  });
  const [searchState, setSearchState] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
  const onClose = () => {
    setStatus({
      visible: false,
      s_id: '',
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const showDrawer = (s_id) => {
    setStatus({
      visible: true,
      s_id,
    });
  };
  const excelDownload = () => {
    insertInfoAcessLog(location.pathname, 'makeXlsx', '다운로드', excelReason);
    makeXlsx(excelReason, searchState);
  };
  useEffect(() => {
    getChatHealthTypeList(setChatHlTypes, searchState, setLoading);
  }, []);

  function goSearch() {
    getChatHealthTypeList(setChatHlTypes, searchState, setLoading);
  }
  const columns = [
    {
      title: '',
      dataIndex: 's_id',
      key: 's_id',
      align: 'center',
      render: (s_id, record) => (
        <>
          <Button
            key={s_id}
            type="primary"
            size="small"
            onClick={() => {
              insertInfoAcessLog(
                location.pathname,
                'chatHealthTypeDetail',
                '조회',
                undefined,
                record.user_id
              ),
                showDrawer(s_id);
            }}
          >
            상세
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'c_name',
      key: 'c_name',
    },
    {
      title: '이름',
      dataIndex: 'u_name',
      key: 'u_name',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'u_phone',
      key: 'u_phone',
      render: (data) => maskPhone(data),
    },

    {
      title: '유형 이름',
      dataIndex: 'ambti_ko',
      key: 'ambti_ko',
    },
    {
      title: '문진일',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton
            ctx="전체목록"
            link={routes_ref.base.chatbot.health_type.path}
            originColor="#565e8c"
            hoveredColor="#00854a"
          />
        }
        countLabel={<CounterLabel count={chatHlTypes.length} />}
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setModalIsOpen(true);
              // makeXlsx();
            }}
          >
            엑셀 다운로드
          </Button>
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'chatApply',
          field: ['보험사', '이름', '연락처', '유형 이름'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      {loading ? (
        <Spin tip="Loading" size="large">
          <ListTableTemplate columns={columns} />
        </Spin>
      ) : (
        <ListTableTemplate
          dataSource={chatHlTypes}
          columns={columns}
          DetailTableContainer={
            <Drawer
              width={750}
              closable
              onClose={onClose}
              open={status.visible}
            >
              <ChatHealthTypeDetailTableContainer s_id={status.s_id} />
            </Drawer>
          }
        />
      )}
      <div>
        {modalIsOpen && (
          <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          ></ExcelModal>
        )}
      </div>
    </>
  );
};

export default ChatHealthTypeTableContainer;
