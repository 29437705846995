import React from 'react';
import { Modal, Input } from 'antd';

const ExcelModal = (props) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      props.excelDownload()
      props.modalIsOpen(false)
    }
  };

    return (
        <Modal
            open={props.open}
            cancelText={"닫기"}
            okText={"확인"}
            onCancel={()=> props.modalIsOpen(false)}
            onOk={()=> {props.excelDownload(), props.modalIsOpen(false)}}
            title={"다운로드 사유를 입력하세요."}
            closable={false}
          >
            <p>다운로드 시 시간이 걸릴 수 있습니다.</p>
            <Input onChange={(e) => props.excelReason(e.target.value)}
            onKeyPress={(e) => onKeyPress(e)}/>
          </Modal>
    )
}

export default ExcelModal;