import React, { useEffect, useState } from 'react';
import {
  CounterLabel,
  LinkButton,
  ListTableTemplate,
  ExcelModal,
} from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Badge, Button, Drawer, Spin } from 'antd';
import { status_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { routes_ref } from 'lib/routeRef';
import ChatApplyDetailTableContainer from './ChatApplyDetailTableContainer';
import { getChatApplysList, makeXlsx } from 'controllers/chatbot/chatbotApply';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const ChatApplyTableContainer = () => {
  const [chatApplys, setChatApplys] = useState([{}]);
  const [status, setStatus] = useState({
    visible: false,
    ps_no: '',
  });
  const [searchState, setSearchState] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
  const onClose = () => {
    setStatus({
      visible: false,
      ps_no: '',
    });
  };

  const showDrawer = (ps_no) => {
    setStatus({
      visible: true,
      ps_no,
    });
  };

  const excelDownload = () => {
    insertInfoAcessLog(location.pathname, 'makeXlsx', '다운로드', excelReason);
    makeXlsx(excelReason, searchState);
  };
  useEffect(() => {
    getChatApplysList(setChatApplys, searchState, setLoading);
  }, []);
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  function goSearch() {
    getChatApplysList(setChatApplys, searchState, setLoading);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'ps_no',
      key: 'ps_no',
      align: 'center',
      width: '5%',
      render: (ps_no, record) => (
        <>
          <Button
            key={ps_no}
            type="primary"
            size="small"
            onClick={() => {
              insertInfoAcessLog(
                location.pathname,
                'chatDetail',
                '조회',
                undefined,
                record.member_id
              ),
                showDrawer(ps_no);
            }}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '신청분류',
      dataIndex: 'product_id',
      key: 'product_id',
      align: 'center',
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'member_nm',
      key: 'member_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'member_ph',
      key: 'member_ph',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '중분류',
      dataIndex: 'category2',
      key: 'category2',
      align: 'center',
    },
    {
      title: '소분류',
      dataIndex: 'service_type',
      key: 'service_type',
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data) => {
        let badge;
        switch (data) {
          case 0:
          case 10:
            badge = <Badge status="error" text={status_ref[data]} />;
            break;
          case 1:
          case 11:
          case 12:
            badge = <Badge status="processing" text={status_ref[data]} />;
            break;
          case 2:
          case 13:
          case 14:
            badge = <Badge status="success" text={status_ref[data]} />;
            break;
          case 9:
          case 19:
            badge = <Badge status="default" text={status_ref[data]} />;
            break;
          case 99:
            badge = <Badge status="default" text={status_ref[9]} />;
            break;
          default:
            break;
        }
        return badge;
      },
    },
    {
      title: '관리자 확인 시각',
      dataIndex: 'admin_check_dt',
      key: 'admin_check_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '신청일자',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '상담 희망일자',
      dataIndex: 'service_period',
      key: 'reg_service_period_dt',
      align: 'center',
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton
            ctx="전체목록"
            link={routes_ref.base.chatbot.chat_apply.path}
            originColor="#565e8c"
            hoveredColor="#00854a"
          />
        }
        countLabel={<CounterLabel count={chatApplys.length} />}
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setModalIsOpen(true);
              // makeChatApplysXlsx(searchState);
            }}
          >
            엑셀 다운로드
          </Button>
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'chatApply',
          field: ['보험사', '이름', '연락처', '상태', '분류', '희망일'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      {loading ? (
        <Spin tip="Loading" size="large">
          <ListTableTemplate columns={columns} />
        </Spin>
      ) : (
        <ListTableTemplate
          dataSource={chatApplys}
          columns={columns}
          DetailTableContainer={
            <Drawer
              width={750}
              closable
              onClose={onClose}
              open={status.visible}
            >
              <ChatApplyDetailTableContainer ps_no={status.ps_no} />
            </Drawer>
          }
        />
      )}
      <div>
        {modalIsOpen && (
          <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          ></ExcelModal>
        )}
      </div>
    </>
  );
};

export default ChatApplyTableContainer;
