import React, { useEffect, useState } from 'react';
import { getCancerLDetail } from 'controllers/HealthLogs/cancerLogs';
import { CancerLogDetailTable } from 'components/Common';
import { Spin } from 'antd';

const CancerLogDetailTableContainer = ({ member_id, category, visible, onClose }) => {
  const initCancerLogDetail = {
    category: '',
    company_id: '',
    crm_nm: '',
    crm_phone: '',
    document_id: '',
    lists: [],
    member_id: '',
  };
  const [cancerLogDetail, setCancerLogDetail] = useState(initCancerLogDetail);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (member_id) {
      try {
        setCancerLogDetail(initCancerLogDetail);
        setLoading(true);
        const data = getCancerLDetail(member_id, category);
        data.then((d) => {
          setCancerLogDetail(d);
          setLoading(false);
        });
      } catch (e) {
        console.log(e);
        alert('로딩이 정상적으로 처리되지 않았습니다');
      }
    }
  }, [member_id]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <CancerLogDetailTable cancerLogDetail={cancerLogDetail} category={category} visible={visible} onClose={onClose} />
      </Spin>
    :
      <CancerLogDetailTable cancerLogDetail={cancerLogDetail} category={category} visible={visible} onClose={onClose} />
  );
};

export default CancerLogDetailTableContainer;
