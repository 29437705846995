import React, { useEffect, useState } from 'react';
import { ApplyDetailTable } from 'components/Common';
import { getApplyDetail } from 'controllers/Services/applies';
import { Spin } from 'antd';

const ApplyDetailTableContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [applyDetail, setApplyDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setApplyDetail({});
      setLoading(true);
      const data = getApplyDetail(document_id);
      data.then((d) => {
        setApplyDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <ApplyDetailTable applyDetail={applyDetail} setApplyDetail={setApplyDetail}/>
      </Spin>
    :
      <ApplyDetailTable applyDetail={applyDetail} setApplyDetail={setApplyDetail} />
  );
};

export default ApplyDetailTableContainer;
