import React, { useEffect, useState } from 'react';
import { getGrowthRecDetail } from 'controllers/HealthLogs/growthRecs';
import { GrowthRecsDetailTable } from 'components/Common';
import { Spin } from 'antd';

const GrowthRecsDetailTableContainer = ({ crm_child_id, crm_child_gender }) => {
  const [loading, setLoading] = useState(true);
  const [growthRecDetail, setGrowthRecDetail] = useState({});
  useEffect(() => {
    if (crm_child_id && crm_child_gender) {
      setLoading(true);
      setGrowthRecDetail({});
      const data = getGrowthRecDetail(crm_child_id, crm_child_gender);
      data.then((d) => {
        setGrowthRecDetail(d);
        setLoading(false);
      });
    }
  }, [crm_child_id, crm_child_gender]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <GrowthRecsDetailTable growthRecDetail={growthRecDetail} />
      </Spin>
    :
      <GrowthRecsDetailTable growthRecDetail={growthRecDetail} />
  );
};

export default GrowthRecsDetailTableContainer;
