import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { BbiddakiApiLogDetailTable } from 'components/Common';

const BbiddakiApiLogDetailTableContainer = ({ props }) => {
  const [loading, setLoading] = useState(true);
  const [dataState, setDataState] = useState([{}]);

  useEffect(() => {
    if (props) {
      setDataState(props);
      setLoading(false);
    }
  }, [props]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <BbiddakiApiLogDetailTable dataState={dataState} />
      </Spin>
    :
      <BbiddakiApiLogDetailTable dataState={dataState} />
  );
};

export default BbiddakiApiLogDetailTableContainer;
