 import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination, ExcelModal } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { Button } from 'antd';
import { getInfoAcessLogList, makeXlsx, insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { maskName } from 'lib/maskPersonInfo';

const AdminInfoAcessLogTableContainer = () => {
  const [adminLogs, setAdminLogs] = useState({ 
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchState, setSearchState] = useState({});   // 검색어
  useEffect(() => {
    getInfoAcessLogList(setAdminLogs, adminLogs, 'first', setCurrentPage, searchState, setLoading);
  }, []);
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  function goSearch() {
    getInfoAcessLogList(setAdminLogs, adminLogs, 'first', setCurrentPage, searchState, setLoading);
  }
  // 메뉴 찾기
  const findMenuPath = (data) => {
    for (const key in routes_ref.base) {
      let pathList = routes_ref.base[key];

      for (const path in pathList) {
        if (path !== 'default_label' && pathList[path].path === data) {
          return pathList[path].label.label;
        }
      }
    }
    return '-';
  }
  const excelDownload = () =>{  
    insertInfoAcessLog(location.pathname, 'makeXlsx', "다운로드", excelReason);
    makeXlsx(excelReason, searchState); 
  } 
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유

  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n); 
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  const setPage = (page) => {
    setCurrentPage(page)
  }
  const renderMoveData = (move) => {
    getInfoAcessLogList(setAdminLogs, adminLogs, move, setCurrentPage, searchState, setLoading);
  }
  const columns = [
    {
      title: '아이디',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      // render: (data) => maskName(data),
    },
    {
      title: '메뉴',
      dataIndex: 'location',
      key: 'location',
      align: 'center',
      render: (data) => findMenuPath(data),
    },
    {
      title: '호출함수',
      dataIndex: 'cont',
      key: 'cont',
      align: 'center',
      render: (data) => (data ? data : '-'),
    },
    {
      title: '처리대상',
      dataIndex: 'infoText',
      key: 'infoText',
      align: 'center',
      render: (data) => (data ? maskName(data) : '-'),
    },
    {
      title: '타입',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
    },
    {
      title: '다운로드 사유',
      dataIndex: 'excel_reason',
      key: 'excel_reason',
      align: 'center',
      render: (data) => (data ? data : '-'),
    },
    {
      title: 'ip',
      dataIndex: 'ip',
      key: 'ip',
      align: 'center',
      render: (data) => (data ? data : '-'),
    },
    {
      title: '등록일시',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.systems.admin_access_log.path}
          />
        )}
        countLabel={(<CounterLabel count={adminLogs.count} />)}
        xlsButton={(
          <Button
              type="primary"
              style={{ float: 'right', marginLeft: '8px' }}
              onClick={() => {
                setModalIsOpen(true);
              }}
            >
            엑셀 다운로드
          </Button>
        )}
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['아이디', '타입', '등록일'],
          page: "accessInfo",
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(adminLogs.data)}
        columns={columns}
        loading={loading}
        pagination={(
          <Pagination
            totalData={adminLogs.data}
            pagingState={adminLogs.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        )}
      />
      <div>
        {
          modalIsOpen && (
            <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
             </ExcelModal>
        )}
      </div>
    </>
  );
};

export default AdminInfoAcessLogTableContainer;
