import React from 'react';
import { Select, Descriptions, Input, DatePicker } from 'antd';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import {
  company_ref,
  subscribe_goods_ref,
  os_ref,
  category_ref,
  sort_ref,
  body_age_status_ref,
  product_id_ref,
  status_ref,
} from 'lib/dataRef';

const { Item } = Descriptions;
const getFieldList = (ref, setSearchState, searchState, page, onKeyPress) =>
  ref.map((row) => {
    if (row !== '') {
      if (row === '보험사') {
        return (
          <Item label={row}>
            <Select
              style={{ width: '150px' }}
              value={searchState.company_id}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  company_id: e,
                });
              }}
            >
              {getOptionsByRefId(company_ref)}
            </Select>
          </Item>
        );
      } else if (row === '제휴사') {
        return (
          <Item label="제휴사">
            <Select
              style={{ width: '150px' }}
              value={searchState.subscribe_goods}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  subscribe_goods: e,
                });
              }}
            >
              {getOptionsByRefId(subscribe_goods_ref)}
            </Select>
          </Item>
        );
      } else if (row === '등록일') {
        return (
        // page == 'accessInfo' || page == 'loginLogs' ? (
          <Item label="등록일">
            <DatePicker
              style={{ width: '150px' }}
              value={searchState.reg_dt1}
              onChange={(dates, dateStrings, info) => {
                if (dates == null) {
                  setSearchState({
                    ...searchState,
                    reg_dt1: '',
                  });
                } else {
                  // var today = new Date();
                  // var time =
                  //   today.getHours() +
                  //   ':' +
                  //   today.getMinutes() +
                  //   ':' +
                  //   today.getSeconds();
                  // setSearchState({
                  //   ...searchState,
                  //   reg_dt1: Date.parse(dateStrings + ' ' + time),
                  // });
                  setSearchState({
                    ...searchState,
                    reg_dt1: dates
                  })
                }
              }}
            />
            &nbsp;-&nbsp;
            <DatePicker
              style={{ width: '150px' }}
              value={searchState.reg_dt2}
              onChange={(dates, dateStrings, info) => {
                if (dates == null) {
                  setSearchState({
                    ...searchState,
                    reg_dt2: '',
                  });
                } else {
                  // var today = new Date();
                  // var time =
                  //   today.getHours() +
                  //   ':' +
                  //   today.getMinutes() +
                  //   ':' +
                  //   today.getSeconds();
                  // setSearchState({
                  //   ...searchState,
                  //   reg_dt2: Date.parse(dateStrings + ' ' + time),
                  // });
                  setSearchState({
                    ...searchState,
                    reg_dt2: dates
                  })
                }
              }}
            />
          </Item>
        )
        //  : (
        //   <Item label="등록일">
        //     <DatePicker
        //       style={{ width: '150px' }}
        //       value={searchState.subscribe_start_dt1}
        //       onChange={(moment) => {
        //         if (moment == null) {
        //           setSearchState({
        //             ...searchState,
        //             subscribe_start_dt1: '',
        //           });
        //         } else {
        //           setSearchState({
        //             ...searchState,
        //             subscribe_start_dt1: moment.format(
        //               'YYYY-MM-DDT00:00:00.000'
        //             ),
        //           });
        //         }
        //       }}
        //     />
        //     &nbsp;-&nbsp;
        //     <DatePicker
        //       style={{ width: '150px' }}
        //       value={searchState.subscribe_start_dt2}
        //       onChange={(moment) => {
        //         if (moment == null) {
        //           setSearchState({
        //             ...searchState,
        //             subscribe_start_dt2: '',
        //           });
        //         } else {
        //           setSearchState({
        //             ...searchState,
        //             subscribe_start_dt2: moment.format(
        //               'YYYY-MM-DDT00:00:00.000'
        //             ),
        //           });
        //         }
        //       }}
        //     />
        //   </Item>
        // );
      } else if (row === '이름') {
        return (
          <Item label="이름">
            <Input
              style={{ width: '150px' }}
              value={searchState.nm}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  nm: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      } else if (row === '연락처') {
        return (
          <Item label="연락처">
            <Input
              style={{ width: '150px' }}
              value={searchState.phone}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  phone: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      } else if (row === '상태') {
        return (
          <Item label="상태">
            <Select
              style={{ width: '150px' }}
              value={searchState.status}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  status: e,
                });
              }}
            >
              {page === 'body-ages' || page === 'dzPreds'
                ? getOptionsByRefId(body_age_status_ref)
                : page === 'dentals'
                ? getOptionsByRefId({ 0: '신청', 8: '취소', 9: '취소(롤백)' })
                : page === 'apply'
                ? getOptionsByRefId(status_ref)
                : getOptionsByRefId({
                    0: '신청',
                    1: '진행중',
                    2: '완료',
                    9: '취소',
                  })}
            </Select>
          </Item>
        );
      } else if (row === '구분') {
        return (
          <Item label="구분">
            <Select
              style={{ width: '150px' }}
              value={searchState.av_tp}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  av_tp: e,
                });
              }}
            >
              {getOptionsByRefId(os_ref)}
            </Select>
          </Item>
        );
      } else if (row === '자녀') {
        return (
          <Item label="자녀">
            <Input
              style={{ width: '150px' }}
              value={searchState.crm_child_nm}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  crm_child_nm: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      } else if (row === '대분류') {
        const field = Object.keys(searchState);
        return (
          <Item label="대분류">
            <Select
              style={{ width: '150px' }}
              value={searchState.category1}
              onChange={(e) => {
                if (!field.includes('company_id')) {
                  setSearchState({
                    ...searchState,
                    category1: '',
                  });
                  return alert('보험사를 먼저 선택해주세요.');
                } else {
                  setSearchState({
                    ...searchState,
                    category1: e,
                  });
                }
              }}
            >
              {getOptionsByRefId(category_ref(searchState.company_id).ref1)}
            </Select>
          </Item>
        );
      } else if (row === '중분류') {
        const field = Object.keys(searchState);
        return (
          <Item label="중분류">
            <Select
              style={{ width: '150px' }}
              value={searchState.category2}
              onChange={(e) => {
                if (!field.includes('company_id')) {
                  setSearchState({
                    ...searchState,
                    category2: '',
                  });
                  return alert('보험사를 먼저 선택해주세요.');
                } else {
                  setSearchState({
                    ...searchState,
                    category2: e,
                  });
                }
              }}
            >
              {getOptionsByRefId(category_ref(searchState.company_id).ref2)}
            </Select>
          </Item>
        );
      } else if (row === '걸음') {
        return (
          <Item label="걸음">
            <Select
              style={{ width: '150px' }}
              value={searchState.sort_step}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  sort_step: e,
                });
              }}
            >
              {getOptionsByRefId(sort_ref)}
            </Select>
          </Item>
        );
      } else if (row === '포인트') {
        return (
          <Item label="포인트">
            <Select
              style={{ width: '150px' }}
              value={searchState.sort_point}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  sort_point: e,
                });
              }}
            >
              {getOptionsByRefId(sort_ref)}
            </Select>
          </Item>
        );
      } else if (row === '앱 명칭' || row === '상품코드') {
        return (
          <Item label={row}>
            <Select
              style={{ width: '220px' }}
              value={searchState.product_id}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  product_id: e,
                });
              }}
            >
              {getOptionsByRefId(product_id_ref)}
            </Select>
          </Item>
        );
      } else if (row === '아이디') {
        return (
          <Item label="아이디">
            <Input
              style={{ width: '150px' }}
              value={searchState.id}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  id: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      } else if (row === '타입') {
        return (
          <Item label="타입">
            <Input
              style={{ width: '150px' }}
              value={searchState.type}
              onChange={(e) => {
                setSearchState({
                  ...searchState,
                  type: e.target.value,
                });
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </Item>
        );
      }
    }
  });

export default getFieldList;
