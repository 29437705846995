/* eslint-disable jsx-a11y/label-has-associated-control,max-len */
import React, { Fragment, useEffect, useState } from 'react';
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Rate,
  Select,
  Descriptions,
  Steps,
} from 'antd';
import Title from 'antd/es/typography/Title';
import {
  category_ref,
  company_ref,
  cp_id_mapper,
  crm_return_type_ref,
  evaluation_ref,
  helpergroup_type_ref,
  p_apply_category2_array_ref,
  premium_helper_type_ref,
  status_ref,
} from 'lib/dataRef';
import getOptionsByRefId from 'lib/getOptionsByRefId';
import {
  deleteApply,
  editApplyDetail,
  getApplyDetail,
  getHelperDetail,
  getHelperGroupDetail,
  getHelperGroupsList,
  getHelpersList,
  insertApply,
  insertHelper,
  sendMessage,
} from 'controllers/Services/applies';
import usePreventLeave from 'lib/hooks/usePreventLeave';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { baseUrl } from 'lib/url';

const { Item } = Descriptions;
const { Option } = Select;
const { TextArea } = Input;
dayjs.extend(utc);
dayjs.extend(timezone);

const PApplyAddContainer = ({ props }) => {
  const { enablePrevent, disablePrevent } = usePreventLeave();
  let { p_apply_category2_array } = props.state;
  const { document_id, category1 } = props.state;
  const typeA = ['carerSupport', 'housekeeper', 'rehabilitationExercise', 'companion'];
  const typeB = ['psychologyCounsel', 'nurseAccompany'];
  // FIXME: 고객 신규 등록 시, 상담원의 휴먼에러를 방지 하기 위해서 fix 해놓은 부분
  const samsung_hard_setting = {
    company_id: 'L03',
    product_id: 'SamsungLife01_0000',
  };
  const initP_applyState = {
    document_id,
    // 프리미엄 서비스 선택
    category1: 'premiumCare',
    category2:
      Array.isArray(p_apply_category2_array) &&
      p_apply_category2_array.length < 2
        ? p_apply_category2_array[0]
        : '',

    // 진행상태
    status: 10,
    // 고객 정보
    company_id: '',
    product_id: '',

    crm_nm: '',
    crm_phone: '',
    crm_member_type: '',
    // 신청자 정보
    member_id: '',
    member_nm: '',
    member_ph: '',
    member_addr: '',
    service_nm: '',
    service_dt: '',
    service_days: 1,
    service_hours: 8,
    reg_dt: '',

    // TODO: 설희님과 변수명 던지고 받는 것 확인할 필요가 있음
    member_doc_id: '',
    member_type: '',
    crm_service_start: '',
    crm_service_end: '',
    crm_service_total: 0,
    applies_count_free: {
      carerSupport: 0,
      housekeeper: 0,
      psychologyCounsel: 0,
      nurseAccompany: 0,
    },
    applies_count_discount: {
      carerSupport: 0,
      housekeeper: 0,
      psychologyCounsel: 0,
      nurseAccompany: 0,
    },
    applies_count_free_senior: {
      carerSupport: 0,
      companion: 0,
      housekeeper: 0,
      nurseAccompany: 0,
      rehabilitationExercise: 0,
    },
    applies_count_discount_senior: {
      carerSupport: 0,
      companion: 0,
      housekeeper: 0,
      nurseAccompany: 0,
      rehabilitationExercise: 0,
    },
    // ...testCRMServiceInfo,

    helpergroup_list: [],
    helper_candidate_list: [],
    helper_list: [],
    helper_nm_list: [{ value: '' }],
    isReplaced: false,
    helpergroup_doc_id: '',
    helpergroup_type: '',
    helpergroup_nm: '',
    helper_doc_id: '',
    helper_nm: '',
    helper_phone: '',
    helper_birth_dt: '',
    helper_local_yn: undefined,
    isSaved: false,

    helpergroup_dispatch_count: undefined, // 업체가 서비스 파견시킨 횟수
    helpergroup_evaluation: undefined, // 업체의 고객만족도 조사 평균
    helpergroup_evaluation_num: undefined, // 업체의 고객만족도 조사 평균 (리뉴얼버전 20230321)
    helper_reject_count: undefined,
    helper_evaluation: undefined,
    helper_evaluation_num: undefined, // (리뉴얼버전 20230321) 리뉴얼 만족도조사 횟수
    helper_assign_count: undefined,
    letter_send_count: undefined,
    first_letter_send_dt: undefined,

    claim_yn: false,
    claim_detail_memo: '',

    // 만족도 조사
    evaluation: [],
    isDisabledEval: true,
    evaluation_srvy_dt_yn: false,
    evaluation_new: [],
    evaluation_num: [], // (리뉴얼버전 20230321)

    ...samsung_hard_setting,
  };
  const initStepState = {
    currentStepStatus: 0,
  };
  const [p_apply, setP_apply] = useState(initP_applyState);
  const [stepStatus, setStepStatus] = useState(initStepState);
  const [isSameData, setIsSameData] = useState(false);
  function handleStatusFetch(status) {
    const changed = {
      idx: 0,
    };
    switch (status) {
      case 10:
        changed.idx = 0;
        break;
      case 11:
        changed.idx = 1;
        break;
      case 12:
        changed.idx = 2;
        break;
      case 13:
        changed.idx = 3;
        break;
      case 14:
        if (typeA.includes(p_apply.category2)) {
          changed.idx = 4;
        } else if (typeB.includes(p_apply.category2)) {
          changed.idx = 1;
        }
        break;
      case 19:
        if (typeA.includes(p_apply.category2)) {
          changed.idx = 5;
        } else if (typeB.includes(p_apply.category2)) {
          changed.idx = 2;
        }
        break;
      case 99:
        if (typeA.includes(p_apply.category2)) {
          changed.idx = 6;
        } else if (typeB.includes(p_apply.category2)) {
          changed.idx = 3;
        }
        break;
      default:
        break;
    }
    setStepStatus({
      currentStepStatus: changed.idx,
    });
  }
  function handleStepChange(current) {
    const changed = {
      idx: current,
      status: p_apply.status,
    };
    if (typeA.includes(p_apply.category2)) {
      switch (changed.idx) {
        case 0:
          changed.status = 10;
          break;
        case 1:
          changed.status = 11;
          break;
        case 2:
          changed.status = 12;
          break;
        case 3:
          changed.status = 13;
          break;
        case 4:
          changed.status = 14;
          break;
        case 5:
          changed.status = 19;
          break;
        case 6:
          changed.status = 99;
          break;
        default:
          break;
      }
    } else if (typeB.includes(p_apply.category2)) {
      switch (changed.idx) {
        case 0:
          changed.status = 10;
          break;
        case 1:
          changed.status = 14;
          break;
        case 2:
          changed.status = 19;
          break;
        case 3:
          changed.status = 99;
          break;
        default:
          break;
      }
    }
    setStepStatus({
      currentStepStatus: changed.idx,
    });
    setP_apply({
      ...p_apply,
      status: changed.status,
    });
  }

  function handleChangeCompanyId(company_id) {
    const product_id = cp_id_mapper[company_id];
    setP_apply({
      ...p_apply,
      company_id,
      product_id,
    });
  }
  useEffect(() => {
    enablePrevent();
    return () => {
      disablePrevent();
    };
  }, []);

  useEffect(() => {
    if (document_id) {
      insertInfoAcessLog(
        location.pathname,
        'premiumServiceDetail',
        '조회',
        undefined,
        document_id
      );
      const data = getApplyDetail(
        document_id,
        category1,
        p_apply_category2_array[0]
      );
      data.then((d) => {
        const selectedHelper =
          Array.isArray(d.helper_list) && d.helper_list.length > 0
            ? d.helper_list[d.helper_list.length - 1]
            : {};
        setP_apply({
          ...p_apply,
          ...d,
          ...selectedHelper,
          evaluation: d.evaluation ? d.evaluation : [],
          evaluation_new: d.evaluation_new ? d.evaluation_new : [],
          evaluation_num: d.evaluation_num ? d.evaluation_num : [],
          isSaved: !typeA.includes(d.category2) ? false : (d.helper_list.length > 0? true : false),
        });
        handleStatusFetch(d.status);
      });
    }
  }, [document_id]);
  useEffect(() => {
    handleStatusFetch(p_apply.status);
  }, [p_apply.status]);

  useEffect(() => {
    //평가가 필요한 항목들인지 체크
    let keys = Object.keys(evaluation_ref);
    let isNotEvaluation = keys.includes(p_apply.category2);

    if (
      p_apply.status >= 12 &&
      p_apply.evaluation.length === 0 &&
      p_apply.evaluation_new.length === 0 &&
      p_apply.evaluation_num.length === 0
    ) {
      //평가가 필요한 항목들인지 체크
      if (isNotEvaluation) {
        if (['housekeeper', 'carerSupport'].includes(p_apply.category2)) {
          let type = p_apply.category2 + '_new';
          const tmpArr = [];
          const len = evaluation_ref[type].length;
          for (let i = 0; i < len; i++) {
            tmpArr.push(0);
          }
          setP_apply({
            ...p_apply,
            evaluation_num: tmpArr,
          });
        } else {
          const tmpArr = [];
          const len = evaluation_ref[p_apply.category2].length;
          for (let i = 0; i < len; i++) {
            tmpArr.push(0);
          }
          setP_apply({
            ...p_apply,
            evaluation_num: tmpArr,
          });
        }
      }
    }
  }, [p_apply.status]);

  useEffect(() => {
    const selectedHelper = p_apply.helper_candidate_list.filter(
      (helper) => helper.helper_nm === p_apply.helper_nm
    );
    if (selectedHelper.length !== 0) {
      getHelperDetail(selectedHelper[0].helper_doc_id).then((helperDetail) => {
        setP_apply({
          ...p_apply,
          ...selectedHelper[0],
          ...helperDetail,
        });
      });
    }
  }, [p_apply.helper_nm]);

  useEffect(() => {
    const seletecdHelpergroup = p_apply.helpergroup_list.filter(
      (helpergroup) => helpergroup.helpergroup_nm === p_apply.helpergroup_nm
    );
    if (
      seletecdHelpergroup.length !== 0 &&
      seletecdHelpergroup[0].helpergroup_nm === p_apply.helpergroup_nm
    ) {
      getHelperGroupDetail(seletecdHelpergroup[0].helpergroup_doc_id).then(
        (helpergroupDetail) => {
          const { helpergroup_evaluation, helpergroup_dispatch_count } =
            helpergroupDetail;
          setP_apply({
            ...p_apply,
            helpergroup_nm: seletecdHelpergroup[0].helpergroup_nm,
            helpergroup_evaluation,
            helpergroup_dispatch_count,
          });
        }
      );
    }
  }, [p_apply.helpergroup_nm]);

  const serviceRadioGroupComponent = () => {
    if (!p_apply_category2_array) {
      p_apply_category2_array = [
        'carerSupport',
        'housekeeper',
        'psychologyCounsel',
        'nurseAccompany',
        'rehabilitationExercise',
        'companion',
      ];
    }
    const radioOptions = p_apply_category2_array.map((service) => (
      <Radio key={service} value={service}>
        {p_apply_category2_array_ref[service]}
      </Radio>
    ));
    function handleServiceRadioGroupChange(e) {
      const currentValue = e.target.value;
      setP_apply({
        ...initP_applyState,
        category2: currentValue,
      });
      setStepStatus(initStepState);
    }

    return (
      <>
        <Radio.Group
          value={p_apply.category2}
          onChange={handleServiceRadioGroupChange}
        >
          {radioOptions}
        </Radio.Group>
      </>
    );
  };

  const stepStatusComponent = () => {
    const stepsStyle = {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 auto 0 auto',
      borderRadius: '6px',
      width: '90%',
      // minWidth: '1027px',
      height: '58px',
      backgroundColor: '#f2f2f2',
    };
    const steps = () => {
      const stepTypeB = (
        <>
          <Steps
            progressDot
            size="small"
            style={{ margin: '250px' }}
            current={stepStatus.currentStepStatus}
            onChange={handleStepChange}
          >
            <Steps title={status_ref[10].replaceAll('[P]', '')} />
            <Steps title={status_ref[14].replaceAll('[P]', '')} />
            <Steps title={status_ref[19].replaceAll('[P]', '')} />
            <Steps title="취소 후 일반상담 전환" />
          </Steps>
        </>
      );
      const stepTypeA = (
        <>
          <Steps
            progressDot
            size="small"
            current={stepStatus.currentStepStatus}
            onChange={handleStepChange}
          >
            <Steps title={status_ref[10].replaceAll('[P]', '')} />
            <Steps title={status_ref[11].replaceAll('[P]', '')} />
            <Steps title={status_ref[12].replaceAll('[P]', '')} />
            <Steps title={status_ref[13].replaceAll('[P]', '')} />
            <Steps title={status_ref[14].replaceAll('[P]', '')} />
            <Steps title={status_ref[19].replaceAll('[P]', '')} />
            <Steps title="취소 후 일반상담 전환" />
          </Steps>
        </>
      );
      if (typeA.includes(p_apply.category2)) {
        return stepTypeA;
      } else if (typeB.includes(p_apply.category2)) {
        return stepTypeB;
      } else {
        return null;
      }
    };
    return <div style={stepsStyle}>{steps()}</div>;
  };

  const memberStatusComponent = (isCrmCol) => {
    const InputCrm_nm = (
      <>
        <Input
          style={{ width: '100%' }}
          value={
            isCrmCol
              ? document_id
                ? p_apply.crm_nm
                : p_apply.crm_nm
              : document_id
              ? p_apply.member_nm
              : p_apply.member_nm
          }
          disabled={isSameData && !isCrmCol}
          onChange={(e) => {
            const changed = {};
            const userInput = e.target.value;
            if (isCrmCol) {
              if (isSameData) {
                changed.member_nm = userInput;
              }
              changed.crm_nm = userInput;
            } else if (!isCrmCol) {
              changed.member_nm = userInput;
            }
            setP_apply({
              ...p_apply,
              ...changed,
            });
          }}
        />
      </>
    );
    const InputCrm_phone = (
      <>
        <Input
          style={{ width: '100%' }}
          value={
            isCrmCol
              ? document_id
                ? p_apply.crm_phone
                : p_apply.crm_phone
              : document_id
              ? p_apply.member_ph
              : p_apply.member_ph
          }
          disabled={isSameData && !isCrmCol}
          onChange={(e) => {
            const changed = {};
            const pattern = /[^0-9]/gi;
            const userInput = e.target.value.replaceAll(pattern, '');
            if (isCrmCol) {
              if (isSameData) {
                changed.member_ph = userInput;
              }
              changed.crm_phone = userInput;
            } else if (!isCrmCol) {
              changed.member_ph = userInput;
            }
            setP_apply({
              ...p_apply,
              ...changed,
            });
          }}
        />
      </>
    );
    const ServiceButton = () => (
      <>
        {isCrmCol ? (
          <FormItem label="">
            <Button
              onClick={async () => {
                const res = await fetch(
                  `${baseUrl}/member`,
                  {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                      companyCode: p_apply.company_id,
                      productId: p_apply.product_id,
                      memberName: p_apply.crm_nm,
                      memberMobile: p_apply.crm_phone,
                      devMode: process.env.NODE_ENV === 'development',
                    }),
                  }
                );
                const result = (async function () {
                  try {
                    const fetched = await res.json();
                    setP_apply({
                      ...p_apply,
                      applies_count_discount: {
                        ...fetched.appliesCountData.discount,
                      },
                      applies_count_free: { ...fetched.appliesCountData.free },
                      applies_count_discount_senior: {
                        ...fetched.appliesCountData.seniorDiscount,
                      },
                      applies_count_free_senior: {
                        ...fetched.appliesCountData.seniorFree,
                      },
                      crm_service_start:
                        fetched.appliesCountData.premiumStartDate,
                      crm_service_end: fetched.appliesCountData.premiumEndDate,
                      crm_service_total: fetched.appliesCountData.premiumTotal,
                      member_doc_id: fetched.memberDocId,
                      crm_member_type: fetched.memberType,
                      newMemberDocObj: fetched.newMemberDocObj,
                      member_id: fetched.memberId,
                    });
                  } catch (e) {
                    setP_apply({
                      ...p_apply,
                      crm_member_type: 'NA',
                    });
                  }
                })();
              }}
              type="primary"
            >
              서비스 가능 조회
            </Button>
          </FormItem>
        ) : null}
        {p_apply.category2 === 'psychologyCounsel' && isCrmCol ? (
          <>
            <FormItem label="서비스 희망일" required>
              <DatePicker
                value={
                  p_apply.service_dt
                    ? dayjs.tz(p_apply.service_dt, 'Asia/Seoul')
                    : null
                }
                onChange={(dates, dateStrings, info) => {
                  // value는 localtime 적용 안되고  onchange의 dates는 localtime이 적용이 됨??왜??
                  setP_apply({
                    ...p_apply,
                    service_dt: dates? dayjs(dates).format(
                      'YYYY-MM-DDThh:mm:ss'
                    ) : null,
                  });
                }}
              />
            </FormItem>
            <FormItem label="희망 기간(일 수)" required>
              <InputNumber
                min={0}
                value={p_apply.service_days || 1}
                onChange={(changed) => {
                  setP_apply({
                    ...p_apply,
                    service_days: changed,
                  });
                }}
              />
            </FormItem>
          </>
        ) : null}
      </>
    );
    return (
      <>
        {p_apply.category2 && typeA.includes(p_apply.category2) ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '75px',
            }}
          >
            <Title level={3}>{isCrmCol ? '고객 정보' : '신청자 정보'}</Title>
            <div className="space" style={{ minHeight: '35px' }}>
              {isCrmCol ? null : (
                <Checkbox
                  checked={isSameData}
                  onChange={() => {
                    setIsSameData(!isSameData);
                    setP_apply({
                      ...p_apply,
                      member_nm: p_apply.crm_nm,
                      member_ph: p_apply.crm_phone,
                    });
                  }}
                >
                  <strong>고객 정보와 동일</strong>
                </Checkbox>
              )}
            </div>

            <Form style={{ width: '37%' }} withAsterisk>
              {isCrmCol ? (
                <FormItem label="보험사" required>
                  {/* FIXME: 고객 신규 등록 시, 상담원의 휴먼에러를 방지 하기 위해서 fix 해놓은 부분 */}
                  <Select
                    disabled
                    style={{ width: '100%' }}
                    onChange={handleChangeCompanyId}
                    value={p_apply.company_id}
                  >
                    {getOptionsByRefId(company_ref)}
                  </Select>
                </FormItem>
              ) : null}

              <FormItem label="이름" required>
                {InputCrm_nm}
              </FormItem>
              <FormItem label="휴대폰번호" required>
                {InputCrm_phone}
              </FormItem>
              <ServiceButton />
              {isCrmCol ? (
                <FormItem label="회원 구분">
                  <Input
                    style={{ width: '100%' }}
                    value={
                      p_apply.crm_member_type
                        ? crm_return_type_ref[p_apply.crm_member_type]
                        : null
                    }
                    disabled
                  />
                </FormItem>
              ) : null}
              {p_apply.category2 === 'carerSupport' && !isCrmCol ? (
                <FormItem label="병원명" required>
                  <Input
                    style={{ width: '100%' }}
                    value={p_apply.service_nm}
                    onChange={(e) => {
                      setP_apply({
                        ...p_apply,
                        service_nm: e.target.value,
                      });
                    }}
                  />
                </FormItem>
              ) : null}
              {['housekeeper', 'rehabilitationExercise', 'companion'].includes(p_apply.category2) && !isCrmCol ? (
                <>
                  <FormItem label="주소" required>
                    <Input
                      style={{ width: '100%' }}
                      value={
                        document_id ? p_apply.member_addr : p_apply.member_addr
                      }
                      onChange={(e) => {
                        setP_apply({
                          ...p_apply,
                          member_addr: e.target.value,
                        });
                      }}
                    />
                  </FormItem>
                </>
              ) : null}
              {['housekeeper'].includes(p_apply.category2) && !isCrmCol ? (
                <FormItem label="가사도우미 희망시간" required>
                  <Radio.Group
                    value={p_apply.service_hours}
                    onChange={(e) =>
                      setP_apply({
                        ...p_apply,
                        service_hours: e.target.value,
                      })
                    }
                  >
                    <Radio value={4}>4시간</Radio>
                    <Radio value={8}>8시간</Radio>
                  </Radio.Group>
                </FormItem>
              ) : null}
              {!['housekeeper', 'carerSupport', 'rehabilitationExercise','companion'].includes(p_apply.category2) || isCrmCol ? null : (
                <>
                  <FormItem label="서비스 희망일" required>
                    <DatePicker
                      value={
                        p_apply.service_dt
                          ? dayjs.tz(p_apply.service_dt, 'Asia/Seoul')
                          : null
                      }
                      onChange={(dates, dateStrings, info) => {
                        // value는 localtime 적용 안되고  onchange의 dates는 localtime이 적용이 됨??왜??
                        setP_apply({
                          ...p_apply,
                          service_dt: dates? dayjs(dates).format(
                            'YYYY-MM-DDThh:mm:ss'
                          ) : null,
                        });
                      }}
                    />
                  </FormItem>
                  <FormItem label="희망 기간(일 수)" required>
                    <InputNumber
                      min={0}
                      value={p_apply.service_days || 1}
                      onChange={(changed) => {
                        setP_apply({
                          ...p_apply,
                          service_days: changed,
                        });
                      }}
                    />
                  </FormItem>
                  <FormItem label="신청일" required>
                    {/* TODO: 접수의 경우 당일, (추후)데이터가 넘어오는 화면의 경우 그 값 */}
                    <DatePicker
                      value={p_apply.reg_dt ? dayjs(p_apply.reg_dt) : null}
                      onChange={(dates, dateStrings, info) => {
                        var today = new Date();
                        var time =
                          today.getHours() +
                          ':' +
                          today.getMinutes() +
                          ':' +
                          today.getSeconds();
                        setP_apply({
                          ...p_apply,
                          reg_dt: Date.parse(dateStrings + ' ' + time),
                        });
                      }}
                    />
                  </FormItem>
                </>
              )}
            </Form>
          </div>
        ) : (
          <>
            {typeB.includes(p_apply.category2) && isCrmCol ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginRight: '75px',
                }}
              >
                <Title level={3}>
                  {isCrmCol ? '고객 정보' : '신청자 정보'}
                </Title>
                <div className="space" style={{ minHeight: '35px' }}>
                  {isCrmCol ? null : (
                    <Checkbox
                      checked={isSameData}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        if (isChecked) {
                          setP_apply({
                            ...p_apply,
                            member_nm: p_apply.crm_nm,
                            member_ph: p_apply.crm_phone,
                          });
                        }
                        setIsSameData(!isSameData);
                      }}
                    >
                      <strong>고객 정보와 동일</strong>
                    </Checkbox>
                  )}
                </div>
                <Form style={{ width: '37%' }} withAsterisk>
                  {isCrmCol ? (
                    <FormItem label="보험사" required>
                      <Select
                        // FIXME: 고객 신규 등록 시, 상담원의 휴먼에러를 방지 하기 위해서 fix 해놓은 부분
                        disabled
                        style={{ width: '100%' }}
                        onChange={handleChangeCompanyId}
                        value={p_apply.company_id}
                      >
                        {getOptionsByRefId(company_ref)}
                      </Select>
                    </FormItem>
                  ) : null}
                  <FormItem label="이름" required>
                    {InputCrm_nm}
                  </FormItem>
                  <FormItem label="휴대폰번호" required>
                    {InputCrm_phone}
                  </FormItem>
                  <ServiceButton />
                  {isCrmCol ? (
                    <FormItem label="회원 구분">
                      {/* TODO: crm 에서 넘어오는 회원 구분 값 입력 */}
                      <Input
                        value={
                          p_apply.crm_member_type
                            ? crm_return_type_ref[p_apply.crm_member_type]
                            : null
                        }
                        disabled
                      />
                    </FormItem>
                  ) : (
                    <>
                      {p_apply.category2 === 'psychologyCounsel' ? (
                        <>
                          <FormItem label="서비스 희망일" required>
                            <DatePicker
                              value={
                                p_apply.service_dt
                                  ? dayjs.tz(p_apply.service_dt, 'Asia/Seoul')
                                  : null
                              }
                              onChange={(dates, dateStrings, info) => {
                                // value는 localtime 적용 안되고  onchange의 dates는 localtime이 적용이 됨??왜??
                                setP_apply({
                                  ...p_apply,
                                  service_dt: dates? dayjs(dates).format(
                                    'YYYY-MM-DDThh:mm:ss'
                                  ) : null,
                                });
                              }}
                            />
                          </FormItem>
                          <FormItem label="희망 기간(일 수)" required>
                            <InputNumber
                              min={0}
                              value={p_apply.service_days || 1}
                              onChange={(changed) => {
                                setP_apply({
                                  ...p_apply,
                                  service_days: changed,
                                });
                              }}
                            />
                          </FormItem>
                        </>
                      ) : null}
                      <FormItem label="신청일" required>
                        {/* TODO: 접수의 경우 당일, (추후)데이터가 넘어오는 화면의 경우 그 값 */}
                        <DatePicker
                          value={p_apply.reg_dt ? dayjs(p_apply.reg_dt) : null}
                          onChange={(dates, dateStrings, info) => {
                            var today = new Date();
                            var time =
                              today.getHours() +
                              ':' +
                              today.getMinutes() +
                              ':' +
                              today.getSeconds();
                            setP_apply({
                              ...p_apply,
                              reg_dt: Date.parse(dateStrings + ' ' + time),
                            });
                          }}
                        />
                      </FormItem>
                    </>
                  )}
                </Form>
              </div>
            ) : null}
          </>
        )}
      </>
    );
  };

  const CRMServiceInfoComponent = () => {
    const crmBoxType = {
      free: 'free',
      discount: 'discount',
    };
    const infoStyle = {
      display: 'flex',
      justifyContent: 'space-between',
    };

    const CrmBox = ({ type }) => {
      let cs = 0, hk = 0, na = 0, re = 0, cp = 0, pc = 0;
      let d_cs = 0, d_hk = 0, d_na = 0, d_re = 0, d_cp = 0, d_pc = 0;
      let freeTotalCnt = 0, discountTotalCnt = 0;

      const category1 = p_apply.category1;

      if(['nursingCare', 'dementiaCare'].includes(category1)) {
        ({
          carerSupport: cs,
          housekeeper: hk,
          nurseAccompany: na,
          rehabilitationExercise: re,
          companion: cp,
        } = p_apply.applies_count_free_senior);
        freeTotalCnt = cs + hk + na + re + cp;

        ({
          carerSupport: d_cs,
          housekeeper: d_hk,
          nurseAccompany: d_na,
          rehabilitationExercise: d_re,
          companion: d_cp,
        } = p_apply.applies_count_discount_senior);
        discountTotalCnt = d_cs + d_hk + d_na + d_re + d_cp;

      } else {
        ({
          carerSupport: cs,
          housekeeper: hk,
          psychologyCounsel: pc,
          nurseAccompany: na,
        } = p_apply.applies_count_free);
        freeTotalCnt = cs + hk + pc + na;

        ({
          carerSupport: d_cs,
          housekeeper: d_hk,
          psychologyCounsel: d_pc,
          nurseAccompany: d_na,
        } = p_apply.applies_count_discount);
        discountTotalCnt = d_cs + d_hk + d_pc + d_na;
      };

      let currentBoxStyle = {};
      let currentTitleStyle = {};
      const boxStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: '4px',
        padding: '4px 6px',
        width: '49%',
        height: '67px',
        border: '1px solid #d2d2d2',
        borderRadius: '5px',
      };
      const titleStyle = {
        fontSize: '20px',
        color: '#fb743e',
      };
      const fadeStyle = {
        backgroundColor: '#f2f2f2',
        color: '#b2b2b2',
      };
      // FIXME: 임시방편으로 10회 이내는 무료, 초과는 우대로 임시 처리해놓았으나 추후 조건이 명확해지면 적용 필요함
      if (type === crmBoxType.free) {
        if (freeTotalCnt <= 10) {
          currentBoxStyle = { ...boxStyle };
          currentTitleStyle = { ...titleStyle };
        } else {
          currentBoxStyle = { ...boxStyle, ...fadeStyle };
          currentTitleStyle = { ...titleStyle, ...fadeStyle };
        }
      } else if (type === crmBoxType.discount) {
        if (freeTotalCnt > 10) {
          currentBoxStyle = { ...boxStyle };
          currentTitleStyle = { ...titleStyle };
        } else {
          currentBoxStyle = { ...boxStyle, ...fadeStyle };
          currentTitleStyle = { ...titleStyle, ...fadeStyle };
        }
      }
      return (
        <div style={currentBoxStyle}>
            <span>
              <span style={currentTitleStyle}>
                {type === crmBoxType.free
                  ? '무료 (5년 이내)'
                  : '15년/20년 우대 (5년 초과, 10회 초과)'}
              </span>
              {type === crmBoxType.free
                ? ` 총 ${freeTotalCnt}회`
                : ` 총 ${discountTotalCnt}회`}
            </span>
          {(['nursingCare', 'dementiaCare'].includes(category1)) ? (
            <span>
              {type === crmBoxType.free
                ? `입원 시 간병인 지원 ${cs}회 | 가사도우미 지원 ${hk}회 | 상급병원 간호사 동행 ${na}회 | 재활 운동 지원 ${re} | 동행인 지원 ${cp}`
                : `입원 시 간병인 지원 ${d_cs}회 | 가사도우미 지원 ${d_hk}회 | 상급병원 간호사 동행 ${d_na}회 | 재활 운동 지원 ${d_re} | 동행인 지원 ${d_cp}`}
            </span>
          ) : (
            <span>
              {type === crmBoxType.free
                ? `입원 시 간병인 지원 ${cs}회 | 가사도우미 지원 ${hk}회 | 대면심리상담 ${pc}회 | 상급병원 간호사 동행 ${na}회`
                : `입원 시 간병인 지원 ${d_cs}회 | 가사도우미 지원 ${d_hk}회 | 대면심리상담 ${d_pc}회 | 상급병원 간호사 동행 ${d_na}회`}
            </span>
          )}
      </div>

      )}

    return (
      <>
        <div style={infoStyle}>
          <CrmBox type={crmBoxType.free} />
          <CrmBox type={crmBoxType.discount} />
        </div>
      </>
    );
  };

  const EvalCarerSupportComponent = ({ type }) => {
    const mapEvalData = evaluation_ref[type].map((row, idx) => (
      <Fragment key={`${row.label}`}>
        <div>
          <div
            style={{ display: 'inline-block', width: '100px' }}
          >{`${row.label}`}</div>
          <Rate
            value={p_apply.evaluation && p_apply.evaluation[idx]}
            disabled={p_apply.isDisabledEval}
            onChange={(changedNum) => {
              setP_apply({
                ...p_apply,
                evaluation: [
                  ...p_apply.evaluation.slice(0, idx),
                  changedNum,
                  ...p_apply.evaluation.slice(
                    idx + 1,
                    p_apply.evaluation.length
                  ),
                ],
              });
            }}
          />
        </div>
        <div style={{ marginBottom: '3px' }}>{row.desc}</div>
      </Fragment>
    ));
    const handleDisableToggle = () => {
      setP_apply({
        ...p_apply,
        isDisabledEval: !p_apply.isDisabledEval,
      });
    };
    const buttonStyle = {
      margin: '0 15px',
      minWidth: '120px',
    };
    return (
      <>
        {mapEvalData}
        <div
          style={{ display: 'flex', flexDirection: 'row', margin: '0 auto' }}
        >
          <Button
            danger
            type={p_apply.isDisabledEval ? 'primary' : 'default'}
            style={buttonStyle}
            onClick={handleDisableToggle}
          >
            {p_apply.isDisabledEval ? '수정 활성화' : '수정 비활성화'}
          </Button>
        </div>
      </>
    );
  };

  const EvalCarerSupportComponentNew = ({ type }) => {
    // 만족도 조사 폼 리뉴얼 버전이면 '_new' 추가
    if (['housekeeper', 'carerSupport'].includes(p_apply.category2)) {
      type += '_new';
    }
    const stars = Array(10).fill(0);
    // 첫번째 리뉴얼 버전 만족도조사가 있는 경우
    const mapEvalData =
      p_apply.evaluation_new.length > 0
        ? evaluation_ref[type].map((row, idx) => (
            <Fragment key={`${row.label}`}>
              <div>
                <div
                  style={{ display: 'inline-block', width: '100px' }}
                >{`${row.label}`}</div>
                <Rate
                  value={p_apply.evaluation_new && p_apply.evaluation_new[idx]}
                  disabled={p_apply.isDisabledEval}
                  onChange={(changedNum) => {
                    setP_apply({
                      ...p_apply,
                      evaluation_new: [
                        ...p_apply.evaluation_new.slice(0, idx),
                        changedNum,
                        ...p_apply.evaluation_new.slice(
                          idx + 1,
                          p_apply.evaluation_new.length
                        ),
                      ],
                    });
                  }}
                />
              </div>
              <div style={{ marginBottom: '3px' }}>{row.desc}</div>
            </Fragment>
          ))
        : // 10점 리뉴얼 버전 만족도조사가 있는 경우 (20230321)
          evaluation_ref[type].map((row, idx) => (
            <Fragment key={`${row.label}`}>
              <div>
                <div
                  style={{ display: 'inline-block', width: '100px' }}
                >{`${row.label}`}</div>
                <Select
                  defaultValue={
                    p_apply.evaluation_num && p_apply.evaluation_num[idx]
                  }
                  style={{
                    width: 120,
                  }}
                  disabled={p_apply.isDisabledEval}
                  onChange={(changedNum) => {
                    setP_apply({
                      ...p_apply,
                      evaluation_num: [
                        ...p_apply.evaluation_num.slice(0, idx),
                        changedNum,
                        ...p_apply.evaluation_num.slice(
                          idx + 1,
                          p_apply.evaluation_num.length
                        ),
                      ],
                    });
                  }}
                  options={stars.map((star, i) => ({
                    value: 10 - i,
                    label: 10 - i,
                  }))}
                />
              </div>
              <div style={{ marginBottom: '3px' }}>{row.desc}</div>
            </Fragment>
          ));

    const handleDisableToggle = () => {
      setP_apply({
        ...p_apply,
        isDisabledEval: !p_apply.isDisabledEval,
      });
    };
    const buttonStyle = {
      margin: '0 15px',
      minWidth: '120px',
    };
    return (
      <>
        {mapEvalData}
        <div
          style={{ display: 'flex', flexDirection: 'row', margin: '0 auto' }}
        >
          <Button
            danger
            type={p_apply.isDisabledEval ? 'primary' : 'default'}
            style={buttonStyle}
            onClick={handleDisableToggle}
          >
            {p_apply.isDisabledEval ? '수정 활성화' : '수정 비활성화'}
          </Button>
        </div>
      </>
    );
  };

  const helpergroupListOptions = p_apply.helpergroup_list.map((group) => (
    <Option key={group.helpergroup_doc_id} value={group.helpergroup_nm}>
      {group.helpergroup_nm}
    </Option>
  ));

  const handleSendSrvyMessage = () => {
    try {
      sendMessage(
        p_apply.category2,
        'evaluation',
        p_apply.document_id,
        p_apply.helper_doc_id
      );
      alert('메세지가 정상적으로 처리되었습니다');
    } catch (e) {
      alert('메세지 전송이 정상적으로 처리되지 않았습니다');
      console.error(e);
    }
  };
  return (
    <Container>
      <Section title={`${category_ref(p_apply.company_id).ref1[p_apply.category1]} 서비스 선택`}>
        {serviceRadioGroupComponent()}
      </Section>
      <Section title="진행상태">{stepStatusComponent()}</Section>
      {p_apply.category2 ? (
        <>
          <Section flexDirection="row">
            {memberStatusComponent(true)}
            {memberStatusComponent(false)}
          </Section>
          <Section title={`${category_ref(p_apply.company_id).ref1[p_apply.category1]} 신청 내역`} flexDirection="column">
            <div>
              {`서비스 시작일: ${p_apply.crm_service_start} / `}
              {`서비스 종료일: ${p_apply.crm_service_end}(5년 후 자동 계산하여 입력) / `}
              {`보험금 지급일: ${p_apply.crm_service_start}`}
            </div>
            <CRMServiceInfoComponent />
          </Section>
        </>
      ) : null}
      {p_apply.status !== undefined && typeA.includes(p_apply.category2) ? (
        <>
          <Section
            title={`${premium_helper_type_ref[p_apply.category2]} 정보`}
            button={
              <Button
                disabled={
                  !(
                    // p_apply.helper_list &&
                    // p_apply.helper_list.length !== 0 &&
                    !p_apply.isReplaced
                  )
                }
                type="text"
                style={{ backgroundColor: '#5d5e5d', color: 'white' }}
                onClick={async () => {
                  setP_apply({
                    ...p_apply,
                    isReplaced: true,
                    helpergroup_doc_id: '',
                    helpergroup_nm: '',
                    helper_doc_id: '',
                    helper_nm: '',
                    helper_phone: '',
                    helper_birth_dt: '',
                    helper_local_yn: true,
                    isSaved: false,
                  });
                }}
              >
                {premium_helper_type_ref[p_apply.category2]}
                {' 재배정하기'}
              </Button>
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '75px',
              }}
            >
              <Form withAsterisk style={{ marginRight: '75px' }}>
                <FormItem label="소속업체" required>
                  <Select
                    disabled={
                      !(p_apply.helper_list.length === 0
                        ? true
                        : !!p_apply.isReplaced)
                    }
                    style={{ width: '200px' }}
                    placeholder="소속업체를 선택해주세요"
                    value={
                      p_apply.helpergroup_nm ? p_apply.helpergroup_nm : null
                    }
                    loading={p_apply.helpergroup_list.length === 0}
                    onMouseEnter={() => {
                      if (p_apply.category2) {
                        getHelperGroupsList(p_apply.category2).then((d) => {
                          setP_apply({
                            ...p_apply,
                            helpergroup_list: d,
                          });
                        });
                      }
                    }}
                    onChange={(selected) => {
                      getHelpersList(
                        p_apply.category2,
                        selected,
                        p_apply.company_id
                      ).then((helper_candidate_list) => {
                        const helper_nm_list = helper_candidate_list.reduce(
                          (acc, helper) => {
                            acc.push({
                              value: helper.helper_nm,
                            });
                            return acc;
                          },
                          []
                        );
                        const selectedGroup = p_apply.helpergroup_list.filter(
                          (group) => group.helpergroup_nm === selected
                        )[0];
                        if(p_apply.category2 === 'companion') {
                          // 신규 동행인 입력열어둠
                          setP_apply({
                            ...p_apply,
                            helpergroup_nm: selected,
                            helpergroup_doc_id: selectedGroup.helpergroup_doc_id,
                            helpergroup_type: p_apply.category2,
                            helper_local_yn: undefined,
                            helper_candidate_list,
                            helper_nm_list,
                          });
                        } else {
                          setP_apply({
                            ...p_apply,
                            helpergroup_nm: selected,
                            helpergroup_doc_id: selectedGroup.helpergroup_doc_id,
                            helpergroup_type: p_apply.category2,
                            helper_phone: selectedGroup.helpergroup_phone,
                            helper_birth_dt: '',
                            helper_local_yn: undefined,
                            helper_candidate_list,
                            helper_nm_list,
                            helper_nm: selectedGroup.helpergroup_nm,
                          });
                        }
                      });
                    }}
                  >
                    {helpergroupListOptions}
                  </Select>
                </FormItem>
                <FormItem label="이름" required>
                  <AutoComplete
                    disabled={p_apply.helper_nm && p_apply.isSaved}
                    // disabled={
                    //   (p_apply.helper_nm.length === 0
                    //     ? false
                    //     : true)
                    // }
                    style={{
                      width: 200,
                    }}
                    // options={p_apply.helper_nm_list}
                    value={p_apply.helper_nm}
                    onChange={(value) => {
                      setP_apply({
                        ...p_apply,
                        helper_nm: value,
                      });
                    }}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </FormItem>
                <FormItem label="휴대폰번호" required>
                  <Input
                    disabled={p_apply.helper_phone && p_apply.isSaved}
                    // disabled={
                    //   !(p_apply.helper_list.length === 0
                    //     ? true
                    //     : !!p_apply.isReplaced)
                    // }
                    value={p_apply.helper_phone}
                    onChange={(e) => {
                      const pattern = /[^0-9]/gi;
                      const userInput = e.target.value.replaceAll(pattern, '');
                      setP_apply({
                        ...p_apply,
                        helper_phone: userInput,
                      });
                    }}
                  />
                </FormItem>
                <FormItem label="생년월일" required>
                  <DatePicker
                    disabled={
                      !(p_apply.helper_list.length === 0
                        ? true
                        : !!p_apply.isReplaced)
                    }
                    value={
                      p_apply.helper_birth_dt
                        ? dayjs(p_apply.helper_birth_dt)
                        : null
                    }
                    onChange={(dates, dateStrings, info) => {
                      setP_apply({
                        ...p_apply,
                        helper_birth_dt: dates.format('YYYYMMDD').toString(),
                      });
                    }}
                  />
                </FormItem>
                <FormItem label="내외국인 여부" required>
                  <Radio.Group
                    disabled={
                      !(p_apply.helper_list.length === 0
                        ? true
                        : !!p_apply.isReplaced)
                    }
                    value={p_apply.helper_local_yn}
                    onChange={(e) => {
                      setP_apply({
                        ...p_apply,
                        helper_local_yn: e.target.value,
                      });
                    }}
                  >
                    <Radio value>내국인</Radio>
                    <Radio value={false}>외국인</Radio>
                  </Radio.Group>
                </FormItem>
                <FormItem label="">
                  <Button
                    type="primary"
                    disabled={
                      // NOTE: 서약서가 작성 완료 되었을 때, 재배정이 눌리지 않았을 때
                      p_apply.letter_submitted_yn ||
                      p_apply.isReplaced ||
                      p_apply.helper_list.length === 0
                    }
                    onClick={() => {
                      if (confirm('정말로 서약서를 다시 보내시겠습니까?')) {
                        try {
                          sendMessage(
                            p_apply.category2,
                            'letter',
                            p_apply.document_id,
                            p_apply.helper_doc_id
                          );
                          setP_apply({
                            ...p_apply,
                            letter_send_count: p_apply.letter_send_count + 1,
                          });
                          alert('서약서가 재발송 되었습니다');
                        } catch (e) {
                          alert('서약서 재발송에 실패하였습니다');
                          console.log(e);
                        }
                      }
                    }}
                  >
                    서약서 재전송하기
                  </Button>
                </FormItem>
              </Form>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '75px',
              }}
            >
              <Form>
                <FormItem label="파견 횟수: ">
                  {p_apply.helpergroup_dispatch_count === undefined
                    ? '-'
                    : p_apply.helpergroup_dispatch_count}
                  {' 회'}
                </FormItem>
                <FormItem label="고객만족도 조사 평균: ">
                  {p_apply.helpergroup_evaluation === undefined
                    ? '-'
                    : p_apply.helpergroup_evaluation.toFixed(2)}
                  {' 점'}
                </FormItem>
                <FormItem label="거절 횟수: ">
                  {p_apply.helper_reject_count === undefined
                    ? '-'
                    : p_apply.helper_reject_count}
                  {' 회'}
                </FormItem>
                <FormItem label="고객만족도 점수: ">
                  {p_apply.helper_evaluation === undefined
                    ? '-'
                    : p_apply.helper_evaluation}
                  {' 점'}
                </FormItem>
                <FormItem label="배정 횟수: ">
                  {p_apply.helper_assign_count === undefined
                    ? '-'
                    : p_apply.helper_assign_count}
                  {' 회'}
                </FormItem>
                <FormItem label="서약서 보내기 완료">
                  {'(총 '}
                  {p_apply.letter_send_count === undefined
                    ? '-'
                    : p_apply.letter_send_count}
                  {' 회) '}
                  {p_apply.first_letter_send_dt
                    ? dayjs(p_apply.first_letter_send_dt)
                        .format('YYYY/MM/DD')
                        .toString()
                    : null}
                </FormItem>
              </Form>
            </div>
          </Section>
          <Button
            type="primary"
            disabled={
              p_apply.helper_candidate_list
                ? p_apply.helper_candidate_list.filter(
                    (helper) =>
                      helper.helper_nm === p_apply.helper_nm &&
                      helper.helper_phone === p_apply.helper_phone &&
                      helper.helper_birth_dt === p_apply.helper_birth_dt
                  ).length !== 0
                : null
            }
            style={{ alignSelf: 'center' }}
            onClick={() => {
              if (p_apply.isSaved === false) {
                const {
                  helpergroup_nm,
                  helper_nm,
                  helper_phone,
                  helper_birth_dt,
                  helper_local_yn,
                  company_id,
                  product_id,
                } = p_apply;
                const helpergroup = p_apply.helpergroup_list.filter(
                  (helpergroup) => helpergroup.helpergroup_nm === helpergroup_nm
                );
                const { helpergroup_doc_id } = helpergroup[0];
                const helper = {
                  nm: helper_nm,
                  phone: helper_phone,
                  birth_dt: helper_birth_dt,
                  local_yn: helper_local_yn,
                  helpergroup_doc_id,
                  helpergroup_nm,
                  helpergroup_type: p_apply.category2,
                  company_id,
                  product_id,
                };
                insertHelper(helper).then((helper_doc_id) => {
                  setP_apply({
                    ...p_apply,
                    isSaved: true,
                    helper_doc_id,
                    isReplaced: false,
                  });
                });
              }
            }}
          >
            {p_apply.isSaved ? '저장 완료' : `신규 ${premium_helper_type_ref[p_apply.category2]} 저장하기`}
          </Button>
        </>
      ) : null}
      {p_apply.status > 11 && typeA.includes(p_apply.category2) ? (
        <Section title={`${premium_helper_type_ref[p_apply.category2]} 서약서`}>
          <FormItem label="서약서">
            <Button
              onClick={() => {
                const strWindowFeatures =
                  'resizable=yes,innerWidth=414,innerHeight=736';
                window.open(
                  p_apply.letter_url,
                  '서약서 확인',
                  strWindowFeatures
                );
              }}
            >
              서약서 보기
            </Button>
          </FormItem>
        </Section>
      ) : null}
      {typeA.includes(p_apply.category2) ? (
        <>
          <Section title="고객 클레임 사항" flexDirection="column">
            <Checkbox
              checked={p_apply.claim_yn}
              disabled={p_apply.claim_detail_memo}
              onChange={() => {
                setP_apply({ ...p_apply, claim_yn: !p_apply.claim_yn });
              }}
            >
              클레임 발생
            </Checkbox>
            {p_apply.claim_yn ? (
              <FormItem label="클레임 내용">
                <TextArea
                  rows={4}
                  value={p_apply.claim_detail_memo}
                  onChange={(e) => {
                    setP_apply({
                      ...p_apply,
                      claim_detail_memo: e.target.value,
                    });
                  }}
                />
              </FormItem>
            ) : null}
          </Section>
          {p_apply.status >= 13 ? (
            // 만족도조사 데이터 변화 (evaluation -> evaluation_new -> evaluation_num)
            // 기존 만족도조사 데이터가 있는 경우
            p_apply.evaluation.length > 0 &&
            p_apply.evaluation_srvy_dt_yn === undefined ? (
              <>
                <Section title="고객만족도 조사">
                  {p_apply.category2 && typeA.includes(p_apply.category2) ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <EvalCarerSupportComponent type={p_apply.category2} />
                    </div>
                  ) : null}
                </Section>
              </>
            ) : (
              // 리뉴얼 만족도조사 데이터가 있는 경우
              <>
                <Section>
                  <div>
                    <div>
                      {`${
                        helpergroup_type_ref[p_apply.category2]
                      } 서비스가 신청하신 날짜에 제공이 되었습니까?`}
                    </div>
                    <Radio.Group
                      value={p_apply.evaluation_srvy_dt_yn}
                      onChange={(e) => {
                        if (!p_apply.isDisabledEval) {
                          setP_apply({
                            ...p_apply,
                            evaluation_srvy_dt_yn: e.target.value,
                          });
                        }
                      }}
                    >
                      <Radio value>예</Radio>
                      <Radio value={false}>아니요</Radio>
                    </Radio.Group>
                  </div>
                </Section>
                <Section
                  title="고객만족도 조사"
                  button={
                    <Button type="primary" onClick={handleSendSrvyMessage}>
                      고객만족도 URL 보내기
                    </Button>
                  }
                >
                  {p_apply.category2 && typeA.includes(p_apply.category2) ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <EvalCarerSupportComponentNew type={p_apply.category2} />
                    </div>
                  ) : null}
                </Section>
              </>
            )
          ) : (
            <Section
              title="고객만족도 조사"
              button={
                <Button type="primary" onClick={handleSendSrvyMessage}>
                  고객만족도 URL 보내기
                </Button>
              }
              flexDirection="row"
            >
              <div
                style={{
                  justifySelf: 'center',
                  margin: '0 auto',

                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    margin: '15px 0',
                  }}
                >
                  <Title level={4}>고객만족도 조사 진행 전 입니다.</Title>
                </div>
                <div>
                  {/* TODO: 버튼 상세기능 구현 필요 */}
                  <Button
                    danger
                    type="primary"
                    style={{
                      margin: '0 8px',
                    }}
                    onClick={() => setP_apply({ ...p_apply, status: 14 })}
                  >
                    고객만족도 조사 종결
                  </Button>
                </div>
              </div>
            </Section>
          )}
        </>
      ) : null}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          margin: '15px 50px',
        }}
      >
        <div>
          <Button
            type="text"
            style={{
              marginRight: '6px',
              minWidth: '90px',
              backgroundColor: '#e0e0e0',
              color: 'black',
            }}
            onClick={() => {
              window.location.href = '/apply';
            }}
          >
            취소
          </Button>
          {p_apply.document_id ? (
            <Button
              type="primary"
              style={{ marginLeft: '6px', minWidth: '90px' }}
              onClick={async () => {
                const bool = confirm(
                  '페이지 내 모든 수정 사항을 저장하시겠습니까?'
                );
                if (bool) {
                  const {
                    document_id,
                    company_id,
                    product_id,
                    category1,
                    category2,
                    member_id,
                    member_doc_id,
                    crm_phone,
                    crm_nm,
                    status,
                    evaluation,
                    claim_yn,
                    claim_detail_memo,
                    evaluation_srvy_dt_yn,
                    evaluation_new,
                    evaluation_num,
                    reg_dt,
                  } = p_apply;
                  const {
                    member_nm,
                    member_ph,
                    member_addr,
                    service_nm,
                    service_dt,
                    service_hours,
                    service_days,
                  } = p_apply;
                  const { crm_service_start, crm_service_end } = p_apply;

                  const {
                    helper_list,
                    helpergroup_doc_id,
                    helpergroup_type,
                    helpergroup_nm,
                    helper_doc_id,
                    helper_nm,
                    helper_phone,
                    helper_birth_dt,
                    helper_local_yn,
                  } = p_apply;
                  const { member_type, newMemberDocObj } = p_apply;
                  const submitContent = {
                    status,
                    document_id,
                    company_id,
                    product_id,
                    category1,
                    category2,
                    member_id,
                    member_doc_id,
                    crm_phone,
                    crm_nm,
                    member_nm,
                    member_ph,
                    member_addr,
                    service_nm,
                    service_dt,
                    service_hours,
                    service_days,
                    helper_list,
                    claim_yn,
                    claim_detail_memo,
                    premium_start_str_dt: crm_service_start,
                    premium_end_str_dt: crm_service_end,
                    reg_dt,
                  };

                  // 완료 이후 상태이고 evaluation_srvy_dt_yn가 값을 가질때
                  if (
                    p_apply.status >= 13 &&
                    evaluation_srvy_dt_yn !== undefined
                  ) {
                    submitContent.evaluation_srvy_dt_yn = evaluation_srvy_dt_yn;
                  }

                  const evalSum = p_apply.evaluation.reduce((acc, point) => {
                    acc += point;
                    return acc;
                  }, 0);
                  if (p_apply.status >= 13 && evalSum !== 0) {
                    submitContent.evaluation = evaluation;
                  }

                  const evalSumNew = p_apply.evaluation_new.reduce(
                    (acc, point) => {
                      acc += point;
                      return acc;
                    },
                    0
                  );
                  if (p_apply.status >= 13 && evalSumNew !== 0) {
                    submitContent.evaluation_new = evaluation_new;
                  }

                  const evalSumNum = p_apply.evaluation_num.reduce(
                    (acc, point) => {
                      acc += point;
                      return acc;
                    },
                    0
                  );
                  if (p_apply.status >= 13 && evalSumNum !== 0) {
                    submitContent.evaluation_num = evaluation_num;
                  }

                  if (typeA.includes(category2)) {
                    if (
                      p_apply.isReplaced === true ||
                      (p_apply.helper_list.length === 0 && p_apply.status !== 19) // helper 저장 전 취소일때는 helper_list 배열 추가안함
                    ) {
                      const this_helper_list = p_apply.helper_list || [];
                      const helper_list_content = {
                        helpergroup_doc_id,
                        helpergroup_type,
                        helpergroup_nm,
                        helper_doc_id,
                        helper_nm,
                        helper_phone,
                        helper_birth_dt,
                        helper_local_yn,
                      };
                      await this_helper_list.push(helper_list_content);
                      submitContent.helper_list = this_helper_list;
                    }

                    if(p_apply.status >= 10 && p_apply.status < 19 && !p_apply.service_dt) {
                      return alert('저장에 실패하였습니다\n 서비스 희망일을 지정해주세요.');
                    }
                  }
                  try {
                    const res = await editApplyDetail(submitContent);
                    alert('저장되었습니다');

                    setP_apply({
                      ...p_apply,
                      status: status,
                    });
                    insertInfoAcessLog(
                      location.pathname,
                      'premiumServiceDetail',
                      '수정',
                      undefined,
                      p_apply.document_id
                    );
                    window.location.href = '/apply';
                  } catch (error) {
                    alert('저장에 실패하였습니다\n 관리자에게 문의 바랍니다');
                  }
                }
              }}
            >
              수정하기
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ marginLeft: '6px', minWidth: '90px' }}
              onClick={async () => {
                const bool = confirm('해당 서비스신청을 등록하시겠습니까?');
                if (bool) {
                  const {
                    company_id,
                    product_id,
                    category1,
                    category2,
                    member_doc_id,
                    crm_phone,
                    crm_nm,
                    member_nm,
                    member_ph,
                    member_addr,
                    service_dt,
                    service_hours,
                    service_days,
                    status,
                    helper_list,
                    service_nm,
                    reg_dt,
                  } = p_apply;
                  const {
                    helpergroup_doc_id,
                    helpergroup_type,
                    helpergroup_nm,
                    helper_doc_id,
                    helper_nm,
                    helper_phone,
                    helper_birth_dt,
                    helper_local_yn,
                    member_id,
                  } = p_apply;
                  const this_helper_list = helper_list || [];
                  const helper_list_content = {
                    helpergroup_doc_id,
                    helpergroup_type,
                    helpergroup_nm,
                    helper_doc_id,
                    helper_nm,
                    helper_phone,
                    helper_birth_dt,
                    helper_local_yn,
                  };
                  const { crm_member_type, newMemberDocObj } = p_apply;
                  if (typeA.includes(category2) && helper_doc_id) {
                    await this_helper_list.push(helper_list_content);
                  }
                  const submitContent = {
                    memberType: crm_member_type,
                    newMemberDocObj,
                    apply: {
                      status,
                      company_id,
                      product_id,
                      category1,
                      category2,
                      member_id,
                      member_doc_id,
                      crm_phone,
                      crm_nm,
                      member_nm,
                      member_ph,
                      member_addr,
                      service_nm,
                      service_dt,
                      service_hours,
                      service_days,
                      helper_list:
                        this_helper_list.length === 0 ? [] : this_helper_list,
                      reg_dt,
                    },
                  };
                  try {
                    const res = await insertApply(submitContent);
                    alert('저장되었습니다');
                    insertInfoAcessLog(
                      location.pathname,
                      'premiumServiceDetail',
                      '저장',
                      undefined,
                      p_apply.crm_nm
                    );
                    window.location.href = '/apply';
                  } catch (error) {
                    alert('저장에 실패하였습니다\n 관리자에게 문의 바랍니다');
                  }
                }
              }}
            >
              저장하기
            </Button>
          )}
        </div>
        <Button
          danger
          type="primary"
          onClick={() => {
            const bool = confirm('정말로 데이터를 삭제하시겠습니까?');
            if (bool) {
              deleteApply(p_apply).then(() => {
                alert('삭제되었습니다');
                insertInfoAcessLog(
                  location.pathname,
                  'premiumServiceDetail',
                  '삭제',
                  undefined,
                  p_apply.document_id
                );
                window.location.href = '/apply';
              });
            }
          }}
        >
          삭제하기
        </Button>
      </div>
    </Container>
  );
};

const Container = ({ children }) => {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowX: 'scroll',
  };
  return <div style={style}>{children}</div>;
};

const Section = ({ children, title, button, flexDirection }) => {
  const style = {
    margin: '15px 50px',
    padding: '15px 30px',
    minWidth: '1160px',
    border: '1px solid #d2d2d2',
    borderRadius: '5px',
  };
  return (
    <div style={style} className="section">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <CommonTitle>{title}</CommonTitle>
        {button || null}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection,
        }}
      >
        {children}
      </div>
    </div>
  );
};

const CommonTitle = ({ children }) => <Title level={3}>{children}</Title>;
const Form = ({ children, withAsterisk }) => (
  <div>
    {withAsterisk ? (
      <h5 style={{ color: '#d2d2d2' }}>
        <Asterisk />
        {' 는 필수 입력항목입니다'}
      </h5>
    ) : null}
    {children}
  </div>
);
const FormItem = ({ children, label, required }) => (
  <label style={{ display: 'flex', margin: '10px 0' }}>
    <div
      className="label"
      style={{ margin: 'auto 0', minWidth: '130px', textAlign: 'left' }}
    >
      {`${label}`}
      {required ? <Asterisk /> : null}
    </div>
    {children}
  </label>
);
const Asterisk = () => <span style={{ color: 'red' }}>{' *'}</span>;

export default PApplyAddContainer;
