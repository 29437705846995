import React, { useRef, useState } from 'react';
import { CancelButton, SaveButton, ImgModal } from 'components/Common';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import {
  Button,
  Descriptions,
  Divider,
  Input,
  Steps,
  Space,
  Modal,
  Row,
} from 'antd';
import Item from 'antd/es/list/Item';
import { status_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { getCookie } from 'lib/cookie';
import { getAdminPwdYn } from 'controllers/Services/admins';
import { editChatApplyDetail } from 'controllers/chatbot/chatbotApply';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { decrypt } from '../../../../../controllers/common';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ChatApplyDetailTable = (props) => {
  const { chatApplyDetail, setChatApplyDetail } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imgModalIsOpen, setImgModalIsOpen] = useState(false);
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);
  const [adminPwd, setAdminPwd] = useState();
  const [URL, setUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const pdfRef = useRef(null);
  const [count, setCount] = useState(30 * 1000); //30초
  const step = 1000;
  const handleStatusChange = (current) => {
    setChatApplyDetail({
      ...chatApplyDetail,
      status: current,
    });
  };
  const decryptSSN = (memSsn) => {
    let ssn = decrypt(memSsn);
    const ssn1 = ssn.substr(0, 6);
    const ssn2 = ssn.substr(6);
    return ssn1 + '-' + ssn2;
  };

  const handleStatusCancelChange = (current) => {
    let statusNum;
    // eslint-disable-next-line default-case
    switch (current) {
      case 9:
        statusNum = 9;
        break;
      case 10:
        statusNum = 99;
        break;
    }
    setChatApplyDetail({ ...chatApplyDetail, status: statusNum });
  };

  const mappedImgUrl = () => {
    if (chatApplyDetail.new_img_url) {
      return chatApplyDetail.new_img_url.map((url) => {
        return (
          <Row key={url}>
            <img
              style={{
                height: '250px',
                width: 'auto',
              }}
              key={url}
              src={url}
              onClick={() => {
                setImgModalIsOpen(true), setUrl(url);
              }}
              alt="Img Url"
              onContextMenu={(e) => e.preventDefault()}
            />
            <Divider />
          </Row>
        );
      });
    }
  };

  const onDocumentLoaded = ({ numPages }) => setNumPages(numPages);

  const handlePdfLender = (e, url) => {
    console.log(e);
    e.preventDefault();
    setUrl(url);
    setPdfModalIsOpen(true);
  };

  const mappedPdfViewer = () => {
    if (chatApplyDetail.new_pdf_url) {
      return chatApplyDetail.new_pdf_url.map((url) => {
        return (
          <Row key={url}>
            <Document file={url} onLoadSuccess={console.log(url)}>
              <Page
                pageNumber={1}
                width={300}
                height={600}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                onClick={(e) => {
                  handlePdfLender(e, url);
                }}
              />
            </Document>
            <Divider />
          </Row>
        );
      });
    }
  };

  const countTransToTime = (count, step) => {
    const seconds = Math.floor((count / step) % 60);
    if (count > 0) {
      return seconds;
    } else if (count < 0) {
      setModalIsOpen(false);
    }
  };

  const checkPwnYn = async () => {
    // console.log('ckeditor', adminPwd);
    // console.log('session', getCookie('session'));
    // 여기에,,,도큐먼트랑 비번 같이 보내서 true, false로 떨어지는 펑션만들기..
    const yn = await getAdminPwdYn(getCookie('session'), adminPwd);

    if (yn) {
      setModalIsOpen(true);
      insertInfoAcessLog(
        location.pathname,
        'member_ssn',
        '조회',
        undefined,
        chatApplyDetail.member_id
      );
      const id = setInterval(() => {
        setCount((c) => c - step);
      }, 1000);
      return () => clearInterval(id);
    } else {
      alert('비밀번호를 다시 확인하세요.');
    }
  };

  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="계약자 이름">{chatApplyDetail.crm_nm || '-'}</Item>
        <Item label="계약자 연락처">{chatApplyDetail.crm_phone || '-'}</Item>
        <Item label="보험사명">{chatApplyDetail.company_id || '-'}</Item>
        {/* <Item label="신청 대상자">{chatApplyDetail.member_nm || '-'}</Item>
        <Item label="신청 대상자 연락처">
          {chatApplyDetail.member_ph || '-'}
        </Item>
        <Item label="신청 대상자 주소">
          {chatApplyDetail.member_addr || '-'}
        </Item> */}
      </Descriptions>
      <Divider />

      <Descriptions
        title="서비스 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="앱 명칭">{chatApplyDetail.product_id || '-'}</Item>
        <Item label="대분류">{chatApplyDetail.category1 || '-'}</Item>
        <Item label="중분류">{chatApplyDetail.category2 || '-'}</Item>
        <Item label="소분류">{chatApplyDetail.service_type || '-'}</Item>
        {/* <Item label="기관명" style={{ whiteSpace: 'pre-line' }}>
          {chatApplyDetail.service_nm || '-'}
        </Item> */}
        <Item label="관리자 메모">
          <Input.TextArea
            value={chatApplyDetail.admin_memo}
            rows={10}
            allowClear
            onChange={(e) =>
              setChatApplyDetail({
                ...chatApplyDetail,
                admin_memo: e.currentTarget.value,
              })
            }
          />
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions
        title="신청 정보"
        bordered
        column={2}
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Item label="서비스 진행 상태" span={2}>
          <Steps
            type="navigation"
            size="default"
            current={chatApplyDetail.status}
            labelPlacement="vertical"
            onChange={(current) => handleStatusChange(current)}
            className="site-navigation-steps"
          >
            <Steps.Step
              title={status_ref[0]}
              description={getFormattedDate(chatApplyDetail.reg_dt, 'opt1')}
            />
            <Steps.Step
              title={status_ref[1]}
              description={getFormattedDate(
                chatApplyDetail.admin_check_dt,
                'opt1'
              )}
            />
            <Steps.Step title={status_ref[2]} subTitle="" description="" />
          </Steps>
          <Steps
            type="navigation"
            size="small"
            current={
              chatApplyDetail.status >= 9
                ? chatApplyDetail.status === 99
                  ? 10
                  : 9
                : undefined
            }
            labelPlacement="vertical"
            initial={9}
            onChange={(current) => handleStatusCancelChange(current)}
            className="site-navigation-steps"
          >
            <Steps.Step
              title={status_ref[9]}
              status="error"
              subTitle=""
              description="사용자의 신청을 취소합니다"
            />
            {/*   */}
          </Steps>
        </Item>
      </Descriptions>
      <Divider />

      <Descriptions bordered column={1} style={{ width: '100%' }}>
        <Item label="서비스 지역">{chatApplyDetail.service_area || '-'}</Item>
         <Item label="기관명" style={{ whiteSpace: 'pre-line' }}>
          {chatApplyDetail.service_nm || '-'}
        </Item>
        <Item label="상담 희망일">
          {chatApplyDetail.service_dt
            ? chatApplyDetail.service_dt.slice(0, 10)
            : '-'}
        </Item>
        <Item label="상담희망시간">{chatApplyDetail.service_time || '-'}</Item>
        <Item label="서비스 선택">{chatApplyDetail.service_type || '-'}</Item>
        <Item label="사용자 전달사항" style={{ whiteSpace: 'pre-line' }}>
          {chatApplyDetail.service_memo || '-'}
        </Item>
        {chatApplyDetail.category2 == '진료예약' ? (
          <Item label="주민등록번호">
            {chatApplyDetail.member_ssn ? (
              <Space.Compact
                style={{
                  width: '100%',
                }}
              >
                <Input.Password
                  placeholder="비밀번호 입력"
                  onChange={(e) => setAdminPwd(e.target.value)}
                />
                <Button type="primary" onClick={() => checkPwnYn()}>
                  확인
                </Button>
              </Space.Compact>
            ) : (
              <p>삭제되었습니다.</p>
            )}
            <div>
              {modalIsOpen && (
                <Modal
                  open={modalIsOpen}
                  onCancel={() => setModalIsOpen(false)}
                  title={decryptSSN(chatApplyDetail.member_ssn)}
                  closable={false}
                  footer={
                    <Button onClick={() => setModalIsOpen(false)}>닫기</Button>
                  }
                  style={{ textAlign: 'center' }}
                  width={250}
                >
                  <p>{countTransToTime(count, step)}초 후 자동으로 닫힙니다.</p>
                </Modal>
              )}
            </div>
          </Item>
        ) : null}
      </Descriptions>
      <Divider />

      <Descriptions
        title="첨부 파일"
        bordered
        column={1}
        style={{ width: '100%', paddingTop: '10px' }}
        layout="vertical"
      >
        <Item label="이미지">{mappedImgUrl()}</Item>
        <Item label="pdf">{mappedPdfViewer()}</Item>
      </Descriptions>
      <Divider />

      <Row>
        <Space>
          <CancelButton location="/chat-apply" />
          <SaveButton
            func={editChatApplyDetail}
            data={chatApplyDetail}
            location="/chat-apply"
          />
        </Space>
      </Row>
      <div>
        {imgModalIsOpen && (
          <ImgModal
            open={imgModalIsOpen}
            modalIsOpen={setImgModalIsOpen}
            imgUrl={URL}
          ></ImgModal>
        )}
      </div>
      <div>
        {pdfModalIsOpen && (
          <Modal
            open={pdfModalIsOpen}
            footer={null}
            width={1200}
            onCancel={() => setPdfModalIsOpen(false)}
            modalIsOpen={setImgModalIsOpen}
            closable={false}
          >
            {
              <Row key={URL}>
                <Document file={URL} onLoadSuccess={onDocumentLoaded}>
                  <Page
                    pageNumber={pageNumber}
                    width={1150}
                    height={600}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    style={{ textAlign: 'center' }}
                  />
                </Document>
                <Divider />
                <div style={{ paddingLeft: '45%' }}>
                  <nav>
                    <ul className="pagination" style={{ padding: '0px' }}>
                      <Button
                        className="pgBtn"
                        icon={<LeftOutlined style={{ fontSize: '30px' }} />}
                        onClick={() =>
                          pageNumber > 1 ? setPageNumber(pageNumber - 1) : null
                        }
                      />
                      <p5 style={{ textAlign: 'center', fontSize: '30px' }}>
                        {pageNumber} / {numPages}
                      </p5>
                      <Button
                        className="pgBtn"
                        icon={<RightOutlined style={{ fontSize: '30px' }} />}
                        onClick={() =>
                          pageNumber < numPages
                            ? setPageNumber(pageNumber + 1)
                            : null
                        }
                      />
                    </ul>
                  </nav>
                </div>
              </Row>
            }
          </Modal>
        )}
      </div>
    </>
  );
};

export default ChatApplyDetailTable;
