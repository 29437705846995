import React, { useEffect, useState } from 'react';
import { getHelperActivities, getHelperDetail, getHelperGroupsList } from 'controllers/Outers/helpers';

const useHelperHandler = (document_id, type) => {
  const Helper = {};
  const initialState = {
    product_id: 'SamsungLife01_0000',
  };
  const [helper, setHelper] = useState(initialState);
  const [helpergroup_list, setHelpergroup_list] = useState([]);
  useEffect(() => {
    let getDataList = {};
    const fetchDataList = async (doc_id) => {
      if (type) {
        if (type === 'a') {
          getDataList = await getHelperActivities(doc_id) || [];
        } else if (type === 'd') {
          getDataList = await getHelperDetail(doc_id) || [];
        }
      }
      await setHelper(getDataList);
    };
    if (document_id) {
      fetchDataList(document_id);
    }
  }, [document_id]);
  useEffect(() => {
    if (helper.helpergroup_type) {
      getHelperGroupsList(helper.helpergroup_type).then((d) => {
        setHelpergroup_list(d);
      });
    }
  }, [helper.helpergroup_type]);

  Helper.helper = helper;
  Helper.setHelper = setHelper;
  Helper.helpergroup_list = helpergroup_list;
  Helper.setHelpergroup_list = setHelpergroup_list;

  Helper.handleChangeHelperGroupType = (changedType) => {
    setHelper({
      ...helper,
      helpergroup_doc_id: '',
      helpergroup_nm: '',
      helpergroup_type: changedType,
    });
  };
  Helper.handleChangeCompanyId = (company_id) => {
    setHelper({
      ...helper,
      company_id,
    });
  };

  Helper.handleChangeLocal = (e) => {
    setHelper({
      ...helper,
      local_yn: e.target.value,
    });
  };

  Helper.handleChangeBirth = (date, dateStr) => {
    setHelper({
      ...helper,
      birth_dt: dateStr,
    });
  };

  Helper.handleChangeHelperGroupName = (doc_id) => {
    const selected = helpergroup_list.filter((helperGroup) => helperGroup.helpergroup_doc_id === doc_id);
    setHelper({
      ...helper,
      helpergroup_doc_id: selected[0].helpergroup_doc_id,
      helpergroup_nm: selected[0].helpergroup_nm,
      helpergroup_type: selected[0].helpergroup_type,
    });
  };

  Helper.handleChangePhone = (e) => {
    setHelper({
      ...helper,
      phone: e.target.value,
    });
  };

  return Helper;
};

export default useHelperHandler;
