import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import GrowthRecsListTableContainer from 'containers/HealthLogs/GrowthRecs/GrowthRecsListTableContainer';
import { useCheckAuth } from 'lib/hooks';

const GrowthRecsPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['healthLogs']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<GrowthRecsListTableContainer />}
    />
  );
};

export default GrowthRecsPage;
