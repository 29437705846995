import React, { useState, useEffect } from 'react';
// import { Transition } from "react-transition-group";
import styles from './DropDown.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const DropDown = props => {
    const [visibleAnimation, setVisibleAnimation] = useState(false);

    return (
        <article 
        className={`${props.visible ? 'slide-out-dropdown' : 'slide-in-dropdown'}`}
              >
            {props.children}
        </article>
    )
}

export default DropDown;