import React, { useState, useEffect } from 'react';
import { getHashDetail } from 'controllers/Systems/appHash';
import { AppHashDetailTable } from 'components/Common';
import { Spin } from 'antd';

const AppHashDetailTableContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [hashDetail, setHashDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setHashDetail({});
      setLoading(true);
      const data = getHashDetail(document_id);
      data.then((d) => {
        setHashDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <AppHashDetailTable hashDetail={hashDetail} setHashDetail={setHashDetail} />
      </Spin>
    :
      <AppHashDetailTable hashDetail={hashDetail} setHashDetail={setHashDetail} />
  );
};

export default AppHashDetailTableContainer;