import React from 'react';
import { Descriptions, Divider, message, Space } from 'antd';
import { CancelButton } from 'components/Common';
import { subscribe_cancel_ref } from 'lib/dataRef';
import { getFormattedDate, to_date } from 'lib/getFormattedDate';
const { Item } = Descriptions;
const DentalDetailTable = (props) => {
  const { dentalDetail, setDentalDetail } = props;

  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="제휴사">
          {dentalDetail.subscribe_goods_nm || '-'}
        </Item>
        <Item label="이름">
          {dentalDetail.member_nm || '-'}
        </Item>
        <Item label="아이디">
          {dentalDetail.member_id}
        </Item>
        <Item label="연락처">
          {dentalDetail.member_ph || '-'}
        </Item>
        <Item label="상품 색상">
          {dentalDetail.subscribe_brush_color || '-'}
        </Item>
        <Item label="상품 종류">
          {dentalDetail.subscribe_brush_type || '-'}
        </Item>
        <Item label="처리상태">
          {subscribe_cancel_ref[dentalDetail.subscribe_cancel_yn] || '-'}
        </Item>
        <Item label="일시">
          {getFormattedDate(to_date(dentalDetail.subscribe_dt), 'opt5') || '-'}
        </Item>
      </Descriptions>
      <Divider />
    </>
  );
};

export default DentalDetailTable;
