import firebase from 'firebase/app';
import { storage } from '../store/firestore';

/**
 * 이미지를 firebase storage에 업로드
 * @param {string} company_nm 보험사 이름
 * @param {object} customOption 부가 옵션
 */
export default async function uploadToStorage(company_nm, customOption) {
  // firebase documentation 참조하여 만듦
  const { onProgress, onError, onSuccess, file } = customOption;
  try {
    const storageRef = storage.ref().child(
      'service/'
      + company_nm
      + '/' + Math.floor(new Date().getTime()),
    );
    const uploadTask = storageRef.put(file); // metadata.......
    let imgURL = '';
    await uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done.');
      onProgress({
        percent: progress,
      }, file);

      switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED:
        console.log('Upload is paused.');
        break;
      case firebase.storage.TaskState.RUNNING:
        console.log('Upload is running.');
        break;
      default:
        console.log('Unknown upload state...');
        break;
      }
    }, (error) => {
      switch (error.code) {
      case 'storage/unauthorized':
        console.log('Unauthorized.');
        break;
      case 'storage/canceled':
        console.log('Canceled.');
        break;
      case 'storage/unknown':
        console.log('Unknown.');
        break;
      default:
        console.log('Unknown error code');
        break;
      }
      onError(error);
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then((url) => {
        imgURL = url;
        onSuccess(url, file);
      });
    });
    return imgURL;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
