import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 어드민 사이트의 메인 페이지에 노출할, 특정 상태를 가진 서비스 신청의 갯수
 * @param {number} status 특정 상태 값
 */
export async function getMainAppliesSize(status) {
  try {
    return await axios.get(
      `${baseUrl}/main/getSize/${status}`).then((res) => {
        return res.data.count;
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 어드민 사이트의 메인 페이지에 노출할, 회원 정보
 */
export async function getMainMembersList() {
  try {
    return await axios.get(
      `${baseUrl}/main/getMemberList`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 어드민 사이트의 메인 페이지에 노출할, 서비스 신청 정보
 */
export async function getMainAppliesList() {
  try {
    return await axios.get(
      `${baseUrl}/main/getAppliesList`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 어드민 사이트의 메인 페이지에 노출할, 고객들의 로그인 이력 정보
 */
export async function getMainLoginLogsList() {
  try {
    return await axios.get(
      `${baseUrl}/main/getLogsList`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 어드민 사이트의 메인 페이지에 노출할, 특정 상태를 가진 서비스 신청의 갯수
//  * @param {number} status 특정 상태 값
//  */
// export async function getMainAppliesSize(status) {
//   try {
//     const memsRef = await firestore.collection('cust_APPLIES')
//       .where('status', '==', status).get();
//     return memsRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 어드민 사이트의 메인 페이지에 노출할, 회원 정보
//  */
// export async function getMainMembersList() {
//   try {
//     const memsRef = await firestore.collection('cust_MEMBERS')
//       .where('type', '==', 'C').orderBy('reg_dt', 'desc').limit(5)
//       .get();
//     const memDocs = [];

//     memsRef.docs.forEach((doc) => {
//       const product_id = String(doc.data().product_id).split('_');
//       memDocs.push({
//         document_id: doc.data().document_id,
//         company_id: doc.data().company_id,
//         app_code: product_id[0],
//         goods_code: product_id[1],
//         product_id: doc.data().product_id,
//         id: doc.data().id,
//         nm: doc.data().nm,
//         birth_dt: doc.data().birth_dt,
//         phone: doc.data().phone,
//         gender: doc.data().gender,
//         admin_msort: doc.data().admin_msort,
//         reg_dt: doc.data().reg_dt,
//         mod_dt: doc.data().mod_dt,
//         del_yn: doc.data().del_yn,
//         del_dt: doc.data().del_dt,
//         last_logged_dt: doc.data().last_logged_dt,
//       });
//     });

//     return memDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 어드민 사이트의 메인 페이지에 노출할, 서비스 신청 정보
//  */
// export async function getMainAppliesList() {
//   try {
//     // app_code and category2 are undefined.
//     const aplsRef = await firestore.collection('cust_APPLIES')
//       .orderBy('reg_dt', 'desc').limit(5)
//       .get();
//     const aplDocs = [];
//     aplsRef.docs.forEach((doc) => {
//       const product_id = String(doc.data().product_id).split('_');
//       aplDocs.push({
//         document_id: doc.data().document_id,
//         company_id: doc.data().company_id,
//         app_code: product_id[0],
//         goods_code: product_id[1],
//         product_id: doc.data().product_id,
//         category1: doc.data().category1,
//         category2: doc.data().category2,
//         crm_child_nm: doc.data().crm_child_nm,
//         crm_nm: doc.data().crm_nm,
//         crm_phone: doc.data().crm_phone,
//         status: doc.data().status,
//         admin_check_yn: doc.data().admin_check_yn,
//         admin_check_dt: doc.data().admin_check_dt,
//         reg_dt: doc.data().reg_dt,
//         del_yn: doc.data().del_yn,
//         del_dt: doc.data().del_dt,
//       });
//     });

//     return aplDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 어드민 사이트의 메인 페이지에 노출할, 고객들의 로그인 이력 정보
//  */
// export async function getMainLoginLogsList() {
//   try {
//     const lgsRef = await firestore.collection('cust_LOGINLOGS')
//       .orderBy('reg_dt', 'desc').limit(10)
//       .get();
//     const lgDocs = [];

//     lgsRef.docs.forEach((doc) => {
//       lgDocs.push({
//         company_id: doc.data().company_id,
//         crm_nm: doc.data().crm_nm,
//         crm_phone: doc.data().crm_phone,
//         login_yn: doc.data().login_yn,
//         reg_dt: doc.data().reg_dt,
//       });
//     });

//     return lgDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }