// 가운데4자리 전화번호 마스킹처리
export const maskPhone = (phone) => {
  if(!phone) return '-';
  const maskedPhone = phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3');
  return maskedPhone;
}

// 첫글자, 마지막글자 제외 이름 마스킹처리
export const maskName = (name) => {
  if(!name) return '-';
  if (name.length <= 2) {
    return name.charAt(0) + '*'.repeat(name.length - 1);
  }

  const maskedName = name.charAt(0) + '*'.repeat(name.length - 2) + name.charAt(name.length - 1);
  return maskedName;
}

// 연도 제외 생년월일 마스킹처리
export const maskBirth = (birth) => {
  if (!birth) return '-';
  if (birth.length === 6) {
    // 6자리인 경우 (YYMMDD 형식)
    return birth.charAt(0) + birth.charAt(1) + '**' + birth.charAt(4) + birth.charAt(5);
  } else if (birthday.length === 8) {
    // 8자리인 경우 (YYYYMMDD 형식)
    return birth.charAt(2) + birth.charAt(3) + '**' + birth.charAt(6) + birth.charAt(7);
  } else {
    return birth; // 다른 형식의 데이터는 그대로 반환
  }
}

// 주소 공백을 기준으로 세번째자리부터 마스킹처리
export const maskAddress = (address) => {
  if (!address) return '';
  const add = address.trim();
  const parts = add.split(' '); // 주소를 공백 기준으로 나눔
  const maskedParts = parts.map(part => part.substring(0, 2) + '*'.repeat(part.length - 2)); // 각 부분을 마스킹 처리
  return maskedParts.join(' '); // 다시 조합하여 반환
};

// 이메일 4번째 자리부터 마스킹처리
export const maskEmail = (email) => {
  if (!email) return '';
  const [id, domain] = email.split('@'); // 이메일 아이디와 도메인을 분리
  const maskedId = id.substring(0, 3) + '*'.repeat(id.length - 3); // 아이디 마스킹 처리
  return maskedId + '@' + domain; // 마스킹된 아이디와 도메인을 조합하여 반환
};