 import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination, ExcelModal } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { Button } from 'antd';
import { getAdminLoginLogList, makeXlsx } from 'controllers/Systems/adminLoginLogs';
import { login_yn_ref } from 'lib/dataRef';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
const AdminLoginLogTableContainer = () => {
  const [adminLogs, setAdminLogs] = useState({ 
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }});
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getAdminLoginLogList(setAdminLogs, adminLogs, 'first', setCurrentPage, setLoading);
  }, [])
  const excelDownload = () =>{  
    insertInfoAcessLog(location.pathname, 'makeXlsx', "다운로드", excelReason);
    makeXlsx(excelReason); 
  } 
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 

  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  const setPage = (page) => {
    setCurrentPage(page)
  }

  const renderMoveData = (move) => {
    getAdminLoginLogList(setAdminLogs, adminLogs, move, setCurrentPage, setLoading);
  }
  const columns = [
    {
      title: '아이디',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
    },
    {
      title: '로그인성공여부',
      dataIndex: 'login_yn',
      key: 'login_yn',
      align: 'center',
      render: (data) => login_yn_ref[data],
    },
    {
      title: '등록일시',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.systems.admin_login_log.path}
          />
        )}
        countLabel={(<CounterLabel count={adminLogs.count} />)}
        xlsButton={(
          <Button
              type="primary"
              style={{ float: 'right', marginLeft: '8px' }}
              onClick={() => {
                setModalIsOpen(true);
              }}
            >
            엑셀 다운로드
          </Button>
        )}
      />
      <ListTableWithOutPaging
         dataSource={currentData(adminLogs.data)}
         columns={columns}
         loading={loading}
         pagination={(
           <Pagination
             totalData={adminLogs.data}
             pagingState={adminLogs.pagingState}
             setPage={setPage}
             renderMoveData={renderMoveData}
           ></Pagination>
           )}
      />
      <div>
        {
          modalIsOpen && (
            <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
             </ExcelModal>
        )}
      </div>
    </>
  );
};

export default AdminLoginLogTableContainer;
