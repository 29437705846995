import React from 'react';
import { useCheckAuth } from '../../lib/hooks';
import PageTemplate from '../PageTemplate';
import HeaderContainer from '../../containers/HeaderContainer';
import { MenuPane } from '../../components/MenuPane';
import StepsListContainer from '../../containers/HealthLogs/Steps/StepsListContainer';

const StepsPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['healthLogs']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<StepsListContainer />}
    />
  );
};

export default StepsPage;
