import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 앱버전 관리 리스트 조회
 */
export async function getAppVersList(setAppVer, setCount, setLoading, searchState) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/system/version/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    setAppVer(result.lists);
    setCount(result.count);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 강제 업데이트 앱 버전 신규 입력
 * @param {object} appVer 신규로 입력될 계정의 정보(id, nm, pwd, ...)
 */
export async function insertAppVer(appVer) {
  // appVer keys : av_tp, av_fnl, av_enfr_updt, av_dstrb_yn, ....(optional)
  try {
    // document에 들어가야 할 필수 값 체크
    if (!appVer.company_id && !appVer.av_tp && !appVer.av_fnl && !appVer.av_enfr_updt && !appVer.av_dstrb_yn && !appVer.text && !appVer.product_id) {
      return { result: 'fail', message: 'Please check the values.' };
    }

    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/system/version/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        appVer
      }),
    })
    .then((response) =>  response.data);

    return result;
    } catch (err) {
      console.error(err);
      return { result: 'error: \n', message: err };
  }
}

/**
 * 강제 업데이트 앱 버전의 상세 정보 조회
 * @param {string} document_id 해당 버전의 정보가 저장된 document의 id
 */
export async function getAppVerDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/system/version/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 앱 버전 수정
 * @param {object} appVer 해당 계정에 새롭게 입력될 값
 */
export async function editAppVerDetail(appVer) {
  // appVer === getAppVerDetail's return value
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/system/version/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        appVer
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 업데이트 앱 버전 삭제
 * @param {object} appVer 삭제하고자 하는 앱 버전의 정보
 */
export async function deleteAppVer(appVer) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/system/version/delete`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        appVer
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// /**
//  * 앱버전 관리의 갯수 조회
//  */
// export async function getAppVersSize() {
//   try {
//     const appVersRef = await firestore.collection('cust_APPVERSION')
//     .orderBy('reg_dt','desc')
//     .get();

//     return appVersRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 앱버전 관리 리스트 조회
//  */
// export async function getAppVersList() {
//   try {
//     const appVersRef = await firestore.collection('cust_APPVERSION')
//     .orderBy('reg_dt','desc')
//     .get();

//     const lists = [];

//     appVersRef.docs.forEach(async (doc) => {
//       lists.push({
//         document_id: doc.data().document_id,
//         company_id: doc.data().company_id,
//         product_id: doc.data().product_id,
//         av_tp: doc.data().av_tp,
//         av_fnl: doc.data().av_fnl,
//         av_enfr_updt: doc.data().av_enfr_updt,
//         reg_dt: doc.data().reg_dt,
//         mod_dt: doc.data().mod_dt,
//         av_dstrb_yn : doc.data().av_dstrb_yn,
//       });
//     });
//     return lists;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 강제 업데이트 앱 버전 신규 입력
//  * @param {object} appVer 신규로 입력될 계정의 정보(id, nm, pwd, ...)
//  */
// export async function insertAppVer(appVer) {
//   // appVer keys : av_tp, av_fnl, av_enfr_updt, av_dstrb_yn, ....(optional)
//   try {
//     // document에 들어가야 할 필수 값 체크
//     if (!appVer.company_id && !appVer.av_tp && !appVer.av_fnl && !appVer.av_enfr_updt && !appVer.av_dstrb_yn && !appVer.text && !appVer.product_id) {
//       return { result: 'fail', message: 'Please check the values.' };
//     }

//     const appVersRef = await firestore.collection('cust_APPVERSION')
//                     .where('company_id','==',appVer.company_id)
//                     .where('product_id', '==', appVer.product_id)
//                     .where('av_tp','==',appVer.av_tp)
//                     .where('av_fnl','==',appVer.av_fnl)
//                     .where('av_enfr_updt','==',appVer.av_enfr_updt)
//                     .get();

//     if(appVersRef.size > 0){
//       return { result: 'fail', message: 'document is exist.' };
//     }
//     // 기본적으로 들어가야 할 값 가공 및 처리
//     appVer.reg_dt = Math.floor(new Date().getTime());
//     appVer.mod_dt = '';

//     // document 생성 및 값 입력
//     await firestore.collection('cust_APPVERSION').add(appVer)
//         .then((doc) => {
//         firestore.collection('cust_APPVERSION').doc(doc.id)
//             .set({ document_id: doc.id }, { merge: true });
//         }).catch((err) => {
//         console.error(err);
//         });
//     return { result: 'success', message: 'The app-version is inserted.' };
//   } catch (err) {
//       console.error(err);
//       return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 강제 업데이트 앱 버전의 상세 정보 조회
//  * @param {string} document_id 해당 버전의 정보가 저장된 document의 id
//  */
// export async function getAppVerDetail(document_id) {
//   try {
//     const appVersRef = await firestore.collection('cust_APPVERSION')
//         .doc(document_id).get();
//     return {
//       document_id: appVersRef.data().document_id,
//       company_id: appVersRef.data().company_id,
//       product_id: appVersRef.data().product_id,
//       av_tp: appVersRef.data().av_tp,
//       av_fnl: appVersRef.data().av_fnl,
//       av_enfr_updt: appVersRef.data().av_enfr_updt,
//       text: appVersRef.data().text,
//       reg_dt: appVersRef.data().reg_dt,
//       mod_dt: appVersRef.data().mod_dt,
//       av_dstrb_yn : appVersRef.data().av_dstrb_yn,
//     };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 앱 버전 수정
//  * @param {object} appVer 해당 계정에 새롭게 입력될 값
//  */
// export async function editAppVerDetail(appVer) {
//   // appVer === getAppVerDetail's return value
//   try {
//     // 기존에 DB에 저장되어있던 값 읽어오기
//     const original = await firestore.collection('cust_APPVERSION')
//         .doc(appVer.document_id).get();

//     // 잘못 덮어씌워지면 안 되는 값 제거
//     delete appVer.mod_dt;

//     // 현재 들어온 값과 비교하기 위한 DB에 인서트된 값을 담은 object
//     const checkChg = {
//       company_id: original.company_id,
//       product_id: original.product_id,
//       av_tp: original.av_tp,
//       av_fnl: original.av_fnl,
//       av_enfr_updt: original.av_enfr_updt,
//       text: original.text,
//       av_dstrb_yn: original.av_dstrb_yn,
//     };

//     // 변경된 사항이 있다면 mod_dt(수정시각)을 업데이트
//     Object.keys(checkChg).forEach((v) => {
//       if (checkChg[v] !== appVer[v]) {
//         appVer.mod_dt = Math.floor(new Date().getTime());
//       }
//     });

//     // 아예 키가 선언도 되지 않았거나 빈 값이 들어있는 경우, 삭제 처리
//     // (값이 undefined인 상태로 document를 업데이트 하려 하면 에러 발생)
//     Object.keys(appVer).forEach((v) => {
//       if (appVer[v] === undefined || appVer[v].length === 0) {
//         delete appVer[v];
//       }
//     });
//     if (appVer.mod_dt !== undefined && appVer.mod_dt > 0) {
//       // document 값 업데이트
//       await firestore.collection('cust_APPVERSION').doc(appVer.document_id).set(appVer, { merge: true });
//       return { result: 'success', message: 'The appVersion information is updated.' };
//     } else {
//       return { result: 'fail', message: 'The appVersion is the same as before.' };
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 업데이트 앱 버전 삭제
//  * @param {object} appVer 삭제하고자 하는 앱 버전의 정보
//  */
// export async function deleteAppVer(appVer) {
//   try {
//     await firestore.collection('cust_APPVERSION').doc(appVer.document_id)
//         .delete();

//     return { result: 'success', message: 'The appVersion is deleted.' };
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }