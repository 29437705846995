import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 관리자 접속이력 내역 조회
 */
export async function getAdminLoginLogList(
  setAdminLogs,
  adminLogs,
  goPage,
  setCurrentPage,
  setLoading
) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/system/adminLoginLog/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage,
        pagingState: adminLogs.pagingState,
      }),
    }).then((res) => {
      setAdminLogs(res.data);
      setCurrentPage(1);
      setLoading(false);
    });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 모든 관리자 접속이력 로그 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_LoginLog_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };
    return await axios
      .post(`${baseUrl}/system/adminLoginLog/excel`, {})
      .then((res) => {
        return exportXlsx(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
