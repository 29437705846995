import React, { useEffect, useState } from 'react';
import { Row, Input, Descriptions, Divider, Space, Switch } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { getFormattedDate } from 'lib/getFormattedDate';
import { editAdminDetail, deleteAdmin } from 'controllers/Services/admins';
import { CancelButton, SaveButton, DelButton } from 'components/Common';
import AdminPermissionCheckTable from './AdminPermissionCheckTable';
// import AdminExternalAccountTable from './AdminExternalAccountTable';

const { Item } = Descriptions;
const AdminDetailTable = (props) => {
  const { adminDetail, setAdminDetail } = props;
  const [isLocked, setIsLocked] = useState(true);
  const handleSwitchChange = () => {
    setAdminDetail({ ...adminDetail, block_yn: !adminDetail.block_yn });
  };

  return (
    <>
      <Row>
        <Descriptions
          title="관리자 회원 정보"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="ID">{adminDetail.id}</Item>
          <Item label="비밀번호">
            <Input.Password
              value={adminDetail.pwd}
              onChange={(e) =>
                setAdminDetail({ ...adminDetail, pwd: e.target.value })
              }
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Item>
          <Item label="이름">
            <Input
              value={adminDetail.nm}
              style={{ width: '120px', marginRight: '10px' }}
              disabled={isLocked}
              onChange={(e) =>
                setAdminDetail({ ...adminDetail, nm: e.target.value })
              }
            />
            <Space>
              활성화
              <Switch
                checked={!isLocked}
                onChange={() => setIsLocked(!isLocked)}
              />
            </Space>
          </Item>
          <Item label="연락처">
            <Space.Compact
              style={{
                width: '100%',
              }}
            >
              <Input
                value={adminDetail.phone}
                onChange={(e) =>
                  setAdminDetail({ ...adminDetail, phone: e.target.value })
                }
              />
            </Space.Compact>
          </Item>
          <Item label="생년월일">{' - '}</Item>
          <Item label="등록일">{getFormattedDate(adminDetail.reg_dt)}</Item>
          <Item label="최종접속일">
            {getFormattedDate(adminDetail.last_logged_dt)}
          </Item>
          <Item label="소속">
            <Input
              value={adminDetail.department}
              onChange={(e) =>
                setAdminDetail({ ...adminDetail, department: e.target.value })
              }
            />
          </Item>
          <Item label="권한">
            <AdminPermissionCheckTable
              adminDetail={adminDetail}
              setAdminDetail={setAdminDetail}
            />
          </Item>
          {/* <Item label="외부 관리자 계정" style={{ width: '105px' }}>
            <AdminExternalAccountTable
              adminDetail={adminDetail}
              setAdminDetail={setAdminDetail}
            />
          </Item> */}
          <Item label="수정시각">
            {getFormattedDate(adminDetail.mod_dt, 'opt2')}
          </Item>
          <Item label="차단여부">
            <Switch
              checked={adminDetail.block_yn}
              onChange={handleSwitchChange}
            />
          </Item>
          <Item label="차단일자">
            {getFormattedDate(adminDetail.block_dt, 'opt2')}
          </Item>
          <Item label="비밀번호수정시각">
            {getFormattedDate(adminDetail.pwd_mod_dt, 'opt2')}
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton location="/admin" />
          <SaveButton
            func={editAdminDetail}
            data={adminDetail}
            location="/admin"
          />
          <DelButton
            func={deleteAdmin}
            data={adminDetail.document_id}
            location="/admin"
          ></DelButton>
        </Space>
      </Row>
    </>
  );
};

export default AdminDetailTable;
