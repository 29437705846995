import React, { useState } from 'react';
import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames/bind';
import styles from './Pagination.scss';
const cx = classNames.bind(styles);

const Pagination = (props) => {
    const data = props.totalData;
    const pageState = props.pagingState;
    const [active, setActive] = useState(1);

  const pageNumbers = [];
  const size = data.length == 0 ? 1 : data.length;

  for (let i = 1; i <= Math.ceil(size / 10); i++) {
    const n = (pageState.page*10)-10;
    pageNumbers.push(n + i);
  }

    return (
        <div>
            <nav>
                <ul className="pagination">
                    <Button className='pgBtn'
                        icon={<DoubleLeftOutlined style={{ fontSize: '12px' }} />}
                        disabled={(pageState.goPage === 'first' || data.length == 0) ? true: false}
                        onClick={() => {
                            props.renderMoveData('first');
                        }}
                    />
                    <Button  className='pgBtn'
                        icon={<LeftOutlined style={{ fontSize: '12px' }} />}
                        disabled={(pageState.goPage === 'first' || data.length == 0) ? true: false}
                        onClick={() => {
                            props.renderMoveData('prev');
                        }}
                    />
                    {pageNumbers.map((number) => (
                        <li key={number} className={"page-item"+ (active == number ? "-active" : "")} onClick={() => {setActive(number), props.setPage(number)}}>
                            <span className="page-link">
                                {number}
                            </span>
                        </li>
                    ))}
                    <Button  className='pgBtn'
                        icon={<RightOutlined style={{ fontSize: '12px' }} />}
                        disabled={(pageState.goPage === 'last' || data.length == 0 || data.length < 100) ? true: false}
                        onClick={() => {
                            props.renderMoveData('next');
                        }}
                    />
                    <Button  className='pgBtn'
                        icon={<DoubleRightOutlined style={{ fontSize: '12px' }} />}
                        disabled={(pageState.goPage === 'last' || data.length == 0 || data.length < 100) ? true: false}
                        onClick={() => {
                            props.renderMoveData('last');
                        }}
                    />
                </ul>
            </nav>
        </div>
    )
};
export default Pagination;