import React, { useEffect, useState } from 'react';
import { getMedicinesList } from 'controllers/HealthLogs/medicines';
import { Button, Drawer } from 'antd';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate } from 'components/Common';
import { getFormattedDate } from 'lib/getFormattedDate';
import MedicinesDetailTableContainer from './MedicinesDetailTableContainer';
import { routes_ref } from 'lib/routeRef';
import { company_ref } from 'lib/dataRef';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const MedicinesListTableContainer = () => {
  const [medicens, setMedicens] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [status, setStatus] = useState({
    visible: false,
    member_id: '',
  });

  const onClose = () => {
    setStatus({
      visible: false,
      member_id: '',
    });
  };
  const showDrawer = (member_id) => {
    setStatus({
      visible: true,
      member_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getMedicinesList(setMedicens, setCount, setLoading, searchState);
  }, []);

  function goSearch() {
    getMedicinesList(setMedicens, setCount, setLoading, searchState);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'member_id',
      key: 'member_id',
      align: 'center',
      render: (member_id) => (
        <>
          <Button
            key={member_id}
            type="primary"
            size="small"
            onClick={() => {insertInfoAcessLog(location.pathname, 'medicinesDetail', "조회", undefined, member_id), showDrawer(member_id)}}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data]
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '처방전 명',
      dataIndex: 'pre_name',
      key: 'pre_name',
      align: 'center',
    },
    {
      title: '처방병원',
      dataIndex: 'pre_hospital',
      key: 'pre_hospital',
      align: 'center',
    },
    {
      title: '처방일',
      dataIndex: 'pre_dt',
      key: 'pre_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '복용 알림 기간',
      dataIndex: 'med_end_dt',
      key: 'med_end_dt',
      align: 'center',
      render: (date, record) => {
        let str = '';
        if (record.med_end_yn) {
          str = ' (종료)';
        }
        return ('~' + getFormattedDate(date) + str);
      },
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.healthLogs.medicine.path}
          />
        )}
        countLabel={(<CounterLabel count={count} />)}
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['보험사', '이름', '연락처'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableTemplate
        dataSource={medicens}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
          <Drawer
            width={1200}
            closable
            onClose={onClose}
            open={status.visible}
          >
            <MedicinesDetailTableContainer
              member_id={status.member_id}
            />
          </Drawer>
        )}
      />
    </>
  );
};

export default MedicinesListTableContainer;
