import React, { useEffect, useState } from 'react';
import {
  CounterLabel,
  LinkButton,
  ListTableWithOutPaging,
  Pagination,
  MediCertsAddTable,
  DelListButton,
} from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Button, Drawer, Badge } from 'antd';
import { company_ref, mediCerts_ref, mediCerts_type_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { getMediCertsList } from 'controllers/Services/mediCerts';
import { routes_ref } from 'lib/routeRef';
import { deleteBundle } from 'controllers/common';
import MediCertsDetailTableContainer from './MediCertsDetailTableContainer';
import { maskName, maskPhone } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const MediCertsListTableContainer = () => {
  const [mediCerts, setMedicerts] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first',
    },
  });
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedList, setCheckedList] = useState([]);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });

  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };

  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getMediCertsList(
      setMedicerts,
      mediCerts,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
  }, []);

  function goSearch() {
    getMediCertsList(
      setMedicerts,
      mediCerts,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
  }
  let cp = currentPage;
  if (cp > 10) {
    // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp % 10;
    cp = n == 0 ? 10 : n;
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  };
  let list = currentData(mediCerts.data);

  const setPage = (page) => {
    setCurrentPage(page);
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const renderMoveData = (move) => {
    getMediCertsList(
      setMedicerts,
      mediCerts,
      move,
      setCurrentPage,
      searchState,
      setLoading
    );
    // 페이지 이동할때마다 체크박스 리셋
    list = {};
    setCheckedList([]);
  };

  const onChangeChecked = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      checkedList.splice(checkedList.indexOf(id), 1);
      setCheckedList([...checkedList]);
    }
  };
  const onCheckedAll = (e) => {
    const checkList = list.map((item) => item.document_id);
    if (e.target.checked) {
      setCheckedList(checkList);
    } else {
      setCheckedList([]);
    }
  };
  const columns = [
    {
      title: () => {
        return (
          <>
            <input
              type="checkbox"
              style={{ marginRight: '5px' }}
              checked={checkedList.length == list.length}
              onChange={(e) => onCheckedAll(e)}
            />
            전체
          </>
        );
      },
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <input
            type="checkbox"
            style={{ marginRight: '5px' }}
            checked={checkedList.includes(document_id)}
            onChange={(e) => onChangeChecked(e, document_id)}
          />
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => {
              insertInfoAcessLog(
                location.pathname,
                'medicertsDetail',
                '조회',
                undefined,
                document_id
              ),
                showDrawer(document_id);
            }}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data) => {
        let badge;
        switch (data) {
          case 0:
          case 10:
            badge = <Badge status="error" text={mediCerts_ref[data]} />;
            break;
          case 1:
          case 11:
          case 12:
            badge = <Badge status="processing" text={mediCerts_ref[data]} />;
            break;
          case 2:
          case 13:
          case 14:
            badge = <Badge status="success" text={mediCerts_ref[data]} />;
            break;
          case 9:
          case 19:
            badge = <Badge status="default" text={mediCerts_ref[data]} />;
            break;
          case 99:
            badge = <Badge status="default" text={mediCerts_ref[9]} />;
            break;
          default:
            break;
        }
        return badge;
      },
    },
    {
      title: '타입',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: (type) => mediCerts_type_ref[type],
    },
    {
      title: '관리자 확인 시각',
      dataIndex: 'admin_check_dt',
      key: 'admin_check_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '신청일자',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '서류제출 여부',
      dataIndex: 'approved_yn',
      align: 'center',
      render: (bool) => (bool ? 'YES' : ''),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton link={routes_ref.base.services.mediCert.path} />
        }
        countLabel={<CounterLabel count={mediCerts.count} />}
        addButton={<MediCertsAddTable />}
        delButton={
          <DelListButton
            func={deleteBundle}
            data={checkedList}
            location="/mediCerts"
          />
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'mediCerts',
          field: ['보험사', '이름', '연락처', '상태'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(mediCerts.data)}
        columns={columns}
        loading={loading}
        pagination={
          <Pagination
            totalData={mediCerts.data}
            pagingState={mediCerts.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        }
        DetailTableContainer={
          <Drawer width={750} closable onClose={onClose} open={status.visible}>
            <MediCertsDetailTableContainer document_id={status.document_id} />
          </Drawer>
        }
      />
    </>
  );
};

export default MediCertsListTableContainer;
