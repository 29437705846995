import React, { useEffect, useState } from 'react';
import { getMentalList } from 'controllers/Services/mental';
import { CounterLabel, LinkButton, ListTableTemplate } from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Button, Drawer, Badge } from 'antd';
import MentalDetailTableContainer from './MentalDetailTableContainer';
import { routes_ref } from 'lib/routeRef';
import { company_ref, status_ref } from 'lib/dataRef';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { maskName, maskPhone } from 'lib/maskPersonInfo';

const MentalListTableContainer = () => {
  const [mental, setMental] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [status, setStatus] = useState({
    visible: false,
    document_id: '',
  });
  const onClose = () => {
    setStatus({
      visible: false,
      document_id: '',
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id,
    });
  };

  useEffect(() => {
    getMentalList(setMental, setCount, setLoading);
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => {insertInfoAcessLog(location.pathname, 'mentalDetail', "조회", undefined, document_id), showDrawer(document_id)}}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data]
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data) => {
        let badge;
        switch (data) {
        case 0:
          badge = <Badge status="error" text={status_ref[data]} />;
          break;
        case 2:
          badge = <Badge status="success" text={status_ref[data]} />;
          break;
        default:
          break;
        }
        return badge;
      },
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.services.mental.path}
          />
        )}
        countLabel={(<CounterLabel count={count} />)}
      />
      <ListTableTemplate
        dataSource={mental}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
          <Drawer
            width={750}
            closable
            onClose={onClose}
            open={status.visible}
          >
            <MentalDetailTableContainer
              document_id={status.document_id} />
          </Drawer>
        )}
      />
    </>
  );
};

export default MentalListTableContainer;
