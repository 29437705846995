import React, { useEffect, useState } from 'react';
import { getStepDetail } from 'controllers/HealthLogs/steps';
import { StepsDetailTable } from 'components/Common';
import { Spin } from 'antd';

const StepsDetailContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const initialState ={
    stepsDetail: '',
    monthly: ''
  };
  const [stepsDetail, setStepsDetail] = useState(initialState);
  useEffect(() => {
    if (document_id) {
      setLoading(true);
      setStepsDetail(initialState)
      const res = getStepDetail(document_id);
      res.then((d) => {
        setStepsDetail({
          stepsDetail: d.total,
          monthly: d.monthly
        });
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <StepsDetailTable stepsDetail={stepsDetail.stepsDetail} monthly={stepsDetail.monthly} />
      </Spin>
    :
      <StepsDetailTable stepsDetail={stepsDetail.stepsDetail} monthly={stepsDetail.monthly} />
  );
};

export default StepsDetailContainer;
