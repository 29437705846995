import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import { useCheckAuth } from '../../lib/hooks';
import HeaderContainer from '../../containers/HeaderContainer';
import { MenuPane } from '../../components/MenuPane';
import DzPredsListContainer from '../../containers/HealthLogs/DzPreds/DzPredsListContainer';

const DzPredsPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['healthLogs']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<DzPredsListContainer />}
    />
  );
};

export default DzPredsPage;
