import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import { useCheckAuth } from 'lib/hooks';
import ChatDoctorTableContainer from 'containers/chatbot/ChatDoctor/ChatDoctorListTableContainer';

const ChatDoctorPage = ({ pid }) => {
  useCheckAuth(pid);
  console.log('의료진관리');
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['chatbot', 'chatbot-contents']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<ChatDoctorTableContainer />}
    />
  );
};

export default ChatDoctorPage;
