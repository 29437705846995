import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import dayjs from 'dayjs';

/**
 * 걷기 기록 조회
 */
export async function getStepsList(setSteps, steps, goPage, setCurrentPage, searchState, setLoading) {
  try {
    return await axios.post(
      `${baseUrl}/steps/getList`, {
        goPage: goPage,
        pagingState: steps.pagingState,
        searchState: searchState
      }).then((res) => {
        setSteps(res.data);
        setCurrentPage(1);
        setLoading(false);
      }).catch((err) => {
        console.error(err);
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 걷기 기록의 세부 정보 조회
 * @param {string} document_id 조회하고자 하는 걷기 기록의 document id
 */
export async function getStepDetail(document_id) {
  try {
    return await axios.get(
      `${baseUrl}/steps/getDetail/${document_id}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 걷기 기록을 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if(!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (values) => {
      const fileName = 'AAIHC_steps_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(values);
      const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios.post(
      `${baseUrl}/steps/excel/all`,{
        searchState: searchState
      }).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }}

  /**
 * 걷기 기록을 엑셀로 추출(회원별)
 * @param {document_id} document_id 조회하고자 하는 고객의 docId
 * @param {number} year 조회하고자 하는 특정 년도
 * @param {number} month 조회하고자 하는 특정 월
 */
export async function makeForCustomerXlsx(document_id, year, month, excelReason) {
  try {
    // 사유 미작성 시 다운로드 못함
    if(!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    insertInfoAcessLog(location.pathname, 'makeForCustomerXlsx', "다운로드", excelReason);
    
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (values) => {
      const fileName = 'AAIHC_steps_by_monthly_' + Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(values);
      const workBook = { Sheets: { 'daily': workSheet }, SheetNames: ['daily'] };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios.get(
      `${baseUrl}/steps/excel/${document_id}/${year}/${month}`).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
    }}

    /**
 * 하나손보 300만보 달성자를 엑셀로 추출
 */
export async function make300StepsXlsx() {
  try {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    let date = new Date();
    date = new Date(date.getFullYear().toString(), date.getMonth());
    date = dayjs(date).format("YYYYMM");

    const exportXlsx = (values) => {
      const fileName = date + '_하나걷기 300만보 달성자';
      const workSheet = XLSX.utils.json_to_sheet(values);
      const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios.get(
      `${baseUrl}/steps/excel/hana300`).then((res) => {
        return exportXlsx(res.data);
      }).catch((err) => {
        console.error(err);
      });

  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }}

// import firestore from 'store/firestore';
// import * as XLSX from 'xlsx';
// import * as FileSaver from 'file-saver';
// import { company_ref, product_id_ref } from 'lib/dataRef';

// /**
//  * 걷기 기록의 갯수 조회
//  */
// export async function getStepsSize() {
//   try {
//     const stepsRef = await firestore.collection('cust_STEPS')
//       .get();
//     return stepsRef.size;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 걷기 기록 조회
//  */
// export async function getStepsList() {
//   try {
//     const stepsRef = await firestore.collection('cust_STEPS')
//       .orderBy('reg_dt', 'desc')
//       .get();
//     const lists = [];

//     stepsRef.docs.forEach(async (doc) => {
//       lists.push({
//         document_id: doc.data().document_id,
//         company_id: doc.data().company_id,
//         product_id: doc.data().product_id,
//         member_id: doc.data().member_id,
//         crm_nm: doc.data().crm_nm,
//         crm_phone: doc.data().crm_phone,
//         steps_total: doc.data().steps_total,
//         // 기록 시작일과 종료일 둘 다 명시된 경우만 사용
//         activity_dt: doc.data().activity_start_str_dt !== undefined && doc.data().activity_start_str_dt.length > 0
//           && doc.data().activity_end_str_dt !== undefined && doc.data().activity_end_str_dt.length > 0
//           ? doc.data().activity_start_str_dt + ' ~ ' + doc.data().activity_end_str_dt
//           : '',
//         del_yn: doc.data().del_yn,
//         del_dt: doc.data().del_dt,
//       });
//     });
//     return lists;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 걷기 기록의 세부 정보 조회
//  * @param {string} document_id 조회하고자 하는 걷기 기록의 document id
//  */
// export async function getStepDetail(document_id) {
//   try {
//     const stepRef = await firestore.collection('cust_STEPS')
//       .where('document_id', '==', document_id)
//       .get();
//     const total = {
//       document_id: stepRef.docs[0].data().document_id,
//       company_id: stepRef.docs[0].data().company_id,
//       product_id: stepRef.docs[0].data().product_id,
//       member_id: stepRef.docs[0].data().member_id,
//       crm_nm: stepRef.docs[0].data().crm_nm,
//       crm_phone: stepRef.docs[0].data().crm_phone,
//       // 기록 시작일과 종료일 둘 다 명시된 경우만 사용
//       activity_dt: stepRef.docs[0].data().activity_start_str_dt !== undefined
//         && stepRef.docs[0].data().activity_start_str_dt.length > 0
//         && stepRef.docs[0].data().activity_end_str_dt !== undefined
//         && stepRef.docs[0].data().activity_end_str_dt.length > 0
//         ? stepRef.docs[0].data().activity_start_str_dt + ' ~ ' + stepRef.docs[0].data().activity_end_str_dt
//         : '',
//       daily_steps_max: stepRef.docs[0].data().daily_steps_max,
//       steps_total: stepRef.docs[0].data().steps_total,
//       calories_total: stepRef.docs[0].data().calories_total,
//       monthly_steps_avg: stepRef.docs[0].data().monthly_steps_avg,
//       daily_calories_avg: stepRef.docs[0].data().daily_calories_avg,
//       daily_calories_max: stepRef.docs[0].data().daily_calories_max,
//       points_total: stepRef.docs[0].data().points_total,
//     };
//     const monthlyRef = await firestore.collection('cust_STEPS')
//       .doc(stepRef.docs[0].id)
//       .collection('monthly')
//       .where('del_yn', '==', false)
//       .orderBy('year', 'desc')
//       .orderBy('month', 'desc')
//       .get();
//     const monthly = [];

//     monthlyRef.docs.forEach((doc) => {
//       monthly.push({
//         year: doc.data().year,
//         month: doc.data().month,
//         count: doc.data().count,
//         steps_avg: doc.data().steps_avg,
//         steps_max: doc.data().steps_max,
//         steps_total: doc.data().steps_total,
//         calories_avg: doc.data().calories_avg,
//         calories_max: doc.data().calories_max,
//         calories_total: doc.data().calories_total,
//         points_total: doc.data().points_total,
//         total_document_id: stepRef.docs[0].data().document_id,
//       });
//     });

//     return { total, monthly};
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 걷기 기록을 엑셀로 추출
//  * @param {number} year (미사용)조회하고자 하는 특정 년도
//  * @param {number} month (미사용)조회하고자 하는 특정 월
//  */
// export async function makeXlsx(year, month) {
//   try {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const data = [];

//     const stepsExcelRef = await firestore.collection('cust_STEPSXLSX')
//       .where('del_yn', '==', false)
//       .orderBy('reg_dt', 'desc')
//       .get();

//     stepsExcelRef.docs.forEach((doc) => {
//       if (doc.data().crm_nm !== '양희윤') {
//         const app_name = product_id_ref[doc.data().product_id];
//         const company_name = company_ref[doc.data().company_id];

//         data.push({
//           '보험사 코드': doc.data().company_id,
//           '보험사명': company_name,
//           '앱명': app_name,
//           '이름': doc.data().crm_nm,
//           '연락처': doc.data().crm_phone,
//           '년도': doc.data().year,
//           '월': doc.data().month,
//           '누적 걸음': doc.data().steps_total,
//         });
//       }
//     });

//     const exportXlsx = (values) => {
//       const fileName = 'AAIHC_steps_' + Math.floor(new Date().getTime() / 1000);
//       const workSheet = XLSX.utils.json_to_sheet(values);
//       const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
//       const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
//       const blob = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(blob, fileName, fileExtension);
//     };
//     return exportXlsx(data);
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }}

//   /**
//  * 걷기 기록을 엑셀로 추출(회원별)
//  * @param {document_id} document_id 조회하고자 하는 고객의 docId
//  * @param {number} year 조회하고자 하는 특정 년도
//  * @param {number} month 조회하고자 하는 특정 월
//  */
// export async function makeForCustomerXlsx(document_id, year, month) {
//   try {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const stepsExcelRef = await firestore.collection('cust_STEPS')
//     .doc(document_id)
//     .collection('daily')
//     .where('year', '==', year)
//     .where('month', '==', month)
//     .where('del_yn', '==', false)
//     .orderBy('steps_reg_str_dt', 'asc')
//     .get();

//     const daily = [];

//       stepsExcelRef.forEach((doc) => {
//         daily.push({
//             '상세일자': doc.data().steps_reg_str_dt,
//             '걸음수': doc.data().steps,
//             '포인트': doc.data().points,
//             '칼로리': doc.data().calories,
//             '거리': doc.data().distance,
//         });
//       });

//     const exportXlsx = (values) => {
//       const fileName = 'AAIHC_steps_by_monthly_' + Math.floor(new Date().getTime() / 1000);
//       const workSheet = XLSX.utils.json_to_sheet(values);
//       const workBook = { Sheets: { 'daily': workSheet }, SheetNames: ['daily'] };
//       const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
//       const blob = new Blob([excelBuffer], { type: fileType });
//       FileSaver.saveAs(blob, fileName, fileExtension);
//     };
//     return exportXlsx(daily);
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//     }}