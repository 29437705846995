import axios from 'axios';
import { baseUrl } from 'lib/url';
import sha256 from 'crypto-js/sha256';
import { setCookie, getCookie, deleteCookie } from 'lib/cookie';
import firebase from 'firebase/app';

/**
 * 어드민 계정 로그인
 * @param {string} id 입력된 아이디
 * @param {string} pwd 입력된 비밀번호
 */
export async function login(id, pwd) {
  try {
    return await axios.post(
      `${baseUrl}/login`, {
        id: id,
        pwd: sha256(pwd).toString()
      }).then((res) => {
        setCookie('session', res.data.document_id, { 'max-age': 60 * 60 });
        setCookie('id', res.data.id);
        setCookie('nm', res.data.nm);
        document.location.href = '/main';

         // signinanonymously firebase 이미지 보안
        firebase.auth().signInAnonymously()
        .then((res) => {
        console.log("Signed in..");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log('error', errorMessage)
        }); 
      return true;
      }).catch((err) => {
        console.log(err)  // alert 3가지 필요
        const res = err.response.data;
        if(res.status == 401 && res.errorCode == 'INVALID_PWD') alert('비밀번호가 일치하지 않습니다');
        if(res.status == 401 && res.errorCode == 'ACCESS_DENIED') alert('권한이 없습니다\n관리자에게 문의바랍니다');
        if(res.status == 403) alert('이미 로그인된 계정입니다');
        if(res.status == 404) alert('아이디가 존재하지 않습니다');
        deleteCookie('session');
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 어드민 계정 로그아웃
 */
export async function logout() {
  try {
    const session = getCookie('session');
    if(!session) {
      return { result: 'fail', message: 'Please check the values.' };
    }
    const result = await axios.post(
      `${baseUrl}/logout`, {
        document_id: session
      });

    deleteCookie('session');
    document.location.href = '/login';
    return { result: 'success', message: 'The session is deleted.' };
  } catch (err) {
    deleteCookie('session');
    document.location.href = '/login';
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 쿠키의 만료시간 저장
 */
export async function updateLoginInfo() {
  try {
    const result = await axios.post(
      `${baseUrl}/updateLoginInfo`, {
        document_id: getCookie('session')
      });
    return { result: 'success', message: 'The session is updated.' };
  } catch (err) {
    deleteCookie('session');
    document.location.href = '/login';
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 주어진 권한 중 특정 권한이 존재하는지 판별
 * @param {number} permission 조회하려는 권한
 */
export async function checkAuth(permission) {
  const document_id = getCookie('session');

  try {
   return await axios.get(
      `${baseUrl}/login/checkAuth/${document_id}/${permission}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err)
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 현재 로그인되어있는 계정에 연결되어있는 타 어드민 계정(삼성생명, DB손보) 정보 조회
 */
export async function getOtherAdminList() {
  const document_id = getCookie('session');

  try {
    return await axios.get(
      `${baseUrl}/login/getOtherList/${document_id}`).then((res) => {
        return res.data;
      }).catch((err) => {
        console.error(err)
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';
// import sha256 from 'crypto-js/sha256';
// import { setCookie, getCookie, deleteCookie } from 'lib/cookie';
// import { ConsoleSqlOutlined } from '@ant-design/icons';

// /**
//  * 어드민 계정 로그인
//  * @param {string} id 입력된 아이디
//  * @param {string} pwd 입력된 비밀번호
//  */
// export async function login(id, pwd) {
//   try {
//     const admRef = await firestore.collection('adm_MEMBERS')
//       .where('id', '==', id).get();
//     if (admRef.empty) {
//       alert('아이디가 존재하지 않습니다');
//       return false;
//     }
//     const now = Math.floor(new Date().getTime());
//     // 입력된 비밀번호의 암호화된 값과 document에 저장되어있는 비밀번호 값이 같은 경우
//     if (admRef.docs[0].data().pwd === sha256(pwd).toString()) {
//       // 해당 계정이 블락처리 된 경우
//       if (admRef.docs[0].data().block_yn) {
//         alert('권한이 없습니다\n관리자에게 문의바랍니다');
//         deleteCookie('session');
//         return false;
//       }
//       const last_logged_dt = now;
//       // 마지막 로그인 시점 갱신 및 로그인 이력 생성
//       await firestore.collection('adm_MEMBERS').doc(admRef.docs[0].data().document_id)
//         .set({ last_logged_dt }, { merge: true });
//       await firestore.collection('adm_LOGINLOGS').add({
//         member_doc_id: admRef.docs[0].id,
//         id,
//         nm: admRef.docs[0].data().nm,
//         reg_dt: now,
//         login_yn: true,
//       });
//       setCookie('session', admRef.docs[0].id,
//         { 'max-age': 60 * 60 });
//       document.location.href = '/main';
//       return true;
//     // 입력된 비밀번호의 암호화된 값과 document에 저장되어있는 비밀번호 값이 다른 경우
//     } else {
//       await firestore.collection('adm_LOGINLOGS').add({
//         member_doc_id: admRef.docs[0].id,
//         id,
//         nm: admRef.docs[0].data().nm,
//         reg_dt: now,
//         login_yn: false,
//       });
//       alert('비밀번호가 일치하지 않습니다');
//       deleteCookie('session');
//       return false;
//     }
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 어드민 계정 로그아웃
//  */
// export function logout() {
//   deleteCookie('session');
//   document.location.href = '/login';
// }

// /**
//  * 현재 로그인되어있는 계정이 갖고있는 권한 조회
//  */
// export async function getPermissionList() {
//   try {
//     const id = getCookie('session');
//     const admRef = await firestore.collection('adm_MEMBERS')
//       .doc(id).get();
//     const permissionObj = Object.entries(admRef.data().permissions);
//     const permissionList = permissionObj.reduce((a, x) => {
//       if (x[1].length > 0) {
//         if (!a[x[0]]) { a[x[0]] = []; }
//         a[x[0]].push(x[1]);
//       }
//       return a;
//     }, []);
//     return permissionList;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 주어진 권한 중 특정 권한이 존재하는지 판별
//  * @param {number} permission 조회하려는 권한
//  */
// export async function checkAuth(permission) {
//   try {
//     let pList;
//     await getPermissionList()
//       .then((v) => { pList = v; })
//       .catch((err) => { console.log(err); });
//     let hasAuth = false;

//     switch (Math.floor(permission / 100)) {
//     case 1:
//       if (pList.admins !== undefined && pList.admins.length > 0) {
//         pList.admins[0].forEach((v) => {
//           if (v === permission) {
//             hasAuth = true;
//           }
//         });
//       }
//       break;
//     case 2:
//       if (pList.members !== undefined && pList.members.length > 0) {
//         pList.members[0].forEach((v) => {
//           if (v === permission) {
//             hasAuth = true;
//           }
//         });
//       }
//       break;
//     case 3:
//       if (pList.services !== undefined && pList.services.length > 0) {
//         pList.services[0].forEach((v) => {
//           if (v === permission) {
//             hasAuth = true;
//           }
//         });
//       }
//       break;
//     case 4:
//       if (pList.contents !== undefined && pList.contents.length > 0) {
//         pList.contents[0].forEach((v) => {
//           if (v === permission) {
//             hasAuth = true;
//           }
//         });
//       }
//       break;
//     case 5:
//       if (pList.outers !== undefined && pList.outers.length > 0) {
//         pList.outers[0].forEach((v) => {
//           if (v === permission) {
//             hasAuth = true;
//           }
//         });
//       }
//       break;
//     case 6:
//       if (pList.healthLogs !== undefined && pList.healthLogs.length > 0) {
//         pList.healthLogs[0].forEach((v) => {
//           if (v === permission) {
//             hasAuth = true;
//           }
//         });
//       }
//       break;
//     case 8:
//         if (pList.systems !== undefined && pList.systems.length > 0) {
//           pList.systems[0].forEach((v) => {
//             if (v === permission) {
//               hasAuth = true;
//             }
//           });
//         }
//         break;
//     default:
//       break;
//     }

//     return hasAuth;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 현재 로그인되어있는 계정에 연결되어있는 타 어드민 계정(삼성생명, DB손보) 정보 조회
//  */
// export async function getOtherAdminList() {
//   try {
//     const id = getCookie('session');
//     const admRef = await firestore.collection('adm_MEMBERS')
//       .doc(id).get();
//     return admRef.data().other_admin_sites;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }