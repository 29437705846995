import React, { useState } from 'react';
import { Descriptions, Divider, Input, Radio, Select, Space, Drawer } from 'antd';
import { company_ref, product_id_ref } from 'lib/dataRef';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { insertAppVer } from 'controllers/Systems/appVersion';
import getOptionsByRefId from 'lib/getOptionsByRefId';

const { Item } = Descriptions;
const AppVersionAddTable = () => {
    const [appVer, setAppVer] = useState({
        company_id: '',
        product_id: '',
        av_tp: '',
        av_fnl: '',
        av_enfr_updt: '',
        av_dstrb_yn: '',
        text: '',
    });
    const [drawerStatus, setDrawerStatus] = useState(false);
    const handleDrawerShow = () => {
        setDrawerStatus({
        visible: true,
        });
    };
    const handleDrawerClose = () => {
        setDrawerStatus({
        visible: false,
        });
        setAppVer(appVer);
    };
    const options = () => (getOptionsByRefId(company_ref));
    const prooductIdOptions = () => (getOptionsByRefId(product_id_ref));
   
    return (
        <>
       <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
            <Descriptions
            title="버전 추가"
            bordered
            column={1}
            style={{ width: '100%' }}
            >
            <Item label="보험사">
                <Select
                style={{ width: '200px' }}
                onChange={(e) => setAppVer({ ...appVer, company_id: e })}
                >
                    {options()}
                </Select>
            </Item>
            <Item label="상품코드">
                <Select
                style={{ width: '200px' }}
                onChange={(e) => setAppVer({ ...appVer, product_id: e })}
                >
                    {prooductIdOptions()}
                </Select>
            </Item>
            <Item label="os 구분">
                <Select
                style={{ width: '200px' }}
                onChange={(e) => setAppVer({ ...appVer, av_tp: e })}
                options={[
                    {
                        value: 'AOS',
                        label: 'Android'
                    },
                    {
                        value: 'IOS',
                        label: 'IOS'
                    },
                ]}
                />
            </Item>
            <Item label="최신버전">
                <Input
                value={appVer.av_fnl}
                onChange={(e) => setAppVer({ ...appVer, av_fnl: e.target.value })}
                />
            </Item>
            <Item label="업데이트 기준버전">
                <Input
                value={appVer.av_enfr_updt}
                onChange={(e) => setAppVer({ ...appVer, av_enfr_updt: e.target.value })}
                />
            </Item>
            <Item label="점검여부">
                <Radio.Group
                    onChange={(e) => setAppVer({ ...appVer, av_dstrb_yn: e.target.value, })}
                    defaultValue={false}
                    value={appVer.av_dstrb_yn}
                    >
                    <Radio value>Y</Radio>
                    <Radio value={false}>N</Radio>
                </Radio.Group>
            </Item>
            <Item label="사유">
                <Input.TextArea
                value={appVer.text}
                rows={8}
                allowClear
                onChange={(e) => setAppVer({ ...appVer, text: e.target.value })}
                />
            </Item>
            </Descriptions>
        <Divider />
            <Space>
            <CancelButton
                location="/app-version"
            />
            <SaveButton
                func={insertAppVer}
                data={appVer}
                location="/app-version"
            />
            </Space>
            </Drawer>
        </>
    );
};

export default AppVersionAddTable;
