import React, { useRef, useEffect, useState } from 'react';
import '../../chatbot/Chat.css';
import { getCookie } from 'lib/cookie';
import { encrypt } from 'controllers/common';

const ChatQnATableContainer = () => {
  const [iframe, setIframe] = useState(null);
  const [viewMode, setViewMode] = useState('initView');
  const id = encrypt(getCookie('id'));
  const nm = encrypt(getCookie('nm'));
  return (
    <>
      <Newframe viewMode={viewMode} setIframe={setIframe} />
    </>
  );
};

const Newframe = (props) => {
  const iframe = useRef();
  const id = encrypt(getCookie('id'));
  const nm = encrypt(getCookie('nm'));
  useEffect(() => {
    props.setIframe(iframe.current);
  }, []);

  function load() {
    setTimeout(
      () =>
        parent.document.getElementById('chat').contentWindow.window.postMessage(
          { id: id, name: nm },
          // 'https://aai-chatbot-management-admin-dev.vercel.app/dashboard?menu=qna' // 개발
          src='https://aai-chatbot-management-admin.vercel.app/dashboard?menu=qna' // 운영
        ),
      3000
    );
  }

  return (
    <div id="area" className="content">
      <iframe
        ref={iframe}
        id="chat"
        // src="https://aai-chatbot-management-admin-dev.vercel.app/dashboard?menu=qna" // 개발
        src='https://aai-chatbot-management-admin.vercel.app/dashboard?menu=qna' // 운영
        allow="clipboard-read; clipboard-write"
        onLoad={() => {
          load();
        }}
      />
    </div>
  );
};
export default ChatQnATableContainer;
