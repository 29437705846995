import React, { useState } from 'react';
import { ArrowDownOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Card, Descriptions, Input, Radio, Row, Space, Table, Typography } from 'antd';
import questionIcon from 'assets/icons/question_icon.png';
import { CancelButton, SaveButton } from 'components/Common';
import { addTrainerOpinion } from 'controllers/Outers/healthyBody';
import { gender_ref } from 'lib/dataRef';
import { getFilteredPain_ref, getFilteredSelfTest_ref, getSplitBbiddakiResult_ref } from 'lib/filteredRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { routes_ref } from 'lib/routeRef';
import noImage from '../../../../../assets/none_image.png'

const { Item } = Descriptions;
const { Text, Title } = Typography;
const HealthyBodyMiddleReportTable = (props) => {
  const { dataState, setDataState } = props;

  const [trainerOpinion, setTrainerOpinion] = useState({
    type: 'MIDDLE',
    document_id: dataState.document_id,
    program_seq: dataState.program_seq,
    member_id: dataState.member_id,
    trainer_nm: '',
    trainer_opinion: '',
  });


  const reportTitleStyle = {
    textAlign: 'center',
    width: '100%',
  };

  const titleStyle = {
    textAlign: 'center',
    width: '100%',
  };

  const columns = [
    {
      title: '주차',
      dataIndex: 'week',
      align: 'center',
      verticalAlign: 'middle',
    },
    {
      title: '단위 프로그램',
      dataIndex: 'program',
      align: 'center',
      verticalAlign: 'middle',
    },
    {
      title: '운동 요일',
      dataIndex: 'exercise_day',
      align: 'center',
      verticalAlign: 'middle',
    },
    {
      title: '참여 횟수',
      dataIndex: 'exercise_cnt',
      align: 'center',
      verticalAlign: 'middle',
    },
  ];

  const chapter5Columns = [
    {
      title: '자세 정렬',
      dataIndex: 'position_align',
      align: 'center',
      verticalAlign: 'middle',
    },
    {
      title: '시작 주',
      dataIndex: 'first_week',
      align: 'center',
      verticalAlign: 'middle',
    },
    {
      title: '4주 후',
      dataIndex: 'four_week',
      align: 'center',
      verticalAlign: 'middle',
    },
  ];

  const chapter5Data = [];
  for (let i = 0; i < 3; i++) {
    chapter5Data.push({
      key: i,
      position_align: i,
      first_week: i,
      four_week: i,
    });
  }

  const handleErrorImage = (e) => {
    e.target.src = noImage;
    e.target.style = 'width: 50px;'
  };

  const drawChater2 = () => {
    if (dataState.before_medical_result) {
      return dataState.before_medical_result.medical_report.map((row, index) => (
        <React.Fragment key={index}>
          <Item label={row.nm} style={{ width: '100px', textAlign: 'center', verticalAlign: 'middle' }}>
            <img src={getFilteredPain_ref(row)} />
          </Item>
          <br />
          <Item style={{ textAlign: 'center', verticalAlign: 'middle' }}> 
            {row.description}
          </Item>
        </React.Fragment>
      ));
    }
  };

  const drawSelfCheckResult = () => {
    let value = true;
    if(dataState.before_medical_result) {
      if(dataState.before_medical_result.medical_interview) {
        let data = dataState.before_medical_result.medical_interview;
        let beforeSymptom;
        let flag = true;
        return data.map((row, index) => {
          if(!beforeSymptom || beforeSymptom !== row.symptom) {
            flag = true;
            beforeSymptom = row.symptom;
          } else {
           flag = false;
          }
          return <React.Fragment key={index}>
            {flag
              ?
              <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                {row.symptom}
              </div>
              :
              null
            }
            <span style={{ width: '10px', height: '10px' }} >
              <img src={questionIcon} />
            </span>
            <span style={{ marginLeft: '5px' }}>
              {row.question}
            </span>
            <br/>
            <div style={{ marginLeft: '35px' }}>
              {row.value
                ?
                <Radio.Group value={value}>
                  <Space direction="horizontal">
                    <Radio value={true}>예</Radio>
                    <Radio value={false}>아니오</Radio>
                  </Space>
                </Radio.Group>
                :
                <Radio.Group value={value}>
                  <Space direction="horizontal">
                    <Radio value={false}>예</Radio>
                    <Radio value={true}>아니오</Radio>
                  </Space>
                </Radio.Group>
              }
            </div>
            <br/>
            <br/>
          </React.Fragment>
        });
      } else {
        return '-';
      }
    } else {
      return '-';
    }
  }

  const drawSelfCheckResult2 = () => {
    if(dataState.medical_result.self_test) {
      return dataState.medical_result.self_test.map((row, index) => {
        return <React.Fragment key={index}>
          <div style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {row.question}
          </div>
          <div style={{ marginLeft: '15px', marginTop: '2px' }}>
            {getFilteredSelfTest_ref(row.value, index)}
          </div>
          <br />
        </React.Fragment>
      });
    } else {
      return '-';
    }
  };

  return (
    <>
      <Descriptions
        title="중간 상태 보고서"
        bordered
        style={reportTitleStyle}
      >
        <Item label="이름">
          {dataState.nm || '-'}
        </Item>
        <Item label="생년월일">
          {dataState.birth_dt || '-'}
        </Item>
        <Item label="성별">
          {gender_ref[dataState.gender] || '-'}
        </Item>
        <Item label="고객작성일" >
          {getFormattedDate(dataState.reg_dt, 'opt2') || '-'}
        </Item>
        <Item label="프로그램명">
          {dataState.program_type_nm || '-'}
        </Item>
        <Item label="운동시작일">
          {getFormattedDate(dataState.start_dt, 'opt2') || '-'}
        </Item>
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="1. 불편감 정도"
        bordered
        layout="vertical"
        style={titleStyle}
      >
        <Item label="통증">
          {dataState.pain}
        </Item>
        <Item label="동작범위">
          {dataState.range}
        </Item>
        <Item label="불편감">
          {dataState.uncomfortable}
        </Item>
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="2. 증상에 따른 가능 질환 위험성"
        bordered
        style={titleStyle}
      >
        {drawChater2()}
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="3. 생활 문진 및 자가체크 결과"
        style={titleStyle}
      >
      </Descriptions>
      <Card>
        {drawSelfCheckResult()}
        {drawSelfCheckResult2()}
      </Card>
      <br />
      <br />
      <Descriptions
        title="4. 프로그램 진행 전후 신체 상태 비교"
        layout="horizontal"
        style={titleStyle}
      >
        <div>
          <Row
            style={{ width: '264px', height: '55px', textAlign: 'center', verticalAlign: 'middle', display: 'block' }}
          >
            <Title level={3}>
              시작 주
            </Title>
          </Row>
          <Descriptions
            layout="vertical"
            bordered
          >
            <Item label="통증">
              {dataState.before_pain}
            </Item>
            <Item label="동작범위">
              {dataState.before_range}
            </Item>
            <Item label="불안감">
              {dataState.before_uncomfortable}
            </Item>
          </Descriptions>
        </div>
        <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <Descriptions
            layout="vertical"
          >
            <Item>
              <ArrowRightOutlined style={{ fontSize: '50px', color: '#1890ff' }} />
            </Item>
          </Descriptions>
        </div>
        <div>
          <Row
            style={{ width: '264px', height: '55px', textAlign: 'center', verticalAlign: 'middle', display: 'block' }}
          >
            <Title level={3}>
              4주 후
            </Title>
          </Row>
          <Descriptions
            layout="vertical"
            bordered
          >
            <Item label="통증">
              {dataState.pain}
            </Item>
            <Item label="동작범위">
              {dataState.range}
            </Item>
            <Item label="불안감">
              {dataState.uncomfortable}
            </Item>
          </Descriptions>
        </div>
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="5. 프로그램 진행 현황"
        style={titleStyle}
      >
        <div>
          <Table
            style={{ width: '937px', height: '100%' }}
            columns={columns}
            dataSource={dataState.week_period}
            bordered
            size="middle"
            pagination={false}
          />
        </div>
      </Descriptions>
      <br />
      <br />
      <Descriptions
        title="6. 인공지능 자세진단 비교"
        layout="vertical"
        bordered
        style={titleStyle}
      >
        <Item label="정면사진" style={{ alignItems: 'center' }}>
          <img src={dataState.before_image_url_front} alt={dataState.before_image_url_front} style={{ width: '100%', height: '335px' }} 
          onError={handleErrorImage}
          onContextMenu={(e) => e.preventDefault()} />
        </Item>
        <Item label="측면사진" style={{ alignItems: 'center' }}>
          <img src={dataState.before_image_url_side} alt={dataState.before_image_url_side} style={{ width: '100%', height: '335px' }} 
          onError={handleErrorImage}
          onContextMenu={(e) => e.preventDefault()} />
        </Item>
      </Descriptions>
      <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
        {'(진단날짜: ' + getFormattedDate(dataState.reg_dt, 'opt1') + ')' || '-'}
      </div>
      <br />
      <Descriptions
        layout="vertical"
        style={titleStyle}
      >
        <div>
          <ArrowDownOutlined style={{ fontSize: '50px', color: '#1890ff' }} />
        </div>
      </Descriptions>
      <br />
      <Descriptions
        layout="vertical"
        bordered
        style={titleStyle}
      >
        <Item label="정면사진" style={{ alignItems: 'center' }}>
          <img src={dataState.image_url_front} alt={dataState.image_url_front} style={{ width: '100%', height: '335px' }} 
          onError={handleErrorImage}
          onContextMenu={(e) => e.preventDefault()} />
        </Item>
        <Item label="측면사진" style={{ alignItems: 'center' }}>
          <img src={dataState.image_url_side} alt={dataState.image_url_side} style={{ width: '100%', height: '335px' }} 
          onError={handleErrorImage}
          onContextMenu={(e) => e.preventDefault()} />
        </Item>
      </Descriptions>
      <div style={{ fontWeight: 'bold', textAlign: 'center', marginTop: '5px' }}>
        {'(진단날짜: ' + getFormattedDate(dataState.end_dt, 'opt1') + ')' || '-'}
      </div>
      <br />
      <br />
      <Card style={{ backgroundColor: '#EBF5FF' }}>
        <div>
          <Descriptions layout="horizontal">
            <Item style={{ width: '275px', height: '55px', paddingLeft: 15, marginBottom: 15, textAlign: 'left', verticalAlign: 'middle', display: 'inline-block' }}>
              <Title level={3}>
                머리위치
                <br/>
                {dataState.bbiddaki.scores[1].score}점
              </Title>
            </Item>
            <Item style={{ verticalAlign: 'middle', display: 'inline-block' }}>
              <progress value={dataState.bbiddaki.scores[1].score} max={100} style={{ width: '350px', height: '30px' }}></progress>
            </Item>
          </Descriptions>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[0].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[0].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[0].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[1].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[1].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[1].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[2].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[2].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[2].value)}&#176;{')'}
            </div>
          </Card>
        </div>
        <br/>
        <div>
          <Descriptions layout="horizontal">
            <Item style={{ width: '275px', height: '55px', paddingLeft: 15, marginBottom: 15, textAlign: 'left', verticalAlign: 'middle', display: 'inline-block' }}>
              <Title level={3}>
                상체정렬
                <br/>
                {dataState.bbiddaki.scores[2].score}점
              </Title>
            </Item>
            <Item style={{ verticalAlign: 'middle', display: 'inline-block' }}>
              <progress value={dataState.bbiddaki.scores[2].score} max={100} style={{ width: '350px', height: '30px' }}></progress>
            </Item>
          </Descriptions>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[3].title}{' ]'} 
              </span>
              &nbsp;{getSplitBbiddakiResult_ref(dataState.bbiddaki.reports[3].result)}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[3].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[5].title}{' ]'} 
              </span>
              &nbsp;{getSplitBbiddakiResult_ref(dataState.bbiddaki.reports[5].result)}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[5].value)}&#176;{')'}
            </div>
          </Card>
        </div>
        <br/>
        <div>
          <Descriptions layout="horizontal">
            <Item style={{ width: '275px', height: '55px', paddingLeft: 15, marginBottom: 15, textAlign: 'left', verticalAlign: 'middle', display: 'inline-block' }}>
              <Title level={3}>
                하체정렬
                <br/>
                {dataState.bbiddaki.scores[3].score}점
              </Title>
            </Item>
            <Item style={{ verticalAlign: 'middle', display: 'inline-block' }}>
              <progress value={dataState.bbiddaki.scores[3].score} max={100} style={{ width: '350px', height: '30px' }}></progress>
            </Item>
          </Descriptions>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[4].title}{' ]'} 
              </span>
              &nbsp;{getSplitBbiddakiResult_ref(dataState.bbiddaki.reports[4].result)}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[4].value)}&#176;{')'}
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>
                {'[ '}{dataState.bbiddaki.reports[6].title}{' ]'} 
              </span>
              &nbsp;{dataState.bbiddaki.reports[6].result}
              <br/>
              {'(최적: 0'}&#176; {'현재상태: ' + String(dataState.bbiddaki.reports[6].value)}&#176;{')'}
            </div>
          </Card>
          <br/>
          <Card style={{ borderRadius: '5px', color: '#FFFFFF', backgroundColor: '#007AFF', verticalAlign: 'middle' }}>
            <div style={{ textAlign: 'center', float: 'left', marginLeft: '150px', marginTop: '5px', verticalAlign: 'middle', fontSize: 16 }}>
              인공지능 자세진단
              <br/>
              평가 종합점수
            </div>
            <div style={{ textAlign: 'center', float: 'center', marginTop: '5px', fontWeight: 'bold' }}>
              <span style={{ fontSize: 25 }}>
                {dataState.before_bbiddaki.scores[0].score.toFixed(2) + ' > '}
              </span>
              <span style={{ fontSize: 37 }}>
                {dataState.bbiddaki.scores[0].score.toFixed(2) || '-'}점
              </span>
            </div>
          </Card>
          <br/>
          <Card style={{ borderRadius: '5px' }}>
            <div style={{ textAlign: 'left', display: 'inline-block' }}>
              {dataState.before_medical_result.medical_report.map((row, i) => {
                return <React.Fragment key={i}>
                  {'[ ' + row.nm + ' ]'}
                  <br />
                  {row.comment.split('.').map((line, index) => {
                    let val = row.comment;
                    let valArr = val.split('.');
                    let valLen = valArr.length;
                    if(valLen === index+1) {
                      if(valArr[index].trim() !== '') {
                        return <React.Fragment key={index}>{line}.</React.Fragment>
                      }
                    } else {
                      if(valLen === index+2) {
                        if(valArr[index+1].trim() === '') {
                          return <React.Fragment key={index}>{line}.</React.Fragment>
                        } else {
                          return <React.Fragment key={index}>{line}.<br /></React.Fragment>
                        }
                      } else {
                        return <React.Fragment key={index}>{line}.<br /></React.Fragment>
                      }
                    }
                  })}
                  <br />
                  <br />
                </React.Fragment>
              })}
            </div>
          </Card>
        </div>
      </Card>
      <br />
      <br />
      <Descriptions
        layout="vertical"
        style={titleStyle}
      >
        <div>
          <ArrowDownOutlined style={{ fontSize: '50px', color: '#1890ff' }} />
        </div>
      </Descriptions>
      <br />
      <div>
        {dataState.chapter5Data && dataState.chapter5Data.length > 0 && (
          <Table
            columns={chapter5Columns}
            dataSource={dataState.chapter5Data}
            bordered
            size="middle"
            pagination={false}
          />
        )}
      </div>
      <br />
      <br />
      <Descriptions
        title="7. 운동처방사 평가"
        bordered
        layout="horizontal"
        style={titleStyle}
      >
        <Item label="평가자:" style={{ textAlign: 'center', width: '250px' }}>
          {dataState.trainer_opinion !== "" && dataState.trainer_opinion !== undefined
            ?
              <>
                {dataState.trainer_nm}
              </>
            :
              <Input
                value={trainerOpinion.trainer_nm}
                onChange={(e) => setTrainerOpinion({ ...trainerOpinion, trainer_nm: e.target.value })}
              />
          }
        </Item>
      </Descriptions>
      <div>
        {dataState.trainer_opinion !== "" && dataState.trainer_opinion !== undefined
        ?
          <Card>
            {dataState.trainer_opinion}
          </Card>
        :
          <Input.TextArea
            value={trainerOpinion.trainer_opinion}
            rows={10}
            allowClear
            onChange={(e) => setTrainerOpinion({ ...trainerOpinion, trainer_opinion: e.currentTarget.value })}
          />
        }
      </div>
      <br />
      <br />
      {dataState.trainer_opinion !== "" && dataState.trainer_opinion !== undefined
        ?
          null
        :
          <Row>
            <Space>
              <CancelButton
                location={routes_ref.base.outers.healthy_body.path}
              />
              <SaveButton
                data={trainerOpinion}
                func={addTrainerOpinion}
                location={routes_ref.base.outers.healthy_body.path}
              />
            </Space>
          </Row>
      }
      <br />
      <br />
    </>
  );
};

export default HealthyBodyMiddleReportTable;
