import React, { useEffect, useState } from 'react';
import { BenefitDetailTable } from 'components/Common';
import { getBenefitDetail } from 'controllers/Outers/benefits';
import { Spin } from 'antd';

const BenefitDetailTableContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [benefitDetail, setBenefitDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setBenefitDetail({});
      setLoading(true);
      const data = getBenefitDetail(document_id);
      data.then((d) => {
        setBenefitDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <BenefitDetailTable benefitDetail={benefitDetail} setBenefitDetail={setBenefitDetail}/>
      </Spin>
    :
      <BenefitDetailTable benefitDetail={benefitDetail} setBenefitDetail={setBenefitDetail} />
  );
};

export default BenefitDetailTableContainer;
