import React from 'react';
import PropTypes from 'prop-types';

const CounterLabel = ({ label, count, isPerson }) => {
  const style = {
    display: 'flex',
    justifyContent: 'row',
    height: '30px',
    lineHeight: '30px',
    fontSize: '11.04px',
  };
  const labelStyle = {
    borderRadius: '5px 0 0 5px',
    padding: '0 5px',
    background: '#9eacc6',
    color: '#ffffff',
  };
  const counterStyle = {
    borderRadius: '0 5px 5px 0',
    padding: '0 5px',
    background: '#ededed',
    color: '#666666',
  };
  return (
    <div style={style}>
      <div style={labelStyle}>
        {isPerson ? '총 ' : '전체'}
        {label}
      </div>
      <div style={counterStyle}>
        {count}
        {isPerson ? '명' : '건'}
      </div>
    </div>
  );
};

CounterLabel.propTypes = {
  /** 라벨에 보여질 이름. ex)회원, 비회원, 전체 등 */
  label: PropTypes.string,
  /** DB 내 count 결과값 */
  count: PropTypes.number,
  /** count 결과값의 suffix를 결정, 사람의 경우 "명", 아닐경우 "건"  */
  isPerson: PropTypes.bool,
};

CounterLabel.defaultProps = {
  label: '',
  count: 0,
  isPerson: false,
};

export default CounterLabel;
