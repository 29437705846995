import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const DropDownButton = (props) => {
    const visible = props.visible;
      
    return (
        <Button className={`slide-in-and-out ${visible ? 'btn-slide-out-dropdown' : 'btn-slide-in-dropdown'}`}
        icon={visible 
            ? <LeftOutlined 
            /> 
                : <RightOutlined />}
        onClick={e => props.setVisible(!visible)}
        style={{ 
            marginLeft: visible ? '240px' : '0px', 
            borderRadius: '0px 11px 11px 0px',
        }}
         />
      );
}

export default DropDownButton;