import React, { useEffect, useState } from 'react';
import { getMainAppliesList, getMainAppliesSize, getMainLoginLogsList, getMainMembersList } from 'controllers/main';
import { applyType_ref, category_ref, company_ref, login_yn_ref, status_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { Badge, Card, Col, Divider, Row, Spin, Statistic, Table } from 'antd';

const MainContainer = () => {
  const [mainDataList, setMainDataList] = useState({
    mainMembersList: [],
    mainAppliesList: [],
    mainLoginLogsList: [],
    status_0: 0,
    status_1: 0,
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getDataList = {};
    const fetchDataList = async () => {
      getDataList.mainMembersList = await getMainMembersList() || [];
      getDataList.mainAppliesList = await getMainAppliesList() || [];
      getDataList.mainLoginLogsList = await getMainLoginLogsList() || [];
      getDataList.status_0 = await getMainAppliesSize(0) || 0;
      getDataList.status_1 = await getMainAppliesSize(1) || 0;
      await setMainDataList(getDataList);
      setLoading(false);
    };
    fetchDataList();
  }, []);
  const memberColumns = [
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data = '') => data.replace(data.slice(1, data.length), '**'),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (data = '') => data.replace(data.slice(3, 7), '****'),
    },
    {
      title: '생년월일',
      dataIndex: 'birth_dt',
      key: 'birth_dt',
      align: 'center',
      render: (data = '') => data.replace(data.slice(0, 6), '******'),
    },
    {
      title: '회원구분',
      dataIndex: 'admin_msort',
      key: 'admin_msort',
      align: 'center',
      render: (data) => applyType_ref[data],
    },
    {
      title: '가입일',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '최종접속일',
      dataIndex: 'last_logged_dt',
      key: 'last_logged_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];
  const applyColumns = [
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data = '') => data.replace(data.slice(1, data.length), '**'),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data = '') => data.replace(data.slice(3, 7), '****'),
    },
    {
      title: '중분류',
      dataIndex: 'category2',
      key: 'category2',
      align: 'center',
      render: (data) => category_ref().ref2[data],
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data) => {
        let badge;
        switch (data) {
        case 0:
          badge = <Badge status="error" text={status_ref[data]} />;
          break;
        case 1:
          badge = <Badge status="processing" text={status_ref[data]} />;
          break;
        case 2:
          badge = <Badge status="success" text={status_ref[data]} />;
          break;
        case 9:
          badge = <Badge status="default" text={status_ref[data]} />;
          break;
        default:
          break;
        }
        return badge;
      },
    },
    {
      title: '관리자 확인 시각',
      dataIndex: 'admin_check_dt',
      key: 'admin_check_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '신청일자',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];
  const loginLogColumns = [
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data = '') => {
        if (data.length > 1) {
          return data.replace(data.slice(1, data.length), '**');
        } else {
          return '구버전 사용자';
        }
      },
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data = '') => {
        if (data.length > 1) {
          return data.replace(data.slice(3, 7), '****');
        } else {
          return '비회원';
        }
      },
    },
    {
      title: '로그인성공여부',
      dataIndex: 'login_yn',
      key: 'login_yn',
      align: 'center',
      render: (bool) => (
        bool
          ? (
            <>
              <Badge
                status="success"
                text={login_yn_ref[bool]}
              />
            </>
          )
          : (
            <>
              <Badge
                status="error"
                text={login_yn_ref[bool]}
              />
            </>
          )),

    },
    {
      title: '로그인 시각',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];
  return (
    <>
    {
      loading ?
        <Spin
          tip="Loading"
          size="large"
        >
          <Table/>
        </Spin>
      :
        <> 
          <div style={{
            background: '#ececec',
            padding: '18px',
          }}
          >
            <Row gutter={16}>
              <Col flex="auto">
                <Card
                  bodyStyle={{
                    padding: '21px',
                    marginBottom: '4px',
                    color: 'rgba(0, 0, 0, 0.45)',
                    fontSize: '14px',
                  }}
                >
                  최근 가입한 회원
                </Card>
              </Col>
            </Row>
          </div>
          <Table
            dataSource={mainDataList.mainMembersList}
            columns={memberColumns}
            pagination={false}
          />
          <Divider />
          <div style={{
            background: '#ececec',
            padding: '18px',
          }}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Card
                  bodyStyle={{ padding: '21px' }}
                >
                  <Statistic
                    title="현재 신청 건 수"
                    value={mainDataList.status_0 + ' 건'}
                    valueStyle={{ color: '#cf1322' }}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  bodyStyle={{ padding: '21px' }}
                >
                  <Statistic
                    title="현재 진행중 건 수"
                    value={mainDataList.status_1 + ' 건'}
                    valueStyle={{ color: '#2db7f5' }}
                  />
                </Card>
              </Col>
            </Row>
          </div>
          <Table
            dataSource={mainDataList.mainAppliesList}
            columns={applyColumns}
            pagination={false}
          />
          <Divider />
          <div style={{
            background: '#ececec',
            padding: '18px',
          }}
          >
            <Row gutter={16}>
              <Col flex="auto">
                <Card
                  bodyStyle={{
                    padding: '21px',
                    marginBottom: '4px',
                    color: 'rgba(0, 0, 0, 0.45)',
                    fontSize: '14px',
                  }}
                >
                  최근 로그인 기록
                </Card>
              </Col>
            </Row>
          </div>
          <Table
            dataSource={mainDataList.mainLoginLogsList}
            columns={loginLogColumns}
            pagination={false}
          />
        </>
      }
    </>
  );
};

export default MainContainer;
