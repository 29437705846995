import React, { useState } from 'react';
import {
  Descriptions,
  Divider,
  Input,
  Modal,
  Upload,
  Steps,
  Badge,
  Row,
  Space,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import uploadToStorage from 'lib/uploadToStorage';
import {
  company_ref,
  product_id_ref,
  status_ref,
  storage_ref,
} from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import { CancelButton, SaveButton, DelButton } from 'components/Common';
import { editBenefitDetail, deleteBenefit } from 'controllers/Outers/benefits';
import useUpload from 'lib/hooks/useUpload';

const { Item } = Descriptions;
const BenefitDetailTable = (props) => {
  const { benefitDetail, setBenefitDetail } = props;
  const [uploadChk, setUploadChk] = useState(false);
  const UPLOAD = useUpload();
  const { previewVisible, previewImage, fileList, previewTitle } =
    UPLOAD.upload;
  const { handlePreview, handleChange, handleCancel } = UPLOAD;
  const handleStatusChange = (current) => {
    setBenefitDetail({ ...benefitDetail, status: current });
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleStatusCancelChange = (current) => {
    let statusNum;
    // eslint-disable-next-line default-case
    switch (current) {
      case 9:
        statusNum = 9;
        break;
      case 10:
        statusNum = 99;
        break;
    }
    setBenefitDetail({ ...benefitDetail, status: statusNum });
  };
  console.log(benefitDetail);
  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="계약자 이름">{benefitDetail.crm_nm || '-'}</Item>
        <Item label="계약자 연락처">{benefitDetail.crm_phone || '-'}</Item>
        <Item label="보험사명">
          {company_ref[benefitDetail.company_id] || '-'}
        </Item>
        <Item label="신청 대상자">{benefitDetail.member_nm || '-'}</Item>
        <Item label="신청 대상자 연락처">{benefitDetail.member_ph || '-'}</Item>
        <Item label="신청 대상자 주소">{benefitDetail.member_addr || '-'}</Item>
        <Item label="쿠폰번호">{benefitDetail.coupon || '-'}</Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="서비스 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="앱 명칭">
          {product_id_ref[benefitDetail.product_id] || '-'}
        </Item>
        <Item label="관리자 메모">
          <Input.TextArea
            value={benefitDetail.admin_memo}
            rows={10}
            allowClear
            onChange={(e) =>
              setBenefitDetail({
                ...benefitDetail,
                admin_memo: e.currentTarget.value,
              })
            }
          />
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions bordered layout="vertical">
        <Item
          label={
            benefitDetail.img_url ? (
              <Badge status="success" text="첨부 이미지" />
            ) : (
              <Badge status="error" text="첨부 이미지" />
            )
          }
        >
          {!uploadChk && benefitDetail.img_url ? (
            <img
              style={{ height: '250px', width: 'auto' }}
              key={benefitDetail.img_url[0]}
              src={benefitDetail.img_url[0]}
              onContextMenu={(e) => e.preventDefault()}
              alt="Img Url"
            />
          ) : (
            <Upload
              disabled={benefitDetail.company_id === undefined}
              customRequest={(customOption) => {
                uploadToStorage(
                  storage_ref[benefitDetail.company_id],
                  customOption
                );
              }}
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={(info) => {
                handleChange(info);
                const url = info.fileList.map((fileInfo) => fileInfo.response);
                if(url[0] != undefined) {
                  setUploadChk(true);
                  setBenefitDetail({
                    ...benefitDetail,
                    img_url: url
                    });
                  }
              }}
            >
              {fileList.length >= 1 ? null : uploadButton}
            </Upload>
          )}
          <Modal
            open={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="신청 정보"
        bordered
        column={2}
        layout="vertical"
        style={{ width: '100%' }}
      >
        <Item label="서비스 진행 상태" span={2}>
          <Steps
            type="navigation"
            size="default"
            current={benefitDetail.status}
            labelPlacement="vertical"
            onChange={(current) => handleStatusChange(current)}
            className="site-navigation-steps"
          >
            <Steps
              title={status_ref[0]}
              description={getFormattedDate(benefitDetail.reg_dt, 'opt1')}
            />
            <Steps
              title={status_ref[1]}
              description={getFormattedDate(
                benefitDetail.admin_check_dt,
                'opt1'
              )}
            />
            <Steps title={status_ref[2]} subTitle="" description="" />
          </Steps>
          <Steps
            type="navigation"
            size="small"
            current={
              benefitDetail.status >= 9
                ? benefitDetail.status === 99
                  ? 10
                  : 9
                : undefined
            }
            labelPlacement="vertical"
            initial={9}
            onChange={(current) => handleStatusCancelChange(current)}
            className="site-navigation-steps"
          >
            <Steps
              title={status_ref[9]}
              status="error"
              subTitle=""
              description="사용자의 신청을 취소합니다"
            />
          </Steps>
        </Item>
      </Descriptions>
      <Divider />
      <Descriptions
        title="관리자 관련"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="관리자 확인 여부">
          {benefitDetail.admin_check_yn
            ? benefitDetail.status === 1
              ? '관리자 확인중'
              : '확인완료'
            : '-'}
        </Item>
        <Item label="관리자 확인 시각">
          {getFormattedDate(benefitDetail.admin_check_dt, 'opt2') || '-'}
        </Item>
        <Item label="등록시각">
          {getFormattedDate(benefitDetail.reg_dt, 'opt2') || '-'}
        </Item>
        <Item label="삭제여부">{benefitDetail.del_yn || '-'}</Item>
      </Descriptions>
      <Divider />
      <Row>
        <Space>
          <CancelButton location="/apply" />
          <SaveButton
            func={editBenefitDetail}
            data={benefitDetail}
            location="/benefits"
          />
          <DelButton
            func={deleteBenefit}
            data={benefitDetail}
            location="/benefits"
          />
        </Space>
      </Row>
    </>
  );
};

export default BenefitDetailTable;
