/* eslint-disable import/prefer-default-export */
export const routes_ref = {
  base: {
    admins: {
      default_label: { label: '관리자 관련', value: 100 },
      admin: {
        label: { label: '관리자관리', value: 101 },
        pid: 101,
        path: '/admin',
      },
    },
    members: {
      default_label: { label: '회원 관련', value: 200 },
      member: {
        label: { label: '회원관리', value: 201 },
        pid: 201,
        path: '/member',
      },
      login_log: {
        label: { label: '로그인기록', value: 202 },
        pid: 202,
        path: '/login-logs',
      },
      hana_step: {
        label: { label: '하나손보 걷기', value: 203 },
        pid: 203,
        path: '/hana-step',
      }
    },
    services: {
      default_label: { label: '서비스신청 관련', value: 300 },
      apply: {
        label: { label: '서비스신청', value: 301 },
        pid: 301,
        path: '/apply',
      },
      p_apply: {
        label: { label: '프리미엄 서비스신청', value: 302 },
        pid: 302,
        path: '/premium-apply',
      },
      mediCert: {
        label: { label: '서류 제출', value: 303 },
        pid: 303,
        path: '/mediCerts',
      },
      mental: {
        label: { label: '심리상담(흥국, db생명)', value: 304 },
        pid: 303,
        path: '/mental',
      },
    },
    contents: {
      default_label: { label: '컨텐츠 관련', value: 400 },
      notice: {
        label: { label: '공지사항', value: 403 },
        pid: 403,
        path: '/notice',
      },
      faq: {
        label: { label: 'FAQ', value: 404 },
        pid: 404,
        path: '/faq',
      },
      treatment: {
        label: { label: '치료정보', value: 401 },
        pid: 401,
        path: '/post-treatments',
      },
      event_info: {
        label: { label: '임신출산행사정보', value: 402 },
        pid: 402,
        path: '/post-eventInfos',
      },
      push: {
        label: { label: '알림푸시', value: 405 },
        pid: 405,
        path: '/push',
      },
    },
    outers: {
      default_label: { label: '외부관리자 관련', value: 500 },
      art_consulting: {
        label: { label: '미술심리상담', value: 502 },
        pid: 502,
        path: '/art-counsels',
      },
      helper_group: {
        label: { label: '간병인/가사도우미 업체 등록', value: 503 },
        pid: 503,
        path: '/helper_group',
      },
      helper: {
        label: { label: '간병인/가사도우미 등록', value: 504 },
        pid: 504,
        path: '/helper',
      },
      healthy_body: {
        label: { label: '건강한몸 프로그램 관리', value: 505 },
        pid: 505,
        path: '/healthy_body',
      },
      bbiddaki_api_log: {
        label: { label: '삐딱 요청/응답 기록', value: 506 },
        pid: 506,
        path: '/bbiddaki_api_log',
      },
      benefits: {
        label: { label: '돌봄로봇(현물급부)', value: 507 },
        pid: 507,
        path: '/benefits',
      },
    },
    healthLogs: {
      default_label: { label: '건강관리 관련', value: 600 },
      cancer_log: {
        label: { label: '치료일지', value: 601 },
        pid: 601,
        path: '/cancer-logs',
      },
      medicine: {
        label: { label: '복약관리/알람', value: 602 },
        pid: 602,
        path: '/medicines',
      },
      growth_rec: {
        label: { label: '아이성장', value: 603 },
        pid: 603,
        path: '/growth-recs',
      },
      body_age: {
        label: { label: '의학생체나이측정', value: 604 },
        pid: 604,
        path: '/body-ages',
      },
      dzPreds: {
        label: { label: '질병예측', value: 605 },
        pid: 605,
        path: '/dzPreds',
      },
      steps: {
        label: { label: '걷기', value: 606 },
        pid: 606,
        path: '/steps',
      },
      dentals: {
        label: { label: '구독서비스', value: 607 },
        pid: 607,
        path: '/dentals',
      },
    },
    systems: {
      default_label: { label: '시스템 관리', value: 800 },
      app_version: {
        label: { label: '앱버전', value: 801 },
        pid: 801,
        path: '/app-version',
      },
      app_hash: {
        label: { label: '앱 해시', value: 805 },
        pid: 805,
        path: '/app-hash',
      },
      admin_access_log: {
        label: { label: '개인정보접근 로그', value: 802 },
        pid: 802,
        path: '/admin-access-log',
      },
      admin_login_log: {
        label: { label: '관리자 접속 로그', value: 803 },
        pid: 803,
        path: '/admin-login-log',
      },
      admin_mod_log: {
        label: { label: '관리자 변경 로그', value: 804 },
        pid: 804,
        path: '/admin-mod-log',
      },
    },
    chatbot: {
      default_label: { label: '챗봇 관리', value: 900 },
      chatting: {
        label: { label: '채팅 상담', value: 901 },
        pid: 901,
        path: '/chatting',
      },
      chat_apply: {
        label: { label: '서비스 신청', value: 902 },
        pid: 902,
        path: '/chat-apply',
      },
      health_type: {
        label: { label: '건강 유형', value: 903 },
        pid: 903,
        path: '/health-type',
      },
      center: {
        label: { label: '건강검진 기관', value: 904 },
        pid: 904,
        path: '/center',
      },
      center_add: {
        label: { label: '건강검진 기관 등록', value: 905 },
        pid: 905,
        path: '/center_add',
      },
      doctor: {
        label: { label: '전문의료진', value: 906 },
        pid: 906,
        path: '/doctor',
      },
      doctor_add: {
        label: { label: '전문의료진 등록', value: 907 },
        pid: 907,
        path: '/doctor_add',
      },
      chat_qna: {
        label: { label: 'Q&A', value: 908 },
        pid: 908,
        path: '/chat_qna',
      },
      chat_term: {
        label: { label: '용어', value: 909 },
        pid: 909,
        path: '/chat_term',
      },
    },
  },
};
