import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * faq의 전체 정보 조회
 */
export async function getFaqList(setFaq, setCount, searchState, setLoading) {
  try {
    const result = await axios.post(
      `${baseUrl}/faq/getList`,{
      searchState: searchState
    }).then((res) => {
      setFaq(res.data.lists);
      setCount(res.data.count);
      setLoading(false);
    });

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 faq의 정보 조회
 * @param {string} document_id 조회하고자 하는 행사정보의 document id
 */
export async function getFaqDetail(document_id) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/faq/getDetail/${document_id}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);
    
    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 faq의 정보 수정
 * @param {object} faq 수정하고자 하는 행사정보의 정보 및 수정 내용
 */
export async function editFaqDetail(faq) {
  try {
    // 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(faq).forEach((v) => {
      if (faq[v] === undefined || faq[v].length === 0) {
        delete faq[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/faq/update`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        faq
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 새로운 faq 등록
 * @param {object} faq 등록하고자 하는 행사정보의 정보
 */
export async function postFaq(faq) {
  try {
    // 키가 contents 혹은 title이 아니면서, 값이 undefined 이거나 길이가 0인 경우, 키 삭제
    Object.keys(faq).forEach((v) => {
      if ((v !== 'content' && v !== 'subject' && v !== 'product_id')
        || (faq[v] === undefined || faq[v].length === 0)) {
        delete faq[v];
      }
    });
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/faq/add`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        faq
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 faq 삭제
 * @param {object} faq 삭제하고자 하는 faq의 정보
 */
export async function deleteFaq(faq) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/faq/delete`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        faq
      }),
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}