import React, { useEffect, useState } from 'react';
import { getLoginLogsList, makeXlsx } from 'controllers/Services/loginLogs';
import {
  LinkButton,
  CounterLabel,
  ListTableWithOutPaging,
  Pagination,
  ExcelModal,
} from 'components/Common';
import { InfoPane } from 'components/InfoPane';
import { Button } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import { routes_ref } from 'lib/routeRef';
import { company_ref, login_yn_ref } from 'lib/dataRef';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { maskName, maskPhone } from 'lib/maskPersonInfo';

const LoginLogsListTableContainer = () => {
  const [loginlogs, setLoginlogs] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first',
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({}); // 검색어
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  function goSearch() {
    getLoginLogsList(
      setLoginlogs,
      loginlogs,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
  }
  useEffect(() => {
    getLoginLogsList(
      setLoginlogs,
      loginlogs,
      'first',
      setCurrentPage,
      searchState,
      setLoading
    );
  }, []);
  const excelDownload = () => {
    insertInfoAcessLog(location.pathname, 'makeXlsx', '다운로드', excelReason);
    makeXlsx(excelReason, searchState);
  };
  const columns = [
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data],
    },
    {
      title: '상품코드',
      dataIndex: 'product_id',
      key: 'product_id',
      align: 'center',
      render: (data) => data,
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '로그인성공여부',
      dataIndex: 'login_yn',
      key: 'login_yn',
      align: 'center',
      render: (data) => login_yn_ref[data],
    },
    {
      title: '로그인 시각',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
  ];

  let cp = currentPage;
  if (cp > 10) {
    // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp % 10;
    cp = n == 0 ? 10 : n;
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  };
  const setPage = (page) => {
    setCurrentPage(page);
  };

  const renderMoveData = (move) => {
    getLoginLogsList(
      setLoginlogs,
      loginlogs,
      move,
      setCurrentPage,
      searchState,
      setLoading
    );
  };

  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton link={routes_ref.base.members.login_log.path} />
        }
        countLabel={<CounterLabel count={loginlogs.count} />}
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
        }
        resetButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        }
        searchButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setLoading(true);
              goSearch();
            }}
          >
            검색
          </Button>
        }
        searchFieldList={{
          page: 'loginLogs',
          field: ['보험사', '상품코드', '이름', '등록일'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress,
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(loginlogs.data)}
        columns={columns}
        loading={loading}
        pagination={
          <Pagination
            totalData={loginlogs.data}
            pagingState={loginlogs.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        }
      />
      <div>
        {modalIsOpen && (
          <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          ></ExcelModal>
        )}
      </div>
    </>
  );
};

export default LoginLogsListTableContainer;
