import React, { useEffect, useState } from 'react';
import { MentalDetailTable } from 'components/Common';
import { getMentalDetail } from 'controllers/Services/mental';
import { Spin } from 'antd';

const MentalDetailTableContainer = ({ document_id }) => {
  const [mentalDetail, setMentalDetail] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (document_id) {
      setMentalDetail({});
      setLoading(true);
      const data = getMentalDetail(document_id);
      data.then((d) => {
        setMentalDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <MentalDetailTable mentalDetail={mentalDetail} setMentalDetail={setMentalDetail} />
      </Spin>
    :
      <MentalDetailTable mentalDetail={mentalDetail} setMentalDetail={setMentalDetail} />
  );
};

export default MentalDetailTableContainer;
