import axios from 'axios';
import { baseUrl } from 'lib/url';

/**
 * 성장기록 전체 정보 조회
 */
export async function getGrowthRecsList(setGrowthRecs, growthRecs, goPage, setCurrentPage, searchState, setLoading) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/health/growthRecs/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        goPage: goPage, 
        pagingState: growthRecs.pagingState, 
        searchState: searchState
      })
    })
    .then((response) =>  response);

    setGrowthRecs(result.data);
    setCurrentPage(1);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 특정 성장기록의 정보 조회
 * @param {string} crm_child_id 특정 성장기록을 가진 자녀(피보험자)의 id
 * @param {string} crm_child_gender 특정 성장기록을 가진 자녀(피보험자)의 성별
 */
export async function getGrowthRecDetail(crm_child_id, crm_child_gender) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/health/growthRecs/getDetail/${crm_child_id}/${crm_child_gender}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) =>  response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

// import firestore from 'store/firestore';

// let size = 0;

// /**
//  * 성장기록 건수 조회
//  */
// export function getGrowthRecsSize() {
//   return size;
// }

// /**
//  * 성장기록 전체 정보 조회
//  */
// export async function getGrowthRecsList() {
//   try {
//     const grsRef = await firestore.collection('cust_GROWTHREC')
//       .orderBy('checkup_dt', 'desc').orderBy('reg_dt', 'desc')
//       .get();
//     let product_id = String(grsRef.docs[0].data().product_id).split('_');
//     const grDocs = [{
//       document_id: grsRef.docs[0].data().document_id,
//       app_code: product_id[0],
//       goods_code: product_id[1],
//       product_id: grsRef.docs[0].data().product_id,
//       crm_child_nm: grsRef.docs[0].data().crm_child_nm,
//       crm_child_id: grsRef.docs[0].data().crm_child_id,
//       crm_child_gender: grsRef.docs[0].data().crm_child_gender,
//       crm_child_birth_dt: grsRef.docs[0].data().crm_child_birth_dt,
//       crm_nm: grsRef.docs[0].data().crm_nm,
//       crm_phone: grsRef.docs[0].data().crm_phone,
//       checkup_dt: grsRef.docs[0].data().checkup_dt,
//       checkup_dt_str: grsRef.docs[0].data().checkup_dt_str,
//       months: grsRef.docs[0].data().months,
//       height: grsRef.docs[0].data().height,
//       weight: grsRef.docs[0].data().weight,
//       head_circum: grsRef.docs[0].data().head_circum,
//       body_mass: grsRef.docs[0].data().body_mass,
//       reg_dt: grsRef.docs[0].data().reg_dt,
//       del_yn: grsRef.docs[0].data().del_yn,
//       del_dt: grsRef.docs[0].data().del_dt,
//     }];

//     grsRef.docs.forEach((doc) => {
//       let check = true;
//       for (let i = 0; i < grDocs.length; i++) {
//         if (grDocs[i].crm_child_id === doc.data().crm_child_id) {
//           check = false;
//         }
//       }

//       if (check) {
//         product_id = String(doc.data().product_id).split('_');
//         grDocs.push({
//           app_code: product_id[0],
//           goods_code: product_id[1],
//           product_id: doc.data().product_id,
//           document_id: doc.data().document_id,
//           crm_child_nm: doc.data().crm_child_nm,
//           crm_child_id: doc.data().crm_child_id,
//           crm_child_gender: doc.data().crm_child_gender,
//           crm_child_birth_dt: doc.data().crm_child_birth_dt,
//           crm_nm: doc.data().crm_nm,
//           crm_phone: doc.data().crm_phone,
//           checkup_dt: doc.data().checkup_dt,
//           checkup_dt_str: doc.data().checkup_dt_str,
//           months: doc.data().months,
//           height: doc.data().height,
//           weight: doc.data().weight,
//           head_circum: doc.data().head_circum,
//           body_mass: doc.data().body_mass,
//           reg_dt: doc.data().reg_dt,
//           del_yn: doc.data().del_yn,
//           del_dt: doc.data().del_dt,
//         });
//       }
//     });
//     size = grDocs.length;
//     return grDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }

// /**
//  * 특정 성장기록의 정보 조회
//  * @param {string} crm_child_id 특정 성장기록을 가진 자녀(피보험자)의 id
//  * @param {string} crm_child_gender 특정 성장기록을 가진 자녀(피보험자)의 성별
//  */
// export async function getGrowthRecDetail(crm_child_id, crm_child_gender) {
//   try {
//     // 해당 성별의 모든 성장기록 기준치 조회
//     const grHeight = await firestore.collection('cnt_GROWTHREC')
//       .doc(crm_child_gender + '_height').get();
//     const grWeight = await firestore.collection('cnt_GROWTHREC')
//       .doc(crm_child_gender + '_weight').get();
//     const grHeadC = await firestore.collection('cnt_GROWTHREC')
//       .doc(crm_child_gender + '_headCircum').get();
//     const grBodyM = await firestore.collection('cnt_GROWTHREC')
//       .doc(crm_child_gender + '_bodyMass').get();
//     // 해당 자녀(피보험자)의 모든 성장기록 조회
//     const grsRef = await firestore.collection('cust_GROWTHREC')
//       .where('crm_child_id', '==', crm_child_id).orderBy('checkup_dt', 'desc')
//       .get();
//     // grHeight = Object.values(grHeight.data().values);
//     // grWeight = Object.values(grWeight.data().values);
//     // grHeadC = Object.values(grHeadC.data().values);
//     // grBodyM = Object.values(grBodyM.data().values);
//     const grDocs = [];

//     grsRef.docs.forEach((doc) => {
//       // 각 신체 부위별로 기준치 값이 존재하는 개월수 안에 해당 기록이 측정됐는지 확인
//       // (3은 최소 퍼센트)
//       let height_percentile = doc.data().height > 0 && doc.data().months <= 227
//         ? 3 : undefined;
//       let weight_percentile = doc.data().weight > 0 && doc.data().months <= 227
//         ? 3 : undefined;
//       let head_circum_percentile = doc.data().head_circum > 0 && doc.data().months <= 35
//         ? 3 : undefined;
//       let body_mass_percentile = doc.data().body_mass > 0 && doc.data().months <= 227
//         && doc.data().months >= 24 ? 3 : undefined;

//       // 기준치 값에서 해당 측정값에 가장 가까운 퍼센트를 적용
//       if (height_percentile !== undefined) {
//         Object.keys(grHeight.data().values).forEach((v) => {
//           if (grHeight.data().values[v][doc.data().months] <= doc.data().height) {
//             height_percentile = v;
//           }
//         });
//       }
//       if (weight_percentile !== undefined) {
//         Object.keys(grWeight.data().values).forEach((v) => {
//           if (grWeight.data().values[v][doc.data().months] <= doc.data().weight) {
//             weight_percentile = v;
//           }
//         });
//       }
//       if (head_circum_percentile !== undefined) {
//         Object.keys(grHeadC.data().values).forEach((v) => {
//           if (grHeadC.data().values[v][doc.data().months] <= doc.data().head_circum) {
//             head_circum_percentile = v;
//           }
//         });
//       }
//       if (body_mass_percentile !== undefined) {
//         Object.keys(grBodyM.data().values).forEach((v) => {
//           if (grBodyM.data().values[v][doc.data().months - 24] <= doc.data().body_mass) {
//             body_mass_percentile = v;
//           }
//         });
//       }

//       const product_id = String(doc.data().product_id).split('_');
//       grDocs.push({
//         app_code: product_id[0],
//         goods_code: product_id[1],
//         product_id: doc.data().product_id,
//         document_id: doc.data().document_id,
//         crm_child_nm: doc.data().crm_child_nm,
//         crm_child_gender: doc.data().crm_child_gender,
//         crm_child_birth_dt: doc.data().crm_child_birth_dt,
//         crm_nm: doc.data().crm_nm,
//         months: doc.data().months,
//         checkup_dt: doc.data().checkup_dt,
//         checkup_dt_str: doc.data().checkup_dt_str,
//         height: doc.data().height,
//         weight: doc.data().weight,
//         head_circum: doc.data().head_circum,
//         body_mass: doc.data().body_mass,
//         height_percentile,
//         weight_percentile,
//         head_circum_percentile,
//         body_mass_percentile,
//         reg_dt: doc.data().reg_dt,
//         mod_dt: doc.data().mod_dt,
//         del_dt: doc.data().del_dt,
//         del_yn: doc.data().del_yn,
//       });
//     });
//     return grDocs;
//   } catch (err) {
//     console.error(err);
//     return { result: 'error: \n', message: err };
//   }
// }