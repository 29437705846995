import React, { useState, useEffect } from 'react';
import { getArtCounselDetail } from 'controllers/Outers/artCounsels';
import { ArtCounselsDetailTable } from 'components/Common';
import { Spin } from 'antd';

const ArtCounselsDetailTableConatiner = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [artCounsel, setArtCounsel] = useState({});
  useEffect(() => {
    if (document_id) {
      setArtCounsel({});
      setLoading(true);
      const data = getArtCounselDetail(document_id);
      data.then((d) => {
        setArtCounsel(d);
        setLoading(false);
      });
    }
  }, [document_id]);
  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <ArtCounselsDetailTable artCounsel={artCounsel} setArtCounsel={setArtCounsel} />
      </Spin>
    :
      <ArtCounselsDetailTable artCounsel={artCounsel} setArtCounsel={setArtCounsel} />
  );
};

export default ArtCounselsDetailTableConatiner;
