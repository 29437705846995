import React from 'react';
import { Modal, Input } from 'antd';

const PdfModal = (props) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      props.pdfDownload(props.record)
      props.modalIsOpen(false)
    }
  };

    return (
        <Modal
            open={props.open}
            cancelText={"닫기"}
            okText={"확인"}
            onCancel={()=> props.modalIsOpen(false)}
            onOk={()=> {props.pdfDownload(props.record), props.modalIsOpen(false)}}
            title={"다운로드 사유를 입력하세요."}
            closable={false}
          >
            <Input onChange={(e) => props.pdfReason(e.target.value)}
            onKeyPress={(e) => onKeyPress(e)}/>
          </Modal>
    )
}

export default PdfModal;