import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import login from './login_reducer';
import member from './member_reducer';

const createRootReducer = (history) =>
  // eslint-disable-next-line implicit-arrow-linebreak
  combineReducers({
    router: connectRouter(history),
    login,
    member,
  });
export default createRootReducer;
