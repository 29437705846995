import React from 'react';
import PageTemplate from 'pages/PageTemplate';
import HeaderContainer from 'containers/HeaderContainer';
import { MenuPane } from 'components/MenuPane';
import LoginLogsListTableContainer from 'containers/Services/LoginLogs/LoginLogsListTableContainer';
import { useCheckAuth } from 'lib/hooks';
import classNames from 'classnames/bind';
import styles from './LoginLogsPage.scss';

const cx = classNames.bind(styles);

const LoginLogsPage = ({ pid }) => {
  useCheckAuth(pid);
  return (
    <PageTemplate
      header={<HeaderContainer />}
      menu={
        <MenuPane
          selectedOpenKey={['aaihc']}
          selectedMenuKey={pid.toString()}
        />
      }
      body={<LoginLogsListTableContainer />}
    />
  );
};

export default LoginLogsPage;
