import React, { useEffect, useState } from 'react';
import { getEventInfoList } from 'controllers/Contents/eventInfos';
import { Button, Drawer } from 'antd';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate, EventInfosAddTable } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { company_ref } from 'lib/dataRef';
import { getFormattedDate } from 'lib/getFormattedDate';
import PostEventInfosDetailContainer from 'containers/Contents/EventInfos/PostEventInfosDetailContainer';

const PostEventInfosListTableContainer = () => {
  const [eventInfos, setEventInfos] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState({
    visible: false,
    document_id: ''
  });
  const onClose = () => {
    setStatus({
      visible: false,
    });
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id
    });
  };
  useEffect(() => {
    getEventInfoList(setEventInfos, setCount, setLoading);
  }, []);

  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => showDrawer(document_id)}
          >
            관리
          </Button>
        </>
      ),
    },
    // {
    //   title: '보험사',
    //   dataIndex: 'company_id',
    //   key: 'company_id',
    //   align: 'center',
    //   render: (data) => company_ref[data],
    // },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
    },
    {
      title: '등록시각',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '수정시각',
      dataIndex: 'mod_dt',
      key: 'mod_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '공개여부',
      dataIndex: 'del_yn',
      key: 'del_yn',
      align: 'center',
      render: (date) => (date ? '비공개' : '공개'),
    },
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.contents.event_info.path}
          />
        )}
        countLabel={(<CounterLabel count={count} />)}
        addButton={(<EventInfosAddTable />)}
      />
      <ListTableTemplate
        dataSource={eventInfos}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
        <Drawer
          width={750}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <PostEventInfosDetailContainer
            document_id={status.document_id} />
        </Drawer>
      )}
      />
    </>
  );
};

export default PostEventInfosListTableContainer;
