import React from 'react';
import { Row, Descriptions, Input, Divider, Space } from 'antd';
import { company_ref, product_id_ref } from 'lib/dataRef';
import { editPushDetail } from 'controllers/Contents/push';
import { CancelButton, SaveButton } from 'components/Common';

const { Item } = Descriptions;
const PushDetailTable = (props) => {
  const { pushDetail, setPushDetail } = props;
  return (
    <>
      <Row>
        <Descriptions
          title="세부 사항"
          bordered
          column={1}
          style={{ width: '100%' }}
        >
          <Item label="보험사">
          {company_ref[pushDetail.company_id] || '-'}
          </Item>
          <Item label="앱 명칭">
             {product_id_ref[pushDetail.product_id] || '-'}
            </Item>
          <Item label="이름">
          {pushDetail.nm}
          </Item>
          <Item label="연락처">
          <Space.Compact
              style={{
                width: '100%',
              }}
            >
              <Input
                value={pushDetail.phone}
                onChange={(e) => setPushDetail({ ...pushDetail, phone: e.target.value })}
              />
            </Space.Compact>
          </Item>
          <Item label="제목">
            <Input.TextArea
              value={pushDetail.subject}
              rows={8}
              allowClear
              onChange={(e) => setPushDetail({ ...pushDetail, subject: e.target.value })}
            />
          </Item>
          <Item label="내용">
          <Input.TextArea
              value={pushDetail.content}
              rows={8}
              allowClear
              onChange={(e) => setPushDetail({ ...pushDetail, content: e.target.value })}
            />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/push"
          />
          <SaveButton
            func={editPushDetail}
            data={pushDetail}
            location="/push"
          />
        </Space>
      </Row>
    </>
  );
};

export default PushDetailTable;
