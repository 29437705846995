import React, { useState } from 'react';
import { Button, message, Popconfirm } from 'antd';

const SendButton = ({ func, data, location, isDebug = false }) => {
  const [popState, setPopState] = useState({
    visible: false,
  });

  const confirm = async () => {
    setPopState({ visible: false });
    await func(data).then(async (d) => {
      if(d.result === 'success'){
        await message.success('발송에 성공하였습니다.').then((d) => {
          if (location && !isDebug) {
            document.location.href = location;
          }
        });
      }else if(d.result === 'fail'){
        await message.error(`발송에 실패했습니다. ${d.message}`)
      }
    });
  };

  const cancel = () => {
    setPopState({ visible: false });
    message.error('취소했습니다');
  };

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setPopState({ visible });
      return;
    }
    setPopState({ visible }); // show the popconfirm
  };

  const log = () => {
    console.log(data);
  };

  const description = "발송 대상 인원은 총 " + data.length + "명 입니다. \n 발송하시겠습니까?";
  return (
    <>
      <Popconfirm
        title="발송인원을 확인해주세요"
        description={description}
        okText="발송"
        cancelText="취소"
        open={popState.visible}
        onOpenChange={handleVisibleChange}
        onClick={log}
        onConfirm={confirm}
        onCancel={cancel}
      >
        <Button
          type="primary"
          style={{float: 'right', marginLeft:'8px'}}
        >
        발송
        </Button>
      </Popconfirm>
    </>
  );
};

export default SendButton;
