import React from 'react'
import classNames from 'classnames/bind'
import styles from './HeaderButton.scss'

const cx = classNames.bind(styles)

const HeaderButton = () => {
  return (
    <div className={cx('headerButton')}>
    </div>
  )
}

export default HeaderButton
