import * as api from 'controllers/login';

const ID_CHANGE = 'login/ID_CHANGE';
const PW_CHANGE = 'login/PW_CHANGE';
const HANDLE_SUBMIT = 'login/HANDLE_SUBMIT';
const HANDLE_LOGOUT = 'login/HANDLE_LOGOUT';

export const idChange = (id) => ({
  type: ID_CHANGE,
  id,
});
export const pwChange = (pw) => ({
  type: PW_CHANGE,
  pw,
});
export const handleSubmit = (id, pw) => ({
  type: HANDLE_SUBMIT,
  id,
  pw,
});
export const handleLogout = () => ({
  type: HANDLE_LOGOUT,
});

const initialState = {
  id: '',
  pw: '',
};

export default function login(state = initialState, action) {
  switch (action.type) {
  case ID_CHANGE:
    return {
      ...state,
      id: action.id,
    };
  case PW_CHANGE:
    return {
      ...state,
      pw: action.pw,
    };
  case HANDLE_SUBMIT:
    api.login(action.id, action.pw);
    return state;
  case HANDLE_LOGOUT:
    api.logout();
    return state;
  default:
    return state;
  }
}
