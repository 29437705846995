import { Spin } from 'antd';
import { HealthyBodyNowReportTable, HealthyBodyMiddleReportTable, HealthyBodyLastReportTableP, HealthyBodyLastReportTableR, HealthyBodyCounselingTable } from 'components/Common';
import { getHealthyBodyDetail } from 'controllers/Outers/healthyBody';
import React, { useEffect, useState } from 'react';

const HealthyBodyDetailTableContainer = ({ document_id, type, program_cd, props }) => {
  const [loading, setLoading] = useState(true);
  const [dataState, setDataState] = useState({});
  useEffect(() => {
    if (document_id) {
      setLoading(true);
      const data = getHealthyBodyDetail(document_id, type, program_cd, props);
      data.then((d) => {
        setDataState(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      // 테이블(배경화면)이 많아서 불투명한 화면으로 처리
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh', // 화면의 높이에 맞춰줍니다
        }}
      >
        <Spin />
      </div>
    :
    <>
      {/* 현재 평가 보고서 */}
      {type === 'NOW' && dataState.bbiddaki && dataState.medical_result && dataState.medical_result.medical_report &&
        dataState.medical_result.medical_interview && (
        <HealthyBodyNowReportTable dataState={dataState} setDataState={setDataState} />
      )}
      {/* 중간 평가 보고서 */}
      {type === 'MIDDLE' && dataState.bbiddaki && dataState.before_bbiddaki && dataState.before_medical_result && dataState.medical_result &&
        dataState.before_medical_result.medical_interview && dataState.before_medical_result.medical_report && (
        <HealthyBodyMiddleReportTable dataState={dataState} setDataState={setDataState} />
      )}
      {/* 최종 평가 보고서 ( 질환예방 )*/}
      {type === 'LAST' && program_cd === 'P' && dataState.bbiddaki && dataState.before_medical_result && (
        <HealthyBodyLastReportTableP dataState={dataState} setDataState={setDataState} />
      )}
      {/* 최종 평가 보고서 ( 재발방지 )*/}
      {
        type === 'LAST' &&
        program_cd === 'R' &&
        dataState.first_week_bbiddaki &&
        dataState.four_week_bbiddaki &&
        dataState.bbiddaki &&
        dataState.first_week_medical_result &&
        dataState.four_week_medical_result &&
        dataState.medical_result &&
        dataState.first_week_medical_result.medical_report &&
          (
          <HealthyBodyLastReportTableR dataState={dataState} setDataState={setDataState} />
      )}
      {/* 상담내역 */}
      {type === 'COUNSELING' && dataState && dataState.counselingInfo && (
        <HealthyBodyCounselingTable dataState={dataState} setDataState={setDataState} />
      )}
      </>
  );
};

export default HealthyBodyDetailTableContainer;
