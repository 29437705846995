import React, { useState } from 'react';
import { Row, Descriptions, Input, Divider, Space, DatePicker, Drawer } from 'antd';
import { AddButton, CancelButton, SaveButton } from 'components/Common';
import { postEventInfo } from 'controllers/Contents/eventInfos';

const { Item } = Descriptions;
const EventInfosAddTable = () => {
  const [eventInfoDetail, setEventInfoDetail] = useState({
    title: '',
    contents: '',
    place: '',
    url: '',
    event_start_dt: '',
    event_end_dt: '',
  });
  const [drawerStatus, setDrawerStatus] = useState(false);
  const handleDrawerShow = () => {
    setDrawerStatus({
      visible: true,
    });
  };
  const handleDrawerClose = () => {
    setDrawerStatus({
      visible: false,
    });
    setEventInfoDetail(eventInfoDetail);
  };

  return (
    <>
    <AddButton handleDrawerShow={handleDrawerShow} />
      <Drawer
        width={600}
        closable
        onClose={handleDrawerClose}
        open={drawerStatus.visible}
      >
      <Row>
        <Descriptions title="컨텐츠 추가" bordered column={1} style={{ width: '100%' }}>
          <Item label="제목">
            <Input
              value={eventInfoDetail.title}
              onChange={(e) => setEventInfoDetail({ ...eventInfoDetail, title: e.target.value })}
            />
          </Item>
          <Item label="컨텐츠내용">
            <Input.TextArea
              value={eventInfoDetail.contents}
              rows={8}
              allowClear
              onChange={(e) => setEventInfoDetail({ ...eventInfoDetail, contents: e.target.value })}
            />
          </Item>
          <Item label="장소">
            <Input
              value={eventInfoDetail.place}
              onChange={(e) => setEventInfoDetail({ ...eventInfoDetail, place: e.target.value })}
            />
          </Item>
          <Item label="URL">
            <Input
              value={eventInfoDetail.url}
              onChange={(e) => setEventInfoDetail({ ...eventInfoDetail, url: e.target.value })}
            />
          </Item>
          <Item label="행사기간">
            <DatePicker onChange={(date) => setEventInfoDetail({ ...eventInfoDetail, event_start_dt: date.format('YYYY.MM.DD') })} />
            {' ~ '}
            <DatePicker onChange={(date) => setEventInfoDetail({ ...eventInfoDetail, event_end_dt: date.format('YYYY.MM.DD') })} />
          </Item>
        </Descriptions>
      </Row>
      <Divider />
      <Row>
        <Space>
          <CancelButton
            location="/post-eventInfos"
          />
          <SaveButton
            func={postEventInfo}
            data={eventInfoDetail}
            location="/post-eventInfos"
          />
        </Space>
      </Row>
      </Drawer>
    </>
  );
};

export default EventInfosAddTable;
