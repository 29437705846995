import dayjs from 'dayjs';

export function getFormattedDate (date, option) {
  if (date > 0) {
    let formatOpt;
    switch (option) {
    case 'opt1':
      formatOpt = 'YYYY년 MM월 DD일';
      break;
    case 'opt2':
      formatOpt = 'YYYY/\MM/DD\nAhh:mm';
      break;
    case 'opt3':
      formatOpt = 'hh:mm';
      break;
    case 'opt4':
      formatOpt = 'YYYY년 MM월 DD일 HH시 mm분';
      break;
    case 'opt5':
      formatOpt = 'YYYY-MM-DD\nHH:mm:ss';
      break;
    case 'opt6':
      formatOpt = 'YYYY-MM-DD';
      break;
    default:
      formatOpt = 'YYYY/MM/DD';
      break;
    }
    return (dayjs.unix(date / 1000).format(formatOpt));
  }
  return '-';
};

export function to_date(date_str) {
    var yyyyMMdd = String(date_str);
    var sYear = yyyyMMdd.substring(0, 4);
    var sMonth = yyyyMMdd.substring(4, 6);
    var sDate = yyyyMMdd.substring(6, 8);
    var sHour = yyyyMMdd.substring(8, 10);
    var sMinute = yyyyMMdd.substring(10, 12);
    var sSecond = yyyyMMdd.substring(12, 14);
  
    return new Date(
      Number(sYear),
      Number(sMonth) - 1,
      Number(sDate),
      Number(sHour),
      Number(sMinute),
      Number(sSecond)
    );
  }

// export default getFormattedDate;
