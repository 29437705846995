import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination } from 'components/Common';
import { getGrowthRecsList } from 'controllers/HealthLogs/growthRecs';
import GrowthRecsDetailTableContainer from './GrowthRecsDetailTableContainer';
import { routes_ref } from 'lib/routeRef';
import { maskName } from 'lib/maskPersonInfo';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';

const GrowthRecsListTableContainer = () => {
  const [growthRecs, setGrowthRecs] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }
  });
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState({
    visible: false,
    crm_child_id: '',
    crm_child_gender: '',
  });
  
  const onClose = () => {
    setStatus({
      visible: false,
      crm_child_id: '',
      crm_child_gender: '',
    });
  };
  const showDrawer = (crm_child_id, crm_child_gender) => {
    setStatus({
      visible: true,
      crm_child_id,
      crm_child_gender,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getGrowthRecsList(setGrowthRecs, growthRecs, 'first', setCurrentPage, searchState, setLoading);
  }, []);

  function goSearch() {
    getGrowthRecsList(setGrowthRecs, growthRecs, 'first', setCurrentPage, searchState, setLoading);
  }

  const columns = [
    {
      title: '',
      dataIndex: 'crm_child_id',
      key: 'crm_child_id',
      align: 'center',
      render: (data, record) => (
        <>
          <Button
            key={data}
            type="primary"
            size="small"
            onClick={() => {insertInfoAcessLog(location.pathname, 'growRecsDetail', "조회", undefined, data), showDrawer(data, record.crm_child_gender)}}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '계약자 이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '자녀 이름',
      dataIndex: 'crm_child_nm',
      key: 'crm_child_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '자녀 생년월일(개월)',
      dataIndex: 'crm_child_birth_dt',
      key: 'crm_child_birth_dt',
      align: 'center',
      render: (data, record) => (data || ' ------ ') + ' ( ' + record.months + ' 개월 )',
    },
    {
      title: '신장',
      dataIndex: 'height',
      key: 'height',
      align: 'center',
      render: (data) => (
        <>
          <strong>
            {data}
          </strong>
          {' cm'}
        </>
      ),
    },
    {
      title: '체중',
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      render: (data) => (
        <>
          <strong>
            {data}
          </strong>
          {' kg'}
        </>
      ),
    },
    {
      title: '체질량지수',
      dataIndex: 'body_mass',
      key: 'body_mass',
      align: 'center',
      render: (data) => (
        <>
          <strong>
            {data}
          </strong>
          {' kg/m2'}
        </>
      ),
    },
    {
      title: '머리둘레',
      dataIndex: 'head_circum',
      key: 'head_circum',
      align: 'center',
      render: (data) => {
        if (data === -1) {
          data = '-';
        }
        return (
          <>
            <strong>
              {data}
            </strong>
            {' cm'}
          </>
        );
      },
    },
  ];


  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  const setPage = (page) => {
    setCurrentPage(page)
  }

  const renderMoveData = (move) => {
    getGrowthRecsList(setGrowthRecs, growthRecs, 'first', setCurrentPage, searchState, setLoading);
  }

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.healthLogs.growth_rec.path}
          />
        )}
        countLabel={(<CounterLabel count={growthRecs.count} />)}
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['이름', '자녀'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableWithOutPaging
        dataSource={currentData(growthRecs.data)}
        columns={columns}
        loading={loading}
        pagination={(
          <Pagination
            totalData={growthRecs.data}
            pagingState={growthRecs.pagingState}
            setPage={setPage}
            renderMoveData={renderMoveData}
          ></Pagination>
        )}
        DetailTableContainer={(
          <Drawer
            width={1200}
            closable
            onClose={onClose}
            open={status.visible}
          >
            <GrowthRecsDetailTableContainer
              crm_child_id={status.crm_child_id}
              crm_child_gender={status.crm_child_gender}
            />
          </Drawer>
        )}
      />
    </>
  );
};

export default GrowthRecsListTableContainer;
