import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableWithOutPaging, Pagination } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { Spin, Button } from 'antd';
import { getHanaStepsList } from 'controllers/Services/hanaSteps';
import { maskName, maskPhone } from 'lib/maskPersonInfo';

const HanaStepListTableContainer = () => {
  const [hanaSteps, setHanaSteps] = useState({
    data: [],
    count: 0,
    pagingState: {
      start: null,
      next: null,
      page: 1,
      goPage: 'first'
    }
  });
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getHanaStepsList(setHanaSteps, hanaSteps, 'first', setCurrentPage, searchState, setLoading);
  }, []);

  function goSearch() {
    getHanaStepsList(setHanaSteps, hanaSteps, 'first', setCurrentPage, searchState, setLoading);
  }
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const columns = [
    {
      title: '',
    },
    {
      title: '이름',
      dataIndex: 'crm_nm',
      key: 'crm_nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'crm_phone',
      key: 'crm_phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '가입일',
      dataIndex: 'signup_str_dt',
      key: 'signup_str_dt',
      align: 'center',
    },
    {
      title: '현재 서비스 기간',
      dataIndex: 'activity_dt',
      key: 'activity_dt',
      align: 'center',
    },
    {
      title: '누적 걸음 수',
      dataIndex: 'accumulated_steps',
      key: 'accumulated_steps',
      align: 'center',
      render(accumulated_steps) {
          return `${accumulated_steps} 보`;
      },
    },
    {
      title: '누적 포인트',
      dataIndex: 'accumulated_points',
      key: 'accumulated_points',
      align: 'center',
      render(accumulated_points) {
          return `${accumulated_points} P`;
      },
    },
  ];

  let cp = currentPage;
  if(cp > 10) {  // 전체데이터가 아닌 100개씩 잘라서 가져오므로 별수없음;
    const n = cp%10;
    cp = (n == 0 ? 10 : n);
  }

  const indexOfLast = cp * 10;
  const indexOfFirst = indexOfLast - 10;

  const currentData = (data) => {
    let currentData;
    currentData = data.slice(indexOfFirst, indexOfLast);
    return currentData;
  }
  const setPage = (page) => {
    setCurrentPage(page)
  }

  const renderMoveData = (move) => {
    getHanaStepsList(setHanaSteps, hanaSteps, move, setCurrentPage, searchState, setLoading);
  }

  return (
    <>
    <InfoPane
      boardListButton={(
      <LinkButton
        link={routes_ref.base.members.hana_step.path}
      />
      )}
      countLabel={(<CounterLabel count={hanaSteps.count} />)}
      resetButton={(
        <Button
          type="primary"
          style={{float: 'right', marginLeft:'8px'}}
          onClick={() => {
            setSearchState({});
          }}
        >
          초기화
        </Button>
      )}
      searchButton={(
        <Button
          type="primary"
          style={{float: 'right', marginLeft: '8px'}}
          onClick={() => {
            setLoading(true);
            goSearch()
          }}
        >
          검색
        </Button>
      )}
      searchFieldList={{
        field: ['이름', '연락처'],
        searchState: searchState,
        setSearchState: setSearchState,
        onKeyPress: onKeyPress
      }}
    />
    <ListTableWithOutPaging
      dataSource={currentData(hanaSteps.data)}
      columns={columns}
      loading={loading}
      pagination={(
        <Pagination
          totalData={hanaSteps.data}
          pagingState={hanaSteps.pagingState}
          setPage={setPage}
          renderMoveData={renderMoveData}
        ></Pagination>
      )}
    />
    </>
  );
};

export default HanaStepListTableContainer;
