import React, { useEffect, useState } from 'react';
import { getFormattedDate } from 'lib/getFormattedDate';
import { company_ref } from 'lib/dataRef';
import { Descriptions, Divider, Row } from 'antd';
import { ListTableTemplate } from 'components/Common';

const { Item } = Descriptions;
const MedicinesDetailTable = ({ medicineDetail }) => {
  const [mediList, setMediList] = useState([]);
  const columns = [
    {
      title: '처방전 명',
      dataIndex: 'pre_name',
      key: 'pre_name',
      align: 'center',
    },
    {
      title: '처방병원',
      dataIndex: 'pre_hospital',
      key: 'pre_hospital',
      align: 'center',
    },
    {
      title: '처방일',
      dataIndex: 'pre_dt',
      key: 'pre_dt',
      align: 'center',
      render: (date) => getFormattedDate(date),
    },
    {
      title: '복용 알림 기간',
      dataIndex: 'med_end_dt',
      key: 'med_end_dt',
      align: 'center',
      render: (date, record) => {
        let str = '';
        if (record.med_end_yn) {
          str = ' (종료)';
        }
        return ('~' + getFormattedDate(date) + str);
      },
    },
    {
      title: '알람 온오프 여부',
      dataIndex: 'img_url',
      key: 'alarm_yn',
      align: 'center',
      render: (bool) => (bool ? 'Y' : ''),
    },
    {
      title: '복용시각',
      dataIndex: 'alerts',
      key: 'alerts',
      align: 'center',
      render: (data) => data.map((v) => (
        <>
          <Row>
            {v.time.split('T')[1].slice(0, 5)}
          </Row>
        </>
      )),
    },
  ];
  useEffect(() => {
    const mediArr = [];
    if (medicineDetail.length) {
      medicineDetail.map((row) => {
        mediArr.push(row);
      });
    }
    setMediList(mediArr);
  }, [medicineDetail]);
  return (
    <>
      <Descriptions
        title="회원 정보"
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="이름">
          {medicineDetail[0] ? medicineDetail[0].crm_nm : '-'}
        </Item>
        <Item label="연락처">
          {medicineDetail[0] ? medicineDetail[0].crm_phone : '-'}
        </Item>
        <Item label="보험사명">
          {medicineDetail[0] ? company_ref[medicineDetail[0].company_id] : '-'}
        </Item>
      </Descriptions>
      <Divider />
      <ListTableTemplate
        dataSource={mediList}
        columns={columns}
      />
    </>
  );
};

export default MedicinesDetailTable;
