import React from 'react';
import { HeaderLogout } from 'components/HeaderPane';

const HeaderPane = (props) => (
  <>
    <HeaderLogout onLogout={props.onLogout} />
  </>
);

export default HeaderPane;
