import React, { useEffect, useState } from 'react';
import { getMediCertDetail } from 'controllers/Services/mediCerts';
import { MedicertsDetailTable } from 'components/Common';
import { Spin } from 'antd';

const MediCertsDetailTableContainer = ({ document_id }) => {
  const [loading, setLoading] = useState(true);
  const [mediCertsDetail, setMedicertsDetail] = useState({});
  useEffect(() => {
    if (document_id) {
      setMedicertsDetail({});
      setLoading(true);
      const data = getMediCertDetail(document_id);
      data.then((d) => {
        setMedicertsDetail(d);
        setLoading(false);
      });
    }
  }, [document_id]);

  return (
    loading ?
      <Spin
        tip="Loading"
        size="large"
      >
        <MedicertsDetailTable mediCertsDetail={mediCertsDetail} setMedicertsDetail={setMedicertsDetail}/>
      </Spin>
    :
      <MedicertsDetailTable
      mediCertsDetail={mediCertsDetail}
      setMedicertsDetail={setMedicertsDetail} />
  );
};

export default MediCertsDetailTableContainer;
