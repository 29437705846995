import axios from 'axios';
import { baseUrl } from 'lib/url';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

/**
 * 챗봇 건강유형 관리 리스트 조회
 */
export async function getChatHealthTypeList(
  setChatHlTypes,
  searchState,
  setLoading
) {
  try {
    const result = await axios({
      method: 'POST',
      url: `${baseUrl}/chatbot/healthType/getList`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ searchState }),
    }).then((response) => response.data.lists);

    setChatHlTypes(result);
    setLoading(false);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 챗봇 건강유형 의 상세 정보 조회
 * @param {string} no 해당 건강유형의 정보가 저장된 document의 id
 */
export async function getChatHealthTypeDetail(no) {
  try {
    const result = await axios({
      method: 'GET',
      url: `${baseUrl}/chatbot/healthType/getDetail/${no}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.data);

    return result;
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}

/**
 * 고객들의 모든 챗봇 건강유형 이력을 엑셀로 추출
 */
export async function makeXlsx(excelReason, searchState) {
  try {
    // 사유 미작성 시 다운로드 못함
    if (!excelReason) {
      return { result: 'fail', message: 'Please check the values.' };
    }

    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportXlsx = (data) => {
      const fileName =
        'Healthcare_Admin_chatHealthType_' +
        Math.floor(new Date().getTime() / 1000);
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook = { Sheets: { data: workSheet }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(workBook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const blob = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(blob, fileName, fileExtension);
    };

    return await axios
      .post(`${baseUrl}/chatbot/healthType/excel`, { searchState: searchState })
      .then((res) => {
        return exportXlsx(res.data);
      });
  } catch (err) {
    console.error(err);
    return { result: 'error: \n', message: err };
  }
}
