import React, { useEffect, useState } from 'react';
import { getChatApplyDetail } from 'controllers/chatbot/chatbotApply';
import { ChatApplyDetailTable } from 'components/Common';
import { Spin } from 'antd';

const ChatApplyDetailTableContainer = ({ ps_no }) => {
  const [chatApplyDetail, setChatApplyDetail] = useState({});
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (ps_no) {
      setChatApplyDetail({});
      setLoading(true);
      const data = getChatApplyDetail(ps_no);
      data.then((d) => {
        setChatApplyDetail(d);
        setLoading(false);
      });

    }
  }, [ps_no]);

  return (
    loading ?
    <Spin
      tip="Loading"
      size="large"
    >
      <ChatApplyDetailTable chatApplyDetail={chatApplyDetail} setChatApplyDetail={setChatApplyDetail} />
    </Spin>
  :
    <ChatApplyDetailTable chatApplyDetail={chatApplyDetail} setChatApplyDetail={setChatApplyDetail} />
  );
};

export default ChatApplyDetailTableContainer;
