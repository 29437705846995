// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../../assets/menu-cl.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".headerButton{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 50% 50% no-repeat #005b33;width:50px;height:50px;border:0}\n", "",{"version":3,"sources":["webpack://./src/components/HeaderPane/HeaderButton/HeaderButton.scss"],"names":[],"mappings":"AAAA,cACE,4EAAsE,CACtE,UAAW,CACX,WAAY,CACZ,QAAS","sourcesContent":[".headerButton {\n  background: url(../../../assets/menu-cl.png) 50% 50% no-repeat #005b33;\n  width: 50px;\n  height: 50px;\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
