import React, { useEffect, useState } from 'react';
import { getFaqList } from 'controllers/Contents/faq';
import { Button, Drawer } from 'antd';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate, FaqAddTable } from 'components/Common';
import { routes_ref } from 'lib/routeRef';
import { company_ref, product_id_ref } from 'lib/dataRef';
import FaqDetailContainer from 'containers/Contents/Faq/FaqDetailContainer';

const FaqListTableContainer = () => {
  const [faq, setFaq] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});   // 검색어
  const [status, setStatus] = useState({
    visible: false,
    document_id: ''
  });
  const onClose = () => {
    setStatus({
      visible: false,
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  const showDrawer = (document_id) => {
    setStatus({
      visible: true,
      document_id
    });
  };
  useEffect(() => {
    getFaqList(setFaq, setCount, searchState, setLoading);
  }, []);

  function goSearch() {
    getFaqList(setFaq, setCount, searchState, setLoading);
  }
  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      width: '5%',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => showDrawer(document_id)}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      width: '15%',
      align: 'center',
      render: (data) => company_ref[data] 
  },
  {
    title: '앱 명칭',
    dataIndex: 'product_id',
    key: 'product_id',
    width: '20%',
    align: 'center',
    render: (data) => product_id_ref[data] 
},
    {
      title: '제목',
      dataIndex: 'subject',
      key: 'subject',
      align: 'center',
    }
  ];

  return (
    <>
      <InfoPane
        boardListButton={(
          <LinkButton
            link={routes_ref.base.contents.faq.path}
          />
        )}
        countLabel={(<CounterLabel count={count} />)}
        addButton={(<FaqAddTable />)}
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['앱 명칭'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableTemplate
        dataSource={faq}
        columns={columns}
        loading={loading}
        DetailTableContainer={(
        <Drawer
          width={800}
          closable
          onClose={onClose}
          open={status.visible}
        >
          <FaqDetailContainer
            document_id={status.document_id} />
        </Drawer>
      )}
      />
    </>
  );
};

export default FaqListTableContainer;
