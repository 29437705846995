import React, { useState, useEffect, useRef } from 'react';
export function daumPostcode(chatCenter, setCenter) {
  new daum.Postcode({
    oncomplete: function (data) {
      const { naver } = window;
      const navermaps = naver.maps;

      // 지번선택과 도로명선택시 들어오는 데이터가 다름
      const addr =
        data.jibunAddress == '' ? data.autoJibunAddress : data.jibunAddress;

      navermaps.Service.geocode(
        {
          address: addr,
        },
        function (status, response) {
          if (status !== navermaps.Service.Status.OK) {
            console.log('error');
            return alert('Something wrong!');
          } else {
            console.log('응답 = ', response);
            const result = response.result;
            console.log('결과 = ', result); // Container of the search result
            const items = result.items; // Array of the search result
            console.log('아이템 = ', items);
            // do Something
            console.log(
              '위도 = ',
              items[0].point.y,
              ' 경도 = ',
              items[0].point.x
            );
            console.log(data);
            if (data.sido == '전북특별자치도') {
              data.sido = '전북';
            }
            console.log(data);
            setCenter({
              ...chatCenter,
              i_lon: items[0].point.x,
              i_lat: items[0].point.y,
              i_addr: data.roadAddress + ', (' + data.bname + ')',
              i_shortaddr: data.sido + ' ' + data.sigungu,
            });
          }
        }
      );
    },
  }).open();
}
