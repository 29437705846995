import React, { useEffect, useState } from 'react';
import { InfoPane } from 'components/InfoPane';
import { CounterLabel, LinkButton, ListTableTemplate, HelperAddTable, ExcelModal } from 'components/Common';
import { Button, Drawer } from 'antd';
import { getFormattedDate } from 'lib/getFormattedDate';
import { routes_ref } from 'lib/routeRef';
import { company_ref, helpergroup_type_ref } from 'lib/dataRef';
import { getHelpersList, makeXlsx } from 'controllers/Outers/helpers';
import HelpersDetailTableContainer from './HelpersDetailTableContainer';
import { insertInfoAcessLog } from 'controllers/Systems/adminInfoAcessLogs';
import { maskName, maskPhone } from 'lib/maskPersonInfo';

const HelpersListTableContainer = () => {
  const [helpers, setHelpers] = useState([{}]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchState, setSearchState] = useState({});
  const [drawerStatus, setDrawerStatus] = useState({
    detail: {
      visible: false,
    },
    activity: {
      visible: false,
    },
    document_id: '',
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [excelReason, setExcelReason] = useState(''); // 엑셀다운로드 사유

  const showDetailDrawer = (document_id) => {
    setDrawerStatus({
      ...drawerStatus,
      detail: {
        visible: true,
      },
      document_id,
    });
  };
  const onCloseDetailDrawer = () => {
    setDrawerStatus({
      ...drawerStatus,
      detail: {
        visible: false,
      },
      document_id: '',
    });
  };
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      setLoading(true);
      goSearch();
    }
  };
  useEffect(() => {
    getHelpersList(setHelpers, setCount, setLoading, searchState);
  }, []);

  function goSearch() {
    getHelpersList(setHelpers, setCount, setLoading, searchState);
  }
  const excelDownload = () =>{
    insertInfoAcessLog(location.pathname, 'makeXlsx', "다운로드", excelReason);
    makeXlsx(excelReason, searchState);
  }
  const columns = [
    {
      title: '',
      dataIndex: 'document_id',
      key: 'document_id',
      align: 'center',
      render: (document_id) => (
        <>
          <Button
            key={document_id}
            type="primary"
            size="small"
            onClick={() => {insertInfoAcessLog(location.pathname, 'helperDetail', "조회", undefined, document_id),showDetailDrawer(document_id)}}
          >
            관리
          </Button>
        </>
      ),
    },
    {
      title: '보험사',
      dataIndex: 'company_id',
      key: 'company_id',
      align: 'center',
      render: (data) => company_ref[data]
    },
    {
      title: '구분',
      dataIndex: 'helpergroup_type',
      key: 'helpergroup_type',
      align: 'center',
      render: (data) => helpergroup_type_ref[data]
    },
    {
      title: '업체명',
      dataIndex: 'helpergroup_nm',
      key: 'helpergroup_nm',
      align: 'center',
    },
    {
      title: '이름',
      dataIndex: 'nm',
      key: 'nm',
      align: 'center',
      render: (data) => maskName(data),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (data) => maskPhone(data),
    },
    {
      title: '간병인 등록일',
      dataIndex: 'reg_dt',
      key: 'reg_dt',
      align: 'center',
      render: (date) => getFormattedDate(date, 'opt2'),
    },
    {
      title: '만족도 조사\n횟수',
      dataIndex: 'evaluation_num_count',
      key: 'evaluation_num_count',
      align: 'center',
      render: (value) => `${value} 회`,
    },
    {
      title: '만족도 조사\n점수',
      dataIndex: 'evaluation_num',
      key: 'evaluation_num',
      align: 'center',
      render: (value, record) =>
        record.evaluation_num ? `${record.evaluation_num.toFixed(2)} 점` : '',
    },
  ];
  return (
    <>
      <InfoPane
        boardListButton={
          <LinkButton
            link={routes_ref.base.outers.helper.path}
          />
        }
        countLabel={<CounterLabel count={count} />}
        addButton={<HelperAddTable />}
        xlsButton={
          <Button
            type="primary"
            style={{ float: 'right', marginLeft: '8px' }}
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            엑셀 다운로드
          </Button>
        }
        resetButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft:'8px'}}
            onClick={() => {
              setSearchState({});
            }}
          >
            초기화
          </Button>
        )}
        searchButton={(
          <Button
            type="primary"
            style={{float: 'right', marginLeft: '8px'}}
            onClick={() => {
              setLoading(true);
              goSearch()
            }}
          >
            검색
          </Button>
        )}
        searchFieldList={{
          field: ['보험사', '이름', '연락처'],
          searchState: searchState,
          setSearchState: setSearchState,
          onKeyPress: onKeyPress
        }}
      />
      <ListTableTemplate
        dataSource={helpers}
        columns={columns}
        loading={loading}
        DetailTableContainer={
          <>
            <Drawer
              width={900}
              closable
              onClose={onCloseDetailDrawer}
              open={drawerStatus.detail.visible}
            >
              <HelpersDetailTableContainer
                document_id={drawerStatus.document_id}
              />
            </Drawer>
          </>
        }
      />
      <div>
        {
          modalIsOpen && (
            <ExcelModal
            open={modalIsOpen}
            modalIsOpen={setModalIsOpen}
            excelDownload={excelDownload}
            excelReason={setExcelReason}
          >
          </ExcelModal>
        )}
      </div>
    </>
  );
};

export default HelpersListTableContainer;
