import React from 'react';
import { Descriptions, Divider } from 'antd';

const { Item } = Descriptions;
const BbiddakiApiLogDetailTable = (props) => {
  const { dataState, setDataState } = props;
  return (
    <>
      <Descriptions
        title={'상세보기'}
        bordered
        column={1}
        style={{ width: '100%' }}
      >
        <Item label="머리-목1" style={{width:'10%'}}>
            {dataState.ok === true && dataState.ex001 === '-' ?
            dataState.errors[0].title
            : dataState.ex001
            }
        </Item>
        <Item label="머리-목2" style={{width:'10%'}}>
            {dataState.ok === true && dataState.ex002 === '-' ?
            dataState.errors[0].title
            : dataState.ex002
            }
        </Item>
        <Item label="머리-목3" style={{width:'10%'}}>
            {dataState.ok === true && dataState.ex003 === '-' ?
            dataState.errors[0].title
            : dataState.ex003
            }
        </Item>
        <Item label="상체1" style={{width:'10%'}}>
            {dataState.ex004}
        </Item>
        <Item label="상체2" style={{width:'10%'}}>
            {dataState.ex005}
        </Item>
        <Item label="하체1" style={{width:'10%'}}>
            {dataState.ex006}
        </Item>
        <Item label="하체2" style={{width:'10%'}}>
            {dataState.ex007}
        </Item>
        <Item label="전체점수(평균)" style={{width:'10%'}}>
            {dataState.total_score > 0 ? Number(dataState.total_score).toFixed(2) : '-' }
        </Item>
        <Item label="머리-목" style={{width:'10%'}}>
            {dataState.head_score > 0 ? Number(dataState.head_score).toFixed(2) : '-' }
        </Item>
        <Item label="상체" style={{width:'10%'}}>
            {dataState.upper_body_score > 0 ? Number(dataState.upper_body_score).toFixed(2) : '-' }
        </Item>
        <Item label="하체" style={{width:'10%'}}>
            {dataState.lower_body_score > 0 ? Number(dataState.lower_body_score).toFixed(2) : '-' }
        </Item>
      </Descriptions>
      <Divider />
    </>
  );
};

export default BbiddakiApiLogDetailTable;
